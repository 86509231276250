import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from 'helpers/axios';
import { stringify } from 'qs';

// ##### ##### ##### ##### #####   GET   ##### ##### ##### ##### ##### //

export const getFolders = ({ type, search, projectId } = {}) => {
  const queryParams = {
    type,
    'search[title_cont]': search,
    project_id: projectId,
  };

  return getRequest(`folders?${stringify(queryParams)}`);
};

// TODO: folders/:folderId
// TODO: folders/:folderId/already_shared
// TODO: folders/:folderId/collaborators
export const getFolderDocuments = (folderId) => {
  return getRequest(`folders/${folderId}/documents`);
};

export const exportFolder = (folderId) => {
  return getRequest(`folders/${folderId}/export`, 'arraybuffer');
};
// TODO: folders/trashed

// ##### ##### ##### ##### #####   POST   ##### ##### ##### ##### ##### //

export const createFolder = (projectId, title) => {
  const queryParams = {
    project_id: projectId,
  };

  return postRequest(`folders?${stringify(queryParams)}`, {
    folder: { title },
  });
};

export const duplicateFolder = (folderId, title) => {
  return postRequest(`folders/${folderId}/duplicate`, { folder: { title } });
};
// TODO: folders/:folderId/move_document
export const starFolder = (folderId) => {
  return postRequest(`starred_folders?starred_folder[folder_id]=${folderId}`);
};

// ##### ##### ##### ##### #####   PUT   ##### ##### ##### ##### ##### //

// TODO: folders/:folderId
export const restoreFolder = (folderId) => {
  return putRequest(`folders/${folderId}/restore`);
};

export const renameFolder = (folderId, title) => {
  return putRequest(`folders/${folderId}`, { folder: { title } });
};

// ##### ##### ##### ##### #####   PATCH   ##### ##### ##### ##### ##### //

// TODO: folders/:folderId

// ##### ##### ##### ##### #####   DELETE   ##### ##### ##### ##### ##### //

// TODO: folders/:folderId
// TODO: folders/:fully_destroy
// TODO: folders/:remove_document
export const deleteStarredFolder = (folderId) => {
  return deleteRequest(`starred_folders/${folderId}`);
};

// ##### ##### ##### ##### #####   ? WEIRD ?   ##### ##### ##### ##### ##### //
export const shareFolderIfNotAlready = async (user, urlPath = null) => {
  urlPath = urlPath || window.location.pathname;
  const urlPathParams = urlPath.split('/');
  const folderId = urlPathParams[2];
  const access = urlPathParams[4];

  let navigatingPath = null;

  if (
    urlPathParams[1] === 'folder' &&
    urlPathParams[3] === 'true' &&
    urlPathParams[4]
  ) {
    const response = await getRequest(`folders/${folderId}/already_shared`);

    if (response?.data?.owner) {
      navigatingPath = `/folder/${folderId}`;
    } else {
      !response?.data?.already_shared &&
        (await postRequest(`shared_folders`, {
          shared_folder: {
            collaborators: [user.email],
            folder_id: folderId,
            access_level_id: access,
          },
        }));
    }
  }

  return navigatingPath;
};
