import { useMutation } from '@tanstack/react-query';
import { generateIdeas } from 'services/taskService';
import { error as renderError } from 'helpers/toaster';

const useIdeate = () => {
  const mutation = useMutation({
    mutationFn: generateIdeas,
    onError: (error) => {
      renderError(error);
    },
  });

  return {
    ...mutation,
    ...mutation?.data,
  };
};

export default useIdeate;
