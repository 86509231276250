const transformScore = (category) => (category?.score || 0) * 10;

export const getScoreInfo = ({ 
  readability, 
  grammar_and_correctness,
  writing_style,
  SEO,
  vocabulary,
  sentence_structure,
  term_usage
}) => {

  const transformedReadability = transformScore(readability);
  const grammar = transformScore(grammar_and_correctness);
  const writingStyle = transformScore(writing_style);
  const seo = transformScore(SEO);
  const transformedVocabulary = transformScore(vocabulary);
  const sentenceStructure = transformScore(sentence_structure);
  const transformedTerm = transformScore(term_usage);

  const overallScore = Math.round(
    (transformedReadability +
      grammar +
      writingStyle +
      seo +
      transformedVocabulary +
      sentenceStructure +
      transformedTerm) /
    7, // <- Number of Categories extracted from docHealth
  ) || 0;

  return {
    overallScore,
    transformedReadability,
    grammar,
    writingStyle,
    seo,
    transformedVocabulary,
    sentenceStructure,
    transformedTerm,
  };
}