import { HocuspocusProvider } from '@hocuspocus/provider';
import Collaboration from '@tiptap/extension-collaboration';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import { Color } from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import { TextStyle } from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { Editor as TiptapEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { AfterParagraphSpacing } from 'Containers/Editor/Extensions/AfterParagraphSpacing';
import { BeforeParagraphSpacing } from 'Containers/Editor/Extensions/BeforeParagraphSpacing';
import { FontSize } from 'Containers/Editor/Extensions/FontSize';
import { LineHeight } from 'Containers/Editor/Extensions/LineHeight';
import Paragraph from 'Containers/Editor/Extensions/Paragraph';
import { Span } from 'Containers/Editor/Extensions/Span';
import { Comment } from 'Containers/Editor/Extensions/comment';
import { ResizableMedia } from 'Containers/Editor/Extensions/resizableMedia';
import { getRandomColor } from 'constants';
import { copyToClipboard } from 'helpers';
import { generateResponse } from 'helpers/openAI';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setLoader } from 'redux/slices/loader';
import {
  getComments,
  getOpenComments,
  getResolvedComments,
  postComment,
  changeStatus,
  deleteComment as deleteCommentService,
} from 'services/commentService';
import {
  getDocument,
  getDocumentAlreadyShared,
  getDocumentCollaborators,
  getDocumentHealth,
  shareDocumentIfNotAlready,
  updateDocument,
} from 'services/documentService';
import { v4 as uuid } from 'uuid';
import * as Y from 'yjs';
import { Placeholder } from '@tiptap/extension-placeholder';

const windowWidth = window.innerWidth;

export default function useCollabEditor() {
  const [editor, setEditor] = useState(undefined);
  const [comments, setComments] = useState({});
  const [userDocument, setDocument] = useState(null);
  const [documentCopy, setDocumentCopy] = useState(null);
  const [contentSidebarCollapse, setContentSidebarCollapse] = useState(true);
  const [showComments, setShowComments] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [showDocHealth, setShowDocHealth] = useState(false);
  const [lastUpdated, setLastUpdated] = useState({});
  const updateEditor = useRef(false);
  const [collaborators, setCollaborators] = useState([]);
  const [documentOwner, setDocumentOwner] = useState(null);
  const [isTeamMember, setIsTeamMember] = useState(false);
  const [hoverId, setHoverId] = useState('');
  const [isEditorSynced, setIsEditorSynced] = useState(false);
  const [selection, setSelection] = useState(null);
  const [docHealth, setDocHealth] = useState({});
  const [url, setUrl] = useState('');
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [docHealthLoading, setDocHealthLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { name, id: userId } = user || {};

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { documentId, templateId } = useParams() || {};

  const collaborator = collaborators.find(
    (collaboratorInfo) => collaboratorInfo.collaborator.id === user.id,
  );

  const commentTransformation = (content) => {
    return (
      content?.replace(
        /<span\s+id="([^"]+)"\s+class="([^"]+)"\s*(.*?)>(.*?)<\/span>/g,
        '<span data-comment=\'{"id":"$1","class":"$2"}\'>$3$4</span>',
      ) || ''
    );
  };

  const access = useMemo(() => {
    if (!documentOwner) {
      return '';
    }

    if (user.id === documentOwner?.id || isTeamMember) {
      return 'full_access';
    }

    if (!collaborators?.length) {
      return '';
    }

    if (collaborator) {
      const { access_level } = collaborator || {};

      if (access_level?.level === 'can_view') {
        return navigate(`/document/${documentId}`);
      }

      return access_level?.level;
    }
    return navigate('/');
  }, [collaborators, documentOwner]);

  const fetchComments = async (status) => {
    if (status === 'open') {
      const commentsData = await getOpenComments(documentId);
      setComments(commentsData?.data);
    } else if (status === 'resolved') {
      const commentsData = await getResolvedComments(documentId);
      setComments(commentsData?.data);
    } else {
      const commentsData = await getComments(documentId);
      setComments(commentsData?.data);
    }
  };

  const isComment = access === 'can_comment';

  const fetchCollaborators = useCallback(async () => {
    try {
      const collaboratorResponse = await getDocumentCollaborators(documentId);

      const { data } = collaboratorResponse || {};
      const { shared_documents } = data || {};

      setCollaborators(shared_documents || []);
    } catch (error) {}
  }, []);

  const fetchTeamInfo = useCallback(async () => {
    try {
      const collaboratorResponse = await getDocumentAlreadyShared(documentId);

      const { data } = collaboratorResponse || {};
      const { project_member } = data || {};

      setIsTeamMember(!!project_member);
    } catch (error) {}
  }, []);

  const updateDocumentWithDebounce = useCallback(
    debounce(async (document) => {
      const response = await updateDocument(documentId, document);

      const { document: resDocument } = response?.data || {};
      setLastUpdated({
        updatedAt: resDocument?.updated_at,
        updatedBy: resDocument?.last_updated_by,
      });

      setDocumentCopy(resDocument);

      fetchComments();
    }, 5000),
    [updateDocument],
  );

  useEffect(() => {
    const ydoc = new Y.Doc();
    const provider = new HocuspocusProvider({
      url: process.env.REACT_APP_SOCKET_URL,
      name: documentId,
      document: ydoc,
      onSynced: () => {
        !isEditorSynced && setIsEditorSynced(true);
      },
    });
    const fragment = ydoc.getXmlFragment('richtext');

    if (!editor && isEditorSynced) {
      const tiptapEditor = new TiptapEditor({
        editable: !isComment,
        editorProps: {
          handlePaste: (view, event, slice) => {
            const content = slice.content.content
              .reduce((acc, value) => {
                acc.push(value.content.content[0].text);
                return acc;
              }, [])
              .join('');

            if (!content) {
              return false;
            }

            if (
              /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(
                content,
              )
            ) {
              const ele = document.createElement('div');
              ele.innerHTML = content;
              const transformedContent = ele.innerHTML;
              editor.commands.insertContent(
                transformedContent
                  .replace(/\t/g, '')
                  .replace(/\n/g, '')
                  .replaceAll('> <', '><'),
              );
              return true;
            }

            return false;
          },
        },
        extensions: [
          StarterKit.configure({
            paragraph: Paragraph,
          }),
          Underline,
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
          Placeholder.configure({
            placeholder: () =>
              access === 'can_comment'
                ? ''
                : 'Start typing or prompt AI to write for you',
          }),
          Highlight.configure({ multicolor: true }),
          Color,
          TextStyle,
          FontSize,
          LineHeight,
          BeforeParagraphSpacing,
          AfterParagraphSpacing,
          Link.configure({
            openOnClick: false,
          }),
          Collaboration.configure({
            fragment,
            addCommands() {
              return {
                undo: () => () => {
                  return false;
                },
                redo: () => () => {
                  return false;
                },
              };
            },
          }),
          Comment,
          CollaborationCursor.configure({
            provider,
            user: {
              name,
              color: getRandomColor(),
            },
          }),
          Span,
          ResizableMedia,
        ],
        onBeforeCreate: async ({ editor }) => {
          try {
            const navigatingPath = await shareDocumentIfNotAlready(user);
            if (navigatingPath) {
              navigate(navigatingPath);
            }
          } catch (error) {
            renderError(error);
          }

          if (!userDocument && !updateEditor.current) {
            dispatch(
              setLoader({
                isLoading: true,
                heading: 'Loading Document!',
                description: 'Please wait...',
              }),
            );
            try {
              const response = await getDocument(documentId);
              const { data } = response || {};
              const { document: resDocument } = data || {};

              const { content, user } = resDocument || {};
              setLastUpdated({
                updatedAt: resDocument?.updated_at,
                updatedBy: resDocument?.last_updated_by,
              });

              setDocument(resDocument);
              setDocumentCopy(resDocument);
              const docContent = content || '';

              setDocumentOwner(user);

              const transformedValue = commentTransformation(docContent);
              const commentsData = await getOpenComments(documentId);
              setComments(commentsData?.data);

              editor?.commands.setContent(transformedValue, true);

              updateEditor.current = true;
              dispatch(
                setLoader({
                  isLoading: false,
                }),
              );

              setDocHealthLoading(true);
              const documentHealth = await getDocumentHealth(documentId);
              setDocHealth(documentHealth?.data);
              setDocHealthLoading(false);
            } catch (error) {
              dispatch(
                setLoader({
                  isLoading: false,
                }),
              );
              setDocHealthLoading(false);
            }
          }
        },
      });
      setEditor(tiptapEditor);
    }

    editor?.on('update', async ({ editor }) => {
      if (updateEditor.current) {
        const html = editor.isFocused
          ? editor.getHTML()
          : editor.isEmpty
            ? userDocument.content
            : editor.getHTML();
        const createElement = document.createElement('DIV');
        createElement.innerHTML = html;

        const commentIds = [...createElement.querySelectorAll('.comment')].map(
          (val) => val.id,
        );

        updateDocumentWithDebounce({
          content: html,
          uuids: commentIds,
          template_id: templateId,
        });
      }
    });

    editor?.on('selectionUpdate', ({ editor }) => {
      setSelection(editor?.state?.selection);
    });
  }, [documentId, userDocument, updateEditor.current, isEditorSynced]);

  useEffect(() => {
    if (isComment) {
      editor?.setEditable(false);
    }
  }, [isComment]);

  useEffect(() => {
    fetchCollaborators();
    fetchTeamInfo();
  }, []);

  const { state } = editor || {};

  const handleRewrite = async (type) => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Magic Takes Time',
        description: 'Please wait, rewriting...',
      }),
    );
    const { to, from } = selection || {};
    const text = state.doc.textBetween(from, to, ' ');

    let prompt = '';

    if (type === 'humanize') {
      prompt = `When it comes to writing content, three factors are crucial, "perplexity", "burstiness", and "predictability." Perplexity measures the complexity of text. Separately, burstiness compares the variations of sentences. Finally, predictability is how likely someone is to guess the next sentence.Humans tend to write with greater burstiness, for example, with some longer or complex sentences alongside shorter ones. AI sentences tend to be more uniform. Therefore, when writing the following content I am going to ask you to create, I need it to have a good amount of perplexity and burstiness. I also need it to have a low amount of predictability. You must also write in [ENGLISH] only. Now, rewrite the text: [${text}].`;
    } else if (type === 'clarify') {
      prompt = `Clarify this text more by generating text that explains the core value and the core point of the paragraph in more specific ways. Include examples or research if you need to and write in ways that can clarify the point to anyone without any industry knowledge. Make sure it flows with the paragraph and make sure it is written in the same tone and voice. Do not use complex sentences and complicated words. Keep it simple but unique. Do not start by saying "The core value of this paragraph". Write it as if you are rewriting the paragraph but with more clarification. Here is the text: [${text}].`;
    } else if (type === 'shorten') {
      prompt = `Shorten this [${text}]. Make it engaging but concise and make it flow better.  Must be 100% human writing style, fix grammar issues. All output shall be in [ENGLISH]. the text to shorten is [${text}].`;
    } else if (type === 'exemplify') {
      prompt = `Please exemplify this text: "${text}"`;
    } else if (type === 'explain') {
      prompt = `Please explain this text: "${text}"`;
    } else if (type === 'summarize') {
      prompt = `Please ignore all previous instructions. You are an Extreme summary generator, a new form of extreme summarization tool for paragraphs.
      generation involves high source compression, removes stop words and summarizes the paragraph whilst retaining meaning.
      You will examine this text: [${text}] and come up with the best summary in [ENGLISH].
      The result will be the shortest possible summary that retains all of the original meaning and context of the paragraph.
      `;
    } else {
      prompt = `As a skilled marketer and copywriter, rewrite the text in various formats: concise, coherent, engaging, persuasive, formal, informal, and detailed. Respond in English
      [${text}]`;
    }

    try {
      const description = await generateResponse(
        prompt,
        { max_token: 3000 },
        false,
      );

      editor.commands.deleteSelection();
      editor.commands.insertContentAt(from, description);

      dispatch(setLoader({ isLoading: false }));
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
    }
  };

  const handleWriteParagraph = async (data) => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Magic Takes Time',
        description: 'Please wait, writing the paragraph...',
      }),
    );
    const { $anchor } = selection || {};
    const { parentOffset, pos, parent } = $anchor || {};
    const { content } = parent || {};
    const { size: nodeSize } = content || {};
    const parentIndex = pos - parentOffset;
    const toIndex = parentIndex + nodeSize;
    try {
      const response = await generateResponse(
        `Generate Paragraph for this heading: "${data}"`,
        {},
        false,
      );

      const rewriteDescription = response.split('\n\n');

      const description = rewriteDescription[rewriteDescription.length - 1];

      editor.commands.insertContentAt(toIndex, `<p>${description}</p>`);
      dispatch(setLoader({ isLoading: false }));
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
    }
  };

  // const handleContentSideBarToggle = (source) => {
  //   switch (source) {
  //     case 'comments': {
  //       if (showDocHealth && !showComments) {
  //         setShowDocHealth(false);
  //         setShowEditor(false);
  //         setShowComments(true);
  //         return;
  //       }
  //       setContentSidebarCollapse(!contentSidebarCollapse);
  //       setShowComments(!showComments);
  //       break;
  //     }
  //     case 'dochealth': {
  //       if (showComments && !showDocHealth) {
  //         setShowDocHealth(true);
  //         setShowEditor(false);
  //         setShowComments(false);
  //         return;
  //       }
  //       setContentSidebarCollapse(!contentSidebarCollapse);
  //       setShowDocHealth(!showDocHealth);
  //       break;
  //     }
  //     case 'editor': {
  //       if (showDocHealth && !showComments) {
  //         setShowDocHealth(false);
  //         setShowEditor(true);
  //         setShowComments(false);
  //         return;
  //       }
  //       setContentSidebarCollapse(!contentSidebarCollapse);
  //       setShowComments(!showComments);
  //       break;
  //     }
  //   }
  // };


const handleContentSideBarToggle = (source) => {
  switch (source) {
    case 'comments': {
      if (showDocHealth || showEditor) {
        setShowDocHealth(false);
        setShowEditor(false);
        setShowComments(true);
        return;
      }
      setContentSidebarCollapse(!contentSidebarCollapse);
      setShowComments(!showComments);
      break;
    }
    case 'dochealth': {
      if (showComments || showEditor) {
        setShowDocHealth(true);
        setShowEditor(false);
        setShowComments(false);
        return;
      }
      setContentSidebarCollapse(!contentSidebarCollapse);
      setShowDocHealth(!showDocHealth);
      break;
    }
    case 'editor': {
      if (!showEditor) {
        setShowDocHealth(false);
        setShowEditor(true);
        setShowComments(false);
        setContentSidebarCollapse(false);

      }
      break;
    }
    case 'close': {
      setShowDocHealth(false);
      setShowEditor(false);
      setShowComments(false);
      setContentSidebarCollapse(true); // Optionally collapse the sidebar
      break;
    }
    default:
      break;
  }
};
  const refetchContent = async (updateRequired = true) => {
    try {
      const response = await getDocument(documentId);
      const { data } = response || {};
      const { document } = data || {};
      const { content } = document || {};

      if (updateRequired) {
        setDocument(document);
        const transformedValue = commentTransformation(content);
        editor?.commands.setContent(transformedValue, true);
      } else {
        setDocumentCopy(document);
      }
      setLastUpdated({
        updatedAt: document?.updated_at,
        updatedBy: document?.last_updated_by,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = () => {
    setUrl(editor.getAttributes('link').href);
    setLinkModalOpen(true);
  };

  const closeModal = () => {
    setLinkModalOpen(false);
    setUrl('');
  };

  const saveLink = () => {
    if (url) {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url, target: '_blank' })
        .run();
    } else {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
    }
    editor.commands.blur();
    closeModal();
  };

  const removeLink = () => {
    editor.chain().focus().extendMarkRange('link').unsetLink().run();
    closeModal();
  };

  const handleCopyLink = () => {
    copyToClipboard(editor.getAttributes('link').href);
    renderSuccess('Link Copied');
  };

  const handleExpand = async () => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Magic Takes Time',
        description: 'Please wait...',
      }),
    );
    const { to, from } = selection || {};
    const text = state.doc.textBetween(from, to, ' ');

    try {
      const response = await generateResponse(
        `Expand on this [${text}] and explain it in more depth, Make sure it flows with the rest of the text and uses the same tone and voice. Write at least a few sentences to expand on the main [HIGHLIGHTED TEXT]'s core point. `,
        { max_token: 500 },
        false,
      );

      const rewriteDescription = response.split('\n\n');

      const description = rewriteDescription[rewriteDescription.length - 1];

      editor.commands.insertContentAt(to, ` ${description}`);
      dispatch(setLoader({ isLoading: false }));
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
    }
  };

  const addComment = async (comment, mentions) => {
    let commentId = uuid();
    const newVal = editor.isActive('comment');
    if (newVal) {
      const parsedComment = editor.getAttributes('comment').comment;
      if (parsedComment && parsedComment.id) commentId = parsedComment.id;
    }

    const commentInfo = {
      user_id: userId,
      uuid: commentId,
      message: comment,
      mentions,
    };

    setComments((prevValue) => ({
      ...prevValue,
      [commentId]: [commentInfo],
    }));
    const newPosition = selection.to + 1;

    await postComment(documentId, commentInfo);

    fetchComments();

    editor
      .chain()
      .focus()
      .setComment(
        JSON.stringify({
          id: commentId,
          class: 'comment',
        }),
      )
      .run();
    editor.commands.setTextSelection(newPosition);
    editor.commands.toggleComment();
    editor.commands.insertContentAt(newPosition, ' ');
  };

  const handleUpdateDocument = async (uuid) => {
    const editorHTML = editor.getHTML();
    const regex = new RegExp(
      '<span[^>]*\\bid=["\']' + uuid + '["\'][^>]*>(.*?)</span>',
      'g',
    );

    const documentWithoutComment = editorHTML.replace(regex, '$1');

    const response = await updateDocument(documentId, {
      ...userDocument,
      content: documentWithoutComment,
    });

    const { data } = response || {};
    const { document: resDocument } = data || {};
    const { content } = resDocument || {};

    const transformedValue = commentTransformation(content);
    editor?.commands.setContent(transformedValue, true);
  };

  const resolveComment = async (userId, uuid) => {
    await changeStatus(documentId, {
      user_id: userId,
      uuid,
    });

    handleUpdateDocument(uuid);
  };

  const deleteComment = async (userId, uuid) => {
    await deleteCommentService(documentId, {
      data: {
        user_id: userId,
        uuid,
      },
    });

    handleUpdateDocument(uuid);
  };

  return {
    comments,
    setComments,
    documentCopy,
    contentSidebarCollapse,
    showComments,
    lastUpdated,
    collaborators,
    documentOwner,
    hoverId,
    setHoverId,
    selection,
    docHealth,
    setDocHealth,
    url,
    setUrl,
    linkModalOpen,
    handleRewrite,
    handleWriteParagraph,
    showDocHealth,
    handleContentSideBarToggle,
    refetchContent,
    openModal,
    closeModal,
    saveLink,
    removeLink,
    handleCopyLink,
    handleExpand,
    addComment,
    resolveComment,
    deleteComment,
    editor,
    fetchComments,
    access,
    collaborator,
    docHealthLoading,
    showEditor,
    setSelection
  };
}
