import React, { useContext } from 'react';
import { ModalContext } from 'layouts/LandingLayout/LandingLayout';
// import Icon1 from '../../../assets/landingpage/Frame 1618867779.svg';
import Icon2 from '../../../assets/landingpage/Frame 1618867780.svg';
import Icon3 from '../../../assets/landingpage/Frame 1618867781.svg';
import Icon4 from '../../../assets/landingpage/Frame 1618867782.svg';
// import Icon5 from '../../../assets/landingpage/Frame 1618867783.svg';
// import Icon6 from '../../../assets/landingpage/Frame 1618867784.svg';
import Icon7 from '../../../assets/landingpage/Frame 1618867785.svg';
// import Icon8 from '../../../assets/landingpage/Frame 1618867786.svg';
// import Icon9 from '../../../assets/landingpage/Frame 1618867787.svg';
// import Icon10 from '../../../assets/landingpage/Frame 1618867788.svg';
// import Icon11 from '../../../assets/landingpage/Frame 1618867789.svg';
// import Icon12 from '../../../assets/landingpage/Frame 1618867790.svg';
// import Icon13 from '../../../assets/landingpage/Frame 1618867791.svg';
// import Icon14 from '../../../assets/landingpage/Frame 1618867792.svg';
import Icon15 from '../../../assets/landingpage/Frame 1618867793.svg';
// import Icon16 from '../../../assets/landingpage/Frame 1618867794.svg';

import Box from 'ui/Box/Box';
import Flex from 'ui/Flex/Flex';
import Text from 'ui/Text/Text';
import Button from 'ui/Button/Button';

import Styles from './PublishSection.module.scss';
import { useNavigate } from 'react-router-dom';

const images = [
  // Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon7,
  Icon15,
  Icon2,
  Icon3,
  Icon4,
  Icon7,
  Icon15,
  Icon2,
  Icon3,
  Icon4,
  Icon7,
  Icon15,
  Icon2,
  Icon3,
  Icon4,
  Icon7,
  Icon15,
  // Icon5,
  // Icon6,
  // Icon8,
  // Icon9,
  // Icon10,
  // Icon11,
  // Icon12,
  // Icon13,
  // Icon14,
  // Icon16,
];

const PublishSection = () => {
  const { modalViewHandler, setModalType } = useContext(ModalContext);
  const navigate = useNavigate();

  return (
    <Box
      as="section"
      id="PublishSection"
      direction="column"
      align="center"
      gap="48"
      color="transparent"
      css={{ textAlign: 'center' }}
      className={Styles.section_container}
    >
      <Flex direction="column" align="center" gap="16">
        <Text as="h2" heading="1" className={Styles.section_heading}>
          <span className={Styles.section_heading__publish}> Publish </span>
          directly to
          <br />
          your channels
        </Text>
        <Text
          as="p"
          size="lg"
          css={{ color: '#334155' }}
          className={Styles.description}
        >
          Still cross-posting manually? Ditch the copy-paste hustle and automate
          <br />
          your publishing across CMS, socials, RSS, and other channels.
        </Text>
      </Flex>
      <Flex className={Styles.Fog} direction="column" gap="16">
        <Flex justify="center" gap="16" className={Styles.Fog__imgTicker}>
          {images.map((I, index) => (
            <div key={index} className={Styles.Fog_Channels}>
              <img src={I} alt="images" />
            </div>
          ))}
        </Flex>
        <Flex justify="center" gap="16" className={Styles.Fog__imgTicker_2}>
          {images.map((I, index) => (
            <div key={index} className={Styles.Fog_Channels}>
              <img src={I} alt="images" />
            </div>
          ))}
        </Flex>
      </Flex>
      <button
        onClick={() => navigate('sign-up')}
        className={Styles.get_started_btn}
      >
        Start your FREE Trial
      </button>
    </Box>
  );
};

export default PublishSection;
