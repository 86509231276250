import axios from 'axios';
import { API_V1 } from 'constants';
import authHeader from 'services/authHeader';
import { redirect } from 'react-router-dom';
import store from '../redux/store';
import { logout } from '../redux/slices/auth';
import { error as renderError } from 'helpers/toaster.js';
import { SUBSCRIPTION, SUBSCRIBE_PLAN, UPDATE_PROFILE } from 'constants';

const axiosClient = axios.create();

axiosClient.defaults.baseURL = `${process.env.REACT_APP_URL}/${API_V1}/`;

axiosClient.interceptors.request.use(
  (config) => {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers['Content-Type'] = config.headers['Content-Type']
      ? config.headers['Content-Type']
      : 'application/json';
    config.headers.Accept = 'application/json';
    if (process.env.NODE_ENV === 'development') {
      config.headers['ngrok-skip-browser-warning'] = true;
      config.headers['bypass-tunnel-reminder'] = true;
    }
    Object.assign(config.headers, authHeader());
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { data, status } = error.response || {};
    const { error_code, error: errorMessage } = data || {};
    if (
      Number(status) === 401 &&
      [
        'Signature has expired',
        'revoked token',
        'nil user',
        "Couldn't find an active session!",
      ].includes(errorMessage)
    ) {
      localStorage.removeItem('user');
      redirect('/');
      if (
        [
          'Signature has expired',
          'revoked token',
          "Couldn't find an active session!",
        ].includes(errorMessage)
      ) {
        renderError(errorMessage, 'revoked-token');
      }
      if (error.response.config.url !== '/logout')
        store.dispatch(logout({ showToast: false }));
    }
    if (Number(error_code) === 1001) {
      if (
        !window.location.pathname.includes(SUBSCRIPTION) &&
        !window.location.pathname.includes(UPDATE_PROFILE)
      ) {
        window.open(`/${SUBSCRIBE_PLAN}`, '_self');
      }
    }
    return Promise.reject(error);
  },
);

// All request will wait 2 seconds before timeout
// axiosClient.defaults.timeout = 30000;

// axiosClient.defaults.withCredentials = true;
axiosClient.defaults.withCredentials = process.env.NODE_ENV !== 'development';

export function getRequest(URL, responseType = '') {
  return axiosClient
    .get(`/${URL}`, {
      responseType,
    })
    .then((response) => response);
}

export function postRequest(URL, payload, headers) {
  return axiosClient
    .post(`/${URL}`, payload, {
      headers: headers || {},
    })
    .then((response) => response);
}

export function patchRequest(URL, payload, headers) {
  return axiosClient
    .patch(`/${URL}`, payload, {
      headers: headers || {},
    })
    .then((response) => response);
}

export function putRequest(URL, payload) {
  return axiosClient.put(`/${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL, payload) {
  return axiosClient.delete(`/${URL}`, payload).then((response) => response);
}
