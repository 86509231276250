import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import TagsInput from 'react-tagsinput';
import './TagInput.scss';
import 'react-tagsinput/react-tagsinput.css';

export default function TagInput({
  name,
  tags,
  onChange,
  placeholder,
  label,
  disabled,
  isRequired,
  showError = false,
}) {
  // const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const [inputValue, setInputValue] = useState('');

  const handleBlur = () => {
    if (inputValue.trim() !== '') {
      onChange([...tags, inputValue.trim()]);
      setInputValue('');
    }
  };

  return (
    <Form.Group className={'tags_input'}>
      {label && (
        <Form.Label className="Input_label">
          {label}
          {isRequired && <span className="text-danger"> *</span>}
        </Form.Label>
      )}
      <TagsInput
        // inputProps={{ placeholder }}
        value={tags}
        onChange={(newTags) => {
          setInputValue('');
          onChange(newTags);
        }}
        inputValue={inputValue}
        onChangeInput={(value) => setInputValue(value)}
        inputProps={{
          placeholder: tags?.length === 0 ? placeholder : '',
        }}
        disabled={disabled}
        addOnBlur
        onBlur={handleBlur}
      />
      {/* {showError  &&  (
        <div className="error mt-2">
          {errors[name]}
        </div>
       )}   */}
    </Form.Group>
  );
}
