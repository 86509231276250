import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const FormikDatePicker = ({ field, form, ...props }) => (
  <Datepicker
    // dateFormat="dd/MM/yyyy"
    {...field}
    {...props}
    selected={field.value}
    onChange={(newValue) => {
      form.setFieldValue(field.name, newValue);
    }}
    placeholderText="Select date"
    showPopperArrow={false}
    showMonthDropdown
    showYearDropdown
    dropdownMode="select"
  />
);
export default FormikDatePicker;
