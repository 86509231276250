import { postRequest, putRequest } from 'helpers/axios';
const BASE_URL = '';

const requestResetPassword = (body) => {
  return postRequest(BASE_URL + `password`, body);
};

const resetPassword = (body) => {
  return putRequest(BASE_URL + `password`, body);
};

const passwordService = {
  requestResetPassword,
  resetPassword,
};

export default passwordService;
