import React, { useState } from 'react';
import Styles from './Footer.module.scss';
import Image from '../../../assets/landingpage/learn_frame.svg';
import Button1 from '../../../assets/landingpage/svg/Frame (1).svg';
import Button2 from '../../../assets/landingpage/svg/Frame (2).svg';
import Button3 from '../../../assets/landingpage/svg/Frame (3).svg';
import Button4 from '../../../assets/landingpage/svg/Frame (4).svg';
import Button5 from '../../../assets/landingpage/svg/insta.svg';
import Button6 from '../../../assets/landingpage/svg/youtube.svg';
import Logo from '../../../assets/landingpage/svg/Group 57 (1).svg';
import { subscribeToNewsletter } from 'services/newsletter';
import { toast } from 'react-toastify';

const Footer = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate inputs
    if (!name || !email) {
      setError('Both name and email are required.');
      return;
    }

    setError('');

    try {
      await subscribeToNewsletter(name, email);
      toast.success('Subscribed successfully!');
    } catch (error) {
      toast.error('Failed to subscribe. Please try again later.');
    }
  };

  return (
    <div className={Styles.footer}>
      <div className={Styles.footer__top}>
        <div className={Styles.footer__top__left}>
          <p className={Styles.footer__top__left__title}>
            Special Limited Time Offer
          </p>
          <p className={Styles.footer__top__left__heading}>
            Start Using AI For Content Marketing In 7 Days with our Free AI
            Crash Course
          </p>
          <a
            href="https://vocablecourse.carrd.co/"
            target="_blank"
            className={Styles.footer__top__left__btn}
          >
            Send Me The Free Email Course
          </a>
        </div>
        <div className={Styles.footer__top__right}>
          <div className={Styles.footer__top__right__image}>
            <img src={Image} alt="iamge" />
          </div>
        </div>
      </div>
      <div className={Styles.footer__bottom}>
        <div className={Styles.footer__bottom__left}>
          <div>
            <img src={Logo} alt="logo" />
          </div>
          <p></p>
          <span>
            Join Our Newsletter for Exclusive Content, AI Tips, and Updates
            Delivered Straight to Your Inbox!
          </span>
          <form onSubmit={handleSubmit}>
            <div className={Styles.footer__bottom__left__input}>
              <input
                type="text"
                placeholder="Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit" className={Styles.subscribe_btn}>
              Subscribe to Newsletter
            </button>
          </form>
        </div>

        <div className={Styles.footer__bottom__right}>
          <p className={Styles.footer__bottom__right__official}>
            Let’s Make it Official
          </p>
          <div className={Styles.footer__bottom__right__btns}>
            <a
              href="https://www.linkedin.com/company/vocable-ai/"
              target="blank"
            >
              <img src={Button1} alt="" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100091572124135"
              target="blank"
            >
              <img src={Button2} alt="" />
            </a>
            {/* <a>
              <img src={Button3} alt="" />
            </a>
            <a>
              <img src={Button4} alt="" />
            </a> */}
            <a
              href="https://www.instagram.com/vocable.ai/?hl=en"
              target="blank"
            >
              <img src={Button5} alt="" />
            </a>
            <a
              href="https://www.youtube.com/@Vocable.ai20/featured"
              target="blank"
            >
              <img src={Button6} alt="" />
            </a>
          </div>
          <p className={Styles.footer__bottom__right__text}>
            Let's Connect! Follow Us on Social Media for Daily Inspiration,
            Tips, and More.
          </p>
          <div className={Styles.footer__bottom__right__privicy}>
            <a href="https://vocable.ai/privacy">Privacy Policy</a>
            <a href="https://vocable.ai/terms">Terms & Conditions</a>
          </div>
        </div>
      </div>

      <div className={Styles.footer__copy}>
        <div className={Styles.footer__copy__line}></div>
        <span>© Copyright 2024 | Vocable</span>
      </div>
    </div>
  );
};

export default Footer;
