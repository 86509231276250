import { Card } from 'react-bootstrap';
import BSButton from 'Components/Button/BSButton';
import Styles from './ProjectIntegrationsCard.module.scss';

const STATUS = {
  draft: 'draft',
  publish: 'publish',
};

const ProjectIntegrationsCard = ({ integration, onClickIntegration }) => {
  return (
    <Card className={Styles.CardIntegration}>
      <div className={Styles.CardImageAndTitle}>
        <Card.Img
          src={`/Images/${integration.type.toLowerCase()}.png`}
          className={Styles.CardImage}
        />
        <Card.Title className={Styles.CardTitle}>{integration.name}</Card.Title>
      </div>
      <Card.Body className={Styles.CardBody}>
        <BSButton
          classList="button"
          ButtonText="Draft"
          variant="light"
          onClick={() => onClickIntegration(integration.id, STATUS.draft)}
        />
        <BSButton
          classList="button addBtn"
          ButtonText="Publish"
          variant="light"
          onClick={() => onClickIntegration(integration.id, STATUS.publish)}
        />
      </Card.Body>
    </Card>
  );
};

export default ProjectIntegrationsCard;
