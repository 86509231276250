import { styled } from 'styles/stitches/stitches.config';

const Flex = styled('div', {
  display: 'flex',
  variants: {
    direction: {
      row: {
        flexDirection: 'row',
      },
      column: {
        flexDirection: 'column',
      },
      rowReverse: {
        flexDirection: 'row-reverse',
      },
      columnReverse: {
        flexDirection: 'column-reverse',
      },
    },
    align: {
      start: {
        alignItems: 'flex-start',
      },
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'flex-end',
      },
    },
    justify: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
      between: {
        justifyContent: 'space-between',
      },
      around: {
        justifyContent: 'space-around',
      },
      evenly: {
        justifyContent: 'space-evenly',
      },
    },
    wrap: {
      noWrap: {
        flexWrap: 'nowrap',
      },
      wrap: {
        flexWrap: 'wrap',
      },
      wrapReverse: {
        flexWrap: 'wrap-reverse',
      },
    },
    gap: {
      8: {
        gap: '$8',
      },
      16: {
        gap: '$16',
      },
      24: {
        gap: '$24',
      },
      32: {
        gap: '$32',
      },
      48: {
        gap: '$48',
      },
      64: {
        gap: '$64',
      },
      72: {
        gap: '$72',
      },
      136: {
        gap: '$136',
      },
    },
  },
  defaultVariants: {
    direction: 'row',
  },
});

export default Flex;
