import React from 'react';
import Styles from './SectionHeader.module.scss';
import CircularIcon from 'Components/Icons/CircularIcon';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { DashboardSelectStyles } from './dashboardSelectOptions';
const SectionHeader = ({
  icon,
  name,
  route,
  count,
  view,
  selectedValue,
  setSelectedValue,
  options,
  hideLink = false,
}) => {
  return (
    <div
      className={`${Styles.Dashboard__TableContainer_Header} drop-down-projects`}
    >
      <div className={Styles.Dashboard__TableContainer_Header_TitleContainer}>
        <CircularIcon background="#F2FBFB" width="20px" height="20px">
          {icon}
        </CircularIcon>
        <p
          className={
            Styles.Dashboard__TableContainer_Header_TitleContainer_Title
          }
        >
          {name}
        </p>
        <div className={Styles.counter}>{count}</div>
        {options && (
          <Select
            isSearchable={false}
            classNamePrefix={'DashboardCardsSelect_all_projects'}
            className={'DashboardCardsSelect_all_projects'}
            styles={DashboardSelectStyles}
            placeholder="General View"
            value={options.find((option) => option.value === selectedValue)}
            options={options}
            onChange={(newValue) => {
              setSelectedValue(newValue.value);
            }}
          />
        )}
      </div>
      {!hideLink && (
        <Link
          className={Styles.Dashboard__TableContainer_Header_Navigation}
          to={route}
        >
          <span>{view || 'View All'}</span>
        </Link>
      )}
    </div>
  );
};

export default SectionHeader;
