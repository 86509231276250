import BSButton from 'Components/Button/BSButton';
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import styles from './EmailModal.module.scss';

export const EmailModal = ({ isOpen, onClose, sendEmail }) => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSend = () => {
    // Call the send email function passed from parent component
    sendEmail(subject, message);
    // Clear fields and close modal
    closeModal();
  };

  const closeModal = () => {
    setSubject('');
    setMessage('');
    onClose();
  };

  return (
    <Modal size="lg" show={isOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title className={styles.title_email}>Send Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="subject">
            <Form.Label className={styles.labelClass}>Subject:</Form.Label>
            <Form.Control
              type="text"
              className={styles.no_focus}
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="message">
            <Form.Label className={styles.labelClass}>Message:</Form.Label>
            <Form.Control
              className={styles.no_focus}
              as="textarea"
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <BSButton
          onClick={closeModal}
          ButtonText="Close"
          variant="light"
          classList={
            'btn ' + styles.modalFooterBtn + ' ' + styles.modalFooterBtn_Light
          }
        />
        <BSButton
          onClick={handleSend}
          ButtonText="Send"
          variant="dark"
          disabled={message.trim().length === 0 || subject.trim().length === 0}
          classList={'secondary_btn'}
        />
      </Modal.Footer>
    </Modal>
  );
};
