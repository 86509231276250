import { patchRequest } from 'helpers/axios';
const BASE_URL = '';

const uploadImage = async (documentId, body) => {
  const response = await patchRequest(
    BASE_URL + `documents/${documentId}`,
    body,
    {
      'content-type': 'multipart/form-data',
    },
  );
  return response?.data;
};

const imageService = {
  uploadImage,
};

export default imageService;
