import { getRequest } from 'helpers/axios';

// ##### ##### ##### ##### #####   GET   ##### ##### ##### ##### ##### //

export const getTemplates = () => {
  return getRequest('templates');
};

export const getCategories = () => {
  return getRequest('categories');
};

export const getTemplate = (templateId) => {
  return getRequest(`templates/${templateId}`);
};
