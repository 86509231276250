import React, { useEffect, useState } from 'react';
import ContentIdeaListItem from 'Components/ContentIdeaListItem/ContentIdeaListItem';
import NoHeadlineView from 'Components/NoHeadlineView/NoHeadlineView';
import { LINK_REGEX } from 'constants';
import { generateResponse } from 'helpers/openAI';
import { error as renderError } from 'helpers/toaster.js';
import { Button } from 'react-bootstrap';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { setLoader } from 'redux/slices/loader';
import { extractContent } from 'services/documentService';
import AISToolInputSection from './AISToolInputSection';
import styles from './AITool.module.scss';
import PageHeader from 'Components/PageHeader/PageHeader';
import userListEventsService from 'services/userListEventsService';

const AITool = () => {
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [tags, setTags] = useState([]);
  const [AISData, setAISData] = useState([]);
  const [file, setFile] = useState();
  const [content, setContent] = useState('');
  const [url, setUrl] = useState('');
  const [selectedOption, setSelectedOption] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    let isButtonDisabled = false;

    if (selectedOption === 'file') {
      isButtonDisabled = file;
    } else if (selectedOption === 'pastedUrl') {
      isButtonDisabled = LINK_REGEX.test(url);
    } else {
      isButtonDisabled = content?.trim() && content?.length >= 200;
    }

    setIsSubmitButtonDisabled(!isButtonDisabled);
  }, [file, content, url, selectedOption]);

  const handleGenerateButtonClick = async () => {
    const getSummary = async (userInput, options) => {
      return await generateResponse(
        `Act as a translator specialized in making short text. The writing style is to-the-point and straightforward, with easy to read sentences aimed at persons of 12 years old. Start with a neutral and concise title of exact three words without quotation marks or spelling marks at all. Then rewrite and summarise the input into logical paragraphs. Start each paragraph with a title consisting of exactly 3 words without quotation marks. Start each paragraph with the main idea in the first sentence. When all input is summarized, state for the user the main takeaways in exactly 3 bulletpoints. Answer in html. Do not include the html, head and body tags. The text to rewrite and to summarize can be found here: ${userInput}.`,
        options,
        false,
      );
    };

    const getAnalysis = async (userInput, options) => {
      return await generateResponse(
        `Analyze the following content and give me a detailed description of your analysis for each of these properties: Topic Overview, Content format, Tone and voice, keywords usage, SEO, Sources, content length, call to action. Answer in html. Do not include the html, head and body tags. Here is the content: ${userInput}`,
        options,
        false,
      );
    };

    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Magic Takes Time',
        description: 'Please wait, this can take a few seconds',
      }),
    );

    try {
      let summaryPromise, analizePromise;

      if (selectedOption === 'file') {
        const formData = new FormData();
        formData.append('file', file);
        const fileContent = await extractContent(formData);
        const { data } = fileContent || {};
        const { data: contentData } = data || {};

        summaryPromise = getSummary(`[${contentData}]`, {
          max_token: 500,
        });
        analizePromise = getAnalysis(`[${contentData}]`, {
          max_token: 500,
        });
      } else if (selectedOption === 'pastedContent') {
        summaryPromise = getSummary(`[${content}]`, {
          max_token: 500,
        });
        analizePromise = getAnalysis(`[${content}]`, {
          max_token: 500,
        });
      } else if (selectedOption === 'pastedUrl') {
        summaryPromise = getSummary('', {
          max_token: 500,
          url,
        });
        analizePromise = getAnalysis('', {
          max_token: 500,
          url,
        });
      }

      const [summary, analyze] = await Promise.all([
        summaryPromise,
        analizePromise,
      ]);

      const transformedAISData = [summary, `${analyze}`]?.map(
        (title, index) => {
          return {
            id: Math.random(),
            title,
            description: '',
            searchIntent: '',
            outline: '',
          };
        },
      );

      const AISResponse = transformedAISData.filter(
        (value) => value.title.length > 15,
      );

      setAISData(AISResponse || []);
    } catch (error) {
      renderError(error);
    } finally {
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    }

    // notify userlist platform that user used this tool
    userListEventsService.updateUserListEvent({ name: 'tools_analyze' });
  };

  const handleClear = () => {
    setTags(
      tags.map((tag) => {
        return {
          ...tag,
          selected: false,
        };
      }),
    );

    setFile();
    setContent('');
    setUrl('');
    setAISData([]);
  };

  return (
    <section className={'d-flex flex-column gap-4 ' + styles.AIBSTool}>
      <div className={styles.AIBSTool__Header}>
        <PageHeader
          title="Analyze at a Glance"
          subtitle="No matter the source, break down any content. Analyze style, tone, highlights, and extract a summary."
        />
      </div>
      <div className={'d-flex ' + styles.AIBSTool__Wrapper}>
        <section
          className={
            'd-flex flex-column justify-content-between ' +
            styles.AIBSTool__Wrapper_InputSection
          }
        >
          <AISToolInputSection
            content={content}
            url={url}
            file={file}
            setContent={setContent}
            setUrl={setUrl}
            setFile={setFile}
            isSubmitButtonDisabled={isSubmitButtonDisabled}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />

          <div
            className={
              'p-3 d-flex justify-content-between ' +
              styles.AIBSTool__Wrapper_InputSection_Footer
            }
          >
            <Button
              onClick={handleGenerateButtonClick}
              variant="dark"
              className={styles.AIBSTool__Wrapper_InputSection_Footer_btnDark}
              disabled={isSubmitButtonDisabled}
            >
              <span>Generate Summary</span>
              <AiOutlineArrowRight color="#ffff" />
            </Button>
            <Button
              onClick={handleClear}
              variant="light"
              className={styles.AIBSTool__Wrapper_InputSection_Footer_btnLight}
            >
              Clear All
            </Button>
          </div>
        </section>

        {AISData.length ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {AISData.map((aisData, index) => {
              return (
                <section
                  key={index}
                  className={
                    'd-flex flex-column ' + styles.AIBSTool__ContentSection
                  }
                  style={{ width: '100%' }}
                >
                  <div
                    className={
                      'd-flex justify-content-between align-items-center ' +
                      styles.AIBSTool__ContentSection_Header
                    }
                    style={{ borderTop: index ? '1px solid #e4e7ec' : '' }}
                  >
                    <h3 className="Heading-sm">
                      {!index ? 'Summary' : 'Analysis'}
                    </h3>
                  </div>
                  <div className={styles.AIBSTool__ContentSection_Content}>
                    <ul className="d-flex flex-column gap-3">
                      <ContentIdeaListItem
                        key={aisData.id}
                        headline={aisData.title}
                        index={index}
                      />
                    </ul>
                  </div>
                </section>
              );
            })}
          </div>
        ) : (
          <section
            className={'d-flex flex-column ' + styles.AIBSTool__ContentSection}
          >
            <div className={styles.AIBSTool__ContentSection_Content}>
              <NoHeadlineView />
            </div>
          </section>
        )}
      </div>
    </section>
  );
};

export default AITool;
