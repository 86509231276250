import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProjectTile from '../ProjectTile/ProjectTile';
import Styles from './ProjectTiles.module.scss';

const ProjectTiles = ({
  projects,
  userId,
  next,
  hasMore,
  handleProjectEdit,
  handleProjectDelete,
  projectAllCard,
}) => {
  return (
    <div>
      <InfiniteScroll
        dataLength={projects.length}
        next={next}
        hasMore={hasMore}
        className={Styles.Dashboard__compaigns}
      >
        {projectAllCard
          ? projects.map((project, index) => {
              return (
                <ProjectTile
                  key={project.id}
                  project={project}
                  userId={userId}
                  handleEdit={() => handleProjectEdit(project)}
                  handleDelete={() => handleProjectDelete(project.id)}
                />
              );
            })
          : projects.slice(0, 4).map((project, index) => {
              return (
                <ProjectTile
                  key={project.id}
                  project={project}
                  userId={userId}
                  handleEdit={() => handleProjectEdit(project)}
                  handleDelete={() => handleProjectDelete(project.id)}
                />
              );
            })}
      </InfiniteScroll>
    </div>
  );
};

export default ProjectTiles;
