import { useQuery } from '@tanstack/react-query';
import { getDocumentTotalCountsByDuration } from 'services/documentService';
import keys from 'constants/queryKeys';

const useTotalCountDocuments = (duration) => {
  const query = useQuery({
    queryKey: keys.TotalCountDocuments(duration),
    queryFn: () => getDocumentTotalCountsByDuration(duration),
  });

  return {
    ...query,
    ...query?.data?.data,
  };
};

export default useTotalCountDocuments;
