import React from 'react';

import ImageView from '../Image/ImageView';
import { getNameInitials } from '../../helpers';

const Collaborators = ({ editors, className }) => {
  return (
    <>
      {editors?.slice(0, 3)?.map(({ id, avatar, name }) =>
        avatar ? (
          <ImageView
            key={id}
            src={avatar}
            class={`rounded-avatar-bordered ${className}`}
          />
        ) : (
          <div key={id} className={`rounded-collab ${className}`}>
            {getNameInitials(name)}
          </div>
        ),
      )}

      {editors.length > 3 && (
        <div className={`rounded-collab ${className}`}>{`+${
          editors.length - 3
        }`}</div>
      )}
    </>
  );
};

export default Collaborators;
