import styles from './RadioButton.module.scss';

function RadioButton({
  inline,
  type,
  name,
  id,
  value,
  checked,
  onChange,
  label,
  FullWidth,
  disabled,
  SideBarView,
  customLabel,
}) {
  return (
    <>
      <div className={`form-check-${inline} ` + styles.CheckboxWrapper}>
        <input
          // className={"form-check-input CheckboxInput "+props.classList+" " + styles.CheckboxInput}
          className={`form-check-input ${
            FullWidth ? styles.fullWidthRadioBtn : styles.CheckboxInput
          } ${customLabel && styles.fullWidthRadioBtn__ViewTop}`}
          type={type}
          name={name}
          id={id}
          value={value}
          checked={checked}
          onChange={({ target: { checked } }) =>
            onChange && onChange(label, type, checked ? 'add' : 'remove')
          }
          disabled={disabled}
        />
        <label
          className={`form-check-label CheckboxInputLabel ${
            styles.CheckboxInputLabel
          } ${SideBarView && styles.CheckboxInputLabel__sm} ${
            customLabel && styles.CheckboxInputLabel__CustomLabel
          }`}
          htmlFor={id}
          // dangerouslySetInnerHTML={{ __html: label }}
        >
          {label}
        </label>
      </div>
    </>
    // <Form.Check
    //   className={styles.RadioButtonInput}
    //   type={type}
    //   id={id}
    //   label={label}
    //   inline={inline}
    //   name={name}
    // />
  );
}

export default RadioButton;
