import { useQuery } from '@tanstack/react-query';
import { getProjectUsers } from 'services/projectService';
import keys from 'constants/queryKeys';

const useProjectUsers = ({ projectId }) => {
  const query = useQuery({
    queryKey: keys.ProjectUsers(projectId),
    queryFn: () => getProjectUsers(projectId),
    enabled: !!projectId,
  });

  return {
    ...query,
    ...query?.data?.data,
  };
};

export default useProjectUsers;
