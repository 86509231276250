import React from 'react';

import { Formik, ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap';
import FormikTextField from 'Components/Form/FormikTextField';
import BSButton from 'Components/Button/BSButton';

import * as Yup from 'yup';

import Styles from './Ideate.module.scss';

const IdeateForm = ({ onSubmit }) => {
  const initialValues = {
    prompt: '',
  };

  const promptSchema = Yup.object({
    prompt: Yup.string().trim().min(5).required('Prompt is a required field.'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={promptSchema}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {({ values, isValid, dirty }) => {
        return (
          <Form
            className={Styles.Ideate_Generator}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <p>Generate...</p>
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center gap-2">
                <FormikTextField
                  name="prompt"
                  type="string"
                  placeholder="e.g. Podcast episodes about AI and design"
                  classList="input"
                  inputWrapperClass="w-100"
                />
                <BSButton
                  classList={`secondary_btn`}
                  variant="dark"
                  ButtonText="Generate"
                  onClick={() => {
                    onSubmit(values);
                  }}
                  disabled={!(isValid && dirty)}
                />
              </div>
              <ErrorMessage
                name="prompt"
                component="div"
                className="error mt-2"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default IdeateForm;
