import React, { useState } from 'react';
import BSButton from 'Components/Button/BSButton';
import FormikCheckbox from 'Components/Form/FormikCheckbox';
import FormikTextField from 'Components/Form/FormikTextField';
import ImageView from 'Components/Image/ImageView';
import { EMAIL_REGEX, PASSWORD_FORMAT } from 'constants';
import { ErrorMessage, Formik } from 'formik';
import { getNameInitials } from 'helpers';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/slices/auth';
import { setLoader } from 'redux/slices/loader';
import userService from 'services/userService';
import * as Yup from 'yup';
import ProfileModalStyles from './ProfileModal.module.scss';

const ProfileModal = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [selectedImage, setSelectedImage] = useState(user.avatar);
  const [ImageFile, setImageFile] = useState(null);
  const [imageChanged, setImageChanged] = useState(false);
  const [selectedTab, setSelectedTab] = useState('general');
  const [showChangeProfile, setShowChangeProfile] = useState(false);

  const googleLogin = user.provider === 'google_oauth2';

  const initialValues = {
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    is_email_notification_enable: user.is_email_notification_enable,
    current_password: '',
    password: '',
    password_confirmation: '',
    role: user.role,
    company: user.company,
  };

  const generalTabValidationSchema = Yup.object({
    firstName: Yup.string().required('First name is required.'),
    lastName: Yup.string().required('Last name is required.'),
    email: Yup.string()
      .matches(EMAIL_REGEX, 'Invalid Email')
      .required('Email is required.'),
    is_email_notification_enable: Yup.boolean(),
    role: Yup.string().required('Role is required.'),
    company: Yup.string().required('Company information is required.'),
  });

  const passwordTabValidationSchemaObj = {
    password: Yup.string()
      .required('Password is required.')
      .min(8, 'Password must be at least 8 characters.')
      .max(18, 'Password must be at most 18 characters.')
      .matches(PASSWORD_FORMAT, {
        message:
          'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one symbol',
      }),
    password_confirmation: Yup.string()
      .required('Password confirmation is required.')
      .oneOf([Yup.ref('password')], 'Passwords must match.'),
  };

  if (!googleLogin) {
    passwordTabValidationSchemaObj.current_password = Yup.string().required(
      'Current password is required.',
    );
  }

  const passwordTabValidationSchema = Yup.object(
    passwordTabValidationSchemaObj,
  );

  const handleSubmit = async (values) => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Saving!',
        description: 'Please wait...',
      }),
    );
    let body = {};

    if (selectedTab === 'general') {
      const {
        firstName,
        lastName,
        email,
        is_email_notification_enable,
        role,
        company,
      } = values;
      body = {
        user: {
          email,
          first_name: firstName,
          last_name: lastName,
          is_email_notification_enable,
          role,
          company,
          avatar: imageChanged ? ImageFile : {},
        },
      };
    } else if (selectedTab === 'password') {
      const { current_password, password, password_confirmation } = values;
      body = {
        user: {
          ...(!googleLogin ? { current_password } : {}),
          password,
          password_confirmation,
        },
      };
    }
    try {
      const response = await userService.updateUser(body);
      dispatch(setLoader({ isLoading: false }));
      const { user } = response?.data || {};
      dispatch(actions.updateUser({ user }));
      renderSuccess(response?.data?.message);
      props.onHide();
    } catch (error) {
      renderError(error);
      dispatch(setLoader({ isLoading: false }));
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSelectedImage(reader.result);
      setImageChanged(true);
      setImageFile(file);
    };
  };

  const handleModalShow = () => {
    setSelectedImage(user.avatar);
    setImageChanged(false);
  };

  return (
    <Modal
      {...props}
      size="md"
      dialogClassName={ProfileModalStyles.ProfileModal}
      contentClassName={ProfileModalStyles.innerModalSection}
      aria-labelledby="contained-modal-title-vcenter"
      onShow={handleModalShow}
      centered
    >
      <Formik
        initialValues={initialValues}
        validationSchema={
          selectedTab === 'general'
            ? generalTabValidationSchema
            : passwordTabValidationSchema
        }
        validateOnMount={true}
        enableReinitialize={true}
      >
        {({ values, isValid, setErrors, errors, dirty, resetForm }) => {
          return (
            <section className={ProfileModalStyles.innerModalSection}>
              <Modal.Header closeButton>
                <Modal.Title
                  className={'Modal-Title  ' + ProfileModalStyles.ModalTitle}
                  id="contained-modal-title-vcenter"
                >
                  My profile
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className={ProfileModalStyles.ProfileModalBody}>
                <Tabs
                  defaultActiveKey="general"
                  className="mb-3"
                  onSelect={(eventKey) => {
                    setErrors({});
                    resetForm();
                    setSelectedTab(eventKey);
                  }}
                >
                  <Tab eventKey="general" title="General ">
                    <section
                      className={
                        'd-flex flex-column ' +
                        ProfileModalStyles.tabFlexWrapper
                      }
                    >
                      <Form
                        className={
                          'd-flex flex-column ' +
                          ProfileModalStyles.tabFlexWrapper
                        }
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <div
                          onMouseEnter={() => {
                            setShowChangeProfile(true);
                          }}
                          onMouseLeave={() => {
                            setShowChangeProfile(false);
                          }}
                          className={
                            'd-flex position-relative ' +
                            ProfileModalStyles.ProfileImageSection
                          }
                        >
                          {selectedImage ? (
                            <ImageView
                              class={
                                'img-fluid  ' + ProfileModalStyles.profileImage
                              }
                              alt="Profile Image"
                              src={selectedImage}
                            />
                          ) : (
                            <span
                              className={
                                'rounded-profile ' +
                                ProfileModalStyles.profileImage
                              }
                            >
                              {getNameInitials(user.name)}
                            </span>
                          )}
                          <input
                            className={ProfileModalStyles.profileImage_Input}
                            type="file"
                            name="myImage"
                            onChange={handleImageChange}
                            accept="image/*"
                          />
                          {showChangeProfile && (
                            <div
                              className={`d-flex justify-content-center align-items-center  ${ProfileModalStyles.profileImage_Input} ${ProfileModalStyles.profileImage_Input_text} `}
                            >
                              Change Profile
                            </div>
                          )}
                        </div>
                        <div>
                          <FormikTextField
                            Label="First Name"
                            name="firstName"
                            placeholder=""
                            classList="w-100 input"
                            isRequired
                          />
                          <ErrorMessage name="firstName">
                            {(msg) => <span className="error">{msg}</span>}
                          </ErrorMessage>
                        </div>
                        <div>
                          <FormikTextField
                            Label="Last Name"
                            name="lastName"
                            placeholder=""
                            classList="w-100 input"
                            isRequired
                          />
                          <ErrorMessage name="lastName">
                            {(msg) => <span className="error">{msg}</span>}
                          </ErrorMessage>
                        </div>

                        <div>
                          <FormikTextField
                            Label="Email"
                            name="email"
                            placeholder=""
                            classList="w-100 input"
                            isRequired
                          />
                          <ErrorMessage name="email">
                            {(msg) => <span className="error">{msg}</span>}
                          </ErrorMessage>
                        </div>

                        <div>
                          <FormikTextField
                            Label="Role"
                            name="role"
                            placeholder=""
                            classList="w-100 input"
                            isRequired
                          />
                          <ErrorMessage name="role">
                            {(msg) => <span className="error">{msg}</span>}
                          </ErrorMessage>
                        </div>

                        <div>
                          <FormikTextField
                            Label="Company Information"
                            name="company"
                            placeholder=""
                            classList="w-100 input"
                            isRequired
                          />
                          <ErrorMessage name="company">
                            {(msg) => <span className="error">{msg}</span>}
                          </ErrorMessage>
                        </div>

                        <FormikCheckbox
                          type="switch"
                          Label="Enable Email Notifications"
                          name="is_email_notification_enable"
                        ></FormikCheckbox>
                      </Form>
                    </section>
                  </Tab>
                  <Tab eventKey="password" title="Password">
                    <section
                      className={
                        'd-flex flex-column ' +
                        ProfileModalStyles.tabFlexWrapper
                      }
                    >
                      <Form
                        className={
                          'd-flex flex-column ' +
                          ProfileModalStyles.tabFlexWrapper
                        }
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <span className={ProfileModalStyles.ProfileFormHeading}>
                          Change Password
                        </span>
                        {!googleLogin && (
                          <div>
                            <FormikTextField
                              Label="Current Password *"
                              type="password"
                              name="current_password"
                              classList="input input__password"
                              value=""
                              // placeholder="&#183;&#183;&#183;&#183;&#183;&#183;&#183;"
                            />
                            <ErrorMessage name="current_password">
                              {(msg) => <span className="error">{msg}</span>}
                            </ErrorMessage>
                          </div>
                        )}

                        <div>
                          <FormikTextField
                            Label="New Password *"
                            name="password"
                            type="password"
                            classList="input input__password"
                            value=""
                            placeholder="&#183;&#183;&#183;&#183;&#183;&#183;&#183;"
                          />
                          <ErrorMessage name="password">
                            {(msg) => <span className="error">{msg}</span>}
                          </ErrorMessage>
                        </div>

                        <div>
                          <FormikTextField
                            Label="Confirm New Password *"
                            type="password"
                            name="password_confirmation"
                            classList="input input__password"
                            placeholder="&#183;&#183;&#183;&#183;&#183;&#183;&#183;"
                          />
                          <ErrorMessage name="password_confirmation">
                            {(msg) => <span className="error">{msg}</span>}
                          </ErrorMessage>
                        </div>
                      </Form>
                    </section>
                  </Tab>
                </Tabs>
              </Modal.Body>
              <Modal.Footer className={ProfileModalStyles.ProfileModalFooter}>
                <BSButton
                  onClick={props.onHide}
                  ButtonText="Cancel"
                  variant="light"
                  classList={
                    'btn ' +
                    ProfileModalStyles.modalFooterBtn +
                    ' ' +
                    ProfileModalStyles.modalFooterBtn_Light
                  }
                />
                <BSButton
                  onHide={props.onHide}
                  ButtonText="Save Changes"
                  variant="dark"
                  classList={'btn button secondary_btn'}
                  disabled={!((dirty || imageChanged) && isValid)}
                  onClick={() => handleSubmit(values)}
                />
              </Modal.Footer>
            </section>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ProfileModal;
