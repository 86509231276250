import { styled } from 'styles/stitches/stitches.config';

import Flex from 'ui/Flex/Flex';

const Box = styled(Flex, {
  borderRadius: '$20',
  borderWidth: '1px',
  borderStyle: 'solid',
  padding: '$80',

  variants: {
    color: {
      sunset: {
        backgroundColor: '#fff9f0',
        borderColor: '#fddcab',
      },
      darkSunset: {
        backgroundColor: '#fbb956',
        borderColor: '#dc6803',
      },
      blue: {
        backgroundColor: '#f5fbff',
        borderColor: '#b2ddff',
      },
      periwinkle: {
        backgroundColor: '#f4f7ff',
        borderColor: '#d0deff',
      },
      transparent: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
    },
    margins: {
      true: {
        marginLeft: '20px',
        marginRight: '20px',
      },
    },
  },
});

export default Box;
