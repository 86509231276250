import { mergeAttributes, Node, nodeInputRule } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import ResizableMediaNodeView from './ResizableMediaNodeView.jsx';

export const IMAGE_INPUT_REGEX =
  /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;

export const ResizableMedia = Node.create({
  name: 'resizableMedia',

  addOptions() {
    return {
      inline: false,
      allowBase64: false,
      HTMLAttributes: {},
    };
  },

  inline: false,

  group: 'block',

  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      width: {
        default: '75%',
      },
      datawidth: {
        default: '75%',
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => {
          return {
            'data-width': attributes.datawidth,
          };
        },
      },
      height: {
        default: 'auto',
      },
      dataheight: {
        default: 'auto',
        parseHTML: (element) => element.getAttribute('data-height'),
        renderHTML: (attributes) => {
          return {
            'data-height': attributes.dataheight,
          };
        },
      },
      dataalign: {
        default: 'left', // 'left' | 'center' | 'right'
      },
      datafloat: {
        default: null, // 'left' | 'right'
      },
      datastyle: {
        default: '',
        parseHTML: (element) => element.getAttribute('datastyle'),
        renderHTML: (attributes) => {
          return attributes.datastyle
            ? {
                datastyle: attributes.datastyle,
                style: `${attributes.datastyle}`,
              }
            : null;
        },
      },
    };
  },

  selectable: true,

  parseHTML() {
    return [
      {
        tag: 'img[src]:not([src^="data:"])',
        getAttrs: (el) => ({
          src: el.getAttribute('src'),
        }),
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)],
    ];
  },

  addCommands() {
    return {
      setMedia:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ResizableMediaNodeView);
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: IMAGE_INPUT_REGEX,
        type: this.type,
        getAttributes: (match) => {
          const [, , alt, src, title] = match;

          return {
            src,
            alt,
            title,
          };
        },
      }),
    ];
  },
});
