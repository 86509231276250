import React from 'react';
import FolderViewCard from 'Components/FolderViewCard/FolderViewCard';
import Styles from './FolderView.module.scss';

const FolderView = ({ folders, fetchFolders, isTrash, projectView }) => {
  return (
    <section className={Styles.FolderView}>
      {folders?.map((folder) => (
        <FolderViewCard
          key={folder.id}
          folder={folder}
          fetchFolders={fetchFolders}
          isTrash={isTrash}
          projectView={projectView}
        />
      ))}
    </section>
  );
};

export default FolderView;
