import React from 'react';
import ImageView from 'Components/Image/ImageView';

const NoHeadlineView = () => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column gap-4 mt-4 fadeIn">
      <ImageView class="img-flid" src="./Images/noContentSVG.svg" />
      <p className="clr-light-grey text-lg-lh24 w-50 text-center">
        Summarize and analyze your text.
      </p>
    </div>
  );
};

export default NoHeadlineView;
