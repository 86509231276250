import { styled } from 'styles/stitches/stitches.config';

const Button = styled('button', {
  fontFamily: 'DM Sans',
  color: '#1D2939',

  borderRadius: '8px',
  border: 0,

  variants: {
    variant: {
      moonstone: {
        color: '$white',
        backgroundColor: '#00A7B7',
      },
      sunset: {
        color: '#1D2939',
        backgroundColor: '#FDDCAB',
      },
      periwinkle: {
        color: '#1D2939',
        backgroundColor: '#C7D6FE',
      },
      purple: {
        color: '$white',
        backgroundColor: '#7A5AF8',
      },
      dark: {
        color: '$white',
        backgroundColor: '#1D2939',
      },
      white: {
        color: '#1D2939',
        backgroundColor: '$white',
      },
      transparent: {
        backgroundColor: 'transparent',
      },
    },
    size: {
      md: {
        padding: '12px 20px',
      },
      lg: {
        padding: '16px 24px',
      },
    },
    shadow: {
      true: {
        boxShadow:
          '0px 3px 3px 0px rgba(0, 0, 0, 0.12), 0px 9px 12px 0px rgba(0, 0, 0, 0.08), 0px 9px 16px 0px rgba(0, 0, 0, 0.04)',
      },
    },
  },
  defaultVariants: {
    variant: 'moonstone',
    size: 'md',
  },
});

export default Button;
