import React from 'react';
import Styles from './Navigation.module.scss';

const Navigation = ({ navigations, activeIndex, setActiveIndex }) => {
  const handleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className={Styles.navigation}>
      {navigations.map((item, index) => (
        <button
          key={index}
          className={`${Styles.navigation__btn} ${activeIndex === index ? Styles[`navigation__btn--active-${index}`] : ''}`}
          onClick={() => handleClick(index)}
          disabled={item.new}
        >
          {item.icon}
          <span className={Styles.navigation__btn__text}>{item.text}</span>
          {item.new && (
            <span className={Styles.navigation__btn__badge}>Coming soon</span>
          )}
        </button>
      ))}
    </div>
  );
};

export default Navigation;
