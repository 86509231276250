import React from 'react';
import Nav from 'react-bootstrap/Nav';
import linkStyles from './Link.module.scss';
function Link(props) {
  return (
    <Nav.Link
      onClick={props.onClick}
      className={linkStyles.linkColor + ' ' + props.ClassList}
    >
      <span className={linkStyles.span + ' f-s14'}>{props.span}</span>
      {props.linkText}
    </Nav.Link>
  );
}

export default Link;
