import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FiArrowRight, FiUploadCloud } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { AiOutlineFileImage } from 'react-icons/ai';
import { LINK_REGEX } from 'constants';
import { formatBytes } from 'helpers';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import imageService from 'services/imageService';
import Input from 'Components/Input/Input';
import StyleClasses from './UploadImageModal.module.scss';
import Loader from 'Components/Loader/Loader';

function UploadImageModal({ show, onHide, editor }) {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);

  const [link, setLink] = useState('');
  const [isValidLink, setIsValidLink] = useState(true);

  const [startUploading, setStartUploading] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);

  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { documentId } = useParams() || {};

  const fileUploadingHandler = () => {
    fileUploading && setCurrentProgress(0);
    if (fileUploading === false) {
      setStartUploading(true);
    }
    setFileUploading(!fileUploading);
    setIsFilePicked(!isFilePicked);
    setIsDisabled(true);
  };

  const changeHandler = async (event) => {
    setSelectedFile(event.target.files[0]);
    fileUploadingHandler();
    setLink('');
  };

  const handleLinkChange = (event) => {
    setLink(event.target.value);
    const checkLink = event.target.value
      ? !!event.target.value.match(LINK_REGEX)
      : true;
    setIsValidLink(checkLink);
    setIsDisabled(!event.target.value.match(LINK_REGEX));
  };

  useEffect(() => {
    let interval;
    if (startUploading) {
      if (currentProgress < 100) {
        interval = setInterval(() => {
          setCurrentProgress((preValue) => preValue + 10);
        }, 200);
      } else {
        setIsDisabled(false);
        clearInterval(interval);
        setStartUploading(false);
      }
    }

    return () => clearInterval(interval);
  }, [currentProgress, startUploading]);

  const handleImport = async () => {
    try {
      setIsLoading(true);
      setIsDisabled(true);
      const formData = new FormData();
      if (link) {
        const response = await fetch(link);
        const blob = await response.blob();
        formData.append('document[images][]', blob, 'downloaded_image.jpg');
      } else if (selectedFile) {
        setFileUploading(true);
        formData.append('document[images][]', selectedFile);
      }

      const uploadResponse = await imageService.uploadImage(
        documentId,
        formData,
      );

      if (uploadResponse) {
        renderSuccess('Image uploaded successfully');
        const image = uploadResponse.document.images.slice(-1).pop();
        editor
          .chain()
          .focus()
          .createParagraphNear()
          .setMedia({
            src: image.url,
            alt: image.title || 'uploaded image',
            title: image.title || 'uploaded image',
          })
          .createParagraphNear()
          .run();

        onHide();
      }
    } catch (error) {
      renderError(error);
    } finally {
      setIsLoading(false);
      setFileUploading(false);
      setIsDisabled(false);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        size={680}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ zIndex: 8000 }}
      >
        <Modal.Header className="border-0 pb-0" closeButton>
          <Modal.Title className={StyleClasses.Modal__Heading}>
            Add an image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={'d-flex flex-column p-4 ' + StyleClasses.Modal__Body}
        >
          {isLoading ? (
            <Loader
              inline={true}
              loaderHeading="Adding image"
              loaderDescription="Please wait..."
            ></Loader>
          ) : (
            <>
              {!fileUploading ? (
                <>
                  <Input
                    placeholder="https://www.sample.edu/sample.png"
                    classList={'input'}
                    value={link}
                    onChange={handleLinkChange}
                  />
                  {!isValidLink && <div className="error">Invalid Link</div>}
                  <section
                    className={
                      'd-flex justify-content-between align-items-center ' +
                      StyleClasses.dividerSection
                    }
                  >
                    <hr className={StyleClasses.dividerSection__line} />
                    <span>OR</span>
                    <hr className={StyleClasses.dividerSection__line} />
                  </section>
                  <section className={StyleClasses.DragDropArea}>
                    <div className="d-flex flex-column justify-content-center align-items-center text-center ">
                      <div className={StyleClasses.icon_circle_container}>
                        <div className={StyleClasses.icon_inner_circle}>
                          <FiUploadCloud size="18" />
                        </div>
                      </div>

                      <p className={StyleClasses.DragDropArea__fileMsg}>
                        <span
                          className={
                            StyleClasses.DragDropArea__fileMsg_highlight
                          }
                        >
                          Click to upload
                        </span>{' '}
                        or drag and drop
                      </p>
                      <p className={StyleClasses.DragDropArea__fileMsg_format}>
                        {' '}
                        PNG, JPG, JPEG (max. 10MB)
                      </p>
                    </div>
                    <Input
                      type="file"
                      onChange={changeHandler}
                      accept="image/png, image/jpg, image/jpeg"
                    />
                  </section>
                </>
              ) : (
                <ProgressBar
                  className={StyleClasses.file_UploadStatusWrapper}
                  now={currentProgress}
                  label={
                    <div
                      className={
                        'd-flex justify-content-between align-items-center ' +
                        StyleClasses.ProgressBarLabel
                      }
                    >
                      <section
                        className={
                          'd-flex text-start ' +
                          StyleClasses.ProgressBarLabel__fileSection
                        }
                      >
                        <div
                          className={StyleClasses.ProgressBarLabel__fileIcon}
                        >
                          <AiOutlineFileImage />
                        </div>
                        <div className="d-flex flex-column">
                          <p
                            className={
                              'text-md ' +
                              StyleClasses.ProgressBarLabel__filename
                            }
                          >
                            {selectedFile?.name}
                          </p>
                          <span className="text-sm-18lh">
                            {formatBytes(selectedFile?.size)} –{' '}
                            {currentProgress}%
                            {currentProgress === 100
                              ? ' Uploaded'
                              : ' uploading...'}
                          </span>
                        </div>
                      </section>
                      <MdClose
                        class={StyleClasses.CrossBtn_ProgressBar}
                        onClick={fileUploadingHandler}
                      />
                    </div>
                  }
                  min={0}
                  max={100}
                  bsPrefix="file-upload-progress"
                />
              )}
              <Button
                variant="dark"
                onClick={handleImport}
                className="w-100 secondary_btn"
                disabled={isDisabled}
              >
                Add <FiArrowRight size="20" />
              </Button>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UploadImageModal;
