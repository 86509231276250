import React, { useEffect, useRef, useState } from 'react';
import ImageView from 'Components/Image/ImageView';
import Styles from 'Containers/Dashboard/Dashboard.module.scss';
import {
  DocumentNotificationTypes,
  FolderNotificationTypes,
  TaskNotificationTypes,
  TeamNotificationTypes,
  CommentNotificationTypes,
} from 'constants';
import { getNameInitials } from 'helpers';
import Badge from 'react-bootstrap/Badge';
import Toast from 'react-bootstrap/Toast';
import 'Components/PreLoader/Preloader.scss';
import NotificationsStyles from './Notifications.module.scss';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import {
  getNotifications,
  markAllNotifications,
} from 'services/notificationService';

const resourceTypeMapping = {
  sent_document_invitation: 'document',
  sent_team_invitation: 'team',
  sent_folder_invitation: 'folder',
};

const NoNotificationSection = () => (
  <section className={'d-flex pt-4 flex-column ' + Styles.Dashboard}>
    <section className={Styles.Dashboard_NoContent + ' pb-4'}>
      <ImageView src="/Images/plan.svg" class="img-fluid" />
      <strong>
        <p className="text-lg-lh24">No Notification Found.</p>
      </strong>
    </section>
  </section>
);

const NOTIFICATION_TYPES = [
  'document_collaboration',
  'folder_collaboration',
  'document_status_changed',
  'task_assigned',
  'document_added_to_folder',
  'document_removed_from_folder',
  'accepted_document_invitation',
  'accepted_folder_invitation',
  'accepted_team_invitation',
  'sent_document_invitation',
  'sent_folder_invitation',
];

const NOTIFICATIONS_MAPPER = {
  team_member_added: () => 'Joined your',
  team_member_removed: () => 'Removed you from',
  document_collaboration: (title) =>
    `Shared <span class="${NotificationsStyles.notificationPost}">${title}</span> with you`,
  folder_collaboration: (title) =>
    `Shared <span class="${NotificationsStyles.notificationPost}">${title}</span> with you`,
  comment_added: () => 'Commented on',
  mentioned: () => 'Mentioned you on comment in',
  document_status_changed: (title, status) =>
    `Changed <span class="${NotificationsStyles.notificationPost}">${title}</span> to <span class="${NotificationsStyles.notificationPost}">${status}</span>`,
  task_assigned: (title) =>
    `Assigned <span class="${NotificationsStyles.notificationPost}">${title}</span> to you`,
  document_added_to_folder: (title) =>
    `A document <span class="${NotificationsStyles.notificationPost}">${title}</span> has been added to folder`,
  document_removed_from_folder: (title) =>
    `A document <span class="${NotificationsStyles.notificationPost}">${title}</span> has been removed from folder`,
  sent_document_invitation: (title) =>
    `Shared <span class="${NotificationsStyles.notificationPost}">${title}</span> with you`,
  sent_folder_invitation: (title) =>
    `Shared <span class="${NotificationsStyles.notificationPost}">${title}</span> with you`,
  sent_team_invitation: () => 'Invited you to join',
  accepted_document_invitation: (title) =>
    `Document <span class="${NotificationsStyles.notificationPost}">${title}</span> invitation is accepted`,
  accepted_folder_invitation: (title) =>
    `Folder <span class="${NotificationsStyles.notificationPost}">${title}</span> invitation is accepted`,
  accepted_team_invitation: (title) =>
    `Team <span class="${NotificationsStyles.notificationPost}">${title}</span> invitation is accepted`,
  project_user_added: () => 'Added you in',
};

const NotificationsVar = ({ item, onClickHandler }) => {
  const transformedResourceTitle = item.resource_title;

  if (!transformedResourceTitle) {
    return null;
  }

  return (
    <section
      className={
        'd-flex position-relative w-100 ' +
        NotificationsStyles.NotificationsContent
      }
      key={item.id}
      onClick={() => onClickHandler(item)}
      style={{ cursor: 'pointer' }}
    >
      <div className={NotificationsStyles.notificationOwner}>
        {item.creator_avatar ? (
          <ImageView
            src={item.creator_avatar}
            alt="Notification Owner"
            class={'img-fluid  ' + NotificationsStyles.notificationOwnerAvatar}
          />
        ) : (
          <span
            className="rounded-profile"
            style={{ width: '48px', height: '48px' }}
          >
            {getNameInitials(item.creator_name)}
          </span>
        )}
      </div>
      <div
        className={'w-100 ' + NotificationsStyles.NotificationsContent__Right}
        style={{ paddingLeft: item.creator_avatar ? '54px' : '5px' }}
      >
        <div
          className={
            NotificationsStyles.notifications__header +
            ' d-flex justify-content-between'
          }
        >
          <div
            className={
              'd-flex align-items-end ' + NotificationsStyles.NotificationsInfo
            }
          >
            <p className={NotificationsStyles.ownerName}>{item.creator_name}</p>
            <p className={NotificationsStyles.Duration}>{item.created_at}</p>
          </div>
          {!item.read_status && (
            <span className={NotificationsStyles.activeStatus}></span>
          )}
        </div>
        <div className={NotificationsStyles.CategoryWrapper}>
          {!!item?.resource_title && (
            <span className={NotificationsStyles.notificationCategory}>
              {parse(
                NOTIFICATIONS_MAPPER[item?.notification_type || ''](
                  item.resource_title,
                  item.document_status,
                ) || '',
              )}
            </span>
          )}
          {!NOTIFICATION_TYPES.includes(item.notification_type) && (
            <span className={NotificationsStyles.notificationPost}>
              {transformedResourceTitle}
            </span>
          )}
          <br />
          {item.user_invite && (
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
              href={`${window.location.origin}/user_invites/${
                item?.user_invite?.token
              }/accept?type=${
                resourceTypeMapping[item.notification_type]
              }&resource_slug=${item.resource.slug}`}
              style={{ fontSize: '11px', color: '#00a7b7' }}
            >
              Click to accept
            </a>
          )}
        </div>
        <div className={NotificationsStyles.textContentSection}>
          {item.notification_type === 'comment_added' ? (
            <span className={NotificationsStyles.textContent}>
              {item.comment}
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    </section>
  );
};

function Notifications(props) {
  const [showNotifications, setShowNotifications] = useState(false);
  const [userNotifications, setNotifications] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [pagination, setPagination] = useState({});
  const [unreadCount, setUnreadCount] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isFetching) return;
    setIsFetching(true);
    fetchMoreListItems();
  }, [isFetching]);

  function handleScroll(event) {
    const obj = event.target;
    const { current_page, total_pages } = pagination;
    if (
      obj.clientHeight !== obj.scrollHeight - obj.scrollTop ||
      isFetching ||
      current_page === total_pages
    )
      return;
    setIsFetching(true);
  }

  async function fetchMoreListItems(getFirstPage = false) {
    try {
      const res = await getNotifications(
        getFirstPage ? 1 : (pagination.current_page || 0) + 1,
      );

      const { notifications, meta_data } = res?.data || {};
      const { pagination: paginationRes, unread_count } = meta_data || {};

      setNotifications(
        getFirstPage ? notifications : [...userNotifications, ...notifications],
      );
      setPagination(paginationRes);
      setUnreadCount(unread_count);
    } catch (error) {
    } finally {
      setIsFetching(false);
    }
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        const divElement = document.querySelector('#notificationIcon');
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !divElement?.contains(event.target)
        ) {
          setShowNotifications(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const readAll = async () => {
    try {
      const updateduserNotifications = userNotifications?.map((obj) => ({
        ...obj,
        read_status: true,
      }));
      setNotifications(updateduserNotifications);
      setUnreadCount(0);
      await markAllNotifications();
    } catch (error) {}
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const onClickHandler = (item) => {
    const {
      notification_type,
      resource,
      resource_document_slug,
      resource_document_template_id,
    } = item;

    if (DocumentNotificationTypes.includes(notification_type)) {
      const targetRoute = resource.template_id
        ? ROUTES.EditorDocumentTemplate(resource.slug, resource.template_id)
        : ROUTES.FreestyleDocumentTemplate(
            resource.slug || resource.document_id,
          );

      navigate(targetRoute);
    } else if (CommentNotificationTypes.includes(notification_type)) {
      navigate({
        pathname: ROUTES.EditorDocumentTemplate(
          resource_document_slug,
          resource_document_template_id,
        ),
        search: `?comment=${resource.uuid}`,
      });
    } else if (TeamNotificationTypes.includes(notification_type)) {
      navigate(ROUTES.TeamSlug(resource.slug));
    } else if (TaskNotificationTypes.includes(notification_type)) {
      navigate(ROUTES.PlannerBrief(resource.id));
    } else if (FolderNotificationTypes.includes(notification_type)) {
      navigate(ROUTES.Folder(resource.slug));
    } else if (notification_type === 'project_user_added') {
      navigate(ROUTES.ProjectSlug(resource.slug));
    }
  };

  const toggleShowNotifications = () => {
    setShowNotifications(!showNotifications);
    readAll();
  };

  return (
    <div className="position-relative">
      <div
        onClick={toggleShowNotifications}
        className="HeaderIconWrapper"
        id="notificationIcon"
      >
        <img
          className={`${
            showNotifications ? 'activeIcon' : ''
          } + " img-fluid HeaderIcon"`}
          alt="Notification Icon"
          src="/Images/notificationIcon.svg"
        />
        {unreadCount > 0 && (
          <span className="notification-badge">{unreadCount}</span>
        )}
      </div>
      <Toast
        className={NotificationsStyles.toastBodyWrapper}
        show={showNotifications}
        onClose={toggleShowNotifications}
        ref={wrapperRef}
        onScroll={(event) => handleScroll(event)}
      >
        <Toast.Header className={NotificationsStyles.ToastHeaderNotifications}>
          <strong className={'me-auto ' + NotificationsStyles.ToastHeading}>
            Notifications{' '}
            <Badge
              className={NotificationsStyles.Notification_badge}
              pill
              bg="light"
            >
              {props.notificationCount}
            </Badge>
          </strong>
          {unreadCount > 0 && (
            <p
              className={NotificationsStyles.marker}
              onClick={readAll}
              style={{ cursor: 'pointer' }}
            >
              Mark all as read
            </p>
          )}
        </Toast.Header>
        <Toast.Body className={NotificationsStyles.NotificationsBody}>
          <div />
          <ul className="list-group mb-2" onScroll={handleScroll}>
            {!userNotifications?.length ? (
              <NoNotificationSection />
            ) : (
              userNotifications?.map((item) => (
                <NotificationsVar
                  item={item}
                  onClickHandler={onClickHandler}
                  key={item.id}
                />
              ))
            )}
          </ul>
          {isFetching && (
            <div
              className=" preloader__wrapper shadow-reset mg-t-30"
              style={{
                justifyContent: 'center',
                display: 'flex',
                margin: '1rem',
              }}
            >
              <div className="tsperloader14" id="tsperloader14_one" />
              <div className="tsperloader14" id="tsperloader14_two" />
              <div className="tsperloader14" id="tsperloader14_three" />
            </div>
          )}
        </Toast.Body>
      </Toast>
    </div>
  );
}

export default Notifications;
