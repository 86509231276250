import React from 'react';
import Form from 'react-bootstrap/Form';

import './Input.scss';

function Input({
  inputWrapperClass,
  Label,
  type,
  placeholder,
  classList,
  value,
  disabled,
  autoFocus,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  id,
  accept,
  ...props
}) {
  const textareaProps = type === 'textarea' ? { as: 'textarea', rows: 3 } : {};
  return (
    <div className={inputWrapperClass}>
      <Form.Group>
        {Label && <Form.Label className="Input_label">{Label}</Form.Label>}
        <Form.Control
          type={type}
          placeholder={placeholder}
          className={classList}
          value={value}
          disabled={disabled}
          autoFocus={autoFocus}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          {...textareaProps}
          onKeyDown={onKeyDown}
          id={id}
          accept={accept}
          {...props}
        />
      </Form.Group>
    </div>
  );
}

export default Input;
