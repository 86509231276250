import React from 'react';
import Form from 'react-bootstrap/Form';

function TextArea(props) {
  return (
    <Form.Group>
      {props.Label && (
        <Form.Label className="Input_label">{props.Label}</Form.Label>
      )}
      <Form.Control
        type={props.type}
        placeholder={props.placeholder}
        className={props.classList}
        value={props.value}
        disabled={props.disabled}
        onChange={props.onChange}
        as="textarea"
        rows={3}
      />
    </Form.Group>
  );
}

export default TextArea;
