import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProfileForm from './ProfileForm';
import { SUBSCRIBE_PLAN } from 'constants';

const ProfileSetup = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  if (user?.is_profile_completed) {
    navigate(`/${SUBSCRIBE_PLAN}`);
  }
  return <ProfileForm />;
};

export default ProfileSetup;
