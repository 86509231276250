import React from 'react';
import './Loader.scss';

const Loader = ({ loaderHeading, loaderDescription, inline = false }) => {
  return (
    <div className="preloader__wrapper shadow-reset mg-t-30">
      <section
        className={inline ? 'ts_preloading_inline' : `ts_preloading_box`}
      >
        <div id="ts-preloader-absolute14">
          <div className="tsperloader14" id="tsperloader14_one"></div>
          <div className="tsperloader14" id="tsperloader14_two"></div>
          <div className="tsperloader14" id="tsperloader14_three"></div>
        </div>
        <section className="text-center preloader__textSection">
          <h3 className="text-lg-lh30">{loaderHeading}</h3>
          <p className="text-sm-18lh">{loaderDescription}</p>
        </section>
      </section>

      {!inline && <div className="backdrop-loader"></div>}
    </div>
  );
};

export default Loader;
