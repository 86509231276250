export const spaces = {
  8: '0.5rem',
  16: '1rem',
  24: '1.5rem',
  32: '2rem',
  40: '2.5rem',
  48: '3rem',
  56: '3.5rem',
  64: '4rem',
  72: '4.5rem',
  80: '5rem',
  136: '8.5rem',
};
