import { putRequest } from './axios';

export const generateStreamResponse = async (
  url,
  body,
  setStreamContent,
  setContentGenerated,
  signal,
  scrollDiv = 'editorContentBody',
) => {
  fetch(url, {
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
    signal,
  })
    .then((response) => {
      const reader = response.body.getReader();

      function processChunk(chunk) {
        const lines = chunk.split('\n');
        const parsedLines = lines
          .map((line) => line.replace(/^data: /, ''))
          .filter((line) => line !== '' && line !== '[DONE]');

        for (const parsedLine of parsedLines) {
          if (parsedLine) {
            setStreamContent((prevValue) => prevValue + parsedLine);
            const myDiv = document.getElementById(scrollDiv);
            if (myDiv) {
              myDiv.scrollTop = myDiv.scrollHeight;
            }
          }
        }
      }

      function read() {
        return reader.read().then(({ done, value }) => {
          if (done) {
            setContentGenerated(true);
            return;
          }

          const chunk = new TextDecoder().decode(value);
          processChunk(chunk);
          return read();
        });
      }

      return read();
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        console.error('AbortError: User Aborted!');
        return;
      }

      console.error(error);
    });
};

export const baseGenerateStyleGuide = async (
  projectId,
  filter,
  preview = false,
) => {
  try {
    const generateURL = `projects/${projectId}/generate_style_guide`;

    return await putRequest(
      `${generateURL}?filter=${filter}&preview=${preview}`,
    );
  } catch (e) {
    return e;
  }
};
