import React from 'react';
import { titleize } from 'helpers';
import Styles from './Badge.module.scss';

const Badge = ({ status, className }) => {
  return (
    <div className={`${className} ${Styles.Badge}`} data-status={status}>
      {titleize(status)}
    </div>
  );
};

export default Badge;
