import { ErrorMessage, Field } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { TbExclamationCircle } from 'react-icons/tb';
import Select from 'react-select';

const Dropdown = ({
  label,
  name,
  value,
  options,
  onChange,
  placeholder,
  defaultValue,
  isMulti = false,
  isRequired = false,
  isClearable = false,
  isDisabled = false,
  selectClassName,
  onInputChange,
  iconWithLabel,
  iconWithOption,
}) => (
  <div>
    <Field name={name}>
      {({ field }) => {
        return (
          <Form.Group controlId={name}>
            {label && (
              <Form.Label className="Input_label">
                {`${label} `}
                {iconWithLabel && (
                  <TbExclamationCircle
                    color="#98A2B3"
                    style={{
                      height: '20px',
                      width: '20px',
                      marginLeft: '4px',
                      transform: 'rotate(180deg)',
                    }}
                  />
                )}
                {isRequired && <span style={{ color: '#b42318' }}>*</span>}
              </Form.Label>
            )}
            <Select
              {...field}
              id={name}
              inputId={name}
              required={isRequired}
              aria-label="Default select example"
              style={{
                border: '1px solid #D0D5DD',
                color: '#667085',
              }}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              options={options}
              name={name}
              defaultValue={defaultValue ?? (isMulti ? [] : '')}
              isClearable={isClearable}
              isMulti={isMulti}
              className={selectClassName}
              isDisabled={isDisabled}
              formatOptionLabel={(option) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  {iconWithOption && option.icon && <>{option.icon}</>}
                  {option.label}
                </div>
              )}
            />
            <ErrorMessage name={name} component="div" className="error mt-2" />
          </Form.Group>
        );
      }}
    </Field>
  </div>
);

export default Dropdown;
