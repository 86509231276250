import React from 'react';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import Styles from './MultiDropdown.module.scss';
import { LuFolder, LuPlus } from 'react-icons/lu';
import { FiFile, FiFileText } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

function LibraryDropdown({ file, folder }) {
  const navigate = useNavigate();
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="dark"
        id="dropdown-basic"
        className={Styles.rounded_plus_btn}
      >
        <LuPlus className={Styles.plus_icon} />
      </Dropdown.Toggle>

      <Dropdown.Menu className={Styles.drop_menu_top}>
        <Dropdown.Item className={Styles.list_item} onClick={() => file()}>
          <FiFile color="#00A7B7" size={14} />
          <span className="text-md-Regular">Document</span>
        </Dropdown.Item>
        <Dropdown.Item className={Styles.list_item} onClick={() => folder()}>
          <LuFolder color="#00A7B7" size={14} />
          <span className="text-md-Regular">Folder</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LibraryDropdown;
