import React, { useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import StatsSection from './StatsSection';
import Styles from './Dashboard.module.scss';
import ActiveCampaigns from './ActiveCampaigns';
import { FiCalendar, FiFile, FiFileText, FiLayers } from 'react-icons/fi';
import BriefSection from './BriefSection';
import { AiOutlineProject } from 'react-icons/ai';
import NewDocumentModal from 'Components/Modals/NewDocumentModal/NewDocumentModal';
import BriefDrawer from 'Components/Drawers/BriefDrawer/BriefDrawer';
import ProjectDrawer from 'Components/Projects/ProjectDrawer';
import { LuPlus } from 'react-icons/lu';
import useCreateBrief from 'hooks/briefs/useCreateBrief';
import { useSelector } from 'react-redux';
import { formattedDate } from 'helpers';
import DocumentSection from './DocumentSection';
import useTotalCountDocuments from 'hooks/documents/useTotalCountDocuments';
import useOverdueCountBriefs from 'hooks/briefs/useOverdueCountBriefs';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';
import Announcement from 'Components/Pages/Brand/Announcement';

const Dashboard = () => {
  const { user } = useSelector((state) => state.auth);

  const [insightsDuration, setInsightsDuration] = useState('week');
  const { overdue: overdueBriefsCount, refetch: refetchBriefsCount } =
    useOverdueCountBriefs(insightsDuration);
  const {
    completed: completedDocumentsCount,
    in_progress: inProgressDocumentsCount,
    refetch: refetchDocumentStats,
  } = useTotalCountDocuments(insightsDuration);
  const [modalView, setModalView] = useState({
    newDocument: false,
    addTask: false,
    addProject: false,
  });
  const { mutateAsync: createBrief } = useCreateBrief({ userId: user?.id });
  const planName = user?.subscriptions?.current_subscription?.plan_name;
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const dropdownItems = [
    {
      modalKey: 'newDocument',
      Icon: FiFile,
      label: 'Document',
    },
    {
      modalKey: 'addTask',
      Icon: FiFileText,
      label: 'Brief',
    },
    {
      modalKey: 'addProject',
      Icon: FiLayers,
      label: 'Campaign',
    },
  ];

  const onConfirmCreateHandler = async (payload) => {
    await createBrief({
      ...payload,
      deadline_date: payload.deadline_date
        ? formattedDate(payload.deadline_date)
        : '',
    });
    refetchBriefsCount();
  };

  return (
    <>
      <section className={Styles.Dashboard}>
        {/* <div style={{ borderBottom: '1px solid white' }}>
          <Announcement view={true} />
        </div> */}
        <StatsSection
          setInsightsDuration={setInsightsDuration}
          overdueBriefsCount={overdueBriefsCount}
          completedDocumentsCount={completedDocumentsCount}
          inProgressDocumentsCount={inProgressDocumentsCount}
          planName={planName}
        />
        <ActiveCampaigns
          openProjectDrawer={
            planName === 'Basic Plan'
              ? () => setShowUpgradeModal(true)
              : () => {
                  setModalView((prevState) => ({
                    ...prevState,
                    addProject: true,
                  }));
                }
          }
          // openProjectDrawer={()=> setShowUpgradeModal(true)}
        />
        <BriefSection
          onCreateNewBrief={() => {
            setModalView((prevState) => ({ ...prevState, addTask: true }));
          }}
          refetchBriefsCount={refetchBriefsCount}
        />
        <DocumentSection
          onCreateNewDocument={() => {
            setModalView((prevState) => ({
              ...prevState,
              newDocument: true,
            }));
          }}
          refetchDocumentStats={refetchDocumentStats}
        />
      </section>
      {/* Bottom Actions */}
      <DropdownButton
        drop={'down-centered'}
        title={<LuPlus className={Styles.plus_icon} />}
        className={`${Styles.rounded_plus_btn}`}
        variant="dark"
      >
        {dropdownItems.map((item) => (
          <Dropdown.Item
            key={item.modalKey}
            onClick={() => {
              if (planName === 'Basic Plan' && item.modalKey === 'addProject') {
                setShowUpgradeModal(true);
                setModalView({
                  newDocument: false,
                  addTask: false,
                  addProject: false,
                });
                return;
              }
              setModalView({
                newDocument: false,
                addTask: false,
                addProject: false,
                [item.modalKey]: true,
              });
            }}
            className={Styles.list_item}
          >
            <item.Icon color="#00A7B7" size={14} />
            <span className="text-md-Regular">{item.label}</span>
          </Dropdown.Item>
        ))}
      </DropdownButton>

      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
        />
      )}

      {modalView.newDocument && (
        <NewDocumentModal
          show={modalView.newDocument}
          onHide={() => {
            setModalView((prevState) => ({
              ...prevState,
              newDocument: false,
            }));
          }}
        />
      )}

      {modalView.addTask && (
        <BriefDrawer
          show={modalView.addTask}
          onHide={() => {
            setModalView((prevState) => ({ ...prevState, addTask: false }));
          }}
          action="create"
          onSubmit={(_, payload) => onConfirmCreateHandler(payload)}
        />
      )}

      {modalView.addProject && (
        <ProjectDrawer
          show={modalView.addProject}
          onHide={() => {
            setModalView((prevState) => ({
              ...prevState,
              addProject: false,
            }));
          }}
          action="create"
        />
      )}
    </>
  );
};

export default Dashboard;
