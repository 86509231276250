import React from 'react';
import ImageView from 'Components/Image/ImageView';
import { formatBytes } from 'helpers';
import StyleClasses from './ImportDraftModal.module.scss';
import { FiX } from 'react-icons/fi';

function ProgressBarContent({
  selectedFile,
  currentProgress,
  fileUploadingHandler,
}) {
  return (
    <>
      <div
        className={
          'd-flex justify-content-between align-items-center ' +
          StyleClasses.ProgressBarLabel
        }
      >
        <section
          className={
            'd-flex text-start ' + StyleClasses.ProgressBarLabel__fileSection
          }
        >
          <ImageView src="/Images/fileIconCricle.svg" />
          <div className="d-flex flex-column">
            <p className={'text-md ' + StyleClasses.ProgressBarLabel__filename}>
              {selectedFile?.name}
            </p>
            <span className="text-sm-18lh">
              {formatBytes(selectedFile?.size)} – {currentProgress}%
              {currentProgress === 100 ? ' Uploaded' : ' uploading...'}
            </span>
          </div>
        </section>
        <FiX
          className={StyleClasses.CrossBtn_ProgressBar}
          onClick={fileUploadingHandler}
          color="#667085"
        />
      </div>
    </>
  );
}

export default ProgressBarContent;
