import { useInfiniteQuery } from '@tanstack/react-query';
import keys from 'constants/queryKeys';
import { fetchTasks } from '../../services/contentPlannerService';

const useBriefs = ({ searchValue, type = '', filterStr, selectedProject }) => {
  return useInfiniteQuery({
    queryKey: keys.Briefs({ searchValue, type, filterStr, selectedProject }),
    refetchOnWindowFocus: false,
    queryFn: ({ pageParam }) =>
      fetchTasks(searchValue, type, filterStr, selectedProject, pageParam),
    initialPageParam: 1,
    keepPreviousData: true,
    getNextPageParam: ({ data }) => {
      // Check if data is truthy and contains meta_data property
      if (data && data.meta_data) {
        const { pagination } = data.meta_data;
        // Ensure pagination exists before accessing its properties
        if (pagination && pagination.current_page < pagination.total_pages) {
          return pagination.current_page + 1;
        }
      }
      return undefined;
    },
  });
};

export default useBriefs;
