import React from 'react';

const VerticalSeparator = () => {
  return (
    <div
      style={{
        width: '1px',
        backgroundColor: '#E4E7EC',
        height: '40px',
      }}
    />
  );
};

export default VerticalSeparator;
