import React, { useState } from 'react';
import { TbRotate } from 'react-icons/tb';
import { useParams } from 'react-router-dom';
import { Accordion, Nav, OverlayTrigger, Tab, Tooltip } from 'react-bootstrap';
import { getDocumentHealth } from 'services/documentService';
import HealthParameter from './HealthParameter';
import OverallScore from './OverallScore';
import OverallScoreModal from 'Containers/Modal/OverAllScoreModal/OverallScoreModal';
import { getHealthStr } from 'helpers/health';
import Styles from './DocumentHealth.module.scss';
import { getScoreInfo } from 'helpers/score';
import { FiRefreshCcw } from 'react-icons/fi';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { IoIosArrowDown } from 'react-icons/io';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import ContentScore from './ContentScore';
import { FiX } from 'react-icons/fi';

const transformSuggestions = (category) => category?.suggestions || '';

const DocumentHealth = ({
  docHealth,
  setDocHealth,
  docHealthLoading,
  onClose,
}) => {
  const [overallScoreModalView, setOverallScoreModalView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRotating, setIsRotating] = useState(false);

  const { documentId } = useParams();

  const {
    grammar_and_correctness,
    readability,
    vocabulary,
    SEO,
    writing_style,
    term_usage,
    sentence_structure,
    word_count,
    reading_time_seconds,
    sentence_count,
    paragraph_count,
    heading_count,
  } = docHealth;

  const {
    overallScore,
    transformedReadability,
    grammar,
    writingStyle,
    seo,
    transformedVocabulary,
    sentenceStructure,
    transformedTerm,
  } = getScoreInfo(docHealth);

  const suggestions = [
    {
      health: grammar,
      category: 'Correctness',
      remarks: getHealthStr(grammar),
      suggestions: transformSuggestions(grammar_and_correctness),
    },
    {
      health: transformedVocabulary,
      category: 'Vocabulary',
      remarks: getHealthStr(transformedVocabulary),
      suggestions: transformSuggestions(vocabulary),
    },
    {
      health: transformedReadability,
      category: 'Readability',
      remarks: getHealthStr(transformedReadability),
      suggestions: transformSuggestions(readability),
    },
    {
      health: seo,
      category: 'Accessibility',
      remarks: getHealthStr(seo),
      suggestions: transformSuggestions(SEO),
    },
    {
      health: writingStyle,
      category: 'Styles',
      remarks: getHealthStr(writingStyle),
      suggestions: transformSuggestions(writing_style),
    },
    {
      health: transformedTerm,
      category: 'Terms',
      remarks: getHealthStr(transformedTerm),
      suggestions: transformSuggestions(term_usage),
    },
    {
      health: sentenceStructure,
      category: 'SentenceStructure',
      remarks: getHealthStr(sentenceStructure),
      suggestions: transformSuggestions(sentence_structure),
    },
  ];

  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      const documentHealth = await getDocumentHealth(documentId);
      setDocHealth(documentHealth?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleDropchnage = () => {
    setIsRotating(!isRotating);
  };

  return (
    <div className={Styles.documentHeathSection}>
      <div className={Styles.menuBar}>
        <p className={Styles.menuBar__heading}>Document Health</p>
        {!(isLoading || docHealthLoading) ? (
          <div
            className={`${Styles.menuBar__refresh} d-flex gap-3 align-items-center`}
          >
            <FiRefreshCcw
              size={28}
              color="#00A7B7"
              onClick={handleRefresh}
              disabled={isLoading}
            />
            <button className="cancel_btn">
              <FiX color=" #667085" size={20} onClick={onClose} />
            </button>
          </div>
        ) : (
          <div className={Styles.load} />
        )}
      </div>
      {/* tabs */}
      <Tab.Container
        className="nav-tabs"
        id="plan-tabs"
        defaultActiveKey="general"
        onSelect={(key) => {
          // setActiveTab(key);
        }}
      >
        <Nav className={Styles.ContentPlanner__Wrapper_Navigation}>
          <div className={Styles.ContentPlanner__Wrapper_Navigation__left}>
            <Nav.Item
              className={Styles.ContentPlanner__Wrapper_Navigation_Icon}
            >
              <Nav.Link
                eventKey="general"
                className={Styles.ContentPlanner__Wrapper_Navigation_Icon__Item}
              >
                <span>General</span>
              </Nav.Link>
            </Nav.Item>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-settings">Coming Soon</Tooltip>}
            >
              <Nav.Item
                className={Styles.ContentPlanner__Wrapper_Navigation_Icon}
              >
                <Nav.Link
                  eventKey="seo"
                  className={
                    Styles.ContentPlanner__Wrapper_Navigation_Icon__Item
                  }
                  disabled
                >
                  <span>SEO</span>
                </Nav.Link>
              </Nav.Item>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-settings">Coming Soon</Tooltip>}
            >
              <Nav.Item
                className={Styles.ContentPlanner__Wrapper_Navigation_Icon}
              >
                <Nav.Link
                  eventKey="ai"
                  className={
                    Styles.ContentPlanner__Wrapper_Navigation_Icon__Item
                  }
                  disabled
                >
                  <span>AI</span>
                </Nav.Link>
              </Nav.Item>
            </OverlayTrigger>
          </div>
        </Nav>
        <Tab.Content>
          <>
            <Tab.Pane eventKey="general">
              <>
                <Accordion
                  defaultActiveKey="0"
                  className={Styles.accordian_secton}
                >
                  <Accordion.Item
                    eventKey="0"
                    className={Styles.accordian_secton__item}
                  >
                    <Accordion.Header>
                      <div
                        className={Styles.accordian_secton__item__header}
                        onClick={handleDropchnage}
                      >
                        <div
                          className={
                            Styles.accordian_secton__item__header__left
                          }
                        >
                          <span>Content Score</span>
                          <AiOutlineQuestionCircle />
                        </div>
                        <IoIosArrowDown
                          className={isRotating ? Styles.roteIcon : ''}
                        />
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ContentScore
                        overallScore={overallScore}
                        wordCount={word_count}
                        readingTime={reading_time_seconds}
                        sentenceCount={sentence_count}
                        headingCount={heading_count}
                        paragraphCount={paragraph_count}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion
                  bsPrefix="d-flex flex-column gap-4 p-4"
                  style={{ background: '#F9FAFB' }}
                >
                  {suggestions.map((parameter, idx) => (
                    <HealthParameter
                      index={idx}
                      key={parameter.category}
                      health={parameter.health}
                      category={parameter.category}
                      remarks={parameter.remarks}
                      suggestions={parameter.suggestions || []}
                    />
                  ))}
                </Accordion>

                <OverallScoreModal
                  show={overallScoreModalView}
                  onHide={() => {
                    setOverallScoreModalView(false);
                  }}
                  transformedDocHealth={docHealth}
                  score={overallScore}
                />
              </>
            </Tab.Pane>
            <Tab.Pane eventKey="seo">
              <div className={Styles.coming_soon}>Coming soon</div>
            </Tab.Pane>
            <Tab.Pane eventKey="ai">
              <div className={Styles.coming_soon}>Coming soon</div>
            </Tab.Pane>
          </>
        </Tab.Content>
      </Tab.Container>
      {/* <OverallScore
        openScoreModal={() => {
          setOverallScoreModalView(true);
        }}
        score={overallScore}
      /> */}

      {/* OverAll Score Section */}
      {/* <div className={Styles.overallScore_container}>
        <div className={Styles.overallScore_container__top}>
          <p className={Styles.overallScore_container__top__heading}>
            Overall Score
          </p>
          <p className={Styles.overallScore_container__top__score}>12/100</p>
        </div>
        <div>
          <ProgressBar now={60} className={Styles.ProgressBar_design} />
        </div>
      </div> */}
    </div>
  );
};

export default DocumentHealth;
