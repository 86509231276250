import BSButton from 'Components/Button/BSButton';
import FormikTextField from 'Components/Form/FormikTextField';
import {
  EMAIL_REGEX,
  FORGOT_PASSWORD_HEADING,
  RESEND_CODE_BUTTON_TEXT,
} from 'constants';
import { ErrorMessage, Formik } from 'formik';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import passwordService from 'services/passwordService';
import * as Yup from 'yup';
import ImageView from '../../../Components/Image/ImageView';
import styles from '../SignInModal/SignUpModal.module.scss';

const ForgotPasswordModal = ({ show, handleClose }) => {
  const navigate = useNavigate();
  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(EMAIL_REGEX, 'Invalid email')
      .required('Email is required.'),
  });

  const handleSubmit = async (values) => {
    const body = { user: values };
    try {
      await passwordService.requestResetPassword(body);
      navigate('/resetPassword');
      renderSuccess('Please check your email!');
    } catch (error) {
      renderError(error);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="fixwidthModal_476px"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop
      >
        <div>
          <Modal.Header
            className={styles.AuthModalHeader}
            closeButton
          ></Modal.Header>
          <Modal.Body className={styles.ModalBody}>
            <div className={styles.modalBodyWrapper}>
              <div
                className={
                  'd-flex flex-column align-items-center ' +
                  styles.headingSection
                }
              >
                <ImageView
                  src="/Images/vocable__logo_svg.svg"
                  alt="Vocable_logo"
                  class="img-fluid logo-img m-0"
                ></ImageView>
                <p className={styles.modalHeadingText}>
                  {FORGOT_PASSWORD_HEADING}
                </p>
              </div>
              <div className={styles.ModalInnerWrapper}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  validateOnMount={true}
                  enableReinitialize={true}
                >
                  {({ values, isValid }) => {
                    return (
                      <form
                        className={
                          'd-flex flex-column  ' + styles.ModalInputSection
                        }
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <FormikTextField
                          name="email"
                          type="email"
                          placeholder="Enter your email"
                          classList="input input__Email"
                        />
                        <ErrorMessage name="email">
                          {(msg) => <span className="error">{msg}</span>}
                        </ErrorMessage>
                        <BSButton
                          variant="dark"
                          classList="w-100"
                          ButtonText={RESEND_CODE_BUTTON_TEXT}
                          disabled={!isValid}
                          onClick={() => handleSubmit(values)}
                        ></BSButton>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default ForgotPasswordModal;
