import React from 'react';
import { bottom } from '@popperjs/core';
import moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FaEllipsisH } from 'react-icons/fa';
import { FiCheckCircle, FiTrash2 } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import reactStringReplace from 'react-string-replace';

import ImageView from 'Components/Image/ImageView';
import TaggedUser from 'Components/TaggedUser/TaggedUser';

import { getNameInitials } from 'helpers';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';

import styleComment from './../Editor.module.scss';
import { COMMENT_NAME } from 'constants/regex';

const Comment = ({
  comment,
  fetchComments,
  setHoverId,
  hoverId,
  commentAvatar,
  resolveComment,
  deleteComment,
}) => {
  const { uuid, message, status, created_at, name, user_id } = comment || {};
  const isResolved = status !== 'open';

  const { user } = useSelector((state) => state.auth);
  const { id: userId } = user || {};

  const handleDeleteComment = async () => {
    try {
      await deleteComment(userId, uuid);

      renderSuccess('Comment deleted successfully');
    } catch (error) {
      renderError('Unable to delete comment');
    }

    fetchComments();
  };

  const markResolved = async () => {
    try {
      await resolveComment(userId, uuid);

      renderSuccess('Comment resolved successfully');
    } catch (error) {
      renderError('Unable to resolve comment');
    }

    fetchComments();
  };

  const { avatar: ImgAvatar, name: ImgName } = commentAvatar || {};

  const maxLength =
    ImgAvatar.length > ImgName.length ? ImgAvatar.length : ImgName.length;

  // Transforms the message by finding the tagged user
  // And replacing that string for a React Component
  const transformedMessage = reactStringReplace(
    message,
    COMMENT_NAME,
    (match, i) => {
      return <TaggedUser key={i} displayName={match} />;
    },
  );

  function renderRepliesCount(comment) {
    return (
      comment.replies > 0 && (
        <div className={styleComment.Comment_Replies}>
          {comment.replies} {comment.replies === 1 ? 'reply' : 'replies'}
        </div>
      )
    );
  }

  return (
    <div
      className={`${styleComment.Comment} commentContainerInfo`}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        if (hoverId === uuid) {
          setHoverId('');
          return;
        }
        setHoverId(uuid);
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <section className="d-flex align-items-center g-10">
          <div className="stacked-Images-rounded d-flex">
            {Array.from(Array(maxLength)).map((_, index) => {
              const avatarImage = ImgAvatar[index];
              return avatarImage ? (
                <ImageView
                  key={index}
                  src={avatarImage}
                  className={`rounded-avatar-bordered ${styleComment.rounded_avatar_bordered}`}
                />
              ) : (
                <span
                  key={index}
                  className={`rounded-profile ${styleComment.rounded_avatar_bordered}`}
                >
                  {getNameInitials(ImgName[index])}
                </span>
              );
            })}
          </div>

          <div className="d-flex flex-column">
            <span className={styleComment.Comment_authorName}>{name}</span>
            <span className={styleComment.Comment_timeStamp}>
              {moment(created_at).format('h:mm A MMM D')}
            </span>
          </div>
        </section>
        <section className={styleComment.Comment__OptionSection}>
          <DropdownButton
            onClick={(e) => e.stopPropagation()}
            drop={bottom}
            title={<FaEllipsisH fill="#667085" size={13} />}
          >
            <Dropdown.Item
              className={styleComment.Comment_dropdown}
              disabled={isResolved}
            >
              <div
                className="d-flex g-10 align-items-center"
                onClick={() => markResolved()}
              >
                <FiCheckCircle stroke="#667085" size={14}></FiCheckCircle>
                <p className="text-md">{isResolved ? 'Resolved' : 'Resolve'}</p>
              </div>
            </Dropdown.Item>
            {userId === user_id && (
              <Dropdown.Item>
                <div
                  className="d-flex align-items-center g-10"
                  onClick={() => handleDeleteComment()}
                >
                  <FiTrash2 stroke="#667085" size={14}></FiTrash2>
                  <p className="text-md">Delete</p>
                </div>
              </Dropdown.Item>
            )}
          </DropdownButton>
        </section>
      </div>
      <section className={styleComment.Comment__Content}>
        <div className={styleComment.Comment__Content_text}>
          {transformedMessage}
        </div>
        <div>{renderRepliesCount(comment)}</div>
      </section>
    </div>
  );
};

export default Comment;
