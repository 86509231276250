import React from 'react';
import BSButton from 'Components/Button/BSButton';
import Styles from './Dashboard.module.scss';

const EmptyDashboardState = ({
  title,
  description,
  buttonText,
  backgroundImageUrl,
  onClick,
}) => {
  return (
    <div
      className={Styles.Dashboard__EmptyDashboardState}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      <div className={Styles.Dashboard__EmptyDashboardState_Content}>
        <div className={Styles.Dashboard__EmptyDashboardState_Content_Title}>
          {title}
        </div>
        <div
          className={Styles.Dashboard__EmptyDashboardState_Content_Description}
        >
          {description}
        </div>
      </div>
      <BSButton
        classList={`button ${Styles.Dashboard__EmptyDashboardState_Button}`}
        ButtonText={buttonText}
        onClick={onClick}
        variant="dark"
      />
    </div>
  );
};
export default EmptyDashboardState;
