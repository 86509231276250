import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageHeader from 'Components/PageHeader/PageHeader';
import IdeateForm from './IdeateForm';
import IdeateCard from './IdeateCard/IdeateCard';
import Loader from 'Components/Loader/Loader';
import BriefDrawer from 'Components/Drawers/BriefDrawer/BriefDrawer';

import useIdeate from 'hooks/briefs/useIdeate';
import useCreateDocument from 'hooks/documents/useCreateDocument';
import { ROUTES } from 'constants/routes';

import Styles from './Ideate.module.scss';
import useCreateBrief from 'hooks/briefs/useCreateBrief';
import { formattedDate } from 'helpers';
import BackToOnboarding from 'Components/Onboarding/BackToOnboarding/BackToOnboarding';

const Ideate = () => {
  const [editBrief, setEditBrief] = useState();
  const [showBriefDrawer, setShowBriefDrawer] = useState(false);

  const [URLSearchParams] = useSearchParams();
  const onboarding = URLSearchParams.get('onboarding');

  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { id: userId } = user || {};

  const { mutateAsync: createIdeas, isPending, data: ideas } = useIdeate();
  const { mutateAsync: createBrief } = useCreateBrief({ userId: user?.id });
  const { mutateAsync: createDocument } = useCreateDocument({
    userId,
  });

  const addToPlanner = (idea) => {
    setEditBrief(idea);
    setShowBriefDrawer(true);
  };

  const handleBriefCreation = async (payload) => {
    await createBrief({
      ...payload,
      deadline_date: formattedDate(payload.deadline_date),
    });
  };

  const startDraft = async (idea) => {
    await createDocument(
      {
        document: {
          title: idea.title,
          content: idea.description,
          user_id: userId,
        },
      },
      {
        onSuccess: (documentResponse) => {
          const documentId = documentResponse?.data?.document?.id;
          if (!documentId) return;

          navigate(ROUTES.FreestyleDocumentTemplate(documentId));
        },
      },
    );
  };

  const onSubmit = async ({ prompt }) => {
    await createIdeas({ prompt });
  };

  return (
    <>
      <section className={Styles.Ideate}>
        <div className={Styles.Ideate__top_container}>
          <p
            className={`${Styles.Ideate__top_container__heading} Animate-Heading`}
          >
            Inspiration at Your Fingertips
          </p>
          <p
            className={`${Styles.Ideate__top_container__description} Animate-Heading`}
          >
            Generate ideas for every type of content you envision. Like what you
            see? Add your favorites to your content planner or start drafting
            using our entry editor.
          </p>
          <IdeateForm onSubmit={onSubmit} />
          <div className={Styles.tag_container}>
            {[
              'Designing with data',
              'Bridging the overall Gap between AI',
              'Sketch to Screen, AI landscape exploration',
            ].map((item, index) => (
              <div key={index} className={Styles.tag_container__tag}>
                {item}
              </div>
            ))}
          </div>
        </div>
        <div>
          {isPending ? (
            <Loader inline loaderHeading="Generating Ideas..." />
          ) : (
            <div className={Styles.Ideate_Grid}>
              {ideas &&
                ideas.map((idea) => {
                  return (
                    <IdeateCard
                      key={idea.title}
                      title={idea.title}
                      description={idea.description}
                      addPlanner={() => addToPlanner(idea)}
                      startDraft={() => startDraft(idea)}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </section>

      {showBriefDrawer && (
        <BriefDrawer
          action="create"
          isAIPowered={false}
          show={showBriefDrawer}
          onHide={() => setShowBriefDrawer(false)}
          onSubmit={(_, payload) => handleBriefCreation(payload)}
          task={editBrief}
        />
      )}
      {onboarding && <BackToOnboarding />}
    </>
  );
};

export default Ideate;
