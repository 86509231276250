import React from 'react';
import Modal from 'react-bootstrap/Modal';
import BSButton from 'Components/Button/BSButton';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment';
import Dropdown from 'Components/Dropdown/Dropdown';
import { StatusOptions } from 'Components/Status/StatusOption';

const MoveToStateModal = ({
  onMove,
  task,
  show,
  onHide,
  isDocument = false,
}) => {
  const currentState = task ? task?.state : '';

  const initialValues = {
    state: currentState,
  };

  const validationSchema = Yup.object({
    state: Yup.string().required('State is a required field'),
  });

  return (
    <Modal
      show={show}
      onHide={onHide}
      size={600}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="border-0 pb-0" closeButton>
        <Modal.Title className="Modal-Title">
          {isDocument ? 'Edit document' : 'Edit Brief'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          enableReinitialize={true}
        >
          {({ values, setFieldValue, isValid }) => {
            const { state } = values || {};
            return (
              <form
                className="d-flex flex-column gap-4"
                onSubmit={(e) => {
                  e.preventDefault();
                  onMove({
                    project_id: task?.project_id,
                    document_id: task?.document_id,
                    deadline_date: moment(task?.deadline_date).toDate(),
                    ...values,
                  });
                  onHide();
                }}
              >
                <Dropdown
                  name="state"
                  label="Move to"
                  options={StatusOptions}
                  value={StatusOptions.find((option) => option.value === state)}
                  onChange={(option) =>
                    setFieldValue('state', option?.value ?? '')
                  }
                  isRequired
                />

                <BSButton
                  variant="dark"
                  classList="w-100 secondary_btn"
                  ButtonText="Move"
                  type="submit"
                  disabled={!isValid}
                />
              </form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default MoveToStateModal;
