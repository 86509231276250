import { Formik } from 'formik';
import * as Yup from 'yup';
import FormikTextField from 'Components/Form/FormikTextField';
import { Row } from 'react-bootstrap';
import BSButton from 'Components/Button/BSButton';
import {
  integratonFieldsToObject,
  integrationFieldsToSchema,
} from 'helpers/form';

const IntegrationForm = ({ type, fields, defaultValues, onSubmit }) => {
  const initialValues = integratonFieldsToObject(fields, defaultValues);
  const dynamicSchema = integrationFieldsToSchema(fields, defaultValues);

  const validationSchema = Yup.object(dynamicSchema);

  const handleSubmit = (values) => {
    onSubmit(type.toLowerCase(), values);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {({ values, isValid }) => {
        return (
          <form
            onSubmit={(e) => e.preventDefault()}
            className="d-flex flex-column gap-3"
          >
            <Row className="g-3">
              {fields?.map((field) => {
                const fieldKey = field.field;
                const isFieldOptional =
                  validationSchema.describe().fields[fieldKey].optional;

                return (
                  <FormikTextField
                    Label={field.description}
                    isRequired={!isFieldOptional}
                    key={fieldKey}
                    name={fieldKey.toLowerCase()}
                    type="text"
                    placeholder={field.description}
                    classList="input"
                  />
                );
              })}
            </Row>

            <BSButton
              BtnWrapperClass="d-flex justify-content-end"
              classList="button secondary_btn"
              disabled={!isValid}
              onClick={() => handleSubmit(values)}
              ButtonText="Submit"
              variant="light"
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default IntegrationForm;
