import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import TableHead from 'Components/TableHeader/TableHead';
import TableTitles from './TableTitles';
import ProjectRow from '../ProjectRow/ProjectRow';
import Styles from './ProjectList.module.scss';
import { useSelector } from 'react-redux';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';

const ProjectList = ({
  projects,
  userId,
  handleProjectEdit,
  handleProjectDelete,
}) => {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const {  subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  return (
    <section className={Styles.ListContainer}>
      <Table hover responsive="lg" className='table-sticky'>
        <TableHead TableHeadings={TableTitles} />
        <tbody>
          {projects?.map((project) => (
            <ProjectRow
              key={project.id}
              project={project}
              userId={userId}
              handleEdit={currentSubscription?.plan_name === "Basic Plan" ? ()=>setShowUpgradeModal(true) : () => handleProjectEdit(project)}
              handleDelete={() => handleProjectDelete(project.id)}
            />
          ))}
        </tbody>
      </Table>
      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
        />
      )}
    </section>
  );
};

export default ProjectList;
