import React from 'react';
import CardStyles from './ManageSubscriptionCard.module.scss';
import { useNavigate } from 'react-router-dom';
import { BILLING_PAYMENT, BILLING_SUBSCRIPTION } from 'constants';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getNameInitials } from 'helpers';
import star from "../../assets/star-yellow.svg"

export const ManageSubscriptionCard = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { subscriptions, name, avatar } = user || {};
  const nameInitials = getNameInitials(name);
  const { current_subscription: currentSubscription } = subscriptions ?? {};
  return (
    <div className={CardStyles.card_wrapper}>
      <div className={CardStyles.card_wrapper__profile}>
        <div className={CardStyles.card_wrapper__profile__picture}>
          {/* {avatar ? (
            <img src={avatar} alt="user-avater" className="h-100 w-100" />
          ) : (
            <p>{nameInitials}</p>
          )} */}
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <g clip-path="url(#clip0_21816_26967)">
              <path d="M26 32H6C4.4087 32 2.88258 31.3679 1.75736 30.2426C0.632141 29.1174 0 27.5913 0 26L0 6C0 4.4087 0.632141 2.88258 1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0L26 0C27.5913 0 29.1174 0.632141 30.2426 1.75736C31.3679 2.88258 32 4.4087 32 6V26C32 27.5913 31.3679 29.1174 30.2426 30.2426C29.1174 31.3679 27.5913 32 26 32Z" fill="white"/>
              <path d="M16 8C11.589 8 8 11.589 8 16C8 20.411 11.589 24 16 24C20.411 24 24 20.411 24 16C24 11.589 20.411 8 16 8ZM21.191 15.208L18.951 17.504L19.481 20.752C19.4958 20.8445 19.4844 20.9393 19.4479 21.0255C19.4115 21.1118 19.3516 21.1862 19.275 21.24C19.1983 21.2939 19.1081 21.3252 19.0146 21.3303C18.9211 21.3354 18.828 21.3142 18.746 21.269L16 19.753L13.255 21.271C13.173 21.3171 13.0796 21.3391 12.9856 21.3343C12.8916 21.3295 12.8009 21.2982 12.724 21.244C12.6471 21.1898 12.5871 21.1149 12.551 21.028C12.5149 20.9411 12.5041 20.8458 12.52 20.753L13.05 17.505L10.81 15.209C10.7463 15.1431 10.7019 15.0609 10.6816 14.9715C10.6614 14.8821 10.6661 14.7889 10.6951 14.7019C10.7242 14.615 10.7766 14.5377 10.8466 14.4784C10.9166 14.4192 11.0014 14.3803 11.092 14.366L14.168 13.896L15.548 10.956C15.713 10.605 16.288 10.605 16.453 10.956L17.833 13.896L20.909 14.366C20.9996 14.3801 21.0845 14.4189 21.1545 14.478C21.2245 14.5371 21.277 14.6143 21.3061 14.7012C21.3352 14.7881 21.3398 14.8814 21.3195 14.9707C21.2992 15.0601 21.2548 15.1422 21.191 15.208Z" fill="#F99806"/>
            </g>
            <defs>
              <clipPath id="clip0_21816_26967">
                <rect width="32" height="32" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          
        </div>
        <div className={CardStyles.card_wrapper__profile__name}>
          <p>
            {/* {user?.name} */}
            {currentSubscription?.plan_name === "Pro Plan" ? "Pro Plan" : "Upgrade to Pro"}
            </p>
          {/* <span>{user?.role}</span> */}
        </div>
      </div>
      <div className={CardStyles.card_wrapper__divider}></div>
      <p className={CardStyles.card_wrapper__trial}>
        {/* {currentSubscription?.status === 'trial'
          ? `Free Trial ends: ${moment(currentSubscription?.end_date).format('MMM DD, YYYY')}`
          : currentSubscription?.status === 'canceled' &&
              currentSubscription?.active
            ? `Subscription expires: ${moment(
                currentSubscription?.end_date,
              ).format('MMM DD, YYYY')}`
            : `Your next billing cycle is  ${moment(currentSubscription?.end_date).format('MMM DD, YYYY')}`} */}
        {
          currentSubscription?.active && currentSubscription?.status === 'past_due'
            ? 'Your payment has failed. Please update your payment method, or your subscription will end.'
            : currentSubscription?.status === 'trial'
              ? `Free Trial ends: ${moment(currentSubscription?.end_date).format('MMM DD, YYYY')}`
              : currentSubscription?.status === 'canceled' && currentSubscription?.active
                ? `Subscription expires: ${moment(currentSubscription?.end_date).format('MMM DD, YYYY')}`
                : `Your next billing cycle is ${moment(currentSubscription?.end_date).format('MMM DD, YYYY')}`
        }
      </p>
      <div className={CardStyles.card_wrapper__manage_btn}>
        <span
          onClick={() =>
            navigate(
              // `/${currentSubscription?.status === 'trial' ? BILLING_PAYMENT : BILLING_SUBSCRIPTION}`,
              `/${BILLING_SUBSCRIPTION}`,

            )
          }
          className={CardStyles.card_wrapper__manage_btn__link}
        >
          Manage
        </span>
      </div>
    </div>
  );
};
