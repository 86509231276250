import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  step2: {},
  step3: {},
  step4: {},
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    step2: (state, action) => {
      return {
        ...state,
        step2: action.payload,
      };
    },
    step3: (state, action) => {
      return {
        ...state,
        step3: action.payload,
      };
    },
    step4: (state, action) => {
      return {
        ...state,
        step4: action.payload,
      };
    },
  },
});

const { reducer, actions } = onboardingSlice;
export { actions };
export default reducer;
