import React from 'react';

import Box from 'ui/Box/Box';

import Styles from './ContentToolsSection.module.scss';
import Avatar from '../../../assets/landingpage/Avatar.svg';

import Frame from '../../../assets/landingpage/Frame 1618868207.svg';

const ContentToolsSection = () => {
  return (
    <Box
      as="section"
      id="ContentToolsSection"
      direction="column"
      align="start"
      gap="72"
      color="periwinkle"
      margins
      className={Styles.section_responsive}
    >
      <div className={Styles.contenttoll}>
        <div className={Styles.contenttoll__heading}>
          Your Central Hub for Smarter Content Ops!
        </div>
        <div className={Styles.contenttoll__right_box}>
          Why toggle between multiple fragmented tools when you can centralize
          your content operations in one dashboard AND{' '}
          <span className={Styles.contenttoll__right_box__after_effect}>
            achieve better results with less effort...?
          </span>
          <div className={Styles.contenttoll__right_box__cursor}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="23"
              viewBox="0 0 20 23"
              fill="none"
            >
              <g filter="url(#filter0_d_21154_71432)">
                <path
                  d="M5.94126 15.3833L3.72266 3.61914L13.4845 9.50124L8.60358 10.8586L5.94126 15.3833Z"
                  fill="#2E90FA"
                />
                <path
                  d="M3.94889 3.22933L3.10156 2.71875L3.28743 3.70431L5.50603 15.4685L5.72518 16.6306L6.32225 15.6158L8.89286 11.247L13.6018 9.93751L14.6144 9.65589L13.7108 9.11139L3.94889 3.22933Z"
                  stroke="white"
                  stroke-width="1.10289"
                  stroke-linecap="square"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_21154_71432"
                  x="-0.976651"
                  y="-0.59446"
                  width="20.2814"
                  height="23.1818"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="1.10289" />
                  <feGaussianBlur stdDeviation="1.65434" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_21154_71432"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_21154_71432"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
          <div className={Styles.contenttoll__right_box__avatar}>
            <img src={Avatar} alt="avatar" />
          </div>
        </div>
      </div>
      <div className={Styles.frame_image}>
        {/* <img src={Frame} alt="frame" /> */}
        <video
          controls
          autoPlay
          loop
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            borderRadius: '20px',
          }}
          // onClick={handlePlayPause}
          // poster={Thumb}
        >
          <source
            src={
              'https://blobsharedstg.blob.core.windows.net/media/Dashboard Animation.mp4 '
            }
          />
        </video>
      </div>
    </Box>
  );
};

export default ContentToolsSection;
