import { useQuery } from '@tanstack/react-query';
import keys from 'constants/queryKeys';
import { getTask } from 'services/taskService';

const useBrief = (briefId) => {
  const query = useQuery({
    queryKey: keys.Brief(briefId),
    queryFn: () => getTask(briefId),
    enabled: !!briefId,
  });

  return {
    ...query,
    ...query?.data?.data,
  };
};

export default useBrief;
