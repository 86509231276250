import React from 'react';
import Styles from './PageHeader.module.scss';

const PageHeader = ({ title, subtitle }) => {
  return (
    <div>
      <h1 className={`Heading-lg-fs-24 Animate-Heading ${Styles.mainTitle}`}>
        {title}
      </h1>
      {subtitle && (
        <h3 className={`Animate-Heading ${Styles.subTitle}`}>{subtitle}</h3>
      )}
    </div>
  );
};

export default PageHeader;
