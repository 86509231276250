import React from 'react';
import Drawer from 'Components/Drawer/Drawer';
import { Col, Container, Row } from 'react-bootstrap';
import DrawerCard from './DrawerCard';

import { ReactComponent as Instagram } from '../../../assets/publishpage/instagram (1).svg';
import { ReactComponent as X } from '../../../assets/publishpage/twitter.svg';
import { ReactComponent as Ticktok } from '../../../assets/publishpage/tiktok.svg';
import { ReactComponent as Linkedin } from '../../../assets/publishpage/linkedin (1).svg';
import { ReactComponent as Facebook } from '../../../assets/publishpage/type=facebook.svg';

const AddSocialAccountDrawer = ({ show, onHide }) => {
  return (
    <Drawer
      show={show}
      onHide={onHide}
      placement="end"
      title="Connect Social Accounts"
    >
      <div className={StylePropertyMap.drawer}>
        <Container fluid>
          <Row className="mb-4">
            <Col>
              <DrawerCard icon={<Facebook />} text="Facebook" />
            </Col>
            <Col>
              <DrawerCard icon={<Instagram />} text="Instagram" />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <DrawerCard icon={<X />} text="Twitter" />
            </Col>
            <Col>
              <DrawerCard icon={<Linkedin />} text="LinkedIn" />
            </Col>
          </Row>
          <Row className="">
            <Col>
              <DrawerCard icon={<Ticktok />} text="Ticktok" />
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    </Drawer>
  );
};

export default AddSocialAccountDrawer;
