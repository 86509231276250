import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { BsFilter } from 'react-icons/bs';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { bottom } from '@popperjs/core';

import ImageView from 'Components/Image/ImageView';
import Styles from './../Editor.module.scss';
import Comment from './Comment';
import { LuListFilter } from 'react-icons/lu';

const noCommentSection = (
  <section
    className={
      'd-flex flex-column justify-content-center align-items-center ' +
      Styles.Comments__noCommentSection
    }
  >
    <ImageView src="/Images/nocomments.svg" class="img-fluid" />
    <p className={Styles.Comments__noCommentSection_message}>
      No comments yet!
    </p>
  </section>
);

const CommentsSidebar = ({
  comments,
  fetchComments,
  setHoverId,
  hoverId,
  resolveComment,
  deleteComment,
}) => {
  if (!comments) {
    return noCommentSection;
  }

  const transformedComments = Object.keys(comments).map((commentKey) => {
    const replies = comments[commentKey].length - 1;
    comments[commentKey][0].replies = replies;
    return comments[commentKey][0];
  });

  const commentAvatars = Object.keys(comments).reduce((acc, commentKey) => {
    const { uuid } = comments[commentKey][0] || {};

    const threadComments = comments[commentKey].map((comment) => {
      return {
        avatar: comment.mini_avatar,
        name: comment.name,
      };
    });

    acc[uuid] = {
      avatar: [
        ...new Set(threadComments.map((threadComment) => threadComment.avatar)),
      ],
      name: [
        ...new Set(threadComments.map((threadComment) => threadComment.name)),
      ],
    };

    return acc;
  }, {});

  return (
    <>
      <div className={'fade show ' + Styles.Comments}>
        <div
          className={
            'd-flex justify-content-between align-items-center ' +
            Styles.Comments__Header
          }
        >
          <div className={Styles.Comments__Header_title}>Comments</div>
          <div className={'d-flex ' + Styles.Comments__Header_Menu}>
            <DropdownButton
              className={''}
              drop={bottom}
              title={<LuListFilter size={16} color="#344054" />}
            >
              <Dropdown.Item
                className={Styles.DropdownListItem}
                onClick={fetchComments}
              >
                Show all comments
              </Dropdown.Item>
              <Dropdown.Item
                className={Styles.DropdownListItem}
                onClick={() => fetchComments('open')}
              >
                Open comments
              </Dropdown.Item>
              <Dropdown.Item
                className={Styles.DropdownListItem}
                onClick={() => fetchComments('resolved')}
              >
                Resolved comments
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
        {transformedComments?.length
          ? transformedComments.map((comment) => {
              const commentAvatar = commentAvatars[comment.uuid];
              return (
                <Comment
                  comment={comment}
                  fetchComments={fetchComments}
                  setHoverId={setHoverId}
                  hoverId={hoverId}
                  commentAvatar={commentAvatar}
                  key={comment.uuid}
                  resolveComment={resolveComment}
                  deleteComment={deleteComment}
                />
              );
            })
          : noCommentSection}
      </div>
    </>
  );
};

export default CommentsSidebar;
