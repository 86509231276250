/* eslint-disable import/no-extraneous-dependencies */
import { Mark, mergeAttributes } from '@tiptap/react';

export const Comment = Mark.create({
  name: 'comment',
  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },
  // excludes: "",

  addAttributes() {
    return {
      comment: {
        default: null,
        parseHTML: (el) => {
          return el.getAttribute('data-comment');
        },
        renderHTML: (attrs) => {
          return JSON.parse(attrs.comment);
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-comment]',
        getAttrs: (el) => !!el.getAttribute('data-comment')?.trim() && null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setComment:
        (comment) =>
        ({ commands }) =>
          commands.setMark(this.name, { comment }),
      toggleComment:
        () =>
        ({ commands }) =>
          commands.toggleMark(this.name),
      unsetComment:
        () =>
        ({ commands }) =>
          commands.unsetMark(this.name),
    };
  },
});
