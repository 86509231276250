import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { setLoader } from 'redux/slices/loader';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import Styles from './ConfirmEmptyBin.module.scss';
import { deleteFoldersAndDocuments } from 'services/documentService';
import BSButton from 'Components/Button/BSButton';
import { FiAlertCircle } from 'react-icons/fi';
import styles from '../../../Containers/Subscription/EmailModal.module.scss';

function ConfirmEmptyBin({ fetchFolders, onHide, show }) {
  const dispatch = useDispatch();

  const confirmDelete = async () => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Deleting permanently!',
          description: 'Please wait...',
        }),
      );

      const res = await deleteFoldersAndDocuments();
      dispatch(setLoader({ isLoading: false }));
      renderSuccess(res?.data?.message);
      fetchFolders();
      onHide();
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
      renderError(error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pb-0 border-0" closeButton>
        <Modal.Title className="Modal-Title" id="contained-modal-title-vcenter">
          Delete Item
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className="d-flex justify-content-center">
          <div
            style={{
              background: '#FEF3F2',
              height: '45px',
              width: '45px',
              borderRadius: '50%',
              position: 'relative',
            }}
          >
            <div
              style={{
                background: '#FEE4E2',
                height: '35px',
                width: '35px',
                borderRadius: '50%',
                margin: '5px',
                padding: '5px',
              }}
            >
              <FiAlertCircle size={25} color="#D92D20" />
            </div>
          </div>
        </div>

        <p
          style={{
            fontFamily: 'DM Sans',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '28px',
            letterSpacing: '0em',
            textAlign: 'center',
            marginTop: 20,
          }}
        >
          Delete Trash Item
        </p>

        <p
          style={{
            fontFamily: 'DM Sans',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'center',
            color: '#667085',
          }}
        >
          Are you sure you want to delete all items permanently from the trash?
        </p>

        <div className="d-flex justify-content-end g-10 w-100 mt-5">
          <BSButton
            variant="light"
            ButtonText={'Cancel'}
            onClick={onHide}
            classList={
              'btn ' + styles.modalFooterBtn + ' ' + styles.modalFooterBtn_Light
            }
          />
          <BSButton
            variant="dark"
            ButtonText={'Delete'}
            onClick={() => confirmDelete()}
            classList={'btn button secondary_btn'}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmEmptyBin;
