import React from 'react';
import ImageView from '../../../Image/ImageView';
import styles from './DropDownListItem.module.scss';

const DropDownWithLink = ({ href, children }) => {
  if (!href) {
    return <>{children}</>;
  }

  return (
    <a
      className={styles.DropDownlistItemLink}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

const DropDownListItem = ({ hoverHandler, subtitles, icon, title, href }) => {
  const listhoverHandler = () => {
    hoverHandler(subtitles);
  };

  return (
    <DropDownWithLink href={href}>
      <li
        onMouseEnter={listhoverHandler}
        className={'f-s16 ' + styles.DropDownlistItem}
      >
        <ImageView class="img-fluid" src={icon} alt={title} />
        {title}
      </li>
    </DropDownWithLink>
  );
};

export default DropDownListItem;
