import ImageView from 'Components/Image/ImageView';
import React from 'react';
import StyleCard from './TemplateCard.module.scss';
const TemplateCard = ({
  selected,
  onClick,
  id,
  className,
  ImageSrc,
  heading,
  text,
}) => {
  return (
    <div className="template-card">
      <div
        className={
          `d-flex flex-column ` +
          StyleCard.TemplateCardWrapper +
          `${selected === id ? ' active ' : ''}`
        }
        onClick={onClick}
      >
        <div className={`${StyleCard[className]} ` + StyleCard.ImageSection}>
          <ImageView src={ImageSrc} />
        </div>
        <p className={StyleCard.cardHeadingText}>{heading}</p>
        <p className={StyleCard.cardDetailsText}>{text}</p>
      </div>
    </div>
  );
};

export default TemplateCard;
