// REMOVE THIS COMMENTED CODE AFTER TESTING COMPLETED OF BELOW CODE
// import React, { useState, useEffect } from 'react';
// import Styles from './Styles.module.scss';
// import FormikTextField from 'Components/Form/FormikTextField';
// import { Form } from 'react-bootstrap';
// import { ErrorMessage, Formik, FieldArray } from 'formik';
// import { AiOutlineDelete } from 'react-icons/ai';
// import { FiPlus } from 'react-icons/fi';
// import ButtonSection from '../ButtonSection/ButtonSection';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   nextStep,
//   prevStep,
//   updateStep3,
//   updateStep2,
// } from 'redux/slices/newOnboarding';
// import onBoardingService from 'services/onBoardingService';
// import { handleChange } from 'formik';

// const Step3 = ({ haveweb }) => {
//   const dispatch = useDispatch();
//   const onboardingData = useSelector((state) => state.newOnboarding.step3);
//   const welcomeData = useSelector((state) => state.newOnboarding.step1);
//   const summary = useSelector((state) =>
//     haveweb ? state.newOnboarding.step2 : state.newOnboarding.businessSummary,
//   );

//   // const [fields, setFields] = useState(
//   //   onboardingData.topics ? onboardingData.topics.map((topic, index) => ({ id: index, name: 'niche', value: topic })) : [{ id: Date.now(), name: 'niche', value: '' }]
//   // );

//   const [fields, setFields] = useState(
//     onboardingData.topics &&
//       onboardingData.topics.map((topic, index) => ({
//         id: index,
//         name: 'niche',
//         value: topic,
//       })),
//   );

//   useEffect(() => {
//     if (onboardingData.topics) {
//       setFields(
//         onboardingData.topics.map((topic, index) => ({
//           id: index,
//           name: 'niche',
//           value: topic,
//         })),
//       );
//     }
//   }, [onboardingData]);

//   const handleNext = async (values) => {
//     const topicsArray = values?.niches.map((niche) => niche?.niche);

//     try {
//       let body = {
//         [haveweb ? 'user_entered_summary' : 'auto_generated_summary']: summary,
//         ...welcomeData,
//         content_topics: topicsArray,
//       };

//       const response = await onBoardingService.createBusiness(body);
//       if (response?.data?.success) {
//         dispatch(updateStep3({ topics: topicsArray }));
//         dispatch(nextStep());
//       } else {
//         alert('An error occurred 1: ' + response.message); // Adjust this according to the actual response structure
//       }
//     } catch (error) {
//       console.error(error);
//       alert('An error occurred 2: ' + error.message);
//     }
//   };

//   const handleBack = (values) => {
//     const topicsArray = values?.niches?.map((niche) => niche?.niche);
//     dispatch(updateStep3({ topics: topicsArray }));
//     dispatch(prevStep());
//   };

//   return (
//     <div>
//       <Formik
//         initialValues={{
//           niches: fields?.map((field) => ({ niche: field.value })),
//         }}
//         validateOnMount={true}
//         enableReinitialize={true}
//       >
//         {({ values, setFieldValue, isValid, dirty }) => {
//           return (
//             <>
//               <Form
//                 onSubmit={(e) => {
//                   e.preventDefault();
//                 }}
//                 className={Styles.form3_wrapper}
//               >
//                 <FieldArray
//                   name="niches"
//                   render={(arrayHelpers) => (
//                     <>
//                       {fields?.map((field, index) => (
//                         <div
//                           key={fields[index]?.id || index}
//                           // key={field.id}
//                           className={Styles.form3_wrapper__field}
//                         >
//                           <div className="w-100">
//                             <FormikTextField
//                               name={`niches.${index}.${field.name}`}
//                               type="text"
//                               placeholder="Describe business niche..."
//                               classList="input input__Email"
//                               Label=""
//                               isRequired
//                               value={values?.niches[index]?.niche} // Ensure correct value is displayed
//                               onChange={(e) => {
//                                 arrayHelpers.push({ niche: e.target.value });
//                               }} // Update value on change
//                             />
//                             <ErrorMessage
//                               name={`niches.${index}.${field.name}`}
//                             >
//                               {(msg) => <span className="error">{msg}</span>}
//                             </ErrorMessage>
//                           </div>
//                           <button
//                             type="button"
//                             className={Styles.form3_wrapper__field__button}
//                             onClick={() => {
//                               setFields((prevFields) => {
//                                 return prevFields.filter((_, i) => i !== index);
//                               });
//                               arrayHelpers.remove(index);
//                             }}
//                           >
//                             <AiOutlineDelete size={20} color="#667085" />
//                           </button>
//                         </div>
//                       ))}
//                       <button
//                         type="button"
//                         className={Styles.form3_wrapper__another_field_btn}
//                         onClick={(e) => {
//                           const newField = { id: Date.now(), name: 'niche' };
//                           // setFields((prevFields) => [...prevFields, newField]);
//                           setFields((prevFields) => {
//                             return [...prevFields, newField];
//                           });
//                           // arrayHelpers.push({ niche: values?.niches[values?.niches.length - 1]?.niche  });
//                         }}
//                       >
//                         <FiPlus size={20} color="#00a7b7" />
//                         <span>Add another content topic</span>
//                       </button>
//                     </>
//                   )}
//                 />
//                 <div className={Styles.step3_button}>
//                   <ButtonSection
//                     btn_1="Save and Continue"
//                     btn_2="Back to previous step"
//                     isValid={isValid}
//                     handleNext={() => handleNext(values)}
//                     handleBack={() => handleBack(values)}
//                   />
//                 </div>
//               </Form>
//             </>
//           );
//         }}
//       </Formik>
//     </div>
//   );
// };

// export default Step3;


import React, { useState, useEffect } from 'react';
import Styles from './Styles.module.scss';
import { Form } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai';
import { FiPlus } from 'react-icons/fi';
import ButtonSection from '../ButtonSection/ButtonSection';
import { useDispatch, useSelector } from 'react-redux';
import {
  nextStep,
  prevStep,
  updateStep3,
} from 'redux/slices/newOnboarding';
import onBoardingService from 'services/onBoardingService';
import { error as errorToast, success as successToast } from 'helpers/toaster';
import { actions } from 'redux/slices/auth';

const Step3 = ({ haveweb }) => {
  const dispatch = useDispatch();
  const onboardingData = useSelector((state) => state.newOnboarding.step3);
  const welcomeData = useSelector((state) => state.newOnboarding.step1);
  const summary = useSelector((state) =>
    haveweb ? state.newOnboarding.step2 : state.newOnboarding.businessSummary,
  );

  const [fields, setFields] = useState([]);

  useEffect(() => {
    // Check if onboardingData.topics already has data
    if (onboardingData.topics && onboardingData.topics.length > 0) {
      setFields(
        onboardingData.topics.map((topic, index) => ({
          id: index,
          name: 'niche',
          value: topic,
        })),
      );
    } else {
      // If no data, initialize with an empty field
      setFields([{ id: Date.now(), name: 'niche', value: '' }]);
    }
  }, [onboardingData]);

  const fetchBrands = async () => {
    try {
      const response = await onBoardingService.getUserBrandInfo();
      dispatch(actions.updateUser({ user: { user_brands: response?.data } }));
      // setBrandsData(response?.data?.brands_data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNext = async () => {
    const topicsArray = fields.map((field) => field.value);
    if (topicsArray.length === 1 && (!topicsArray[0] || topicsArray[0].trim() === '')) {
      errorToast("At least 1 Topic is required, and it cannot be empty.");
      return;
    }
    try {
      let body = {
        [haveweb ? 'user_entered_summary' : 'auto_generated_summary']: summary,
        ...welcomeData,
        content_topics: topicsArray,
        brand_name: summary.brand_name,
      };

      const response = await onBoardingService.createBusiness(body);
      if (response?.data?.success) {
        dispatch(updateStep3({ topics: topicsArray }));
        dispatch(nextStep());
        fetchBrands();
      } else {
        errorToast('An error occurred 1: ' + response.message); // Adjust this according to the actual response structure
      }
    } catch (error) {
      console.error(error);
      errorToast('An error occurred 2: ' + error.message);
    }
  };

  const handleBack = () => {
    const topicsArray = fields.map((field) => field.value);
    dispatch(updateStep3({ topics: topicsArray }));
    dispatch(prevStep());
  };

  const handleFieldChange = (index, value) => {
    const updatedFields = fields.map((field, i) => 
      i === index ? { ...field, value } : field
    );
    setFields(updatedFields);
  };

  const handleAddField = () => {
    const newField = { id: Date.now(), name: 'niche', value: '' };
    setFields((prevFields) => [...prevFields, newField]);
  };

  const handleRemoveField = (index) => {
    if(fields.length === 1){
      errorToast("Atleast 1 Topic is required");
      return;
    }
    setFields((prevFields) => prevFields.filter((_, i) => i !== index));
  };

  return (
    <div>
      <Form className={Styles.form3_wrapper}>
        {fields.map((field, index) => (
          <div key={field.id} className={Styles.form3_wrapper__field}>
            <div className="w-100">
              <input
                type="text"
                placeholder="Describe business niche..."
                className={Styles.input_content}
                value={field.value}
                onChange={(e) => handleFieldChange(index, e.target.value)}
              />
            </div>
            <button
              type="button"
              className={Styles.form3_wrapper__field__button}
              onClick={() => handleRemoveField(index)}
            >
              <AiOutlineDelete size={20} color="#667085" />
            </button>
          </div>
        ))}
        <button
          type="button"
          className={Styles.form3_wrapper__another_field_btn}
          onClick={handleAddField}
        >
          <FiPlus size={20} color="#00a7b7" />
          <span>Add another content topic</span>
        </button>
        <div className={Styles.step3_button}>
          <ButtonSection
            btn_1="Save and Continue"
            btn_2="Back to previous step"
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </div>
      </Form>
    </div>
  );
};

export default Step3;
