import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { setLoader } from 'redux/slices/loader';
import { BsXLg, BsArrowRightShort } from 'react-icons/bs';

import ImageView from 'Components/Image/ImageView';
import { ROUTES } from 'constants/routes';
import { GENERATE_INNER_HTML_PROMPT_POSTFIX } from 'constants';
import ContentGenerationModal from 'Containers/Modal/ContentGenerationModal/ContentGenerationModal';
import { getIsQuestionSubmitButtonEnabled } from 'helpers';
import { generateStreamResponse } from 'helpers/openAIChat';
import { updateDocument } from 'services/documentService';
import AnswerComponent from './AnswerComponent';
import styles from './Questionnaire.module.scss';
import useCreateDocument from 'hooks/documents/useCreateDocument';
import useQuestions from 'hooks/questions/useQuestions';
import useTemplate from 'hooks/templates/useTemplate';
import BSButton from '../../Components/Button/BSButton';
import { FiFileText, FiRefreshCw } from 'react-icons/fi';
import { IoArrowBack } from 'react-icons/io5';

const Questionnaire = ({
  SideBarView,
  templateIdParams,
  documentTemplateOptions = [],
  refetchContent = async () => {},
}) => {
  const [questionsState, setQuestionsState] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [streamContent, setStreamContent] = useState('');
  const [contentGenerated, setContentGenerated] = useState(false);
  const [templateOptions, setTemplateOptions] = useState([]);

  const { user } = useSelector((state) => state.auth);

  const { mutateAsync: createDocument } = useCreateDocument({
    userId: user?.id,
  });

  const abortController = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { templateId, documentId } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const taskId = searchParams?.get('taskId');
  const projectId = searchParams?.get('projectId');
  const folderId = searchParams?.get('folderId');

  const transformedTemplateId = templateIdParams || templateId;

  const { questions: resQuestions } = useQuestions(transformedTemplateId);
  const { template } = useTemplate(transformedTemplateId);

  const { title: templateName, prompt: templatePrompt } = template || {};

  const initialQuestions = useMemo(
    () =>
      resQuestions?.[0].inputs.map((question, index) => {
        return {
          ...question,
          id: question.id || index + 1,
        };
      }),
    [resQuestions],
  );

  const initialQuestionsState = useMemo(
    () =>
      resQuestions?.[0].inputs
        .sort((a, b) => a.position - b.position)
        .map((question, index) => {
          const match = documentTemplateOptions.find(
            (d) => d.title === question.title,
          );
          return {
            ...question,
            id: question.id || index + 1,
            title: question.title || index + 1,
            selectedOption: match?.value ?? '',
            inputValue: match?.value ?? '',
            position: question.position,
          };
        }) || [],
    [resQuestions],
  );

  useEffect(() => {
    setQuestions(initialQuestions);
    setQuestionsState(initialQuestionsState);
  }, [resQuestions]);

  const handleChange = (index, type, option, action) => {
    const transformedQuestions = questionsState.map((question, qIndex) => {
      if (qIndex === index) {
        const { selectedOption } = question || {};
        const selectedOptions =
          action === 'add'
            ? type === 'radio'
              ? [option]
              : option
                ? [...selectedOption, option]
                : selectedOption
            : selectedOption.filter((val) => val !== option);

        return {
          ...question,
          selectedOption: selectedOptions,
          inputValue: '',
        };
      }
      return question;
    });

    setQuestionsState(transformedQuestions);
  };

  const handleInputChange = (value, index, isTagsInput) => {
    const transformedQuestions = questionsState.map((question, qIndex) => {
      if (qIndex === index) {
        return {
          ...question,
          inputValue: isTagsInput ? value.join(',') : value,
          selectedOption: [],
        };
      }
      return question;
    });

    setQuestionsState(transformedQuestions);
  };

  const handleContentGenerated = async () => {
    if (SideBarView) {
      await updateDocument(documentId, { content: streamContent });
      setOpenModal(false);
      await refetchContent();
    } else {
      await createDocument(
        {
          document: {
            title: questionsState[0]?.inputValue,
            user_id: user.id,
            template_id: transformedTemplateId,
            content: streamContent,
            template_options: templateOptions,
          },
          taskId,
          projectId,
          folderId,
        },
        {
          onSuccess: (documentResponse) => {
            const documentId = documentResponse?.data?.document?.id;
            if (!documentId) return;

            const routeToMove = ROUTES.EditorDocumentTemplate(
              documentId,
              transformedTemplateId,
            );
            navigate(routeToMove);
          },
        },
      );
    }
  };

  useEffect(() => {
    if (contentGenerated) {
      handleContentGenerated();
    }
  }, [contentGenerated]);

  const handleBlockSave = async () => {
    let transformedMessage = templatePrompt;
    const templateOptionsTemp = [];

    questionsState.forEach(
      ({
        title,
        input_type: inputType,
        inputValue,
        selectedOption,
        id: questionId,
        multiple,
      }) => {
        const value =
          inputType === 'text'
            ? inputValue
            : inputType === 'radio'
              ? selectedOption[0]
              : inputValue;

        const allChild = questionsState
          .filter((question) => question.id === questionId && multiple)
          ?.map((question) => question.inputValue);

        transformedMessage = transformedMessage.replaceAll(
          `[${title.toUpperCase()}]`,
          `${value}`,
        );

        templateOptionsTemp.push({ title, value });

        if (allChild?.length) {
          const content = allChild
            .map((_, index) => {
              return `
            [Product: ${index + 1}]
            [Summary]
            [Description]
            [About this Item]
            [Reviews]
            [Pros & Cons]
            [Why should I buy this product]`;
            })
            .join('\n\n');
          transformedMessage = transformedMessage.replaceAll(
            `[Product]`,
            allChild,
          );

          transformedMessage = transformedMessage.replaceAll(
            `[5 Products Types]`,
            `[${allChild.length} Products Types]`,
          );

          transformedMessage = transformedMessage.replaceAll(
            `5 products`,
            `${allChild.length} products`,
          );

          transformedMessage = transformedMessage.replaceAll(
            `5 detailed product`,
            `${allChild.length} detailed product`,
          );

          transformedMessage = transformedMessage.replaceAll(
            `[Product: 1]\n[Summary]\n[Description]\n[About this Item]\n[Reviews]\n[Pros & Cons]\n[Why should I buy this product]`,
            content,
          );
        }
      },
    );

    abortController.current = new AbortController();
    setTemplateOptions({ options: templateOptionsTemp });
    setStreamContent('');
    setContentGenerated(false);
    setOpenModal(true);

    const URL = `${process.env.REACT_APP_URL}/api/v1/completion_stream_data`;
    const BODY = JSON.stringify({
      prompt: `${transformedMessage}\n${GENERATE_INNER_HTML_PROMPT_POSTFIX}`,
      ai_model: 'claude-3-5-sonnet-20240620',
    });

    try {
      await generateStreamResponse(
        URL,
        BODY,
        setStreamContent,
        setContentGenerated,
        abortController?.current.signal,
      );
    } catch (error) {
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    }
  };

  const handleMultipleQuestionAdd = (questionId, index) => {
    const parentQuestion = questions.find(
      (question) => question.id === questionId,
    );

    const previousChildQuestionsCount = questions.filter(
      (question) => question.id === questionId,
    ).length;

    const childQuestion = {
      ...parentQuestion,
      childId: `${questionId}_${previousChildQuestionsCount - 1}`,
      position: parentQuestion.position + previousChildQuestionsCount,
    };

    const transformedQuestions = questions.reduce((acc, question, qIndex) => {
      if (qIndex === index) {
        acc.push(...[question, childQuestion]);
        return acc;
      }

      if (qIndex > index) {
        acc.push({
          ...question,
          position: question.position + 1,
        });
        return acc;
      }

      acc.push(question);
      return acc;
    }, []);

    const transformedQuestionState = questionsState.reduce(
      (acc, question, qIndex) => {
        if (qIndex === index) {
          acc.push(...[question, childQuestion]);
          return acc;
        }

        if (qIndex > index) {
          acc.push({
            ...question,
            position: question.position + 1,
          });
          return acc;
        }

        acc.push(question);
        return acc;
      },
      [],
    );

    setQuestions(transformedQuestions);
    setQuestionsState(transformedQuestionState);
  };

  const handleRemoveChildQuestion = (index) => {
    const transformedQuestions = questions.reduce((acc, question, qIndex) => {
      if (qIndex === index) {
        return acc;
      }

      if (qIndex > index) {
        acc.push({
          ...question,
          position: question.position - 1,
        });
        return acc;
      }

      acc.push(question);
      return acc;
    }, []);

    const transformedQuestionsState = questionsState.reduce(
      (acc, question, qIndex) => {
        if (qIndex === index) {
          return acc;
        }

        if (qIndex > index) {
          acc.push({
            ...question,
            position: question.position - 1,
          });
          return acc;
        }

        acc.push(question);
        return acc;
      },
      [],
    );

    setQuestionsState(transformedQuestionsState);
    setQuestions(transformedQuestions);
  };

  const sortedQuestions =
    questions?.sort((a, b) => a.position - b.position) || [];

  const { isSubmitButtonEnabled, errors } =
    getIsQuestionSubmitButtonEnabled(questionsState);

  return (
    <>
      {!SideBarView && (
        <section className={styles.header_container}>
          <Link to={ROUTES.Templates}>
            <IoArrowBack color="#101828" size={16} />
          </Link>
          <div className={styles.header_container__icon}>
            <FiFileText color="#00a7b7" size={16} />
          </div>
          <h2 className={styles.header_container__heading}>{templateName}</h2>
        </section>
      )}
      <div className={`${styles.Questionaire} ${SideBarView ? 'pt-0' : ''}`}>
        <section
          className={'d-flex flex-column ' + styles.Questionaire__Section}
        >
          <div
            className={`${styles.Questionaire_InnerWrapper} ${
              SideBarView ? 'px-2 py-3 shadow-none' : ''
            }`}
          >
            <section
              className={`d-flex ${styles.Question} ${
                SideBarView && 'flex-column gap-0'
              }`}
            >
              <div className="w-100">
                {!SideBarView && (
                  <>
                    <h2 className={styles.Question_questionText}>
                      What do you want to write about?
                    </h2>
                    <span className={styles.Question_subStr}>
                      Let our editor help you form your ideas
                    </span>
                  </>
                )}
                <div className={styles.Question_Form_Wrapper}>
                  <Form
                    className={`d-flex flex-column ${styles.Question_Form} ${
                      SideBarView && 'pt-3 gap-2'
                    }`}
                  >
                    {sortedQuestions.map((subQuestion, qIndex) => {
                      const {
                        input_type: inputType,
                        placeholder,
                        title,
                        options,
                        multiple,
                        childId,
                        id: questionId,
                        position,
                      } = subQuestion || {};

                      const currentQuestionState = questionsState?.[qIndex];
                      const { inputValue, selectedOption } =
                        currentQuestionState || {};

                      const parentPosition = questions.find(
                        (question) => question.id === questionId,
                      ).position;

                      const questionTitle = multiple
                        ? childId
                          ? `${title} ${position - parentPosition + 1}`
                          : `${title} ${1}`
                        : title;

                      const error = errors[questionTitle || placeholder];

                      const questionPosition = questions.findLast(
                        (question) => question.id === questionId,
                      ).position;

                      return (
                        <React.Fragment key={childId || questionId}>
                          <AnswerComponent
                            SideBarView={SideBarView}
                            type={inputType}
                            placeholder={placeholder}
                            inputValue={inputValue}
                            handleInputChange={handleInputChange}
                            title={questionTitle}
                            handleChange={handleChange}
                            selectedOption={selectedOption}
                            options={options}
                            quesIndex={qIndex}
                          />
                          {!!inputValue && error && (
                            <div className="error">{error}</div>
                          )}

                          <div className="d-flex flex-row justify-content-evenly">
                            {questionPosition === position && multiple && (
                              <Button
                                className={`${styles.FormContinueBtn} ${
                                  SideBarView ? styles.FormContinueBtn_sm : ''
                                }`}
                                variant="dark"
                                onClick={() =>
                                  handleMultipleQuestionAdd(questionId, qIndex)
                                }
                              >
                                +<span>Add {title}</span>
                              </Button>
                            )}

                            {childId && (
                              <Button
                                className={`${styles.FormContinueBtn} ${
                                  SideBarView ? styles.FormContinueBtn_sm : ''
                                } `}
                                variant="dark"
                                onClick={() =>
                                  handleRemoveChildQuestion(qIndex)
                                }
                              >
                                -<span>Remove {title}</span>
                              </Button>
                            )}
                          </div>
                        </React.Fragment>
                      );
                    })}
                    <BSButton
                      classList={`${styles.FormContinueBtn} secondary_btn ${
                        SideBarView ? styles.FormContinueBtn_sm : ''
                      }`}
                      BtnWrapperClass={
                        SideBarView ? 'd-flex justify-content-end' : ''
                      }
                      variant={SideBarView ? 'dark' : 'dark'}
                      disabled={!isSubmitButtonEnabled}
                      onClick={() => handleBlockSave()}
                      icon={
                        SideBarView ? (
                          <FiRefreshCw />
                        ) : (
                          <BsArrowRightShort size={32} />
                        )
                      }
                      iconPosition={SideBarView ? 'left' : 'right'}
                      ButtonText={SideBarView ? 'Regenerate' : `Continue`}
                    />
                  </Form>
                </div>
              </div>
            </section>
          </div>
        </section>

        <ContentGenerationModal
          onHide={() => {
            setOpenModal(false);
            setStreamContent('');
            setContentGenerated(false);
            abortController?.current.abort();
          }}
          show={openModal}
          string={streamContent}
          contentGenerated={contentGenerated}
        />
      </div>
    </>
  );
};

export default Questionnaire;
