import { getRequest, postRequest } from 'helpers/axios';

// ##### ##### ##### ##### #####   GET   ##### ##### ##### ##### ##### //
export const getNotifications = (page) => {
  return getRequest(`notifications?page=${page}`);
};

// ##### ##### ##### ##### #####   POST   ##### ##### ##### ##### ##### //
export const markAllNotifications = () => {
  return postRequest('notifications/mark_all_as_read');
};
