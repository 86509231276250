import { useQuery } from '@tanstack/react-query';

import keys from 'constants/queryKeys';
import { getQuestionResponse } from 'services/questionService';

const useQuestions = (templateId) => {
  const query = useQuery({
    queryKey: keys.Questions(templateId),
    queryFn: () => getQuestionResponse(templateId),
    staleTime: Infinity,
  });

  return {
    ...query,
    ...query?.data?.data,
  };
};

export default useQuestions;
