import { useMutation } from '@tanstack/react-query';
import { starDocument } from 'services/documentService';

import { error as renderError } from 'helpers/toaster';

const useStarDocument = () => {
  return useMutation({
    mutationFn: starDocument,
    onError: (error) => {
      renderError(error);
    },
  });
};

export default useStarDocument;
