import React, { useCallback, useEffect, useState } from 'react';
import Styles from './PlanDetailScreen.module.scss';
import SubscriptionCard from './SubscriptionCard';
import { SUBSCRIBE_PLAN } from 'constants';
import Header from 'Components/Pages/PricingPlans/Header';
import useFetchPlans from 'hooks/subscriptions/fetchPlans';

const Subscription = ({ closeDrawer, refetch, ...props }) => {
  const [currentTab, setCurrentTab] = useState(1);
  const [subscriptionPlans, setSubscriptionPlans] = useState(
    props.subscriptionPlans ?? {},
  );
  const [currentSubscription, setCurrentSubscription] = useState(
    props.currentSubscription ?? null,
  );
  const [lastSubscription, setLastSubscription] = useState(
    props.lastSubscription ?? null,
  );
  const [paymentMethods, setPaymentMethods] = useState(
    props.paymentMethods ?? {},
  );

  const fetchPlansData = useFetchPlans();
  const fetchPlans = useCallback(async (setShouldRedirect) => {
    if (
      !(
        props.subscriptionPlans &&
        props.lastSubscription &&
        props.currentSubscription &&
        props.paymentMethods
      )
    ) {
      fetchPlansData({
        setPaymentMethods,
        setSubscriptionPlans,
        setCurrentSubscription,
        setLastSubscription,
        setShouldRedirect,
      });
    }
  }, []);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  useEffect(() => {
    setCurrentSubscription(props.currentSubscription);
  }, [props.currentSubscription]);

  const renderTab = useCallback(() => {
    switch (currentTab) {
      case 1:
        return (
          <SubscriptionCard
            plan={subscriptionPlans.monthlyPlan}
            currentSubscription={currentSubscription}
            lastSubscription={lastSubscription}
            refetch={refetch ?? fetchPlans}
            hasPaymentMethod={Boolean(paymentMethods)}
            closeDrawer={closeDrawer}
            setProductData={props.setProductData}
            setTrial={props.setTrial}
            fullHeight={true}
            // setSummaryData={props.setSummaryData}
          />
        );
      case 2:
        return (
          <SubscriptionCard
            plan={subscriptionPlans.annualplan}
            currentSubscription={currentSubscription}
            lastSubscription={lastSubscription}
            refetch={refetch ?? fetchPlans}
            hasPaymentMethod={Boolean(paymentMethods)}
            closeDrawer={closeDrawer}
            setProductData={props.setProductData}
            fullHeight={true}
            // setSummaryData={props.setSummaryData}
          />
        );
      default:
        return <h1>Something went wrong</h1>;
    }
  }, [
    currentSubscription,
    currentTab,
    fetchPlans,
    lastSubscription,
    subscriptionPlans.annualplan,
    subscriptionPlans.monthlyPlan,
  ]);

  const isPlanCanceled =
    currentSubscription?.status === 'canceled' && currentSubscription?.active;

  const isSubscribePlan = window.location.pathname.includes(SUBSCRIBE_PLAN);

  const handleToggleClick = () => {
    // Toggle between tabs based on the current tab
    const newTabId = currentTab === 1 ? 2 : 1;
    setCurrentTab(newTabId);
  };

  return (
    <>
      <div className={Styles.layout}>
        <div className={Styles.header}>
          <Header
            isChecked={currentTab === 2}
            handleToggleClick={handleToggleClick}
          />
          {closeDrawer && (
            <button className={Styles.close_btn} onClick={closeDrawer}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M15 5L5 15M5 5L15 15"
                  stroke="#344054"
                  stroke-width="1.67"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          )}
        </div>
        {renderTab()}
      </div>
    </>
  );
};

export default Subscription;
