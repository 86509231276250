import { stringify } from 'qs';

const TEMPLATES_ROUTE = '/templates';
const PROJECTS = '/projects';
const EDITOR = '/editor';
const FREESTYLE_EDITOR = '/freestyle-editor';
const QUESTIONNAIRE = '/questionnaire';
const TEAMS = '/teams';
const DOCUMENT_HISTORY = '/documentHistory';
const LIBRARY = '/library';
const FOLDER = '/folder';
const PLANNER = '/content-planner';
const IDEATE = '/ideate';
const RESEARCH = '/research';
const SUMMARIZATION = '/summarization';
const SUBSCRIPTION = '/subscription';
const CONFIRM_SUBSCRIPTION = '/confirm-subscription';
const BILLING_SUBSCRIPTION = '/billing-subscription';
const BILLING_PAYMENT = '/billing-payment';
const BILLING_PLAN = '/billing-plan';
const NEW_PLAN = '/new-plan';
const BRAND = '/brand';
const INTEGRATION = '/integrations';

export const ROUTES = {
  HomePage: '/',
  Templates: TEMPLATES_ROUTE,
  Projects: PROJECTS,
  Project: {
    New: `${PROJECTS}/new`,
    Setup: `${PROJECTS}/new/setup`,
    NewPlan: (projectId, queryParams) =>
      `${PROJECTS}/${projectId}/newPlan${
        queryParams ? `?${stringify(queryParams)}` : ''
      }`,
    Folder: (projectId, folderId) =>
      `${PROJECTS}/${projectId}/folders/${folderId}`,
  },
  ProjectSlug: (id, queryParams) =>
    `${PROJECTS}/${id}${queryParams ? `?${stringify(queryParams)}` : ''}`,
  Folder: (folderId) => `${FOLDER}/${folderId}`,
  PLANNER,
  Onboarding: '/onboarding',
  // OnboardingInfo: '/onboarding/info',
  Planner: PLANNER,
  PlannerBrief: (id) => `${PLANNER}/brief/${id}`,
  DocumentHistory: (documentId) => `${DOCUMENT_HISTORY}/${documentId}`,
  Library: LIBRARY,
  Teams: TEAMS,
  TeamSlug: (teamId) => `${TEAMS}/${teamId}`,
  Research: RESEARCH,
  Ideate: IDEATE,
  Analyze: SUMMARIZATION,
  EditorDocumentTemplate: (documentId, templateId) =>
    `${EDITOR}/document/${documentId}/template/${templateId}`,
  FreestyleDocumentTemplate: (documentId) =>
    `${FREESTYLE_EDITOR}/document/${documentId}`,
  QuestionnaireTemplate: (templateId, queryParams) =>
    `${QUESTIONNAIRE}/template/${templateId}${
      queryParams ? `?${stringify(queryParams)}` : ''
    }`,
  SubscriptionPlan: '/subscription-plan',
};

export const ROUTES_REQUIRE_LAYOUT = [
  PLANNER,
  LIBRARY,
  TEAMS,
  TEMPLATES_ROUTE,
  IDEATE,
  SUMMARIZATION,
  RESEARCH,
  FOLDER,
  PROJECTS,
  EDITOR,
  FREESTYLE_EDITOR,
  QUESTIONNAIRE,
  SUBSCRIPTION,
  CONFIRM_SUBSCRIPTION,
  BILLING_SUBSCRIPTION,
  BILLING_PAYMENT,
  NEW_PLAN,
  // BILLING_PLAN,
  BRAND,
  INTEGRATION,
];

export const ROUTES_REQUIRE_DIFF_STYLE = [
  EDITOR,
  FREESTYLE_EDITOR,
  QUESTIONNAIRE,
];
