import { useInfiniteQuery } from '@tanstack/react-query';
import { getProjects } from 'services/projectService';
import keys from 'constants/queryKeys';

const useProjects = ({ searchTerm, type = '', enabled = true }) => {
  return useInfiniteQuery({
    enabled,
    queryKey: keys.InfiniteProjects(searchTerm, type),
    queryFn: ({ pageParam }) => getProjects(searchTerm, type, pageParam),
    initialPageParam: 1,
    keepPreviousData: true,
    getNextPageParam: ({ data }) => {
      const {
        meta_data: { pagination },
      } = data;
      if (pagination.current_page < pagination.total_pages) {
        return pagination.current_page + 1;
      }
      return undefined;
    },
  });
};

export default useProjects;
