import { toast } from 'react-toastify';
import { humanize } from 'helpers/index';

const error = (error, toastId = '') => {
  const message =
    (error.response &&
      error.response.data &&
      error.response.data.error &&
      (error.response.data.error?.message || error.response.data.error)) ||
    error.message ||
    error.toString();

  if (!message) return;

  toast.error(humanize(message), { autoClose: 2000, toastId });
};

const success = (message) => {
  if (!message) return;

  toast.success(humanize(message.toString()), { autoClose: 2000 });
};

export { error, success };
