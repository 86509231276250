import React, { forwardRef } from 'react';

import { FiPlus } from 'react-icons/fi';
import Styles from './FloatingButton.module.scss';

const FloatingButton = forwardRef(
  ({ bgColor = '#1d2939', iconColor = '#fff', size = 27.43, onClick }, ref) => {
    return (
      <div
        ref={ref}
        onClick={onClick}
        className={Styles.FloatingButtonWrapper}
        style={{ backgroundColor: bgColor }}
      >
        <FiPlus color={iconColor} size={size} />
      </div>
    );
  },
);

FloatingButton.displayName = 'FloatingButton';

export default FloatingButton;
