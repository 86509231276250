import React from 'react';

import Styles from './AIProjectHeader.module.scss';
import { FiCheck } from 'react-icons/fi';

const HeaderStep = ({ title, description, step, currentStep, completed }) => {
  return (
    <div
      className={Styles.Header_Step}
      data-step={currentStep === step ? 'active' : ''}
    >
      <div>
        <h5>{title}</h5>
        {currentStep === step && completed && (
          <span className={Styles.Header_Step_Completed}>
            <FiCheck color="#027A48" />
          </span>
        )}
      </div>
      <p>{description}</p>
    </div>
  );
};

const AIProjectHeader = ({ step, completed }) => {
  return (
    <div className={Styles.Header}>
      <HeaderStep
        title="Campaign Setup"
        description="Define Campaign overview"
        step="setup"
        currentStep={step}
        completed={completed}
      />
      <HeaderStep
        title="Content Plan"
        description="Auto-generated content ideas"
        step="contentPlan"
        currentStep={step}
        completed={completed}
      />
    </div>
  );
};

export default AIProjectHeader;
