import React from 'react';

import Styles from './IdeateCard.module.scss';
import { FiCalendar, FiEdit3 } from 'react-icons/fi';

const IdeateCard = ({ title, description, addPlanner, startDraft }) => {
  return (
    <div className={Styles.IdeateCard}>
      <div className={Styles.IdeateCard_Top}>
        <div className={Styles.IdeateCard_Top_Title}>{title}</div>
        <div className={Styles.IdeateCard_Top_Desc}>{description}</div>
      </div>
      <div className={Styles.IdeateCard_Bottom}>
        <div className={Styles.IdeateCard_Bottom__btn} onClick={addPlanner}>
          <FiCalendar />
          <span>Add to Planner</span>
        </div>
        <div className={Styles.IdeateCard_Bottom__btn} onClick={startDraft}>
          <FiEdit3 />
          <span>Start draft</span>
        </div>
      </div>
    </div>
  );
};

export default IdeateCard;
