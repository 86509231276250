import React, { useState } from 'react';
import AIProjectHeader from '../Header/AIProjectHeader';
import PageStyles from '../AIProject.module.scss';
import { Formik, Form } from 'formik';
import NameAndDescriptionStep from './NameAndDescriptionStep';
import ContentTypeAndDateStep from './ContentTypeAndDateStep';
import MoreDetailsStep from './MoreDetailsStep';
import { error as errorToast, success as successToast } from 'helpers/toaster';
import { createProject } from 'services/projectService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { aiProjectCreateSchema } from 'schemas/projectSchemas';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/slices/aiProject';
import { GTM_EVENTS, pushEvent } from 'analytics/gtm';
import BSButton from 'Components/Button/BSButton';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import BackToOnboarding from 'Components/Onboarding/BackToOnboarding/BackToOnboarding';

const AIProjectSetup = () => {
  const [moreDetails, setMoreDetails] = useState(false);
  const [setupStep, setSetupStep] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [typeButton, setTypeButton] = useState('button');

  const [URLSearchParams] = useSearchParams();
  const onboarding = URLSearchParams.get('onboarding');

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const initialValues = {
    name: '',
    description: '',
    contentType: [],
    surpriseMe: false,
    startDate: '',
    endDate: '',
    targetAudience: '',
    keyMessage: '',
  };

  const nextStep = () => {
    setSetupStep((prevStep) => prevStep + 1);
  };

  const previousStep = () => {
    setSetupStep((prevStep) => prevStep - 1);
  };

  const isNotGetStartedStep = setupStep !== 0;
  const isFirstStep = setupStep === 1;
  const isSecondStep = setupStep === 2;

  const previousButton = () => {
    return {
      text: isNotGetStartedStep ? '' : 'Cancel',
      icon: isNotGetStartedStep ? <FiArrowLeft size={18} /> : '',
      fn: isNotGetStartedStep
        ? previousStep
        : () => navigate(ROUTES.Project.New),
    };
  };

  const nextButton = () => {
    let text = 'Next';

    if (isSecondStep) {
      text = moreDetails
        ? 'Generate content plan'
        : 'Skip to generate content plan';
    }

    const fn = () => {
      if (isSecondStep) {
        setTypeButton('submit');
      }

      if (!isSecondStep) {
        nextStep();
      }
    };

    return {
      text,
      icon: !isSecondStep ? <FiArrowRight size={18} /> : '',
      fn,
    };
  };

  const handleSubmit = async (values) => {
    try {
      const newProject = await createProject({
        body: {
          name: values.name,
          objective: values.description,
          start_date: values.startDate,
          end_date: values.endDate,
          target_audience: values.targetAudience,
          content_types: values.contentType.join(', '),
          key_message: values.keyMessage,
        },
      });

      pushEvent(GTM_EVENTS.PROJECT_CREATED, { user_id: user?.id });

      const {
        data: { project },
      } = newProject ?? {};

      dispatch(actions.setProject(project));

      successToast('Campaign Created!');
      setCompleted(true);
      navigate(ROUTES.Project.NewPlan(project.id, { isManual: false }));
    } catch (error) {
      errorToast(error);
    }
  };

  const previousButtonProps = previousButton();
  const nextButtonProps = nextButton();

  return (
    <div className={PageStyles.AIProjectContainer}>
      <AIProjectHeader step="setup" completed={completed} />
      <div className={PageStyles.AIProjectContainer_Body}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={aiProjectCreateSchema}
        >
          {({ values, errors, setFieldValue }) => {
            const isNameValid =
              !errors?.name && values?.name !== initialValues.name;

            const isDescriptionValid =
              !errors?.description &&
              values?.description !== initialValues.description;

            const isContentTypeValid =
              (!errors?.contentType &&
                values?.contentType.length !==
                  initialValues.contentType.length) ||
              values.surpriseMe;

            const areDatesValid =
              !errors?.startDate &&
              !errors?.endDate &&
              values?.startDate !== initialValues.startDate &&
              values?.endDate !== initialValues.endDate;

            const isValid = () => {
              if (!isNotGetStartedStep) {
                return isNameValid && isDescriptionValid;
              }

              if (isFirstStep) {
                return isContentTypeValid && areDatesValid;
              }

              if (isSecondStep) {
                return true;
              }
            };

            return (
              <Form className="align-self-stretch">
                {!isNotGetStartedStep && <NameAndDescriptionStep />}
                {isFirstStep && (
                  <ContentTypeAndDateStep setFieldValue={setFieldValue} />
                )}
                {isSecondStep && (
                  <MoreDetailsStep
                    moreDetails={moreDetails}
                    toggleMoreDetails={setMoreDetails}
                  />
                )}
                <div
                  className={
                    PageStyles.AIProjectContainer_Body_Step_ButtonActions
                  }
                >
                  <BSButton
                    classList="button"
                    ButtonText={previousButtonProps.text}
                    variant="outline-secondary"
                    icon={previousButtonProps.icon}
                    type="button"
                    onClick={() => previousButtonProps.fn()}
                  />
                  <BSButton
                    disabled={!isValid()}
                    classList="button secondary_btn"
                    ButtonText={nextButtonProps.text}
                    variant="light"
                    iconPosition="right"
                    icon={nextButtonProps.icon}
                    type={typeButton}
                    onClick={() => nextButtonProps.fn()}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {onboarding && <BackToOnboarding />}
    </div>
  );
};

export default AIProjectSetup;
