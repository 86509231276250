// export const getHealthStr = (value) => {
//   // if (value >= 90) {
//   //   return 'Great';
//   // }
//   if (value >= 80) {
//     return 'Great';
//   }
//   if (value >= 70) {
//     return 'Average';
//   }
//   if (value >= 60) {
//     return 'Poor';
//   }

//   return 'Poor';
// };

export const getHealthStr = (value) => {
  // if (value >= 90) {
  //   return 'Great';
  // }
  if (value >= 80) {
    return 'Great';
  }
  if (value >= 40) {
    return 'Average';
  }
  if (value < 40) {
    return 'Poor';
  }

  return 'Poor';
};

export const secondsToMinutesAndSeconds = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${minutes}m ${seconds}s`;
};
