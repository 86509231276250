import { getRequest, patchRequest, postRequest } from 'helpers/axios';
import { stringify } from 'qs';

// ##### ##### ##### ##### #####   GET   ##### ##### ##### ##### ##### //
export const getUsers = (search, page = 1) => {
  const queryParams = {
    page,
    'search[name_cont]': search,
  };

  return getRequest(`system_users?${stringify(queryParams)}`);
};

const getUser = async () => {
  const response = await getRequest('profile');
  return response?.data;
};

export const getAccessLevels = () => {
  return getRequest('access_levels');
};

// ##### ##### ##### ##### #####   POST   ##### ##### ##### ##### ##### //
export const confirmEmail = (token) => {
  return postRequest('confirm_email', { token });
};

const updateUser = (body) => {
  return patchRequest('signup', body, {
    'content-type': 'multipart/form-data',
  }).then((response) => {
    const oldUser = getLocalStorageUser();
    if (response?.data) {
      const payload = {
        ...response?.data?.user,
        token: oldUser.token,
      };
      updateLocalStorageUser(payload);

      return response;
    }
  });
};

const updateProfile = (body) => {
  return patchRequest('update_user', body, {
    'content-type': 'multipart/form-data',
  }).then((response) => {
    const oldUser = getLocalStorageUser();
    if (response?.data) {
      const payload = {
        ...response?.data?.user,
        token: oldUser.token,
      };
      updateLocalStorageUser(payload);

      return response;
    }
  });
};

const updateUserPreferences = async (preferences) => {
  const response = await patchRequest('update_user_preferences', {
    user: {
      preferences,
    },
  });
  return response?.data;
};

const getLocalStorageUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

const updateLocalStorageUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const userService = {
  updateUser,
  getUser,
  updateUserPreferences,
  getLocalStorageUser,
  updateLocalStorageUser,
  updateProfile,
};

export default userService;
