import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { Menu, MenuDivider, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import UploadImageModal from 'Containers/Modal/UploadImageModal/UploadImageModal';
import { remCalc } from 'helpers';
import {
  FiMinus,
  FiPlus,
  FiBold,
  FiItalic,
  FiUnderline,
  FiLink,
  FiList,
} from 'react-icons/fi';
import { MdRedo, MdUndo } from 'react-icons/md';
import {
  TbAlignCenter,
  TbAlignJustified,
  TbAlignLeft,
  TbAlignRight,
  TbLineHeight,
  TbListNumbers,
  TbStrikethrough,
} from 'react-icons/tb';
import { BiImageAdd } from 'react-icons/bi';

const getPTag = (target) => {
  if (['P', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(target.tagName)) {
    return target;
  }

  return getPTag(target.parentNode);
};

const MenuBar = React.forwardRef(({ editor, styles, openModalLink }, ref) => {
  const [fontSize, setFontSize] = useState(16);
  const [lineHeight, setLineHeight] = useState('1rem');
  const [marginTop, setMarginTop] = useState('0px');
  const [marginBottom, setMarginBottom] = useState('0px');
  const [UploadImageModalView, setUploadImageModalView] = useState(false);

  useEffect(() => {
    const handleClick = (event) => {
      const editorDiv = [...document.getElementsByClassName('ProseMirror')][0];

      if (editorDiv.contains(event.target) && editor?.state.selection.empty) {
        try {
          const font = window
            .getComputedStyle(event.target, null)
            .getPropertyValue('font-size');
          const line = window
            .getComputedStyle(getPTag(event.target), null)
            .getPropertyValue('line-height');
          const marginTop = window
            .getComputedStyle(getPTag(event.target), null)
            .getPropertyValue('margin-top');
          const marginBottom = window
            .getComputedStyle(getPTag(event.target), null)
            .getPropertyValue('margin-bottom');

          const transformedLineHeight = remCalc(line);
          setFontSize(Math.floor(Number(font.split('px')[0])));
          setLineHeight(transformedLineHeight);
          setMarginBottom(remCalc(marginBottom));
          setMarginTop(remCalc(marginTop));
        } catch (error) {}
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => document.removeEventListener('mousedown', handleClick);
  }, []);

  if (!editor) {
    return null;
  }

  const getSelectValue = () => {
    if (editor.isActive('paragraph') || editor.isActive('span')) {
      return '1';
    }

    if (editor.isActive('heading', { level: 1 })) {
      return '2';
    }

    if (editor.isActive('heading', { level: 2 })) {
      return '3';
    }

    if (editor.isActive('heading', { level: 3 })) {
      return '4';
    }
  };

  const handleSelectChange = (value) => {
    switch (Number(value)) {
      case 1:
        editor.chain().focus().setParagraph().run();
        break;
      case 2:
        editor.chain().focus().toggleHeading({ level: 1 }).run();
        break;
      case 3:
        editor.chain().focus().toggleHeading({ level: 2 }).run();
        break;
      case 4:
        editor.chain().focus().toggleHeading({ level: 3 }).run();
        break;

      default:
        break;
    }
  };

  const decreaseFontSize = () => {
    if (fontSize === 1) {
      return;
    }

    if (!editor.state.selection.empty) {
      editor
        .chain()
        .focus()
        .setFontSize(`${fontSize - 1}px`)
        .run();
    }

    setFontSize((value) => value - 1);
  };

  const increaseFontSize = () => {
    if (fontSize === 96) {
      return;
    }

    if (!editor.state.selection.empty) {
      editor
        .chain()
        .focus()
        .setFontSize(`${fontSize + 1}px`)
        .run();
    }

    setFontSize((value) => value + 1);
  };

  const handleChange = ({ target: { value } }) => {
    if (!editor.state.selection.empty) {
      editor.chain().focus().setFontSize(`${value}px`).run();
    }

    setFontSize(Number(value));
  };

  const lineSpacingOption = [
    { name: 'Single', value: ['1rem'] },
    { name: '1.15', value: ['1.15rem', '1.125rem'] },
    { name: '1.5', value: ['1.5rem'] },
    { name: 'Double', value: ['2rem'] },
    {
      name: `${
        Number(marginTop.replace(/(px|rem)/, '')) ? 'Remove' : 'Add'
      } space before paragraph`,
      value: ['100rem'],
    },
    {
      name: `${
        Number(marginBottom.replace(/(px|rem)/, '')) ? 'Remove' : 'Add'
      } space after paragraph`,
      value: ['100rem'],
    },
  ];

  const handleParagraphSpacing = (name, value) => {
    if (name === 'Add space before paragraph') {
      editor.chain().focus().setBeforeParagraphSpacing('2rem', true).run();
    } else if (name === 'Add space after paragraph') {
      editor.chain().focus().setAfterParagraphSpacing('2rem', false).run();
    } else if (name === 'Remove space before paragraph') {
      editor.chain().focus().unsetBeforeParagraphSpacing().run();
    } else if (name === 'Remove space after paragraph') {
      editor.chain().focus().unsetAfterParagraphSpacing().run();
    } else {
      editor.chain().focus().setLineHeight(value[0]).run();
    }
  };

  return (
    <>
      <div className="menuBar" ref={ref} style={styles}>
        <div>
          <button onClick={() => editor.chain().focus().undo().run()}>
            <MdUndo color="#101828" />
          </button>
          <button onClick={() => editor.chain().focus().redo().run()}>
            <MdRedo color="#101828" />
          </button>

          <span />

          <Form.Select
            value={getSelectValue()}
            onChange={({ target: { value } }) => handleSelectChange(value)}
            className="editor_select_text_formates"
          >
            <option value="1">Paragraph</option>
            <option value="2">Heading 1</option>
            <option value="3">Heading 2</option>
            <option value="4">Heading 3</option>
          </Form.Select>

          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive('bold') ? 'is_active' : ''}
          >
            <FiBold color="#101828" />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive('italic') ? 'is_active' : ''}
          >
            <FiItalic color="#101828" />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={editor.isActive('underline') ? 'is_active' : ''}
          >
            <FiUnderline color="#101828" />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={editor.isActive('strike') ? 'is_active' : ''}
          >
            <TbStrikethrough color="#101828" />
          </button>

          <span />

          <button
            onClick={() => openModalLink()}
            disabled={editor.state.selection.empty}
          >
            <FiLink color="#101828" />
          </button>
          <button
            onClick={() => {
              setUploadImageModalView(true);
            }}
          >
            <BiImageAdd color="#101828" />
          </button>
          <Menu
            menuButton={
              <button>
                <TbLineHeight color="#101828" />
              </button>
            }
            transition
          >
            {lineSpacingOption.map((option, index) => {
              return (
                <React.Fragment key={index}>
                  <MenuItem
                    type="checkbox"
                    checked={option.value.includes(lineHeight)}
                    onClick={() =>
                      handleParagraphSpacing(option.name, option.value)
                    }
                  >
                    {option.name}
                  </MenuItem>

                  {[
                    'Add space before paragraph',
                    'Remove space before paragraph',
                  ].includes(lineSpacingOption[index + 1]?.name) && (
                    <MenuDivider />
                  )}
                </React.Fragment>
              );
            })}
          </Menu>
          <button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive('bulletList') ? 'is_active' : ''}
          >
            <FiList color="#101828" />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={editor.isActive('orderedList') ? 'is_active' : ''}
          >
            <TbListNumbers color="#101828" />
          </button>

          <span />

          <button
            onClick={() => editor.chain().focus().setTextAlign('left').run()}
            className={
              editor.isActive({ textAlign: 'left' }) ? 'is_active' : ''
            }
          >
            <TbAlignLeft color="#101828" />
          </button>
          <button
            onClick={() => editor.chain().focus().setTextAlign('right').run()}
            className={
              editor.isActive({ textAlign: 'right' }) ? 'is_active' : ''
            }
          >
            <TbAlignRight color="#101828" />
          </button>
          <button
            onClick={() => editor.chain().focus().setTextAlign('center').run()}
            className={
              editor.isActive({ textAlign: 'center' }) ? 'is_active' : ''
            }
          >
            <TbAlignCenter color="#101828" />
          </button>
          <button
            onClick={() => editor.chain().focus().setTextAlign('justify').run()}
            className={
              editor.isActive({ textAlign: 'justify' }) ? 'is_active' : ''
            }
          >
            <TbAlignJustified color="#101828" />
          </button>
        </div>
      </div>

      {UploadImageModalView && (
        <UploadImageModal
          show={UploadImageModalView}
          onHide={() => {
            setUploadImageModalView(false);
          }}
          editor={editor}
        />
      )}
    </>
  );
});

MenuBar.displayName = 'MenuBar';

export default MenuBar;
