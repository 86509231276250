import React from 'react';
import Styles from './LogosSection.module.scss';
import { ReactComponent as Logo1 } from '../../../assets/landingpage/Mask group.svg';
import { ReactComponent as Logo2 } from '../../../assets/landingpage/mv-logo 1.svg';
import { ReactComponent as Logo3 } from '../../../assets/landingpage/Mask group (1).svg';
import { ReactComponent as Logo4 } from '../../../assets/landingpage/SiteLogo.svg';
import { ReactComponent as Logo5 } from '../../../assets/landingpage/Mask group (2).svg';
import { ReactComponent as Logo6 } from '../../../assets/landingpage/Frame.svg';

const LogosSection = () => {
  return (
    <div className={Styles.logo_container}>
      <Logo1 />
      <Logo2 />
      <Logo3 />
      <Logo4 />
      <Logo5 />
      <Logo6 />
    </div>
  );
};

export default LogosSection;
