import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BriefsKey } from 'constants/queryKeys';
import { addTask } from 'services/contentPlannerService';
import { GTM_EVENTS, pushEvent } from 'analytics/gtm';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster';

const useCreateBrief = ({ userId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addTask,
    onSuccess: (response) => {
      renderSuccess(response?.data?.message);
      queryClient.invalidateQueries({ queryKey: [BriefsKey] });
      pushEvent(GTM_EVENTS.BRIEF_CREATED, { user_id: userId });
    },
    onError: (error) => {
      renderError(error);
    },
  });
};

export default useCreateBrief;
