import { useQuery } from '@tanstack/react-query';
import { getFolderDocuments } from 'services/folderService';
import keys from 'constants/queryKeys';

const useFolderDocuments = ({ folderSlug, enabled }) => {
  const query = useQuery({
    enabled,
    queryKey: keys.LibraryFolder(folderSlug),
    queryFn: () => getFolderDocuments(folderSlug),
  });

  return {
    ...query,
    ...query?.data?.data,
  };
};

export default useFolderDocuments;
