import Styles from './AIProjectPlan.module.scss';
import BSButton from '../../Button/BSButton';
import React, {useState} from 'react';
import AIProjectHeader from '../AIProject/Header/AIProjectHeader';
import { FaCheck } from 'react-icons/fa6';
import { FaPlus } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';
import { getNameInitials } from 'helpers';
import { FiArrowLeft } from 'react-icons/fi';
import ImageView from 'Components/Image/ImageView';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import EditorAddModal from '../EditorAddModal';
import EditorRemoveModal from '../EditorRemoveModal';
import ProjectDrawer from '../ProjectDrawer';
import useProject from 'hooks/projects/useProject';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import FloatingButton from 'ui/FloatingButton/FloatingButton';
import MultiDropdown from 'Components/MultiDropdown/MultiDropdown';
import BriefDrawer from 'Components/Drawers/BriefDrawer/BriefDrawer';
import ExpandPlanDrawer from 'Components/Drawers/ExpandPlanDrawer/ExpandPlanDrawer';
import useCreateBrief from 'hooks/briefs/useCreateBrief';
import { useDispatch, useSelector } from 'react-redux';


const AIPlanLayout = ({
  loading,
  onBoostPlan,
  onAcceptPlan,
  onCancel,
  isManual = 'true',
  children,
  header,
  description,
  buttonText,
  showButton = true,
  projectId
}) => {
  const isProcessManual = isManual === 'true';
  // const project = location.state || {};
  // const { project, campaignCards } = location.state || {};

  const location = useLocation();
  let project, isLoading, refetch;
  if (location.pathname.startsWith('/projects')) {
    ({ project, isLoading, refetch } = useProject({ projectId }));
  }
  const [newTask, setnewTask] = useState({});

  const { user } = useSelector((state) => state.auth);
  const { id: userId } = user || {};

  const navigate = useNavigate();
  const [editorRemoveModalOpen, setEditorRemoveModalOpen] = useState(false);
  const [editorAddModalOpen, setEditorAddModalOpen] = useState(false);
  const [projectDrawerView, setProjectDrawerView] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [showDropdown, setShowDropdown] = useState(true);

  const handleTogglePopover = () => {
    setShowPopover((show) => !show);
  };

  const onConfirmCreateHandler = async (payload) => {
    await createBrief({
      ...payload,
      deadline_date: payload.deadline_date
        ? formattedDate(payload.deadline_date)
        : '',
    });
  };

  const handleItemClick = (item) => {
    // if (item.modalKey === 'expandPlan') {
    //   setShowNestedDropdown(!showNestedDropdown);
    // }
    setModalView({
      expandPlan: false,
      createBrief: false,
      [item.modalKey]: true,
    });
  };

  const [modalView, setModalView] = useState({
    expandPlan: false,
    createBrief: false,
    // addProject: false,
  });

  const { mutateAsync: createBrief } = useCreateBrief({ userId: user?.id });


  const onSubmitHandler = async (action, payload) => {
    if (action === 'create') {
      await onConfirmCreateHandler(payload);
    }

    if (action === 'edit') {
      await onConfirmEditHandler(payload);
    }
  };


  const NewProjectPopover = (
    <Popover>
      <BSButton
        classList="button secondary_btn"
        ButtonText="AI-Powered Campaigns"
        variant="light"
        // onClick={handleAIPowered}
      />
      <BSButton
        classList="button secondary_btn"
        ButtonText="Self-Powered Campaigns"
        variant="outline-secondary"
        onClick={() => {
          setShowPopover(false);
          // setAction('create');
          // setShowProjectDrawer(true);
        }}
      />
    </Popover>
  );

  return (
    <div style={{ padding: '8px 24px' }}>
      <div className={`${Styles.AIPlanLayout_new}`}>
        {isProcessManual ? (
          <>
            {showButton && (
              <>
                <h1 className={Styles.AIPlanTitle}>AI Powered Plan</h1>
                <BSButton
                  variant="light"
                  className="button"
                  type="button"
                  ButtonText="Cancel"
                  onClick={onCancel}
                />
              </>
            )}
          </>
        ) : (
          // <AIProjectHeader step="contentPlan" completed={!loading} />
          // <h1>hiii</h1>
          <div className={Styles.ProjectDetail_titleContainer}>
            <FiArrowLeft
              className={Styles.ProjectDetail_titleContainer_arrow}
              size={24}
              color="#344054"
              onClick={() => navigate(ROUTES.Projects)}
            />
            <div className={Styles.ProjectDetail_titleContainer_title}>
              <p>{project?.name}</p>
              {/* <p
              className={
                StyleClasses.ProjectDetail_titleContainer_description
              }
            >
              {project?.objective}
            </p> */}
            </div>

            <button
              onClick={() => setProjectDrawerView(true)}
              className="cancel_btn text-nowrap"
            >
              Edit Campaign
            </button>
            <div className="d-flex stacked-Images-rounded">
              <div
                className={Styles.ProjectDetail_titleContainer_editors}
                onClick={() => setEditorRemoveModalOpen(true)}
              >
                {project?.editors
                  ?.slice(0, 3)
                  ?.map(({ id, avatar, name: userName }) => {
                    return avatar ? (
                      <ImageView
                        key={id}
                        src={avatar}
                        class="rounded-avatar-bordered"
                      />
                    ) : (
                      <div
                        key={id}
                        className={`rounded-profile ${Styles.avatar}`}
                      >
                        {getNameInitials(userName)}
                      </div>
                    );
                  })}

                {project?.editors.length > 3 && (
                  <div
                    className={Styles.ProjectDetail_titleContainer_editorCount}
                  >
                    {`+${project?.editors.length - 3}`}
                  </div>
                )}
              </div>

              <div
                className={Styles.ProjectDetail_titleContainer_editorAdd}
                onClick={() => setEditorAddModalOpen(true)}
              >
                +
              </div>
            </div>
          </div>
        )}
      </div>
      {!location.pathname.startsWith('/projects') &&
        !isProcessManual &&
        !loading && (
          <div className={Styles.aiFinishedText}>
            <b className={Styles.approvalMenuTitle}>
              Ta-da! There it is…Your content plan is your new roadmap to
              success.
            </b>
            <p className={Styles.approvalMenuDescription}>
              A well-structured content plan is key to executing a successful
              content strategy. We've packed your plan with detailed and
              customizable briefs for each piece of content. Super cool, right?
              Now, all that's left is the fun part—bringing these briefs to
              life!
            </p>
          </div>
        )}

      {children}
      {!loading && (
        <div className={`${Styles.approvalMenu} ${Styles.approvalMenu_footer}`}>
          <p className={Styles.approvalMenuTitle}>
            {header || location.pathname.startsWith('/projects')
              ? 'Happy with your initial plan?'
              : 'Happy with your first content ideas?'}
          </p>
          <p className={Styles.approvalMenuDescription}>
            {description ||
              'You can boost your plan with more briefs now, or choose to add individual briefs later.'}
          </p>
          <div className={Styles.approvalMenuButtons}>
            <BSButton
              icon={<FaPlus />}
              variant="light"
              classList="button white_btn d-flex flex-row-reverse"
              type="button"
              ButtonText={buttonText || 'Boost my plan'}
              onClick={onBoostPlan}
            />
            <BSButton
              icon={<FaCheck />}
              variant="light"
              classList="button secondary_btn d-flex flex-row-reverse"
              type="button"
              ButtonText="View content"
              onClick={onAcceptPlan}
            />
          </div>
        </div>
      )}
      {/* <OverlayTrigger
        placement="top"
        rootClose
        show={showPopover}
        onToggle={handleTogglePopover}
        overlay={NewProjectPopover}
      >
        <FloatingButton 
        onClick={()=>setShowDropdown(true)} 
        />
      </OverlayTrigger> */}

      {showDropdown && location.pathname.startsWith('/projects') && (
        <MultiDropdown
          onClick={handleItemClick}
          showExpand={false}
          higherIndex={true}
        />
      )}

      {editorRemoveModalOpen && (
        <EditorRemoveModal
          show={editorRemoveModalOpen}
          editors={project?.editors}
          onHide={async () => {
            await refetch();
            setEditorRemoveModalOpen(false);
          }}
        />
      )}

      {editorAddModalOpen && (
        <EditorAddModal
          show={editorAddModalOpen}
          editors={project?.editors}
          onHide={async () => {
            await refetch();
            setEditorAddModalOpen(false);
          }}
        />
      )}
      {projectDrawerView && (
        <ProjectDrawer
          show={projectDrawerView}
          onHide={() => {
            setProjectDrawerView(false);
          }}
          action="edit"
          project={project}
          // refreshDashboard={async () => {
          //   await fetchProject();
          // }}
        />
      )}

      {modalView.createBrief && (
        <BriefDrawer
          action="create"
          show={modalView.createBrief}
          onHide={() => {
            setModalView((prevState) => ({
              ...prevState,
              createBrief: false,
            }));
          }}
          onSubmit={onSubmitHandler}
          // task={newTask}
          isProjectView={!!projectId}
        />
      )}

      {modalView.expandPlan && (
        <ExpandPlanDrawer
          show={modalView.expandPlan}
          onHide={() => {
            setModalView((prevState) => ({
              ...prevState,
              expandPlan: false,
            }));
          }}
          title={'Expand Plan'}
          placement="end"
          width={720}
        />
      )}
    </div>
  );
};

export default AIPlanLayout;
