/* @unocss-include */

import {
  TbAlignCenter,
  TbAlignLeft,
  TbAlignRight,
  TbFloatLeft,
  TbFloatRight,
  TbTrash,
} from 'react-icons/tb';
export const resizableMediaActions = [
  {
    tooltip: 'Align left',
    action: (updateAttributes) =>
      updateAttributes({
        dataalign: 'left',
        datafloat: null,
        datastyle: 'display: block; margin: 0 auto 0 0;',
      }),
    icon: TbAlignLeft,
    isActive: (attrs) => attrs.dataalign === 'left',
  },
  {
    tooltip: 'Align center',
    action: (updateAttributes) =>
      updateAttributes({
        dataalign: 'center',
        datafloat: null,
        datastyle: 'display: block; margin: auto;',
      }),
    icon: TbAlignCenter,
    isActive: (attrs) => attrs.dataalign === 'center',
  },
  {
    tooltip: 'Align right',
    action: (updateAttributes) =>
      updateAttributes({
        dataalign: 'right',
        datafloat: null,
        datastyle: 'display: block; margin: 0 0 0 auto;',
      }),
    icon: TbAlignRight,
    isActive: (attrs) => attrs.dataalign === 'right',
  },
  {
    tooltip: 'Float left',
    action: (updateAttributes) =>
      updateAttributes({
        dataalign: null,
        datafloat: 'left',
        datastyle: 'float: left',
      }),
    icon: TbFloatLeft,
    isActive: (attrs) => attrs.datafloat === 'left',
  },
  {
    tooltip: 'Float right',
    action: (updateAttributes) =>
      updateAttributes({
        dataalign: null,
        datafloat: 'right',
        float: 'right',
        datastyle: 'float: right',
      }),
    icon: TbFloatRight,
    isActive: (attrs) => attrs.datafloat === 'right',
  },
  {
    tooltip: 'Delete',
    icon: TbTrash,
    delete: (deleteNode) => deleteNode(),
  },
];
