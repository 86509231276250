import { useQuery } from '@tanstack/react-query';
import { getRecentDocuments } from 'services/documentService';
import keys from 'constants/queryKeys';

const useRecentDocuments = () => {
  const query = useQuery({
    queryKey: keys.RecentDocuments,
    queryFn: () => getRecentDocuments(),
  });

  return {
    ...query,
    ...query?.data?.data,
  };
};

export default useRecentDocuments;
