import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import Style from './Modal.module.scss';

const Modal = ({ show, onHide, size, title, children }) => {
  return (
    <BootstrapModal show={show} onHide={onHide} size={size} centered>
      <BootstrapModal.Header className={Style.ModalHeader} closeButton>
        {title && (
          <BootstrapModal.Title as="h5" className={Style.ModalHeader_Title}>
            {title}
          </BootstrapModal.Title>
        )}
      </BootstrapModal.Header>
      <BootstrapModal.Body className={Style.ModalBody}>
        {children}
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

export default Modal;
