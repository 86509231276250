import React from 'react';
import BSButton from 'Components/Button/BSButton';
import FormikTextField from 'Components/Form/FormikTextField';
import { ErrorMessage, Formik } from 'formik';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { setLoader } from 'redux/slices/loader';
import * as Yup from 'yup';
import { duplicateFolder } from 'services/folderService';

function DuplicateFolderModal({ folder, fetchFolders, show, onHide }) {
  const dispatch = useDispatch();

  const initialValues = {
    title: folder?.title,
  };

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
  });

  const handleSubmit = async ({ title }) => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Duplicating!',
          description: 'Please wait...',
        }),
      );

      const res = await duplicateFolder(folder?.id, title);

      dispatch(setLoader({ isLoading: false }));
      renderSuccess(res?.data?.message);
      fetchFolders();
      onHide();
    } catch (error) {
      renderError(error);
      dispatch(setLoader({ isLoading: false }));
    }
  };

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header className={'pb-0 border-0'} closeButton>
        <Modal.Title className="Modal-Title" id="contained-modal-title-vcenter">
          Duplicate Folder
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={'d-flex flex-column p-4'}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          enableReinitialize={true}
        >
          {({ values, isValid, dirty }) => {
            return (
              <form
                className="d-flex flex-column"
                onSubmit={(e) => e.preventDefault()}
              >
                <FormikTextField
                  name="title"
                  type="text"
                  placeholder="Folder Name"
                  Label="Folder Name *"
                  classList={'input'}
                />
                <ErrorMessage name="title">
                  {(msg) => <div className="error">{msg}</div>}
                </ErrorMessage>
                <BSButton
                  ButtonText="Duplicate"
                  classList="w-100 mt-4 secondary_btn"
                  variant="dark"
                  disabled={!isValid && !dirty}
                  onClick={() => handleSubmit(values)}
                ></BSButton>
              </form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default DuplicateFolderModal;
