// import React from 'react';
// import InfiniteScroll from 'react-infinite-scroll-component';

// import FolderView from 'Components/FolderView/FolderView';
// import DocumentFileView from 'Components/DocumentFileView/DocumentFileView';
// import Styles from './Documents.module.scss';
// import RecentDocumentCard from 'Components/RecentDocumentCard/RecentDocumentCard';
// import useRecentDocuments from 'hooks/documents/useRecentDocuments';
// import CompaignCardSkeleton from 'Components/CompaignCard/CompaignCardSkeleton';
// import EmptyDashboardState from 'Containers/Dashboard/EmptyDashboardState';

// const DocumentsGridView = ({
//   fetchFolders,
//   folders,
//   documents,
//   activeDisplay,
//   isTrash,
//   projectView,
//   next,
//   hasMore,
// }) => {

//   return (
//     <section className={Styles.GridView}>
//       {folders?.length > 0 && activeDisplay !== 'drafts' && (
//         <FolderView
//           folders={folders}
//           fetchFolders={fetchFolders}
//           isTrash={isTrash}
//           projectView={projectView}
//         />
//       )}

//       <InfiniteScroll
        // className={`${folders?.length < 1 ? 'pt-4' : ''} ${Styles.GridView_InfiniteScroll}`}
        // next={next}
        // hasMore={hasMore}
        // dataLength={documents?.length || 0}
//       >
//         {
//          documents?.length > 0  && (
//           <div className={Styles.Dashboard__compaigns}>
//             {documents?.map((document, index) => (
//               <RecentDocumentCard
//                 key={index}
//                 document={document}
//                 isDashboardView={false}
//                 refetch={fetchFolders}
//               />
//             ))}
//           </div>
//         ) 
//         // : (
//           // <EmptyDashboardState
//           //   title="Zero Docs"
//           //   description="Kickstart your content journey—create your first Document now"
//           //   buttonText="New Document"
//           //   backgroundImageUrl="/Images/DashboardEmptyDocsState.svg"
//           //   // onClick={onCreateNewDocument}
//           // />
//         // )
//         }
//       </InfiniteScroll>
//     </section>
//   );
// };

// export default DocumentsGridView;


import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import FolderView from 'Components/FolderView/FolderView';
import DocumentFileView from 'Components/DocumentFileView/DocumentFileView';
import Styles from './Documents.module.scss';

const DocumentsGridView = ({
  fetchFolders,
  folders,
  documents,
  activeDisplay,
  isTrash,
  projectView,
  next,
  hasMore,
}) => {
  return (
    <section className={Styles.GridView}>
      {folders?.length > 0 && activeDisplay !== 'drafts' && (
        <FolderView
          folders={folders}
          fetchFolders={fetchFolders}
          isTrash={isTrash}
          projectView={projectView}
        />
      )}

      <InfiniteScroll
           className={`${folders?.length < 1 ? 'pt-4' : ''} ${Styles.GridView_InfiniteScroll}`}
           next={next}
           hasMore={hasMore}
           dataLength={documents?.length || 0}
      >
        <DocumentFileView
          activeDisplay={activeDisplay}
          documents={documents}
          fetchFolders={fetchFolders}
          isTrash={isTrash}
        />
      </InfiniteScroll>
    </section>
  );
};

export default DocumentsGridView;