import { transformAiResponse } from 'helpers';
import { postRequest } from './axios';

export const generateResponse = async (
  prompt,
  setting = {},
  shouldTransform = true,
) => {
  const completion = await postRequest('completion_data', {
    prompt,
    ...setting,
  });
  const response = completion?.data?.text || '';
  return shouldTransform ? transformAiResponse(response) : response;
};
