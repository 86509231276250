import { postRequest } from 'helpers/axios';
import userService from './userService';
const BASE_URL = '';

const updateUserListEvent = async (event) => {
  const userLocal = userService.getLocalStorageUser();

  const eventCompleted = `${event.name}_completed`;

  if (!(eventCompleted in userLocal.preferences)) {
    const response = await postRequest(BASE_URL + `userlist_events`, {
      event,
    });
    userLocal.preferences[eventCompleted] = true;
    userService.updateLocalStorageUser(userLocal);
    return response?.data;
  }
};

const userListEventsService = {
  updateUserListEvent,
};

export default userListEventsService;
