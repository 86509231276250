import Input from 'Components/Input/Input';
import { LINK_REGEX } from 'constants';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Styles from './LinkModal.module.scss';

export function LinkModal(props) {
  // eslint-disable-next-line no-unused-vars
  const {
    url,
    closeModal,
    onChangeUrl,
    onSaveLink,
    onRemoveLink,
    setUrl,
    ...rest
  } = props;

  const isValidLink = url ? !!url.match(LINK_REGEX) : true;

  return (
    <Modal centered show={true} onHide={closeModal} style={{ zIndex: 99999 }}>
      <Modal.Header className="border-0 pb-0" closeButton>
        <Modal.Title className="Modal-Title">Link Text</Modal.Title>
      </Modal.Header>
      <Modal.Body className={Styles.ModalBody__PickTemplateModal}>
        <Input
          placeholder="Enter Link..."
          autoFocus
          value={url}
          onChange={onChangeUrl}
          classList="input"
        />

        {!isValidLink && <div className="error">Invalid Link</div>}
      </Modal.Body>
      <Modal.Footer className={Styles.ModalFooter}>
        <div className="d-flex justify-content-end gap-2 w-100">
          <Button
            variant="light"
            className={`button white_btn`}
            onClick={() => setUrl('')}
            disabled={!url}
          >
            Remove
          </Button>
          <Button
            variant="dark"
            className={`button secondary_btn`}
            onClick={onSaveLink}
            disabled={!url || !isValidLink}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
