import React from 'react';

export const NewDocumentAI = () => {
  return (
    <svg
      width="98"
      height="88"
      viewBox="0 0 98 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7167 9.76306C21.4915 8.95277 22.537 8.50135 23.6225 8.5H74.3775C75.4631 8.50137 76.5085 8.95279 77.2833 9.76307C78.0588 10.5742 78.4988 11.6785 78.5 12.8348V75.1652C78.4988 76.3215 78.0588 77.4258 77.2833 78.2369C76.5085 79.0473 75.4629 79.4987 74.3772 79.5H23.6228C22.5372 79.4987 21.4916 79.0473 20.7167 78.2369L20.3565 78.5814L20.7167 78.2369C19.9413 77.4259 19.5013 76.3217 19.5 75.1655V12.8346C19.5013 11.6783 19.9413 10.5741 20.7167 9.76306Z"
        fill="white"
        stroke="#D0D5DD"
      />
      <path
        d="M64.7088 47.7209H43.5296C43.4124 47.7211 43.2963 47.6924 43.188 47.6365C43.0797 47.5807 42.9812 47.4987 42.8983 47.3953C42.8154 47.2919 42.7496 47.1691 42.7047 47.0339C42.6598 46.8987 42.6367 46.7539 42.6367 46.6075C42.6367 46.4612 42.6598 46.3163 42.7047 46.1812C42.7496 46.046 42.8154 45.9232 42.8983 45.8198C42.9812 45.7164 43.0797 45.6344 43.188 45.5785C43.2963 45.5227 43.4124 45.494 43.5296 45.4941H64.7088C64.9451 45.4945 65.1717 45.6119 65.3388 45.8207C65.5058 46.0295 65.5997 46.3125 65.5997 46.6075C65.5997 46.9026 65.5058 47.1856 65.3388 47.3944C65.1717 47.6032 64.9451 47.7206 64.7088 47.7209Z"
        fill="#E4E7EC"
      />
      <path
        d="M68.4107 51.4338H43.5304C43.4131 51.434 43.2969 51.4053 43.1885 51.3494C43.0801 51.2936 42.9815 51.2116 42.8985 51.1082C42.8155 51.0048 42.7497 50.882 42.7048 50.7468C42.6598 50.6116 42.6367 50.4667 42.6367 50.3204C42.6367 50.1741 42.6598 50.0292 42.7048 49.8941C42.7497 49.7589 42.8155 49.6361 42.8985 49.5327C42.9815 49.4293 43.0801 49.3473 43.1885 49.2914C43.2969 49.2355 43.4131 49.2069 43.5304 49.207H68.4107C68.6474 49.207 68.8745 49.3243 69.0419 49.5331C69.2093 49.7419 69.3034 50.0251 69.3034 50.3204C69.3034 50.6157 69.2093 50.8989 69.0419 51.1077C68.8745 51.3165 68.6474 51.4338 68.4107 51.4338Z"
        fill="#E4E7EC"
      />
      <path
        d="M64.7078 65.5364H43.5296C43.4124 65.5365 43.2963 65.5079 43.188 65.452C43.0797 65.3961 42.9812 65.3141 42.8983 65.2107C42.8154 65.1073 42.7496 64.9845 42.7047 64.8493C42.6598 64.7142 42.6367 64.5693 42.6367 64.423C42.6367 64.2766 42.6598 64.1318 42.7047 63.9966C42.7496 63.8614 42.8154 63.7386 42.8983 63.6352C42.9812 63.5318 43.0797 63.4498 43.188 63.394C43.2963 63.3381 43.4124 63.3094 43.5296 63.3096H64.7078C64.9444 63.3096 65.1712 63.4269 65.3385 63.6357C65.5057 63.8445 65.5997 64.1277 65.5997 64.423C65.5997 64.7183 65.5057 65.0015 65.3385 65.2103C65.1712 65.4191 64.9444 65.5364 64.7078 65.5364Z"
        fill="#E4E7EC"
      />
      <path
        d="M68.4097 69.2473H43.5304C43.4131 69.2475 43.2969 69.2188 43.1885 69.1629C43.0801 69.107 42.9815 69.025 42.8985 68.9216C42.8155 68.8182 42.7497 68.6954 42.7048 68.5603C42.6598 68.4251 42.6367 68.2802 42.6367 68.1339C42.6367 67.9876 42.6598 67.8427 42.7048 67.7075C42.7497 67.5724 42.8155 67.4496 42.8985 67.3462C42.9815 67.2428 43.0801 67.1608 43.1885 67.1049C43.2969 67.049 43.4131 67.0203 43.5304 67.0205H68.4097C68.527 67.0203 68.6432 67.049 68.7516 67.1049C68.8601 67.1608 68.9586 67.2428 69.0416 67.3462C69.1246 67.4496 69.1904 67.5724 69.2353 67.7075C69.2803 67.8427 69.3034 67.9876 69.3034 68.1339C69.3034 68.2802 69.2803 68.4251 69.2353 68.5603C69.1904 68.6954 69.1246 68.8182 69.0416 68.9216C68.9586 69.025 68.8601 69.107 68.7516 69.1629C68.6432 69.2188 68.527 69.2475 68.4097 69.2473Z"
        fill="#E4E7EC"
      />
      <path
        d="M37.6423 53.1551H28.5205C28.3825 53.155 28.2502 53.1001 28.1526 53.0026C28.055 52.9051 28.0002 52.7729 28 52.635V44.5202C28.0002 44.3823 28.055 44.25 28.1526 44.1525C28.2502 44.055 28.3825 44.0002 28.5205 44H37.6423C37.7803 44.0002 37.9126 44.055 38.0102 44.1525C38.1078 44.25 38.1627 44.3823 38.1628 44.5202V52.635C38.1627 52.7729 38.1078 52.9051 38.0102 53.0026C37.9126 53.1001 37.7803 53.155 37.6423 53.1551Z"
        fill="#E4E7EC"
      />
      <path
        d="M37.6423 70.8378H28.5205C28.3825 70.8376 28.2502 70.7828 28.1526 70.6852C28.055 70.5877 28.0002 70.4555 28 70.3176V62.2028C28.0002 62.0649 28.055 61.9327 28.1526 61.8351C28.2502 61.7376 28.3825 61.6828 28.5205 61.6826H37.6423C37.7803 61.6828 37.9126 61.7376 38.0102 61.8351C38.1078 61.9327 38.1627 62.0649 38.1628 62.2028V70.3176C38.1627 70.4555 38.1078 70.5877 38.0102 70.6852C37.9126 70.7828 37.7803 70.8376 37.6423 70.8378Z"
        fill="#E4E7EC"
      />
      <path
        d="M49 37C54.5229 37 59 32.5228 59 27C59 21.4771 54.5229 17 49 17C43.4772 17 39 21.4771 39 27C39 32.5228 43.4772 37 49 37Z"
        fill="#00A7B7"
      />
      <path
        d="M45.4185 31H43.606L46.3677 23H48.5474L51.3052 31H49.4927L47.4888 24.8281H47.4263L45.4185 31ZM45.3052 27.8555H49.5864V29.1758H45.3052V27.8555ZM53.9595 23V31H52.2681V23H53.9595Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11132_10568"
          x1="38.9997"
          y1="36.9999"
          x2="58.9998"
          y2="16.9998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="red" />
          <stop offset="1" stopColor="red" />
        </linearGradient>
      </defs>
    </svg>
  );
};
