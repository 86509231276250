import React from 'react';
import Styles from './Promote.module.scss';
import OneClick from './Promotion/OneClick';

const Promote = () => {
  return (
    <div>
      <OneClick />
    </div>
  );
};

export default Promote;
