import React, { useCallback, useState } from 'react';
import Input from 'Components/Input/Input';
import { FiSearch } from 'react-icons/fi';
import { debounce } from 'lodash';

import Styles from './SearchInput.module.scss';

const SearchInput = ({
  inputValue,
  setInputValue,
  shouldDebounce = true,
  text,
}) => {
  const [value, setValue] = useState(inputValue);
  const [display, setDisplay] = useState(false);

  const handleDisplay = () => {
    setDisplay((prevDisplay) => !prevDisplay);
  };

  const debounceFn = useCallback(
    debounce((targetValue) => {
      setInputValue(targetValue);
    }, 500),
    [setInputValue],
  );

  const handleOnChange = (e) => {
    e.preventDefault();

    const targetValue = e.target.value;
    setValue(targetValue);

    if (shouldDebounce) {
      debounceFn(targetValue);
    } else {
      setInputValue(targetValue);
    }
  };

  return (
    <div className={Styles.SearchInputWrapper}>
      <div
        className={Styles.SearchInputWrapper_Handler}
        onClick={handleDisplay}
      >
        <FiSearch className="me-2" size={18} />
        <div className={display ? 'd-none' : ''}>
          <span>{text}</span>
        </div>
      </div>
      <Input
        classList={display ? '' : 'd-none'}
        autoFocus={true}
        type="Search"
        placeholder="Type to search.."
        value={value}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default SearchInput;
