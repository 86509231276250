import React from 'react';
import Table from 'react-bootstrap/Table';
import { BiDisc } from 'react-icons/bi';
import { FiCalendar, FiTag, FiUser } from 'react-icons/fi';
import Styles from './KanbanList.module.scss';

import TableHead from 'Components/TableHeader/TableHead';
import ContentPlannerTable from 'Components/ContentPlanner/ContentPlannerTable';

const TableHeadings = [
  {
    id: 'deadline',
    title: 'Deadline',
    icon: <FiCalendar color="#667085" size={16} />,
  },
  {
    id: 'assignedTo',
    title: 'Assigned to',
    icon: <FiUser color="#667085" size={16} />,
  },
  {
    id: 'status',
    title: 'Status',
    icon: <BiDisc color="#667085" size={16} />,
  },
  {
    id: 'contentType',
    title: 'Content Type',
    icon: <FiTag color="#667085" size={16} />,
  },
];

function KanbanList({
  tasks,
  onDelete,
  onEdit,
  onMove,
  fetchCPTasks,
  onArchive,
  onUpdateBrief = () => {},
  selectedColumns = [
    ['deadline', true],
    ['assignedTo', true],
    ['status', true],
    ['contentType', true],
  ],
  updateColumns = null,
  calendarTable,
  handleAICreate,
}) {
  return (
    <section className={Styles.calendar_table}>
      <Table className="table-fixed table-sticky" hover responsive="lg">
        <TableHead
          selectedColumns={selectedColumns}
          updateColumns={updateColumns}
          TableHeadings={TableHeadings}
        />
        <ContentPlannerTable
          tasks={tasks}
          onDelete={onDelete}
          onMove={onMove}
          onEdit={onEdit}
          fetchCPTasks={fetchCPTasks}
          onArchive={onArchive}
          onUpdateBrief={onUpdateBrief}
          selectedColumns={selectedColumns}
          calendarTable={calendarTable}
          handleAICreate={handleAICreate}
        />
      </Table>
    </section>
  );
}

export default KanbanList;
