import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
  OverlayTrigger,
  Tab,
  Dropdown,
  Nav,
  DropdownButton,
} from 'react-bootstrap';
import { BiDisc } from 'react-icons/bi';
import { BsFilter, BsPlus } from 'react-icons/bs';
import { FiTag, FiUser, FiFile, FiFileText } from 'react-icons/fi';
import { RxCross2 } from 'react-icons/rx';
import { TbCalendarMinus } from 'react-icons/tb';

import { setLoader } from 'redux/slices/loader';
import BSButton from 'Components/Button/BSButton';
import Calender from 'Components/Calender/Calender';
import SearchInput from 'ui/SearchInput/SearchInput';
import KanbanBoard from 'Containers/KanbanBoard/KanbanBoard';
import KanbanList from 'Containers/KanbanList/KanbanList';
import MoveToStateModal from 'Containers/Modal/MoveToStateModal/MoveToStateModal';
import BriefDrawer from 'Components/Drawers/BriefDrawer/BriefDrawer';
import { formattedDate } from 'helpers';
import ConfirmDeleteModal from 'Containers/Modal/ConfirmDeleteModal/ConfirmDeleteModal';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import {
  deleteTask,
  updateTask,
  getDashboardConfig,
  updateDashboardConfig,
} from 'services/contentPlannerService';
import ContentFilter from './ContentFilter';
import Styles from './ContentPlanner.module.scss';
import ProjectSelect from './ProjectSelect';
import { ROUTES } from 'constants/routes';
import FloatingButton from 'ui/FloatingButton/FloatingButton';
import Popover from 'Components/Popover/Popover';
import ImageView from 'Components/Image/ImageView';
import EmptyState from 'Components/EmptyState/EmptyState';
import { archiveTask } from 'services/taskService';
import useBriefs from 'hooks/briefs/useBriefs';
import Loader from 'Components/Loader/Loader';
import useCreateBrief from 'hooks/briefs/useCreateBrief';
import { ReactComponent as List } from 'assets/svg/list.svg';
import { ReactComponent as Calendar } from 'assets/svg/calendar.svg';
import { ReactComponent as Kanban } from 'assets/svg/kanban.svg';
import { IoIosList } from 'react-icons/io';
import { TableSkeleton } from 'Components/TableSkeleton/TableSkeleton';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import { AiOutlineProject } from 'react-icons/ai';
import { LuPlus } from 'react-icons/lu';
import MultiDropdown from 'Components/MultiDropdown/MultiDropdown';
import Drawer from 'Components/Drawer/Drawer';
import ExpandPlanDrawer from 'Components/Drawers/ExpandPlanDrawer/ExpandPlanDrawer';
import { generateStreamResponse } from 'helpers/openAIChat';
import { error as errorToast } from 'helpers/toaster';
import useCreateDocument from 'hooks/documents/useCreateDocument';
import ContentGenerationModal from 'Containers/Modal/ContentGenerationModal/ContentGenerationModal';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';
import Announcement from 'Components/Pages/Brand/Announcement';

const optionsClassName =
  'd-flex align-items-center select-option ' + Styles.SelectLabel;

const options = [
  {
    value: 'state',
    label: (
      <div className={optionsClassName}>
        <BiDisc size={14} color="#667085" /> <p>Status</p>
      </div>
    ),
  },
  {
    value: 'deadline_date',
    label: (
      <div className={optionsClassName}>
        <TbCalendarMinus size={14} color="#667085" /> <p>Deadline</p>
      </div>
    ),
  },
  {
    value: 'assignee_id',
    label: (
      <div className={optionsClassName}>
        <FiUser size={14} color="#667085" /> <p>Assigned to</p>
      </div>
    ),
  },
  {
    value: 'content_type',
    label: (
      <div
        className={
          'd-flex align-items-center select-option ' + Styles.SelectLabel
        }
      >
        <FiTag size={14} color="#667085" /> <p>Content Type</p>
      </div>
    ),
  },
];

const isFilterOptions = [
  {
    value: '_in',
    label: <div className={optionsClassName}>is</div>,
  },
  {
    value: '_not_in',
    label: <div className={optionsClassName}>is not</div>,
  },
];

const SelectStyles = {
  option: (styles, { isSelected, isFocused }) => {
    return {
      boxShadow: isFocused ? null : null,
      background: isSelected ? `url("./Images/blueTick.svg")` : '',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '90% 50%',
      backgroundColor: isSelected ? '#F2FBFB!important' : '#fff',
      paddingRight: isSelected ? '0px' : '25px',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: '500',
      color: '#344054',

      '&:hover': {
        backgroundColor: 'lightgrey',
        cursor: 'pointer',
      },
    };
  },

  control: (baseStyles) => {
    return {
      ...baseStyles,
      cursor: 'pointer',
    };
  },
};

const filterOptions = {
  filterBy: '',
  isOptions: '',
};

const parseFilters = (filters) => {
  return filters.reduce((acc, filter) => {
    const { filterBy, isOptions, filterValue } = filter || {};
    let val = '';
    if (['state', 'assignee_id', 'content_type'].includes(filterBy)) {
      val = filterValue
        ? filterValue.reduce((accFilter, filterVal, index) => {
            if (index + 1 === filterValue.length) {
              accFilter += filterVal.value;
              return accFilter;
            }
            accFilter += `${filterVal.value},`;
            return accFilter;
          }, '')
        : '';
    } else {
      val = filterValue ? moment(filterValue).format('DD-MM-YYYY') : '';
    }

    acc += filterBy ? `search[${filterBy}${isOptions}]=${val}&` : '';
    return acc;
  }, '');
};

const ContentPlanner = ({ projectView = true, noMargin }) => {
  const [showBriefDrawer, setShowBriefDrawer] = useState(false);
  const [editTaskModalView, setEditTaskModalView] = useState(false);
  const [deleteTaskModalView, setDeleteTaskModalView] = useState(false);
  const [moveToStateModalView, setMoveToStateModalView] = useState(false);
  const [newTask, setnewTask] = useState({});
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [taskIdToDelete, setTaskIdToDelete] = useState(null);
  const [taskIdToEdit, setTaskIdToEdit] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [filters, setFilters] = useState([filterOptions]);
  const [finalFilters, setFinalFilters] = useState([filters]);
  const [searchValue, setSearchValue] = useState('');
  const [showPopover, setShowPopover] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [activeTab, setActiveTab] = useState('list');
  const [date, setDate] = useState(new Date());
  const calendarRef = useRef(null);
  const [modalView, setModalView] = useState({
    expandPlan: false,
    createBrief: false,
    // addProject: false,
  });
  const [taskInfo, setTaskInfo] = useState({
    taskId: null,
    taskName: null,
    projectId: null,
  });
  const [showContentGeneration, setShowContentGeneration] = useState(false);
  const [streamContent, setStreamContent] = useState('');
  const [contentGenerated, setContentGenerated] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const abortController = useRef();
  const { user } = useSelector((state) => state.auth);
  const { id: userId } = user || {};

  const { subscriptions, user_brands } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const { mutateAsync: createDocument } = useCreateDocument({
    userId,
  });

  const dropdownItems = [
    {
      modalKey: 'expandPlan',
      Icon: FiFile,
      label: 'Expand Plan',
    },
    {
      modalKey: 'createBrief',
      Icon: FiFileText,
      label: 'Create Brief',
    },
    // {
    //   modalKey: 'addProject',
    //   Icon: AiOutlineProject,
    //   label: 'Campaign',
    // },
  ];

  const { briefId, projectId } = useParams();

  const {
    data: briefsPages,
    refetch,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching
  } = useBriefs({
    searchValue,
    filterStr: parseFilters(finalFilters),
    selectedProject: projectId || selectedProject,
  });

  const { mutateAsync: createBrief } = useCreateBrief({ userId: user?.id });

  useEffect(() => {
    const currentTasks = briefsPages?.pages
      .flatMap((page) => page?.data?.tasks)
      .filter((task) => !!task);
    setTasks(currentTasks);
  }, [briefsPages?.pages]);

  useEffect(() => {
    fetchColumnConfiguration();
  }, []);

  useEffect(() => {
    if (contentGenerated) {
      handleContentGenerated();
    }
  }, [contentGenerated]);

  useEffect(() => {
    if (tasks?.some((task) => Number(task.id) === Number(briefId))) {
      onEditHandler(Number(briefId));
    }
  }, [briefId, tasks]);

  const onDeleteHandler = (id) => {
    setDeleteTaskModalView(true);
    setTaskIdToDelete(id);
  };

  const handleNextMonth = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    setDate(calendarApi.getDate());
  };

  const handlePreviousMonth = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    setDate(calendarApi.getDate());
  };

  const formattedMonthYear = date.toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  });

  const fetchColumnConfiguration = async () => {
    try {
      const response = await getDashboardConfig();
      setSelectedColumns(response.data);
    } catch (e) {
      renderError('Unable to fetch dashboard configurations');
    }
  };

  const updateColumns = async (columns) => {
    try {
      setSelectedColumns(columns);
      await updateDashboardConfig(columns);
      renderSuccess('Updated column configuration');
    } catch (e) {
      renderError('Cannot save column configuration');
    }
  };

  const onConfirmDeleteHandler = async () => {
    try {
      const response = await deleteTask(taskIdToDelete);
      tasks?.filter((task) => {
        return task.id !== response?.data?.task.id;
      });

      if (editTaskModalView) {
        setEditTaskModalView(false);
      }

      refetch();
      renderSuccess(response?.data?.message);
    } catch (error) {
      renderError(error);
    }
  };

  const updateTaskInTasksArray = (taskId, updatedTask) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.id === taskId ? { ...task, ...updatedTask } : task,
      ),
    );
  };

  const onUpdateBrief = (id, brief) => {
    const index = tasks.findIndex((task) => task.id === id);
    if (index !== -1) {
      updateTaskInTasksArray(id, brief);
    }
  };

  const onMoveHandler = (id) => {
    setMoveToStateModalView(true);
    setTaskIdToEdit(id);
  };

  const onEditHandler = (id) => {
    setEditTaskModalView(true);
    setTaskIdToEdit(id);
  };

  const onArchive = async (id) => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Archiving Brief!',
        description: 'Please wait...',
      }),
    );
    try {
      const response = await archiveTask(id);
      refetch();
      renderSuccess(response?.data?.message);
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    } catch (error) {
      refetch();
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
      renderError(error);
    }
  };

  const onConfirmEditHandler = async (payload) => {
    try {
      const { project_id: projectId, ...remainingPayload } = payload;
      const response = await updateTask(taskIdToEdit, {
        task: {
          deadline_date: payload.deadline_date
            ? formattedDate(payload.deadline_date)
            : null,
          ...remainingPayload,
        },
        project_id: projectId,
      });

      tasks?.map((task) => {
        if (task.id === taskIdToEdit) {
          return { ...task, ...response?.data?.task };
        }
        return task;
      });

      refetch();
      renderSuccess(response?.data?.message);
    } catch (error) {
      renderError(error);
    }
  };

  const onCreateHandler = (category) => {
    setnewTask({ state: category });
    // setShowBriefDrawer(true);
    setModalView({
      expandPlan: false,
      createBrief: true,
      // [item.modalKey]: true,
    });
  };

  const onConfirmCreateHandler = async (payload) => {
    await createBrief({
      ...payload,
      deadline_date: payload.deadline_date
        ? formattedDate(payload.deadline_date)
        : '',
    });
  };

  const onSubmitHandler = async (action, payload) => {
    if (action === 'create') {
      await onConfirmCreateHandler(payload);
    }

    if (action === 'edit') {
      await onConfirmEditHandler(payload);
    }
  };

  const handleFilters = (index, val, type) => {
    const transformedFilters = filters.map((filter, filIndex) => {
      if (filIndex === index) {
        if (type === 'isOptions') {
          return {
            ...filter,
            [type]: val,
          };
        }

        if (type !== 'filterValue') {
          return {
            ...filter,
            [type]: val,
            filterValue: '',
          };
        }

        return {
          ...filter,
          [type]: val,
        };
      }

      return filter;
    });

    if (type === 'filterValue') {
      setFinalFilters(transformedFilters);
    }
    setFilters(transformedFilters);
  };

  const handleRemoveFilter = (index) => {
    if (filters.length === 1) {
      setFilters([{ filterBy: '', isOptions: '', filterValue: '' }]);
    }

    const transformedFilters = [...filters];
    transformedFilters.splice(index, 1);

    setFilters(transformedFilters);
    setFinalFilters(transformedFilters);
    refetch();
  };

  const appliedFilters = filters?.map((filter) => filter?.filterBy) || [];

  const handleTogglePopover = () => {
    setShowPopover((show) => !show);
  };

  const NewBriefPopover = (
    <Popover>
      <BSButton
        classList="button secondary_btn"
        ButtonText="Boost Plan"
        variant="light"
        onClick={() => navigate(ROUTES.Project.NewPlan(projectId))}
      />
      <BSButton
        classList="button secondary_btn"
        ButtonText="New Brief"
        variant="outline-secondary"
        onClick={() => {
          handleTogglePopover();
          onCreateHandler();
        }}
      />
    </Popover>
  );

  // const [showNestedDropdown, setShowNestedDropdown] = useState(false);

  const handleItemClick = (item) => {
    // if (item.modalKey === 'expandPlan') {
    //   setShowNestedDropdown(!showNestedDropdown);
    // }
    setModalView({
      expandPlan: false,
      createBrief: false,
      [item.modalKey]: true,
    });
  };

  const handleAICreate = async (taskId, projectId, taskName) => {
    setTaskInfo({ taskId, taskName, projectId });

    abortController.current = new AbortController();
    setStreamContent('');
    setContentGenerated(false);
    setShowContentGeneration(true);

    try {
      const URL = `${process.env.REACT_APP_URL}/api/v1/completion_stream_data_style_guides`;
      const BODY = JSON.stringify({
        project_id: projectId,
        task_id: taskId,
      });

      await generateStreamResponse(
        URL,
        BODY,
        setStreamContent,
        setContentGenerated,
        abortController?.current.signal,
      );
    } catch (error) {
      errorToast(error);
    }
  };

  const handleContentGenerated = async () => {
    const taskName = taskInfo?.taskName;
    const taskId = taskInfo?.taskId;
    const projectId = taskInfo?.projectId;
    await createDocument(
      {
        document: {
          title: taskName,
          user_id: userId,
          content: streamContent,
        },
        taskId,
        projectId,
      },
      {
        onSuccess: (documentResponse) => {
          const documentId = documentResponse?.data?.document?.id;
          if (!documentId) return;

          navigate(ROUTES.FreestyleDocumentTemplate(documentId));
        },
      },
    );
  };

  return (
    <section className={Styles.ContentPlanner}>
      {/* {projectView && <Announcement view={true}/>} */}

      <section
        className={Styles.ContentPlanner__Wrapper}
        style={{ marginTop: noMargin === true ? 0 : !projectView ? '20px' : 0 }}
      >
        <Tab.Container
          className="nav-tabs"
          id="plan-tabs"
          defaultActiveKey="list"
          onSelect={(key) => {
            setActiveTab(key);
          }}
        >
          <Nav className={Styles.ContentPlanner__Wrapper_Navigation}>
            <div className={Styles.ContentPlanner__Wrapper_Navigation__left}>
              <Nav.Item
                className={Styles.ContentPlanner__Wrapper_Navigation_Icon}
              >
                <Nav.Link
                  eventKey="kanban"
                  className={
                    Styles.ContentPlanner__Wrapper_Navigation_Icon__Item
                  }
                >
                  <Kanban size={20} />
                  <span>Kanban view</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                className={Styles.ContentPlanner__Wrapper_Navigation_Icon}
              >
                <Nav.Link
                  eventKey="list"
                  className={
                    Styles.ContentPlanner__Wrapper_Navigation_Icon__Item
                  }
                >
                  <List size={20} />
                  <span>List view</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                className={Styles.ContentPlanner__Wrapper_Navigation_Icon}
              >
                <Nav.Link
                  eventKey="calendar"
                  className={
                    Styles.ContentPlanner__Wrapper_Navigation_Icon__Item
                  }
                >
                  <Calendar size={20} />
                  <span>Calendar view</span>
                </Nav.Link>
              </Nav.Item>
            </div>
            <div className={Styles.ContentPlanner__Wrapper_Navigation__right}>
              {activeTab === 'calendar' && tasks?.length >= 0 && (
                <div className="calendar-controls d-flex align-itrms-center gap-2">
                  <button
                    onClick={handlePreviousMonth}
                    className={Styles.calendar_btn}
                  >
                    <FaChevronLeft />
                  </button>
                  <span className={Styles.calendar_month_text}>
                    {formattedMonthYear}
                  </span>
                  <button
                    onClick={handleNextMonth}
                    className={Styles.calendar_btn}
                  >
                    <FaChevronRight />
                  </button>
                </div>
              )}
              <div className="px-2">
                <SearchInput
                  setInputValue={setSearchValue}
                  inputValue={searchValue}
                  text="Search"
                />
              </div>
              <Dropdown>
                <Dropdown.Toggle
                  className={Styles.ContentPlanner__Wrapper_Navigation_Filter}
                >
                  <BsFilter size={20} />
                  Filter
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className={
                    Styles.ContentPlanner__Wrapper_InputSection_DropDown
                  }
                >
                  {filters.map((filter, index) => {
                    const { filterBy, isOptions, filterValue } = filter || {};

                    const filterByValue = options.find(
                      (status) => status.value === filterBy,
                    );

                    return (
                      <div
                        className={
                          'justify-content-between gap-2 ' +
                          Styles.ContentPlanner__Wrapper_InputSection_Filters
                        }
                        style={{ marginBottom: 5 }}
                        key={index}
                      >
                        <section className="d-flex justify-content-start gap-2">
                          <Select
                            isSearchable={false}
                            className={'filter-Select'}
                            classNamePrefix={'filter-Select'}
                            styles={SelectStyles}
                            placeholder="Filter By"
                            options={options.filter(
                              (option) =>
                                !appliedFilters.includes(option.value),
                            )}
                            value={filterByValue || null}
                            onChange={(val) =>
                              handleFilters(index, val.value, 'filterBy')
                            }
                          />
                          <Select
                            isSearchable={false}
                            className="filter-Select"
                            classNamePrefix="filter-Select"
                            styles={SelectStyles}
                            placeholder="is"
                            options={isFilterOptions}
                            value={
                              isFilterOptions.find(
                                (status) => status.value === isOptions,
                              ) || null
                            }
                            onChange={(val) =>
                              handleFilters(index, val.value, 'isOptions')
                            }
                          />
                          <ContentFilter
                            filterBy={filterBy}
                            filterValue={filterValue}
                            handleFilters={handleFilters}
                            index={index}
                            filters={filters}
                            isOptions={isOptions}
                            project={projectId || selectedProject}
                          />
                        </section>

                        <RxCross2
                          size={14}
                          color="#667085"
                          onClick={() => handleRemoveFilter(index)}
                        />
                      </div>
                    );
                  })}

                  {filters.length < 4 && (
                    <BSButton
                      icon={<BsPlus />}
                      classList="mt-3 button button-light"
                      ButtonText="Add filter"
                      variant="light"
                      onClick={() =>
                        setFilters((prevValue) => [
                          ...prevValue,
                          { filterBy: '', isOptions: '' },
                        ])
                      }
                    />
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <div
                className={`dropdown_container ${Styles.utility_left_margin}`}
              >
                <ProjectSelect setSelectedProject={setSelectedProject} />
              </div>
            </div>
          </Nav>
          <Tab.Content>
            {isLoading || isFetching ? (
              <div className="mt-4">
                <Loader inline loaderHeading="Loading Briefs..." />
              </div>
            ) : tasks?.length <= 0 ? (
              <EmptyState
                emptyText="You don't have any briefs yet."
                ctaText={
                  projectView
                    ? 'Create your first brief.'
                    : 'Generate a content plan.'
                }
                buttonText={projectView ? 'New Brief' : 'Boost Plan'}
                onClick={
                  projectView
                    ? () => onCreateHandler()
                    : currentSubscription?.plan_name === "Basic Plan" ? () => setShowUpgradeModal(true) : () => navigate(ROUTES.Project.NewPlan(projectId))
                }
              >
                <ImageView src="/Images/plan.svg" />
              </EmptyState>
            ) : (
              <>
                <Tab.Pane eventKey="kanban">
                  <KanbanBoard
                    onCreateClick={onCreateHandler}
                    tasks={tasks || []}
                    onDelete={onDeleteHandler}
                    onMove={onMoveHandler}
                    onEdit={onEditHandler}
                    fetchCPTasks={refetch}
                    onArchive={onArchive}
                    next={fetchNextPage}
                    hasMore={hasNextPage}
                    setTasks={setTasks}
                    handleAICreate={handleAICreate}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="list">
                  <KanbanList
                    tasks={tasks || []}
                    onDelete={onDeleteHandler}
                    onMove={onMoveHandler}
                    onEdit={onEditHandler}
                    fetchCpTasks={refetch}
                    onArchive={onArchive}
                    onUpdateBrief={onUpdateBrief}
                    selectedColumns={selectedColumns}
                    updateColumns={updateColumns}
                    handleAICreate={handleAICreate}
                  />
                  {/* <table className="w-100">
                    <TableSkeleton />
                  </table> */}
                </Tab.Pane>
                <Tab.Pane eventKey="calendar">
                  <Calender
                    tasks={tasks || []}
                    onMove={onMoveHandler}
                    onEdit={onEditHandler}
                    onDelete={onDeleteHandler}
                    fetchCpTasks={refetch}
                    onArchive={onArchive}
                    calendarProps={{ ref: calendarRef, headerToolbar: false }}
                    onUpdateBrief={onUpdateBrief}
                    handleAICreate={handleAICreate}
                  />
                </Tab.Pane>
              </>
            )}
          </Tab.Content>
        </Tab.Container>
      </section>

      {/* {projectId ? ( */}
      {/* <OverlayTrigger
          placement="top"
          rootClose
          show={showPopover}
          onToggle={handleTogglePopover}
          overlay={NewBriefPopover}
        > */}
      {/* <FloatingButton onClick={handleTogglePopover} /> */}
      {/* <DropdownButton
        drop={'down-centered'}
        title={<LuPlus className={Styles.plus_icon} />}
        className={`${Styles.rounded_plus_btn}`}
        variant="dark"
      >
        {dropdownItems.map((item) => (
          <React.Fragment key={item.modalKey}>
            <Dropdown.Item
              onClick={() => handleItemClick(item)}
              className={Styles.list_item}
            >
              <item.Icon color="#00A7B7" size={14} />
              <span className="text-md-Regular">{item.label}</span>
            </Dropdown.Item>
            {item.modalKey === 'expandPlan' && showNestedDropdown && (
              <DropdownButton
                drop={'right'}
                title="More Options"
                className={Styles.nested_dropdown}
                variant="light"
                show={showNestedDropdown}
                onToggle={() => setShowNestedDropdown(!showNestedDropdown)}
              >
                <Dropdown.Item
                  onClick={() => console.log('Expanded Option 1 Clicked')}
                >
                  Expanded Option 1
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => console.log('Expanded Option 2 Clicked')}
                >
                  Expanded Option 2
                </Dropdown.Item>
              </DropdownButton>
            )}
          </React.Fragment>
        ))}
      </DropdownButton> */}
      <MultiDropdown
        onClick={handleItemClick}
        higherIndex={true}
        projectView={projectView}
      />
      {/* </OverlayTrigger> */}
      {/* // ) 
      // : (
      //   <FloatingButton onClick={() => onCreateHandler()} />
      // )
      } */}

      {deleteTaskModalView && (
        <ConfirmDeleteModal
          show={deleteTaskModalView}
          onHide={() => {
            setDeleteTaskModalView(false);
            setTaskIdToDelete(null);
          }}
          onDelete={onConfirmDeleteHandler}
        />
      )}

      {modalView.createBrief && (
        <BriefDrawer
          action="create"
          show={modalView.createBrief}
          onHide={() => {
            setModalView((prevState) => ({
              ...prevState,
              createBrief: false,
            }));
          }}
          onSubmit={onSubmitHandler}
          task={newTask}
          isProjectView={!!projectId}
        />
      )}

      {modalView.expandPlan && (
        <ExpandPlanDrawer
          show={modalView.expandPlan}
          onHide={() => {
            setModalView((prevState) => ({
              ...prevState,
              expandPlan: false,
            }));
          }}
          callback={refetch}
          title={'Expand Plan'}
          placement="end"
          width={720}
          isProjectView={!!projectId}
        />
      )}

      {editTaskModalView && (
        <BriefDrawer
          action="edit"
          show={editTaskModalView}
          onHide={() => {
            setEditTaskModalView(false);
            setTaskIdToEdit(null);
            briefId && navigate(ROUTES.Planner);
          }}
          onSubmit={onSubmitHandler}
          onDeleteHandler={onDeleteHandler}
          task={tasks?.find((task) => {
            return task.id === taskIdToEdit;
          })}
          isProjectView={!!projectId}
        />
      )}

      {moveToStateModalView && (
        <MoveToStateModal
          show={moveToStateModalView}
          onHide={() => {
            setMoveToStateModalView(false);
            setTaskIdToEdit(null);
          }}
          onMove={onConfirmEditHandler}
          task={tasks?.find((task) => {
            return task.id === taskIdToEdit;
          })}
        />
      )}

      <ContentGenerationModal
        onHide={() => {
          setShowContentGeneration(false);
          setStreamContent('');
          setContentGenerated(false);
          abortController?.current.abort();
        }}
        show={showContentGeneration}
        string={streamContent}
        contentGenerated={contentGenerated}
      />

      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
        />
      )}
    </section>
  );
};

export default ContentPlanner;
