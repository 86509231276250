import Header from 'Components/Pages/PricingPlans/Header';
import FooterCard from 'Components/Pages/PricingPlans/FooterCard';
import PricingCard from 'Components/Pages/PricingPlans/PricingCard';
import React from 'react';
import Styles from './PlanDetailScreen.module.scss';

const PlanDetailsScreen = () => {
  const basicServices = [
    'AI-powered Topic Ideation',
    'AI-powered Content Planning',
    'Access to AI Templates',
    'Smart Entry Editor',
    'AI Editing Features',
  ];
  const proServices = [
    'Comes with 1 Seat (but can add up to 2 seats for $45/mo per seat)',
    'AI-powered projects with automated content planning',
    'SEO research tool',
    'Integration with up to 5 channels',
    'Brand Voice',
    'Direct scheduling and publishing to connected channels',
    'Multi-model prompting (Claude, GPT, Gemini) in smart entry editor',
    'Collaboration features',
  ];
  const businessServices = [
    '5 user seats (additional seats available at $35/mo per seat)',
    'Review and approval workflow features',
    'Advanced analytics and reporting',
    'AI-powered content strategy',
  ];
  return (
    <div className={Styles.layout}>
      <div className={Styles.header}>
        <Header />
        <button className={Styles.close_btn}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M15 5L5 15M5 5L15 15"
              stroke="#344054"
              stroke-width="1.67"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <div className="d-flex">
        <PricingCard type="basic" services={basicServices} />
        <PricingCard type="pro" services={proServices} />
        <PricingCard type="business" services={businessServices} />
      </div>
      <div className="d-flex">
        <FooterCard type="basic" />
        <FooterCard type="pro" />
        <FooterCard type="business" />
      </div>
    </div>
  );
};

export default PlanDetailsScreen;
