import React, { useEffect, useRef, useState } from 'react';
import { bottom } from '@popperjs/core';
import ImageView from 'Components/Image/ImageView';
import { mentionsInputStyle, mentionStyle } from 'constants';
import { getNameInitials, getUserImage } from 'helpers';
import reactStringReplace from 'react-string-replace';
import TaggedUser from 'Components/TaggedUser/TaggedUser';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import moment from 'moment';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { FaEllipsisH } from 'react-icons/fa';
import { FiCheckCircle, FiTrash2 } from 'react-icons/fi';
import { Mention, MentionsInput } from 'react-mentions';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCommentsById, postComment } from 'services/commentService';
import styleComment from './../Editor.module.scss';
import { COMMENT_NAME } from 'constants/regex';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';

export default function CommentComponent({
  rectValues,
  commentId,
  comments,
  setComments,
  fetchComments,
  isListOpen,
  mainContainerRef,
  userList,
  resolveComment,
  deleteComment,
}) {
  const [rectValue, setRectValue] = useState({ top: 0 });
  const [shouldFormOpen, setShouldFormOpen] = useState(false);
  const [shouldListShown, setShouldListShown] = useState(isListOpen);
  const commentRef = useRef(null);
  const [comment, setComment] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const { documentId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { id: userId } = user || {};

  useEffect(() => {
    const handleClickOutside = (event) => {
      const suggestionEle = document.querySelectorAll('[role="listbox"]')[0];
      if (
        commentRef.current &&
        !commentRef.current.contains(event.target) &&
        !suggestionEle?.contains(event.target)
      ) {
        setShouldListShown(false);
        setShouldFormOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [commentRef]);

  useEffect(() => {
    setRectValue(rectValues);
  }, [rectValues]);

  const handleFocusInput = () => {
    setIsFocused(true);
  };

  const handleAddComment = async () => {
    const transformedComments = comments[commentId];

    let mention = [];
    userList.forEach((userValue) => {
      const matchExist = comment.includes(
        `@[${userValue.display}](${userValue.id})`,
      );
      if (matchExist) {
        mention = [...mention, userValue.id];
      } else {
        mention = mention.filter((val) => val !== userValue.id);
      }
    });

    const commentInfo = {
      user_id: userId,
      uuid: commentId,
      message: comment,
      mentions: mention,
    };

    setComments((preValue) => ({
      ...preValue,
      [commentId]: [...transformedComments, commentInfo],
    }));
    setComment('');
    await postComment(documentId, commentInfo);
    fetchComments();
  };

  const transformedComments = comments[commentId];

  if (!transformedComments?.length) {
    return <></>;
  }

  const { mini_avatar, name, uuid } = transformedComments?.[0] || {};

  const scrollPosition = window.scrollY;

  const top = rectValue?.top + scrollPosition - 35;

  const left = rectValue?.left + rectValue?.offsetWidth / 2 - 20;

  const handleDeleteComment = async (uuid) => {
    try {
      await deleteComment(userId, uuid);

      renderSuccess('Comment deleted successfully');
    } catch (error) {
      renderError('Unable to delete comment');
    }

    fetchComments();
  };

  const markResolved = async (uuid) => {
    try {
      await resolveComment(userId, uuid);

      renderSuccess('Comment resolved successfully');
    } catch (error) {
      renderError('Unable to resolve comment');
    }

    fetchComments();
  };

  const handleCommentsClick = async () => {
    setShouldListShown(true);
    try {
      const commentsRes = await getCommentsById(documentId, uuid);
      const { data } = commentsRes || {};
      const transformedComments = data?.[uuid];

      setComments((prevValue) => ({
        ...prevValue,
        [uuid]: transformedComments,
      }));
    } catch (error) {}
  };

  const topLimit = 190;

  return top < topLimit ? null : (
    <div
      style={{
        top,
        left,
        zIndex: shouldListShown ? 10000 : 0,
      }}
      className="comment-container"
      ref={commentRef}
    >
      {!shouldListShown ? (
        <div
          className="profile-Avatar"
          onClick={handleCommentsClick}
          id={`${commentId}_comment`}
        >
          {getUserImage(mini_avatar, name, {
            width: 32,
            height: 32,
          })}
        </div>
      ) : (
        <div
          className="searchbox-wrap"
          onMouseLeave={() => {
            if (!shouldFormOpen) {
              setShouldListShown(false);
              setShouldFormOpen(false);
            }
          }}
          onClick={() => setShouldFormOpen((prevOpen) => !prevOpen)}
        >
          <div className="comment-info">
            <div className="comments-list">
              {transformedComments.map((commentValue, index) => {
                const {
                  uuid,
                  message,
                  status,
                  created_at,
                  name,
                  user_id,
                  mini_avatar,
                } = commentValue || {};

                // Transforms the message by finding the tagged user
                // And replacing that string for a React Component
                const transformedMessage = reactStringReplace(
                  message,
                  COMMENT_NAME,
                  (match, i) => {
                    return <TaggedUser key={uuid} displayName={match} />;
                  },
                );

                const isResolved = status !== 'open';
                return (
                  <div
                    className="CommentWrapper"
                    key={`${message}-${uuid}`}
                    style={{
                      borderWidth:
                        index === transformedComments.length - 1 ? '0' : '1px',
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <section className="d-flex align-items-center g-10">
                        <div className="stacked-Images-rounded d-flex">
                          {mini_avatar ? (
                            <img
                              src={mini_avatar}
                              className="avatar"
                              alt="profileImage"
                              style={{
                                width: '35px',
                                height: '35px',
                                borderRadius: 20,
                                marginRight: 5,
                              }}
                            />
                          ) : (
                            <span className="rounded-profile">
                              {getNameInitials(name)}
                            </span>
                          )}
                        </div>

                        <div className="d-flex flex-column">
                          <span
                            className={
                              'text-md ' + styleComment.Comment_authorName
                            }
                          >
                            {name}
                          </span>
                          <span
                            className={
                              'text-sm-18lh ' + styleComment.Comment_timeStamp
                            }
                          >
                            {moment(created_at).format('h:mm A MMM D')}
                          </span>
                        </div>
                      </section>
                      {!index && (
                        <section
                          className={styleComment.Comment__OptionSection}
                        >
                          <DropdownButton
                            onClick={(e) => e.stopPropagation()}
                            drop={bottom}
                            title={<FaEllipsisH fill="#667085" size={13} />}
                          >
                            <Dropdown.Item
                              className={styleComment.Comment_dropdown}
                              disabled={isResolved}
                            >
                              <div
                                className="d-flex g-10 align-items-center"
                                onClick={() => markResolved(uuid)}
                              >
                                <FiCheckCircle stroke="#667085" size={14} />
                                <p className="text-md">
                                  {isResolved ? 'Resolved' : 'Resolve'}
                                </p>
                              </div>
                            </Dropdown.Item>
                            {user_id === userId && (
                              <Dropdown.Item>
                                <div
                                  className="d-flex align-items-center g-10"
                                  onClick={() => handleDeleteComment(uuid)}
                                >
                                  <FiTrash2 stroke="#667085" size={14} />
                                  <p className="text-md">Delete</p>
                                </div>
                              </Dropdown.Item>
                            )}
                          </DropdownButton>
                        </section>
                      )}
                    </div>
                    <section
                      className={'pt-2 ' + styleComment.Comment__Content}
                    >
                      <div className={styleComment.Comment__Content_text}>
                        {transformedMessage}
                      </div>
                    </section>
                  </div>
                );
              })}

              {shouldFormOpen && (
                <div className="comment-adder-section">
                  <div className="addCommentSection position-relative">
                    <MentionsInput
                      placeholder="Add a comment"
                      value={comment}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                      }}
                      onClick={(e) => e.stopPropagation()}
                      onFocus={handleFocusInput}
                      onBlur={() => {
                        setIsFocused(false);
                      }}
                      type="text"
                      onChange={(e) => setComment(e.target.value)}
                      style={mentionsInputStyle}
                      a11ySuggestionsListLabel="Suggested mentions"
                      suggestionsPortalHost={mainContainerRef}
                      allowSuggestionsAboveCursor
                    >
                      <Mention
                        style={mentionStyle}
                        data={userList}
                        appendSpaceOnAdd
                        displayTransform={(_, display) => `@${display}`}
                        renderSuggestion={(suggestion) => {
                          return (
                            <div className="d-flex align-items-center gap-3">
                              {suggestion.avatar ? (
                                <ImageView
                                  src={suggestion.avatar}
                                  className="rounded-profile-x-sm"
                                />
                              ) : (
                                getUserImage(
                                  suggestion.avatar,
                                  suggestion.display,
                                  { width: 26, height: 26 },
                                  'sm',
                                )
                              )}
                              <p>{suggestion.display}</p>
                            </div>
                          );
                        }}
                      />
                    </MentionsInput>
                    <div
                      onClick={
                        comment && comment.trim()
                          ? () => handleAddComment()
                          : () => {}
                      }
                      className={`arrow_circle_input ${
                        isFocused && comment && comment.trim()
                          ? 'activeSVG'
                          : ''
                      }`}
                    >
                      <BsFillArrowRightCircleFill size={24} color="#D0D5DD" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
