import { useState } from 'react';
import { Tab, Nav, Col } from 'react-bootstrap';
import IntegrationForm from './IntegrationForm';
import Modal from 'react-bootstrap/Modal';
import { capitalize } from 'helpers';
import Styles from './IntegrationModal.module.scss';

const IntegrationNavItem = ({ type }) => {
  return (
    <Col key={type}>
      <Nav.Item>
        <Nav.Link eventKey={type} className={Styles.IntegrationBtn}>
          {type}
        </Nav.Link>
      </Nav.Item>
    </Col>
  );
};

const IntegrationModal = ({
  projectId,
  availableIntegrations,
  handleAddSubmit,
  handleUpdateSubmit,
  updatedIntegration,
  show,
  onHide,
}) => {
  const [tabKey, setTabKey] = useState(
    (updatedIntegration && capitalize(updatedIntegration.type)) ||
      availableIntegrations[0].type,
  );

  const handleFormSubmit = async (type, values) => {
    const payload = { integration: { ...values, project_id: projectId } };

    if (updatedIntegration) {
      // Update Integration
      handleUpdateSubmit(type, updatedIntegration.id, payload);
    } else {
      // Add New Integration
      handleAddSubmit(type, payload);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size={600}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="border-0 pb-0" closeButton>
        <Modal.Title className="Modal-Title">
          {updatedIntegration ? 'Updating' : 'New'} Integration
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Tab.Container
          id="integration-tabs"
          activeKey={tabKey}
          onSelect={(k) => setTabKey(k)}
        >
          <Nav
            variant="pills"
            className="justify-content-center gap-3 text-center pb-3"
          >
            {updatedIntegration ? (
              <IntegrationNavItem type={capitalize(updatedIntegration.type)} />
            ) : (
              availableIntegrations?.map((integration) => (
                <IntegrationNavItem
                  key={integration.type}
                  type={integration.type}
                />
              ))
            )}
          </Nav>
          <div className="border-bottom pb-3">
            {/* <span className="Input_label form-label">
              Integration Instructions:
            </span>{' '} */}
            <a
              href="https://blog.vocable.ai/publishing-integration-instructions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here for Integration Instructions
            </a>
          </div>
          <Tab.Content className="mt-3">
            {availableIntegrations?.map((integration) => (
              <Tab.Pane eventKey={integration.type} key={integration.type}>
                <IntegrationForm
                  type={integration.type.toLowerCase()}
                  fields={integration.fields}
                  defaultValues={updatedIntegration}
                  onSubmit={handleFormSubmit}
                />
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};

export default IntegrationModal;
