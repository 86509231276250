import React, { useState } from 'react';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import Styles from './MultiDropdown.module.scss';
import { LuPlus, LuFileEdit } from 'react-icons/lu';
import { FiFile, FiFileText } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IoCopyOutline } from "react-icons/io5";
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';

const dropdownItems = [
  {
    modalKey: 'expandPlan',
    Icon: IoCopyOutline,
    label: 'Expand Plan',
  },
  {
    modalKey: 'createBrief',
    Icon: FiFileText,
    label: 'Create Brief',
  },
];

function MultiDropdown({ onClick, showExpand = true ,higherIndex, projectView }) {

  const { user } = useSelector((state) => state.auth);
  const { subscriptions, user_brands } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const navigate = useNavigate();
  return (
    <>
    <Dropdown>
      <Dropdown.Toggle
        variant="dark"
        id="dropdown-basic"
        className={Styles.rounded_plus_btn}
        style={higherIndex ? { zIndex: 999 } : {}}
      >
        <LuPlus className={Styles.plus_icon} />
      </Dropdown.Toggle>

      <Dropdown.Menu className={Styles.drop_menu_top}>
        {/* <NavDropdown
          title={
            <div className={Styles.list_item}>
              <FiFile color="#00A7B7" size={14} />
              <span className="text-md-Regular">Expand Plan</span>
            </div>
          }
          id="nav-dropdown-expand-plan"
          className={Styles.drop_option}
        > */}

        {/* dont remove will uncomment in future */}


        {user_brands?.brands_count > 0 && showExpand && projectView && <Dropdown.Item
          onClick={() => onClick(dropdownItems[0])}
          className={Styles.list_item}
        >
          <div className={Styles.list_item_nested}>
            <span className={Styles.list_item_nested__icon}>
              <IoCopyOutline color="#00A7B7" size={14} />
            </span>
            <div className="d-flex flex-column">
              <span className="text-md-Regular" style={{display: 'contents'}}>Expand Existing Plan</span>
              {/* <span className={Styles.description}>
                This will add new briefs and <br /> build on your current plan.
              </span> */}
            </div>
          </div>
        </Dropdown.Item>}
        {showExpand && projectView && <Dropdown.Item
          onClick={() => navigate('/new-plan')}
          className={Styles.list_item}
        >
          <div className={Styles.list_item_nested}>
            <span className={Styles.list_item_nested__icon}>
              <LuFileEdit color="#00A7B7" size={14} />
            </span>
            <div className="d-flex flex-column">
              <span className="text-md-Regular"  style={{display: 'contents'}}>Create New Plan</span>
            </div>
          </div>
        </Dropdown.Item> }
        {/* </NavDropdown> */}

        <Dropdown.Item
          className={Styles.list_item}
          onClick={currentSubscription?.plan_name === "Basic Plan" && !projectView ? ()=>setShowUpgradeModal(true) :() => onClick(dropdownItems[1])}
        >
          <FiFileText color="#00A7B7" size={14} />
          <span className="text-md-Regular"  style={{display: 'contents'}}>Add Brief</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
       {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
        />
      )}
      </>
  );
}

export default MultiDropdown;
