import React, { useEffect, useRef, useCallback } from 'react';
import { NodeViewWrapper } from '@tiptap/react';
import { resizableMediaActions } from './resizableMediaMenuUtil';
import Tippy from '@tippyjs/react';
import { renderIcon } from 'helpers/renderIcon.js';
import { computeNewDimensions } from './computeNewDimensions';

const ResizableMediaNodeView = ({
  editor,
  node,
  decorations,
  selected,
  extension,
  getPos,
  updateAttributes,
  deleteNode,
}) => {
  const resizableImg = useRef(null);
  const aspectRatio = useRef(0);
  const proseMirrorContainerWidth = useRef(0);
  const mediaActionActiveState = useRef({});

  const isHorizontalResizeActiveRef = useRef(false);
  const lastCursorXRef = useRef(-1);

  const isVerticalResizeActiveRef = useRef(false);
  const lastCursorYRef = useRef(-1);

  const setMediaActionActiveStates = () => {
    const activeStates = {};

    for (const { tooltip, isActive } of resizableMediaActions) {
      activeStates[tooltip] = !!isActive?.(node.attrs);
    }

    mediaActionActiveState.current = activeStates;
  };

  useEffect(() => {
    setMediaActionActiveStates();
  }, [node.attrs]);

  const mediaSetupOnLoad = () => {
    // ! TODO: move this to extension storage
    const proseMirrorContainerDiv = document.querySelector('.ProseMirror');

    if (proseMirrorContainerDiv) {
      proseMirrorContainerWidth.current = proseMirrorContainerDiv.clientWidth;
    }

    // When the media has loaded
    if (!resizableImg.current) return;

    resizableImg.current.onload = () => {
      // Aspect Ratio from its original size
      aspectRatio.current =
        resizableImg.current.naturalWidth / resizableImg.current.naturalHeight;

      onHorizontalResize('left', 0);
    };

    setTimeout(() => setMediaActionActiveStates(), 500);
  };

  useEffect(() => {
    mediaSetupOnLoad();
  }, []);

  const limitWidthOrHeightToTwentyPixels = ({ width, height }) =>
    width < 20 || height < 20;

  const startHorizontalResize = (e) => {
    isHorizontalResizeActiveRef.current = true;

    lastCursorXRef.current = e.clientX;

    document.addEventListener('mousemove', onHorizontalMouseMove);
    document.addEventListener('mouseup', stopHorizontalResize);
    return false;
  };

  const stopHorizontalResize = () => {
    isHorizontalResizeActiveRef.current = false;

    lastCursorXRef.current = -1;

    document.removeEventListener('mousemove', onHorizontalMouseMove);
    document.removeEventListener('mouseup', stopHorizontalResize);
  };

  const onHorizontalResize = (directionOfMouseMove, diff) => {
    if (!resizableImg.current) {
      console.error('Media ref is undefined|null', {
        resizableImg: resizableImg.current,
      });
      return;
    }

    const newMediaDimensions = computeNewDimensions(
      resizableImg.current,
      diff,
      aspectRatio,
      directionOfMouseMove,
      proseMirrorContainerWidth,
    );

    if (limitWidthOrHeightToTwentyPixels(newMediaDimensions)) return;

    updateAttributes(newMediaDimensions);
  };

  const onHorizontalMouseMove = useCallback(
    (e) => {
      if (!isHorizontalResizeActiveRef.current) return;

      const { clientX } = e;

      const diff = lastCursorXRef.current - clientX;

      lastCursorXRef.current = clientX;

      if (diff === 0) return;

      const directionOfMouseMove = diff > 0 ? 'left' : 'right';

      if (!resizableImg.current) {
        console.error('Media ref is undefined|null', {
          resizableImg: resizableImg.current,
        });
        return;
      }

      const newMediaDimensions = computeNewDimensions(
        resizableImg.current,
        diff,
        aspectRatio,
        directionOfMouseMove,
        proseMirrorContainerWidth,
      );

      if (limitWidthOrHeightToTwentyPixels(newMediaDimensions)) return;

      updateAttributes(newMediaDimensions);
    },
    [updateAttributes, aspectRatio],
  );

  const startVerticalResize = (e) => {
    isVerticalResizeActiveRef.current = true;
    lastCursorYRef.current = e.clientY;

    document.addEventListener('mousemove', onVerticalMouseMove);
    document.addEventListener('mouseup', stopVerticalResize);
  };

  const stopVerticalResize = () => {
    isVerticalResizeActiveRef.current = false;
    lastCursorYRef.current = -1;

    document.removeEventListener('mousemove', onVerticalMouseMove);
    document.removeEventListener('mouseup', stopVerticalResize);
  };

  const onVerticalMouseMove = (e) => {
    if (!isVerticalResizeActiveRef.current) return;

    const { clientY } = e;

    const diff = lastCursorYRef.current - clientY;

    lastCursorYRef.current = clientY;

    if (diff === 0) return;

    const directionOfMouseMove = diff > 0 ? 'up' : 'down';

    if (!resizableImg.current) {
      console.error('Media ref is undefined|null', {
        resizableImg: resizableImg.current,
      });
      return;
    }

    const newMediaDimensions = computeNewDimensions(
      resizableImg.current,
      diff,
      aspectRatio,
      directionOfMouseMove,
      proseMirrorContainerWidth,
    );

    if (limitWidthOrHeightToTwentyPixels(newMediaDimensions)) return;

    updateAttributes(newMediaDimensions);
  };

  const isFloat = !!node.attrs.datafloat;
  const isAlign = !!node.attrs.dataalign;

  return (
    <NodeViewWrapper
      as="article"
      className={`media-node-view not-prose ${
        isFloat ? `f-${node.attrs.datafloat}` : ''
      } ${isAlign ? `align-${node.attrs.dataalign}` : ''}`}
    >
      <Tippy
        content={
          <section className="image-actions-container">
            {resizableMediaActions.map((mediaAction, i) => (
              <button
                key={i}
                className="btn btn-sm btn-ghost image-action-button"
                onClick={() =>
                  mediaAction.tooltip === 'Delete'
                    ? mediaAction.delete?.(deleteNode)
                    : mediaAction.action?.(updateAttributes)
                }
              >
                {renderIcon(mediaAction.icon, {
                  className: `image-action-icon ${
                    mediaActionActiveState.current[mediaAction.tooltip]
                      ? 'active'
                      : ''
                  }`,
                  color: '#101828',
                })}
              </button>
            ))}
          </section>
        }
        interactive={true}
      >
        <div
          className="d-flex"
          style={{
            width: 'fit-content',
            position: 'relative',
            padding: '4px',
          }}
        >
          <p>
            <img
              data-drag-handle
              {...node.attrs}
              ref={resizableImg}
              className={`rounded-lg ${
                isFloat ? `float-${node.attrs.datafloat}` : ''
              } ${isAlign ? `align-${node.attrs.dataalign}` : ''}`}
              draggable="true"
              alt={node.attrs.alt}
            />
          </p>
          <div
            className={`horizontal-resize-handle ${
              isHorizontalResizeActiveRef.current
                ? 'horizontal-resize-active'
                : ''
            }`}
            title="Resize"
            onMouseDown={startHorizontalResize}
            onMouseUp={stopHorizontalResize}
          />
          <div
            className={`vertical-resize-handle ${
              isVerticalResizeActiveRef.current ? 'vertical-resize-active' : ''
            }`}
            title="Resize"
            onMouseDown={startVerticalResize}
            onMouseUp={stopVerticalResize}
          />
        </div>
      </Tippy>
    </NodeViewWrapper>
  );
};

export default ResizableMediaNodeView;
