import Input from 'Components/Input/Input';
import { Button } from 'react-bootstrap';
import Styles from './InputGroup.module.scss';

function InputGroup({
  InputPlaceHoder,
  iconright,
  BtnID,
  onChange,
  value,
  disabled,
  onClick,
  variant,
  BtnIcon,
  BtnText,
  SideBarView,
  inputClass,
  defaultValue,
  headerInputBtn,
  btnDisabled,
  onKeyPress,
  researchModule,
}) {
  return (
    <div
      className={`${researchModule ? Styles.input_container : `input-group ${Styles.InputGroup}`}`}
    >
      <Input
        type="text"
        classList={`input-group-input input w-100 ${inputClass} ${
          SideBarView && 'f-s12'
        }`}
        placeholder={InputPlaceHoder}
        aria-label={InputPlaceHoder}
        aria-describedby={BtnID}
        inputWrapperClass={`${researchModule ? 'w-100' : 'w-75'}`}
        onChange={onChange}
        value={value}
        disabled={disabled}
        defaultValue={defaultValue}
        onKeyPress={onKeyPress}
      />
      <Button
        onClick={onClick}
        className={`button secondary_btn ${Styles.BannerBtn} ${
          headerInputBtn && Styles.BannerBtn__Header
        } ${SideBarView && Styles.BannerBtn__sm}`}
        variant={variant}
        disabled={disabled || btnDisabled}
      >
        {!iconright && BtnIcon}
        <span>{BtnText}</span>
        {iconright && BtnIcon}
      </Button>
    </div>
  );
}

export default InputGroup;
