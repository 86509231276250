import React from 'react';
import ImageView from 'Components/Image/ImageView';
import Input from 'Components/Input/Input';
import TextArea from 'Components/Input/TextArea/TextArea';
import StyleClasses from './ImportDraftModal.module.scss';

function ModalBodyContent({
  setLink,
  setContent,
  isValidLink,
  changeHandler,
  link,
  content,
}) {
  return (
    <>
      <Input
        placeholder="https://www.sample.edu/sample.pdf"
        classList={'input'}
        value={link}
        onChange={({ target: { value } }) => {
          setLink(value);
          setContent('');
        }}
      />
      {!isValidLink && <div className="error">Invalid Link</div>}
      <section
        className={
          'd-flex justify-content-between align-items-center ' +
          StyleClasses.dividerSection
        }
      >
        <hr className={StyleClasses.dividerSection__line} />
        <span>OR</span>
        <hr className={StyleClasses.dividerSection__line} />
      </section>
      <section className={StyleClasses.DragDropArea}>
        <div className="d-flex flex-column justify-content-center align-items-center text-center ">
          <ImageView src="/Images/uploadIcon.svg" class="img-fluid" />
          <p class={StyleClasses.DragDropArea__fileMsg}>
            <span className={StyleClasses.DragDropArea__fileMsg_highlight}>
              Click to upload
            </span>{' '}
            or drag and drop
          </p>
          <p className={StyleClasses.DragDropArea__fileMsg_format}>
            {' '}
            PDF, TXT, DOCX or RTF (max. 1MB)
          </p>
        </div>
        <Input
          type="file"
          onChange={changeHandler}
          accept=".doc,.docx,.pdf,.rtf,.txt"
        />
      </section>
      <section
        className={
          'd-flex justify-content-between align-items-center ' +
          StyleClasses.dividerSection
        }
      >
        <hr className={StyleClasses.dividerSection__line} />
        <span>OR</span>
        <hr className={StyleClasses.dividerSection__line} />
      </section>
      <TextArea
        placeholder={'Paste here'}
        Label="Copy & Paste your content"
        type={'textarea'}
        classList="input"
        value={content}
        onChange={({ target: { value } }) => {
          setContent(value);
          setLink('');
        }}
      />
    </>
  );
}

export default ModalBodyContent;
