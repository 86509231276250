import React, { useState, useEffect } from 'react';
import Styles from './TickerHero.module.scss';
import { ReactComponent as Icon1 } from '../../../assets/landingpage/div.svg';
import { ReactComponent as Icon2 } from '../../../assets/landingpage/div (1).svg';
import { ReactComponent as Icon3 } from '../../../assets/landingpage/div (2).svg';
import { ReactComponent as Icon4 } from '../../../assets/landingpage/div (3).svg';
import { ReactComponent as Icon5 } from '../../../assets/landingpage/div (4).svg';
import { ReactComponent as Icon6 } from '../../../assets/landingpage/div (5).svg';
import { ReactComponent as Icon7 } from '../../../assets/landingpage/div (6).svg';
import TickerCard from './TickerCard';
import MyCarousel from '../CustomerReview/Testimonials/Carousel';

const items = [
  { Icon: Icon1, text1: 'AI-Powered', text2: 'Content Plan' },
  { Icon: Icon2, text1: 'AI Brief', text2: 'Generation' },
  { Icon: Icon3, text1: 'SEO & Keyword', text2: 'Optimization' },
  { Icon: Icon4, text1: 'Content', text2: 'Collaboration' },
  { Icon: Icon5, text1: 'Multi-Model', text2: 'Entry Editor' },
  { Icon: Icon6, text1: 'Brand', text2: 'Voice' },
  { Icon: Icon7, text1: 'AI Content', text2: 'Tools' },
  { Icon: Icon1, text1: 'AI-Powered', text2: 'Content Plan' },
  { Icon: Icon2, text1: 'AI Brief', text2: 'Generation' },
  { Icon: Icon3, text1: 'SEO & Keyword', text2: 'Optimization' },
  { Icon: Icon4, text1: 'Content', text2: 'Collaboration' },
  { Icon: Icon5, text1: 'Multi-Model', text2: 'Entry Editor' },
  { Icon: Icon6, text1: 'Brand', text2: 'Voice' },
  { Icon: Icon7, text1: 'AI Content', text2: 'Tools' },
  { Icon: Icon1, text1: 'AI-Powered', text2: 'Content Plan' },
  { Icon: Icon2, text1: 'AI Brief', text2: 'Generation' },
  { Icon: Icon3, text1: 'SEO & Keyword', text2: 'Optimization' },
  { Icon: Icon4, text1: 'Content', text2: 'Collaboration' },
  { Icon: Icon5, text1: 'Multi-Model', text2: 'Entry Editor' },
  { Icon: Icon6, text1: 'Brand', text2: 'Voice' },
  { Icon: Icon7, text1: 'AI Content', text2: 'Tools' },
];

const TickerHero = () => {
  const [direction, setDirection] = useState('leftToRight');

  useEffect(() => {
    const toggleDirection = () => {
      setDirection((prevDirection) =>
        prevDirection === 'leftToRight' ? 'leftToRight' : 'leftToRight',
      );
    };

    const interval = setInterval(toggleDirection, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <MyCarousel gap={6}>
      <div className={`${Styles.imgTicker} ${Styles[direction]}`}>
        {items.map((item, index) => (
          <div key={index} className={Styles.ticker_item}>
            <TickerCard
              Icon={item.Icon}
              text1={item.text1}
              text2={item.text2}
            />
          </div>
        ))}
      </div>
    </MyCarousel>
  );
};

export default TickerHero;
