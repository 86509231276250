import React from 'react';
import {
  FiCalendar,
  FiFileText,
  FiGrid,
  FiLayers,
  FiList,
  FiSearch,
  FiUsers,
  FiZap,
} from 'react-icons/fi';
import { HiOutlineLightBulb } from 'react-icons/hi';
import { ROUTES } from './routes';

export const MAIN_SIDEBAR_OPTIONS = [
  {
    id: 'dashboard',
    path: ROUTES.HomePage,
    label: 'Dashboard',
    icon: <FiGrid size={18} />,
  },
  {
    id: 'planner',
    path: ROUTES.Planner,
    label: 'Plan',
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66667 4.66667H4.66667V10.6667H6.66667V4.66667Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3333 4.66667H9.33333V8H11.3333V4.66667Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    id: 'projects',
    path: ROUTES.Projects,
    label: 'Campaigns',
    icon: <FiLayers size={18} />,
  },
  {
    id: 'library',
    path: ROUTES.Library,
    label: 'Library',
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.6668 12.6667C14.6668 13.0203 14.5264 13.3594 14.2763 13.6095C14.0263 13.8595 13.6871 14 13.3335 14H2.66683C2.31321 14 1.97407 13.8595 1.72402 13.6095C1.47397 13.3594 1.3335 13.0203 1.3335 12.6667V3.33333C1.3335 2.97971 1.47397 2.64057 1.72402 2.39052C1.97407 2.14048 2.31321 2 2.66683 2H6.00016L7.3335 4H13.3335C13.6871 4 14.0263 4.14048 14.2763 4.39052C14.5264 4.64057 14.6668 4.97971 14.6668 5.33333V12.6667Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    id: 'integration',
    path: 'integrations',
    label: 'Integrations',
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_21887_11021)">
          <path
            d="M4.00016 4.00065H4.00683M4.00016 12.0007H4.00683M2.66683 1.33398H13.3335C14.0699 1.33398 14.6668 1.93094 14.6668 2.66732V5.33398C14.6668 6.07036 14.0699 6.66732 13.3335 6.66732H2.66683C1.93045 6.66732 1.3335 6.07036 1.3335 5.33398V2.66732C1.3335 1.93094 1.93045 1.33398 2.66683 1.33398ZM2.66683 9.33398H13.3335C14.0699 9.33398 14.6668 9.93094 14.6668 10.6673V13.334C14.6668 14.0704 14.0699 14.6673 13.3335 14.6673H2.66683C1.93045 14.6673 1.3335 14.0704 1.3335 13.334V10.6673C1.3335 9.93094 1.93045 9.33398 2.66683 9.33398Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_21887_11021">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    // new: true,
  },
  {
    id: 'Brand',
    path: '/brand',
    label: 'Brand',
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_21897_24991)">
          <path
            d="M5.3335 9.33398C5.3335 9.33398 6.3335 10.6673 8.00016 10.6673C9.66683 10.6673 10.6668 9.33398 10.6668 9.33398M6.00016 6.00065H6.00683M10.0002 6.00065H10.0068M14.6668 8.00065C14.6668 11.6826 11.6821 14.6673 8.00016 14.6673C4.31826 14.6673 1.3335 11.6826 1.3335 8.00065C1.3335 4.31875 4.31826 1.33398 8.00016 1.33398C11.6821 1.33398 14.6668 4.31875 14.6668 8.00065Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_21897_24991">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    // new: true,
  },
  // {
  //   id: 'Insights',
  //   path: '/insights',
  //   label: 'Insights',
  //   icon: (
  //     <svg
  //       width="18"
  //       height="18"
  //       viewBox="0 0 16 16"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M8 13.3327V6.66602M12 13.3327V2.66602M4 13.3327V10.666"
  //         stroke="white"
  //         strokeWidth="1.5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //     </svg>
  //   ),
  //   new: true,
  // },

  {
    id: 'teams',
    path: ROUTES.Teams,
    label: 'Teams',
    icon: <FiUsers size={18} />,
  },
];

export const OTHER_OPTIONS = [
  {
    id: 'templates',
    path: ROUTES.Templates,
    label: 'Templates',
    icon: <FiZap size={18} />,
  },
];

export const TOOLS_SIDEBAR_OPTIONS = [
  {
    id: 'ideate',
    path: ROUTES.Ideate,
    label: 'Ideate',
    icon: <HiOutlineLightBulb size={18} />,
  },
  {
    id: 'research',
    path: ROUTES.Research,
    label: 'Research',
    icon: <FiSearch size={18} />,
  },
  // {
  //   id: 'analyze',
  //   path: ROUTES.Analyze,
  //   label: 'Analyze',
  //   icon: <FiFileText size={18} />,
  // },
];

export const TOOL_PATHS = ['/summarization', '/research', '/ideate'];
