import React from 'react';

import Flex from 'ui/Flex/Flex';
import Text from 'ui/Text/Text';

import { FaStar } from 'react-icons/fa';

import Styles from './CustomerReview.module.scss';

const ReviewCard = ({ color, text, rating = 5, name }) => {
  return (
    <Flex className={Styles.ReviewCard} data-color={color} direction={'column'}>
      <Text className={Styles.ReviewCard__text}>{text}</Text>
      <Flex direction="column" gap="8">
        <Flex className={Styles.stars_container}>
          {[...Array(rating)].map((_, index) => (
            <FaStar key={index} size={13} />
          ))}
        </Flex>
        <Text className={Styles.name}>{name}</Text>
      </Flex>
    </Flex>
  );
};

export default ReviewCard;
