import React, { useState, useMemo, useEffect } from 'react';
import InputGroup from 'Components/InputGroup/InputGroup';
import TableHead from 'Components/TableHeader/TableHead';
import { currencyFormat } from 'constants';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'redux/slices/loader';
import AIRToolInputSection from './AIRToolInputSection';
import sc from './AITool.module.scss';
import ResearchToolCards from './ResearchToolCards';
import { error as errorToast } from 'helpers/toaster';
import PageHeader from 'Components/PageHeader/PageHeader';
import userListEventsService from 'services/userListEventsService';
import { getResearch } from 'services/researchService';
import KeywordStatsTable from 'Components/TableBody/KeywordStatsTable';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';
import Input from 'Components/Input/Input';
import BSButton from 'Components/Button/BSButton';
import SearchInput from 'ui/SearchInput/SearchInput';
import { Dropdown } from 'react-bootstrap';
import { BsFilter } from 'react-icons/bs';

const TableHeadTitlesQnKV = (args) => {
  return [
    {
      title: args,
      id: 'args',
    },
    {
      title: 'Volume',
      id: 'volume',
    },
    {
      title: 'Results',
      id: 'results',
    },
    {
      title: 'Keyword difficulty',
      id: 'keyword_difficulty',
    },
    {
      title: 'CPC',
      id: 'cpc',
    },
  ];
};

const TableHeadTopHeadlines = [
  {
    title: 'Page title URL',
    id: 'page_title_url',
  },
];
const AIRTool = () => {
  const [researchInfo, setResearchInfo] = useState({});
  const [inputKeyword, setInputKeyword] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');

  const { user } = useSelector((state) => state.auth);
  const { subscriptions } = user ?? {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};
  const [showUpgradeModal, setShowUpgradeModal] = useState(false); // State for UpgradeModal visibility

  const dispatch = useDispatch();

  const getResearchData = async (keyword) => {
    if (currentSubscription?.plan_name === 'Basic Plan') {
      errorToast('You are not authorized to access this page.');
      return;
    }
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Fetching Keyword Info!',
          description: 'Please wait...',
        }),
      );
      setKeyword(keyword);
      setSearchKeyword(keyword);
      const response = await getResearch(keyword);

      // Send to userlist platform that user used this tool
      userListEventsService.updateUserListEvent({
        name: 'tools_keyword_research',
      });

      const { data } = response || {};
      setResearchInfo(data);
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );

      if (!data?.basic_info?.length) {
        errorToast('No Info found for this keyword');
      }
    } catch (error) {
      errorToast(error);
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    }
  };

  const setKeyword = (value) => {
    setInputKeyword(value);
  };

  const { basic_info, top_headlines, key_word_variations, questions } =
    researchInfo || {};

  const transformedTopHeadlines = useMemo(() => {
    if (!top_headlines?.length) {
      return [];
    }
    return top_headlines?.map((headline) => {
      return {
        id: Math.random(),
        link: headline?.Url,
        address: headline?.Domain,
      };
    });
  }, [top_headlines]);

  const keywordVariations = useMemo(() => {
    if (!key_word_variations?.length) {
      return [];
    }
    return key_word_variations?.map((keywordVariation) => {
      return {
        id: Math.random(),
        title: keywordVariation?.Keyword,
        volume: currencyFormat(keywordVariation?.['Search Volume'] || 0) || '0',
        results:
          currencyFormat(keywordVariation?.['Number of Results'] || 0) || '0',
        KeywordDifficulty: `${keywordVariation?.['Keyword Difficulty Index']}%`,
        CPC: `$${keywordVariation?.CPC}`,
      };
    });
  }, [key_word_variations]);

  const transformedQuestions = useMemo(() => {
    if (!questions?.length) {
      return [];
    }

    return questions?.map((keywordVariation) => {
      return {
        id: Math.random(),
        title: keywordVariation?.Keyword,
        volume: currencyFormat(keywordVariation?.['Search Volume'] || 0) || '0',
        results:
          currencyFormat(keywordVariation?.['Number of Results'] || 0) || '0',
        KeywordDifficulty: `${keywordVariation?.['Keyword Difficulty Index']}%`,
        CPC: `$${keywordVariation?.CPC}`,
      };
    });
  }, [questions]);

  useEffect(() => {
    if (currentSubscription?.plan_name === 'Basic Plan') {
      setShowUpgradeModal(true); // Show UpgradeModal if the plan is Basic
      return;
    }
  }, []);

  const AIRToolContentSection = (
    <section className="w-100">
      <div className={sc.AIRTOOL__top_container}>
        <p className={`${sc.AIRTOOL__top_container__heading} Animate-Heading`}>
          Keyword Research / Keyword: UI/UX
        </p>
        <p
          className={`${sc.AIRTOOL__top_container__description} Animate-Heading`}
        >
          Search your topics, break down keyword variations, browse the top
          queries and use these insights to brainstorm content ideas.
        </p>
        <div
          className={`d-flex justify-content-between gap-2 w-100 ${sc.AIRTOOL__InputSection}`}
        >
          <InputGroup
            headerInputBtn={true}
            InputPlaceHoder={'Enter Keyword..'}
            onChange={(e) => {
              setInputKeyword(e.target.value);
            }}
            value={inputKeyword}
            inputClass={'input'}
            BtnText="Search"
            variant={'dark'}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                getResearchData(inputKeyword);
              }
            }}
            onClick={() => getResearchData(inputKeyword)}
            btnDisabled={!inputKeyword.trim()}
            researchModule={true}
          />
        </div>
        <ResearchToolCards
          basicInfo={basic_info?.[0] || {}}
          researchModule={true}
        />
      </div>
      <div className={sc.tabs_container}>
        <Tabs
          defaultActiveKey="TopHeadline"
          className={'mb-3 px-3 tabs-wrapper'}
        >
          <Tab
            eventKey="TopHeadline"
            title={
              <div className="d-flex align-items-center gap-2">
                <span>Top pages</span>
                <span className="badge p-2">
                  {currencyFormat(transformedTopHeadlines?.length || 0)}
                </span>
              </div>
            }
          >
            <section
              className="rounded-table"
              style={{ maxHeight: '72vh', overflowY: 'scroll' }}
            >
              <Table className="table-fixed" responsive="lg">
                <TableHead bg={true} TableHeadings={TableHeadTopHeadlines} />
                <KeywordStatsTable
                  keywordVariations={transformedTopHeadlines || []}
                />
              </Table>
            </section>
          </Tab>
          <Tab
            eventKey="Keyword Variations"
            title={
              <div
                className={
                  'd-flex align-items-center gap-2 ' +
                  sc.ContentPlanner__Wrapper_NavigationTab
                }
              >
                <span>Keyword variations</span>
                <span className="badge p-2">
                  {currencyFormat(keywordVariations?.length || 0)}
                </span>
              </div>
            }
          >
            <section
              className="rounded-table"
              style={{ maxHeight: '60vh', overflowY: 'scroll' }}
            >
              <Table className="table-fixed" responsive="lg">
                <TableHead
                  bg={true}
                  TableHeadings={TableHeadTitlesQnKV('Keyword')}
                />
                <KeywordStatsTable
                  keywordVariations={keywordVariations || []}
                />
              </Table>
            </section>
          </Tab>
          <Tab
            eventKey="Questions"
            title={
              <div
                className={
                  'd-flex align-items-center gap-2 ' +
                  sc.ContentPlanner__Wrapper_NavigationTab
                }
              >
                <span>Top Queries</span>
                <span className="badge p-2">
                  {currencyFormat(transformedQuestions?.length || 0)}
                </span>
              </div>
            }
          >
            <section
              className="rounded-table"
              style={{ maxHeight: '72vh', overflowY: 'scroll' }}
            >
              <Table className="table-fixed" responsive={true}>
                <TableHead
                  bg={true}
                  TableHeadings={TableHeadTitlesQnKV('Questions')}
                />
                <KeywordStatsTable
                  keywordVariations={transformedQuestions || []}
                />
              </Table>
            </section>
          </Tab>
        </Tabs>
        {/* <div className={sc.tabs_container__filters}>
          <div className={`d-flex align-items-center gap-2`}>
            <div className="px-2">
              <SearchInput
                // setInputValue={setSearchTerm}
                // inputValue={searchTerm}
                text="Search"
              />
            </div>
            <Dropdown>
              <Dropdown.Toggle className={sc.filters}>
                <BsFilter size={20} />
                Filter
              </Dropdown.Toggle>

              {/* <Dropdown.Menu
                className={sc.ContentPlanner__Wrapper_InputSection_DropDown}
              >
                menu list here...
              </Dropdown.Menu> */}
            {/* </Dropdown>
          </div>
        </div> */} 
      </div>
    </section>
  );

  return (
    <>
      {Object.keys(researchInfo)?.length && basic_info?.length ? (
        AIRToolContentSection
      ) : (
        <AIRToolInputSection onClick={getResearchData} />
      )}
      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
        />
      )}
    </>
  );

};

export default AIRTool;
