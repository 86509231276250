import { useQuery } from '@tanstack/react-query';
import keys from 'constants/queryKeys';
import { getTemplates } from '../../services/templateService';

const useTemplates = () => {
  const query = useQuery({
    queryKey: keys.Templates,
    queryFn: getTemplates,
    staleTime: Infinity,
  });

  return {
    ...query,
    ...query?.data?.data,
  };
};

export default useTemplates;
