import Modal from 'react-bootstrap/Modal';
import ModalStyles from '../Modal/ShareModal/ShareModal.module.scss';
import { FiAlertCircle } from 'react-icons/fi';

const ErrorModal = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 1500 }}
    >
      <Modal.Header
        className="border-0"
        closeButton
        style={{ backgroundColor: '#FFFCF5' }}
      >
        <FiAlertCircle
          size={24}
          color="#D92D20"
          style={{ marginRight: '8px' }}
        />
        <Modal.Title className={ModalStyles.ShareModalHeading}>
          We couldn’t publish your document
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: '#FFFCF5', borderRadius: '8px' }}>
        <p>
          Your document is ok, but the connection to the CMS failed due to some
          technical issues. Try to publish the document again. If the issue
          continues, contact Customer Support.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
