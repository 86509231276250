import React from 'react';
import { ErrorMessage } from 'formik';
import FormikTextField from 'Components/Form/FormikTextField';
import PageStyles from '../AIProject.module.scss';

const NameAndDescriptionStep = () => {
  return (
    <section className={PageStyles.AIProjectContainer_Body_Step}>
      <div className={PageStyles.AIProjectContainer_Body_Step_Wrapper}>
        <b>What would you like to name your campaign?</b>
        <div>
          <FormikTextField
            name="name"
            placeholder="Campaign name"
            classList="w-100 input"
            onKeyDown={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
          <ErrorMessage name="name" component="div" className="error mt-2" />
        </div>
        <div className="mt-2">
          <b>Describe your Campaign topic and objectives</b>
          <p>This helps us suggest relevant content ideas.</p>
        </div>
        <div>
          <FormikTextField
            name="description"
            as="textarea"
            rows={3}
            placeholder="Enter a description..."
            classList="w-100 input"
            onKeyDown={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
          <ErrorMessage
            name="description"
            component="div"
            className="error mt-2"
          />
        </div>
      </div>
    </section>
  );
};

export default NameAndDescriptionStep;
