import React from 'react';
import BSButton from 'Components/Button/BSButton';
import Modal from 'react-bootstrap/Modal';
import { FiAlertCircle } from 'react-icons/fi';
import styles from '../../Containers/Subscription/EmailModal.module.scss';

const ProjectDeleteModal = ({ onDelete, ...rest }) => {
  return (
    <Modal
      {...rest}
      size={'400'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 100000 }}
    >
      <Modal.Body className="p-4">
        <div className="d-flex justify-content-center">
          <div
            style={{
              background: '#FEF3F2',
              height: '45px',
              width: '45px',
              borderRadius: '50%',
              position: 'relative',
            }}
          >
            <div
              style={{
                background: '#FEE4E2',
                height: '35px',
                width: '35px',
                borderRadius: '50%',
                margin: '5px',
                padding: '5px',
              }}
            >
              <FiAlertCircle size={25} color="#D92D20" />
            </div>
          </div>
        </div>

        <p
          style={{
            fontFamily: 'DM Sans',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '28px',
            letterSpacing: '0em',
            textAlign: 'center',
            marginTop: 20,
          }}
        >
          Delete Campaign
        </p>

        <p
          style={{
            fontFamily: 'DM Sans',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'center',
            color: '#667085',
          }}
        >
          Are you sure you want to delete this campaign? This action cannot be
          undone.
        </p>

        <div
          style={{
            marginTop: 30,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            gap: '10px',
          }}
        >
          <BSButton
            variant="light"
            ButtonText={'Cancel'}
            onClick={() => rest.onHide()}
            classList={
              'btn ' + styles.modalFooterBtn + ' ' + styles.modalFooterBtn_Light
            }
          />
          <BSButton
            variant="dark"
            ButtonText={'Delete'}
            onClick={onDelete}
            classList={'btn button secondary_btn'}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProjectDeleteModal;
