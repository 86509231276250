import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Styles from './ContentGenerationModal.module.scss';
import Loader from 'Components/Loader/Loader';

const ContentGenerationModal = ({ show, onHide, string, contentGenerated }) => {
  const normalizedString = string.replace(/```html/g, '');
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
      backdrop="static"
      style={{ zIndex: 999999 }}
    >
      <Modal.Header style={{ borderBottom: 'none' }} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {!contentGenerated ? `Generating Content...` : `Content Generated`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        id="editorContentBody"
        className={Styles.ModalBody__NoContentModal}
      >
        {normalizedString ? (
          <span dangerouslySetInnerHTML={{ __html: normalizedString }} />
        ) : (
          <div className="mt-3">
            <Loader inline />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ContentGenerationModal;
