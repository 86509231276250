import React from 'react';
import { FiFile, FiFolder, FiMoreVertical } from 'react-icons/fi';
import OptionsDropDown from 'Components/OptionsDropDown/OptionsDropDown';
import { error as renderError } from 'helpers/toaster.js';
import { ROUTES } from 'constants/routes';
import { useNavigate } from 'react-router-dom';

import Styles from './TableBody.module.scss';
import { deleteStarredFolder, starFolder } from 'services/folderService';
import CircularIcon from 'Components/Icons/CircularIcon';
import pluralize from 'pluralize';
import Badge from 'Components/Badge/Badge';
import Collaborators from 'Components/Collaborators/Collaborators';
import Star from 'Components/Star/Star';
import useDeleteStarredDocument from 'hooks/documents/useDeleteStarredDocument';
import useStarDocument from 'hooks/documents/useStarDocument';
import { FaFolder } from 'react-icons/fa';
import { TbFileFilled } from 'react-icons/tb';
import { formatmdy } from 'helpers/dates';
import { CONTENT_TYPE_ICONS } from 'constants/contentTypes';

const DocumentListBody = ({
  tableData,
  fetchFolders,
  isTrash,
  projectView,
}) => {
  const navigate = useNavigate();

  const { mutateAsync: addStar } = useStarDocument();
  const { mutateAsync: removeStar } = useDeleteStarredDocument();

  const handleStar = async (documentId, starred) => {
    if (Object.keys(starred || {}).length) {
      await removeStar(starred.id);
    } else {
      await addStar(documentId);
    }

    await fetchFolders(false);
  };

  const handleFolderStar = async (folderId, starred) => {
    try {
      if (Object.keys(starred || {}).length) {
        await deleteStarredFolder(starred.id);
      } else {
        await starFolder(folderId);
      }

      fetchFolders && fetchFolders(false);
    } catch (error) {
      renderError(error);
    }
  };

  const handleClick = (isDocument, id, templateId, projectId) => {
    if (isTrash) return;

    if (isDocument) {
      const targetRoute = templateId
        ? ROUTES.EditorDocumentTemplate(id, templateId)
        : ROUTES.FreestyleDocumentTemplate(id);
      navigate(targetRoute);
    } else {
      const targetRoute = !projectView
        ? ROUTES.Project.Folder(projectId, id)
        : ROUTES.Folder(id);
      navigate(targetRoute);
    }
  };

  const handleStarOnClick = (isDocument, id, starred) => {
    if (isDocument) {
      handleStar(id, starred);
    } else {
      handleFolderStar(id, starred);
    }
  };

  return (
    <tbody>
      {tableData.map((rowItem) => {
        const {
          id,
          title,
          documents_count,
          created_at,
          starred,
          collaborators,
          isDocument,
          template_id,
          status,
          project_id,
          user,
          task
        } = rowItem || {};

        return (
          <tr key={id} className={Styles.TableRow}>
            <td
              className={`d-flex align-items-center gap-4 text-md-Regular ${Styles.TableData} ${Styles.TableData__Extensive}`}
            >
              {!isTrash && (
                <Star
                  isStarred={starred}
                  shouldDisplay={true}
                  position="flex"
                  onClick={() => handleStarOnClick(isDocument, id, starred)}
                />
              )}
              <div
                className={Styles.TableRow_Name}
                onClick={() =>
                  handleClick(isDocument, id, template_id, project_id)
                }
              >
                <CircularIcon
                  height="32px"
                  width="32px"
                  background={isDocument ? '#ECF2FF' : '#FFF9F0'}
                >
                  {isDocument ? (
                    <TbFileFilled size={20} color="#0BA5EC" />
                  ) : (
                    <FaFolder size={20} color="#F8C864" />
                  )}
                </CircularIcon>
                <p className={`${Styles.TableData_Title_library}`}>
                  <span>{title}</span>
                  {documents_count >= 0 && (
                    <span className={Styles.TableData_Title_Items}>
                      (
                      {`${documents_count} ${pluralize(
                        'item',
                        documents_count,
                      )}`}
                      )
                    </span>
                  )}
                </p>
              </div>
            </td>
            <td
              className={'text-sm-normal overflow-visible ' + Styles.TableData}
              style={{ textWrap: 'nowrap' }}
            >
              {/* content type here */}
              <div>
                {task ?
                  task.content_type &&
                  (task.content_type === 'other'
                    ? CONTENT_TYPE_ICONS.other
                    : CONTENT_TYPE_ICONS[task.content_type]): "-"}
              </div>
            </td>
            <td
              className={'text-sm-normal overflow-visible ' + Styles.TableData}
              style={{ textWrap: 'nowrap' }}
            >
              {formatmdy(created_at)}
            </td>
            <td style={{ textWrap: 'nowrap' }}>
              {status && <Badge status={status} />}
            </td>
            <td
              className={'text-sm-normal overflow-visible ' + Styles.TableData}
            >
              <div className={'d-flex stacked-Images-rounded ' + Styles.TableData_Collaborators}>

                <Collaborators
                  editors={[user, ...collaborators]}
                  className={Styles.TableData_Collaborators_Avatar}
                />
              </div>
            </td>
            <td className={'overflow-visible ' + Styles.TableData_Title}>
              <OptionsDropDown
                OptionsFor={isDocument ? 'File' : 'Folder'}
                drop="end"
                Icon={<FiMoreVertical size={20} color="#98A2B3" />}
                document={rowItem}
                fetchFolders={fetchFolders}
                isDocument={isDocument}
                folder={rowItem}
                isTrash={isTrash}
                chevronRight={true}
              />
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default DocumentListBody;
