/* eslint-disable react/display-name */
import {
  PaymentElement,
  useElements,
  useStripe,
  CardElement,
} from '@stripe/react-stripe-js';
import { BILLING_PAYMENT, BILLING_SUBSCRIPTION } from 'constants';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setLoader } from 'redux/slices/loader';
import {
  createCard,
  createCustomer,
  createSubscription,
  getClientSecret,
  markDefaultCard,
} from 'services/subscriptionService';

export const CheckoutForm = forwardRef(
  ({ priceId, isProductView, refetch, setDisableSubmitCardButton }, ref) => {
    const stripe = useStripe();
    const elements = useElements();

    const dispatch = useDispatch();

    const handleError = (error) => {
      dispatch(setLoader({ isLoading: false }));
      toast.error(error.message);
    };
    const handleCardChange = (event) => {
      if (event.complete) {
        setDisableSubmitCardButton && setDisableSubmitCardButton(false);
      } else {
        setDisableSubmitCardButton && setDisableSubmitCardButton(true);
      }
    };

    const handleSubmit = async (isProductView = true) => {
      dispatch(
        setLoader({
          isLoading: true,
          heading: isProductView && 'Adding New Card',
          description: 'Please wait...',
        }),
      );
      try {
        if (!stripe) {
          // Stripe.js hasn't yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          dispatch(setLoader({ isLoading: false }));
          return;
        }

        const customer = await createCustomer();

        if (!isProductView) {
          const element = elements.getElement(CardElement);
          const { token, error } = await stripe.createToken(element);

          if (error) {
            handleError(error);
            throw error;
          }

          await createCard(token.id);
          toast.success('Card Created Successfully');
          refetch && refetch();
          dispatch(setLoader({ isLoading: false }));
          return;
        }

        try {
          // Fetch the client secret for the SetupIntent
          const response = await getClientSecret(
            customer?.data?.stripe_customer_id,
          );
          const clientSecret = response?.data?.client_secret || {};

          if (!clientSecret) {
            throw new Error('Failed to get client secret.');
          }

          // Trigger form validation and wallet collection
          const { error: submitError } = await elements.submit();
          if (submitError) {
            handleError(submitError, 'submit error');
            return;
          }

          // Confirm the SetupIntent using the Payment Element
          const { setupIntent, error } = await stripe.confirmSetup({
            elements,
            clientSecret,
            confirmParams: {
              return_url: window.location.href, // Use the current URL as the return_url
            },
            redirect: 'if_required',
          });

          if (error) {
            handleError(error, 'handel error');
          } else if (setupIntent.status === 'succeeded') {
            handleError('');
            toast.success('Card Created Successfully');
            elements.getElement(PaymentElement).clear();

            // Set the payment method as default if it's the first one
            const paymentMethodId = setupIntent.payment_method;
            const defaultRes = await markDefaultCard(paymentMethodId);
            if (defaultRes.data.message === 'Successfully marked as default!') {
              toast.success(defaultRes.data.message);
            } else {
              handleError(defaultRes.data);
            }
          } else {
            handleError('Unexpected status: ' + setupIntent.status);
          }
        } catch (error) {
          handleError(error);
        }

        dispatch(setLoader({ isLoading: false }));
      } catch (error) {
        handleError({
          message: error?.response?.data?.error || 'Something went wrong',
        });
        dispatch(setLoader({ isLoading: false }));
        throw error;
      }
    };

    useImperativeHandle(ref, () => {
      return {
        async handleSubmit(isProductView = true) {
          await handleSubmit(isProductView);
        },
      };
    });

    return (
      <form>
        {isProductView ? (
          <PaymentElement onChange={handleCardChange} />
        ) : (
          <CardElement onChange={handleCardChange} />
        )}
        {/* <button type="submit" disabled={!stripe || loading}>
        Submit Payment
      </button> */}
        {/* {errorMessage && <div>{errorMessage}</div>} */}
      </form>
    );
  },
);

export default CheckoutForm;
