import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate } from 'react-router-dom';

import CategoryTemplateCard from '../Modal/PickTemplateModal/CategoryTemplateCard';
import Styles from './Templates.module.scss';
import { ROUTES } from 'constants/routes';
import PageHeader from 'Components/PageHeader/PageHeader';
import Loader from 'Components/Loader/Loader';
import useCategoriesWithTemplates from 'hooks/templates/useCategoriesWithTemplates';
import SearchInput from 'ui/SearchInput/SearchInput';

const Templates = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  const { isLoading, categoriesWithTemplates } = useCategoriesWithTemplates(
    searchValue,
    true,
  );

  const handleTemplateClick = (templateId) => {
    navigate(ROUTES.QuestionnaireTemplate(templateId));
  };

  return (
    <section className={`d-flex flex-column ${Styles.Templates}`}>
      {/* <PageHeader
        title="Templates to Kickstart Your Content"
        subtitle="Choose, customize, and draft your content using our AI-powered templates."
      /> */}
      <div className="position-relative templates-tabs-wrapper_not">
        {isLoading ? (
          <div className="mt-4">
            <Loader inline loaderHeading="Loading Templates..." />
          </div>
        ) : (
          <>
            <Tabs
              defaultActiveKey={categoriesWithTemplates?.[0]?.id}
              className="px-4"
            >
              {categoriesWithTemplates?.map((category) => (
                <Tab
                  title={category.title}
                  eventKey={category.id}
                  key={category.id}
                  tabClassName={`py-4 ${category.title.replace(/\s/g, '')}`}
                >
                  <div
                    className={`d-flex flex-wrap gap-3 p-4 ${category.title.replace(
                      /\s/g,
                      '',
                    )} ${Styles.contentWrapper}`}
                  >
                    <CategoryTemplateCard
                      templates={category.templates || []}
                      handleTemplateClick={handleTemplateClick}
                    />
                  </div>
                </Tab>
              ))}
            </Tabs>
            <div
              className={
                'd-flex justify-content-between gap-4 ' +
                Styles.Templates_InputSection
              }
            >
              <SearchInput
                setInputValue={setSearchValue}
                inputValue={searchValue}
                text="Search"
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Templates;
