import React from 'react';
import Spinner from 'Components/Spinner/Spinner';
import Styles from './AIPoweredBrief.module.scss';
import { foundationSchema } from 'schemas/briefSchemas';
import BSButton from 'Components/Button/BSButton';
import { FiArrowRight } from 'react-icons/fi';

const HeadlineIdeas = ({
  formFields,
  handleFormFieldsChange,
  headlines,
  setFieldValue,
  previousStep,
  isFetchingHeadlines,
}) => {
  return (
    <div className={Styles.Container_Form}>
      {isFetchingHeadlines ? (
        <Spinner
          className={Styles.Container_KeywordsContainer_KeywordsSpinner}
          text="Magic Takes Time"
        />
      ) : (
        <>
          <p className={Styles.Container_Title}>
            You've got options! Based on your input, we’ve cooked up some
            headline ideas.
          </p>
          <p className={Styles.Container_Subtitle}>
            Select one to complete your brief.
          </p>
          <section className={Styles.Container_HeadlinesContainer}>
            {headlines?.map((headline, index) => (
              <div
                className={Styles.Container_HeadlinesContainer_HeadlineCard}
                key={`${headline}-${index}`}
              >
                <label
                  htmlFor={headline}
                  className={
                    Styles.Container_HeadlinesContainer_HeadlineCard_Title
                  }
                >
                  "{headline}"
                </label>
                <input
                  type="radio"
                  id={headline}
                  name="headline"
                  value={headline}
                  onClick={() => {
                    handleFormFieldsChange('headline', headline);
                    setFieldValue('headline', headline);
                  }}
                  className={
                    Styles.Container_HeadlinesContainer_HeadlineCard_RadioButton
                  }
                />
              </div>
            ))}
          </section>
          <div className="d-flex gap-2 justify-content-end drawer-footer">
            <BSButton
              classList={`button cancel_btn`}
              variant="outline-secondary"
              ButtonText="Back"
              onClick={previousStep}
            />
            <BSButton
              classList={`button secondary_btn ${Styles.Container_NextButton}`}
              variant="light"
              ButtonText="Generate Brief"
              iconPosition="right"
              disabled={!foundationSchema.isValidSync(formFields)}
              type="submit"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default HeadlineIdeas;
