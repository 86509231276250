import React, { useState } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  FiCopy,
  FiDownload,
  FiRefreshCcw,
  FiTrash2,
  FiUserPlus,
} from 'react-icons/fi';

import StyleClasses from './OptionsDropDown.module.scss';
import { useSelector } from 'react-redux';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';

const FolderOptions = ({
  isFolderOwner,
  isFullAccessFolder,
  isTrash,
  drop,
  Icon,
  setShareModalOpen,
  toggleShowFormats,
  exportZipFolder,
  setShowDuplicateModal,
  setDeleteFolderModalView,
  restoreFolder,
  documentAccessLevel,
  setDeleteTrashConfirmModalView,
  projectId
}) => {
  const hasFullAccess = isFolderOwner || isFullAccessFolder;

  const { user } = useSelector((state) => state.auth);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { id: userId, subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  return (
    <>
      {!isTrash ? (
        <DropdownButton
          className={StyleClasses.DropdownWrapper}
          drop={drop}
          title={Icon}
        >
          {hasFullAccess && (
            <Dropdown.Item
              onClick={currentSubscription?.plan_name === "Basic Plan" && projectId !== null ? ()=>setShowUpgradeModal(true) :() => setShareModalOpen(true)}
              className={StyleClasses.dropDownItemWrapper}
            >
              <div className={StyleClasses.listItem_sm}>
                <FiUserPlus color="#667085" size={16} />
                <p>Add Collaborator</p>
              </div>
            </Dropdown.Item>
          )}

          <Dropdown.Item
            className={StyleClasses.dropDownItemWrapper}
            onMouseEnter={toggleShowFormats}
            onMouseLeave={toggleShowFormats}
          >
            <div
              className={'position-relative ' + StyleClasses.listItem_sm}
              onClick={exportZipFolder}
            >
              <FiDownload size={16} color="#667085" />
              <p>Export as Zip</p>
            </div>
          </Dropdown.Item>

          <Dropdown.Item
            className={StyleClasses.dropDownItemWrapper}
            onClick={() => setShowDuplicateModal(true)}
          >
            <div className={StyleClasses.listItem_sm}>
              <FiCopy size={16} color="#667085" />
              <p>Duplicate</p>
            </div>
          </Dropdown.Item>

          {hasFullAccess && (
            <Dropdown.Item
              className={StyleClasses.dropDownItemWrapper}
              onClick={() => setDeleteFolderModalView(true)}
            >
              <div className={StyleClasses.listItem_sm}>
                <FiTrash2 size={16} color="#B42318" />
                <p className="error">Delete</p>
              </div>
            </Dropdown.Item>
          )}
        </DropdownButton>
      ) : (
        <DropdownButton
          className={StyleClasses.DropdownWrapper}
          drop={drop}
          title={Icon}
        >
          <Dropdown.Item
            className={StyleClasses.dropDownItemWrapper}
            onClick={() => restoreFolder()}
          >
            <div className={StyleClasses.listItem_sm}>
              <FiRefreshCcw />
              <p>Restore</p>
            </div>
          </Dropdown.Item>
          {documentAccessLevel === 'full_access' && (
            <Dropdown.Item
              className={StyleClasses.dropDownItemWrapper}
              onClick={() => setDeleteTrashConfirmModalView(true)}
            >
              <div className={StyleClasses.listItem_sm}>
                <FiTrash2 size={16} color="#B42318" />
                <p className="error">Delete</p>
              </div>
            </Dropdown.Item>
          )}
        </DropdownButton>
      )}
      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
          message={
            'Updating campaign related content is a pro feature. Please update to pro plan to access this feature.'
          }
        />
      )}
    </>
  );
};

export default FolderOptions;
