import React, { useState } from 'react';
import { Button, Modal, Nav, Row, Tab } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';

import CategoryTemplateCard from './CategoryTemplateCard';
import Styles from './PickTemplateModal.module.scss';
import { ROUTES } from 'constants/routes';
import Loader from 'Components/Loader/Loader';
import { getCategoriesSvg } from './getCategoriesSvg';
import useCategoriesWithTemplates from 'hooks/templates/useCategoriesWithTemplates';

const PickTemplateModal = ({ show, onHide, taskId, folderId }) => {
  const [selected, setSelected] = useState('');

  const { projectId } = useParams();
  const navigate = useNavigate();

  const { isLoading, categoriesWithTemplates } = useCategoriesWithTemplates();

  const handleTemplateClick = async (templateId) => {
    onHide();
    navigate(
      ROUTES.QuestionnaireTemplate(templateId, { taskId, projectId, folderId }),
    );
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="PickATemplateModal"
      show={show}
      onHide={onHide}
      style={{ zIndex: 100000 }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="Modal-Title "
          id="contained-modal-title-vcenter"
        >
          Choose template
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isLoading ? (
          <div className="mt-4">
            <Loader inline loaderHeading="Loading Templates..." />
          </div>
        ) : (
          <Tab.Container
            id="template-tabs"
            defaultActiveKey={categoriesWithTemplates?.[0]?.id}
          >
            <Row className="g-0">
              <Nav className={Styles.TabWrapper}>
                {categoriesWithTemplates?.map((category) => (
                  <Nav.Item
                    key={category.id}
                    className={`nav-item ${category.title.replace(/\s/g, '')}`}
                  >
                    <Nav.Link eventKey={category.id}>
                      <div className={Styles.tabTitle}>
                        <div className={'title_image ' + Styles.TitleImage}>
                          {getCategoriesSvg(category.title)}
                        </div>
                        <p>{category.title}</p>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Row>

            <Row className="g-0">
              <Tab.Content>
                {categoriesWithTemplates?.map((category) => (
                  <Tab.Pane eventKey={category.id} key={category.id}>
                    <div
                      className={`${category.title.replace(/\s/g, '')} ${
                        Styles.contentWrapper
                      }`}
                    >
                      <CategoryTemplateCard
                        templates={category.templates || []}
                        setSelected={setSelected}
                        selected={selected}
                      />
                    </div>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Row>
          </Tab.Container>
        )}
      </Modal.Body>

      <Modal.Footer className={Styles.ModalFooter}>
        <Button
          variant="light"
          className={`${Styles.Button} ${Styles.Button__light}`}
          onClick={onHide}
        >
          Cancel
        </Button>
        <Button
          variant="dark"
          className={`secondary_btn h-auto ${Styles.Button} ${Styles.Button__dark}`}
          disabled={!selected}
          onClick={() => handleTemplateClick(selected)}
        >
          Continue
          <FiArrowRight size={20} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PickTemplateModal;
