import React, { useEffect, useState } from 'react';
import Styles from './Styles.module.scss';
import { IoMdCheckmark } from 'react-icons/io';
import Animation from '../../../../assets/animations/loaging_animation.mov';
const Board = () => {
  const labels = [
    'Understanding your business',
    'Finding your target audience',
    'Searching web for trending topics',
    // 'Choosing your branding',
    'Creating content ideas for your business',
  ];

  const [active, setActive] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setActive(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={Styles.board}>
      <label htmlFor="" className={Styles.board__heading}>
        We're Getting to Know Your Brand
      </label>
      <div className={Styles.board__labels}>
        {/* {labels.map((item, index) => {
          return (
            <div
              key={index}
              className={`${Styles.board__labels__label} ${active ? Styles.active : ''}`}
            >
              {item}
              <IoMdCheckmark
                color="#17B26A"
                className={`${Styles.board__labels__label__icon} ${active ? Styles.active : ''}`}
              />
            </div>
          );
        })} */}

        <div className={Styles.giff_img}>
          <video
            autoPlay
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          >
            <source src={Animation} />
          </video>
        </div>
        <span className={Styles.board__timer}>
          Fetching Data, It might take 30 sec...
        </span>
      </div>
    </div>
  );
};

export default Board;
