import React, { useState } from 'react';
import Styles from './OnClick.module.scss';
import Annoucement from '../../../assets/animations/anoucement.gif';
import { ReactComponent as Instagram } from '../../../assets/publishpage/instagram (1).svg';
import { ReactComponent as X } from '../../../assets/publishpage/twitter.svg';
import { ReactComponent as Ticktok } from '../../../assets/publishpage/tiktok.svg';
import { ReactComponent as Linkedin } from '../../../assets/publishpage/linkedin (1).svg';
import { ReactComponent as Facebook } from '../../../assets/publishpage/type=facebook.svg';
import Carousel from '../../Editor/Components/Carousel/Carousel';
import AddSocialAccountDrawer from './AddSocialAccountDrawer';

const OneClick = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const handleShowDrawer = () => {
    setShowDrawer(!showDrawer);
  };
  return (
    <div className={Styles.wraper}>
      <div className={Styles.wraper__lotie}>
        <img src={Annoucement} alt="annoucement" />
      </div>
      <div className={Styles.wraper__heading}>One-Click Content Promotion</div>
      <div className={Styles.wraper__description}>
        Maximize your content's visibility and impact by sharing it instantly to
        your connected social media accounts. With just a single click, you can
        cross-promote your latest blog post, video, or any other content across
        platforms like Facebook, Twitter, LinkedIn, and more.
      </div>
      <Carousel>
        <div className={Styles.wraper__animation}>
          <div className={Styles.wraper__animation__logo}>
            <Instagram />
          </div>
          <div className={Styles.wraper__animation__logo}>
            <X />
          </div>
          <div className={Styles.wraper__animation__logo}>
            <Ticktok />
          </div>
          <div className={Styles.wraper__animation__logo}>
            <Linkedin />
          </div>
          <div className={Styles.wraper__animation__logo}>
            <Facebook />
          </div>
        </div>
      </Carousel>
      <div className={Styles.wraper__btn_section}>
        <button className="cancel_btn" onClick={handleShowDrawer}>
          Add Social Accounts
        </button>
      </div>
      <AddSocialAccountDrawer show={showDrawer} onHide={handleShowDrawer} />
    </div>
  );
};

export default OneClick;
