import React from 'react';
import { useState } from 'react';
import DropDownListItem from './DropDownListItem/DropDownListItem';
import styles from './DropDownList.module.scss';

const DropDownList = ({ expandedViewEnabled, listItems }) => {
  const [expandMenu, setExpandMenu] = useState(false);
  const [expandMenuList, setExpandMenuList] = useState('');

  const hoverHandler = (listItems) => {
    if (!expandedViewEnabled) return;

    setExpandMenu(true);
    setExpandMenuList(
      listItems.map((item) => (
        <DropDownListItem
          key={item.id}
          title={item.title}
          icon={item.iconPath}
          hoverHandler={() => {}}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      )),
    );
  };

  return (
    <ul
      className={
        !expandMenu ? styles.dropdownMenu : styles.dropdownMenu_Expanded
      }
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className="list_Section"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {listItems.map((item) => (
          <DropDownListItem
            key={item.id}
            title={item.title}
            icon={item.iconPath}
            subtitles={item.sublist}
            hoverHandler={hoverHandler}
            href={item.href}
          />
        ))}
      </div>
      {expandMenu && <div className={styles.sublist}>{expandMenuList}</div>}
    </ul>
  );
};

export default DropDownList;
