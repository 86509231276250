import React from 'react';
import Styles from './Dashboard.module.scss';

export const dashboardSelectOptions = (labels) => {
  return labels.map((label, index) => ({
    value: label.value || index,
    label: (
      <div className={Styles.label}>
        <span>{label.label}</span>
      </div>
    ),
  }));
};

export const DashboardSelectStyles = {
  option: (styles, { isSelected, isFocused }) => {
    return {
      backgroundRepeat: 'no-repeat',
      borderRadius: 'none',
      paddingRight: '0px',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: '500',
      color: '#344054',
      '&:hover': {
        cursor: 'pointer',
      },
    };
  },

  control: (baseStyles) => {
    return {
      ...baseStyles,
      cursor: 'pointer',
    };
  },
};
