import React from 'react';
import Styles from './CompaignCardSkeleton.module.scss';

const CompaignCardSkeleton = ({ type, count = 1 }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <div
          className={`${Styles.card} ${type === 'document_card' ? Styles.document_card : ''}`}
          key={index}
        >
          <div
            className={`${Styles.card__header} ${type === 'document_card' ? Styles.document_card_header : ''}`}
          >
            <div
              className={`${Styles.card__header__badge} ${type === 'document_card' && Styles.document_card_header_badge}`}
            ></div>
            <div className={`${Styles.card__header__avatar}`}></div>
          </div>
          <p className={Styles.card_heading}></p>
          <div className={Styles.card__date}></div>
          <div
            className={`${Styles.card__footer} ${type === 'document_card' ? Styles.card__footer_document : ''}`}
          >
            <div className={Styles.card__footer__avatars}></div>
            <span className={Styles.card__footer__dots}></span>
          </div>
        </div>
      ))}
    </>
  );
};
export default CompaignCardSkeleton;
