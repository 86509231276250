import { useInfiniteQuery } from '@tanstack/react-query';
import { getDocuments } from 'services/documentService';
import keys from 'constants/queryKeys';

const useInfiniteDocuments = ({
  status,
  type = '',
  search,
  projectId,
  enabled,
}) => {
  return useInfiniteQuery({
    enabled,
    queryKey: keys.Library({ search, type, projectId, status }),
    queryFn: ({ pageParam }) =>
      getDocuments({ type, search, projectId, status, pageParam }),
    initialPageParam: 1,
    keepPreviousData: true,
    getNextPageParam: ({ data }) => {
      const {
        meta_data: { pagination },
      } = data;
      if (pagination.current_page < pagination.total_pages) {
        return pagination.current_page + 1;
      }
      return undefined;
    },
  });
};

export default useInfiniteDocuments;
