import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Styles from './Drawer.module.scss';

const Drawer = ({ show, onHide, placement, title, width = 480, children }) => {
  return (
    <Offcanvas
      show={show}
      onHide={onHide}
      placement={placement}
      className={Styles.Drawer}
      backdropClassName={Styles.Backdrop}
      style={{
        width: `${width}px`,
        borderRadius: '20px',
      }}
    >
      <Offcanvas.Header className={Styles.Drawer_Header} closeButton>
        <div className={Styles.Drawer_Header_Title}>{title}</div>
      </Offcanvas.Header>
      <Offcanvas.Body className={Styles.Drawer_Body}>{children}</Offcanvas.Body>
    </Offcanvas>
  );
};

export default Drawer;
