import React from 'react';
import { ErrorMessage, Field } from 'formik';
import Dropdown from 'Components/Dropdown/Dropdown';
import FormikCheckbox from 'Components/Form/FormikCheckbox';
import FormikDatePicker from 'Components/Form/FormikDatePicker';
import PageStyles from '../AIProject.module.scss';
import { CONTENT_TYPE_OPTIONS } from 'constants/contentTypes';

const ContentTypeAndDateStep = ({ setFieldValue }) => {
  return (
    <section className={PageStyles.AIProjectContainer_Body_Step}>
      <div className={PageStyles.AIProjectContainer_Body_Step_Wrapper}>
        <b>What type of content are you interested in?</b>
        <div>
          <div>
            <Dropdown
              name="contentType"
              placeholder="Select content type"
              options={CONTENT_TYPE_OPTIONS}
              required
              onChange={(option) =>
                setFieldValue(
                  'contentType',
                  option.map(({ value }) => value),
                )
              }
              isMulti
            />
          </div>
          <div className={PageStyles.Optional}>
            <p>Or... leave the type of content to us</p>
            <FormikCheckbox Label="Surprise me" name="surpriseMe" />
          </div>
        </div>
        <b className="mt-4">What is your project timeline?</b>
        <div>
          <div className="d-flex gap-4">
            <div className="w-100">
              <label className="Input_label form-label">Start Date</label>
              <Field
                name="startDate"
                component={FormikDatePicker}
                className="input form-control"
              />
            </div>
            <div className="w-100">
              <label className="Input_label form-label">End Date</label>
              <Field
                name="endDate"
                component={FormikDatePicker}
                className="input form-control"
              />
            </div>
          </div>
          <ErrorMessage
            name="startDate"
            component="div"
            className="error mt-2"
          />
          <ErrorMessage name="endDate" component="div" className="error mt-2" />
        </div>
      </div>
    </section>
  );
};

export default ContentTypeAndDateStep;
