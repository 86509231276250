const fontSizes = {
  8: '0.5rem',
  12: '0.75rem',
  14: '0.875rem',
  16: '1rem',
  18: '1.125rem',
  20: '1.25rem',
  24: '1.5rem',
  28: '1.75rem',
  32: '2rem',
  36: '2.25rem',
  40: '2.5rem',
  44: '2.75rem',
  48: '3rem',
  64: '4rem',
  72: '4.5rem',
};

const fontWeights = {
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
  extraBold: '800',
};

const letterSpacings = {
  2: '0.125rem',
};

const lineHeights = {
  8: '0.5rem',
  12: '0.75rem',
  14: '0.875rem',
  16: '1rem',
  20: '1.25rem',
  24: '1.5rem',
  28: '1.75rem',
  32: '2rem',
  36: '2.25rem',
  40: '2.5rem',
  44: '2.75rem',
  48: '3rem',
  64: '4rem',
  72: '4.5rem',
};

export const fontSettings = {
  fonts: {
    body: '"DM Sans", sans-serif',
  },
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
};
