import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { BiTrash } from 'react-icons/bi';
import { BsArrowRightCircle } from 'react-icons/bs';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FiArchive, FiEdit2 } from 'react-icons/fi';
import Styles from './KanbanCard.module.scss';
import { AiOutlineDelete } from 'react-icons/ai';

const KanbanDropDown = ({
  id,
  onDelete,
  onMove,
  onEdit,
  access = '',
  state = 'not_started',
  onArchive,
  threedots,
}) => {
  return (
    <DropdownButton
      className={threedots}
      title={<BsThreeDotsVertical size={20} color="#1D2939" />}
    >
      <Dropdown.Item className={Styles.DropdownList} onClick={() => onMove(id)}>
        <BsArrowRightCircle color="#1D2939" />
        <span>Move to </span>
      </Dropdown.Item>
      <Dropdown.Item className={Styles.DropdownList} onClick={() => onEdit(id)}>
        <FiEdit2 color="#1D2939" />
        <span>Edit </span>
      </Dropdown.Item>
      <Dropdown.Item
        className={Styles.DropdownList}
        onClick={() => (onArchive ? onArchive(id) : {})}
      >
        <FiArchive color="#1D2939" />
        <span>Archive </span>
      </Dropdown.Item>

      <Dropdown.Item
        className={Styles.DropdownList}
        onClick={() => onDelete(id)}
      >
        <AiOutlineDelete color="#1D2939" />

        <span>Delete </span>
      </Dropdown.Item>
    </DropdownButton>
  );
};

export default KanbanDropDown;
