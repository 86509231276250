import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

import ModalStyles from '../ShareModal/ShareModal.module.scss';
import { publishDocuments } from 'services/integrationService';
import { getProjectIntegrations } from 'services/projectService';
import { error as errorToast, success as successToast } from 'helpers/toaster';
import ProjectIntegrationsCard from 'Containers/Integrations/ProjectIntegrationsCard';
import { ROUTES } from 'constants/routes';
import BSButton from 'Components/Button/BSButton';
import ErrorModal from '../../ErrorModal';
import { PROJECT_TABS } from 'constants/projects';

const statusProjectIntegrations = {
  NO_PROJECT: 'no_project',
  NO_INTEGRATION: 'no_integration',
  LIST: 'list',
};

const StatusHandler = ({ activeStatus, currentStatus, children }) => {
  if (activeStatus !== currentStatus) {
    return null;
  }
  return <>{children}</>;
};

const ProjectIntegrationsModal = ({ projectId, documentId, ...props }) => {
  const [integrations, setIntegrations] = useState([]);
  const [status, setStatus] = useState();
  const [showErrorModal, setShowErrorModal] = useState(false);

  const navigate = useNavigate();

  const fetchIntegrations = useCallback(async () => {
    try {
      if (!projectId) {
        setStatus(statusProjectIntegrations.NO_PROJECT);
        return;
      }

      const projectIntegrations = await getProjectIntegrations(projectId);

      const { platform_integrations } = projectIntegrations?.data || {};

      const activeIntegrations = platform_integrations?.filter(
        (integration) => integration.is_active,
      );

      setIntegrations(activeIntegrations);

      if (!activeIntegrations?.length) {
        setStatus(statusProjectIntegrations.NO_INTEGRATION);
        return;
      }

      setStatus(statusProjectIntegrations.LIST);
    } catch (error) {
      errorToast(error);
    }
  }, [projectId]);

  useEffect(() => {
    fetchIntegrations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const onClickIntegration = async (integrationId, status) => {
    try {
      await publishDocuments(documentId, {
        integration_id: integrationId,
        status,
      });
      successToast(`Document published with the state: ${status}`);
      props.onHide();
    } catch (error) {
      props.onHide();
      setShowErrorModal(true);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ zIndex: 1500 }}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className={ModalStyles.ShareModalHeading}>
            Publish to
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <StatusHandler
              activeStatus={statusProjectIntegrations.NO_PROJECT}
              currentStatus={status}
            >
              The document needs to be part of a project before publishing.
            </StatusHandler>

            <StatusHandler
              activeStatus={statusProjectIntegrations.NO_INTEGRATION}
              currentStatus={status}
            >
              <p className={'pb-3'}>
                You don’t have any publishing channels set up.
              </p>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <BSButton
                  classList="button secondary_btn"
                  ButtonText="Add new channel"
                  variant="light"
                  onClick={() =>
                    navigate(
                      ROUTES.ProjectSlug(projectId, {
                        tab: PROJECT_TABS.channels,
                      }),
                    )
                  }
                />
              </div>
            </StatusHandler>

            <StatusHandler
              activeStatus={statusProjectIntegrations.LIST}
              currentStatus={status}
            >
              {integrations.map((integration) => (
                <ProjectIntegrationsCard
                  key={integration.id}
                  integration={integration}
                  onClickIntegration={onClickIntegration}
                />
              ))}
            </StatusHandler>
          </div>
        </Modal.Body>
      </Modal>
      <ErrorModal
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
      />
    </>
  );
};

export default ProjectIntegrationsModal;
