import React, { useContext } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Accordion } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Tooltip from 'react-bootstrap/Tooltip';
import { BsChevronDown, BsChevronUp, BsQuestionCircle } from 'react-icons/bs';
import Styles from './DocumentHealth.module.scss';

const tooltipData = {
  Correctness: `Correctness helps you improve spellings, grammar & punctuation mistakes`,
  Vocabulary: `Expand your word bank and enhance your communication. Improve your understanding and usage of words to express ideas accurately.`,
  Readability: `Enhance the clarity and coherence of your writing. Make it easier for readers to understand and engage with your content by organizing your ideas effectively.`,
  Accessibility: `Ensure your content is accessible to all readers. Consider different abilities and needs, and make your writing inclusive and easy to comprehend for everyone.`,
  Style: `Develop your unique writing voice. Refine your tone, word choice, and sentence structure to create a distinct and engaging style that captivates your readers.`,
  Term: `Master the use of specialized terminology. Familiarize yourself with specific industry or subject-related terms to enhance precision and convey expertise in your writing.`,
  SentenceStructure: `Craft well-structured sentences. Improve the flow and coherence of your writing by organizing your ideas in clear and logical sentence structures.`,
};

const AccordionAwareToggle = ({ children, eventKey, callback }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div
      className="d-flex align-items-center gap-2 justify-content-between w-100"
      onClick={decoratedOnClick}
      style={{ cursor: 'pointer' }}
    >
      {children}
      {isCurrentEventKey ? (
        <BsChevronUp size={14} />
      ) : (
        <BsChevronDown size={14} />
      )}
    </div>
  );
};

const HealthParameter = ({ index, health, category, remarks, suggestions }) => {
  const renderTooltip = (tooltipProps) => (
    <Tooltip
      className="tooltip-white"
      id={`button-tooltip ${category}`}
      {...tooltipProps}
    >
      {tooltipData[category]}
    </Tooltip>
  );

  const getClassByRemark = (remark, type) => {
    switch (remark) {
      case 'Great':
        return Styles[`${type}__great`];
      case 'Good':
        return Styles[`${type}__good`];
      case 'Average':
        return Styles[`${type}__average`];
      case 'Poor':
        return Styles[`${type}__poor`];
      default:
        return '';
    }
  };

  // const getRemarkClass = (remark) => {
  //   switch (remark) {
  //     case 'Great':
  //       return Styles.HealthParameter__great;
  //     case 'Good':
  //       return Styles.HealthParameter__good;
  //     case 'Average':
  //       return Styles.HealthParameter__average;
  //     case 'Poor':
  //       return Styles.HealthParameter__poor;
  //     default:
  //       return '';
  //   }
  // };

  // const getSuggestionClass = (remark) => {
  //   switch (remark) {
  //     case 'Great':
  //       return Styles.Suggestions__great;
  //     case 'Good':
  //       return Styles.Suggestions__good;
  //     case 'Average':
  //       return Styles.Suggestions__average;
  //     case 'Poor':
  //       return Styles.Suggestions__poor;
  //     default:
  //       return '';
  //   }
  // };

  // const getProgressClass = (remark) => {
  //   switch (remark) {
  //     case 'Great':
  //       return Styles.progress_great;
  //     case 'Good':
  //       return Styles.progress_good;
  //     case 'Average':
  //       return Styles.progress_average;
  //     case 'Poor':
  //       return Styles.progress_poor;
  //     default:
  //       return '';
  //   }
  // };

  return (
    <section className={Styles.HealthParameter}>
      <div className="d-flex justify-content-between">
        <AccordionAwareToggle eventKey={index}>
          <p className="text-lg d-flex align-items-center gap-1">
            <span className={Styles.HealthParameter__accrodoan_heading_p}>
              {category.replace(/([A-Z])/g, ' $1')}
            </span>
            <OverlayTrigger
              placement="top"
              delay={{ show: 150, hide: 250 }}
              overlay={renderTooltip}
            >
              <section style={{ cursor: 'pointer' }}>
                {' '}
                <BsQuestionCircle size={14} color="#98A2B3" />
              </section>
            </OverlayTrigger>
          </p>
        </AccordionAwareToggle>
      </div>
      <div className={Styles.HealthParameter}>
        <ProgressBar
          bsPrefix={`progress custom-health-progressbar ${category}`}
          now={health}
          className={getClassByRemark(remarks, 'progress')}
        />
        {/* Dynamic classes*/}
        {/* for poor */}
        {/* {Styles.HealthParameter__poor} */}
        {/* for great */}
        {/* {Styles.HealthParameter__great} */}
        <div className="d-flex justify-content-between align-items-center">
          <p className={getClassByRemark(remarks, 'HealthParameter')}>
            <span>{remarks}</span>
          </p>
          <span className={Styles.HealthParameter__count}>{health}/100</span>
        </div>
      </div>
      <Accordion.Collapse eventKey={index}>
        {/* conditional classes */}
        {/* ${Styles.Suggestions__average} */}
        {/* ${Styles.Suggestions__poor} */}
        <div
          className={`${Styles.Suggestions} ${getClassByRemark(remarks, 'Suggestions')}`}
        >
          <ul>
            {suggestions?.map(
              (suggestion) =>
                suggestion?.trim() && <li key={suggestion}>{suggestion}</li>,
            )}
          </ul>
        </div>
      </Accordion.Collapse>
    </section>
  );
};

export default HealthParameter;
