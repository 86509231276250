import React from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import Styles from './DocumentView.module.scss';
import { FiArrowLeft } from 'react-icons/fi';
import useDocument from 'hooks/documents/useDocument';
import Loader from 'Components/Loader/Loader';

const DocumentView = () => {
  const { documentId } = useParams();
  const navigate = useNavigate();

  const { document, isLoading, isError } = useDocument({ documentId });

  const readingTime = () => {
    if (document?.content) {
      const text = document?.content;
      const wpm = 225;
      const words = text.trim().split(/\s+/).length;
      const time = Math.ceil(words / wpm);
      return time;
    }

    return 0;
  };

  if (isError) {
    navigate('/');
  }

  return (
    <div className={Styles.documentHeader}>
      {isLoading ? (
        <Loader inline />
      ) : (
        <>
          <div>
            <div className="d-flex">
              <div
                style={{ cursor: 'pointer', marginRight: '15px' }}
                onClick={() => navigate('/')}
              >
                <FiArrowLeft size={20} color="#344054" />
              </div>

              <div
                style={{
                  width: 'fit-content',
                  height: '30px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '20px',
                  lineHeight: '30px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '500px',
                }}
              >
                {document?.title}
              </div>
            </div>

            <div
              style={{
                width: '332px',
                height: '18px',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '18px',
                color: '#667085',
              }}
            >
              Created by: {document?.user?.name} •{' '}
              {moment(document?.created_at).format('MMM DD, YYYY')} •{' '}
              {readingTime()} minutes read
            </div>
          </div>
          <div className={Styles.content} id="content">
            <div dangerouslySetInnerHTML={{ __html: document.content }} />
          </div>
        </>
      )}
    </div>
  );
};

export default DocumentView;
