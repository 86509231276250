import { deleteRequest, postRequest } from 'helpers/axios';

const BASE_URL = '';

const register = (body) => {
  return postRequest(BASE_URL + 'signup', body).then((response) => {
    if (response?.data) {
      const payload = {
        ...response.data.user,
        token: response.headers.getAuthorization(),
      };
      if (!!payload.confirmed_at) {
        localStorage.setItem('user', JSON.stringify(payload));
      }
      return payload;
    }
  });
};

const login = (body) => {
  return postRequest(BASE_URL + 'login', body).then((response) => {
    if (response?.data) {
      const payload = {
        ...response.data.user,
        token: response.headers.getAuthorization(),
      };
      localStorage.setItem('user', JSON.stringify(payload));
      return payload;
    }
  });
};

const socialLogin = (body) => {
  const { googleAccessToken } = body || {};
  return postRequest(BASE_URL + 'auth/request', body, {
    Token: googleAccessToken,
  }).then((response) => {
    if (response?.data) {
      const payload = {
        ...response.data.user,
        token: response.headers.getAuthorization(),
      };
      localStorage.setItem('user', JSON.stringify(payload));

      return payload;
    }
  });
};

const logout = () => {
  try {
    return deleteRequest(BASE_URL + 'logout').then((response) => {
      if (response?.status === 200) {
        localStorage.removeItem('user');
      }
    });
  } catch (error) {
    localStorage.removeItem('user');
  }
};

const authService = {
  register,
  login,
  logout,
  socialLogin,
};

export default authService;
