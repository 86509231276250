import React from 'react';

import Styles from './EmptyState.module.scss';
import BSButton from 'Components/Button/BSButton';
import { FiPlus } from 'react-icons/fi';

const EmptyState = ({ emptyText, ctaText, buttonText, onClick, children }) => {
  return (
    <section className={Styles.EmptyStateContainer}>
      {children}
      <div className={Styles.EmptyState}>
        <p>{emptyText}</p>
        <p>{ctaText}</p>
      </div>
      <BSButton
        classList="cancel_btn"
        ButtonText={buttonText}
        variant="outline-secondary"
        onClick={onClick}
        icon={<FiPlus size={18} />}
      />
    </section>
  );
};

export default EmptyState;
