import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { setLoader } from 'redux/slices/loader';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import { confirmEmail } from 'services/userService';

function EmailVerification() {
  const { token } = useParams() || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const confirmEmailFn = async () => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Verifying your email',
          description: 'Please wait...',
        }),
      );

      const res = await confirmEmail(token);

      dispatch(setLoader({ isLoading: false }));
      renderSuccess(res?.data?.message);
      navigate('/');
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
      renderError(error);
      navigate('/');
    }
  };

  useEffect(() => {
    confirmEmailFn();
  }, [token]);

  return <></>;
}

export default EmailVerification;
