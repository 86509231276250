import React, { useCallback, useEffect, useState } from 'react';
import IntegrationCard from './IntegrationCard';
import IntegrationModal from 'Containers/Modal/IntegrationModal/IntegrationModal';
import ImageView from 'Components/Image/ImageView';
import { getProjectIntegrations } from 'services/projectService';
import {
  error as errorToast,
  success as successToast,
} from 'helpers/toaster.js';
import { Col, Row } from 'react-bootstrap';
import {
  getAvailableIntegrations,
  postNewIntegration,
  updateIntegration,
  deleteIntegration,
  patchIntegration,
  getUserIntegrations,
} from 'services/integrationService';
import FloatingButton from 'ui/FloatingButton/FloatingButton';
import EmptyState from 'Components/EmptyState/EmptyState';
import Styles from './Publish.module.scss';
import wordpress from '../../assets/publish/wordpress.svg';
import Dotsverticle from '../../assets/publish/Icondots.svg';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';

const Integrations = ({ projectId }) => {
  const [integrationModalView, setIntegrationModalView] = useState(false);
  const [availableIntegrations, setAvailableIntegrations] = useState(null);
  const [integrations, setIntegrations] = useState(null);
  const [updatedIntegration, setUpdatedIntegration] = useState(null);

  const { user } = useSelector((state) => state.auth);
  const { subscriptions, user_brands } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  useEffect(() => {
    fetchAvailableIntegrations();
    fetchUserIntegrations();
  }, []);

  const fetchAvailableIntegrations = useCallback(async () => {
    try {
      const integrations = await getAvailableIntegrations();

      setAvailableIntegrations(integrations?.data);
    } catch (error) {
      errorToast(error);
    }
  }, []);

  const fetchUserIntegrations = useCallback(async () => {
    try {
      const { data } = await getUserIntegrations(projectId);

      setIntegrations(data?.platform_integrations);
    } catch (error) {
      errorToast(error);
    }
  }, []);

  const handleAddIntegration = async (type, payload) => {
    try {
      await postNewIntegration(type, payload);
      fetchUserIntegrations();
      successToast('Integration successfully added!');
      setIntegrationModalView(false);
    } catch (error) {
      errorToast(error);
    }
  };

  const handleActive = async (integrationToChange) => {
    try {
      await patchIntegration(
        integrationToChange.type,
        integrationToChange.id,
        'active',
        {
          is_active: !integrationToChange.is_active,
        },
      );
      successToast(
        `Integration ${integrationToChange.is_active ? 'Inactive' : 'Active'}!`,
      );
      fetchUserIntegrations();
    } catch (error) {
      errorToast(error);
    }
  };

  const handleUpdate = (integrationToUpdate) => {
    setUpdatedIntegration(integrationToUpdate);
    setIntegrationModalView(true);
  };

  const handleUpdateIntegration = async (type, id, payload) => {
    try {
      await updateIntegration(type, id, payload);
      successToast('Integration successfully updated!');
      fetchUserIntegrations();
      setUpdatedIntegration(null);
      setIntegrationModalView(false);
    } catch (error) {
      errorToast(error);
    }
  };

  const handleDelete = async (integrationToDelete) => {
    try {
      await deleteIntegration(integrationToDelete.type, integrationToDelete.id);
      successToast('Integration successfully deleted!');
      fetchUserIntegrations();
    } catch (error) {
      errorToast(error);
    }
  };

  return (
    <section className="d-flex flex-column">
      {integrations?.length <= 0 ? (
        <EmptyState
          emptyText="You still haven't configured any CMS."
          ctaText="Add your first CMS"
          buttonText="Add CMS"
          onClick={
            currentSubscription?.plan_name === 'Basic Plan' &&
            projectId !== null
              ? () => setShowUpgradeModal(true)
              : () => setIntegrationModalView(true)
          }
        >
          <ImageView src="/Images/channels.svg" />
        </EmptyState>
      ) : (
        <>
          <Row className={'ps-3 my-4 ' + Styles.heading_channel}>
            Connected Channels
          </Row>
          <div  className={"g-3 " + Styles.integrationGrid}>
            {integrations?.map((integration) => (
              <Col key={integration.name}>
                <IntegrationCard
                  integration={integration}
                  handleActive={handleActive}
                  handleUpdate={currentSubscription?.plan_name === 'Basic Plan'? () => setShowUpgradeModal(true) : handleUpdate}
                  handleDelete={handleDelete}
                />
             
               </Col>
            ))}
          </div>
        </>
      )}

      <FloatingButton
        onClick={
          currentSubscription?.plan_name === 'Basic Plan' && projectId !== null
            ? () => setShowUpgradeModal(true)
            : () => setIntegrationModalView(true)
        }
      />

      {integrationModalView && (
        <IntegrationModal
          projectId={projectId}
          availableIntegrations={availableIntegrations}
          handleAddSubmit={handleAddIntegration}
          handleUpdateSubmit={handleUpdateIntegration}
          updatedIntegration={updatedIntegration}
          show={integrationModalView}
          onHide={() => {
            setUpdatedIntegration(null);
            setIntegrationModalView(false);
          }}
        />
      )}
      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
        />
      )}
    </section>
  );
};

export default Integrations;
