import React from 'react';
import {
  nextStep,
  prevStep,
  updateStep2,
  updateStep3,
} from 'redux/slices/newOnboarding';
import { useDispatch, useSelector } from 'react-redux';
import ButtonSection from '../ButtonSection/ButtonSection';
import Styles from './Styles.module.scss';
import FormikTextField from 'Components/Form/FormikTextField';
import { Form } from 'react-bootstrap';
import { ErrorMessage, Formik } from 'formik';
import Dropdown from 'Components/Dropdown/Dropdown';
import { INDUSTRY, TONES } from 'constants/contentTypes';
import * as Yup from 'yup';
import onBoardingService from 'services/onBoardingService';
import { error as errorToast, success as successToast } from 'helpers/toaster';
import TagInput from 'Components/Input/TagsInput/TagInput';

const Step2 = () => {
  const dispatch = useDispatch();
  const onboardingData = useSelector((state) => state.newOnboarding.step2);
  const onboardingData1 = useSelector((state) => state.newOnboarding.step1);

  const validationSchema = Yup.object().shape({
    industry: Yup.array()
      .of(Yup.string().required())
      .min(1, 'Industry is required'),
    tone: Yup.array().of(Yup.string().required()).min(1, 'Tone is required'),
    core_messaging: Yup.string().trim().required('Core Messaging is required'),
    target_audience: Yup.array()
      .of(Yup.string().required())
      .min(1, 'Target Audience is required'),
    brand_description: Yup.string().trim().required('Brand Description is required'),
    value_proposition: Yup.string().trim().required('Value Proposition is required'),
    keywords_and_themes: Yup.array()
      .of(Yup.string().required())
      .min(1, 'Keyword is required'),
    brand_name: Yup.string().trim().required('Brand Name is required'),
  });

  const initialValues = {
    brand_name: onboardingData.brand_name || '',
    industry: onboardingData.industry || [],
    tone: onboardingData.tone || [],
    core_messaging: onboardingData.core_messaging || '',
    target_audience: onboardingData.target_audience || [],
    brand_description: onboardingData.brand_description || '',
    value_proposition: onboardingData.value_proposition || '',
    keywords_and_themes: onboardingData.keywords_and_themes || [],
  };

  const handleNext = async (values) => {
    try {
      const { logo, ...userEnteredValues } = values;
      const body = { user_entered_summary: userEnteredValues };
      const response = await onBoardingService.getContentTopics(body);
      if (response?.data?.success) {
        dispatch(updateStep2(values));
        dispatch(
          updateStep3({ topics: response?.data?.content_topics }),
        );
        dispatch(nextStep());
      } else {
        errorToast('An error occurred 1: ' + response.message); // Adjust this according to the actual response structure
      }
    } catch (error) {
      console.error(error);
      errorToast('An error occurred 2: ' + error.message);
    }
  };

  const handleBack = (values) => {
    dispatch(updateStep2(values));
    dispatch(prevStep());
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={handleNext}
      >
        {({ values, setFieldValue, isValid, dirty, submitForm }) => {
          const handleClick = () => {
            submitForm();
          };
          return (
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className={Styles.form2_wrapper}
              >
                {/* <div>
                  <Dropdown
                    name="industry"
                    label="Industry"
                    isMulti={true}
                    options={INDUSTRY}
                    isRequired
                    placeholder="Select an Option"
                    value={values.industry.map((val) =>
                      INDUSTRY.find((option) => option.value === val),
                    )}
                    onChange={(selectedOptions) =>
                      setFieldValue(
                        'industry',
                        selectedOptions.map((option) => option.value),
                      )
                    }
                  />
                </div> */}
                 <div className="w-100">
                  <FormikTextField
                    id="brand_name"
                    name="brand_name"
                    type="text"
                    placeholder="Enter brand name..."
                    Label="Brand Name"
                    classList="input form-control w-100"
                    isRequired
                  />
                  <ErrorMessage
                    name="brand_name"
                    component="div"
                    className="error mt-2"
                  />
                </div>
                <div className="w-100">
                  <FormikTextField
                    id="brand_description"
                    name="brand_description"
                    type="text"
                    placeholder="Enter brand description..."
                    Label="Brand Description"
                    classList="input form-control text-area-height w-100"
                    isRequired
                    isTextArea
                  />
                  <ErrorMessage
                    name="brand_description"
                    component="div"
                    className="error mt-2"
                  />
                </div>
                <div className="w-100">
                  <TagInput
                    label="Audience"
                    tags={values?.target_audience}
                    onChange={(value) => setFieldValue('target_audience', value)}
                    placeholder="Define target audience..."
                    isRequired
                  />
                  <ErrorMessage
                    name="target_audience"
                    component="div"
                    className="error mt-2"
                  />
                </div>
                <div>
                  <Dropdown
                    name="tone"
                    label="Tone"
                    isMulti={true}
                    options={TONES}
                    isRequired
                    placeholder="Select an Option"
                    value={values.tone.map((val) =>
                      TONES.find((option) => option.value === val),
                    )}
                    onChange={(selectedOptions) =>
                      setFieldValue(
                        'tone',
                        selectedOptions.map((option) => option.value),
                      )
                    }
                  />
                </div>
              <div className="w-100">
                  <FormikTextField
                    id="core_messaging"
                    name="core_messaging"
                    type="text"
                    placeholder="Enter core messaging..."
                    Label="Core Messaging"
                    classList="input form-control text-area-height w-100"
                    isRequired
                    isTextArea
                  />
                  <ErrorMessage
                    name="core_messaging"
                    component="div"
                    className="error mt-2"
                  />
                  {/* <ErrorMessage name="core_values">
                    {(msg) => <span className="error">{msg}</span>}
                  </ErrorMessage> */}
                </div>
                <div className="w-100">
                  <TagInput
                    label="Industry"
                    tags={values.industry}
                    onChange={(value) => setFieldValue('industry', value)}
                    placeholder="Enter Industry..."
                    isRequired
                  />
                  <ErrorMessage
                    name="industry"
                    component="div"
                    className="error mt-2"
                  />
                </div>
                <div
                 className="w-100"
                >
                  <FormikTextField
                    id="value_proposition"
                    name="value_proposition"
                    type="text"
                    placeholder="Enter value proposition..."
                    Label="Value Proposition"
                    classList="input form-control text-area-height w-100"
                    isRequired
                    isTextArea
                  />
                  <ErrorMessage
                    name="value_proposition"
                    component="div"
                    className="error mt-2"
                  />
                </div>
                <div className="w-100">
                  <TagInput
                    label="Keywords & Themes"
                    tags={values.keywords_and_themes}
                    onChange={(value) => setFieldValue('keywords_and_themes', value)}
                    placeholder="Enter keywords and themes..."
                    isRequired
                  />
                  <ErrorMessage
                    name="keywords_and_themes"
                    component="div"
                    className="error mt-2"
                  />
                </div>
                <div className={Styles.button_margin_top}>
                  <ButtonSection
                    btn_1="Save and Continue"
                    btn_2="Back to previous step"
                    isValid={true}
                    handleNext={handleClick}
                    handleBack={() => handleBack(values)}
                  />
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default Step2;
