const Tag = ({
  tag,
  setTags,
  tags,
  multiTags = true,
  maxTags = Infinity,
  iconLeft,
  Tagclasses,
  callClickHandler=false,
  onClick = null
}) => {
  const { title, id, selected } = tag || {};
  const currentSelectedCount = tags.filter((t) => t.selected).length;

  const setIsActiveClass = () => {
    // If current tag is not selected and we reached the max, do nothing
    if (!selected && currentSelectedCount >= maxTags) {
      return;
    }
    const transformedTags = tags.map((tag) => {
      if (tag.id === id) {
        return {
          ...tag,
          selected: !tag.selected,
        };
      }

      return multiTags
        ? tag
        : {
            ...tag,
            selected: false,
          };
    });

    setTags(transformedTags);

    if (callClickHandler) {
      onClick(transformedTags);
    }
  };
  return (
    <span
      onClick={setIsActiveClass}
      className={`tag text-md-Regular ${selected ? 'active' : ''} ${Tagclasses && Tagclasses}`}
    >
      {iconLeft && <>{iconLeft}</>}
      {title}
    </span>
  );
};

export default Tag;
