import { useQuery } from '@tanstack/react-query';

import keys from 'constants/queryKeys';
import { getTemplate } from 'services/templateService';

const useTemplate = (templateId) => {
  const query = useQuery({
    queryKey: keys.Template(templateId),
    queryFn: () => getTemplate(templateId),
    staleTime: Infinity,
  });

  return {
    ...query,
    ...query?.data?.data,
  };
};

export default useTemplate;
