import React, { useMemo } from 'react';
import BSButton from 'Components/Button/BSButton';
import FormikTextField from 'Components/Form/FormikTextField';
import { ErrorMessage, Formik } from 'formik';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setLoader } from 'redux/slices/loader';
import * as Yup from 'yup';
import { FiLayers } from 'react-icons/fi';
import { createFolder } from 'services/folderService';
import useProjects from 'hooks/projects/useProjects';
import Dropdown from 'Components/Dropdown/Dropdown';
import Drawer from 'Components/Drawer/Drawer';

const NewFolderModal = ({ show, onHide }) => {
  const { projectId } = useParams();
  const { data: projectPages } = useProjects({
    searchTerm: '',
    enabled: !projectId,
  });

  const projects = useMemo(() => {
    return projectPages?.pages.flatMap((page) => page?.data?.projects);
  }, [projectPages?.pages]);

  const dispatch = useDispatch();

  const initialValues = {
    title: '',
    project_id: projectId || '',
  };

  const validationSchema = Yup.object({
    title: Yup.string().trim().required('Name is required'),
    project_id: Yup.string().trim(),
  });

  const projectOptions = useMemo(() => {
    return (
      projects?.map((project) => {
        return {
          value: project.id,
          label: (
            <div className={'d-flex align-items-center gap-3'}>
              <FiLayers size={18} color="#101828" />
              <p className="w-100">{project.name}</p>
            </div>
          ),
        };
      }) || []
    );
  }, [projects]);

  const handleSubmit = async ({ title, project_id }) => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Creating!',
          description: 'Please wait...',
        }),
      );

      const res = await createFolder(projectId || project_id, title);

      renderSuccess(res?.data?.message);
      dispatch(setLoader({ isLoading: false }));
      onHide();
    } catch (error) {
      renderError(error);
      dispatch(setLoader({ isLoading: false }));
    }
  };

  return (
    <Drawer
      show={show}
      onHide={onHide}
      placement="end"
      width={420}
      title={'New Folder'}
    >
      <div className="px-4">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          enableReinitialize={true}
        >
          {({ values, isValid, setFieldValue, setFieldTouched }) => {
            return (
              <Form
                className="d-flex flex-column gap-2"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                {!projectId && (
                  <Dropdown
                    name="project_id"
                    label="Select campaign"
                    placeholder="Select campaign"
                    options={projectOptions}
                    onChange={(option) => {
                      setFieldValue('project_id', option?.value);
                      setFieldTouched('project_id', true);
                    }}
                    isClearable
                  />
                )}
                <FormikTextField
                  name="title"
                  type="text"
                  placeholder="Type something..."
                  Label="Folder name"
                  classList="input"
                />
                <ErrorMessage className="error" name="title" component="div" />

                <div
                  className={`d-flex drawer-footer justify-content-end gap-2`}
                >
                  <BSButton
                    onClick={onHide}
                    ButtonText="Cancel"
                    variant="light"
                    classList={'btn button cancel_btn'}
                  />
                  <BSButton
                    ButtonText="Create"
                    classList="w-100 secondary_btn"
                    variant="dark"
                    disabled={!isValid}
                    type="submit"
                    onClick={() => handleSubmit(values)}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Drawer>
  );
};

export default NewFolderModal;
