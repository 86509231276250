import { useQuery } from '@tanstack/react-query';
import { getDocumentProjectCollaborators } from 'services/documentService';
import keys from 'constants/queryKeys';

const useDocumentProjectCollaborators = ({ documentId }) => {
  const query = useQuery({
    queryKey: keys.DocumentProjectCollaborators(documentId),
    queryFn: () => getDocumentProjectCollaborators(documentId ),
  });

  return {
    ...query,
    ...query?.data?.data,
  };
};

export default useDocumentProjectCollaborators;
