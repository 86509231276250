import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Styles from './BottomDrawer.module.scss';

const BottomDrawer = ({ show, onHide, placement, title, width, children }) => {
  return (
    <Offcanvas
      show={show}
      onHide={onHide}
      placement={placement}
      className={Styles.Drawer}
      backdropClassName={Styles.Backdrop}
      style={{
        width: `${width}%`,
        borderRadius: '20px',
        height: '90%',
      }}
    >
      {/* <Offcanvas.Header className={Styles.Drawer_Header} closeButton>
        <Offcanvas.Title className={Styles.Drawer_Header_Title}>
          {title}
        </Offcanvas.Title>
      </Offcanvas.Header> */}
      <Offcanvas.Body className={Styles.Drawer_Body}>{children}</Offcanvas.Body>
    </Offcanvas>
  );
};

export default BottomDrawer;
