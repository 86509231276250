import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProjectsKey } from 'constants/queryKeys';
import { updateProject } from 'services/projectService';
import { error as renderError } from 'helpers/toaster';

const useUpdateProject = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ProjectsKey] });
    },
    onError: (error) => {
      renderError(error);
    }
  })
};

export default useUpdateProject;
