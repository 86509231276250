import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { login, register, socialLogin } from 'redux/slices/auth';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { error as renderError } from 'helpers/toaster.js';
import FormikTextField from 'Components/Form/FormikTextField';
import PasswordValidationError from 'Components/Password/Error';
import BSButton from '../../../Components/Button/BSButton';
import ImageView from '../../../Components/Image/ImageView';
import Link from '../../../Components/Input/Link/Link';
import { ROUTES } from '../../../constants/routes';
import {
  EMAIL_REGEX,
  FORGOT_PASSWORD,
  PASSWORD_FORMAT,
  SIGNIN_BUTTON_TEXT,
  SIGNIN_GOOGLE_TEXT,
  SIGNIN_MODAL_HEADING,
  SIGNIN_MODAL_SPAN_TEXT,
  SIGNIN_TEXT,
  SIGNUP_BUTTON_TEXT,
  SIGNUP_GOOGLE_TEXT,
  SIGNUP_MODAL_HEADING,
  SIGNUP_MODAL_SPAN_TEXT,
  SIGNUP_TEXT,
} from '../../../constants';
import styles from './SignUpModal.module.scss';
import { useNavigate } from 'react-router-dom';

const SignUpModal = ({
  show,
  handleClose,
  forgotPasswordHandleShow,
  modalType = 'SIGN-UP',
}) => {
  const navigate = useNavigate();
  const [signType, setSignType] = useState(() => modalType === 'SIGN-IN');

  const dispatch = useDispatch();

  const initialValues = signType
    ? {
        email: '',
        password: '',
      }
    : {
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        confirmPassword: '',
      };

  const validationSchema = signType
    ? Yup.object({
        email: Yup.string()
          .matches(EMAIL_REGEX, 'Invalid email')
          .required('Email is required.'),
        password: Yup.string().required('Password is required.'),
      })
    : Yup.object({
        email: Yup.string()
          .matches(EMAIL_REGEX, 'Invalid email')
          .required('Email is required.'),
        password: Yup.string()
          .required('Password is required.')
          .min(8, 'Password must be at least 8 characters.')
          .max(18, 'Password must be at most 18 characters.'),
        firstName: Yup.string().required('First name is required.'),
        lastName: Yup.string().required('Last name is required.'),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match.')
          .matches(PASSWORD_FORMAT, {
            message: PasswordValidationError(),
          })
          .required('Confirm password is required'),
      });

  const handleSubmit = async (values) => {
    const { email, password, confirmPassword, firstName, lastName } =
      values || {};
    const body = signType
      ? { user: { email, password } }
      : {
          user: {
            email,
            first_name: firstName,
            last_name: lastName,
            password,
            password_confirmation: confirmPassword,
          },
        };

    // How can I clear query params here?

    const response = await dispatch(signType ? login(body) : register(body));
    const { payload = {} } = response;
    if (payload.confirmation_required) {
      handleClose();
    } else if (payload.user?.sign_in_count < 2) {
      navigate(ROUTES.OnboardingInfo);
    }
  };

  const handleGoogleLoginSuccess = async (response) => {
    const responseData = await dispatch(
      socialLogin({ googleAccessToken: response.access_token }, signType),
    );
    const { payload = {} } = responseData || {};
    if (payload.user?.sign_in_count < 2) {
      navigate(ROUTES.OnboardingInfo);
    }
  };

  const handleGoogleLoginFailure = () => {
    renderError('Login failed, please contact with support!');
  };

  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginFailure,
  });

  const GOOGLE_ICON = (
    <ImageView
      src="/Images/GoogleIcon.svg"
      alt="Google Logo"
      class="img-fluid"
    />
  );

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="fixwidthModal_476px"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop
      >
        <div>
          <Modal.Header className={styles.AuthModalHeader} closeButton />
          <Modal.Body className={styles.ModalBody}>
            <div className={styles.modalBodyWrapper}>
              <div
                className={
                  'd-flex flex-column align-items-center ' +
                  styles.headingSection
                }
              >
                <ImageView
                  src="/Images/vocable__logo_svg.svg"
                  alt="Vocable_logo"
                  class="img-fluid logo-img m-0"
                />
                <p className={styles.modalHeadingText}>
                  {signType ? SIGNIN_MODAL_HEADING : SIGNUP_MODAL_HEADING}
                </p>
              </div>
              <div className={styles.ModalInnerWrapper}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  validateOnMount={true}
                  enableReinitialize={true}
                >
                  {({ values, isValid }) => {
                    return (
                      <form
                        className={
                          'd-flex flex-column  ' + styles.ModalInputSection
                        }
                        onSubmit={(e) => e.preventDefault()}
                      >
                        {signType ? (
                          <>
                            <FormikTextField
                              name="email"
                              type="email"
                              placeholder="Enter your email"
                              classList="input input__Email"
                            />
                            <ErrorMessage name="email">
                              {(msg) => <div className="error">{msg}</div>}
                            </ErrorMessage>
                            <FormikTextField
                              name="password"
                              type="password"
                              placeholder="&#183;&#183;&#183;&#183;&#183;&#183;&#183;"
                              classList="input input__password"
                            />
                            <ErrorMessage name="password">
                              {(msg) => <div className="error">{msg}</div>}
                            </ErrorMessage>
                            <>
                              <div
                                className={
                                  styles.linkSection +
                                  ' d-flex justify-content-start'
                                }
                              >
                                {signType && (
                                  <Link
                                    ClassList="f-s14"
                                    onClick={() => {
                                      forgotPasswordHandleShow();
                                    }}
                                    linkText={FORGOT_PASSWORD}
                                  />
                                )}
                              </div>
                            </>
                          </>
                        ) : (
                          <>
                            <FormikTextField
                              name="email"
                              type="email"
                              placeholder="Enter your email"
                              classList="input input__Email"
                            />
                            <ErrorMessage name="email">
                              {(msg) => <div className="error">{msg}</div>}
                            </ErrorMessage>
                            <FormikTextField
                              name="firstName"
                              type="text"
                              placeholder="Enter your first name"
                              classList="input input__Email"
                            />
                            <ErrorMessage name="firstName">
                              {(msg) => <div className="error">{msg}</div>}
                            </ErrorMessage>
                            <FormikTextField
                              name="lastName"
                              type="text"
                              placeholder="Enter your last name"
                              classList="input input__Email"
                            />
                            <ErrorMessage name="lastName">
                              {(msg) => <div className="error">{msg}</div>}
                            </ErrorMessage>
                            <FormikTextField
                              name="password"
                              type="password"
                              placeholder="Enter your password"
                              classList="input input__password"
                            />
                            <ErrorMessage name="password">
                              {(msg) => <div className="error">{msg}</div>}
                            </ErrorMessage>
                            <FormikTextField
                              name="confirmPassword"
                              type="password"
                              placeholder="Confirm password"
                              classList="input input__password"
                            />
                            <ErrorMessage name="confirmPassword">
                              {(msg) => <div className="error">{msg}</div>}
                            </ErrorMessage>
                          </>
                        )}
                        <BSButton
                          variant="dark"
                          classList="w-100"
                          ButtonText={
                            signType ? SIGNIN_BUTTON_TEXT : SIGNUP_BUTTON_TEXT
                          }
                          disabled={!isValid}
                          onClick={() => handleSubmit(values)}
                        />
                        <div className={styles.dividerSection}>
                          <hr className={styles.dividerSection__line} />
                          OR
                          <hr className={styles.dividerSection__line} />
                        </div>
                        <BSButton
                          variant="light"
                          type="button"
                          classList="w-100 btn btn__defaultStyles"
                          ButtonText={
                            signType ? SIGNIN_GOOGLE_TEXT : SIGNUP_GOOGLE_TEXT
                          }
                          icon={GOOGLE_ICON}
                          onClick={() => googleLogin()}
                        />
                      </form>
                    );
                  }}
                </Formik>
                <div
                  className={
                    styles.linkSection + ' d-flex justify-content-center'
                  }
                >
                  <Link
                    ClassList="f-s14"
                    onClick={() => setSignType((prevSign) => !prevSign)}
                    span={
                      signType ? SIGNIN_MODAL_SPAN_TEXT : SIGNUP_MODAL_SPAN_TEXT
                    }
                    linkText={signType ? SIGNUP_TEXT : SIGNIN_TEXT}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default SignUpModal;
