import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import Styles from './AIProjectPlan.module.scss';
import ProjectPlanCard from './ProjectPlanCard';
import {
  generateBriefs,
  generateHeadlines,
  saveBriefs,
} from 'services/projectService';
import { error as errorToast, success as successToast } from 'helpers/toaster';
import { sumDaysToDate } from 'helpers/dates';
import { ROUTES } from 'constants/routes';
import AIPlanLayout from './AIPlanLayout';
import Spinner from '../../Spinner/Spinner';
import BriefDrawer from 'Components/Drawers/BriefDrawer/BriefDrawer';
import ConfirmDeleteModal from 'Containers/Modal/ConfirmDeleteModal/ConfirmDeleteModal';
import { PROJECT_TABS } from 'constants/projects';
import { resetOnboarding } from 'redux/slices/newOnboarding';
import { useDispatch, useSelector} from 'react-redux';
import { success } from 'helpers/toaster';

const sanitizeBrief = ({ brief, projectId }) => {
  return {
    id: uuid(),
    title: brief['Topic/Headline'],
    description: brief.Description,
    deadline_date: sumDaysToDate(brief.Deadline),
    priority: 'medium',
    state: 'not_started',
    content_type: brief['Content Type'],
    keywords: brief['Keywords to Use'],
    project_id: projectId,
    content_structure: brief['Content Structure'],
    word_count: brief['Word Count'],
  };
};

const AIProjectPlan = ({ ...props }) => {

  // console.log(props,"props")
  const {
    headlines: contentTopics,
    subscriptionPlansDrawer,
    onboardingFooter,
  } = props ?? {};
  const { projectId } = useParams();
  const [headlines, setHeadlines] = useState(props.headlines || []);
  const [briefs, setBriefs] = useState([]);
  const [loadingBriefs, setLoadingBriefs] = useState([]);
  const [editBrief, setEditBrief] = useState();
  const [deleteTaskModalView, setDeleteTaskModalView] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const campaignCards  = location?.state?.campaignCards;
  const customAmount  = location?.state?.customAmount;
  const contentAmount  = location?.state?.amount;


  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const isManual = query?.get('isManual') || 'true';

  const url = useSelector((state) => state?.newOnboarding?.step1?.url);


  const setLoadingToFalse = (index) => {
    setLoadingBriefs((prev) => {
      const loadingArray = [...prev];
      loadingArray[index] = false;
      return loadingArray;
    });
  };
  const fetchBriefs = () => {
    if (!headlines.length) {
      return;
    }
    headlines.forEach((headline, index) => {
      setLoadingBriefs((prev) => {
        prev.push(true);
        return prev;
      });
      const generateBriefsParams = {
        project_id: projectId,
        headlines: [headline],
        business_id: props?.brand,
      };
  
      if (props?.flow === 'onboarding' || props?.flow === 'newPlan') {
        generateBriefsParams.url = url;
      }

      // generateBriefs({
      //   project_id: projectId,
      //   headlines: [headline],
      // })
      generateBriefs(generateBriefsParams)
        .then((res) => {
          if (!res) {
            setLoadingToFalse(index);
            return;
          }
          const newBrief = sanitizeBrief({
            brief: res.data[0],
            projectId,
          });
          setBriefs((prevBriefs) => {
            return [...prevBriefs, newBrief];
          });
          setLoadingToFalse(index);
        })
        .catch((e) => {
          setLoadingToFalse(index);
          console.error(e);
        });
    });
  };

  const fetchHeadlines = async () => {
    try {
      setLoadingBriefs([]);
      const generateHeadlinesParams = {
        project_id: projectId,
        amount: customAmount ? contentAmount : props.amount || 16,
        previously_generated: briefs.map(({ title, content_type }) => ({
          'Topic/Headline': title,
          'Content Type': content_type,
        })),
        // start_date: props?.headlines?.start_date ?? '2024-01-01',
        // end_date: props?.headlines?.end_date  ?? '2024-12-31',
        start_date: props?.headlines?.start_date,
        end_date: props?.headlines?.end_date,
        content_types: props?.headlines?.content_types,
        business_id: props?.brand,
        topics: props?.topics,
        goals: props?.goals,
      };
  
      if (props?.flow === 'onboarding' || props?.flow === 'newPlan') {
        generateHeadlinesParams.url = url;
      }
      const resGenerateHeadlines = await generateHeadlines(generateHeadlinesParams);
      setHeadlines(resGenerateHeadlines?.data);
    } catch (e) {
      setLoadingBriefs([false]);
      errorToast(e);
    }
  };
// after complete onboard new plan it will clear redux after redirection
  const clearOnboarding = () => {
    // success('Plan Expanded!');
    setTimeout(() => {
      dispatch(resetOnboarding());
    }, 5000); // 5 seconds
  };

  const handleAcceptPlanWithCallback = () => {
    if (props.onHide) {
      props.onHide();
    }
    // const callback = props.flow === "newPlan" ? clearOnboarding : subscriptionPlansDrawer;
    const callback =
      props.flow === 'newPlan'
        ? clearOnboarding
        : props.flow === 'onboarding'
          ? subscriptionPlansDrawer
          : props.flow === 'expandPlan'
            ? props.callback
            : null;
    onAcceptPlan(callback);
  };

  useEffect(() => {
    fetchHeadlines();
  }, []);

  useEffect(() => {
    fetchBriefs();
  }, [headlines]);

  const navigateToPlan = () => {
    navigate(
      projectId
        ? ROUTES.ProjectSlug(projectId, { tab: PROJECT_TABS.plan })
        : ROUTES.PLANNER,
    );
  };

  const onAcceptPlan = async (callback) => {
    try {
      let response = await saveBriefs({
        project_id: projectId,
        tasks: briefs.map((brief) => ({
          ...brief,
          assignee_id: brief.assignee?.id,
        })),
      });
    // props.flow !== 'onboarding' && navigateToPlan();
      // new code
      if (response.status === 200) {
        props.flow !== 'onboarding' && navigateToPlan();
        (props.flow === 'expandPlan' || props.flow === 'newPlan') &&
          response.status === 200 &&
          success('Plan Expanded!');

        if (callback) {
          callback();
        }
      } else {
        errorToast('An error occured while expanding plan.');
      }
      //new code
      // if (callback) {
      //   callback();
      // }
    } catch (e) {
      errorToast(e);
    }
  };

  const onDeleteHandler = () => {
    setDeleteTaskModalView(true);
  };

  const onConfirmDeleteHandler = async () => {
    setBriefs((prevBriefs) => {
      return prevBriefs.filter((brief) => brief.id !== editBrief?.id);
    });
    setEditBrief();
  };

  const isLoading = loadingBriefs.length === 0 || loadingBriefs.some((v) => v);

  return (
    <>
      <AIPlanLayout
        loading={isLoading}
        onBoostPlan={fetchHeadlines}
        // onBoostPlan={props.flow === "expandPlan" ? props.boostPlan(fetchHeadlines) : fetchHeadlines}
        onAcceptPlan={handleAcceptPlanWithCallback}
        onCancel={navigateToPlan}
        isManual={isManual}
        header={(props.flow == 'onboarding' || props.flow == 'newPlan' || props.flow == "expandPlan") && 'Happy with your initial plan?'}
        description={
          (props.flow == 'onboarding' || props.flow == 'newPlan' || props.flow == "expandPlan")  &&
          'You can boost your plan with more briefs now, or choose to add individual briefs later.'
        }
        buttonText={(props.flow == 'onboarding' || props.flow == 'newPlan' || props.flow == "expandPlan")  && 'Add more'}
        // showButton={props.flow !== 'onboarding'}
        showButton={props.flow !== 'onboarding' && props.flow !== 'newPlan' && props.flow !== "expandPlan"}
        projectId={projectId}

      >
        <div className={Styles.cardsTable}>
          {briefs.map((brief, index) => {
            return (
              <ProjectPlanCard
                key={index}
                brief={brief}
                onClick={() => setEditBrief(brief)}
                onboardingFooter={onboardingFooter || campaignCards}
              />
            );
          })}
          {isLoading && <Spinner text="Generating brief" className="mh-100" />}
        </div>
      </AIPlanLayout>

      {!!editBrief && (
        <BriefDrawer
          onHide={() => setEditBrief()}
          onSubmit={(action, values) => {
            setBriefs((prevBriefs) => {
              const indexOfBriefToReplace = prevBriefs.findIndex(
                (brief) => brief.id === editBrief?.id,
              );
              prevBriefs[indexOfBriefToReplace] = { ...editBrief, ...values };
              successToast("Brief Updated!")
              return prevBriefs;
            });
            setEditBrief();
          }}
          action="edit"
          task={editBrief}
          show={!!editBrief}
          onDeleteHandler={onDeleteHandler}
          disableCreateDraft
        />
       )}

      {deleteTaskModalView && (
        <ConfirmDeleteModal
          show={deleteTaskModalView}
          onHide={() => {
            setDeleteTaskModalView(false);
          }}
          onDelete={onConfirmDeleteHandler}
        />
      )}
    </>
  );
};

export default AIProjectPlan;
