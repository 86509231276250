import React from 'react';
import SubscriptionCardContainer from './SubscriptionCardContainer';
import { GetAccessCard } from './GetAccessCard';
import { FooterSection } from './FooterSection';
import Footer from 'Components/LandingPage/Footer/Footer';
import Styles from './NewUserSubscriptionPlan.module.scss';
import ContactUs from './ContactUs';
export const NewUserSubscriptionPlan = () => {
  return (
    <div className="p-4">
      <SubscriptionCardContainer />

      {/* <GetAccessCard /> */}
      {/* <FooterSection /> */}
      <ContactUs />
      <div className={Styles.footer_container}>
        <Footer />
      </div>
    </div>
  );
};
