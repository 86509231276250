import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setLoader } from 'redux/slices/loader';
import { actions } from 'redux/slices/auth';
import { getPaymentMethods, getPlans } from 'services/subscriptionService';
import { sortArrayCustomOrder } from 'helpers';

const useFetchPlans = () => {
  const dispatch = useDispatch();
  const fetchPlans = useCallback(
    async ({ showLoader = true, ...setters }) => {
      const {
        setPaymentMethods,
        setSubscriptionPlans,
        setCurrentSubscription,
        setSelectedPlanType,
        setLastSubscription,
        setShouldRedirect,
      } = setters;

      try {
        dispatch(
          setLoader({
            isLoading: showLoader,
            heading: 'Loading Plans',
            description: 'Please wait...',
          }),
        );

        const [plansRes, paymentMethodsRes] = await Promise.all([
          getPlans(),
          getPaymentMethods(),
        ]);

        const { data } = plansRes || {};
        const { products, subscriptions } = data || {};
        const { stripe_products: stripeProducts } = products || {};
        const {
          current_subscription: currentSubscription,
          last_subscription: lastSubscription,
        } = subscriptions || {};

        const transformedStripeProducts = stripeProducts.reduce(
          (acc, stripeProduct) => {
            const {
              stripe_prices: stripePrices,
              name,
              description,
              additional_info: additionalInfo,
            } = stripeProduct || {};
            const { headings } = additionalInfo || {};
            const services = headings?.services ?? [];
            const monthlyPlan = stripePrices.find(
              (stripePrice) => stripePrice.interval === 'month',
            );
            const yearlyPlan = stripePrices.find(
              (stripePrice) => stripePrice.interval === 'year',
            );

            if (acc.monthlyPlan) {
              acc.monthlyPlan = [
                ...acc.monthlyPlan,
                { ...monthlyPlan, type: name, description, services },
              ];
            } else {
              acc.monthlyPlan = [
                { ...monthlyPlan, type: name, description, services },
              ];
            }

            if (acc.annualplan) {
              acc.annualplan = [
                ...acc.annualplan,
                { ...yearlyPlan, type: name, description, services },
              ];
            } else {
              acc.annualplan = [
                { ...yearlyPlan, type: name, description, services },
              ];
            }

            acc.monthlyPlan = sortArrayCustomOrder(acc.monthlyPlan);
            acc.annualplan = sortArrayCustomOrder(acc.annualplan);

            return acc;
          },
          {},
        );

        const { data: paymentMethodData } = paymentMethodsRes || {};
        dispatch(actions.updateUser({ user: { subscriptions } }));
        const { payment_methods: paymentMethods } = paymentMethodData || {};
        setPaymentMethods(paymentMethods);
        setSubscriptionPlans(transformedStripeProducts);
        setCurrentSubscription(currentSubscription);
        setSelectedPlanType &&
          setSelectedPlanType(
            currentSubscription.stripe_price.interval ?? 'month',
          );
        setLastSubscription && setLastSubscription(lastSubscription);
        dispatch(setLoader({ isLoading: false }));
        setShouldRedirect && setShouldRedirect(true);
      } catch (e) {
        dispatch(setLoader({ isLoading: false }));
      }
    },
    [dispatch],
  );

  return fetchPlans;
};

export default useFetchPlans;
