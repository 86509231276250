import React from 'react';
import Styles from './FooterCard.module.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BILLING_PAYMENT } from 'constants';

const FooterCard = ({ type, subId, children, newUser }) => {
  let navigate = useNavigate();
  const user = useSelector((state) => state.auth);

  // const handleClick = () => {
  //   if(type === 'Business Plan'){
  //     return
  //   }
  //   if (user?.isLoggedIn) {
  //     navigate(`/${BILLING_PAYMENT}?subscriptionId=${subId}`);
  //   } else {
  //     navigate('/log-in');
  //   }
  // };

  return (
    <div
      className={`${Styles.card_container} ${newUser && Styles.new_user_btns}`}
    >
      <div className={Styles.card_container__card}>
        <div className={Styles.card_container__card__inner}>
          <p className={Styles.card_container__card__inner__text}>
            <span>Best Suited For : </span>
            {type === 'Pro Plan'
              ? 'Small businesses looking to enhance content production and efficiency.'
              : type === 'businessplan'
                ? 'Teams/agencies needing to collaborate effectively and scale their content marketing efforts.'
                : 'Solopreneurs and freelance content creators just starting their content marketing journey.'}
          </p>
          {children}
          {/* <button
            onClick={handleClick}
            className={`cancel_btn w-100 ${
              type === 'Pro Plan'
                ? Styles.pro_btn
                : type === 'Business Plan'
                ? Styles.business_btn
                : Styles.basic_btn
            }`}
          >
            {/* {children} */}
          {/* {type === 'Business Plan' ? 'Coming Soon' : "Click Here"} */}
          {/* </button> */}
        </div>
      </div>
    </div>
  );
};

export default FooterCard;
