import React from 'react';
import Styles from 'Components/KanbanCard/KanbanCard.module.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FaEllipsisV } from 'react-icons/fa';
import sc from 'Components/FolderViewCard/FolderViewCard.module.scss';

function CustomDropDown({ options, resource, addCustomClass = false }) {
  const classes = addCustomClass ? 'd-flex ' + sc.listItem_sm : '';

  const items = options.map((option) => {
    return (
      <div className={classes} key={resource.id + option.key}>
        <Dropdown.Item
          className={Styles.DropdownList}
          onClick={() => option.action(resource)}
        >
          {option.icon}
          {option.label}
        </Dropdown.Item>
      </div>
    );
  });

  return (
    <DropdownButton title={<FaEllipsisV color="#182230" />}>
      {items}
    </DropdownButton>
  );
}

export default CustomDropDown;
