import React from 'react';
import Styles from './Icons.module.scss';

const CircularIcon = ({
  background,
  children,
  height = '40px',
  width = '40px',
}) => {
  return (
    <div
      className={Styles.CircularIconContainer}
      style={{ background, height, width }}
    >
      {children}
    </div>
  );
};

export default CircularIcon;
