import React from 'react';
import Table from 'react-bootstrap/Table';
import { FiUsers, FiType, FiCalendar, FiDisc } from 'react-icons/fi';

import TableHead from 'Components/TableHeader/TableHead';
import DocumentListBody from 'Components/TableBody/DocumentListBody';
import Styles from './Documents.module.scss';

const documentsTableHeader = [
  {
    id: 'name',
    title: 'Name',
  },
  {
    id: 'contenttype',
    title: 'Content Type',
  },
  {
    id: 'dateCreated',
    title: 'Date Created',
  },
  {
    id: 'status',
    title: 'Status',
  },
  {
    id: 'collaborators',
    title: 'Collaborators',
  },
  {
    id: 'actions',
    title: '',
    icon: '',
  },
];

const DocumentsListView = ({
  documents = [],
  folders = [],
  fetchFolders,
  isTrash,
  projectView,
}) => {
  const tableData = [
    ...folders.map((f) => ({
      ...f,
      isDocument: false,
    })),
    ...documents.map((d) => ({
      ...d,
      isDocument: true,
    })),
  ];

  return (
    <section className={Styles.ListView}>
      <Table className="table-fixed" hover responsive="lg">
        <TableHead TableHeadings={documentsTableHeader} />
        <DocumentListBody
          tableData={tableData}
          fetchFolders={fetchFolders}
          isTrash={isTrash}
          projectView={projectView}
        />
      </Table>
    </section>
  );
};

export default DocumentsListView;
