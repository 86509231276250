import React, { useState } from 'react';
import sc from './AITool.module.scss';
import BSButton from 'Components/Button/BSButton';
import Input from 'Components/Input/Input';
import { FiSearch } from 'react-icons/fi';

const Suggestions = [
  {
    id: Math.random(),
    value: 'Social media',
  },
  {
    id: Math.random(),
    value: 'Blogging',
  },
  {
    id: Math.random(),
    value: 'content marketing',
  },
];
function AIRToolInputSection({ onClick }) {
  const [keyword, setKeyword] = useState('');

  function onKeyPress(event) {
    if (event.key === 'Enter') {
      onClick(keyword);
    }
  }

  return (
    <section className={`d-flex flex-column ${sc.AIRTOOL}`}>
      <div className={sc.AIRTOOL__top_container}>
        <p className={`${sc.AIRTOOL__top_container__heading} Animate-Heading`}>
          Keyword Research
        </p>
        <p
          className={`${sc.AIRTOOL__top_container__description} Animate-Heading`}
        >
          Search your topics, break down keyword variations, browse the top
          queries and use these insights to brainstorm content ideas.
        </p>
        <div
          className={`d-flex justify-content-between gap-2 w-100 ${sc.AIRTOOL__InputSection}`}
        >
          <Input
            placeholder={'Type keywords'}
            inputWrapperClass="w-100"
            classList={'input '}
            value={keyword}
            onKeyPress={onKeyPress}
            onChange={({ target: { value } }) => setKeyword(value)}
          />
          <BSButton
            BtnWrapperClass={''}
            onClick={() => onClick(keyword)}
            classList={`secondary_btn`}
            variant="dark"
            ButtonText={'Search'}
            disabled={!keyword.trim()}
          />
        </div>
        <div className={sc.tag_container}>
          {Suggestions.map((item, index) => (
            <div key={index} className={sc.tag_container__tag}>
              {item.value}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default AIRToolInputSection;
