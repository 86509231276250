import React from 'react';
import Tab from 'react-bootstrap/Tab';
import { Nav } from 'react-bootstrap';
import Styles from './ProjectTabs.module.scss';

const ProjectTabs = ({ tabs }) => {
  const activeTab = tabs.find((tab) => tab.label)?.key || 'overview';

  return (
    <section className={Styles.Documents}>
      <section className={Styles.Documents_Wrapper}>
        <Tab.Container
          className="nav-tabs"
          id="document-tabs"
          defaultActiveKey={activeTab}
        >
          <Nav className={Styles.ContentPlanner__Wrapper_Navigation}>
            <div className={Styles.ContentPlanner__Wrapper_Navigation__left}>
              {tabs.map(
                (tab) =>
                  tab.label && (
                    <Nav.Item
                      key={tab.key}
                      className={Styles.ContentPlanner__Wrapper_Navigation_Icon}
                    >
                      <Nav.Link
                        eventKey={tab.key}
                        className={
                          Styles.ContentPlanner__Wrapper_Navigation_Icon__Item
                        }
                      >
                        <span>{tab.label}</span>
                      </Nav.Link>
                    </Nav.Item>
                  ),
              )}
            </div>
          </Nav>
          <Tab.Content>
            {tabs.map(
              (tab) =>
                tab.label && (
                  <Tab.Pane key={tab.key} eventKey={tab.key}>
                    {tab.content}
                  </Tab.Pane>
                ),
            )}
          </Tab.Content>
        </Tab.Container>
      </section>
    </section>
  );
};

export default ProjectTabs;
