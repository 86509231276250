import React from 'react';
import '../Input/Input.scss';
import Form from 'react-bootstrap/Form';
import TextareaAutosize from 'react-textarea-autosize';
import { Field } from 'formik';
import { TbExclamationCircle, TbInfoCircle } from 'react-icons/tb';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function FormikTextField({
  name,
  inputWrapperClass,
  Label,
  iconWithLabel,
  type,
  placeholder,
  classList,
  value,
  disabled,
  autoFocus,
  onChange,
  errorMessage,
  onKeyDown,
  id,
  isRequired,
  isTextArea = false,
  preIcon,
  postIcon,
  infoTooltip,
  ...props
}) {
  return (
    <div className={`w-100 ${inputWrapperClass}`}>
      <Field name={name}>
        {({ field }) => {
          return (
            <Form.Group controlId={name}>
              {Label && (
                <Form.Label className="Input_label">
                  {Label}
                  {iconWithLabel && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-${name}`}>{infoTooltip}</Tooltip>
                      }
                    >
                      <span>
                        <TbExclamationCircle
                          color="#98A2B3"
                          style={{
                            height: '20px',
                            width: '20px',
                            marginLeft: '4px',
                            transform: 'rotate(180deg)',
                            cursor: 'pointer',
                          }}
                        />
                      </span>
                    </OverlayTrigger>
                  )}
                  {''}
                  {isRequired && <span style={{ color: '#b42318' }}> *</span>}
                </Form.Label>
              )}
              {isTextArea ? (
                <TextareaAutosize
                  type={type}
                  placeholder={placeholder}
                  className={classList}
                  value={field.value}
                  disabled={disabled}
                  autoFocus={autoFocus}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  id={id}
                  onKeyDown={onKeyDown}
                  maxRows={7}
                  {...props}
                />
              ) : (
                <div className="position-relative">
                  <Form.Control
                    type={type}
                    placeholder={placeholder}
                    className={`${classList} ${preIcon && 'preicon_input'} ${postIcon && 'posticon_input'}`}
                    value={field.value}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    id={id}
                    onKeyDown={onKeyDown}
                    {...props}
                  />
                  {preIcon && <span className="pre-icon">{preIcon} </span>}
                  {postIcon && <span className="post-icon">{postIcon} </span>}
                </div>
              )}
            </Form.Group>
          );
        }}
      </Field>
    </div>
  );
}

export default FormikTextField;
