import React, { useState } from 'react';
import Styles from './OffCanvas.module.scss';
import parse from 'html-react-parser';
import { BsThreeDots } from 'react-icons/bs';
import { FiX } from 'react-icons/fi';

const AiTextOffcanvas = ({
  aiContent,
  handleInsert,
  input,
  setInput,
  setAiContent,
  onClose,
  showEditor,
  contentGenerated,
  handleCopy,
}) => {
  if (!showEditor) {
    return null; // Do not render if editor is not supposed to be shown
  }
  return (
    <div className={Styles.documentHeathSection}>
      <div className={Styles.menuBar__refresh}>
        <div className="d-flex align-items-center gap-2">
          <button
            disabled={!contentGenerated}
            onClick={handleInsert}
            className="cancel_btn"
          >
            Insert
          </button>
          <button
            disabled={!contentGenerated}
            onClick={handleCopy}
            className="cancel_btn"
          >
            Copy
          </button>
        </div>
        {!!input.trim().length && (
          <button className={Styles.btns__dots}>
            <FiX
              color=" #667085"
              size={20}
              onClick={() => {
                // setInput('');
                setAiContent('');
                onClose();
              }}
            />
          </button>
        )}
      </div>
      {!!aiContent.trim() && (
        <>
          <div className={Styles.content}>{parse(aiContent)}</div>
        </>
      )}
    </div>
  );
};

export default AiTextOffcanvas;
