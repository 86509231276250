import React from 'react';
import styles from './ContentScore.module.scss';
import ScoreCard from './ScoreCard';
import Meter from './Meter';
import GaugeComponent from 'react-gauge-component';
import { secondsToMinutesAndSeconds } from 'helpers/health';

const ContentScore = ({
  overallScore = 0,
  wordCount = 0,
  readingTime = 0,
  headingCount = 0,
  paragraphCount = 0,
  sentenceCount = 0,
}) => {
  return (
    <div>
      {/* <Meter /> */}
      <div className="my-4 d-flex justify-content-center">
        <div className={styles.gauge_container}>
          <GaugeComponent
            type="semicircle"
            arc={{
              colorArray: ['#ee5237', '#f59008', '#5bb46a'],
              padding: 0.02,
              subArcs: [{ limit: 40 }, { limit: 80 }, { limit: 100 }],
            }}
            pointer={{ type: 'blob', animationDelay: 0 }}
            value={overallScore}
            minValue={0}
            maxValue={100}
          />
          <div className={styles.gauge_text}>
            {overallScore} <span>/100</span>
          </div>
          <span className={styles.zero}>0</span>
          <span className={styles.full}>100</span>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.col}>
            <ScoreCard heading="Words" score={wordCount} />
          </div>
          <div className={styles.col}>
            <ScoreCard heading="Sentences" score={sentenceCount} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <ScoreCard heading="Paragraph" score={paragraphCount} />
          </div>
          <div className={styles.col}>
            <ScoreCard heading="Headings" score={headingCount} />
          </div>
        </div>
        <div className={styles.row}>
          {/* <div className={styles.col}>
            <ScoreCard heading="Images" score={23} />
          </div> */}
          <div className={styles.col}>
            <ScoreCard
              heading="Reading Time"
              score={secondsToMinutesAndSeconds(readingTime)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentScore;
