import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setLoader } from 'redux/slices/loader';
import store from 'redux/store';
import { toast } from 'react-toastify';

import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import AuthService from '../../services/authService';
import { humanize } from 'helpers';
import { pushEvent, GTM_EVENTS } from 'analytics/gtm';

const user = JSON.parse(localStorage.getItem('user'));

const removeQueryParams = () => {
  !['/', ''].includes(window.location.pathname) && window.location.assign('/');
};

export const register = createAsyncThunk(
  'auth/register',
  async (body, thunkAPI) => {
    store.dispatch(
      setLoader({
        isLoading: true,
        heading: 'Signing up!',
        description: 'Please wait...',
      }),
    );
    try {
      const response = await AuthService.register(body);
      store.dispatch(setLoader({ isLoading: false }));

      pushEvent(GTM_EVENTS.SIGN_UP, { user_id: response?.user?.id });

      if (!response.confirmed_at) {
        toast.success(
          humanize(
            'Signed up successfully, please verify your email address by following the instructions sent to your email.',
          ),
        );
        return thunkAPI.rejectWithValue({ confirmation_required: true });
      } else {
        return { user: response };
      }
    } catch (error) {
      store.dispatch(setLoader({ isLoading: false }));
      renderError(error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const login = createAsyncThunk('auth/login', async (body, thunkAPI) => {
  store.dispatch(
    setLoader({
      isLoading: true,
      heading: 'Signing in!',
      description: 'Please wait...',
    }),
  );
  try {
    const data = await AuthService.login(body);
    store.dispatch(setLoader({ isLoading: false }));

    pushEvent(GTM_EVENTS.LOGIN, { user_id: data?.id });

    return { user: data };
  } catch (error) {
    store.dispatch(setLoader({ isLoading: false }));
    renderError(error);
    return thunkAPI.rejectWithValue();
  }
});

export const socialLogin = createAsyncThunk(
  'auth/socialLogin',
  async (body, signType, thunkAPI) => {
    store.dispatch(
      setLoader({
        isLoading: true,
        heading: 'Signing in!',
        description: 'Please wait...',
      }),
    );
    try {
      const data = await AuthService.socialLogin(body);
      store.dispatch(setLoader({ isLoading: false }));

      pushEvent(signType ? GTM_EVENTS.LOGIN : GTM_EVENTS.SIGN_UP, {
        user_id: data?.id,
      });

      return { user: data };
    } catch (error) {
      store.dispatch(setLoader({ isLoading: false }));
      renderError(error);
      return thunkAPI.rejectWithValue();
    }
  },
);

export const logout = createAsyncThunk('auth/logout', async ({ showToast }) => {
  try {
    await AuthService.logout();
    showToast && renderSuccess('You have been logged out successfully!');
  } catch (error) {
    showToast && renderError(error);
  }
});

const initialState = user?.id
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      const user = { ...state.user, ...action.payload.user };
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
  },
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      removeQueryParams();
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      removeQueryParams();
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [socialLogin.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      removeQueryParams();
    },
    [socialLogin.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

export const actions = authSlice.actions;
export const { reducer } = authSlice;
export default reducer;
