import React from 'react';
import Styles from './Status.module.scss';
import { BsFillCircleFill } from 'react-icons/bs';

const StatusOption = ({ value, label }) => {
  return (
    <div className={Styles.StatusOption} data-label={value}>
      <BsFillCircleFill size={10} />
      <p>{label}</p>
    </div>
  );
};

export const StatusOptions = [
  {
    value: 'not_started',
    label: <StatusOption value="not_started" label="Not Started" />,
  },
  {
    value: 'in_progress',
    label: <StatusOption value="in_progress" label="In Progress" />,
  },
  {
    value: 'in_review',
    label: <StatusOption value="in_review" label="In Review" />,
  },
  {
    value: 'approved',
    label: <StatusOption value="approved" label="Approved" />,
  },
  {
    value: 'published',
    label: <StatusOption value="published" label="Published" />,
  },
];

export default StatusOption;
