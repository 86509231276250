import React from 'react';
import styles from './ScoreCard.module.scss';

const ScoreCard = ({ heading, score }) => {
  return (
    <div className={styles.score_card}>
      <div className={styles.score_card__heading}>{heading}</div>
      <div className={styles.score_card__score}>{score}</div>
    </div>
  );
};

export default ScoreCard;
