import React, { useMemo, useRef, useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import Modal from 'react-bootstrap/Modal';
import { BiChevronsDown, BiChevronsUp } from 'react-icons/bi';
import { FiCalendar } from 'react-icons/fi';
import { HiLink } from 'react-icons/hi';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import ImageView from 'Components/Image/ImageView';
import KanbanDropDown from 'Components/KanbanCard/KanbanDropDown';
import KanbanList from 'Containers/KanbanList/KanbanList';
import { formattedDate, getUserImage, titleize } from 'helpers';
import useOnClickOutside from 'hooks/useOutsideClick';
import CardStyles from '../KanbanCard/KanbanCard.module.scss';
import './Calender.scss';
import NewDocumentModal from 'Components/Modals/NewDocumentModal/NewDocumentModal';
import { ROUTES } from 'constants/routes';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';
import { useSelector } from 'react-redux';

function ViewMoreModal({
  show,
  setShow,
  tasks,
  onMove,
  onEdit,
  onDelete,
  onArchive,
  onUpdateBrief,
  handleAICreate,
}) {
  const handleClose = () => setShow(false);
  const priorities = ['low', 'medium', 'high'];

  const sortedTasks = tasks.sort(
    (a, b) => priorities.indexOf(b.priority) - priorities.indexOf(a.priority),
  );

  return (
    <Modal
      size="xl"
      centered
      show={show}
      onHide={handleClose}
      style={{ zIndex: 1050 }}
    >
      <Modal.Header className="border-0 pb-0" closeButton>
        <Modal.Title className="Modal-Title">
          {tasks?.[0]?.deadline_date} - Tasks
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <KanbanList
          tasks={sortedTasks}
          onDelete={onDelete}
          onMove={onMove}
          onEdit={onEdit}
          onArchive={onArchive}
          calendarTable={true}
          onUpdateBrief={onUpdateBrief}
          handleAICreate={handleAICreate}
        />
      </Modal.Body>
    </Modal>
  );
}

function Calender({
  tasks,
  onMove,
  onEdit,
  onDelete,
  fetchCPTasks,
  onArchive,
  calendarProps = {},
  onUpdateBrief,
  handleAICreate,
}) {
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedEventInfo, setSelectedEventInfo] = useState(null);
  const [show, setShow] = useState(false);
  const [moreDate, setMoreDate] = useState('');
  const [position, setPosition] = useState(null);
  const [NewDocumentModalView, setNewDocumentModalView] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [taskName, setTaskName] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const eventRef = useRef();

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { id: userId, subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  const navigate = useNavigate();

  useOnClickOutside(eventRef, () => {
    setSelectedEvent('');
    setPosition(null);
  });

  const handleEventClick1 = ({ event, jsEvent }) => {
    if (event.id === selectedEvent) {
      return;
    }
    setSelectedEventInfo(event);
    setSelectedEvent(event.id);

    setPosition({ top: jsEvent.clientY - 10, left: jsEvent.clientX - 40 });
  };

  function eventAddStyles(arg) {
    // all self-created props are under "extendedProps"
    if (arg.event.extendedProps.demanding === 'InReview') {
      return ['event-vocable-calender-Inreview'];
    } else if (arg.event.extendedProps.demanding === 'InProgress') {
      return ['event-vocable-calender-Inprogress'];
    } else if (arg.event.extendedProps.demanding === 'Approved') {
      return ['event-vocable-calender-Approved'];
    } else if (arg.event.extendedProps.demanding === 'Published') {
      return ['event-vocable-calender-Published'];
    } else {
      return ['event-vocable-calender-NotStarted'];
    }
  }

  const events = useMemo(() => {
    if (!tasks?.length) {
      return [];
    }

    return tasks.map((task) => {
      return {
        id: task.id,
        title: task.title,
        start: new Date(task.deadline_date).toISOString(),
        demanding: titleize(task.state, false),
        state: task.state,
        access: task.project_access,
      };
    });
  }, [tasks]);

  const handleClick = (id, templateId) => {
    if (templateId) {
      navigate(ROUTES.EditorDocumentTemplate(id, templateId));
    } else {
      navigate(ROUTES.FreestyleDocumentTemplate(id));
    }
  };

  const onHideModal = (refetchTasks = false) => {
    refetchTasks && fetchCPTasks && fetchCPTasks();
    setNewDocumentModalView(false);
  };

  const selectedTaskInfo =
    tasks.find((task) => Number(task.id) === Number(selectedEvent)) || {};

  const {
    title: task_title,
    state,
    assignee,
    deadline_date,
    priority,
    document,
    id: task_id,
    project_id,
  } = selectedTaskInfo || {};

  const TasksData = [
    {
      id: Math.random(),
      Heading: 'Status',
      info: titleize(state || ''),
    },
    {
      id: Math.random(),
      Heading: 'Assignee',
      info: assignee?.name || '',
      AssigneeAvatar: true,
      AssigneeAvatarSrc: assignee?.mini_avatar,
    },
    {
      id: Math.random(),
      Heading: 'Due Date',
      DueDate: deadline_date,
    },
    {
      id: Math.random(),
      Heading: 'Label',
      info: titleize(priority || ''),
    },
  ];

  const Tasks = TasksData.map((task) => {
    return (
      <div className="d-flex g-10" id={task.id} key={task.id}>
        <span className={CardStyles.CardCalenderEvent__TaskDetails_Heading}>
          {task.Heading}:
        </span>

        {task.AssigneeAvatar ? (
          <div
            className={`${CardStyles.CardCalenderEvent__TaskDetails_Info} d-flex`}
          >
            {task.AssigneeAvatarSrc ? (
              <ImageView
                class="event-card-avatar"
                src={task.AssigneeAvatarSrc}
              />
            ) : (
              getUserImage(
                task.AssigneeAvatarSrc,
                task.info,
                {
                  width: 18,
                  height: 18,
                },
                undefined,
                'event-card-avatar',
              )
            )}
            {task.info}
          </div>
        ) : task.Heading === 'Label' ? (
          <span className={`badge-wrapper ${task.info}Badge`}>
            {task.info !== 'High' ? <BiChevronsDown /> : <BiChevronsUp />}
            <span>{task.info}</span>
          </span>
        ) : task.Heading === 'Due Date' ? (
          <div
            className={`${CardStyles.CardCalenderEvent__TaskDetails_Info} d-flex`}
          >
            <FiCalendar color="#98A2B3" size={12} />
            {task.DueDate}
          </div>
        ) : (
          <span className={CardStyles.CardCalenderEvent__TaskDetails_Info}>
            {task.info}
          </span>
        )}
      </div>
    );
  });

  const renderEventContent = (eventInfo) => {
    return (
      <div id={eventInfo.event.id} className="event_info">
        <div className={CardStyles.CardCalenderEvent__Title}>
          {eventInfo.event.title}
        </div>
      </div>
    );
  };

  const isYGreater = position?.top + 204 > window.innerHeight;
  const isXGreater = position?.left + 357 > window.innerWidth;

  const newX = isXGreater ? position?.left - 357 : position?.left;
  const newY = isYGreater ? position?.top - 204 : position?.top;

  const selectedEventTitleSize = selectedEventInfo?.title?.length || 0;

  return (
    <>
      <section className="vocable-calender">
        <FullCalendar
          eventClassNames={eventAddStyles}
          events={events}
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          contentHeight="auto"
          firstDay={1}
          eventClick={handleEventClick1}
          moreLinkClick={(data) => {
            setShow(true);
            setMoreDate(data.date);
          }}
          eventContent={renderEventContent}
          dayMaxEvents={2}
          headerToolbar={{
            left: 'prev next title',
            center: '',
            right: '',
          }}
          {...calendarProps}
        />

        {show && (
          <ViewMoreModal
            show={show}
            setShow={setShow}
            tasks={tasks.filter(
              (task) =>
                formattedDate(new Date(task.deadline_date)) ===
                formattedDate(new Date(moreDate)),
            )}
            onDelete={onDelete}
            onEdit={onEdit}
            onMove={onMove}
            onArchive={onArchive}
            onUpdateBrief={onUpdateBrief}
            handleAICreate={handleAICreate}
          />
        )}

        {position && (
          <section
            className={
              'd-flex flex-column w-100 ' + CardStyles.CardCalenderEvent
            }
            style={{
              position: 'fixed',
              top: newY,
              left: newX,
            }}
            ref={eventRef}
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              {' '}
              <p
                className={CardStyles.CardCalenderEvent__CardTitle}
                style={{
                  marginLeft: 0,
                  height: selectedEventTitleSize > 50 ? 32 : 18,
                }}
              >
                {selectedEventTitleSize
                  ? selectedEventTitleSize > 25
                    ? `${selectedEventInfo?.title.substr(0, 25)}...`
                    : selectedEventInfo?.title
                  : selectedEventInfo?.title}
              </p>
              <KanbanDropDown
                id={Number(selectedEventInfo?.id)}
                onDelete={(value) => {
                  setPosition(null);
                  setSelectedEvent('');
                  onDelete(value);
                }}
                onEdit={(value) => {
                  if (
                    currentSubscription?.plan_name === 'Basic Plan' &&
                    project_id !== null
                  ) {
                    setShowUpgradeModal(true);
                  } else {
                    setPosition(null);
                    setSelectedEvent('');
                    onEdit(value);
                  }
                }}
                onMove={(value) => {
                  setPosition(null);
                  setSelectedEvent('');
                  onMove(value);
                }}
                onArchive={(value) => {
                  setPosition(null);
                  setSelectedEvent('');
                  onArchive(value);
                }}
                access={selectedEventInfo?.extendedProps?.access}
                state={selectedEventInfo?.extendedProps?.state}
              />
            </div>
            <section className={CardStyles.CardCalenderEvent__TaskDetails}>
              {Tasks}
            </section>
            {document?.id ? (
              <div
                className={
                  'd-flex align-items-center profile-section copy-link'
                }
                onClick={() => handleClick(document?.id, document?.template_id)}
              >
                <HiLink size={10} color="#00a7b7" />
                <p className="linkColor link f-s12">Open Draft</p>
              </div>
            ) : (
              <div
                className={
                  'd-flex align-items-center profile-section copy-link '
                }
                style={{
                  gap: '3px',
                }}
                // onClick={() => handleAICreate(task_id, project_id, task_title)}
                onClick={() => {
                  // e.stopPropagation();
                  currentSubscription?.plan_name === 'Basic Plan' &&
                  project_id !== null
                    ? setShowUpgradeModal(true)
                    : handleAICreate(task_id, project_id, task_title);

                  // currentSubscription?.plan_name === "Basic Plan" && location.pathname.startsWith('/projects') ? setShowUpgradeModal(true) : handleAICreate(task_id, project_id, task_title);
                }}
              >
                <AiOutlinePlus size={10} color="#00a7b7" />
                <span className="linkColor link f-s12">Create Draft</span>
              </div>
            )}
          </section>
        )}
      </section>
      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
          message={"Updating campaign related content is a pro feature. Please update to pro plan to access this feature."}
        />
      )}

      {!!taskId && (
        <NewDocumentModal
          show={NewDocumentModalView}
          onHide={onHideModal}
          taskId={taskId}
          taskName={taskName}
          projectId={projectId}
          aiPowered
        />
      )}
    </>
  );
}

export default Calender;
