import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProjectsKey } from 'constants/queryKeys';
import { createProject } from 'services/projectService';
import { GTM_EVENTS, pushEvent } from 'analytics/gtm';
import { error as renderError } from 'helpers/toaster';

const useCreateProject = ({ userId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ProjectsKey] });
      pushEvent(GTM_EVENTS.PROJECT_CREATED, { user_id: userId });
    },
    onError: (error) => {
      renderError(error);
    },
  });
};

export default useCreateProject;
