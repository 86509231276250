import React from 'react';
import Styles from './AvatarGroup.module.scss';

export function NoAvatar({ heading, description }) {
  return (
    <div>
      <p className={`${Styles.avatar_heading} mb-1`}>{heading}</p>
      <p className={Styles.avatar_description}>{description}</p>
    </div>
  );
}

export default NoAvatar;
