import React from 'react';
import Styles from './Heading.module.scss';
const Heading = ({ heading, desc }) => {
  return (
    <div>
      <p className={Styles.heading}>{heading}</p>
      <p className={Styles.desc}>{desc}</p>
    </div>
  );
};

export default Heading;
