import { useQuery } from '@tanstack/react-query';
import { getDocument } from 'services/documentService';
import keys from 'constants/queryKeys';

const useDocument = ({ documentId }) => {
  const query = useQuery({
    queryKey: keys.Document(documentId),
    queryFn: () => getDocument(documentId),
  });

  return {
    ...query,
    ...query?.data?.data,
  };
};

export default useDocument;
