import React, { useRef, useState } from 'react';
import { copyToClipboard } from 'helpers';
import useOnClickOutside from 'hooks/useOutsideClick';
import { FiCheck, FiCopy, FiEdit3 } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import Styles from './ContentIdeaListItem.module.scss';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import useCreateDocument from 'hooks/documents/useCreateDocument';

const ContentIdeaListItem = ({ headline, index }) => {
  const [isCopied, setIsCopied] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const { mutateAsync: createDocument } = useCreateDocument({
    userId: user?.id,
  });

  const transformedHeadline = headline?.replace(/\n/g, '<br/>');

  const copyRef = useRef(null);

  const navigate = useNavigate();

  useOnClickOutside(copyRef, () => setIsCopied(false));

  const handleCopy = () => {
    setIsCopied(true);
    copyToClipboard(headline);
  };

  const handleEditor = async () => {
    const content = `<p>${transformedHeadline || ''}</p>`;
    const title = !index ? 'Summary' : 'Analysis';

    await createDocument(
      {
        document: {
          title,
          user_id: user?.id,
          content,
        },
      },
      {
        onSuccess: (documentResponse) => {
          const documentId = documentResponse?.data?.document?.id;
          if (!documentId) return;

          navigate(ROUTES.FreestyleDocumentTemplate(documentId || {}));
        },
      },
    );
  };

  return (
    <li className={Styles.ListWrapper}>
      <section className={Styles.ListWrapper__Headline}>
        <div
          className="text-lg-lh24"
          style={{ height: 205, overflowY: 'scroll' }}
        >
          {parse(transformedHeadline)}
        </div>
      </section>
      <section className={'d-flex flex-wrap ' + Styles.ListWrapper__Actions}>
        <div
          onClick={handleCopy}
          className="d-flex align-items-center g-10"
          ref={copyRef}
        >
          {isCopied ? <FiCheck color="#344054" /> : <FiCopy color="#344054" />}
          <span className="text-sm-18lh">
            {isCopied ? 'Copied' : 'Copy text'}
          </span>
        </div>
        <div className="d-flex align-items-center g-10">
          <FiEdit3 color="#344054" />
          <span className="text-sm-18lh" onClick={handleEditor}>
            Open in Editor
          </span>
        </div>
      </section>
    </li>
  );
};

export default ContentIdeaListItem;
