import React from 'react';
import Styles from './AvatarGroup.module.scss';
import { LuUser } from 'react-icons/lu';
import { CONTENT_TYPE_ICONS_ROUNDED } from 'constants/contentTypes';

export function AvatarGroup({
  heading,
  size,
  avatars = [],
  overflowAfter = 1,
  className='',
  justifyLeft
}) {
  const overflowCount = (avatars?.length || 0) - 2;
  return (
    <div>
      {heading && <p className={Styles.avatar_heading}>{heading}</p>}
      <div className={`${Styles.avatar_group} ${className}`} style={justifyLeft === true ? {justifyContent:'start '} : {}}>
        {avatars?.slice(0, 2).map((item, index) => (
          <div
            key={index}
            className={`${size === 'sm' ? Styles.avatar_sm : size === 'large' ? Styles.avatar_lg : Styles.avatar}`}
          >
            {/* {item.avatar ? (
              <img src={item.avatar} alt="Image" />
            )  */}
            {CONTENT_TYPE_ICONS_ROUNDED[(typeof item === 'string' ? item.trim() : '')] ? (
              CONTENT_TYPE_ICONS_ROUNDED[typeof item === 'string' ? item.trim() : '']
            )
            : (
              <span className={Styles.avatar__img}>
                <LuUser color="#00a7b7" />
              </span>
            )}
          </div>
        ))}
        {overflowCount > 0 && (
          <div
            className={`${size === 'sm' ? Styles.avatar_sm : size === 'large' ? Styles.avatar_lg : Styles.avatar}`}
          >
            <p>+{overflowCount}</p>
          </div>
        )}
        {avatars?.length === 1 && (
          <p className={Styles.avatar_name}>{avatars[0].name}</p>
        )}
      </div>
    </div>
  );
}
