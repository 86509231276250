import { Field } from 'formik';
import Form from 'react-bootstrap/Form';
import '../Input/Input.scss';

function FormikCheckbox({ name, inputWrapperClass, Label, type, id }) {
  return (
    <div className={inputWrapperClass}>
      <Field name={name}>
        {({ field }) => {
          return (
            <Form.Group controlId={name}>
              <Form.Check
                id={id}
                type={type}
                label={Label}
                name={name}
                onChange={field.onChange}
                checked={field.value}
              />
            </Form.Group>
          );
        }}
      </Field>
    </div>
  );
}

export default FormikCheckbox;
