import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from 'helpers/axios';

// ##### ##### ##### ##### #####   GET   ##### ##### ##### ##### ##### //
export const getAllMembers = () => {
  return getRequest('team_collaborators');
};
export const getTeams = () => {
  return getRequest('teams');
};

export const getTeam = (teamId) => {
  return getRequest(`teams/${teamId}`);
};

export const getTeamUsers = (teamId) => {
  return getRequest(`teams/${teamId}/team_users`);
};

// ##### ##### ##### ##### #####   POST   ##### ##### ##### ##### ##### //
export const createTeam = (team) => {
  return postRequest(
    'teams',
    { team },
    { 'content-type': 'multipart/form-data' },
  );
};

export const addTeamUser = (teamId, teamUser) => {
  return postRequest(`teams/${teamId}/team_users`, { team_user: teamUser });
};

export const resendInvite = (teamId, member) => {
  return postRequest(`teams/${teamId}/team_users/resend_invite`, {
    team_user: { email: member.email },
  });
};

// ##### ##### ##### ##### #####   PUT   ##### ##### ##### ##### ##### //
// TODO: teams/:teamId
export const updateTeamUserAccess = (teamId, teamUser) => {
  return putRequest(`teams/${teamId}/team_users/update_member_access`, {
    team_user: teamUser,
  });
};

// ##### ##### ##### ##### #####   PATCH   ##### ##### ##### ##### ##### //
export const updateTeam = (teamId, team) => {
  return patchRequest(
    `teams/${teamId}`,
    { team },
    { 'content-type': 'multipart/form-data' },
  );
};

// ##### ##### ##### ##### #####   DELETE   ##### ##### ##### ##### ##### //
// TODO: teams/:teamId
export const removeTeamUser = (teamId, email) => {
  return deleteRequest(
    `teams/${teamId}/team_users/remove?team_user[email]=${encodeURIComponent(
      email,
    )}`,
  );
};

// ##### ##### ##### ##### #####   ? WEIRD ?   ##### ##### ##### ##### ##### //
export const getTeamUsersData = (teamId, searchTerm) => {
  return getRequest(`teams/${teamId}/team_users?search=${searchTerm || ''}`);
};
