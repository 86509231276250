import React from 'react';
import BSButton from 'Components/Button/BSButton';
import { PROJECTS } from 'constants';
import { deleteRequest } from 'helpers/axios';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setLoader } from 'redux/slices/loader';
import { FiAlertCircle } from 'react-icons/fi';
import { PROJECT_TABS } from '../../../constants/projects';
import styles from '../../../Containers/Subscription/EmailModal.module.scss'

function ConfirmDeleteModal({
  resourceName,
  resource,
  onHide,
  onDelete,
  show,
  loaderHeading = 'Processing!',
  loaderDescription = 'Please wait...',
  modalHeading = 'Confirm?',
  actionText = 'Confirm',
  modalDescription = 'Are you sure you want to perform this Action?',
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();

  const confirmDelete = () => {
    const redirectPath = `/${resourceName}`;
    dispatch(
      setLoader({
        isLoading: true,
        heading: loaderHeading,
        description: loaderDescription,
      }),
    );
    deleteRequest(`${resourceName}/${resource?.id}`)
      .then((res) => {
        dispatch(setLoader({ isLoading: false }));
        renderSuccess(res?.data?.message);
        onHide(true);
        if (resourceName !== 'folders') {
          projectId || resource?.project_id
            ? navigate({
                pathname: `/${PROJECTS}/${projectId || resource?.project_id}`,
                search: `?tab=${PROJECT_TABS.content}`,
              })
            : navigate(
                redirectPath === '/documents' ? '/library' : redirectPath,
              );
        }
      })
      .catch((error) => {
        renderError(error);
        dispatch(setLoader({ isLoading: false }));
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pb-0 border-0" closeButton>
        <Modal.Title className="Modal-Title" id="contained-modal-title-vcenter" style={{fontFamily:'Inter'}}>
          {modalHeading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className="d-flex justify-content-center">
          <div
            style={{
              background: '#FEF3F2',
              height: '45px',
              width: '45px',
              borderRadius: '50%',
              position: 'relative',
            }}
          >
            <div
              style={{
                background: '#FEE4E2',
                height: '35px',
                width: '35px',
                borderRadius: '50%',
                margin: '5px',
                padding: '5px',
              }}
            >
              <FiAlertCircle size={25} color="#D92D20" />
            </div>
          </div>
        </div>

        <p
          style={{
            fontFamily: 'Inter',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '28px',
            letterSpacing: '0em',
            textAlign: 'center',
            marginTop: 20,
          }}
        >
          {modalHeading}
        </p>

        <p
          style={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'center',
            color: '#667085',
          }}
        >
          {modalDescription}
        </p>

        <div
          style={{
            marginTop: 30,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            gap:"10px",
          }}
        >
          <BSButton
                  onClick={() => onHide()}
                  ButtonText="Cancel"
                  variant="light"
                  classList={
                    'btn ' +
                    styles.modalFooterBtn +
                    ' ' +
                    styles.modalFooterBtn_Light
                  }
                />
                <BSButton
                  onClick={() => {
                    if (onDelete) {
                      onDelete();
                      onHide();
                    } else {
                      confirmDelete();
                    }
                  }}
                  ButtonText={actionText}
                  variant="dark"
                  classList={
                    'btn ' +
                    styles.modalFooterBtn +
                    ' ' +
                    styles.modalFooterBtn_dark
                  }
                />
          
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmDeleteModal;
