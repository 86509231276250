import React from 'react';
import { currencyFormat } from 'constants';
import { FiActivity, FiBox, FiMousePointer, FiSearch } from 'react-icons/fi';
import { setLoader } from '../redux/slices/loader';
import { generateResponse } from '../helpers/openAI';

export const KeywordResearchStats = ({
  searchVolume,
  keywordDifficulty,
  numberOfResults,
  CPC,
}) => [
  {
    id: Math.random(),
    icon: <FiSearch color="#7F56D9" />,
    Title: 'Search volume',
    TooltipHeading: 'Search volume',
    TooltipText: 'The number of searches this keyword has during a month',
    StatsNumbers: currencyFormat(searchVolume),
  },
  {
    id: Math.random(),
    icon: <FiActivity color="#7F56D9" />,
    Title: 'Keyword Difficulty',
    TooltipHeading: 'Keyword Difficulty',
    TooltipText: ' SEO effort required to rank your website for this keyword',
    StatsNumbers: `${keywordDifficulty}%`,
  },
  {
    id: Math.random(),
    icon: <FiBox color="#6c737f" />,
    Title: 'Results',
    TooltipHeading: 'Results',
    TooltipText:
      'the number of URLs displayed in organic search results for a given keyword.',
    StatsNumbers: currencyFormat(numberOfResults),
  },
  {
    id: Math.random(),
    icon: <FiMousePointer />,
    Title: 'CPC',
    TooltipHeading: 'Cost per click (CPC)',
    TooltipText: 'Average cost that someone pays for every click on an ad',
    StatsNumbers: `$${CPC}`,
  },
];

export const getTagsFromInput = async ({ title, dispatch = null, setTags }) => {
  if (!title.trim()) {
    return;
  }

  if (dispatch) {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Magic Takes Time',
        description: 'Please wait, fetching tags...',
      }),
    );
  }

  try {
    const tagsResponse = await generateResponse(
      `Give four tags of topic ${title}, limit to 3 words`,
    );

    const transformedTags = tagsResponse?.map((tag) => ({
      id: Math.random(),
      title: tag,
      selected: false,
    }));

    setTags(transformedTags);

    if (dispatch) {
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    }
  } catch (error) {
    if (dispatch) {
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    }
  }
};
