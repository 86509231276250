import React from 'react';
import {
  BsFillFileEarmarkTextFill,
  BsFillPostcardFill,
  BsFillMegaphoneFill,
  BsFillClipboard2Fill,
  BsFillCameraReelsFill,
  BsFillEnvelopeFill,
} from 'react-icons/bs';

export const getCategoriesSvg = (category) => {
  switch (category) {
    case 'Articles':
      return <BsFillFileEarmarkTextFill size={24} color="#8B6EFE" />;
    case 'Descriptions':
      return <BsFillPostcardFill size={24} color="#53B1FD" />;
    case 'Press Release':
      return <BsFillMegaphoneFill size={24} color="#7F56D9" />;
    case 'Ad Copy':
      return <BsFillClipboard2Fill size={24} color="#F670C7" />;
    case 'Social Media':
      return <BsFillCameraReelsFill size={24} color="#6172F3" />;
    case 'Email Campaigns':
      return <BsFillEnvelopeFill size={24} color="#4E5BA6" />;
    default:
      break;
  }
};
