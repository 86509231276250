import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DocumentsKey } from 'constants/queryKeys';
import { createDocument } from 'services/documentService';
import { GTM_EVENTS, pushEvent } from 'analytics/gtm';
import { error as renderError } from 'helpers/toaster';

const useCreateDocument = ({ userId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createDocument,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [DocumentsKey] });
      pushEvent(GTM_EVENTS.DOCUMENT_CREATED, { user_id: userId });
    },
    onError: (error) => {
      renderError(error);
    },
  });
};

export default useCreateDocument;
