import React from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  FiChevronRight,
  FiCopy,
  FiDownload,
  FiSave,
  FiTrash2,
} from 'react-icons/fi';

import StyleClasses from './OptionsDropDown.module.scss';
import ExportOptions from './ExportOptions';

const EditorOptions = ({
  drop,
  Icon,
  collaborator,
  openSaveAsModal,
  toggleShowFormats,
  showExportFormats,
  chevronRight,
  exportFile,
  openDuplicateModal,
  canDelete,
  setDeleteDocumentModalView,
}) => {
  return (
    <DropdownButton
      className={StyleClasses.DropdownWrapper}
      drop={drop}
      title={Icon}
    >
      <Dropdown.Item
        onClick={() => (collaborator ? {} : openSaveAsModal())}
        className={`${StyleClasses.dropDownItemWrapper} ${
          collaborator && StyleClasses.dropDownItemWrapper__disabled
        }`}
        disabled={collaborator}
      >
        <div className={'d-flex ' + StyleClasses.listItem}>
          <FiSave size={16} color="#667085" />
          <p>Save as</p>
        </div>
      </Dropdown.Item>
      <Dropdown.Item
        className={StyleClasses.dropDownItemWrapper}
        onMouseEnter={toggleShowFormats}
        onMouseLeave={toggleShowFormats}
      >
        <div
          className={
            'd-flex position-relative align-items-center ' +
            StyleClasses.listItem
          }
        >
          <FiDownload size={16} color="#667085" />
          <p>Export as</p>
          <FiChevronRight className="ms-auto" />
        </div>
        <ExportOptions
          show={showExportFormats}
          onClose={toggleShowFormats}
          chevronRight={chevronRight}
          exportFile={exportFile}
        />
      </Dropdown.Item>
      <Dropdown.Item
        className={StyleClasses.dropDownItemWrapper}
        onClick={openDuplicateModal}
      >
        <div className={'d-flex ' + StyleClasses.listItem}>
          <FiCopy size={16} color="#667085" />
          <p>Duplicate</p>
        </div>
      </Dropdown.Item>
      {canDelete && (
        <Dropdown.Item
          className={StyleClasses.dropDownItemWrapper}
          onClick={() => setDeleteDocumentModalView(true)}
        >
          <div className={'d-flex ' + StyleClasses.listItem}>
            <FiTrash2 size={16} color="#B42318" />
            <p className="error">Delete</p>
          </div>
        </Dropdown.Item>
      )}
    </DropdownButton>
  );
};

export default EditorOptions;
