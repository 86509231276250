export const ProjectsKey = 'PROJECTS';
export const BriefsKey = 'BRIEFS';
export const DocumentsKey = 'DOCUMENTS';
const TemplatesKey = 'TEMPLATES';
const CategoriesKey = 'CATEGORIES';
const LibraryKey = 'LIBRARY';
const FolderKey = 'FOLDER';
const QuestionsKey = 'QUESTIONS';

const keys = {
  InfiniteProjects: (search, type) => {
    return [
      ProjectsKey,
      'SEARCH',
      search.toUpperCase(),
      'TYPE',
      type.toUpperCase(),
    ];
  },
  RecentProjects: [ProjectsKey, 'RECENT'],
  Project: (projectId) => [ProjectsKey, projectId],
  ProjectUsers: (projectId) => [ProjectsKey, 'USERS', projectId],

  Briefs: ({ searchValue, filterStr, selectedProject, type }) => [
    BriefsKey,
    { searchValue: searchValue.toUpperCase(), filterStr, selectedProject },
    'TYPE',
    type.toUpperCase(),
  ],
  Brief: (briefId) => [BriefsKey, briefId],
  RecentBriefs: [BriefsKey, 'RECENT'],
  OverdueCountBriefs: (duration) => [
    BriefsKey,
    'OVERDUE_COUNT',
    duration.toUpperCase(),
  ],

  Document: (documentId) => [DocumentsKey, documentId],
  RecentDocuments: [DocumentsKey, 'RECENT'],
  TotalCountDocuments: (duration) => [
    DocumentsKey,
    'TOTAL_COUNT',
    duration.toUpperCase(),
  ],

  DocumentProjectCollaborators: (documentId) => [
    DocumentsKey,
    documentId,
    'COLLABORATORS',
  ],

  Templates: [TemplatesKey],
  Template: (templateId) => [TemplatesKey, templateId],
  Categories: [CategoriesKey],
  Questions: (templateId) => [QuestionsKey, templateId],

  Folders: ({ search, type, projectId }) => [
    FolderKey,
    projectId.toUpperCase(),
    'SEARCH',
    search.toUpperCase(),
    'TYPE',
    type.toUpperCase(),
  ],
  Library: ({ search, type, projectId, status = '' }) => [
    LibraryKey,
    projectId.toUpperCase(),
    'SEARCH',
    search.toUpperCase(),
    'TYPE',
    type.toUpperCase(),
    'STATUS',
    status.toUpperCase(),
  ],
  LibraryFolder: (folderSlug = '') => [
    LibraryKey,
    FolderKey,
    folderSlug.toUpperCase(),
  ],
};

export default keys;
