import moment from 'moment';

const DATE_FORMAT = 'DD MMM YYYY';
const LONG_DATE_FORMAT = 'dddd, MMMM Do YYYY, h:mm a';
const READABLE_DATE_FORMAT = 'MMM DD, YYYY';
const MDY_DATE_FORMAT = 'MM/DD/YYYY';
const MD_DATE_FORMAT = 'MM/DD';


export const formatDate = (date) => moment(date).format(DATE_FORMAT);
export const formatLongDate = (date) => moment(date).format(LONG_DATE_FORMAT);
export const formatReadableDate = (date) =>
  moment(date).format(READABLE_DATE_FORMAT);
export const formatmdy = (date) =>
  moment(date).format(MDY_DATE_FORMAT);
export const formatmd = (date) =>
  moment(date).format(MD_DATE_FORMAT);

export const sumDaysToDate = (days) => {
  const date = new Date();
  date.setDate(date.getDate() + parseInt(days));
  return date;
};

function join(date, options, separator) {
  function format(option) {
    const formatter = new Intl.DateTimeFormat('en', option);
    return formatter.format(date);
  }
  return options.map(format).join(separator);
}

export const formatDateToNumeric = (date) => {
  const options = [
    { day: 'numeric' },
    { month: 'numeric' },
    { year: 'numeric' },
  ];
  return join(date, options, '-');
};
