import React, { createContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import SignUpModal from 'Containers/Modal/SignInModal/SignUpModal';
import ForgotPasswordModal from 'Containers/Modal/ForgotPasswordModal/ForgotPasswordModal';

export const ModalContext = createContext();

const LandingLayout = ({ children }) => {
  const [searchParams] = useSearchParams();
  const loginParam = searchParams.get('login');
  const loginState = loginParam === 'true';

  const [loginModalShow, setLoginModalShow] = useState(loginState);
  const [modalType, setModalType] = useState('SIGN-IN');
  const [forgotPasswordModalShow, setforgotPasswordModalShow] = useState(false);

  const modalViewHandler = () => setLoginModalShow(true);

  const loginModalCloseHandler = () => {
    setLoginModalShow(false);
    setforgotPasswordModalShow(false);
  };

  const forgotPasswordModalShowHandler = () => {
    setLoginModalShow(false);
    setforgotPasswordModalShow(true);
  };
  const forgotPasswordModalCloseHandler = () => {
    setLoginModalShow(true);
    setforgotPasswordModalShow(false);
  };

  return (
    <ModalContext.Provider value={{ modalViewHandler, setModalType }}>
      {children}

      {loginModalShow && (
        <SignUpModal
          show={loginModalShow}
          modalType={modalType}
          handleClose={loginModalCloseHandler}
          handleShow={modalViewHandler}
          forgotPasswordHandleClose={forgotPasswordModalCloseHandler}
          forgotPasswordHandleShow={forgotPasswordModalShowHandler}
        />
      )}

      {forgotPasswordModalShow && (
        <ForgotPasswordModal
          show={forgotPasswordModalShow}
          handleClose={forgotPasswordModalCloseHandler}
          handleShow={forgotPasswordModalShowHandler}
        />
      )}
    </ModalContext.Provider>
  );
};

export default LandingLayout;
