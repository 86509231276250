import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import messageReducer from './slices/message';
import loaderReducer from './slices/loader';
import onboardingReducer from './slices/onboarding';
import aiProjectReducer from './slices/aiProject';
import newOnboardingReducer from './slices/newOnboarding';

const reducer = {
  auth: authReducer,
  message: messageReducer,
  loader: loaderReducer,
  onboarding: onboardingReducer,
  aiProject: aiProjectReducer,
  newOnboarding: newOnboardingReducer
};

const store = configureStore({
  reducer,
  devTools: true,
});

export default store;
