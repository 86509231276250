import React from 'react';
import { Form } from 'react-bootstrap';
import Datepicker from 'react-datepicker';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Select from 'react-select';

import Input from 'Components/Input/Input';
import TagInput from 'Components/Input/TagsInput/TagInput';
import RadioButton from 'Components/RadioButton/RadioButton';
import styles from './Questionnaire.module.scss';

function AnswerComponent({
  SideBarView,
  type,
  placeholder,
  inputValue = '',
  handleInputChange,
  title,
  handleChange,
  selectedOption,
  options,
  quesIndex,
}) {
  const getComponent = () => {
    switch (type) {
      case 'text':
        return (
          <Input
            Label={title}
            type="text"
            placeholder={placeholder}
            classList="w-100 input "
            value={inputValue}
            onChange={({ target: { value } }) =>
              handleInputChange(value, quesIndex)
            }
          />
        );

      case 'radio':
        if (SideBarView) {
          const transformedOptions = options.map((item) => {
            return { label: item, value: item };
          });
          return (
            <div
              className={styles.formCheckboxes + ' d-flex flex-column gap-2'}
              key={`${title}_${quesIndex}`}
            >
              <p className="Input_label">{title}</p>

              <Select
                style={{
                  border: '1px solid #D0D5DD',
                  color: '#667085',
                }}
                isSearchable={false}
                className={'fullWidth-Select-Questionnaire'}
                classNamePrefix={'fullWidth-Select-Questionnaire'}
                options={transformedOptions}
                onChange={(type) => {
                  handleChange(quesIndex, 'radio', type.value, 'add');
                }}
                value={transformedOptions.find((option) =>
                  selectedOption.includes(option.value),
                )}
              />
            </div>
          );
        }

        return (
          <div className={styles.formCheckboxes} key={`${title}_${quesIndex}`}>
            <p className="Input_label">{title}</p>
            {options.map((item) => {
              return (
                <RadioButton
                  type={'radio'}
                  label={item}
                  id={item}
                  name={item}
                  key={item}
                  inline="inline"
                  FullWidth={false}
                  onChange={(option, type, action) =>
                    handleChange(quesIndex, type, option, action)
                  }
                  checked={selectedOption?.includes(item)}
                  SideBarView={SideBarView}
                />
              );
            })}
          </div>
        );

      case 'textarea':
        return (
          <Input
            Label={title}
            type="textarea"
            placeholder={placeholder}
            classList="w-100 input "
            value={inputValue}
            onChange={({ target: { value } }) =>
              handleInputChange(value, quesIndex)
            }
          />
        );

      case 'number':
        return (
          <Input
            Label={title}
            type="number"
            placeholder={placeholder}
            classList="w-100 input "
            value={inputValue}
            max={2000}
            onChange={({ target: { value } }) =>
              handleInputChange(value, quesIndex)
            }
          />
        );

      case 'phonenumber':
        return (
          <div>
            <Form.Group>
              {title && (
                <Form.Label className="Input_label">{title}</Form.Label>
              )}
              <PhoneInput
                placeholder={placeholder}
                value={inputValue}
                onChange={(value) => handleInputChange(value, quesIndex)}
                className="form-control"
              />
            </Form.Group>
          </div>
        );

      case 'email':
        return (
          <Input
            Label={title}
            type="email"
            placeholder={placeholder}
            classList="w-100 input "
            value={inputValue}
            onChange={({ target: { value } }) =>
              handleInputChange(value, quesIndex)
            }
          />
        );

      case 'date':
        return (
          <div>
            <p className="Input_label form-label">{title}</p>
            <Datepicker
              className={' input form-control '}
              classNamePrefix="filter-plan-datepicker"
              dateFormat="dd/MM/yyyy"
              selected={inputValue}
              onChange={(newValue) => {
                handleInputChange(newValue, quesIndex);
              }}
              placeholderText="Select date"
              showPopperArrow={false}
            />
          </div>
        );

      case 'datetime':
        return (
          <div>
            <p className="Input_label form-label">{title}</p>
            <Datepicker
              className={' input form-control '}
              classNamePrefix="filter-plan-datepicker"
              dateFormat="dd/MM/yyyy h:mm aa"
              selected={inputValue}
              onChange={(newValue) => {
                handleInputChange(newValue, quesIndex);
              }}
              placeholderText="Select date"
              showPopperArrow={false}
              showTimeSelect
            />
          </div>
        );

      case 'tags':
        return (
          <TagInput
            label={title}
            placeholder={placeholder}
            tags={inputValue.split(',').filter((val) => !!val)}
            onChange={(value) => handleInputChange(value, quesIndex, true)}
          />
        );
      default:
        break;
    }
  };

  return <>{getComponent()}</>;
}

export default AnswerComponent;
