import React, { useState } from 'react';
import Styles from './Stepper.module.scss';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

const Stepper = ({ activeStep, setActiveStep, handleNext, handleBack }) => {
  const steps = [0, 1]; // Array representing steps

  return (
    <div className={Styles.steps}>
      <div className={Styles.steps__container}>
        {steps.map((step, index) => (
          <div
            key={index}
            className={`${Styles.steps__container__step} ${
              activeStep === index ? Styles.active : ''
            }`}
          />
        ))}
      </div>
      <div className={Styles.steps__buttons}>
        <button onClick={handleBack} disabled={activeStep === 0}>
          <IoChevronBack
            size={20}
            color={activeStep === 0 ? '#d1d5db' : '#101828'}
          />
        </button>
        <button onClick={handleNext} disabled={activeStep === steps.length - 1}>
          <IoChevronForward
            size={20}
            color={activeStep === steps.length - 1 ? '#d1d5db' : '#101828'}
          />
        </button>
      </div>
    </div>
  );
};

export default Stepper;
