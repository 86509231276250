import React from 'react';

import LandingLayout from 'layouts/LandingLayout/LandingLayout';

import Header from 'Components/Header/Header';
import { Container } from 'react-bootstrap';

const PrivacyPolicy = () => (
  <LandingLayout>
    <Header />
    <Container className="mt-5">
      <div>
        <p
          style={{
            marginTop: '0',
            marginBottom: '12pt',
            textAlign: 'center',
            fontSize: '16pt',
          }}
        >
          <h1>
            <strong>VOCABLE PRIVACY POLICY</strong>
          </h1>
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <p>Effective:&nbsp;</p>
          September 1
          <span style={{ fontSize: '8pt' }}>
            <sup>st</sup>
          </span>
          , 2023
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          This Privacy Policy (&ldquo;Policy&rdquo;) describes how Vocable AI
          Inc. (&ldquo;Vocable,&rdquo; &ldquo;we,&rdquo; &ldquo;our,&rdquo;
          &ldquo;us&rdquo;) collects, uses, and discloses certain personal
          information obtained through our website (&ldquo;Site&rdquo;), which
          is located at Vocable.ai as well as information that we process as
          part of offering you our services (collectively with the Site, the
          &ldquo;Services&rdquo;). By using our Services, you are agreeing to
          the terms of this Policy.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <p>
            <strong>What Information We Collect and Maintain About You</strong>
          </p>
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          We collect personal and other information from you directly when you
          provide it to us through the website.{' '}
          <p>
            We also automatically collect certain information about you and your
            computer, smartphone, or other device when you use, access, or
            interact with our Site.&nbsp;
          </p>
          <p>
            We may also collect information from third parties in relation to
            providing you our Services.&nbsp;
          </p>
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <strong>
            <em>Personal information collected from accountholders</em>
          </strong>
          <strong>
            <em>.&nbsp;</em>
          </strong>
          If you make an account with us in relation to our Services, we will
          collect information from you related to maintaining your account. This
          may include your name, email address and reason for engaging our
          Services. We may also collect information about you from third parties
          in relation to our Services.
          <strong>
            <em>&nbsp;</em>
          </strong>
        </p>

        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <strong>
            <em>Other personal information collected through the Site.</em>
          </strong>{' '}
          You can visit the Site without submitting any information that we can
          use to identify you personally. However, to use most features on the
          Site, you will be required to provide personal information. Such
          information could include, for example, your name, email address and
          phone number.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <strong>
            <em>Web log data.</em>
          </strong>{' '}
          When you use the Site, we automatically receive and record certain
          information from your computer (or other device) and your browser.
          This may include such data as your IP address and domain name, the
          pages you visit on the Site, the date and time of your visit, the
          files that you download, the URLs from the websites you visit before
          and after navigating to the Site, your software and hardware
          attributes (including device IDs), your general geographic location
          (e.g., your city, state, or metropolitan region), and certain cookie
          information (see below). To obtain such information, we may use web
          logs or applications that recognize your computer and gather
          information about its online activity.&nbsp;&nbsp;&nbsp;
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <strong>
            <em>Cookies.</em>
          </strong>{' '}
          We use cookies on the Site. Cookies are small files that are stored on
          your computer by your web browser. A cookie allows the Site to
          recognize whether you have visited before and may store user
          preferences and other information. For example, cookies can be used to
          collect or store information about your use of the Site during your
          current session and over time (including the pages you view and the
          files you download), your computer&rsquo;s operating system and
          browser type, your Internet service provider, your domain name and IP
          address, your general geographic location, the website that you
          visited before visiting the Site, and the link you used to leave the
          Site. If you are concerned about having cookies on your computer, you
          can set your browser to refuse all cookies or to indicate when a
          cookie is being set, allowing you to decide whether to accept it. You
          can also delete cookies from your computer. However, if you choose to
          block or delete cookies, certain features of the Site may not operate
          correctly.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <strong>
            <em>Web beacons.</em>
          </strong>{' '}
          The Site or the emails that you receive from Vocable
          <strong>&nbsp;</strong>may use an application known as a &ldquo;web
          beacon&rdquo; (also known as a &ldquo;clear gif&rdquo; or &ldquo;web
          bug&rdquo;). A web beacon is an electronic file that usually consists
          of a single-pixel image. It can be embedded in a web page or in an
          email to transmit information, which could include personal
          information. For example, it can allow an email sender to determine
          whether a user has opened a particular email.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <strong>
            <em>Third-party online tracking and behavioral advertising.</em>
          </strong>{' '}
          We may partner with certain third parties to collect, analyze, and use
          the personal and other information described in this section. For
          example, we may allow third parties to set cookies or use web beacons
          on the Site or in email communications from us. This information may
          be used for a variety of purposes, including online interest-based
          advertising, as discussed below (see the section entitled &ldquo;With
          Whom and Why We Share Your Information&rdquo;).
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          We use the information that we collect for a variety of purposes. Our
          legal bases for processing your personal information are: 1) our
          legitimate interest in running and maintaining our business; 2)
          performance and fulfillment of our contracts; 3) your consent; and 4)
          compliance with our legal obligations. In many instances, more than
          one of these legal bases apply to the processing of your personal
          information.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          The purposes for which we use your information include to:
        </p>

        <p
          style={{
            marginTop: '0',
            marginLeft: '36pt',
            marginBottom: '0',
            textIndent: '-18pt',
          }}
        >
          <span style={{ fontFamily: 'Noto Sans Symbols' }}>●</span>
          <span
            style={{
              width: '10.75pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Provide you with our Services;
        </p>
        <p
          style={{
            marginTop: '0',
            marginLeft: '36pt',
            marginBottom: '0',
            textIndent: '-18pt',
          }}
        >
          <span style={{ fontFamily: 'Noto Sans Symbols' }}>●</span>
          <span
            style={{
              width: '10.75pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Respond to your questions or requests concerning the Services;
        </p>
        <p
          style={{
            marginTop: '0',
            marginLeft: '36pt',
            marginBottom: '0',
            textIndent: '-18pt',
          }}
        >
          <span style={{ fontFamily: 'Noto Sans Symbols' }}>●</span>
          <span
            style={{
              width: '10.75pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Fulfill the terms of any agreement you have with us;
        </p>
        <p
          style={{
            marginTop: '0',
            marginLeft: '36pt',
            marginBottom: '0',
            textIndent: '-18pt',
          }}
        >
          <span style={{ fontFamily: 'Noto Sans Symbols' }}>●</span>
          <span
            style={{
              width: '10.75pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Fulfill your requests for our Services or otherwise complete a
          transaction that you initiate;
        </p>
        <p
          style={{
            marginTop: '0',
            marginLeft: '36pt',
            marginBottom: '0',
            textIndent: '-18pt',
          }}
        >
          <span style={{ fontFamily: 'Noto Sans Symbols' }}>●</span>
          <span
            style={{
              width: '10.75pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Send you information about our Services and other topics that are
          likely to be of interest to you, including newsletters, updates, or
          other communications, including promotional emails.
        </p>
        <p
          style={{
            marginTop: '0',
            marginLeft: '36pt',
            marginBottom: '0',
            textIndent: '-18pt',
          }}
        >
          <span style={{ fontFamily: 'Noto Sans Symbols' }}>●</span>
          <span
            style={{
              width: '10.75pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Deliver confirmations, account information, notifications, and similar
          operational communications;
        </p>
        <p
          style={{
            marginTop: '0',
            marginLeft: '36pt',
            marginBottom: '0',
            textIndent: '-18pt',
          }}
        >
          <span style={{ fontFamily: 'Noto Sans Symbols' }}>●</span>
          <span
            style={{
              width: '10.75pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Improve your user experience and the quality of our products and
          Services;
        </p>
        <p
          style={{
            marginTop: '0',
            marginLeft: '36pt',
            marginBottom: '0',
            textIndent: '-18pt',
          }}
        >
          <span style={{ fontFamily: 'Noto Sans Symbols' }}>●</span>
          <span
            style={{
              width: '10.75pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Comply with legal and/or regulatory requirements;
        </p>
        <p
          style={{
            marginTop: '0',
            marginLeft: '36pt',
            marginBottom: '0',
            textIndent: '-18pt',
          }}
        >
          <span style={{ fontFamily: 'Noto Sans Symbols' }}>●</span>
          <span
            style={{
              width: '10.75pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Aggregate and deidentify information;
        </p>
        <p
          style={{
            marginTop: '0',
            marginLeft: '36pt',
            marginBottom: '0',
            textIndent: '-18pt',
          }}
        >
          <span style={{ fontFamily: 'Noto Sans Symbols' }}>●</span>
          <span
            style={{
              width: '10.75pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Benchmark results for our customers;
        </p>
        <p
          style={{
            marginTop: '0',
            marginLeft: '36pt',
            marginBottom: '0',
            textIndent: '-18pt',
          }}
        >
          <span style={{ fontFamily: 'Noto Sans Symbols' }}>●</span>
          <span
            style={{
              width: '10.75pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Analyze how visitors use the Services and various Services features,
          including to count and recognize visitors to the Services;
        </p>
        <p
          style={{
            marginTop: '0',
            marginLeft: '36pt',
            marginBottom: '0',
            textIndent: '-18pt',
          }}
        >
          <span style={{ fontFamily: 'Noto Sans Symbols' }}>●</span>
          <span
            style={{
              width: '10.75pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Create new products and Services;
        </p>
        <p
          style={{
            marginTop: '0',
            marginLeft: '36pt',
            marginBottom: '12pt',
            textIndent: '-18pt',
          }}
        >
          <span style={{ fontFamily: 'Noto Sans Symbols' }}>●</span>
          <span
            style={{
              width: '10.75pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Manage our business.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          We may link information gathered through the Site with information
          that we collect in other contexts. But in that event, we will handle
          the combined information in a manner consistent with this Policy.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <p>
            <strong>With Whom and Why We Share Your Information&nbsp;</strong>
          </p>
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          We share your information with other parties for a variety of
          purposes, as described below.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <strong>
            <em>Third-party service providers.&nbsp;</em>
          </strong>
          Vocable AI uses third-party service providers that perform services on
          our behalf, including web-hosting companies, mailing vendors, payment
          processors and analytics providers. These service providers may
          collect and/or use your information, including information that
          identifies you personally, to assist us in achieving the purposes
          discussed above.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          We may share your information with other third parties when necessary
          to fulfill your requests for services; to complete a transaction that
          you initiate; to meet the terms of any agreement that you have with us
          or our partners; or to manage our business.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <strong>
            <em>Legal purposes.</em>
          </strong>
          <strong>&nbsp;</strong>We may use or share your information with third
          parties when we believe, in our sole discretion, that doing so is
          necessary:
        </p>
        <ul type="disc" style={{ margin: '0', paddingLeft: '0' }}>
          <li
            style={{
              marginLeft: '28.52pt',
              paddingLeft: '7.48pt',
              fontFamily: 'serif',
            }}
          >
            <span style={{ fontFamily: 'serif' }}>
              To comply with applicable law or a court order, subpoena, or other
              legal process;&nbsp;
            </span>
          </li>
          <li
            style={{
              marginLeft: '28.52pt',
              paddingLeft: '7.48pt',
              fontFamily: 'serif',
            }}
          >
            <span style={{ fontFamily: 'serif' }}>
              To investigate, prevent, or take action regarding illegal
              activities, suspected fraud, violations of our terms and
              conditions, or situations involving threats to our property or the
              property or physical safety of any person or third party;&nbsp;
            </span>
          </li>
          <li
            style={{
              marginLeft: '28.52pt',
              paddingLeft: '7.48pt',
              fontFamily: 'serif',
            }}
          >
            <span style={{ fontFamily: 'serif' }}>
              To establish, protect, or exercise our legal rights or defend
              against legal claims; or&nbsp;
            </span>
          </li>
          <li
            style={{
              marginLeft: '28.52pt',
              marginBottom: '12pt',
              paddingLeft: '7.48pt',
              fontFamily: 'serif',
            }}
          >
            <span style={{ fontFamily: 'serif' }}>
              To facilitate the financing, securitization, insuring, sale,
              assignment, bankruptcy, or other disposal of all or part of our
              business or assets.&nbsp;
            </span>
          </li>
        </ul>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <strong>
            <em>Aggregated and de-identified information.</em>
          </strong>{' '}
          From time to time, Vocable may share aggregated or de-identified
          information about users, such as by publishing a report on trends in
          the usage of the Site. Such aggregated information will not identify
          you personally.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <strong>Your Privacy Rights</strong>
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          If you want to learn more about the personal information that Vocable
          has about you, or you would like to update, change, or delete that
          information, please contact us by email at info@vocable.ai
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          You may opt out of receiving marketing emails from us by following the
          instructions in those emails or by emailing us at info@vocable.ai.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          If you are a resident of a jurisdiction with an applicable data
          privacy law, you may have certain rights available to you in relation
          to your personal information. These rights may include:
        </p>
        <ul type="disc" style={{ margin: '0', paddingLeft: '0' }}>
          <li
            style={{
              marginLeft: '28.52pt',
              marginBottom: '12pt',
              paddingLeft: '7.48pt',
              fontFamily: 'serif',
            }}
          >
            <span style={{ fontFamily: 'serif' }}>
              The right to be informed about our data collection practices;
            </span>
          </li>
          <li
            style={{
              marginLeft: '28.52pt',
              marginBottom: '12pt',
              paddingLeft: '7.48pt',
              fontFamily: 'serif',
            }}
          >
            <span style={{ fontFamily: 'serif' }}>
              The right to access and correct your data;
            </span>
          </li>
          <li
            style={{
              marginLeft: '28.52pt',
              marginBottom: '12pt',
              paddingLeft: '7.48pt',
              fontFamily: 'serif',
            }}
          >
            <span style={{ fontFamily: 'serif' }}>
              The right to erase or delete your data;
            </span>
          </li>
          <li
            style={{
              marginLeft: '28.52pt',
              marginBottom: '12pt',
              paddingLeft: '7.48pt',
              fontFamily: 'serif',
            }}
          >
            <span style={{ fontFamily: 'serif' }}>
              The right to data portability;
            </span>
          </li>
          <li
            style={{
              marginLeft: '28.52pt',
              marginBottom: '12pt',
              paddingLeft: '7.48pt',
              fontFamily: 'serif',
            }}
          >
            <span style={{ fontFamily: 'serif' }}>
              The right to opt out of the sale or sharing of your information;
            </span>
          </li>
          <li
            style={{
              marginLeft: '28.52pt',
              marginBottom: '12pt',
              paddingLeft: '7.48pt',
              fontFamily: 'serif',
            }}
          >
            <span style={{ fontFamily: 'serif' }}>
              The right to opt out of targeted advertising;
            </span>
          </li>
          <li
            style={{
              marginLeft: '28.52pt',
              marginBottom: '12pt',
              paddingLeft: '7.48pt',
              fontFamily: 'serif',
            }}
          >
            <span style={{ fontFamily: 'serif' }}>
              The right to opt-out of marketing emails and text messages;&nbsp;
            </span>
          </li>
          <li
            style={{
              marginLeft: '28.52pt',
              marginBottom: '12pt',
              paddingLeft: '7.48pt',
              fontFamily: 'serif',
            }}
          >
            <span style={{ fontFamily: 'serif' }}>
              The right to limit our use of any automated decision-making
              processes; and
            </span>
          </li>
          <li
            style={{
              marginLeft: '28.52pt',
              marginBottom: '12pt',
              paddingLeft: '7.48pt',
              fontFamily: 'serif',
            }}
          >
            <span style={{ fontFamily: 'serif' }}>
              The right to withdraw consent (to the extent applicable).&nbsp;
            </span>
          </li>
        </ul>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          To exercise any of the rights listed above, please contact us via
          email at{' '}
          <a href="mailto:info@vocable.ai" style={{ textDecoration: 'none' }}>
            <u>
              <span style={{ color: '#0000ff' }}>info@vocable.ai</span>
            </u>
          </a>
          . We will respond to your request as soon as reasonably possible and
          within the timeframe required under applicable law. Prior to complying
          with your request, we will first verify your identity by comparing the
          information you provide with the information we have on file for you.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          Additionally, you may authorize an agent to make a request on your
          behalf. To designate an agent, please provide a written and signed
          document by both you and the agent that authorizes the agent to act on
          your behalf. You may also use a power of attorney. We will still
          require you to provide information to allow us to reasonably verify
          that you are the person about whom we collected personal information.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          If you are a resident of California, please review our California
          Privacy Notice for additional rights that may apply to you.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <strong>External Links</strong>
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          This Site may contain links to third-party websites. If you use these
          links, you will leave the Site. We have not reviewed these third-party
          sites and do not control and are not responsible for any of these
          sites, their contents, or their privacy policies. Thus, we do not
          endorse or make any representations about them, or any information,
          software, or other products or materials found there, or any results
          that may be obtained from using them. If you decide to access any of
          the third-party sites listed on our website, you do so at your own
          risk.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <strong>Data Security</strong>
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          We employ physical, technical, and administrative procedures to
          safeguard the personal information we collect online. However, no
          website is 100% secure, and we cannot ensure or warrant the security
          of any information you transmit to the Site or to us, and you transmit
          such information at your own risk.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <strong>Data Retention</strong>
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          We retain personal information about you necessary to fulfill the
          purpose for which that information was collected or as required or
          permitted by law. We do not retain personal information longer than is
          necessary for us to achieve the purposes for which we collected it.
          When we destroy your personal information, we do so in a way that
          prevents that information from being restored or reconstructed.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <strong>International Users</strong>
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          The information that we collect through or in connection with the
          Services is transferred to and processed in the United States for the
          purposes described above. Vocable may subcontract the processing of
          your data to, or otherwise share your data with, affiliates or third
          parties in the United States or countries other than your country of
          residence. The data protection laws in these countries may be
          different from, and less stringent than, those in your country of
          residence. By using the Site or by providing any personal or other
          information to us, you expressly consent to such transfer and
          processing.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <strong>Children</strong>
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          Content on this Site is directed at individuals over the age of 18 and
          is not directed at children under the age of 13. We do not knowingly
          collect personally identifiable information from children under the
          age of 13.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <strong>Changes to this Policy</strong>
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          We<strong>&nbsp;</strong>may make changes to the Site in the future
          and as a consequence will need to revise this Policy to reflect those
          changes. We<strong>&nbsp;</strong>will post all such changes on the
          Site, so you should review this page periodically. If we make a
          material change to the Policy, you will be provided with appropriate
          notice.
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <strong>How to Contact Us</strong>
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          Should you have any questions or concerns about this Policy, you can
          contact us at info@vocable.ai
        </p>
      </div>
    </Container>
  </LandingLayout>
);

export default PrivacyPolicy;
