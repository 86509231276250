import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { FiEdit, FiLayers, FiMoreVertical, FiTrash2 } from 'react-icons/fi';

import { ROUTES } from 'constants/routes';
import Styles from './ProjectRow.module.scss';
import { formatReadableDate, formatmdy } from '../../../helpers/dates';
import CircularIcon from '../../Icons/CircularIcon';
import Collaborators from '../../Collaborators/Collaborators';
import { AvatarGroup } from 'Components/AvatarGroup/AvatarGroup';

const ProjectRow = ({
  project: {
    id,
    name,
    editors,
    total_briefs: totalBriefs,
    total_docs: totalDocs,
    start_date: startDate,
    end_date: endDate,
    content_types: contentTypes,
    campaign_goal: campaignGoal
  },
  userId,
  handleEdit,
  handleDelete,
}) => {
  const navigate = useNavigate();

  const canDelete = editors.some(
    (editor) => editor.id === userId && editor.owner,
  );

  const handlePropagation = (e) => {
    e.stopPropagation();
  };

  const navigateToProject = () => {
    navigate(ROUTES.ProjectSlug(id));
  };

  // const text = 'Lead Generation';

  return (
    <tr className={Styles.TableRow} onClick={navigateToProject}>
      <td style={{ maxWidth: '400px' }}>
        <div className={Styles.Title}>{name}</div>
      </td>
      {/* <td>
        <div className={Styles.table_other_fields}>{totalBriefs}</div>
      </td> */}
      <td className={Styles.contents}>
        <AvatarGroup
          size="sm"
          // avatars={['https://i.pravatar.cc/300', 'https://i.pravatar.cc/300', 'https://i.pravatar.cc/300', 'https://i.pravatar.cc/300']}
        avatars={contentTypes?.split(',')}
        className={Styles.customAvatarGroup}
        justifyLeft= {true}
        />
      </td>
      <td>
        <div
          className={Styles.table_other_fields}
        >{`${formatmdy(startDate)} - ${formatmdy(endDate)}`}</div>
      </td>
      <td>
        <div className={Styles.table_other_fields}>
          <div
            className={`${Styles.card__header__badge} ${campaignGoal === 'Lead Generation' ? Styles.lead_generation : campaignGoal === 'Brand Awareness' ? Styles.brand_awareness : campaignGoal === 'Engagement' ? Styles.engagement : campaignGoal === 'Sales Conversion' ? Styles.sales_conversion : Styles.engagement}`}
          >
            {campaignGoal}
          </div>
        </div>
      </td>
      <td>
        <div className={Styles.table_other_fields + ' stacked-Images-rounded'} style={{ display: 'flex' }}>
          <Collaborators editors={editors} className={Styles.avatar} />
        </div>
      </td>
      <td>
        <DropdownButton
          title={<FiMoreVertical color="#667085" size={20} />}
          onClick={handlePropagation}
        >
          <Dropdown.Item onClick={handleEdit}>
            <div className={Styles.DropdownItem}>
              <FiEdit color="#101828" size={14} />
              <p className={Styles.DropdownItem_Text}>Edit</p>
            </div>
          </Dropdown.Item>
          {canDelete && (
            <Dropdown.Item onClick={handleDelete}>
              <div className={Styles.DropdownItem}>
                <FiTrash2 color="#101828" size={14} />
                <p className={Styles.DropdownItem_Text}>Delete</p>
              </div>
            </Dropdown.Item>
          )}
        </DropdownButton>
      </td>
    </tr>
  );
};

export default ProjectRow;
