import { useQuery } from '@tanstack/react-query';
import keys from 'constants/queryKeys';
import { getFolders } from 'services/folderService';

const useFolders = ({ type, search, projectId, enabled }) => {
  const query = useQuery({
    enabled,
    queryKey: keys.Folders({ search, type, projectId }),
    queryFn: () => getFolders({ type, search, projectId }),
  });

  return {
    ...query,
    ...query?.data?.data,
  };
};

export default useFolders;
