import React from 'react';
import Styles from './UrlForm.module.scss';
import { ErrorMessage, Formik } from 'formik';
import { Form } from 'react-bootstrap';
import FormikTextField from 'Components/Form/FormikTextField';
import BSButton from 'Components/Button/BSButton';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';


const UrlForm = ({ setBrandSummary, noButtons, setLoading, setUrl, setActiveTab, setCancelClicked }) => {
  const { user } = useSelector((state) => state.auth);
  const brandCount = user?.user_brands?.brands_count;

  const getValidationSchema = () => {
    return Yup.object().shape({
      url: Yup.string()
        .trim()
        .required('Website is required')
        .url('Invalid website'),
    });
  };

  const handleSubmit = (values)=>{
    setUrl(values.url)
    setBrandSummary(true);
  }

  const handleShowBrands = ()=>{
    setCancelClicked(true)
    setBrandSummary(true);
  }

 
  return (
    <>
      <Formik
        initialValues={{ url: '' }} 
        validationSchema={getValidationSchema()}
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={handleSubmit}

      >
        {({ values, setFieldValue, isValid, dirty, submitForm }) => {
          const handleContinueURL = () => {
            submitForm();  
           };
         

          return (
            <div className={Styles.form_container}>
              <Form
                className={Styles.Container}
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div>
                  <FormikTextField
                    name="url"
                    type="string"
                    placeholder="Enter URL here"
                    Label="Enter URL"
                    classList="input"
                    isRequired
                  />
                  <span className={Styles.helper_text}>
                    Enter a valid, text-heavy site URL so Vocable can scan it
                    and detect your voice.
                  </span>
                  <ErrorMessage
                    name="url"
                    component="div"
                    className="error mt-2"
                  />
                </div>
              </Form>
              {!noButtons && (
                <div className={`d-flex justify-content-end gap-2 mt-4`}>
                  <BSButton
                    classList={`cancel_btn`}
                    variant=""
                    ButtonText="Cancel"
                    // onClick={()=>setBrandSummary(false)}
                    onClick={()=>setActiveTab(null)}

                  />
                  {/* {brandCount > 0 && <BSButton
                    classList={`cancel_btn`}
                    variant=""
                    ButtonText="My Brands"
                    onClick={handleShowBrands}

                  />} */}
                  <BSButton
                    classList={`secondary_btn`}
                    variant=""
                    ButtonText="Continue"
                    type="button"
                    onClick={handleContinueURL}
                  />
                </div>
              )}
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default UrlForm;
