import React, { useState, useEffect, useRef } from 'react';
import KanbanList from 'Containers/KanbanList/KanbanList';
import useBriefs from 'hooks/briefs/useBriefs';
import {
  deleteTask,
  updateTask,
  updateDashboardConfig,
  getDashboardConfig,
} from 'services/contentPlannerService';
import { archiveTask } from 'services/taskService';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import SectionHeader from './SectionHeader';
import { FiFileText } from 'react-icons/fi';
import Styles from './Dashboard.module.scss';
import { setLoader } from 'redux/slices/loader';
import MoveToStateModal from 'Containers/Modal/MoveToStateModal/MoveToStateModal';
import BriefDrawer from 'Components/Drawers/BriefDrawer/BriefDrawer';
import { formattedDate } from 'helpers';
import ConfirmDeleteModal from 'Containers/Modal/ConfirmDeleteModal/ConfirmDeleteModal';
import EmptyDashboardState from './EmptyDashboardState';
import { TableSkeleton } from 'Components/TableSkeleton/TableSkeleton';
import { generateStreamResponse } from 'helpers/openAIChat';
import { error as errorToast } from 'helpers/toaster';
import useCreateDocument from 'hooks/documents/useCreateDocument';
import ContentGenerationModal from 'Containers/Modal/ContentGenerationModal/ContentGenerationModal';

const BriefSection = ({ onCreateNewBrief, refetchBriefsCount }) => {
  const [editTaskModalView, setEditTaskModalView] = useState(false);
  const [deleteTaskModalView, setDeleteTaskModalView] = useState(false);
  const [moveToStateModalView, setMoveToStateModalView] = useState(false);
  const [taskIdToDelete, setTaskIdToDelete] = useState(null);
  const [taskIdToEdit, setTaskIdToEdit] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [taskCount, setTaskCount] = useState([]);
  const [taskInfo, setTaskInfo] = useState({
    taskId: null,
    taskName: null,
    projectId: null,
  });
  const [showContentGeneration, setShowContentGeneration] = useState(false);
  const [streamContent, setStreamContent] = useState('');
  const [contentGenerated, setContentGenerated] = useState(false);
  const abortController = useRef();
  const { user } = useSelector((state) => state.auth);
  const { id: userId } = user || {};
  const { mutateAsync: createDocument } = useCreateDocument({
    userId,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: briefsPages,
    refetch,
    isLoading,
  } = useBriefs({
    searchValue: '',
    filterStr: '',
  });

  useEffect(() => {
    fetchColumnConfiguration();
  }, []);

  useEffect(() => {
    if (contentGenerated) {
      handleContentGenerated();
    }
  }, [contentGenerated]);

  const updateTaskInTasksArray = (taskId, updatedTask) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.id === taskId ? { ...task, ...updatedTask } : task,
      ),
    );
  };

  const onUpdateBrief = (id, brief) => {
    const index = tasks.findIndex((task) => task.id === id);
    if (index !== -1) {
      updateTaskInTasksArray(id, brief);
    }
    refetchBriefsCount();
  };

  useEffect(() => {
    if (!briefsPages || !briefsPages.pages) {
      setTasks([]);
      setTaskCount(0);
      return;
    }

    const tasks = briefsPages.pages
      .flatMap((page) => page?.data?.tasks)
      .filter((task) => !!task);
    const taskCount = briefsPages.pages[0]?.data?.meta_data?.count || 0;

    setTasks(tasks);
    setTaskCount(taskCount);
  }, [briefsPages?.pages]);

  const updateColumns = async (columns) => {
    try {
      setSelectedColumns(columns);
      await updateDashboardConfig(columns);
      renderSuccess('Updated column configuration');
    } catch (e) {
      renderError('Cannot save column configuration');
    }
  };

  const fetchColumnConfiguration = async () => {
    try {
      const response = await getDashboardConfig();
      setSelectedColumns(response.data);
    } catch (e) {
      renderError('Unable to fetch dashboard configurations');
    }
  };

  const onDeleteHandler = (id) => {
    setDeleteTaskModalView(true);
    setTaskIdToDelete(id);
  };

  const onConfirmDeleteHandler = async () => {
    try {
      const response = await deleteTask(taskIdToDelete);
      tasks?.filter((task) => {
        return task.id !== response?.data?.task.id;
      });

      if (editTaskModalView) {
        setEditTaskModalView(false);
      }

      refetch();
      refetchBriefsCount();
      renderSuccess(response?.data?.message);
    } catch (error) {
      renderError(error);
    }
  };

  const onMoveHandler = (id) => {
    setMoveToStateModalView(true);
    setTaskIdToEdit(id);
  };

  const onEditHandler = (id) => {
    setEditTaskModalView(true);
    setTaskIdToEdit(id);
  };

  const onSubmitHandler = async (action, payload) => {
    await onConfirmEditHandler(payload);
  };

  const onArchive = async (id) => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Archiving Brief!',
        description: 'Please wait...',
      }),
    );
    try {
      const response = await archiveTask(id);
      refetch();
      refetchBriefsCount();
      renderSuccess(response?.data?.message);
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    } catch (error) {
      refetch();
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
      renderError(error);
    }
  };

  const onConfirmEditHandler = async (payload) => {
    try {
      const { project_id: projectId, ...remainingPayload } = payload;
      const response = await updateTask(taskIdToEdit, {
        task: {
          deadline_date: formattedDate(payload.deadline_date),
          ...remainingPayload,
        },
        project_id: projectId,
      });

      tasks?.map((task) => {
        if (task.id === taskIdToEdit) {
          return { ...task, ...response?.data?.task };
        }
        return task;
      });

      refetch();
      refetchBriefsCount();
      renderSuccess(response?.data?.message);
    } catch (error) {
      renderError(error);
    }
  };

  const handleAICreate = async (taskId, projectId, taskName) => {
    setTaskInfo({ taskId, taskName, projectId });

    abortController.current = new AbortController();
    setStreamContent('');
    setContentGenerated(false);
    setShowContentGeneration(true);

    try {
      const URL = `${process.env.REACT_APP_URL}/api/v1/completion_stream_data_style_guides`;
      const BODY = JSON.stringify({
        project_id: projectId,
        task_id: taskId,
      });

      await generateStreamResponse(
        URL,
        BODY,
        setStreamContent,
        setContentGenerated,
        abortController?.current.signal,
      );
    } catch (error) {
      errorToast(error);
    }
  };

  const handleContentGenerated = async () => {
    const taskName = taskInfo?.taskName;
    const taskId = taskInfo?.taskId;
    const projectId = taskInfo?.projectId;
    await createDocument(
      {
        document: {
          title: taskName,
          user_id: userId,
          content: streamContent,
        },
        taskId,
        projectId,
      },
      {
        onSuccess: (documentResponse) => {
          const documentId = documentResponse?.data?.document?.id;
          if (!documentId) return;

          navigate(ROUTES.FreestyleDocumentTemplate(documentId));
        },
      },
    );
  };

  return (
    <>
      {' '}
      <SectionHeader
        icon={<FiFileText size={20} color="#00A7B7" />}
        name="Content Plan"
        route={ROUTES.PLANNER}
        count={taskCount ?? 0}
        view="View Full Plan"
        hideLink={(taskCount ?? 0) < 1}
      />
      {isLoading ? (
        <table className="w-100">
          <TableSkeleton count={5} />
        </table>
      ) : tasks && tasks.length > 0 ? (
        <div className={Styles.Dashboard__table}>
          <KanbanList
            tasks={tasks.slice(0, 5) || []}
            onDelete={onDeleteHandler}
            onMove={onMoveHandler}
            onEdit={onEditHandler}
            fetchCpTasks={refetch}
            onArchive={onArchive}
            onUpdateBrief={onUpdateBrief}
            selectedColumns={selectedColumns}
            updateColumns={updateColumns}
            handleAICreate={handleAICreate}
          />
        </div>
      ) : (
        <EmptyDashboardState
          title="No Content Plan?"
          description="Map out a full content marketing plan with ongoing posts ideas for your brand"
          buttonText="Generate your full Content Plan"
          backgroundImageUrl="/Images/DashboardEmptyBriefsState.svg"
          onClick={() => navigate('/new-plan')}
        />
      )}
      {deleteTaskModalView && (
        <ConfirmDeleteModal
          show={deleteTaskModalView}
          onHide={() => {
            setDeleteTaskModalView(false);
            setTaskIdToDelete(null);
          }}
          onDelete={onConfirmDeleteHandler}
        />
      )}
      {editTaskModalView && (
        <BriefDrawer
          action="edit"
          show={editTaskModalView}
          onHide={() => {
            setEditTaskModalView(false);
            setTaskIdToEdit(null);
          }}
          onSubmit={onSubmitHandler}
          onDeleteHandler={onDeleteHandler}
          task={tasks?.find((task) => {
            return task.id === taskIdToEdit;
          })}
          isProjectView={false}
        />
      )}
      {moveToStateModalView && (
        <MoveToStateModal
          show={moveToStateModalView}
          onHide={() => {
            setMoveToStateModalView(false);
            setTaskIdToEdit(null);
          }}
          onMove={onConfirmEditHandler}
          task={tasks?.find((task) => {
            return task.id === taskIdToEdit;
          })}
        />
      )}
      <ContentGenerationModal
        onHide={() => {
          setShowContentGeneration(false);
          setStreamContent('');
          setContentGenerated(false);
          abortController?.current.abort();
        }}
        show={showContentGeneration}
        string={streamContent}
        contentGenerated={contentGenerated}
      />
    </>
  );
};

export default BriefSection;
