import React, { useMemo } from 'react';
import Select from 'react-select';
import Datepicker from 'react-datepicker';
import { useSelector } from 'react-redux';

import Styles from './ContentPlanner.module.scss';
import {
  getContentTypeFilterOptions,
  getStatusFilterOptions,
  optionsClassName,
} from 'helpers/optionsBriefsFilters';
import useProjectUsers from 'hooks/projects/useProjectUsers';

const SelectStatusStyles = {
  option: (styles, { isSelected }) => {
    return {
      boxShadow: isSelected
        ? 'box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;'
        : null,
      padding: '4px',
      '&:hover': {
        backgroundColor: 'lightgrey',
        cursor: 'pointer',
      },
    };
  },

  control: (baseStyles) => {
    return {
      ...baseStyles,
      cursor: 'pointer',
    };
  },
};

function ContentFilter({
  filterBy,
  filterValue,
  handleFilters,
  index,
  isOptions,
  project,
}) {
  const { user } = useSelector((state) => state.auth);

  const { data: projectUsers } = useProjectUsers({ project });

  const users = useMemo(() => {
    if (project) {
      return (projectUsers?.data?.project_users || []).map(({ user }) => user);
    } else {
      return [user];
    }
  }, [project, projectUsers, user]);

  const userOptions = useMemo(() => {
    if (!users?.length) {
      return [];
    }

    return users.map((user) => {
      return {
        value: user.id,
        label: (
          <div className={optionsClassName}>
            <input
              type="checkbox"
              className="select-options form-check-input "
              checked={
                Array.isArray(filterValue) &&
                filterValue.some(
                  (filterVal) => Number(filterVal.value) === Number(user.id),
                )
              }
            />
            <label>{user.name}</label>
          </div>
        ),
      };
    });
  }, [users, filterValue]);

  const filterVal =
    (Array.isArray(filterValue) &&
      filterValue.find(
        (option) => option.value === (filterValue || [])[0]?.value,
      )) ||
    {};

  const transformedFilterValue = {
    ...filterVal,
    label: (
      <div className="d-flex justify-content-between ">
        {filterVal.label}
        {Array.isArray(filterValue) && filterValue.length > 1 ? (
          <span
            className={
              'd-flex justify-content-center  align-items-center ' +
              Styles.Count
            }
          >
            +{filterValue.length - 1}
          </span>
        ) : (
          ''
        )}
      </div>
    ),
  };

  const onChangeSelect = ({ value, label }) => {
    if (
      Array.isArray(filterValue) &&
      filterValue.some((filterVal) => filterVal.value === value)
    ) {
      const trStatus =
        Array.isArray(filterValue) &&
        filterValue.filter((val) => val.value !== value);
      handleFilters(index, trStatus, 'filterValue');
    } else {
      handleFilters(
        index,
        [...(filterValue || []), { value, label }],
        'filterValue',
      );
    }
  };

  const getFilterByComp = () => {
    if (!(filterBy && isOptions)) {
      return null;
    }

    const commonPropsSelect = {
      isSearchable: false,
      className: 'filter-Select',
      classNamePrefix: 'filter-Select',
      styles: SelectStatusStyles,
      onChange: onChangeSelect,
      value: transformedFilterValue,
    };

    switch (filterBy) {
      case 'state':
        return (
          <Select
            {...commonPropsSelect}
            placeholder="Status"
            options={getStatusFilterOptions(filterValue)}
          />
        );
      case 'content_type':
        return (
          <Select
            {...commonPropsSelect}
            placeholder="Content type"
            options={getContentTypeFilterOptions(filterValue)}
          />
        );

      case 'deadline_date':
        return (
          <Datepicker
            className={' input form-control '}
            classNamePrefix="filter-plan-datepicker"
            wrapperClassName={Styles.filterDatePicker}
            dateFormat="dd/MM/yyyy"
            selected={filterValue}
            onChange={(newValue) =>
              handleFilters(index, newValue, 'filterValue')
            }
            placeholderText="Select date"
            showPopperArrow={false}
          />
        );

      case 'assignee_id':
        return (
          <Select
            {...commonPropsSelect}
            placeholder="Assigned To"
            options={userOptions}
          />
        );
      default:
        return null;
    }
  };

  return <>{getFilterByComp()}</>;
}

export default ContentFilter;
