import Styles from 'Containers/Content Planner/ContentPlanner.module.scss';
import Badge from 'Components/Badge/Badge';
import React from 'react';
import { CONTENT_TYPE_OPTIONS } from 'constants/contentTypes';
import { StatusOptions } from 'Components/Status/StatusOption';
import { mapPriorityIcon } from 'constants/statusPriority';
import { titleize } from './index';

export const optionsClassName =
  'd-flex align-items-center select-option ' + Styles.SelectLabel;

export const getStatusFilterOptions = (filterValue) => {
  return StatusOptions.map(({ value }) => ({
    value,
    label: (
      <div className={optionsClassName}>
        <input
          type="checkbox"
          className="select-options form-check-input "
          defaultChecked={
            Array.isArray(filterValue) &&
            filterValue.some((filterVal) => filterVal.value === value)
          }
        />
        <Badge status={value} />
      </div>
    ),
  }));
};

export const getPriorityFilterOptions = (filterValue) => {
  return Object.keys(mapPriorityIcon).map((value) => ({
    value,
    label: (
      <div className={optionsClassName}>
        <input
          type="checkbox"
          className="select-options form-check-input "
          defaultChecked={
            Array.isArray(filterValue) &&
            filterValue.some((filterVal) => filterVal.value === value)
          }
        />
        <div
          className={`${optionsClassName} px-2 badge-wrapper ${titleize(
            value,
          )}Badge`}
        >
          {mapPriorityIcon[value]}
          <label>{titleize(value)}</label>
        </div>
      </div>
    ),
  }));
};

export const getContentTypeFilterOptions = (filterValue) => {
  return CONTENT_TYPE_OPTIONS.map(({ label, value }) => ({
    value,
    label: (
      <div className={optionsClassName}>
        <input
          type="checkbox"
          className="select-options form-check-input "
          defaultChecked={
            Array.isArray(filterValue) &&
            filterValue.some((filterVal) => filterVal.value === value)
          }
        />
        <div className={optionsClassName}>
          <label>{label}</label>
        </div>
      </div>
    ),
  }));
};
