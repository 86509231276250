import sc from './Subscription.module.scss';
import React from 'react';

const PaymentCard = ({
  card,
  setSelectedCard,
  checked,
  handleRemove,
  selectedCard,
}) => {
  const { card: cardData, id } = card || {};
  const { brand, exp_month, exp_year, last4 } = cardData || {};
  return (
    <>
      {
        <div className={`${sc.paymentCard} ${checked && sc.active}`}>
          <div
            className={`${sc.checkIconWrapper}`}
            onClick={() =>
              selectedCard === id ? () => {} : setSelectedCard(card)
            }
          >
            <img src="/Images/checkbox.svg" alt="check" />
          </div>
          <div className={sc.cardTypeImgWrapper}>
            <img
              src={
                brand === 'visa'
                  ? '/Images/visacardIcon.svg'
                  : '/Images/mastercardIcon.svg'
              }
              alt="card Icon"
            />
          </div>
          <div className={sc.cardDetailWrapper}>
            <div className={sc.cardDetailBox}>
              <span className={`${sc.cardTypeText} ${sc.visaText}`}>
                <span>{brand}</span> ending in {last4}
              </span>
              <span className={`${sc.expiryDate} ${sc.dateExpired}`}>
                Expiry {`${exp_month}/${exp_year}`}
              </span>
            </div>
            <div className={sc.cardActionsWrapper}>
              {selectedCard !== id && (
                <button
                  className={`${sc.cardBtn} ${sc.removeBtn}`}
                  onClick={() => handleRemove(card.id)}
                >
                  Remove
                </button>
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PaymentCard;
