import React from 'react';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from 'redux/store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';

import 'react-tooltip/dist/react-tooltip.css';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const reactPlugin = new ReactPlugin();

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_API_KEY}>
        <ToastContainer />
        <App />
      </GoogleOAuthProvider>
    </BrowserRouter>
  </Provider>,
);


try {
  const connectionString = process.env.REACT_APP_AZURE_MONITOR_CONNECTION_STRING;

  if (!connectionString) {
    console.warn('Azure Monitor connection string is missing. Telemetry will not be sent.');
  } else {
    const appInsights = new ApplicationInsights({
      config: {
        connectionString,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
      },
    });

    appInsights.loadAppInsights();
    console.log('Application Insights initialized successfully.');
  }
} catch (error) {
  console.error('Failed to initialize Application Insights:', error);
}
