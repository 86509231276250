import React from 'react';
import { CgSpinner } from 'react-icons/cg';
import Styles from './Spinner.module.scss';

const Spinner = ({ text, className }) => {
  return (
    <div className={`${Styles.SpinnerContainer} ${className}`}>
      <CgSpinner size={40} color={'#00A7B7'} />
      {text && <p className={Styles.SpinnerText}>{text}</p>}
    </div>
  );
};

export default Spinner;
