import React from 'react';
import Styles from './TrialFeatures.module.scss';
import { ReactComponent as CheckIcon } from '../../../assets/svg/featurecheck.svg';

const TrialFeatures = ({ features }) => {
  return (
    <div className={Styles.TrialFeatures_container}>
      <div className={Styles.TrialFeatures_container__heading}> FEATURES</div>
      <ul className={Styles.TrialFeatures_container__list}>
        {features?.map((item, index) => (
          <li
            key={index}
            className={Styles.TrialFeatures_container__list__item}
          >
            <div className={Styles.TrialFeatures_container__list__item__icon}>
              <CheckIcon />
            </div>
            <span className={Styles.TrialFeatures_container__list__item__text}>
              {item}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TrialFeatures;
