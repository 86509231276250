import React, { useState, useEffect } from 'react';
import ImageView from 'Components/Image/ImageView';
import { getNameInitials, getUserImage, humanize, titleize } from 'helpers';
import { FiCalendar, FiTag, FiUserPlus } from 'react-icons/fi';
import CardStyles from './KanbanCard.module.scss';
import KanbanDropDown from './KanbanDropDown';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import NewDocumentModal from 'Components/Modals/NewDocumentModal/NewDocumentModal';
import { ReactComponent as Other } from '../../../src/assets/svg/Group.svg';
import { CONTENT_TYPE_OPTIONS } from 'constants/contentTypes';
import moment from 'moment';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';
import { useSelector } from 'react-redux';

const getCardClass = (status) => {
  if (status === 'not_started') {
    return 'Card__NotStarted';
  }

  if (status === 'in_progress') {
    return 'Card__Inprogress';
  }

  if (status === 'in_review') {
    return 'Card__Inreview';
  }

  if (status === 'published') {
    return 'Card__Published';
  }

  if (status === 'approved') {
    return 'Card__Approved';
  }
};

const KanbanCard = ({
  status,
  title,
  id,
  document,
  onDelete,
  onMove,
  onEdit,
  deadline,
  priority,
  assignedToImage,
  assignedTo,
  onDragStart,
  project_id,
  access = '',
  fetchCPTasks,
  state,
  onArchive,
  description,
  content_type,
  other_content_type,
  handleAICreate,
}) => {
  const { template_id, id: documentId } = document || {};
  const [NewDocumentModalView, setNewDocumentModalView] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { id: userId, subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  const navigate = useNavigate();

  const handleDraftLink = () => {
    if (template_id) {
      navigate(ROUTES.EditorDocumentTemplate(documentId, template_id));
    } else {
      navigate(ROUTES.FreestyleDocumentTemplate(documentId));
    }
  };

  const onHideModal = (refetchTasks = false) => {
    refetchTasks && fetchCPTasks();
    setNewDocumentModalView(false);
  };

  const findIcon = (content_type) => {
    const option = CONTENT_TYPE_OPTIONS.find(
      (opt) => opt.value === content_type,
    );
    return option?.icon || <Other />;
  };
  const icon = findIcon(content_type);

  return (
    <section
      className={`${CardStyles.Card}  ${getCardClass(status)}`}
      onDragStart={(e) => onDragStart(e, id, project_id, documentId)}
      style={{ cursor: 'pointer' }}
      draggable
    >
      <ul className="">
        <li
          className={'d-flex flex-column ' + CardStyles.Card__CardWrapper}
          onClick={currentSubscription?.plan_name === "Basic Plan" && project_id !== null ? ()=>setShowUpgradeModal(true) : () => onEdit(id)}

        >
          <div className="d-flex justify-content-between align-items-start">
            {!!content_type && (
              <div
                className="d-flex justify-content-between align-items-start"
                onClick={() => onEdit(id)}
              >
                <div
                  className={
                    'd-flex align-items-center g-10 ' +
                    CardStyles.Card__CardWrapper_BottomSection
                  }
                >
                  {icon}

                  <span className="text-md-Regular">
                    {content_type === 'other'
                      ? humanize(other_content_type)
                      : humanize(content_type || '')}
                  </span>
                </div>
              </div>
            )}
            {/* Proirity hidden */}

            {/* <div className={`badge-wrapper ${titleize(priority)}Badge`}>
              {mapPriorityIcon[priority]}
              <span>{titleize(priority)}</span>
            </div> */}
          </div>
          <div className="d-flex justify-content-between align-items-start">
            <p className={CardStyles.Card__Title}>{title}</p>
          </div>
          <div
            className={
              'd-flex align-items-center g-10 ' +
              CardStyles.Card__CardWrapper_BottomSection
            }
          >
            {deadline && (
              <>
                <FiCalendar color="#475467" />
                <span className="text-md-Regular">
                  {moment(deadline).format('MM/DD/YYYY')}
                </span>
              </>
            )}
          </div>
        </li>
        <div className={CardStyles.card_divider}></div>
        <li
          className={
            'd-flex flex-column border-0 ' +
            CardStyles.Card__CardWrapper__footer
          }
        >
          <div className="d-flex justify-content-between">
            <div className={CardStyles.card_footer_name_section}>
              {!assignedTo ? (
                <div className={CardStyles.card_footer_name_section__avatar}>
                  <FiUserPlus size={24} />
                </div>
              ) : assignedToImage ? (
                <div className={CardStyles.card_footer_name_section__image}>
                  <ImageView
                    className={CardStyles.card_footer_name_section__image__img}
                    src={assignedToImage}
                  />
                </div>
              ) : (
                <div
                  className={CardStyles.card_footer_name_section__userInitials}
                >
                  {getNameInitials(assignedTo)}
                </div>
              )}
              <p className={CardStyles.card_footer_name_section__name}>
                {assignedTo ?? 'Unassigned'}
              </p>
            </div>
            <div className="d-flex gap-2 align-items-center">
              {documentId ? (
                <div
                  className={
                    'd-flex align-items-center profile-section copy-link '
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDraftLink();
                  }}
                >
                  {/* <Link size={10} color="#6941C6" /> */}
                  <p className={CardStyles.link_card_draft}>Open Draft</p>
                </div>
              ) : (
                <div
                  className={
                    'd-flex align-items-center profile-section copy-link '
                  }
                  style={{
                    gap: '3px',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    currentSubscription?.plan_name === "Basic Plan" && project_id !== null ? setShowUpgradeModal(true) : handleAICreate(id, project_id, title);

                    // currentSubscription?.plan_name === "Basic Plan" && location.pathname.startsWith('/projects') ? setShowUpgradeModal(true) : handleAICreate(id, project_id, title);
                  }}
                >
                  {/* <Link size={10} color="#6941C6" /> */}
                  <span className={CardStyles.link_card_draft}>
                    Create Draft
                  </span>
                </div>
              )}
              <span onClick={(e) => e.stopPropagation()}>
                <KanbanDropDown
                  id={id}
                  onDelete={onDelete}
                  onMove={onMove}
                  onEdit={currentSubscription?.plan_name === "Basic Plan" && project_id !== null ? ()=>setShowUpgradeModal(true) : onEdit}
                  access={access}
                  state={state}
                  onArchive={onArchive}
                  threedots={CardStyles.DropRotate}
                />
              </span>
            </div>
          </div>
        </li>
      </ul>
      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
          message={"Updating campaign related content is a pro feature. Please update to pro plan to access this feature."}

        />
      )}

      <NewDocumentModal
        show={NewDocumentModalView}
        onHide={(refetchTasks) => onHideModal(refetchTasks)}
        taskId={id}
        taskName={title}
        projectId={project_id}
        aiPowered
      />
    </section>
  );
};

export default KanbanCard;
