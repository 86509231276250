import FormikDateField from 'Components/Form/FormikDateField';
import FormikTimeField from 'Components/Form/FormikTimeField';
import { Formik } from 'formik';
import React from 'react';
import Styles from './ScheduleForm.module.scss';
import { Form } from 'react-bootstrap';
import BSButton from 'Components/Button/BSButton';

const ScheduleForm = () => {
  return (
    <Formik
    //   initialValues={initialValues}
    //   validationSchema={briefCreateSchema}
    //   validateOnMount={true}
    //   enableReinitialize={true}
    >
      {({ values, setFieldValue, isValid, dirty }) => {
        const {
          content_type,
          state,
          keywords,
          priority,
          assignee_id,
          project_id,
          document_id,
        } = values || {};

        return (
          <div className="">
            <Form
              className={Styles.Container}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className={Styles.fullWidth}>
                <FormikDateField
                  name="deadline_date"
                  label="Select Date"
                  isRequired
                />
              </div>
              <div className={Styles.fullWidth}>
                <FormikTimeField
                  name="deadline_date"
                  label="Select Time"
                  placeholder="--:-- AM"
                  isRequired
                />
              </div>
              <div className={Styles.btn_container}>
                <BSButton
                  classList={`button cancel_btn`}
                  variant="dark"
                  ButtonText="Cancel"
                />
                <BSButton
                  classList={`btn button secondary_btn`}
                  variant="dark"
                  ButtonText="Done"
                />
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default ScheduleForm;
