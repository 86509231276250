import { React, useState, useRef, useEffect } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import DropDownList from './DropDownList/DropDownList';
import styleClasses from './DropDown.module.scss';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

const CustomDropDown = ({ title, expandedViewEnabled, content, className }) => {
  const [icon, setIcon] = useState(true);
  const wrapperRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIcon(true);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const TitleComponent = (
    <span className={className}>
      {title}
      {icon ? (
        <BsChevronDown className="ms-2" />
      ) : (
        <BsChevronUp className="ms-2" />
      )}
    </span>
  );

  const setIconHandler = () => {
    setIcon(!icon);
  };

  return (
    <div onClick={setIconHandler}>
      <NavDropdown
        title={TitleComponent}
        className={styleClasses.dropdown_toggle_}
        id="collasible-nav-dropdown"
        ref={wrapperRef}
      >
        <DropDownList
          expandedViewEnabled={expandedViewEnabled}
          listItems={content}
        />
      </NavDropdown>
    </div>
  );
};

export default CustomDropDown;
