import React from 'react';
import Styles from './Style.module.scss';
import VocableLogo from '../../assets/login/vocable..svg';
import { ErrorMessage, Formik } from 'formik';
import { Form } from 'react-bootstrap';
import BSButton from 'Components/Button/BSButton';
import FormikTextField from 'Components/Form/FormikTextField';
import { HiOutlineEnvelope } from 'react-icons/hi2';
import { GoLock } from 'react-icons/go';
import { IoEyeOutline } from 'react-icons/io5';
import { IoEyeOffSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import _Button1 from '../../assets/login/_Button.svg';
import Google from '../../assets/login/google.svg';
import _Button2 from '../../assets/login/_Button (1).svg';
import _Button3 from '../../assets/login/_Button (2).svg';
import _Button4 from '../../assets/login/_Button (3).svg';
import { AvatarGroup } from 'Components/AvatarGroup/AvatarGroup';
import { FaStar } from 'react-icons/fa6';
import Stepper from 'Components/Stepper/Stepper';
import Ticker from 'Components/Ticker/Ticker';
import { useNavigate } from 'react-router-dom';
import { EMAIL_REGEX, PASSWORD_FORMAT } from 'constants';
import * as Yup from 'yup';
import PasswordValidationError from 'Components/Password/Error';
import { useDispatch } from 'react-redux';
import { login, register, socialLogin } from 'redux/slices/auth';
import { ROUTES } from '../../constants/routes';
import { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { SIGNUP_MODAL_HEADING } from 'constants';

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [emailSend, setEmailSend] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const steps = [0, 1, 2, 3]; // Array representing steps
  const [activeStep, setActiveStep] = useState(0); // Start with the first step

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const initialValues = {
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    confirm_password: '',
    company_name: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .matches(EMAIL_REGEX, 'Invalid email')
      .required('Email is required.'),
    password: Yup.string()
      .required('Password is required.')
      .min(8, 'Password must be at least 8 characters.')
      .max(18, 'Password must be at most 18 characters.'),
    first_name: Yup.string().trim().required('First name is required.'),
    // last_name: Yup.string().trim().required('Last name is required.'),
    company_name: Yup.string().trim().required('Company name is required.'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match.')
      .matches(PASSWORD_FORMAT, {
        message: PasswordValidationError(),
      })
      .required('Confirm password is required'),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const {
      email,
      password,
      confirm_password,
      first_name,
      last_name,
      company_name,
    } = values || {};
    const body = {
      user: {
        email,
        first_name,
        last_name,
        password,
        password_confirmation: confirm_password,
        company_name,
      },
    };

    // How can I clear query params here?

    const response = await dispatch(register(body));
    const { payload = {} } = response;

    if (payload.confirmation_required) {
      // handleClose();
      resetForm();
      handleSendEmail();
    }
    if (payload.error) {
      // handleClose();
      resetForm();
      setIsError(true);
    }
    if (payload.user?.sign_in_count < 2) {
      // navigate(ROUTES.OnboardingInfo);
    }
  };

  const handleSendEmail = () => {
    setEmailSend(true);
  };

  const handleGoogleLoginSuccess = async (response) => {
    const responseData = await dispatch(
      socialLogin(
        { googleAccessToken: response.access_token },
        SIGNUP_MODAL_HEADING,
      ),
    );
    const { payload = {} } = responseData || {};
    if (payload.user?.sign_in_count < 2) {
      // navigate(ROUTES.OnboardingInfo);
    }
  };

  const handleGoogleLoginFailure = () => {
    renderError('Login failed, please contact with support!');
  };

  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginFailure,
  });

  const handleClick = (values, resetForm) => {
    handleSubmit(values, { resetForm });
  };

  return (
    <div className={Styles.OuterContainer}>
      <div className={Styles.OuterContainer__left}>
        <img
          src={VocableLogo}
          alt="logo"
          onClick={() => navigate('/')}
          style={{ cursor: 'pointer' }}
        />
        <div className={Styles.OuterContainer__left__form_container}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
          >
            {({ values, setFieldValue, isValid, dirty, resetForm }) => {
              const {
                content_type,
                state,
                keywords,
                priority,
                assignee_id,
                project_id,
                document_id,
              } = values || {};

              return (
                <>
                  <div
                    className={
                      Styles.OuterContainer__left__form_container__welcome
                    }
                  >
                    <p>Welcome</p>
                    <span>Sign Up and explore Vocable.ai</span>
                  </div>
                  <Form
                    className={Styles.formik_form}
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {emailSend || isError ? (
                      <div className={Styles.email_send_box}>
                        <div className={Styles.email_send_box__inner_box}>
                          <div
                            className={Styles.email_send_box__inner_box__top}
                          >
                            {emailSend ? 'Verify Email' : 'Email Already Taken'}
                          </div>
                          <div
                            className={Styles.email_send_box__inner_box__bottom}
                          >
                            {emailSend
                              ? 'Signed up successfully, please verify your email address by following the instructions sent to your email.'
                              : 'Please use a different email address to register as this email is already taken.'}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className={Styles.parallel_field}>
                          <div className="w-100">
                            <FormikTextField
                              name="first_name"
                              type="text"
                              placeholder="Enter first name...  "
                              classList="input input__Email"
                              Label="First Name"
                              isRequired
                            />
                            <ErrorMessage name="first_name">
                              {(msg) => <span className="error">{msg}</span>}
                            </ErrorMessage>
                          </div>
                          <div className="w-100">
                            <FormikTextField
                              name="last_name"
                              type="text"
                              placeholder="Enter last name...  "
                              classList="input input__Email"
                              Label="Last Name"
                            />
                            <ErrorMessage name="last_name">
                              {(msg) => <span className="error">{msg}</span>}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className={Styles.fullWidth}>
                          <FormikTextField
                            name="email"
                            type="email"
                            placeholder="Enter your email"
                            classList="input input__Email"
                            Label="Email"
                            isRequired
                            preIcon={<HiOutlineEnvelope color="#667085" />}
                          />
                          <ErrorMessage name="email">
                            {(msg) => <span className="error">{msg}</span>}
                          </ErrorMessage>
                        </div>
                        <div className={Styles.password_field}>
                          <FormikTextField
                            name="company_name"
                            type="text"
                            placeholder="Enter company name..."
                            classList="input input__Email"
                            Label="Company Name"
                            isRequired
                          />
                          <ErrorMessage name="company_name">
                            {(msg) => <span className="error">{msg}</span>}
                          </ErrorMessage>
                        </div>
                        <div className={Styles.password_field}>
                          <FormikTextField
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Enter password..."
                            classList="input input__Email"
                            Label="Password"
                            isRequired
                            preIcon={<GoLock color="#667085" />}
                            postIcon={
                              showPassword ? (
                                <IoEyeOffSharp
                                  color="#667085"
                                  onClick={togglePasswordVisibility}
                                />
                              ) : (
                                <IoEyeOutline
                                  color="#667085"
                                  onClick={togglePasswordVisibility}
                                />
                              )
                            }
                          />
                          <ErrorMessage name="password">
                            {(msg) => <span className="error">{msg}</span>}
                          </ErrorMessage>
                        </div>
                        <div className={Styles.password_field}>
                          <FormikTextField
                            name="confirm_password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            placeholder="Enter password..."
                            classList="input input__Email"
                            Label="Confirm Password"
                            isRequired
                            preIcon={<GoLock color="#667085" />}
                            postIcon={
                              showConfirmPassword ? (
                                <IoEyeOffSharp
                                  color="#667085"
                                  onClick={toggleConfirmPasswordVisibility}
                                />
                              ) : (
                                <IoEyeOutline
                                  color="#667085"
                                  onClick={toggleConfirmPasswordVisibility}
                                />
                              )
                            }
                          />
                          <ErrorMessage name="confirm_password">
                            {(msg) => <span className="error">{msg}</span>}
                          </ErrorMessage>
                        </div>
                        <div className={Styles.btn_container}>
                          <BSButton
                            classList={`btn button secondary_btn w-100`}
                            variant="dark"
                            ButtonText="Sign Up"
                            disabled={!isValid}
                            onClick={() => handleClick(values, resetForm)}
                          />
                        </div>
                        <div className={Styles.or_container}>
                          <div className={Styles.or_container__line}></div>
                          <div className={Styles.or_container__or}>OR</div>
                          <div className={Styles.or_container__line}></div>
                        </div>
                        <div className={Styles.logos_container}>
                          <button
                            onClick={() => googleLogin()}
                            className={Styles.google_btn}
                          >
                            <span>Continue with Google</span>
                            <img src={Google} alt="google" />
                          </button>
                          {/* <button>
                        <img src={_Button2} alt="apple" />
                      </button>
                      <button>
                        <img src={_Button3} alt="microsoft" />
                      </button>
                      <button>
                        <img src={_Button4} alt="facebook" />
                      </button> */}
                        </div>
                      </>
                    )}
                    <div className={Styles.trial_account}>
                      <span className={Styles.trial_account__dont_have}>
                        Already have an account?
                      </span>
                      <Link
                        to="/log-in"
                        className={Styles.trial_account__have}
                        onClick={() => navigate('/log-in')}
                      >
                        Sign In
                      </Link>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className={Styles.OuterContainer__right_signup}>
        {/* <div className={Styles.OuterContainer__right_signup__avatars}>
          <AvatarGroup
            avatars={[
              'https://i.pravatar.cc',
              'https://i.pravatar.cc',
              'https://i.pravatar.cc',
            ]}
            size="large"
          />
          <p
            className={
              Styles.OuterContainer__right_signup__avatars__active_users
            }
          >
            Active Users
          </p>
        </div> */}
        <div className={Styles.OuterContainer__right_signup__text}>
          <div className="d-inline-flex align-items-start position-relative">
            <div
              className="position-absolute"
              style={{ left: '-2rem', top: '-1rem' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M18.4999 11.2489C18.2488 11.2509 17.9982 11.271 17.7499 11.3089C17.816 10.1772 18.1659 9.08033 18.7676 8.11953C19.3692 7.15873 20.2032 6.36493 21.1924 5.81138C21.8299 5.45138 22.5499 4.88888 22.1299 3.93638C22.0674 3.75075 21.9669 3.58019 21.8348 3.43557C21.7027 3.29095 21.5419 3.17545 21.3626 3.09642C21.1834 3.01739 20.9897 2.97657 20.7938 2.97656C20.5979 2.97656 20.4042 3.01736 20.2249 3.09638L20.0674 3.15638C15.9874 4.67888 12.4999 8.99888 13.2499 16.4989C13.5274 19.4989 15.5974 21.7489 18.4999 21.7489C19.8923 21.7489 21.2277 21.1958 22.2123 20.2112C23.1968 19.2266 23.7499 17.8913 23.7499 16.4989C23.7499 15.1065 23.1968 13.7711 22.2123 12.7866C21.2277 11.802 19.8923 11.2489 18.4999 11.2489ZM6.49995 11.2489C6.24883 11.2509 5.99819 11.271 5.74995 11.3089C5.81596 10.1772 6.16593 9.08033 6.76757 8.11953C7.36922 7.15873 8.20316 6.36493 9.19245 5.81138C9.82995 5.45138 10.5499 4.88888 10.1299 3.93638C10.0674 3.75075 9.96687 3.58019 9.83476 3.43557C9.70265 3.29095 9.54185 3.17545 9.36263 3.09642C9.1834 3.01739 8.98967 2.97657 8.79379 2.97656C8.59791 2.97656 8.40418 3.01736 8.22495 3.09638L8.06745 3.15638C3.98745 4.67888 0.499945 8.99888 1.24995 16.4989C1.52745 19.4989 3.59745 21.7489 6.49995 21.7489C7.18938 21.7489 7.87207 21.6131 8.50903 21.3493C9.14599 21.0854 9.72475 20.6987 10.2123 20.2112C10.6998 19.7237 11.0865 19.1449 11.3503 18.508C11.6142 17.871 11.7499 17.1883 11.7499 16.4989C11.7499 15.8094 11.6142 15.1268 11.3503 14.4898C11.0865 13.8528 10.6998 13.2741 10.2123 12.7866C9.72475 12.2991 9.14599 11.9124 8.50903 11.6485C7.87207 11.3847 7.18938 11.2489 6.49995 11.2489Z"
                  fill="#1D2939"
                />
              </svg>
            </div>
            {activeStep === 0 ? (
              <div className="">
                I've tried many different SEO plugins and solutions, but I've
                never seen a tool that connects the dots so well as Vocable.ai.
                It's incredibly intuitive, transforming our workflows and
                centralizing our content marketing effortlessly. This tool is a
                game changer!
              </div>
            ) : (
              <div className="">
                From initial research to publishing, Vocable transforms
                traditional content marketing into an efficient, data-driven
                process.
              </div>
            )}
          </div>
        </div>
        <p className={Styles.OuterContainer__right_signup__desc}>
          Go and signup! It’s free!
        </p>
        <div className={Styles.OuterContainer__right_signup__user}>
          {/* <div className={Styles.OuterContainer__right_signup__user__img}>
            <img src="https://i.pravatar.cc" alt="user" />
          </div> */}
          <div
            className={
              Styles.OuterContainer__right_signup__user__name_container
            }
          >
            <p
              className={
                Styles.OuterContainer__right_signup__user__name_container__name
              }
            >
              {activeStep === 0
                ? `Karolina Anna Waligora - SEO Manager at Mindvalley`
                : `VentureBeat`}
            </p>
            <div className="d-flex gap-1">
              <FaStar color="#F79009" size={20} />
              <FaStar color="#F79009" size={20} />
              <FaStar color="#F79009" size={20} />
              <FaStar color="#F79009" size={20} />
              <FaStar color="#F79009" size={20} />
            </div>
          </div>
        </div>
        <div className={Styles.OuterContainer__right_signup__stepper_container}>
          <Stepper
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </div>
        <div className={Styles.OuterContainer__right_signup__ticker_container}>
          <p
            className={
              Styles.OuterContainer__right_signup__ticker_container__heading
            }
          >
            Featured on:
          </p>
          <Ticker />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
