import { createStitches } from '@stitches/react';

import { colors } from './partials/colors';
import { fontSettings } from './partials/fontSettings';
import { spaces } from './partials/spaces';
import { borderRadius } from './partials/borderRadius';
import { mediaQueries } from './partials/mediaQueries';

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors,
    ...fontSettings,
    space: spaces,
    radii: borderRadius,
  },
  media: mediaQueries,
});
