import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from 'helpers/axios';
import { stringify } from 'qs';

// ##### ##### ##### ##### #####   GET   ##### ##### ##### ##### ##### //
export const getDocuments = ({
  type,
  search,
  projectId,
  status,
  pageParam,
}) => {
  const queryParams = {
    type,
    'search[title_cont]': search,
    project_id: projectId,
    ...(status && { status }),
    page: pageParam,
  };

  return getRequest(`documents?${stringify(queryParams)}`);
};

export const getDocument = (documentId) => {
  return getRequest(`documents/${documentId}`);
};

export const getDocumentAlreadyShared = (documentId) => {
  return getRequest(`documents/${documentId}/already_shared`);
};

export const getDocumentCollaborators = (documentId) => {
  return getRequest(`documents/${documentId}/collaborators`);
};

export const getDocumentHealth = (documentId) => {
  return getRequest(`documents/${documentId}/document_health`);
};

export const getDocumentExport = ({ documentId, type, responseType }) => {
  return getRequest(
    `documents/${documentId}/export?file_type=${type}`,
    responseType,
  );
};

export const getDocumentProjectCollaborators = (documentId) => {
  return getRequest(`documents/${documentId}/project_collaborators`);
};

export const getDocumentVersion = (documentId, page) => {
  return getRequest(`documents/${documentId}/versions?page=${page}`);
};

export const getCompletedDocuments = (projectId = '') => {
  return getRequest(`documents/completed?project_id=${projectId}`);
};

export const getRecentDocuments = (projectId = '') => {
  return getRequest(`documents/recent?project_id=${projectId}`);
};

export const getDocumentTotalCounts = (projectId = '') => {
  return getRequest(`documents/total_count?project_id=${projectId}`);
};

export const getDocumentTotalCountsByDuration = (
  duration = 'week',
  projectId = '',
) => {
  return getRequest(
    `documents/total_count_by_time?duration=${duration}&project_id=${projectId}`,
  );
};

// TODO: documents/:documentId/trashed

// ##### ##### ##### ##### #####   POST   ##### ##### ##### ##### ##### //
export const createDocument = ({ document, taskId, projectId, folderId }) => {
  return postRequest('documents', {
    document,
    task_id: taskId,
    project_id: projectId,
    folder_id: folderId,
  });
};

export const createDuplicateDocument = (documentId, title) => {
  return postRequest(`documents/${documentId}/duplicate`, {
    document: { title },
  });
};

// TODO: documents/:documentId/publish

export const saveAsDocument = ({ title, folderId, projectId, documentId }) => {
  return postRequest(`documents/${documentId}/save_as`, {
    document: { title },
    folder_id: folderId,
    project_id: projectId,
  });
};

export const extractContent = (body) => {
  return postRequest(`documents/extract_file_content`, body, {
    'Content-Type': 'multipart/form-data',
  });
};

export const starDocument = (documentId) => {
  return postRequest(
    `starred_documents?starred_document[document_slug]=${documentId}`,
  );
};

// ##### ##### ##### ##### #####   PUT   ##### ##### ##### ##### ##### //
// TODO: Check this Routes
export const updateDocumentTemplate = (documentId, body) => {
  return putRequest(`documents/${documentId}`, body);
};

export const updateDocument = (documentId, body) => {
  return putRequest(`documents/${documentId}`, {
    document: body,
  });
};

export const restoreDocument = (documentId) => {
  return putRequest(`documents/${documentId}/restore`);
};

export const restoreDocumentVersion = (documentId, versionId) => {
  return putRequest(`documents/${documentId}/restore_version`, {
    version_id: versionId,
  });
};

// ##### ##### ##### ##### #####   PATCH   ##### ##### ##### ##### ##### //
// TODO: documents/:documentId

// ##### ##### ##### ##### #####   DELETE   ##### ##### ##### ##### ##### //
// TODO: documents/:documentId
// TODO: documents/:documentId/fully_destroy
export const deleteStarredDocument = (documentId) => {
  return deleteRequest(`starred_documents/${documentId}`);
};

export const deleteFoldersAndDocuments = () => {
  return deleteRequest('folders_and_documents/empty_bin');
};

// ##### ##### ##### ##### #####   ? WEIRD ?   ##### ##### ##### ##### ##### //
export const shareDocumentIfNotAlready = async (user) => {
  const urlPath = window.location.pathname;
  const urlPathParams = urlPath.split('/');
  const documentId = urlPathParams[3];
  const access =
    urlPathParams[1] === 'editor' ? urlPathParams[7] : urlPathParams[5];
  let shouldProceed = false;

  let navigatingPath = null;

  if (
    urlPathParams[1] === 'editor' &&
    urlPathParams[6] === 'true' &&
    urlPathParams[7]
  ) {
    const documentId = urlPathParams[3];
    const templateId = urlPathParams[5];
    const access = urlPathParams[7];

    const response = await getRequest(`documents/${documentId}/already_shared`);

    if (response?.data?.owner) {
      navigatingPath = `/editor/document/${documentId}/template/${templateId}`;
    } else {
      !response?.data?.already_shared &&
        (await postRequest(`shared_documents`, {
          shared_document: {
            collaborators: [user.email],
            document_id: documentId,
            access_level_id: access,
          },
        }));

      shouldProceed = true;
    }
  } else if (
    urlPathParams[1] === 'freestyle-editor' &&
    urlPathParams[4] === 'true' &&
    urlPathParams[5]
  ) {
    const documentId = urlPathParams[3];
    const access = urlPathParams[5];

    const response = await getRequest(`documents/${documentId}/already_shared`);

    if (response?.data?.owner) {
      navigatingPath = `/freestyle-editor/document/${documentId}`;
    } else {
      !response?.data?.already_shared &&
        (await postRequest(`shared_documents`, {
          shared_document: {
            collaborators: [user.email],
            document_id: documentId,
            access_level_id: access,
          },
        }));

      shouldProceed = true;
    }
  }

  const accessLevels = await getRequest('access_levels');
  const { data } = accessLevels || {};
  const { access_levels } = data || {};

  const isViewOnlyAccess = shouldProceed
    ? access_levels.some(
        (accessLevel) =>
          accessLevel.uuid === access && accessLevel.level === 'can_view',
      )
    : false;

  return isViewOnlyAccess
    ? window.open(`/document/${documentId}`, '_self')
    : navigatingPath;
};

export const getDocumentSettings = (docId = '', integrationId = '') => {
  return getRequest(`documents/${docId}/fetch_story_settings?integration_id=${integrationId}`);
};

// export const saveDocumentSettings = (docId = '', body) => {
//   return postRequest(`documents/${docId}/save_story_settings`, body);
// };

export const saveDocumentSettings = (docId = '', body) => {
  return postRequest(`documents/${docId}/save_platform_integration_settings`, body);
};

export const getChannelsDropdownsData = (integrationId = '') => {
  return getRequest(`integrations/fetch_integration_info?integration_id=${integrationId}`);
};


