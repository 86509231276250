import React from 'react';
import Button from 'react-bootstrap/Button';

const BSButton = (props) => {
  const { iconPosition = 'left' } = props;

  return (
    <div className={props.BtnWrapperClass} style={props.wrapStyle}>
      <Button
        onClick={props.onClick}
        variant={props.variant}
        type={props.type || 'button'}
        className={props.classList}
        disabled={props.disabled}
        href={props.href}
        style={props.style}
      >
        {iconPosition === 'left' && props.icon}
        {props.ButtonText && <span>{props.ButtonText}</span>}
        {iconPosition === 'right' && props.icon}
      </Button>
    </div>
  );
};

export default BSButton;
