import React, { useEffect, useState } from 'react';
import Styles from './FolderViewCard.module.scss';
import { FaFolder } from 'react-icons/fa6';

import { FiRefreshCcw, FiStar, FiUserPlus } from 'react-icons/fi';
import {
  getRequest,
  deleteRequest,
  postRequest,
  putRequest,
} from 'helpers/axios';
import pluralize from 'pluralize';
import CustomDropDown from 'Components/CustomDropDown/CustomDropDown';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import ImageView from 'Components/Image/ImageView';
import { setLoader } from 'redux/slices/loader';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import DuplicateFolderModal from 'Containers/Modal/DuplicateFolderModal/DuplicateFolderModal';
import ConfirmDeleteModal from 'Containers/Modal/ConfirmDeleteModal/ConfirmDeleteModal';
import { useNavigate, useParams } from 'react-router-dom';
import ShareModal from 'Containers/Modal/ShareModal/ShareModal';
import { getAccessLevel } from 'helpers';
import ConfirmTrashDeleteModal from 'Containers/Modal/ConfirmTrashDeleteModal/ConfirmTrashDeleteModal';
import { PROJECTS } from 'constants';
import { HiOutlineDuplicate } from 'react-icons/hi';
import { LuDownload } from 'react-icons/lu';
import { AiOutlineDelete } from 'react-icons/ai';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';

const FolderViewCard = (props) => {
  const { fetchFolders, folder, isTrash, projectView } = props;
  const {
    id,
    title,
    documents_count,
    starred,
    shared_folders,
    user: folderOwner,
    project_id
  } = folder || {};

  const [star, setStar] = useState(starred);
  const [starDisplay, setStarDisplay] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [deleteDocumentModalView, setDeleteDocumentModalView] = useState(false);
  const [deleteTashConfirmModalView, setDeleteTashConfirmModalView] =
    useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false); // New state for dropdown visibility

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const { id: userId, subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  useEffect(() => {
    setStar(starred);
  }, [starred]);

  const accessLevel = getAccessLevel(shared_folders, user, folderOwner);

  const isFullAccess =
    user?.id === folderOwner?.id || accessLevel === 'full_access';

  const handleStar = async () => {
    try {
      if (!!Object.keys(star || {}).length) {
        await deleteRequest(`starred_folders/${star.id}`);
        setStar(null);
      } else {
        const response = await postRequest(
          `starred_folders?starred_folder[folder_id]=${id}`,
        );
        setStar(response.data.starred_folder);
      }
      fetchFolders(false);
    } catch (error) {
      renderError(error);
    }
  };

  const shareFolder = () => {
    if (currentSubscription?.plan_name === 'Basic Plan' && project_id !== null) {
      setShowUpgradeModal(true);
    } else {
      setShareModalOpen(true);
    }
  };

  const exportZip = () => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Exporting!',
        description: 'Please wait...',
      }),
    );
    getRequest(`folders/${id}/export`, 'arraybuffer')
      .then((response) => {
        dispatch(setLoader({ isLoading: false }));
        fileDownload(response.data, `${title}.zip`);
      })
      .catch((error) => {
        dispatch(setLoader({ isLoading: false }));
      });
  };

  const duplicateFolder = () => {
    setShowDuplicateModal(true);
  };

  const deleteFolder = () => {
    setDeleteDocumentModalView(true);
  };

  const dropDownOptions = [
    ...(isFullAccess
      ? [
          {
            label: <p>Add Collaborator</p>,
            icon: <FiUserPlus color="#1D2939" size={16} />,
            action: shareFolder,
          },
        ]
      : []),
    {
      label: <p>Export as Zip</p>,
      icon: <LuDownload color="#1D2939" size={16} />,
      action: exportZip,
    },
    // ...(isFullAccess || accessLevel === "can_edit"
    //   ? [
    {
      label: <p>Duplicate</p>,
      icon: <HiOutlineDuplicate color="#1D2939" size={16} />,
      action: duplicateFolder,
    },
    //   ]
    // : []),
    ...(isFullAccess
      ? [
          {
            label: <p className="">Delete</p>,
            icon: <AiOutlineDelete color="#1D2939" size={16} />,
            action: deleteFolder,
          },
        ]
      : []),
  ];

  const restoreFolder = () => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Restoring!',
        description: 'Please wait...',
      }),
    );

    putRequest(`folders/${folder.id}/restore`)
      .then((response) => {
        dispatch(setLoader({ isLoading: false }));
        renderSuccess(response.data.message);
        fetchFolders();
      })
      .catch((error) => {
        renderError(error);
        dispatch(setLoader({ isLoading: false }));
      });
  };

  const tashDropDownOptions = [
    {
      label: <p>Restore</p>,
      icon: <FiRefreshCcw />,
      action: restoreFolder,
    },
    ...(accessLevel === 'full_access'
      ? [
          {
            label: <p className="error">Delete</p>,
            icon: <ImageView src="/Images/delete.svg"></ImageView>,
            action: () => {
              setDeleteTashConfirmModalView(true);
            },
          },
        ]
      : []),
  ];

  return (
    <div
      onMouseEnter={() => {
        setStarDisplay(true);
        setDropdownVisible(true);
      }}
      onMouseLeave={() => {
        if (!star && setStarDisplay(false));
        setDropdownVisible(false);
      }}
      className={
        'd-flex justify-content-between align-items-center ' +
        Styles.FolderViewCard
      }
    >
      <section
        className={'d-flex gap-2 ' + Styles.FolderViewCard__Details}
        onClick={() => {
          if (isTrash) {
            return;
          }

          !projectView
            ? navigate(`/${PROJECTS}/${projectId}/folders/${id}`)
            : navigate(`/folder/${id}`);
        }}
        style={{ cursor: 'pointer' }}
      >
        <div
          className={'rounded-profile'}
          style={{ background: '#FFF9F0', borderRadius: '28px' }}
        >
          <FaFolder color={'#F8C864'} size={20} />
        </div>
        <div className="d-flex flex-column">
          <p className={'text-md ' + Styles.FolderViewCard__Details_Name}>
            {title}
          </p>
          <p
            className={'text-sm-18lh ' + Styles.FolderViewCard__Details_Caption}
          >
            {`${documents_count} ${pluralize('item', documents_count)}`}
          </p>
        </div>
      </section>
      {!isTrash && (
        <>
          <section
            className={`${Styles.FolderViewCard__Button} d-flex align-items-center gap-3`}
          >
            <div
              className={`d-flex ${star ? 'starred' : ''} ${
                Styles.FolderViewCard__Star
              }`}
            >
              {star ? (
                <FiStar size={20} onClick={() => handleStar()} />
              ) : starDisplay ? (
                <FiStar size={20} onClick={() => handleStar()} />
              ) : (
                ''
              )}
            </div>
            {isDropdownVisible && ( // Only show the dropdown if visible
              <CustomDropDown
                options={dropDownOptions}
                resource={folder}
                addCustomClass={true}
              />
            )}
          </section>
        </>
      )}
      {isTrash && (
        <section className={Styles.FolderViewCard__Button}>
          <CustomDropDown
            options={tashDropDownOptions}
            resource={folder}
            addCustomClass={true}
          />
        </section>
      )}
      <DuplicateFolderModal
        show={showDuplicateModal}
        onHide={() => {
          setShowDuplicateModal(false);
        }}
        fetchFolders={fetchFolders}
        folder={folder}
      />
      <ConfirmDeleteModal
        show={deleteDocumentModalView}
        onHide={(refetchFolders = false) => {
          setDeleteDocumentModalView(false);
          if (refetchFolders) {
            fetchFolders();
          }
        }}
        resource={folder}
        resourceName="folders"
      />

      <ConfirmTrashDeleteModal
        show={deleteTashConfirmModalView}
        onHide={() => {
          setDeleteTashConfirmModalView(false);
        }}
        resource={folder}
        fetchFolders={fetchFolders}
        url={`folders/${folder.id}/fully_destroy`}
      />

      {shareModalOpen && (
        <ShareModal
          show={shareModalOpen}
          onHide={() => {
            setShareModalOpen(false);
          }}
          folder={folder}
          isDocument={false}
          fetchFolders={fetchFolders}
        />
      )}
      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
          message={"Updating campaign related content is a pro feature. Please update to pro plan to access this feature."}

        />
      )}
    </div>
  );
};

export default FolderViewCard;
