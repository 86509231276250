import React from 'react';
import './PublishModal.scss'; // Import the SCSS file
import { useNavigate } from 'react-router-dom';


const PublishModal = ({ isOpen, onClose, message }) => {
  const navigate = useNavigate();

    if (!isOpen) return null;
    return (
      <div className="modal-overlay">
        <div className="modal-content-cs">
          <div className='close-button-container'>
            <button className='close-button' style={{border:'1px solid #EEEEEE'}}  onClick={onClose}>
              ✕
            </button>
          </div>
          <div className='model-container'>
            <div className="modal-icon">
              {/* <div className="icon-placeholder"></div> */}
              <img src="/Images/check-green.svg" alt="icon gif" className="icon-gif" />
            </div>
            <h2 className="modal-title">Content Published</h2>
            <p className="modal-message">
              Congratulations! {message}
            </p>
            <button className="dashboard-button" onClick={()=>navigate('/')}>Go to Dashboard</button>
          </div>
        </div>
      </div>
    );
  };
  export default PublishModal;