import { buildStyles } from 'react-circular-progressbar';

export const circularProgressbarStyle = buildStyles({
  pathTransitionDuration: 0.5,
  pathColor: `#00A7B7`,
  trailColor: '#F2FBFB',
  backgroundColor: '#3e98c7',
  textSize: 36,
  textColor: 'black',
});
