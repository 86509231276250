import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { setLoader } from 'redux/slices/loader';
import { useNavigate } from 'react-router-dom';
import styles from '../../../Containers/Subscription/EmailModal.module.scss';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import { removeTeamUser } from 'services/teamService';
import BSButton from 'Components/Button/BSButton';
import { FiAlertCircle } from 'react-icons/fi';

function LeaveTeamModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { email, resource, showCustomMessage } = props;

  const confirmLeave = async () => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Removing!',
          description: 'Please wait...',
        }),
      );

      const res = await removeTeamUser(resource.id, email);

      dispatch(setLoader({ isLoading: false }));
      if (showCustomMessage) {
        renderSuccess('Team left sucessfully!');
        props.onHide();
        navigate('/teams');
      } else {
        renderSuccess(res?.data?.message);
        props.onHide();
        props.fetchTeamDetail();
        navigate(`/teams/${resource.id}`);
      }
    } catch (error) {
      renderError(error);
      dispatch(setLoader({ isLoading: false }));
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="pb-0 border-0" closeButton>
        <Modal.Title className="Modal-Title" id="contained-modal-title-vcenter">
          Delete Item
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-4">
        <div className="d-flex justify-content-center">
          <div
            style={{
              background: '#FEF3F2',
              height: '45px',
              width: '45px',
              borderRadius: '50%',
              position: 'relative',
            }}
          >
            <div
              style={{
                background: '#FEE4E2',
                height: '35px',
                width: '35px',
                borderRadius: '50%',
                margin: '5px',
                padding: '5px',
              }}
            >
              <FiAlertCircle size={25} color="#D92D20" />
            </div>
          </div>
        </div>

        <p
          style={{
            fontFamily: 'DM Sans',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '28px',
            letterSpacing: '0em',
            textAlign: 'center',
            marginTop: 20,
          }}
        >
          Delete member
        </p>

        <p
          style={{
            fontFamily: 'DM Sans',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'center',
            color: '#667085',
          }}
        >
          Are you sure you want to remove?
        </p>

        <div className="d-flex justify-content-end g-10 w-100 mt-5">
          <BSButton
            variant="light"
            ButtonText={'Cancel'}
            onClick={() => props.onHide()}
            classList={
              'btn ' + styles.modalFooterBtn + ' ' + styles.modalFooterBtn_Light
            }
          />
          <BSButton
            variant="dark"
            ButtonText={'Delete'}
            onClick={() => confirmLeave()}
            classList={'btn button secondary_btn'}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LeaveTeamModal;
