import React from 'react';

import AIProjectHeader from './Header/AIProjectHeader';
import ImageView from 'Components/Image/ImageView';
import BSButton from 'Components/Button/BSButton';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import Styles from './AIProject.module.scss';

const AIProject = () => {
  const navigate = useNavigate();

  return (
    <div className={Styles.AIProjectContainer}>
      <AIProjectHeader />
      <div className={Styles.AIProjectContainer_Body}>
        <ImageView src="/Images/project_ai_step.svg" />
        <BSButton
          BtnWrapperClass={Styles.AIProjectContainer_Body_GetStarted}
          classList="button secondary_btn"
          ButtonText="Get Started"
          variant="dark"
          onClick={() => navigate(ROUTES.Project.Setup)}
        />
        <BSButton
          classList="button cancel_btn"
          ButtonText="Cancel"
          variant="outline-secondary"
          onClick={() => navigate(ROUTES.Projects)}
        />
      </div>
    </div>
  );
};

export default AIProject;
