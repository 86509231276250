import React from 'react';
import Toast from 'react-bootstrap/Toast';

import StyleClasses from './OptionsDropDown.module.scss';

const ExportOptions = ({ show, onClose, chevronRight, exportFile }) => {
  return (
    <Toast
      className={StyleClasses.Toast__Body}
      show={show}
      onClose={onClose}
      style={chevronRight ? { right: '100%' } : { right: '100%' }}
    >
      <Toast.Body>
        <ul className={StyleClasses.list}>
          <li onClick={() => exportFile('docx', 'blob')}>
            Microsoft Word (.docx)
          </li>
          <li onClick={() => exportFile('odt', 'blob')}>
            OpenDocument Format (.odt)
          </li>
          <li onClick={() => exportFile('rtf', 'blob')}>
            Rich Text Format (.rtf)
          </li>
          <li onClick={() => exportFile('pdf', 'blob')}>PDF Document (.pdf)</li>
          <li onClick={() => exportFile('txt', 'blob')}>Plain Text (.txt)</li>
          <li onClick={() => exportFile('zip', 'arraybuffer')}>
            Web Page (.html, zipped)
          </li>
        </ul>
      </Toast.Body>
    </Toast>
  );
};

export default ExportOptions;
