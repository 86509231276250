import React from 'react';
import Styles from './TickerCard.module.scss';

const TickerCard = ({ Icon, text1, text2 }) => {
  return (
    <div
      className={`${Styles.container} 
    ${
      text1 === 'AI-Powered'
        ? Styles.ai_powered
        : text1 === 'AI Brief'
          ? Styles.ai_brief
          : text1 === 'SEO & Keyword'
            ? Styles.seo_keyword
            : text1 === 'Content'
              ? Styles.content
              : text1 === 'Multi-Model'
                ? Styles.multi_model
                : text1 === 'Brand'
                  ? Styles.brand
                  : text1 === 'AI Content'
                    ? Styles.ai_content
                    : ''
    }`}
    >
      <Icon />
      <div className={Styles.container__text}>
        <span>{text1}</span>
        <span>{text2}</span>
      </div>
    </div>
  );
};

export default TickerCard;
