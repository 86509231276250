import React, { useState } from 'react';
import Styles from './TemplatesSection.module.scss';
import Frame from '../../../assets/landingpage/svg/Frame 1618867902.png';
import Navigation from '../Navigation/Navigation';
import { ReactComponent as Icon1 } from '../../../assets/landingpage/svg/Frame 1618867910.svg';
import { ReactComponent as Icon2 } from '../../../assets/landingpage/svg/Frame 1618867910(1).svg';
import { ReactComponent as Icon3 } from '../../../assets/landingpage/svg/Frame 1618867907.svg';
import { ReactComponent as Icon4 } from '../../../assets/landingpage/svg/Frame 1618867904.svg';

import Video from '../../../assets/landingpage/therapy.mov';
import Video1 from '../../../assets/landingpage/Ideate & Brainstorm with Ease.mov';
import { useNavigate } from 'react-router-dom';

const navigations = [
  {
    icon: <Icon1 />,
    text: 'Hassle Free Ideation',
    new: false,
  },
  {
    icon: <Icon2 />,
    text: 'Keyword Research',
    new: false,
  },
  {
    icon: <Icon3 />,
    text: 'Repurpose Content',
    new: true,
  },
  {
    icon: <Icon4 />,
    text: 'Analyze Data',
    new: true,
  },
];

const TemplatesSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();

  return (
    <div className={Styles.toolkit}>
      <div className={Styles.toolkit__header}>
        <div className={Styles.toolkit__header__heading}>
          Your On-Demand Content Toolkit
        </div>
        <div className={Styles.toolkit__header__description}>
          Access the tools to enhance every aspect of your content on-demand.
          Each tool integrates seamlessly into your overall workflow, keeping
          your strategy dynamic and your execution flawless.
        </div>
      </div>

      <div className={Styles.toolkit__grid}>
        <div className={Styles.toolkit__grid__left}>
          <Navigation
            navigations={navigations}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
          <div className={Styles.toolkit__grid__left__button_section}>
            <p className={Styles.toolkit__grid__left__button_section__text}>
              Access the tools to enhance every aspect of your content
              on-demand.{' '}
            </p>
            <button
              className={Styles.toolkit__grid__left__button_section__btn}
              onClick={() => navigate('sign-up')}
            >
              Start your FREE Trial
            </button>
          </div>
        </div>
        {activeIndex === 0 && (
          <div
            className={`${Styles.toolkit__grid__right} ${Styles.toolkit__grid__right__new}`}
          >
            <div className={Styles.toolkit__grid__right__header}>
              <p className={Styles.toolkit__grid__right__header__heading}>
                Ideate & Brainstorm with Ease
              </p>
              <p className={Styles.toolkit__grid__right__header__text}>
                Never face a creative block again. Our Ideate tool offers
                instant inspiration, generating ideas tailored to your audience
                and goals with just a few clicks.
              </p>
            </div>
            <div className={Styles.toolkit__grid__right__image}>
              <video
                autoPlay
                muted
                loop
                className={Styles.toolkit__grid__right__image__video}
              >
                <source src={Video1} />
              </video>
            </div>
          </div>
        )}
        {activeIndex === 1 && (
          <div className={Styles.toolkit__grid__right}>
            <div className={Styles.toolkit__grid__right__header}>
              <p className={Styles.toolkit__grid__right__header__heading}>
                Research Made Simple
              </p>
              <p className={Styles.toolkit__grid__right__header__text}>
                Stay ahead with our research tool. Discover trends, find top
                keywords, and analyze your audience. With Vocable, crafting
                standout content is easy.
              </p>
            </div>
            <div className={Styles.toolkit__grid__right__image}>
              <video
                autoPlay
                muted
                loop
                className={Styles.toolkit__grid__right__image__video}
              >
                <source src={Video} />
              </video>
            </div>
          </div>
        )}
        <div
          className={`${Styles.toolkit__grid__left__button_section} ${Styles.visible_on_mobile}`}
        >
          <p className={Styles.toolkit__grid__left__button_section__text}>
            Access the tools to enhance every aspect of your content on-demand.{' '}
          </p>
          <button
            className={Styles.toolkit__grid__left__button_section__btn}
            onClick={() => navigate('sign-up')}
          >
            Start your FREE Trial
          </button>
        </div>
      </div>
    </div>
  );
};

export default TemplatesSection;
