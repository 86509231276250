import { useQuery } from '@tanstack/react-query';
import keys from 'constants/queryKeys';
import { getCategories } from '../../services/templateService';

const useCategories = () => {
  const query = useQuery({
    queryKey: keys.Categories,
    queryFn: getCategories,
    staleTime: Infinity,
  });

  return {
    ...query,
    ...query?.data?.data,
  };
};

export default useCategories;
