import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ImageView from 'Components/Image/ImageView';
import { getTimeStr } from 'helpers';
import { circularProgressbarStyle } from 'styles/score';
import ScoreModalStyles from './OverallScoreModal.module.scss';

const OverallScoreModal = ({ transformedDocHealth, score, ...props }) => {
  const { word_count, reading_time_seconds, character_count } =
    transformedDocHealth || {};

  const speakingTime = (word_count / 150) * 60;

  const readTime = getTimeStr(reading_time_seconds || 0);
  const speakTime = getTimeStr(speakingTime || 0);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 10000 }}
    >
      <Modal.Header className="border-0 pb-2" closeButton>
        <Modal.Title id="">Overall Score</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <section className={ScoreModalStyles.ScoreWrapper}>
          <div className={ScoreModalStyles.progressBarWrapper}>
            <CircularProgressbar
              styles={circularProgressbarStyle}
              className={ScoreModalStyles.progressBarModal}
              text={`${score || 0}`}
              value={score || 0}
              strokeWidth={16}
            />
          </div>
          <p>
            This score represents the performance of your document. This can be
            improved by following suggestions & corrections.
          </p>
        </section>
        <section className={ScoreModalStyles.ScoreProperties}>
          <p
            className={'heading-lg ' + ScoreModalStyles.ScoreProperties_Heading}
          >
            Word count
          </p>
          <div className="d-flex justify-content-between">
            <div className={ScoreModalStyles.ScoreProperties_card}>
              <ImageView src="/Images/A.svg" />
              <div className="d-flex flex-column">
                <div className={ScoreModalStyles.ScoreProperties_card_property}>
                  <p>{character_count || 0}</p>
                  <span className="dot-black"></span>
                  <p>Characters</p>
                </div>
                <div className={ScoreModalStyles.ScoreProperties_card_property}>
                  <p>{word_count || 0}</p>
                  <span className="dot-black"></span>
                  <p>Words</p>
                </div>
              </div>
            </div>
            <div className={ScoreModalStyles.ScoreProperties_card}>
              <ImageView src="/Images/clock.svg" />
              <div className="d-flex flex-column">
                <div className={ScoreModalStyles.ScoreProperties_card_property}>
                  <p>{readTime}</p>
                  <span className="dot-black"></span>
                  <p>Reading time</p>
                </div>
                <div className={ScoreModalStyles.ScoreProperties_card_property}>
                  <p>{speakTime}</p>
                  <span className="dot-black"></span>
                  <p>Speaking time</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default OverallScoreModal;
