import React, { useEffect, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoader } from 'redux/slices/loader';
import HtmlDiff from 'htmldiff-js';

import DashboardHeader from 'Components/DashboardHeader/DashboardHeader';
import { stringToColor } from 'helpers';
import { error as renderError } from 'helpers/toaster.js';
import EditorStyles from 'Containers/Editor/Editor.module.scss';
import 'Containers/Editor/Editor.scss';
import Styles from './DocumentHistory.module.scss';
import { ROUTES } from 'constants/routes';
import Loader from 'Components/Loader/Loader';
import { formatLongDate } from 'helpers/dates';
import {
  getDocumentVersion,
  restoreDocumentVersion,
} from 'services/documentService';
import useDocument from 'hooks/documents/useDocument';

function DocumentHistory() {
  const { documentId } = useParams() || {};
  const [currentPagination, setPagination] = useState({});
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState([]);
  const [versionIndex, setIndex] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { document, isLoading } = useDocument({ documentId });

  useEffect(() => {
    if (!isLoading) {
      fetchDocumentDetail();
    }
  }, [isLoading]);

  const fetchDocumentDetail = async () => {
    dispatch(setLoader({ isLoading: versions.length === 0 }));

    const versionResponse = await getDocumentVersion(
      documentId,
      (currentPagination.current_page || 0) + 1,
    );
    const { meta_data } = versionResponse?.data || {};
    const { pagination } = meta_data || {};
    const allVersions = versionResponse?.data['paper_trail/versions'] || [];

    const currentVersion = {
      id: null,
      content_after: document.content,
      content_before:
        allVersions.length > 0 ? allVersions[0].content_after : '',
      created_at: document.updated_at,
      whodunnit: {
        name: document.last_updated_by,
      },
    };

    if (
      document.updated_at !== allVersions[0]?.created_at &&
      pagination.current_page === 1
    ) {
      allVersions.unshift(currentVersion);
    }

    setVersions([...versions, ...allVersions]);
    if (allVersions.length > 0 && pagination.current_page === 1) {
      setSelectedVersion(allVersions[0]);
    }
    setPagination(pagination);
    dispatch(setLoader({ isLoading: false }));
  };

  const handleClick = () => {
    const { template_id: templateId, id } = document;

    const routeToMove = templateId
      ? ROUTES.EditorDocumentTemplate(id, templateId)
      : ROUTES.FreestyleDocumentTemplate(id);

    navigate(routeToMove);
  };

  const handleRestore = async () => {
    try {
      dispatch(setLoader({ isLoading: true, heading: 'Restoring...' }));
      await restoreDocumentVersion(documentId, selectedVersion.id);

      dispatch(setLoader({ isLoading: false }));
      handleClick();
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
      renderError(error);
    }
  };

  return (
    <>
      <DashboardHeader />
      <div className="editor-container" style={{ marginTop: '80px' }}>
        {isLoading ? (
          <Loader inline loaderHeading="Loading Document..." />
        ) : (
          <>
            <div className={Styles.DocumentContainer}>
              <section className={Styles.DocumentContainer_Header}>
                <div className={Styles.DocumentContainer_Header_Title}>
                  <FiArrowLeft size={20} onClick={handleClick} />
                  <div className={EditorStyles.documentStatusWrapper}>
                    <h3>
                      {!!selectedVersion &&
                        formatLongDate(selectedVersion.created_at)}
                    </h3>
                    <h5>{document?.title}</h5>
                  </div>
                </div>
                {versionIndex > 0 && (
                  <button
                    onClick={handleRestore}
                    className="button addBtn Dropdown-Button btn btn-light"
                  >
                    Restore
                  </button>
                )}
              </section>
              <div
                className={'version-diff-container ' + Styles.VersionDiff}
                dangerouslySetInnerHTML={{
                  __html: HtmlDiff.execute(
                    selectedVersion.content_before || '',
                    selectedVersion.content_after || '',
                  ),
                }}
              />
            </div>
            <div className={'third ' + Styles.VersionHistory}>
              <div className="d-flex align-items-center pb-2">
                <div className={`${EditorStyles.Comments__Header_title} mb-2`}>
                  Version History
                </div>
                <hr />
              </div>
              <div className="d-flex flex-column">
                {versions.map((version, index) => {
                  return (
                    <div
                      className="commentContainerInfo flex-1"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelectedVersion(version);
                        setIndex(index);
                      }}
                      key={version.id}
                    >
                      <div
                        className={
                          'd-flex justify-content-between align-items-center doc-history-item ' +
                          (version.id === selectedVersion.id ? 'active' : '')
                        }
                      >
                        <section className="d-flex align-items-center g-10">
                          <div
                            className={
                              'd-flex flex-column ' +
                              EditorStyles.Comment__AuthorSection_info
                            }
                          >
                            <span
                              className={
                                'text-md ' + EditorStyles.Comment_authorName
                              }
                            >
                              {formatLongDate(version.created_at)}
                              {index === 0 ? <i> (Current Version)</i> : ''}
                            </span>
                            <span
                              className={
                                'text-sm-18lh ' + EditorStyles.Comment_timeStamp
                              }
                            >
                              <span
                                style={{
                                  height: '9px',
                                  width: '9px',
                                  backgroundColor: `${stringToColor(
                                    version.whodunnit.name,
                                  )}`,
                                  borderRadius: '50%',
                                  display: 'inline-block',
                                  marginRight: '4px',
                                }}
                              />
                              {version.whodunnit.name}
                            </span>
                          </div>
                        </section>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default DocumentHistory;
