export const GTM_EVENTS = {
  SIGN_UP: 'sign_up',
  LOGIN: 'login',
  PROJECT_CREATED: 'project_created',
  BRIEF_CREATED: 'brief_created',
  DOCUMENT_CREATED: 'document_created',
};

export const pushEvent = (name, payload = {}) => {
  try {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push({
      event: name,
      ...payload,
    });
  } catch (error) {
    console.warn('Failed pushing event', error);
  }
};
