import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setLoader } from 'redux/slices/loader';
import {
  getCurrentSubscription,
  getPaymentMethods,
  markDefaultCard,
  refreshSubscription,
  removeCard,
  sendSupportEmail,
} from 'services/subscriptionService';
import useFetchPlans from 'hooks/subscriptions/fetchPlans';
import PaymentCard from './PaymentCard';
import sc from './Subscription.module.scss';
import { CONFIRM_SUBSCRIPTION } from 'constants';
import PlanDetailCard from 'Components/Pages/BillingAndSubscriptions/PlanDetailCard';
import TrialFeatures from 'Components/Pages/BillingAndSubscriptions/TrialFeatures';
import SubscriptionCardModal from './SubscriptionCardModal';
import CustomCheckbox from 'Components/Form/CustomCheckbox';
import OrderSummary from 'Components/Pages/BillingAndSubscriptions/OrderSummary';
import BottomDrawer from 'Components/Drawer/BottomDrawer';
import SubscriptionScreen from './Subscription';
// import SubscriptionCardModal from './SubscriptionCardModal';
import SubscriptionCardForm from './SubscriptionForm';
// import OnboardingHeader from 'Components/Onboarding/Header/OnboardingHeader';
import OnboardingHeader from 'Components/Pages/Onboarding/Header/Header';
import { resetOnboarding } from 'redux/slices/newOnboarding';
import { TbExclamationCircle } from 'react-icons/tb';

const BillingSectionHeading = ({ heading, desc, className, subDesc }) => {
  return (
    <div className={`${sc.billingSectionHeadingWrapper} ${className}`}>
      <h3 className={`${sc.heading} ${sc.headingDetails}`}>{heading}</h3>
      {desc && <p className={sc.desc}>{desc}</p>}
    </div>
  );
};

const PaymentScreen = ({ productData = {}, setOrderSummary }) => {
  const [selectedCard, setSelectedCard] = useState();
  const [showMore, setShowMore] = useState(1);
  const [paymentMethods, setPaymentMethods] = useState({});
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState({});
  const [selectedPlanType, setSelectedPlanType] = useState('month');
  const [newSubscription, setNewSubscription] = useState(null);
  const [isDisabledSwitch, setIsDisabledSwitch] = useState(false);
  const [planFeatures, setPlanFeatures] = useState([]);
  const [lastSubscription, setLastSubscription] = useState();
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const fetchPlansData = useFetchPlans();
  const paymentIntent = searchParams?.get('payment_intent');
  const trial = searchParams?.get('trial') || false;
  // const status = searchParams?.get('status') || '';
  const location = useLocation();
  const status = location?.state?.status;

  const [cardModalOpen, setCardModalOpen] = useState(trial ? true : false);

  const planId = location?.state?.subscriptionId || productData.id;
  const { plan_name: planName, stripe_price: stripePrice } =
    currentSubscription  || {};
  const { interval, amount } = stripePrice || {};

  const updateSelectedPlanType = () => {
    let newPlan = null;
    if (selectedPlanId) {
      if (
        newSubscription &&
        newSubscription?.interval !== selectedPlanType &&
        newSubscription?.id === parseInt(selectedPlanId)
      ) {
        newPlan = subscriptionPlans[
          selectedPlanType === 'month' ? 'monthlyPlan' : 'annualplan'
        ].find((plan) => plan.type === newSubscription?.type);
        setNewSubscription(newPlan);
      } else {
        newPlan = subscriptionPlans['monthlyPlan']
          .concat(subscriptionPlans['annualplan'])
          .find((plan) => {
            return plan.id === parseInt(selectedPlanId);
          });
        if (newPlan?.interval !== selectedPlanType) {
          setSelectedPlanType(newPlan?.interval);
        }
        setNewSubscription(newPlan);
      }
    } else {
      newPlan = subscriptionPlans[
        selectedPlanType === 'month' ? 'monthlyPlan' : 'annualplan'
      ].find((plan) => plan.type === currentSubscription?.plan_name);
      setNewSubscription(newPlan);
    }
    setPlanFeatures(newPlan?.services);
  };
  useEffect(() => {
    if (Object.keys(subscriptionPlans).length > 0) {
      updateSelectedPlanType();
    }
  }, [selectedPlanType, subscriptionPlans, selectedPlanId]);

  useEffect(() => {
    if (trial) {
      dispatch(resetOnboarding());
    }
  }, [trial, dispatch]);

  const fetchPlans = useCallback(async (setShouldRedirect) => {
    fetchPlansData({
      setPaymentMethods,
      setSubscriptionPlans,
      setCurrentSubscription,
      setSelectedPlanType,
      setLastSubscription,
      setShouldRedirect,
    });
  }, []);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  useEffect(() => {
    if (planId) {
      setSelectedPlanId(planId);
    }
  }, [planId]);

  const fetchPaymentMethods = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Fetching Billing Info',
          description: 'Please wait...',
        }),
      );
      const paymentMethodsRes = await getPaymentMethods();
      const { data: paymentMethodData } = paymentMethodsRes || {};
      const { payment_methods, default_payment_method } =
        paymentMethodData || {};
      setPaymentMethods(payment_methods);
      setSelectedCard(default_payment_method);
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    } catch (error) {
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    }
  }, [dispatch]);

  const fetchInvoices = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Fetching Invoices',
          description: 'Please wait...',
        }),
      );
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    } catch (error) {
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    }
  }, [dispatch]);

  const fetchCurrentSubscription = useCallback(async () => {
    try {
      const currentSubscriptionRes = await getCurrentSubscription();
      const { data: subscriptionData } = currentSubscriptionRes || {};
      const { current_subscription: currentSubscription } =
        subscriptionData || {};
        // const { last_subscription: lastSubscription } =
        // subscriptionData || {};
      
      setCurrentSubscription(currentSubscription);
      // setLastSubscription(lastSubscription);
    } catch (error) {}
  }, []);

  const confirmSubscription = useCallback(async () => {
    try {
      await refreshSubscription(paymentIntent);
      toast.success('Plan subscribed successfully!');
      fetchPaymentMethods();
      fetchCurrentSubscription().then(() => setShowSuccessPage(true));
    } catch (error) {}
  }, [fetchCurrentSubscription, fetchPaymentMethods, paymentIntent]);

  useEffect(() => {
    paymentIntent && confirmSubscription();
  }, [confirmSubscription, paymentIntent]);

  useEffect(() => {
    fetchPaymentMethods();
  }, [fetchPaymentMethods]);

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  useEffect(() => {
    fetchCurrentSubscription();
  }, [fetchCurrentSubscription]);

  useEffect(() => {
    if (paymentMethods?.data?.length === 1) {
      setShowMore(1);
    }
  }, [paymentMethods?.data?.length]);
  useEffect(() => {
    showSuccessPage &&
      planName &&
      amount &&
      interval &&
      navigateToSuccessPage();
  }, [planName, amount, interval, showSuccessPage]);

  const navigateToSuccessPage = () =>
    navigate(
      `/${CONFIRM_SUBSCRIPTION}/plan/${planName}/price/${amount}/type/${interval}`,
    );

  const { data: paymentMethodData } = paymentMethods || {};
  const viewAllButtonCursor =
    paymentMethodData?.length > 1 ? 'pointer' : 'default';

  // if (!currentSubscription) {
  //   return null;
  // }

  const handleDefault = async (cardId) => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Marking card as default!',
          description: 'Please wait...',
        }),
      );
      await markDefaultCard(cardId);
      setSelectedCard(cardId);
      fetchCurrentSubscription();
      fetchPaymentMethods();
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    } catch (error) {
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    }
  };

  const handleRemove = async (cardId) => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Removing Card!',
          description: 'Please wait...',
        }),
      );
      await removeCard(cardId);
      fetchCurrentSubscription();
      fetchPaymentMethods();
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    } catch (error) {
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    }
  };

  const getStatusMessage = (status) => {
    switch (status) {
      case "unpaid":
        return "Your payment is failed. Please update your payment method.";
      case "canceled":
        return "Your subscription has been canceled. Please resubscribe.";
      case "incomplete":
        return "Your subscription is incomplete. Please complete the necessary steps to activate your subscription.";
      default:
        return "";
    }
  };

  const transformedPaymentMethodData =
    paymentMethodData?.reduce((acc, currentValue) => {
      if (currentValue.id === selectedCard) {
        acc.unshift(currentValue);
      } else {
        acc.push(currentValue);
      }
      return acc;
    }, []) || [];

  return (
    <div className={sc.billing__root}>
      {trial && <OnboardingHeader />}
      {(status === "unpaid" || status === "canceled" || status === "incomplete") && <OnboardingHeader/>}
      {status && <div className={sc.billing__root__header}>
        <span>
          <TbExclamationCircle />
        </span>
        <p>
        {(status === "unpaid" || status === "canceled" || status === "incomplete") && <span>{getStatusMessage(status)}</span>}
        </p>
      </div>}

      <div className={sc.outer_container}>
        <div className="d-flex gap-5 w-100">
          <div
            className={`${sc.planTypeCardWrapper} ${sc.utility_width_plan_detail}`}
          >
            <PlanDetailCard
              selectedPlanType={selectedPlanType}
              setSelectedPlanType={setSelectedPlanType}
              plan={newSubscription}
              isDisabledSwitch={isDisabledSwitch}
              isTrial={trial}
              setIsModalOpen={setIsModalOpen}
            />
            <TrialFeatures features={planFeatures} />
            <div className={`${sc.addedCardsWrapper} w-100`}>
              <p className={`${sc.heading_billing_payment}`}>PAYMENT DETAILS</p>
              <div className={sc.addedCardsArea}>
                <div className={sc.paymentMethodCardsWrapper}>
                  {[...transformedPaymentMethodData?.slice(0, showMore)]?.map(
                    (card) => (
                      <PaymentCard
                        key={card.id}
                        card={card}
                        setSelectedCard={(card) => handleDefault(card.id)}
                        checked={card.id === selectedCard}
                        selectedCard={selectedCard}
                        setShowMore={setShowMore}
                        handleRemove={handleRemove}
                      />
                    ),
                  )}
                </div>
                <div className={sc.addPaymentActionWrapper}>
                  {showMore === 1 && (
                    <button
                      disabled={
                        paymentMethodData?.length === 1 ||
                        !paymentMethodData?.length
                      }
                      onClick={() => setShowMore(paymentMethodData?.length)}
                      className={sc.viewAllBtn}
                      style={{ cursor: viewAllButtonCursor }}
                    >
                      <span>View All</span>
                      <img src="/Images/chevron-down.svg" alt="downArrow" />
                    </button>
                  )}
                  {showMore > 1 && (
                    <button
                      onClick={() => setShowMore(1)}
                      className={sc.viewLessBtn}
                    >
                      <span>View Less</span>
                      <img src="/Images/chevron-down.svg" alt="downArrow" />
                    </button>
                  )}
                </div>
                {/* <div className={sc.addNewPaymentMethodBtnWrapper}>
                  <button
                    className={sc.addNewPaymentMethodBtn}
                    onClick={() => setCardModalOpen(true)}
                  >
                    <img src="/Images/plus-grey1.svg" alt="addIcon" />
                    <span className={sc.newMethodText}>
                      Add New Payment Method
                    </span>
                  </button>
                </div> */}
                {!cardModalOpen ? (
                  <div className={sc.addNewPaymentMethodBtnWrapper}>
                    <button
                      className={sc.addNewPaymentMethodBtn}
                      onClick={() => setCardModalOpen(true)}
                    >
                      <img src="/Images/plus-grey1.svg" alt="addIcon" />
                      <span className={sc.newMethodText}>
                        Add New Payment Method
                      </span>
                    </button>
                  </div>
                ) : (
                  <SubscriptionCardForm
                    onHide={() => setCardModalOpen(false)}
                    show={cardModalOpen}
                    isProductView={true}
                    refetch={fetchPaymentMethods}
                    product={productData}
                    // initiateTrial={initiateTrial}
                    isTrial={trial}
                  />
                )}
              </div>
              <div className="mt-3">
                {/* <CustomCheckbox label="I need an invoice" /> */}
              </div>
            </div>
          </div>
          <div className={`${sc.utility_width_order_summary}`}>
            <OrderSummary
              newSubscription={newSubscription}
              setIsDisabledSwitch={setIsDisabledSwitch}
              refetch={fetchPlans}
              isTrial={trial}
              product={productData}
              setOrderSummary={setOrderSummary}
              paymentMethodData={paymentMethodData}
              planId={planId}
              selectedPlanType={selectedPlanType}
            />
          </div>
        </div>
      </div>
      {/* <SubscriptionCardModal
        onHide={() => setCardModalOpen(false)}
        show={cardModalOpen}
        isProductView={false}
        refetch={fetchPaymentMethods}
      /> */}
      <BottomDrawer
        title="Subscription Plan"
        show={isModalOpen}
        placement="bottom"
      >
        <SubscriptionScreen
          closeDrawer={() => setIsModalOpen(false)}
          subscriptionPlans={subscriptionPlans}
          paymentMethods={paymentMethods}
          currentSubscription={currentSubscription}
          lastSubscription={lastSubscription}
          refetch={fetchPlans}
        />
      </BottomDrawer>
    </div>
  );
};

export default PaymentScreen;
