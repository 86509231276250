import React from 'react';
import { ErrorMessage, Field } from 'formik';
import { Form } from 'react-bootstrap';
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TbExclamationCircle } from 'react-icons/tb';

const FormikDateField = ({
  name,
  label,
  isRequired,
  placeholder="MM/DD/YYYY",
  iconWithLabel,
  format="MM/dd/yyyy"
}) => {
  return (
    <>
      <Field name={name}>
        {({ field, form }) => {
          return (
            <Form.Group controlId={name}>
              {label && (
                <Form.Label className="Input_label">
                  {`${label} `}
                  {iconWithLabel && (
                    <TbExclamationCircle
                      color="#98A2B3"
                      style={{
                        height: '20px',
                        width: '20px',
                        marginLeft: '4px',
                        transform: 'rotate(180deg)',
                      }}
                    />
                  )}
                  {isRequired && <span style={{ color: '#b42318' }}>*</span>}
                </Form.Label>
              )}
              <br />
              <Datepicker
                {...field}
                onChange={(newValue) => {
                  form.setFieldValue(field.name, newValue);
                }}
                className="input form-control"
                selected={field.value}
                placeholderText={placeholder}
                showPopperArrow={false}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                required={isRequired}
                dateFormat={format}
              />
              <ErrorMessage
                name={name}
                component="div"
                className="error mt-2"
              />
            </Form.Group>
          );
        }}
      </Field>
    </>
  );
};

export default FormikDateField;
