import React, { useEffect, useRef, useState } from 'react';
import Modal from 'Components/Modal/Modal';
import Styles from './NewDocumentModal.module.scss';

import { error as errorToast } from 'helpers/toaster';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { useSelector } from 'react-redux';
import ImportDraftModal from 'Containers/Modal/ImportDraftModal/ImportDraftModal';
import PickTemplateModal from 'Containers/Modal/PickTemplateModal/PickTemplateModal';
import NewDocumentCard from './NewDocumentCard';
import ContentGenerationModal from 'Containers/Modal/ContentGenerationModal/ContentGenerationModal';
import { generateStreamResponse } from 'helpers/openAIChat';
import useCreateDocument from 'hooks/documents/useCreateDocument';

const NewDocumentModal = ({
  show,
  onHide,
  aiPowered,
  taskId,
  taskName = 'Untitled Document',
  projectId,
  folderId,
}) => {
  const [showPickTemplateModal, setShowPickTemplateModal] = useState(false);
  const [showImportDraftModal, setShowImportDraftModal] = useState(false);
  const [showContentGeneration, setShowContentGeneration] = useState(false);

  const [streamContent, setStreamContent] = useState('');
  const [contentGenerated, setContentGenerated] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { id: userId } = user || {};

  const { mutateAsync: createDocument, isLoading } = useCreateDocument({
    userId,
  });

  const abortController = useRef();

  const navigate = useNavigate();

  const handleFreestyle = async () => {
    await createDocument(
      {
        document: {
          title: taskName,
          user_id: userId,
        },
        taskId,
        projectId,
        folderId,
      },
      {
        onSuccess: (documentResponse) => {
          const documentId = documentResponse?.data?.document?.id;
          if (!documentId) return;

          onHide();
          navigate(ROUTES.FreestyleDocumentTemplate(documentId));
        },
      },
    );
  };

  const handleAICreate = async () => {
    abortController.current = new AbortController();
    setStreamContent('');
    setContentGenerated(false);
    setShowContentGeneration(true);

    try {
      const URL = `${process.env.REACT_APP_URL}/api/v1/completion_stream_data_style_guides`;
      const BODY = JSON.stringify({
        project_id: projectId,
        task_id: taskId,
      });

      await generateStreamResponse(
        URL,
        BODY,
        setStreamContent,
        setContentGenerated,
        abortController?.current.signal,
      );
    } catch (error) {
      errorToast(error);
    }
  };

  const handleContentGenerated = async () => {
    await createDocument(
      {
        document: {
          title: taskName,
          user_id: userId,
          content: streamContent,
        },
        taskId,
        projectId,
        folderId,
      },
      {
        onSuccess: (documentResponse) => {
          const documentId = documentResponse?.data?.document?.id;
          if (!documentId) return;

          navigate(ROUTES.FreestyleDocumentTemplate(documentId));
        },
      },
    );
  };

  useEffect(() => {
    if (contentGenerated) {
      handleContentGenerated();
    }
  }, [contentGenerated]);

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" title="New Document">
        <div className={Styles.Container}>
          {aiPowered ? (
            <NewDocumentCard
              type="ai"
              title="Let AI create"
              description="AI draft based on your Project and Brief settings"
              isLoading={isLoading}
              onClick={() => handleAICreate()}
            />
          ) : (
            <NewDocumentCard
              type="template"
              title="Pick a Template"
              description="Find the right framework to start filling in your thoughts"
              isLoading={isLoading}
              onClick={() => setShowPickTemplateModal(true)}
            />
          )}
          <NewDocumentCard
            type="draft"
            title="Import a Draft"
            description="Repurpose an old post or upload a draft to get started"
            isLoading={isLoading}
            onClick={() => setShowImportDraftModal(true)}
          />
          <NewDocumentCard
            type="freestyle"
            title="Freestyle"
            description="Facing a writer's block? Let our AI editor help you"
            isLoading={isLoading}
            onClick={handleFreestyle}
          />
        </div>
      </Modal>

      <ContentGenerationModal
        onHide={() => {
          setShowContentGeneration(false);
          setStreamContent('');
          setContentGenerated(false);
          abortController?.current.abort();
        }}
        show={showContentGeneration}
        string={streamContent}
        contentGenerated={contentGenerated}
      />

      {showPickTemplateModal && (
        <PickTemplateModal
          show={showPickTemplateModal}
          onHide={() => {
            setShowPickTemplateModal(false);
            onHide();
          }}
          taskId={taskId}
          folderId={folderId}
        />
      )}

      {showImportDraftModal && (
        <ImportDraftModal
          show={showImportDraftModal}
          onHide={() => {
            setShowImportDraftModal(false);
            onHide();
          }}
          taskId={taskId}
          folderId={folderId}
        />
      )}
    </>
  );
};

export default NewDocumentModal;
