import React from 'react';
import Styles from './PlanDetailCard.module.scss';
import { HiPencil } from 'react-icons/hi2';
import Form from 'react-bootstrap/Form';
import ImageView from 'Components/Image/ImageView';
import { LuClock } from 'react-icons/lu';

const PlanDetailCard = ({
  plan,
  selectedPlanType,
  setSelectedPlanType,
  isDisabledSwitch,
  setIsModalOpen,
  isTrial = false,
}) => {
  return (
    <div className={Styles.outerContainer}>
      <div className={Styles.outerContainer__header}>
        <div className="d-flex align-items-center gap-3">
          <span className={Styles.outerContainer__header__heading}>
            Plan Details
          </span>
          {isTrial && (
            <div className={Styles.trail_days}>
              <LuClock color="#00a7b7" size={'16px'} />
              <span>7 days free trial</span>
            </div>
          )}
        </div>
        <button
          className={Styles.outerContainer__header__btn}
          onClick={() => setIsModalOpen(true)}
        >
          <HiPencil size={16} />
          <span>Change Plan</span>
        </button>
      </div>
      <div className={Styles.outerContainer__card}>
        <div className={Styles.outerContainer__card__left}>
          <div className={Styles.outerContainer__card__left__top}>
            <span className={Styles.outerContainer__card__left__top__heading}>
              {plan?.type}
            </span>
            <span className={Styles.outerContainer__card__left__top__detail}>
              ({selectedPlanType === 'month' ? 'Monthly' : 'Annually'})
            </span>
          </div>
          <div className={Styles.outerContainer__card__left__bottom}>
            <span
              className={Styles.outerContainer__card__left__bottom__heading}
            >
              ${Number(plan?.amount)}
            </span>
            <span className={Styles.outerContainer__card__left__bottom__detail}>
              /{selectedPlanType}
            </span>
          </div>
        </div>
        <div className={Styles.outerContainer__card__right}>
          <span>Monthly </span>
          <Form>
            <Form.Check
              type="switch"
              id="custom-switch"
              label=""
              className={Styles.outerContainer__card__right__switch}
              onChange={() => {
                setSelectedPlanType(
                  selectedPlanType === 'year' ? 'month' : 'year',
                );
              }}
              checked={selectedPlanType === 'year'}
              disabled={isDisabledSwitch}
            />
          </Form>
          <span>Yearly</span>
        </div>
      </div>
    </div>
  );
};

export default PlanDetailCard;
