import { Extension } from '@tiptap/core';

export const AfterParagraphSpacing = Extension.create({
  name: 'afterParagraphSpacing',

  addOptions() {
    return {
      types: ['heading', 'paragraph'],
      spacing: ['2rem'],
      defaultHeight: '0px',
      after: true,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          afterParagraphSpacing: {
            default: this.options.defaultHeight,
            parseHTML: (element) =>
              element.style.marginBottom || this.options.defaultHeight,
            renderHTML: (attributes) => {
              if (
                attributes.afterParagraphSpacing === this.options.defaultHeight
              ) {
                return {};
              }

              return {
                style: `${'margin-bottom'}: ${
                  attributes.afterParagraphSpacing || '2rem'
                }`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setAfterParagraphSpacing:
        (height) =>
        ({ commands }) => {
          if (!this.options.spacing.includes(height)) {
            return false;
          }

          return this.options.types.every((type) =>
            commands.updateAttributes(type, {
              afterParagraphSpacing: height,
            }),
          );
        },

      unsetAfterParagraphSpacing:
        () =>
        ({ commands }) => {
          return this.options.types.every((type) =>
            commands.resetAttributes(type, 'afterParagraphSpacing'),
          );
        },
    };
  },
});
