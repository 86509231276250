import React, { useState, useEffect } from 'react';
import Styles from './ImageUploader.module.scss';
import ImageView from 'Components/Image/ImageView';
import { FiPlus } from 'react-icons/fi';

const ImageUploadedState = ({ selectedImage, onImageChange }) => {
  const [localImage, setLocalImage] = useState(selectedImage);
  const [showChangeProfile, setShowChangeProfile] = useState(false);

  const handleImageChange = (event) => {
    const file = event?.target?.files?.[0]; 
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageDataUrl = reader?.result;
        setLocalImage(imageDataUrl); // Update local state
        onImageChange(imageDataUrl);  // Update Formik state
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    // if (selectedImage) {
      setLocalImage(selectedImage);
    // }
  }, [selectedImage]);

  return (
    <div
      onMouseEnter={() => setShowChangeProfile(true)}
      onMouseLeave={() => setShowChangeProfile(false)}
      className={'d-flex position-relative ' + Styles.ProfileImageSection}
    >
      {localImage ? (
        <ImageView
          class={'img-fluid ' + Styles.profileImage}
          alt="Profile Image"
          src={localImage}
        />
      ) : (
        <span className={'rounded-profile ' + Styles.profileImage}>
          <FiPlus size={20} color="#667085" />
        </span>
      )}
      <input
        className={Styles.profileImage_Input}
        type="file"
        name="myImage"
        onChange={handleImageChange}
        accept="image/*"
      />
      {showChangeProfile && (
        <div className={`d-flex justify-content-center align-items-center ${Styles.profileImage_Input} ${Styles.profileImage_Input_text}`}>
          <FiPlus size={20} />
        </div>
      )}
    </div>
  );
};

export default ImageUploadedState;
