import React, { useEffect, useRef, useState } from 'react';
import { TbRefresh } from 'react-icons/tb';
import {
  FiEdit3,
  FiFileText,
  FiMessageCircle,
  FiScissors,
} from 'react-icons/fi';
import { FaChalkboard, FaLightbulb, FaParagraph } from 'react-icons/fa';
import { BiCommentAdd, BiListCheck } from 'react-icons/bi';
import ImageView from 'Components/Image/ImageView';
import { CloseButton } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getNameInitials, getUserImage } from 'helpers';
import { BsFillArrowRightCircleFill, BsPersonCheckFill } from 'react-icons/bs';
import { Mention, MentionsInput } from 'react-mentions';
import { mentionsInputStyle, mentionStyle } from 'constants';
import styleComment from './../Editor.module.scss';
import { PiDotsThreeOutlineBold } from 'react-icons/pi';

function ContextMenu({
  menuPosition,
  checkCommentExist,
  handleExpand,
  setMenuPosition,
  addComment,
  isComment,
  handleWriteParagraph,
  mainContainerRef,
  userList,
}) {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocusInput = () => {
    setIsFocused(true);
  };
  const menuRef = useRef(null);
  const nestedMenuRef = useRef(null);
  const [commentAdd, setCommentAdd] = useState(false);
  const [comment, setComment] = useState('');
  const [mentionedUsers, setMentionedUsers] = useState([]);
  const [showNestedList, setShowNestedList] = useState({});

  const { user } = useSelector((state) => state.auth);
  const { name: userName, mini_avatar: userAvatar } = user || {};

  useEffect(() => {
    const handleClickOutside = (event) => {
      const suggestionEle = document.querySelectorAll('[role="listbox"]')[0];
      const isNestedMenuPresent = nestedMenuRef.current;

      if (!isNestedMenuPresent) {
        if (
          menuRef.current &&
          !menuRef.current.contains(event.target) &&
          !suggestionEle?.contains(event.target)
        ) {
          setMenuPosition({ left: 0, top: 0 });
        }
      }
      if (
        nestedMenuRef.current &&
        !nestedMenuRef.current.contains(event.target) &&
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !suggestionEle?.contains(event.target)
      ) {
        setMenuPosition({ left: 0, top: 0 });
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    let mention = [];
    userList.forEach((userValue) => {
      const matchExist = comment.includes(
        `@[${userValue.display}](${userValue.id})`,
      );
      if (matchExist) {
        mention = [...mention, userValue.id];
      } else {
        mention = mention.filter((val) => val !== userValue.id);
      }
    });

    setMentionedUsers(mention);
  }, [comment]);

  const addToComments = (comment) => {
    addComment(comment, mentionedUsers);
    setComment('');
    setMenuPosition({ left: 0, top: 0 });
  };

  const selection = document.getSelection();
  const { anchorNode } = selection || {};
  const { data, parentElement } = anchorNode || {};
  const { nodeName } = parentElement || {};

  const isHeading =
    nodeName === 'H1' ||
    nodeName === 'H2' ||
    nodeName === 'H3' ||
    nodeName === 'H4' ||
    nodeName === 'H5' ||
    nodeName === 'H6';

  return (
    <>
      <div
      // style={{
      //   position: 'absolute',
      //   top: menuPosition.top,
      //   left: menuPosition.left,
      //   background: 'white',
      //   // borderRadius: '50px',
      //   overflow: 'hidden',
      //   border: '1px solid #F2F4F7',
      //   boxShadow:
      //     '0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)',
      //   zIndex: 99999,
      //   maxHeight: 150,
      // }}
      // ref={menuRef}
      // className="dimension-menu"
      >
        {!commentAdd ? (
          <div
            style={{
              position: 'absolute',
              top: menuPosition.top,
              left: menuPosition.left,
              background: 'white',
              borderRadius: '50px',
              overflow: 'hidden',
              border: '1px solid #F2F4F7',
              boxShadow:
                '0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)',
              zIndex: 99999,
              maxHeight: 150,
            }}
            ref={menuRef}
            className="dimension-menu"
          >
            <ul
              className={`d-flex container_comment`}
              style={{ borderRadius: '50px' }}
            >
              {!isComment && (
                <li
                  onMouseEnter={(event) => {
                    const clientRects = event.currentTarget.getClientRects()[0];
                    setShowNestedList({
                      top: clientRects.top,
                      left: clientRects.left,
                    });
                  }}
                  className="text-md-Regular"
                  onClick={() => checkCommentExist()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M11.6587 4.54484L10.9738 2.95573L9.31741 2.22474L10.9738 1.50965L11.6587 0L12.3436 1.50965L14 2.22474L12.3436 2.95573L11.6587 4.54484ZM11.6587 14L10.9738 12.4745L9.31741 11.7594L10.9738 11.0443L11.6587 9.43927L12.3436 11.0443L14 11.7594L12.3436 12.4745L11.6587 14ZM4.66667 11.5528L3.20137 8.42225L0 6.99205L3.20137 5.56186L4.66667 2.44722L6.14789 5.56186L9.33333 6.99205L6.14789 8.42225L4.66667 11.5528Z"
                      fill="#F79009"
                    />
                  </svg>
                </li>
              )}
              <li
                className="text-md-Regular"
                onClick={() => {
                  setCommentAdd(true);
                  setShowNestedList({});
                }}
              >
                <FiMessageCircle size={14} />
              </li>
              {/* <li className="text-md-Regular">
                <PiDotsThreeOutlineBold size={14} />
              </li> */}
            </ul>
          </div>
        ) : (
          <div
            style={{
              position: 'absolute',
              top: menuPosition.top,
              left: menuPosition.left,
              background: 'white',
              borderRadius: '8px',
              overflow: 'hidden',
              border: '1px solid #F2F4F7',
              boxShadow:
                '0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)',
              zIndex: 99999,
              maxHeight: 150,
            }}
            ref={menuRef}
            className="dimension-menu"
          >
            <div className="contextMenu--option">
              <section style={{ width: '330px' }}>
                <div
                  className="d-flex justify-content-between"
                  style={{ padding: '12px 18px' }}
                >
                  <div className="d-flex g-10 align-items-center">
                    <div className="stacked-Images-rounded d-flex">
                      {userAvatar ? (
                        <ImageView
                          src={userAvatar}
                          class="rounded-avatar-bordered"
                        />
                      ) : (
                        <span className={' rounded-profile '}>
                          {getNameInitials(userName)}
                        </span>
                      )}
                    </div>
                    <div className="d-flex flex-column">
                      <span
                        className={'text-md ' + styleComment.Comment_authorName}
                      >
                        {userName}
                      </span>
                    </div>
                  </div>
                  <CloseButton
                    aria-label="Hide"
                    onClick={() => setMenuPosition({ left: 0, top: 0 })}
                    style={{ width: '0.35em', height: '0.35em' }}
                  />
                </div>
                <div className="comment-adder-section">
                  <div className="addCommentSection position-relative">
                    <MentionsInput
                      placeholder="Add a comment"
                      value={comment}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                          e.stopPropagation();
                          //   setComment()
                          setCommentAdd(false);

                          comment && comment.trim() && addToComments(comment);
                        }
                      }}
                      onFocus={handleFocusInput}
                      onBlur={() => {
                        setIsFocused(false);
                      }}
                      onChange={(e) => setComment(e.target.value)}
                      style={mentionsInputStyle}
                      a11ySuggestionsListLabel="Suggested mentions"
                      suggestionsPortalHost={mainContainerRef}
                    >
                      <Mention
                        style={mentionStyle}
                        data={userList}
                        appendSpaceOnAdd
                        displayTransform={(_, display) => `@${display}`}
                        renderSuggestion={(suggestion) => {
                          return (
                            <div className="d-flex align-items-center gap-3">
                              {suggestion.avatar ? (
                                <ImageView
                                  src={suggestion.avatar}
                                  class="rounded-profile-x-sm"
                                />
                              ) : (
                                getUserImage(
                                  suggestion.avatar,
                                  suggestion.display,
                                  {
                                    width: 26,
                                    height: 26,
                                  },
                                  'sm',
                                )
                              )}
                              <p>{suggestion.display}</p>
                            </div>
                          );
                        }}
                      />
                    </MentionsInput>
                    <div
                      onClick={() =>
                        comment && comment.trim() ? addToComments(comment) : {}
                      }
                      className={`arrow_circle_input ${
                        isFocused && comment && comment.trim()
                          ? 'activeSVG'
                          : ''
                      }`}
                    >
                      <BsFillArrowRightCircleFill size={28} color="#D0D5DD" />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )}
      </div>

      {showNestedList.top && (
        <div
          className="dimension-menu"
          style={{
            position: 'absolute',
            top: showNestedList.top,
            left: showNestedList.left + 92,
            background: 'white',
            borderRadius: '8px',
            border: '1px solid #F2F4F7',
            boxShadow:
              '0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)',
          }}
          ref={nestedMenuRef}
        >
          <ul>
            <li className="text-md-Regular" onClick={() => handleExpand()}>
              <FiEdit3 color="#667085" />
              Expand
            </li>
            <li
              className="text-md-Regular"
              onClick={() => checkCommentExist('humanize')}
            >
              <BsPersonCheckFill color="#667085" />
              Humanize
            </li>
            <li
              className="text-md-Regular"
              onClick={() => checkCommentExist('clarify')}
            >
              <FaLightbulb color="#667085" />
              Clarify
            </li>
            <li
              className="text-md-Regular"
              onClick={() => checkCommentExist('shorten')}
            >
              <FiScissors color="#667085" />
              Shorten
            </li>
            <li
              className="text-md-Regular"
              onClick={() => checkCommentExist('exemplify')}
            >
              <FaChalkboard color="#667085" />
              Exemplify
            </li>
            <li
              className="text-md-Regular"
              onClick={() => checkCommentExist('explain')}
            >
              <FiFileText color="#667085" />
              Explain
            </li>
            <li
              className="text-md-Regular"
              onClick={() => checkCommentExist('summarize')}
            >
              <BiListCheck color="#667085" />
              Summarize
            </li>
            {isHeading && (
              <li
                className="text-md-Regular"
                onClick={() => {
                  setMenuPosition({ left: 0, top: 0 });
                  handleWriteParagraph(data);
                }}
              >
                <FaParagraph color="#667085" />
                Write Paragraph
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
}

export default ContextMenu;
