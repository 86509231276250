import React from 'react';
import { Link } from 'react-router-dom';

import Styles from './TableBody.module.scss';

const KeywordStatsTable = ({ keywordVariations }) => {
  return (
    <tbody>
      {keywordVariations?.map((keywordVariation) => {
        const {
          id,
          title,
          volume,
          results,
          KeywordDifficulty,
          CPC,
          link,
          address,
          ESTVisits,
          Backlinks,
        } = keywordVariation;
        return (
          <tr key={id} className={Styles.TableRow}>
            {title && (
              <td
                className={`d-flex align-items-center g-10 text-md-Regular ${Styles.TableData} ${Styles.TableData__Extensive}`}
              >
                {title}
              </td>
            )}
            {volume && (
              <td className={'text-md-Regular ' + Styles.TableData_Title}>
                {volume}
              </td>
            )}
            {results && (
              <td className={'text-md-Regular ' + Styles.TableData_Title}>
                {results}
              </td>
            )}
            {KeywordDifficulty && (
              <td className={'text-md-Regular ' + Styles.TableData_Title}>
                {KeywordDifficulty}
              </td>
            )}
            {CPC && (
              <td className={'text-md-Regular ' + Styles.TableData_Title}>
                {CPC}
              </td>
            )}
            {link && (
              <td className={'text-md-Regular ' + Styles.TableData_Title}>
                <div className="d-flex flex-column">
                  <Link
                    className="link"
                    to={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link}
                  </Link>
                  {address}
                </div>
              </td>
            )}
            {ESTVisits && (
              <td className={'text-md-Regular ' + Styles.TableData_Title}>
                {ESTVisits}
              </td>
            )}
            {Backlinks && (
              <td className={'text-md-Regular ' + Styles.TableData_Title}>
                {Backlinks}
              </td>
            )}
          </tr>
        );
      })}
    </tbody>
  );
};

export default KeywordStatsTable;
