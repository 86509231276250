import Paragraph from '@tiptap/extension-paragraph';

export default Paragraph.extend({
  addAttributes() {
    return {
      customAttribute: {
        default: null,
        parseHTML: (element) => ({
          style: element.getAttribute('data-style'),
          id: element.getAttribute('data-id') || '123',
          comment: element.getAttribute('data-comment'),
        }),
        renderHTML: (attributes) => {
          return {
            style: attributes.customAttribute?.style || '',
            id: attributes.customAttribute?.id || '',
          };
        },
        renderText: (attributes) => attributes && attributes.customAttribute,
      },
    };
  },
});
