import React, { useState, useEffect } from 'react';
import styles from './Ticker.module.scss';
import Ticker1 from '../../assets/login/div.png';
import Ticker2 from '../../assets/login/div (1).png';
import Ticker3 from '../../assets/login/mv-logo 1.png';
import Ticker4 from '../../assets/login/SiteLogo.png';

const Ticker = () => {
  const [direction, setDirection] = useState('leftToRight');
  const logos = [Ticker1, Ticker2, Ticker3, Ticker4];

  useEffect(() => {
    const toggleDirection = () => {
      setDirection((prevDirection) =>
        prevDirection === 'leftToRight' ? 'rightToLeft' : 'leftToRight',
      );
    };

    const interval = setInterval(toggleDirection, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.overflowHidden}>
      <div className={`${styles.imgTicker} ${styles[direction]}`}>
        {logos.concat(logos, logos).map((src, index) => (
          <img
            key={index}
            className={styles.tickerlogo}
            src={src}
            alt={`Logo ${(index % logos.length) + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Ticker;
