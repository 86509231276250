import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: null,
  loaderHeading: '',
  laoderDescription: '',
};

const loaderSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      return {
        isLoading: action.payload.isLoading,
        loaderHeading: action.payload.heading || '',
        laoderDescription: action.payload.description || '',
      };
    },
  },
});

const { reducer, actions } = loaderSlice;

export const { setLoader } = actions;
export default reducer;
