import React, { useState, useEffect } from 'react';
import Styles from './ImageUploader.module.scss';
import ImageView from 'Components/Image/ImageView';
// import DefaultImage from '../../../../assets/login/vocable..svg';
import { FiPlus } from 'react-icons/fi';

const ImageUploader = ({ setFieldValue, fieldName, initialImage }) => {
  const [selectedImage, setSelectedImage] = useState(initialImage );
  const [showChangeProfile, setShowChangeProfile] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result);
      setFieldValue(fieldName, reader.result); // Update Formik value
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (initialImage) {
      setSelectedImage(initialImage);
    }
  }, [initialImage]);

  return (
    <div
      onMouseEnter={() => setShowChangeProfile(true)}
      onMouseLeave={() => setShowChangeProfile(false)}
      className={'d-flex position-relative ' + Styles.ProfileImageSection}
    >
      {selectedImage ? (
        <ImageView
          class={'img-fluid ' + Styles.profileImage}
          alt="Profile Image"
          src={selectedImage}
        />
      ) : (
        <span className={'rounded-profile ' + Styles.profileImage}>
          <FiPlus size={20} color="#667085" />
        </span>
      )}
      <input
        className={Styles.profileImage_Input}
        type="file"
        name="myImage"
        onChange={handleImageChange}
        accept="image/*"
      />
      {showChangeProfile && (
        <div className={`d-flex justify-content-center align-items-center ${Styles.profileImage_Input} ${Styles.profileImage_Input_text}`}>
          <FiPlus size={20} />
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
