// SubscriptionCardForm.js
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useRef, useState } from 'react';
import CheckoutForm from './CheckoutForm';
import sc from './Subscription.module.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

function SubscriptionCardForm({
  product,
  isProductView = true,
  refetch,
  isTrial,
  onHide,
}) {
  const checkoutFormRef = useRef(null);
  const [disableSubmitCardButton, setDisableSubmitCardButton] = useState(true);

  const appearance = {
    theme: 'stripe',
  };

  const { currency, amount, id: priceId } = product || {};

  const options = {
    mode: isProductView && !isTrial ? 'subscription' : 'setup',
    amount: isProductView && !isTrial ? Number(amount || 0) * 100 : undefined,
    currency: currency || 'usd',
    appearance,
    paymentMethodTypes: ['card'], // Specify only card payment method
  };

  const handlePayment = async () => {
    try {
      await checkoutFormRef.current.handleSubmit(isProductView);
      onHide();
      refetch && refetch();
    } catch (error) {}
  };

  return (
    <div className="subscription-card-form">
      <p className={`${sc.heading_billing_payment}`}>Add New Payment Card</p>
      <div>
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            ref={checkoutFormRef}
            priceId={priceId}
            isProductView={isProductView}
            setDisableSubmitCardButton={setDisableSubmitCardButton}
            refetch={() => {
              refetch && refetch();
              onHide();
            }}
          />
        </Elements>
      </div>
      <div className="d-flex align-items-center gap-2">
        <button className="cancel_btn" onClick={onHide}>
          Cancel
        </button>
        <button
          className="secondary_btn"
          disabled={disableSubmitCardButton}
          onClick={() => handlePayment()}
        >
          Add Card
        </button>
      </div>
    </div>
  );
}

export default SubscriptionCardForm;
