import React, { useEffect, useRef } from 'react';
import Styles from './Carousel.module.scss';

const Carousel = ({ children, gap }) => {
  const carouselRef = useRef(null);

  useEffect(() => {
    const scroller = carouselRef.current;

    // If a user hasn't opted in for reduced motion, then we add the animation
    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      addAnimation(scroller);
    }

    // Add event listeners to pause and resume animation on hover
    const scrollerInner = scroller.querySelector('.scroller__inner');
    scrollerInner.addEventListener('mouseover', () => {
      scrollerInner.style.animationPlayState = 'paused';
    });
    scrollerInner.addEventListener('mouseout', () => {
      scrollerInner.style.animationPlayState = 'running';
    });

    return () => {
      scrollerInner.removeEventListener('mouseover', () => {
        scrollerInner.style.animationPlayState = 'paused';
      });
      scrollerInner.removeEventListener('mouseout', () => {
        scrollerInner.style.animationPlayState = 'running';
      });
    };
  }, []);

  const addAnimation = (scroller) => {
    // Add data-animated="true" to the scroller
    scroller.setAttribute('data-animated', true);

    // Make an array from the elements within `.scroller__inner`
    const scrollerInner = scroller.querySelector('.scroller__inner');
    const scrollerContent = Array.from(scrollerInner?.children);

    // For each item in the array, clone it
    // Add aria-hidden to it
    // Add it into the `.scroller-inner`
    scrollerContent.forEach((item) => {
      const duplicatedItem = item.cloneNode(true);
      duplicatedItem.setAttribute('aria-hidden', true);
      scrollerInner.appendChild(duplicatedItem);
    });
  };

  return (
    <div className={Styles.scroller} data-speed="fast" ref={carouselRef}>
      <ul
        className={`${Styles.tag_list} ${Styles.scroller__inner} scroller__inner ${gap && Styles.gap}`}
        id="scroller__inner"
      >
        <li>{children}</li>
      </ul>
    </div>
  );
};

export default Carousel;
