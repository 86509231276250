import React, { useEffect, useState } from 'react';
import RadioButton from 'Components/RadioButton/RadioButton';
import Input from 'Components/Input/Input';
import ProgressBar from 'react-bootstrap/ProgressBar';
import sc from './AITool.module.scss';
import ImageView from 'Components/Image/ImageView';
import ProgressBarContent from 'Containers/Modal/ImportDraftModal/ProgressBarContent';
import StyleClasses from 'Containers/Modal/ImportDraftModal/ImportDraftModal.module.scss';

const AISToolInputSection = ({
  content,
  setContent,
  url,
  setUrl,
  file,
  setFile,
  isSubmitButtonDisabled,
  selectedOption,
  setSelectedOption,
}) => {
  const [fileUploading, setFileUploading] = useState(false);
  const [startUploading, setStartUploading] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectedFile, setSelectedFile] = useState();

  const fileUploadingHandler = () => {
    // fileUploading && setCurrentProgress(0);
    if (fileUploading === false) {
      setStartUploading(true);
    }
    setFileUploading(!fileUploading);
    setIsFilePicked(!isFilePicked);
  };

  useEffect(() => {
    if (isSubmitButtonDisabled && fileUploading) {
      fileUploadingHandler();
    }
  }, [isSubmitButtonDisabled]);

  const changeHandler = async (event) => {
    setSelectedFile(event.target.files[0]);
    fileUploadingHandler();
    setContent('');
  };

  useEffect(() => {
    let interval;
    if (startUploading) {
      if (currentProgress < 100) {
        interval = setInterval(() => {
          setCurrentProgress((preValue) => preValue + 10);
        }, 200);
      } else {
        setFile(selectedFile);
        setStartUploading(false);
        clearInterval(interval);
      }
    }

    return () => clearInterval(interval);
  }, [currentProgress, startUploading]);

  const uploadFileLabel = (
    <section className={`${sc.AISTool__UploadSection}`}>
      <p className="Input_label mb-3">Upload a file</p>
      {!(fileUploading || file) ? (
        <section className={sc.AISTool__DragDropArea}>
          <div className="d-flex flex-column justify-content-center align-items-center text-center ">
            <ImageView src="/Images/UploadIconColored.svg" class="img-fluid" />
            <p className={sc.AISTool__DragDropArea__fileMsg}>
              <span className={sc.AISTool__DragDropArea__fileMsg_highlight}>
                Click to upload
              </span>{' '}
              or drag and drop
            </p>
            <p className={sc.AISTool__DragDropArea__fileMsg_format}>
              {' '}
              PDF, TXT, DOCX or RTF (max. 1MB)
            </p>
          </div>
          <Input type="file" onChange={changeHandler} />
        </section>
      ) : (
        <ProgressBar
          className={StyleClasses.file_UploadStatusWrapper}
          now={currentProgress}
          label={
            <ProgressBarContent
              currentProgress={currentProgress}
              fileUploadingHandler={() => {
                fileUploadingHandler();
                setSelectedFile();
                setFile();
              }}
              selectedFile={selectedFile}
            />
          }
          min={0}
          max={100}
          bsPrefix="file-upload-progress"
          animated={true}
        />
      )}
    </section>
  );

  const PasteContentLabel = (
    <section>
      <section className={`${sc.AISTool__UploadSection}`}>
        <p className="Input_label mb-3">Paste your content</p>
        <Input
          type={'textarea'}
          classList={`input ${sc.AISTool__TextArea}`}
          placeholder="Type here..."
          value={content}
          onChange={({ target: { value } }) => {
            setContent(value);
            setUrl('');
            setFile();
          }}
        ></Input>
      </section>
    </section>
  );

  const PasteURLLabel = (
    <section>
      <section className={`${sc.AISTool__UploadSection}`}>
        <p className="Input_label mb-3">Paste a URL to an Article</p>
        <Input
          type={'url'}
          classList={`input ${sc.AISTool__URL}`}
          placeholder="Insert URL here..."
          value={url}
          onChange={({ target: { value } }) => {
            setContent('');
            setUrl(value);
            setFile();
          }}
        ></Input>
      </section>
    </section>
  );

  return (
    <div className={`p-3 ${sc.AISTool}`}>
      <RadioButton
        type={'radio'}
        customLabel={true}
        label={uploadFileLabel}
        id={1}
        name={'Summarization'}
        key={1}
        FullWidth={true}
        onChange={() => setSelectedOption('file')}
      ></RadioButton>
      <RadioButton
        type={'radio'}
        customLabel={true}
        label={PasteContentLabel}
        id={2}
        name={'Summarization'}
        key={2}
        FullWidth={true}
        onChange={() => setSelectedOption('pastedContent')}
      />
      <RadioButton
        type={'radio'}
        customLabel={true}
        label={PasteURLLabel}
        id={3}
        name={'Summarization'}
        key={3}
        FullWidth={true}
        onChange={() => setSelectedOption('pastedUrl')}
      />
    </div>
  );
};

export default AISToolInputSection;
