import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Modal } from 'react-bootstrap';
import React, { useRef, useState } from 'react';
import CheckoutForm from './CheckoutForm';

function SubscriptionCardModal({
  show,
  onHide,
  product,
  isProductView = true,
  refetch,
  isTrial,
  initiateTrial,
}) {
  const checkoutFormRef = useRef(null);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
  const [disableSubmitCardButton, setDisableSubmitCardButton] = useState(true);

  const appearance = {
    theme: 'stripe',
  };

  const { currency, amount, id: priceId } = product || {};

  const options = {
    mode: isProductView && !isTrial ? 'subscription' : 'setup',
    amount: isProductView && !isTrial ? Number(amount || 0) * 100 : undefined,
    currency: currency || 'usd',
    // Fully customizable with appearance API.
    appearance,
  };

  const handlePayment = async () => {
    try {
      await checkoutFormRef.current.handleSubmit(isProductView && !isTrial);
      if (isTrial) {
        await initiateTrial(product);
      }
    } catch (error) {}
  };

  return (
    <Modal
      className="modalPlanRenew"
      dialogClassName="modalWidth__600"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
      contentClassName={isProductView && !isTrial ? '' : 'modal-height'}
    >
      <Modal.Body className="p-4 modalBody">
        <div className="modalHeadingWrapper">
          <h2 className="heading" style={{ fontFamily: 'Poppins' }}>
            Add Card Details
          </h2>

          <span className="closeIcon" onClick={onHide}>
            <img src="/Images/closeModalIcon.svg" alt="closeIcon" />
          </span>
        </div>
        <div>
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              ref={checkoutFormRef}
              priceId={priceId}
              isProductView={isProductView && !isTrial}
              setDisableSubmitCardButton={setDisableSubmitCardButton}
              refetch={() => {
                refetch && refetch();
                onHide();
              }}
            />
          </Elements>
        </div>
        <div className="btnWrapper">
          <button
            className="primary_btn w-100 px-3 py-2"
            disabled={disableSubmitCardButton}
            onClick={() => handlePayment()}
          >
            Confirm
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SubscriptionCardModal;
