export function computeNewDimensions(
  img,
  diff,
  aspectRatio,
  direction,
  containerWidth,
) {
  const currentMediaDimensions = {
    width: img.width,
    height: img.height,
  };

  const newMediaDimensions = {
    width: -1,
    height: -1,
    dataheight: Math.round(img.height),
    datawidth: Math.round(img.width),
  };

  if (direction === 'left' || direction === 'right') {
    newMediaDimensions.width =
      currentMediaDimensions.width +
      (direction === 'left' ? -1 : 1) * Math.abs(diff);
    newMediaDimensions.height = newMediaDimensions.width / aspectRatio.current;
  } else if (direction === 'up' || direction === 'down') {
    newMediaDimensions.height =
      currentMediaDimensions.height +
      (direction === 'up' ? -1 : 1) * Math.abs(diff);
    newMediaDimensions.width = newMediaDimensions.height * aspectRatio.current;
  }

  if (newMediaDimensions.width > containerWidth.current) {
    newMediaDimensions.width = containerWidth.current;
    newMediaDimensions.height = newMediaDimensions.width / aspectRatio.current;
  }

  newMediaDimensions.dataheight = `${Math.round(newMediaDimensions.height)}px`;
  newMediaDimensions.datawidth = `${Math.round(newMediaDimensions.width)}px`;

  return newMediaDimensions;
}
