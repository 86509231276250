import { mergeAttributes, Node } from '@tiptap/core';

export const Span = Node.create({
  name: 'span',

  priority: 1000,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      customAttribute: {
        default: null,
        parseHTML: (element) => ({
          editable: element.getAttribute('data-editable'),
          class: element.getAttribute('data-class'),
        }),
        renderHTML: (attributes) => {
          return {
            contenteditable:
              attributes.customAttribute?.editable === 'false'
                ? 'false'
                : 'true',
            class: attributes.customAttribute?.class,
          };
        },
        renderText: (attributes) => attributes && attributes.customAttribute,
      },
    };
  },

  group: 'block',

  content: 'inline*',

  parseHTML() {
    return [{ tag: 'span' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setParagraph:
        () =>
        ({ commands }) => {
          return commands.setNode(this.name);
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Alt-1': () => this.editor.commands.setParagraph(),
    };
  },
});
