import React from 'react';
import {
  EMAIL_REGEX,
  INPUT_REGEX,
  LINK_QUESTIONS,
  LINK_REGEX,
} from 'constants';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const removeSpacesAndHyphens = (str) => {
  return str.replace(/-|\s/g, '');
};

export const sortArrayCustomOrder = (
  arr,
  customOrder = ['Basic Plan', 'Pro Plan', 'Business Plan'],
) => {
  return arr.sort(function (a, b) {
    return customOrder.indexOf(a.type) - customOrder.indexOf(b.type);
  });
};

export const unsecuredCopyToClipboard = (text) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Unable to copy to clipboard', err);
  }
  document.body.removeChild(textArea);
};

export const copyToClipboard = (text, write = false) => {
  if (window.isSecureContext && navigator.clipboard) {
    write
      ? navigator.clipboard.write(text)
      : navigator.clipboard.writeText(text);
  } else {
    unsecuredCopyToClipboard(text);
  }
};

export const isNumber = (value) => {
  return typeof value === 'number' && !isNaN(value);
};

export const getAccessDescription = (name) => {
  switch (name) {
    case 'Full access':
      return 'can edit & share with others';
    case 'Can edit':
      return 'can edit but can’t share';
    case 'Can comment':
      return 'can view and comment only';
    case 'Can view':
      return 'cannot edit/share/comment';

    default:
      break;
  }
};

export const getNameInitials = (name = '') => {
  return name
    .replace('(you)', '')
    .split(' ')
    .map((value) => value[0])
    .join('')
    .toUpperCase();
};

export const transformAiResponse = (str) => {
  const strTo = str.split('END')[0];
  return strTo
    .split('\n')
    .map((val) => val.replace(/^[^a-zA-Z]+/, '').replace(/"$/, ''))
    .filter((val) => !!val.trim())
    .reduce((acc, val) => {
      const value = val
        .split(/(\d+)/)
        .filter((val) => !!val && !isNumber(Number(val)));

      const accvalue = value.map((value1) =>
        value1.replace(/^[^a-zA-Z]+/, '').replace(/"$/, ''),
      );
      acc.push(...accvalue);
      return acc;
    }, []);
};

export const titleize = (word = '', spaceAllowed = true) => {
  return word
    .split('_')
    .map((w) => w[0]?.toUpperCase() + w?.slice(1))
    .join(spaceAllowed ? ' ' : '');
};

export const formattedDate = (date = new Date()) => {
  return [date.getDate(), date.getMonth() + 1, date.getFullYear()]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join('/');
};

export const getIsQuestionSubmitButtonEnabled = (questions) => {
  const errors = {};
  const isSubmitButtonEnabled = questions?.every((subQues) => {
    const {
      inputValue,
      selectedOption,
      input_type: inputType,
      title,
      placeholder,
      required,
      multiple,
      childId,
      id: questionId,
      position,
    } = subQues || {};
    const parentPosition = questions?.find(
      (question) => question.id === questionId,
    ).position;

    const qTitle = multiple
      ? childId
        ? `${title} ${position - parentPosition + 1}`
        : `${title} ${1}`
      : title;
    const questionTitle = `${qTitle || placeholder}`;

    const maxLength = title === 'Headline' ? 200 : 500;

    if (!required) {
      if (inputType === 'number') {
        const isValid = Number(inputValue) >= 0;
        if (isValid) {
          errors[questionTitle] = '';
        } else {
          errors[questionTitle] = 'Input out of range';
        }

        return isValid;
      }

      return true;
    }

    if (inputType === 'radio') {
      return !!selectedOption.length;
    } else {
      if (LINK_QUESTIONS.includes(title)) {
        const isValid = !!inputValue.match(LINK_REGEX);
        if (isValid) {
          errors[questionTitle] = '';
        } else {
          errors[questionTitle] = 'Invalid Link';
        }

        return isValid;
      }

      if (inputType === 'date') {
        const isValid = !!inputValue;
        if (isValid) {
          errors[questionTitle] = '';
        } else {
          errors[questionTitle] = 'Date is required';
        }

        return isValid;
      }

      if (inputType === 'datetime') {
        const isValid = !!inputValue;
        if (isValid) {
          errors[questionTitle] = '';
        } else {
          errors[questionTitle] = 'Date is required';
        }

        return isValid;
      }

      if (inputType === 'number') {
        const isValid = Number(inputValue) >= 0;
        if (isValid) {
          errors[questionTitle] = '';
        } else {
          errors[questionTitle] = 'Input out of range';
        }

        return isValid;
      }
      if (inputType === 'email') {
        const isValid = !!inputValue.match(EMAIL_REGEX);
        if (isValid) {
          errors[questionTitle] = '';
        } else {
          errors[questionTitle] = 'Invalid Email';
        }

        return isValid;
      }

      if (inputType === 'phonenumber') {
        const isValid = !!inputValue && isValidPhoneNumber(inputValue);
        if (isValid) {
          errors[questionTitle] = '';
        } else {
          errors[questionTitle] = 'Invalid Phone number';
        }
        return isValid;
      }

      const isValid =
        !!inputValue &&
        !!inputValue.match(INPUT_REGEX) &&
        inputValue.length >= 3 &&
        inputValue.length <= 500;
      if (isValid) {
        errors[questionTitle] = '';
      } else {
        errors[questionTitle] =
          `Input should have min 3 characters and max ${maxLength} characters and should not start with number`;
      }

      return isValid;
    }
  });

  return { isSubmitButtonEnabled, errors };
};

export const getUserImage = (
  profileImage,
  name,
  styles = {},
  sm = '',
  className = '',
  id = '',
) => {
  return profileImage ? (
    <img key={id} src={profileImage} alt="Profile" style={styles}></img>
  ) : (
    <span
      className={`${
        sm ? 'rounded-profile-x-sm' : className || 'rounded-profile'
      }`}
      key={id}
    >
      {getNameInitials(name)}
    </span>
  );
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getGreeting = () => {
  const today = new Date();
  const curHr = today.getHours();

  if (curHr < 12) {
    return 'Good Morning';
  } else if (curHr < 18) {
    return 'Good Afternoon';
  } else {
    return 'Good Evening';
  }
};

export const getTimeStr = (time) => {
  let h = Math.floor(time / 3600);
  let m = Math.floor((time % 3600) / 60);
  let s = Math.floor((time % 3600) % 60);

  if (h < 10) {
    h = '0' + h;
  }
  if (m < 10) {
    m = '0' + m;
  }
  if (s < 10) {
    s = '0' + s;
  }

  const hStr = h > 0 ? h + ` hour${h > 1 ? 's' : ''} . ` : '';
  const mStr = m > 0 ? m + ` min${m > 1 ? 's' : ''} . ` : '';
  return hStr + mStr + (s || 0) + ' sec';
};

export const getAccessLevel = (shared, user, owner) => {
  if (user?.id === owner?.id) {
    return 'full_access';
  }

  const sharedAccess = shared?.find(
    (share) => share.collaborator.id === user.id,
  )?.access_level?.level;

  return sharedAccess;
};

export const capitalize = (str) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const calculatePercentage = (part, whole) => {
  if (whole === 0) {
    whole = 1;
  }
  return ((part / whole) * 100).toFixed(1);
};

export const removeRedundantDecimalPlaces = (value) => {
  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  if (isNaN(value)) {
    return 0;
  }

  return value % 1 === 0 ? value.toString() : value.toFixed(2);
};

export const humanize = (str) => {
  return str[0]?.toUpperCase() + str?.slice(1)?.toLowerCase();
};

export const stringToColor = (string) => {
  const stringUniqueHash = [...string].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
};

export const remCalc = (px, base = 16) => {
  let tempPx = px;
  if (typeof px === 'string' || px instanceof String)
    tempPx = tempPx.replace('px', '');

  tempPx = parseInt(tempPx);
  return (1 / base) * tempPx + 'rem';
};

export const areEqualConsideringEmpty = (value1, value2) => {
  // Normalize the values: if the value is '', undefined, or null, convert it to null
  const normalize = (value) =>
    value === '' || value === undefined || value === null ? null : value;

  const normalizedValue1 = normalize(value1);
  const normalizedValue2 = normalize(value2);

  // Compare the normalized values
  return normalizedValue1 === normalizedValue2;
};
