import React from 'react';
import { CONTENT_TYPE_OPTIONS } from 'constants/contentTypes';
import { BRIEF_GOALS } from 'constants/briefGoals';
import Dropdown from 'Components/Dropdown/Dropdown';
import FormikTextField from 'Components/Form/FormikTextField';
import Styles from './AIPoweredBrief.module.scss';

const FoundationFields = ({
  content_type,
  goals,
  handleFormFieldsChange,
  setFieldValue,
}) => {
  return (
    <div className={Styles.Container_Form}>
      <p className={`${Styles.Container_Title} mb-1`}>
        Let’s Lay the Foundation
      </p>
      <div className={Styles.Container_Form_TwoColumn}>
        <Dropdown
          name="content_type"
          label="What type of content are you creating?"
          placeholder="Select content type"
          options={CONTENT_TYPE_OPTIONS}
          isRequired
          value={CONTENT_TYPE_OPTIONS.find(
            (option) => option.value === content_type,
          )}
          onChange={({ value }) => {
            handleFormFieldsChange('content_type', value);
            setFieldValue('content_type', value);
          }}
          selectClassName={Styles.Container_Form_Dropdown}
          iconWithOption={true}
        />

        <FormikTextField
          Label="What are you writing about?"
          name="topic"
          placeholder="Insert topic"
          classList={'input'}
          onInput={(e) => {
            handleFormFieldsChange('topic', e.target.value);
            setFieldValue('topic', e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          isRequired
        />
      </div>
      <div className={Styles.Container_Form_TwoColumn}>
        <FormikTextField
          Label="Target Audience"
          name="target_audience"
          placeholder="Ex: College students"
          classList="input"
          onInput={(e) => {
            handleFormFieldsChange('target_audience', e.target.value);
            setFieldValue('target_audience', e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        />
        <Dropdown
          name="goals"
          label="Select your content goals"
          placeholder="Select goals"
          options={BRIEF_GOALS}
          isRequired
          value={BRIEF_GOALS.find((option) => option.value === goals)}
          onChange={({ value }) => {
            handleFormFieldsChange('goals', value);
            setFieldValue('goals', value);
          }}
          selectClassName={Styles.Container_Form_Dropdown}
        />
      </div>
    </div>
  );
};

export default FoundationFields;
