import React from 'react';

export const Freestyle = () => {
  return (
    <svg
      width="58"
      height="88"
      viewBox="0 0 58 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="7.14355"
        width="57"
        height="73.713"
        rx="7.5"
        fill="white"
        stroke="#D0D5DD"
      />
      <path
        d="M42.7508 55.8665H14.6078C11.733 55.8522 11.7211 51.328 14.608 51.3125L42.7508 51.3125C45.6235 51.3264 45.6391 55.8509 42.7508 55.8665Z"
        fill="#E4E7EC"
      />
      <path
        d="M42.7508 19.4241H14.6078C11.733 19.4098 11.7211 14.8856 14.608 14.8701L42.7508 14.8701C45.6235 14.884 45.6391 19.4086 42.7508 19.4241Z"
        fill="#E4E7EC"
      />
      <path
        d="M42.7508 64.2737H14.6078C11.733 64.2594 11.7211 59.7352 14.608 59.7197L42.7508 59.7197C45.6235 59.7336 45.6391 64.2582 42.7508 64.2737Z"
        fill="#E4E7EC"
      />
      <path
        d="M42.7508 72.6819H14.6078C11.733 72.6676 11.7211 68.1434 14.608 68.1279L42.7508 68.1279C45.6235 68.1418 45.6391 72.6664 42.7508 72.6819Z"
        fill="#E4E7EC"
      />
      <path
        d="M28.6621 42.625C32.9455 42.625 36.4179 39.0852 36.4179 34.7187C36.4179 30.3522 32.9455 26.8125 28.6621 26.8125C24.3786 26.8125 20.9062 30.3522 20.9062 34.7187C20.9062 39.0852 24.3786 42.625 28.6621 42.625Z"
        fill="#00A7B7"
      />
      <path
        d="M32.1074 33.7612H29.6024V31.2076C29.6024 30.9536 29.5034 30.7101 29.3273 30.5305C29.1511 30.3509 28.9122 30.25 28.663 30.25C28.4139 30.25 28.175 30.3509 27.9988 30.5305C27.8226 30.7101 27.7236 30.9536 27.7236 31.2076V33.7612H25.2187C24.9695 33.7612 24.7306 33.8621 24.5544 34.0416C24.3783 34.2212 24.2793 34.4648 24.2793 34.7188C24.2793 34.9727 24.3783 35.2163 24.5544 35.3959C24.7306 35.5755 24.9695 35.6763 25.2187 35.6763H27.7236V38.2299C27.7236 38.4839 27.8226 38.7274 27.9988 38.907C28.175 39.0866 28.4139 39.1875 28.663 39.1875C28.9122 39.1875 29.1511 39.0866 29.3273 38.907C29.5034 38.7274 29.6024 38.4839 29.6024 38.2299V35.6763H32.1074C32.3565 35.6763 32.5954 35.5755 32.7716 35.3959C32.9478 35.2163 33.0467 34.9727 33.0467 34.7188C33.0467 34.4648 32.9478 34.2212 32.7716 34.0416C32.5954 33.8621 32.3565 33.7612 32.1074 33.7612Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11132_1649"
          x1="20.906"
          y1="42.6249"
          x2="36.7156"
          y2="27.116"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6941C6" />
          <stop offset="1" stopColor="#7F56D9" />
        </linearGradient>
      </defs>
    </svg>
  );
};
