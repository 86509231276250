import React from 'react';
import Styles from './UrlForm.module.scss';
import { ErrorMessage, Formik } from 'formik';
import { Form } from 'react-bootstrap';
import FormikTextField from 'Components/Form/FormikTextField';
import BSButton from 'Components/Button/BSButton';
import ImageView from 'Components/Image/ImageView';
import Input from 'Components/Input/Input';
const UploadForm = ({ noButtons }) => {
  return (
    <>
      <Formik
        initialValues={{}}
        validationSchema={''}
        validateOnMount={true}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, isValid, dirty }) => {
          const {
            content_type,
            state,
            keywords,
            priority,
            assignee_id,
            project_id,
            document_id,
          } = values || {};

          return (
            <div className={Styles.form_container}>
              <Form
                className={Styles.Container}
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <p className={Styles.label}>Upload File</p>
                <section className={Styles.DragDropArea}>
                  <div className="d-flex flex-column justify-content-center align-items-center text-center ">
                    <ImageView src="/Images/uploadIcon.svg" class="img-fluid" />
                    <p class={Styles.DragDropArea__fileMsg}>
                      <span className={Styles.DragDropArea__fileMsg_highlight}>
                        Click to upload
                      </span>{' '}
                      or drag and drop
                    </p>
                    <p className={Styles.DragDropArea__fileMsg_format}>
                      {' '}
                      PDF, TXT, DOCX or RTF (max. 1MB)
                    </p>
                  </div>
                  <Input
                    type="file"
                    // onChange={changeHandler}
                    accept=".doc,.docx,.pdf,.rtf,.txt"
                  />
                </section>
              </Form>
              {!noButtons && (
                <div className={`d-flex justify-content-end gap-2 mt-4`}>
                  <BSButton
                    classList={`cancel_btn`}
                    variant=""
                    ButtonText="Cancel"
                  />
                  <BSButton
                    classList={`secondary_btn`}
                    variant=""
                    ButtonText="Continue"
                  />
                </div>
              )}
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default UploadForm;
