import React from 'react';

import LandingLayout from 'layouts/LandingLayout/LandingLayout';

import Flex from 'ui/Flex/Flex';

import HeroSection from './HeroSection/HeroSection';
import FromObjectivesSection from './FromObjectivesSection/FromObjectivesSection';
import PublishSection from './PublishSection/PublishSection';
import SpecializedAISection from './SpecializedAISection/SpecializedAISection';
import ContentToolsSection from './ContentToolsSection/ContentToolsSection';
import TemplatesSection from './TemplatesSection/TemplatesSection';
import CustomerReview from './CustomerReview/CustomerReview';
import LogosSection from './LogosSection/LogosSection';
import Footer from './Footer/Footer';

// import Banner from 'Components/Banner/Banner';
// import Footer from 'Components/Footer/Footer';

const LandingPage = () => {
  return (
    <LandingLayout>
      <Flex
        direction="column"
        gap="24"
        css={{ marginTop: '20px', marginBottom: '20px' }}
      >
        <HeroSection />
        <LogosSection />
        <FromObjectivesSection />
        <PublishSection />
        <SpecializedAISection />
        <ContentToolsSection />
        <TemplatesSection />
        <CustomerReview />
        <Footer />
      </Flex>
    </LandingLayout>
  );
};

export default LandingPage;
