import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

import ImageView from 'Components/Image/ImageView';
import Questionnaire from 'Containers/Questionnaire/Questionnaire';
import { removeSpacesAndHyphens } from 'helpers';
import Styles from './../Editor.module.scss';
import useTemplates from 'hooks/templates/useTemplates';
import Drawer from 'Components/Drawer/Drawer';

const ContentSettings = ({
  show,
  onHide,
  documentTemplateOptions,
  refetchContent,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const { templateId } = useParams();

  const { templates, isLoading: isLoadingTemplates } = useTemplates();

  const templateOptions = useMemo(() => {
    if (isLoadingTemplates) {
      return [];
    }

    return templates.map((template) => {
      return {
        value: template.id,
        label: (
          <div className="d-flex align-items-center gap-3">
            <ImageView
              src={`/Images/${removeSpacesAndHyphens(template.title)}.svg`}
              class="img-fluid"
              style={{ width: 20, height: 20 }}
            />
            <p>{template.title}</p>
          </div>
        ),
      };
    });
  }, [isLoadingTemplates]);

  return (
    <Drawer
      show={show}
      onHide={onHide}
      title="Template"
      placement="end"
      width={415}
    >
      <div className={Styles.questionsContainer}>
        <div className="content-setting" id="content-setting">
          <div style={{ marginTop: 20 }}>
            <label className="Input_label form-label ">Select Template</label>
            {!!templateOptions?.length && (
              <Select
                isSearchable={false}
                className={' fullWidth-Select'}
                classNamePrefix={'fullWidth-Select'}
                placeholder="Select template..."
                options={templateOptions}
                onChange={(newValue) => {
                  setSelectedTemplate(newValue?.value || '');
                }}
                value={templateOptions.find(
                  (option) => option.value === selectedTemplate,
                )}
                defaultValue={templateOptions.find(
                  (option) => option.value === templateId,
                )}
                isClearable={true}
              />
            )}

            <Questionnaire
              SideBarView={true}
              templateIdParams={selectedTemplate}
              documentTemplateOptions={documentTemplateOptions}
              refetchContent={refetchContent}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ContentSettings;
