import React, { useEffect } from 'react';
import { patchRequest } from 'helpers/axios';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { setLoader } from 'redux/slices/loader';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import { ROUTES } from 'constants/routes';

function InviteAccept() {
  const { token } = useParams() || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const url = new URL(window.location.href);
  const type = url.searchParams.get('type');

  const acceptURL = window.location.href.split(`${window.location.origin}/`)[1];

  useEffect(() => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Accepting Invitation!',
        description: 'Please wait...',
      }),
    );
    patchRequest(acceptURL)
      .then((res) => {
        dispatch(setLoader({ isLoading: false }));
        const { resource } = res?.data || {};

        if (type === 'document') {
          const targetRoute = resource.template_id
            ? ROUTES.EditorDocumentTemplate(resource.slug, resource.template_id)
            : ROUTES.FreestyleDocumentTemplate(resource.slug);

          navigate(targetRoute);
        } else if (type === 'team') {
          navigate(`/teams/${resource.slug}`);
        } else if (type === 'folder') {
          navigate(`/folder/${resource.slug}`);
        }

        renderSuccess(res?.data?.msg);
      })
      .catch((e) => {
        dispatch(setLoader({ isLoading: false }));
        navigate('/');
        renderError(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return <></>;
}

export default InviteAccept;
