import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const aiProjectSlice = createSlice({
  name: 'aiProject',
  initialState,
  reducers: {
    setProject: (state, action) => {
      return { ...action.payload };
    },
  },
});

const { reducer, actions } = aiProjectSlice;

export { actions };
export default reducer;
