import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { TbPencilMinus } from 'react-icons/tb';
import { TbCirclePlus } from 'react-icons/tb';
import { LuPlus } from 'react-icons/lu';
import {
  FaFont,
  FaHeading,
  FaListOl,
  FaListUl,
  FaQuoteLeft,
} from 'react-icons/fa';
import UploadImageModal from 'Containers/Modal/UploadImageModal/UploadImageModal';
import Styles from './AddBlockMenu.module.scss';
import { IoSend } from 'react-icons/io5';
import ChatGptSelect from './ChatGptSelect/ChatGptSelect';

const options = {
  'Body Text': {
    icon: FaFont,
    subtitle: 'Body Text',
  },
  'Heading 1': {
    icon: FaHeading,
    subtitle: 'Heading 1',
  },
  'Heading 2': {
    icon: FaHeading,
    subtitle: 'Heading 2',
  },
  'Heading 3': {
    icon: FaHeading,
    subtitle: 'Heading 3',
  },
  'Bulleted List': {
    icon: FaListUl,
    subtitle: 'Bulleted List',
  },
  'Numbered List': {
    icon: FaListOl,
    subtitle: 'Numbered List',
  },
  Quote: {
    icon: FaQuoteLeft,
    subtitle: 'Quote',
  },
  Image: {
    subtitle: 'Image',
  },
};

function AddBlockMenu({
  editor,
  freeStyle,
  handlePromptAI,
  aiContent,
  handleInsert,
  handleCopy,
  showDrawer,
  setShowDrawer,
  setShowAiTextEditor,
  showEditor,
  input,
  setInput,
}) {
  const [UploadImageModalView, setUploadImageModalView] = useState(false);
  const [selectedModel, setSelectedModel] = useState('gpt-4o');
  const [showAssistance, setShowAssistance] = useState(false);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleClick = () => {
    showEditor();
    handlePromptAI(input, selectedModel);
    // setShowAiTextEditor(true);
  };

  const handleMenuItemClick = (event, index, optionKey) => {
    editor.chain().insertContent(`<p></p>`).focus().run();

    switch (optionKey) {
      case 'Body Text':
        editor.commands.setParagraph();
        break;
      case 'Heading 1':
        editor.commands.setHeading({ level: 1 });
        break;
      case 'Heading 2':
        editor.commands.setHeading({ level: 2 });
        break;
      case 'Heading 3':
        editor.commands.setHeading({ level: 3 });
        break;
      case 'Bulleted List':
        editor.commands.toggleBulletList();
        break;
      case 'Numbered List':
        editor.commands.toggleOrderedList();
        break;
      case 'Quote':
        editor.commands.setBlockquote();
        break;
      case 'Image':
        setUploadImageModalView(true);
        break;
      default:
        break;
    }
  };

  if (freeStyle && editor.isEmpty) {
    return null;
  }

  return (
    <>
      <div className="addBlock">
        <Dropdown>
          <div className={Styles.block_section}>
            <Dropdown.Toggle
              variant="default"
              id="addBlockDropDown"
              className={Styles}
            >
              <span className={Styles.block_section__btn}>
                <LuPlus />
                <span>Add new block</span>
              </span>
            </Dropdown.Toggle>
            <div className={Styles.divider}></div>
            <div className={Styles.ai_assistance_box}>
              <span
                onClick={() => setShowAssistance(!showAssistance)}
                className={Styles.block_section__btn}
              >
                <TbPencilMinus />
                <span>AI Assistance</span>
              </span>
              {showAssistance && (
                <div className={Styles.assitance_box}>
                  <div className={Styles.assitance_box__header}>
                    <div className={Styles.assitance_box__header__heading}>
                      For Example:
                    </div>
                    <div className={Styles.assitance_box__header__desc}>
                      Add a section that explain AI wearables trends in 2024{' '}
                    </div>
                  </div>
                  <div className={Styles.assitance_box__input_section}>
                    <div className={Styles.assitance_box__input_section__icon}>
                      <TbCirclePlus />
                    </div>
                    <div className={Styles.assitance_box__input_section__input}>
                      <input
                        type="text"
                        placeholder="Ask AI to edit or generate"
                        value={input}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className={Styles.assitance_box__drop_section}>
                    <div
                      className={Styles.assitance_box__drop_section__dropdown}
                    >
                      <ChatGptSelect
                        selected={selectedModel}
                        setSelected={setSelectedModel}
                      />
                    </div>
                    <div className={Styles.assitance_box__drop_section__btns}>
                      <button
                        onClick={handleClick}
                        disabled={!input.trim()}
                        className={
                          Styles.assitance_box__drop_section__btns__send
                        }
                      >
                        <IoSend size={16} color="white" />
                      </button>
                      {/* <button
                        onClick={handleInsert}
                        className={
                          Styles.assitance_box__drop_section__btns__dots
                        }
                      >
                        <BsThreeDots size={20} />
                      </button> */}

                      {/* <button
                        onClick={handleCopy}
                        className={
                          Styles.assitance_box__drop_section__btns__window
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_22277_27346)">
                            <path
                              d="M18.333 0.833984H1.66634C1.44533 0.833984 1.23337 0.921782 1.07709 1.07806C0.920805 1.23434 0.833008 1.4463 0.833008 1.66732V18.334C0.833008 18.555 0.920805 18.767 1.07709 18.9232C1.23337 19.0795 1.44533 19.1673 1.66634 19.1673H18.333C18.554 19.1673 18.766 19.0795 18.9223 18.9232C19.0785 18.767 19.1663 18.555 19.1663 18.334V1.66732C19.1663 1.4463 19.0785 1.23434 18.9223 1.07806C18.766 0.921782 18.554 0.833984 18.333 0.833984ZM2.49967 2.50065H12.083V17.5007H2.49967V2.50065ZM17.4997 17.5007H13.7497V2.50065H17.4997V17.5007Z"
                              fill="#475467"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_22277_27346">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button> */}
                    </div>
                  </div>
                  {/* {!!aiContent.trim() && (
                    <>
               
                      <div className={Styles.content}>{parse(aiContent)}</div>
                    </>
                  )} */}
                </div>
              )}
            </div>
          </div>

          <Dropdown.Menu>
            {Object.keys(options).map((optionKey, index) => {
              return (
                <Dropdown.Item
                  key={optionKey}
                  onClick={(event) =>
                    handleMenuItemClick(event, index, optionKey)
                  }
                >
                  {optionKey}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {UploadImageModalView && (
        <UploadImageModal
          show={UploadImageModalView}
          onHide={() => {
            setUploadImageModalView(false);
          }}
          editor={editor}
        />
      )}
    </>
  );
}

export default AddBlockMenu;
