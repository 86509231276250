import React from 'react';

export const ImportDraft = () => {
  return (
    <svg
      width="60"
      height="88"
      viewBox="0 0 60 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11132_1200)">
        <rect
          x="1"
          y="7.14355"
          width="58"
          height="73.713"
          rx="7.5"
          fill="white"
          stroke="#D0D5DD"
        />
        <g clipPath="url(#clip1_11132_1200)">
          <path
            d="M42.8635 58.1768H16.7488C16.2448 58.1762 15.7615 57.9768 15.4051 57.6225C15.0487 57.2681 14.8482 56.7876 14.8477 56.2865V32.1569C14.8482 31.6557 15.0487 31.1753 15.4051 30.8209C15.7615 30.4665 16.2448 30.2672 16.7488 30.2666H42.8635C43.3675 30.2672 43.8508 30.4665 44.2072 30.8209C44.5636 31.1753 44.7641 31.6557 44.7647 32.1569V56.2865C44.7641 56.7876 44.5636 57.2681 44.2072 57.6225C43.8508 57.9768 43.3675 58.1762 42.8635 58.1768Z"
            fill="#E4E7EC"
          />
          <path
            d="M37.3677 56.9021H18.032C17.528 56.9015 17.0448 56.7022 16.6883 56.3478C16.3319 55.9934 16.1314 55.513 16.1309 55.0118V33.4332C16.1314 32.9321 16.3319 32.4516 16.6883 32.0973C17.0448 31.7429 17.528 31.5435 18.032 31.543H41.5817C42.0857 31.5435 42.569 31.7429 42.9254 32.0973C43.2818 32.4516 43.4823 32.9321 43.4828 33.4332V50.822C43.481 52.434 42.8362 53.9794 41.6897 55.1193C40.5433 56.2591 38.989 56.9003 37.3677 56.9021Z"
            fill="white"
          />
          <path
            d="M29.8068 52.6955C34.5132 52.6955 38.3284 48.9021 38.3284 44.2227C38.3284 39.5434 34.5132 35.75 29.8068 35.75C25.1004 35.75 21.2852 39.5434 21.2852 44.2227C21.2852 48.9021 25.1004 52.6955 29.8068 52.6955Z"
            fill="#00A7B7"
          />
          <path
            d="M33.3971 43.796L30.5534 40.3577C30.4762 40.2645 30.3796 40.189 30.2702 40.1365C30.1608 40.084 30.0412 40.0558 29.9198 40.0538C29.7984 40.0518 29.6779 40.0761 29.5669 40.1249C29.4558 40.1738 29.3567 40.246 29.2765 40.3367L26.2354 43.775C26.089 43.941 26.0147 44.158 26.0291 44.3784C26.0435 44.5988 26.1452 44.8046 26.312 44.9504C26.4788 45.0963 26.6971 45.1703 26.9188 45.1563C27.1404 45.1423 27.3475 45.0414 27.4944 44.8757L29.0678 43.0968V47.5575C29.0678 47.7785 29.1561 47.9905 29.3133 48.1468C29.4705 48.303 29.6837 48.3908 29.906 48.3908C30.1283 48.3908 30.3415 48.303 30.4987 48.1468C30.6558 47.9905 30.7442 47.7785 30.7442 47.5575V43.2126L32.1023 44.8547C32.2435 45.0254 32.4471 45.1333 32.6683 45.1548C32.8896 45.1762 33.1104 45.1094 33.2821 44.969C33.4538 44.8286 33.5623 44.6262 33.5839 44.4062C33.6055 44.1862 33.5383 43.9667 33.3971 43.796L33.3971 43.796Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11132_1200"
          x1="21.2852"
          y1="35.75"
          x2="38.3284"
          y2="35.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F56D9" />
          <stop offset="1" stopColor="#9E77ED" />
        </linearGradient>
        <clipPath id="clip0_11132_1200">
          <rect
            width="59"
            height="88"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
        <clipPath id="clip1_11132_1200">
          <rect
            width="30.186"
            height="28.1875"
            fill="white"
            transform="translate(14.8418 30.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
