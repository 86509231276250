import React, { useState, useCallback, useEffect } from 'react';
import Styles from './NewUserSubscriptionPlan.module.scss';
import Header from 'Components/Header/Header';
import LandingLayout from 'layouts/LandingLayout/LandingLayout';
import { SUBSCRIPTION } from 'constants';
// import { SubscriptionCard } from './SubscriptionCard';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLoader } from 'redux/slices/loader';
import { getPlans } from 'services/subscriptionService';
import Toggle from 'Components/Toggle/Toggle';
import { sortArrayCustomOrder } from 'helpers';
import SubscriptionCard from 'Containers/Subscription/SubscriptionCard';

const SubscriptionCardContainer = () => {
  const [currentTab, setCurrentTab] = useState(1);
  const [subscriptionPlans, setSubscriptionPlans] = useState({});
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const basicServices = [
    'AI-powered Topic Ideation',
    'AI-powered Content Planning',
    'Access to AI Templates',
    'Smart Entry Editor',
    'AI Editing Features',
  ];
  const proServices = [
    'Comes with 1 Seat (but can add up to 2 seats for $45/mo per seat)',
    'AI-powered projects with automated content planning',
    'SEO research tool',
    'Integration with up to 5 channels',
    'Brand Voice',
    'Direct scheduling and publishing to connected channels',
    'Multi-model prompting (Claude, GPT, Gemini) in smart entry editor',
    'Collaboration features',
  ];
  const businessServices = [
    '5 user seats (additional seats available at $35/mo per seat)',
    'Review and approval workflow features',
    'Advanced analytics and reporting',
    'AI-powered content strategy',
  ];

  const getServiceList = (planType) => {
    switch (planType.toLowerCase().replace(/\s+/g, '')) {
      case 'basicplan':
        return basicServices;
      case 'proplan':
        return proServices;
      case 'businessplan':
        return businessServices;
      default:
        return [];
    }
  };

  const fetchPlans = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Loading Plans',
          description: 'Please wait...',
        }),
      );
      const plansRes = await getPlans();
      const { data } = plansRes || {};

      const { products } = data || {};
      const { stripe_products } = products || {};

      const transformedStripeProducts = stripe_products.reduce(
        (acc, stripeProduct) => {
          const { stripe_prices, name, description, additional_info } =
            stripeProduct || {};
          const monthlyPlan = stripe_prices.find(
            (stripePrice) => stripePrice.interval === 'month',
          );
          const yearlyPlan = stripe_prices.find(
            (stripePrice) => stripePrice.interval === 'year',
          );
          if (acc.monthlyPlan) {
            acc.monthlyPlan = [
              ...acc.monthlyPlan,
              { ...monthlyPlan, type: name, description, additional_info },
            ];
          } else {
            acc.monthlyPlan = [
              { ...monthlyPlan, type: name, description, additional_info },
            ];
          }

          if (acc.annualplan) {
            acc.annualplan = [
              ...acc.annualplan,
              { ...yearlyPlan, type: name, description, additional_info },
            ];
          } else {
            acc.annualplan = [
              { ...yearlyPlan, type: name, description, additional_info },
            ];
          }
          acc.monthlyPlan = sortArrayCustomOrder(acc.monthlyPlan);
          acc.annualplan = sortArrayCustomOrder(acc.annualplan);

          return acc;
        },
        {},
      );
      setSubscriptionPlans(transformedStripeProducts);
      dispatch(setLoader({ isLoading: false }));
    } catch (e) {
      dispatch(setLoader({ isLoading: false }));
    }
  }, [dispatch]);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  useEffect(() => {
    if (user && navigate) {
      navigate(`/${SUBSCRIPTION}`);
      navigate(`/`);
    }
  }, [user, navigate]);

  const renderTab = useCallback(() => {
    switch (currentTab) {
      case 1:
        return (
          <SubscriptionCard
            plan={subscriptionPlans.monthlyPlan}
            newUser={true}
          />
        );
      case 2:
        return (
          <SubscriptionCard
            plan={subscriptionPlans.annualplan}
            newUser={true}
          />
        );
      default:
        return <h1>Something went wrong</h1>;
    }
  }, [currentTab, fetchPlans, subscriptionPlans]);

  const handleToggleClick = () => {
    // Toggle between tabs based on the current tab
    const newTabId = currentTab === 1 ? 2 : 1;
    setCurrentTab(newTabId);
  };

  return (
    <LandingLayout>
      <div className={Styles.container}>
        <Header />
        <div className={Styles.gradient}></div>
        <div className={Styles.container__heading}>
          <h2>
            Plans for every stage <br />
            <span>of your company</span>
          </h2>
        </div>
        <div className={Styles.container__toggleSection}>
          <span>Monthly</span>
          <Toggle onChange={handleToggleClick} checked={currentTab === 2} />
          <span>Yearly</span>
        </div>
      </div>
      <div className={Styles.container__cards}>{renderTab()}</div>
      {/* <PlanDetailsScreenNewUser /> */}
    </LandingLayout>
  );
};

export default SubscriptionCardContainer;
