import React from 'react';

export const PickTemplate = () => {
  return (
    <svg
      width="98"
      height="88"
      viewBox="0 0 98 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_11132_11211" fill="white">
        <path d="M50.623 1.87335L46.8461 2.84783L8.73622 12.6807L4.95943 13.6552C3.85722 13.9409 2.91328 14.6524 2.33472 15.6335C1.75615 16.6147 1.59021 17.7854 1.87329 18.8888L15.7004 72.535C15.986 73.6378 16.6971 74.5822 17.6777 75.1611C18.6584 75.74 19.8285 75.906 20.9313 75.6228L20.9396 75.6206L66.5866 63.8431L66.5949 63.8409C67.6971 63.5552 68.641 62.8437 69.2196 61.8626C69.7981 60.8814 69.9641 59.7107 69.681 58.6073L55.8539 4.96109C55.5683 3.85832 54.8572 2.9139 53.8766 2.33504C52.8959 1.75617 51.7258 1.59014 50.623 1.87335V1.87335Z" />
      </mask>
      <path
        d="M50.623 1.87335L46.8461 2.84783L8.73622 12.6807L4.95943 13.6552C3.85722 13.9409 2.91328 14.6524 2.33472 15.6335C1.75615 16.6147 1.59021 17.7854 1.87329 18.8888L15.7004 72.535C15.986 73.6378 16.6971 74.5822 17.6777 75.1611C18.6584 75.74 19.8285 75.906 20.9313 75.6228L20.9396 75.6206L66.5866 63.8431L66.5949 63.8409C67.6971 63.5552 68.641 62.8437 69.2196 61.8626C69.7981 60.8814 69.9641 59.7107 69.681 58.6073L55.8539 4.96109C55.5683 3.85832 54.8572 2.9139 53.8766 2.33504C52.8959 1.75617 51.7258 1.59014 50.623 1.87335V1.87335Z"
        fill="white"
        stroke="#F2F4F7"
        strokeWidth="2"
        mask="url(#path-1-inside-1_11132_11211)"
      />
      <path
        d="M47.7706 19.2461L24.6789 25.2041C24.421 25.2706 24.1472 25.2319 23.9178 25.0965C23.6884 24.9611 23.5222 24.7401 23.4556 24.482C23.3891 24.224 23.4278 23.9501 23.5631 23.7205C23.6985 23.491 23.9194 23.3247 24.1773 23.2582L47.269 17.3002C47.5269 17.2339 47.8004 17.2727 48.0296 17.4082C48.2589 17.5436 48.4249 17.7645 48.4914 18.0224C48.5579 18.2803 48.5193 18.5541 48.3841 18.7835C48.249 19.013 48.0283 19.1793 47.7706 19.2461Z"
        fill="#F2F2F2"
      />
      <path
        d="M52.6277 21.4944L25.5246 28.4873C25.2667 28.5539 24.9929 28.5152 24.7635 28.3798C24.5341 28.2444 24.3679 28.0233 24.3013 27.7653C24.2348 27.5072 24.2735 27.2333 24.4088 27.0038C24.5442 26.7743 24.7651 26.608 25.023 26.5414L52.1261 19.5484C52.384 19.4819 52.6578 19.5206 52.8872 19.656C53.1166 19.7914 53.2829 20.0124 53.3494 20.2705C53.4159 20.5285 53.3772 20.8024 53.2419 21.032C53.1065 21.2615 52.8856 21.4278 52.6277 21.4944Z"
        fill="#F2F2F2"
      />
      <path
        d="M51.5636 33.9621L28.4718 39.9201C28.3441 39.9531 28.2112 39.9605 28.0806 39.9421C27.95 39.9236 27.8243 39.8796 27.7108 39.8126C27.5972 39.7455 27.4979 39.6567 27.4186 39.5513C27.3393 39.4459 27.2815 39.3258 27.2486 39.1981C27.2157 39.0703 27.2082 38.9373 27.2267 38.8066C27.2451 38.676 27.2891 38.5502 27.3561 38.4366C27.4231 38.3229 27.5119 38.2236 27.6172 38.1443C27.7226 38.0649 27.8426 38.0071 27.9703 37.9742L51.062 32.0162C51.1897 31.9833 51.3227 31.9758 51.4532 31.9943C51.5838 32.0127 51.7095 32.0567 51.8231 32.1238C51.9367 32.1908 52.036 32.2796 52.1153 32.385C52.1945 32.4905 52.2523 32.6105 52.2852 32.7383C52.3182 32.866 52.3256 32.999 52.3072 33.1297C52.2887 33.2603 52.2448 33.3861 52.1777 33.4997C52.1107 33.6134 52.022 33.7127 51.9166 33.792C51.8112 33.8714 51.6913 33.9292 51.5636 33.9621Z"
        fill="#F2F2F2"
      />
      <path
        d="M56.4206 36.2102L29.3175 43.2031C29.1898 43.2361 29.0569 43.2436 28.9263 43.2251C28.7957 43.2066 28.6701 43.1626 28.5565 43.0956C28.4429 43.0285 28.3436 42.9398 28.2643 42.8343C28.185 42.7289 28.1272 42.6089 28.0943 42.4811C28.0614 42.3533 28.0539 42.2203 28.0724 42.0897C28.0908 41.959 28.1348 41.8333 28.2018 41.7196C28.2688 41.606 28.3576 41.5066 28.4629 41.4273C28.5683 41.348 28.6883 41.2902 28.816 41.2572L55.9191 34.2643C56.177 34.1977 56.4508 34.2364 56.6802 34.3718C56.9096 34.5072 57.0758 34.7283 57.1423 34.9863C57.2088 35.2444 57.1702 35.5183 57.0348 35.7478C56.8995 35.9773 56.6786 36.1436 56.4206 36.2102Z"
        fill="#F2F2F2"
      />
      <path
        d="M20.7374 31.3131L12.5654 33.4215C12.4418 33.4533 12.3106 33.4347 12.2006 33.3698C12.0906 33.3049 12.0109 33.199 11.9789 33.0753L10.103 25.7974C10.0713 25.6737 10.0899 25.5424 10.1548 25.4324C10.2196 25.3223 10.3255 25.2426 10.4491 25.2105L18.621 23.1021C18.7447 23.0703 18.8759 23.0889 18.9858 23.1538C19.0958 23.2187 19.1755 23.3246 19.2076 23.4483L21.0834 30.7262C21.1152 30.85 21.0966 30.9812 21.0317 31.0912C20.9668 31.2013 20.861 31.281 20.7374 31.3131Z"
        fill="#E4E7EC"
      />
      <path
        d="M24.5303 46.0289L16.3584 48.1374C16.2347 48.1691 16.1035 48.1505 15.9936 48.0856C15.8836 48.0207 15.8039 47.9148 15.7719 47.7911L13.896 40.5132C13.8643 40.3895 13.8829 40.2582 13.9477 40.1482C14.0126 40.0382 14.1185 39.9584 14.242 39.9264L22.414 37.8179C22.5376 37.7861 22.6688 37.8047 22.7788 37.8696C22.8888 37.9346 22.9685 38.0405 23.0005 38.1641L24.8764 45.442C24.9081 45.5658 24.8895 45.697 24.8246 45.8071C24.7598 45.9171 24.6539 45.9969 24.5303 46.0289Z"
        fill="#E4E7EC"
      />
      <path
        d="M28.3233 60.7447L20.1514 62.8532C20.0277 62.8849 19.8965 62.8663 19.7865 62.8014C19.6766 62.7365 19.5968 62.6306 19.5648 62.507L17.689 55.229C17.6572 55.1053 17.6758 54.974 17.7407 54.864C17.8056 54.754 17.9114 54.6742 18.035 54.6422L26.207 52.5337C26.3306 52.5019 26.4618 52.5206 26.5718 52.5855C26.6817 52.6504 26.7615 52.7563 26.7935 52.8799L28.6694 60.1579C28.7011 60.2816 28.6825 60.4129 28.6176 60.5229C28.5527 60.6329 28.4469 60.7127 28.3233 60.7447Z"
        fill="#E4E7EC"
      />
      <mask id="path-9-inside-2_11132_11211" fill="white">
        <path d="M81.7302 13.0625H34.7725C33.6387 13.0638 32.5517 13.536 31.75 14.3754C30.9483 15.2149 30.4974 16.353 30.4961 17.5401V75.2724C30.4974 76.4595 30.9483 77.5976 31.75 78.4371C32.5517 79.2765 33.6387 79.7487 34.7725 79.75H81.7302C82.864 79.7486 83.951 79.2765 84.7527 78.4371C85.5544 77.5976 86.0053 76.4595 86.0066 75.2724V17.5401C86.0053 16.353 85.5544 15.2149 84.7527 14.3754C83.951 13.536 82.864 13.0638 81.7302 13.0625Z" />
      </mask>
      <path
        d="M81.7302 13.0625H34.7725C33.6387 13.0638 32.5517 13.536 31.75 14.3754C30.9483 15.2149 30.4974 16.353 30.4961 17.5401V75.2724C30.4974 76.4595 30.9483 77.5976 31.75 78.4371C32.5517 79.2765 33.6387 79.7487 34.7725 79.75H81.7302C82.864 79.7486 83.951 79.2765 84.7527 78.4371C85.5544 77.5976 86.0053 76.4595 86.0066 75.2724V17.5401C86.0053 16.353 85.5544 15.2149 84.7527 14.3754C83.951 13.536 82.864 13.0638 81.7302 13.0625Z"
        fill="white"
        stroke="#D0D5DD"
        strokeWidth="2"
        mask="url(#path-9-inside-2_11132_11211)"
      />
      <path
        d="M87.0354 85.25C91.388 85.25 94.9165 81.7102 94.9165 77.3437C94.9165 72.9772 91.388 69.4375 87.0354 69.4375C82.6828 69.4375 79.1543 72.9772 79.1543 77.3437C79.1543 81.7102 82.6828 85.25 87.0354 85.25Z"
        fill="#00A7B7"
      />
      <path
        d="M90.5346 76.3862H87.9892V73.8326C87.9892 73.5786 87.8886 73.3351 87.7096 73.1555C87.5306 72.9759 87.2878 72.875 87.0346 72.875C86.7815 72.875 86.5387 72.9759 86.3597 73.1555C86.1806 73.3351 86.0801 73.5786 86.0801 73.8326V76.3862H83.5346C83.2815 76.3862 83.0387 76.4871 82.8597 76.6666C82.6806 76.8462 82.5801 77.0898 82.5801 77.3438C82.5801 77.5977 82.6806 77.8413 82.8597 78.0209C83.0387 78.2005 83.2815 78.3013 83.5346 78.3013H86.0801V80.8549C86.0801 81.1089 86.1806 81.3524 86.3597 81.532C86.5387 81.7116 86.7815 81.8125 87.0346 81.8125C87.2878 81.8125 87.5306 81.7116 87.7096 81.532C87.8886 81.3524 87.9892 81.1089 87.9892 80.8549V78.3013H90.5346C90.7878 78.3013 91.0306 78.2005 91.2096 78.0209C91.3886 77.8413 91.4892 77.5977 91.4892 77.3438C91.4892 77.0898 91.3886 76.8462 91.2096 76.6666C91.0306 76.4871 90.7878 76.3862 90.5346 76.3862Z"
        fill="white"
      />
      <path
        d="M70.7916 44.6875H51.1972C51.0887 44.6877 50.9814 44.6611 50.8811 44.6093C50.7809 44.5576 50.6898 44.4816 50.6131 44.3859C50.5364 44.2901 50.4755 44.1763 50.434 44.0512C50.3925 43.926 50.3711 43.7918 50.3711 43.6562C50.3711 43.5207 50.3925 43.3865 50.434 43.2613C50.4755 43.1361 50.5364 43.0224 50.6131 42.9266C50.6898 42.8309 50.7809 42.7549 50.8811 42.7032C50.9814 42.6514 51.0887 42.6248 51.1972 42.625H70.7916C71.0103 42.6253 71.2199 42.7341 71.3745 42.9275C71.529 43.1208 71.6158 43.3829 71.6158 43.6562C71.6158 43.9296 71.529 44.1917 71.3745 44.385C71.2199 44.5784 71.0103 44.6872 70.7916 44.6875Z"
        fill="#E4E7EC"
      />
      <path
        d="M70.7916 28.1875H51.1972C51.0887 28.1877 50.9814 28.1611 50.8811 28.1093C50.7809 28.0576 50.6898 27.9816 50.6131 27.8859C50.5364 27.7901 50.4755 27.6763 50.434 27.5512C50.3925 27.426 50.3711 27.2918 50.3711 27.1562C50.3711 27.0207 50.3925 26.8865 50.434 26.7613C50.4755 26.6361 50.5364 26.5224 50.6131 26.4266C50.6898 26.3309 50.7809 26.2549 50.8811 26.2032C50.9814 26.1514 51.0887 26.1248 51.1972 26.125H70.7916C71.0103 26.1253 71.2199 26.2341 71.3745 26.4275C71.529 26.6208 71.6158 26.8829 71.6158 27.1562C71.6158 27.4296 71.529 27.6917 71.3745 27.885C71.2199 28.0784 71.0103 28.1872 70.7916 28.1875Z"
        fill="#E4E7EC"
      />
      <path
        d="M74.2165 48.125H51.1979C51.0894 48.1252 50.9819 48.0986 50.8816 48.0468C50.7813 47.9951 50.6901 47.9191 50.6133 47.8234C50.5365 47.7276 50.4756 47.6138 50.4341 47.4887C50.3925 47.3635 50.3711 47.2293 50.3711 47.0937C50.3711 46.9582 50.3925 46.824 50.4341 46.6988C50.4756 46.5736 50.5365 46.4599 50.6133 46.3641C50.6901 46.2684 50.7813 46.1924 50.8816 46.1407C50.9819 46.0889 51.0894 46.0623 51.1979 46.0625H74.2165C74.4356 46.0625 74.6456 46.1711 74.8005 46.3645C74.9554 46.5579 75.0424 46.8202 75.0424 47.0937C75.0424 47.3673 74.9554 47.6296 74.8005 47.823C74.6456 48.0163 74.4356 48.125 74.2165 48.125Z"
        fill="#E4E7EC"
      />
      <path
        d="M74.2165 31.625H51.1979C51.0894 31.6252 50.9819 31.5986 50.8816 31.5468C50.7813 31.4951 50.6901 31.4191 50.6133 31.3234C50.5365 31.2276 50.4756 31.1138 50.4341 30.9887C50.3925 30.8635 50.3711 30.7293 50.3711 30.5937C50.3711 30.4582 50.3925 30.324 50.4341 30.1988C50.4756 30.0736 50.5365 29.9599 50.6133 29.8641C50.6901 29.7684 50.7813 29.6924 50.8816 29.6407C50.9819 29.5889 51.0894 29.5623 51.1979 29.5625H74.2165C74.4356 29.5625 74.6456 29.6711 74.8005 29.8645C74.9554 30.0579 75.0424 30.3202 75.0424 30.5937C75.0424 30.8673 74.9554 31.1296 74.8005 31.323C74.6456 31.5163 74.4356 31.625 74.2165 31.625Z"
        fill="#E4E7EC"
      />
      <path
        d="M70.7907 61.1875H51.1972C51.0887 61.1877 50.9813 61.1611 50.8811 61.1093C50.7809 61.0576 50.6898 60.9816 50.6131 60.8859C50.5364 60.7901 50.4755 60.6763 50.434 60.5512C50.3925 60.426 50.3711 60.2918 50.3711 60.1562C50.3711 60.0207 50.3925 59.8865 50.434 59.7613C50.4755 59.6362 50.5364 59.5224 50.6131 59.4266C50.6898 59.3309 50.7809 59.2549 50.8811 59.2032C50.9813 59.1514 51.0887 59.1248 51.1972 59.125H70.7907C71.0096 59.125 71.2194 59.2337 71.3742 59.427C71.5289 59.6204 71.6158 59.8827 71.6158 60.1562C71.6158 60.4297 71.5289 60.6921 71.3742 60.8855C71.2194 61.0788 71.0096 61.1875 70.7907 61.1875Z"
        fill="#E4E7EC"
      />
      <path
        d="M74.2156 64.625H51.1979C51.0894 64.6252 50.9819 64.5986 50.8816 64.5468C50.7813 64.4951 50.6901 64.4191 50.6133 64.3234C50.5365 64.2276 50.4756 64.1138 50.434 63.9887C50.3925 63.8635 50.3711 63.7293 50.3711 63.5938C50.3711 63.4582 50.3925 63.324 50.434 63.1988C50.4756 63.0737 50.5365 62.9599 50.6133 62.8641C50.6901 62.7684 50.7813 62.6924 50.8816 62.6407C50.9819 62.5889 51.0894 62.5623 51.1979 62.5625H74.2156C74.3242 62.5623 74.4316 62.5889 74.532 62.6407C74.6323 62.6924 74.7234 62.7684 74.8002 62.8641C74.877 62.9599 74.9379 63.0737 74.9795 63.1988C75.021 63.324 75.0424 63.4582 75.0424 63.5938C75.0424 63.7293 75.021 63.8635 74.9795 63.9887C74.9379 64.1138 74.877 64.2276 74.8002 64.3234C74.7234 64.4191 74.6323 64.4951 74.532 64.5468C74.4316 64.5986 74.3242 64.6252 74.2156 64.625Z"
        fill="#E4E7EC"
      />
      <path
        d="M45.7509 49.7199H37.3116C37.184 49.7197 37.0616 49.6689 36.9713 49.5786C36.881 49.4883 36.8302 49.3658 36.8301 49.2381V41.722C36.8302 41.5943 36.881 41.4718 36.9713 41.3815C37.0616 41.2912 37.184 41.2404 37.3116 41.2402H45.7509C45.8786 41.2404 46.001 41.2912 46.0913 41.3815C46.1816 41.4718 46.2323 41.5943 46.2325 41.722V49.2381C46.2323 49.3658 46.1816 49.4883 46.0913 49.5786C46.001 49.6689 45.8786 49.7197 45.7509 49.7199Z"
        fill="#E4E7EC"
      />
      <path
        d="M45.7509 33.2853H37.3116C37.184 33.2852 37.0616 33.2344 36.9713 33.144C36.881 33.0537 36.8302 32.9312 36.8301 32.8035V25.2875C36.8302 25.1597 36.881 25.0373 36.9713 24.9469C37.0616 24.8566 37.184 24.8058 37.3116 24.8057H45.7509C45.8786 24.8058 46.001 24.8566 46.0913 24.9469C46.1816 25.0373 46.2323 25.1597 46.2325 25.2875V32.8035C46.2323 32.9312 46.1816 33.0537 46.0913 33.144C46.001 33.2344 45.8786 33.2852 45.7509 33.2853Z"
        fill="#E4E7EC"
      />
      <path
        d="M45.7509 66.0988H37.3116C37.184 66.0986 37.0616 66.0478 36.9713 65.9575C36.881 65.8672 36.8302 65.7447 36.8301 65.617V58.1009C36.8302 57.9732 36.881 57.8507 36.9713 57.7604C37.0616 57.6701 37.184 57.6193 37.3116 57.6191H45.7509C45.8786 57.6193 46.001 57.6701 46.0913 57.7604C46.1816 57.8507 46.2323 57.9732 46.2325 58.1009V65.617C46.2323 65.7447 46.1816 65.8672 46.0913 65.9575C46.001 66.0478 45.8786 66.0986 45.7509 66.0988Z"
        fill="#E4E7EC"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11132_11211"
          x1="79.1541"
          y1="85.2499"
          x2="94.9665"
          y2="69.4876"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6941C6" />
          <stop offset="1" stopColor="#7F56D9" />
        </linearGradient>
      </defs>
    </svg>
  );
};
