import React from 'react';

import LandingLayout from 'layouts/LandingLayout/LandingLayout';

import Header from 'Components/Header/Header';
import { Container } from 'react-bootstrap';

import './TermsConditions.module.scss';

const TermsConditions = () => (
  <LandingLayout>
    <Header />
    <Container className="mt-5">
      <div>
        <p
          style={{
            marginTop: '0pt',
            marginBottom: '12pt',
            textAlign: 'center',
          }}
        >
          <h1>
            <strong>BETA TEST AGREEMENT</strong>
          </h1>
        </p>
        <p
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textAlign: 'justify',
            fontSize: '10pt',
          }}
        >
          <strong>
            <u>IMPORTANT</u>
          </strong>
          <strong>
            : PLEASE READ THIS BETA TEST AGREEMENT CAREFULLY. DOWNLOADING,
            INSTALLING, ACCESSING OR USING SOFTWARE THAT IS PROVIDED IN
            CONNECTION WITH THIS AGREEMENT CONSTITUTES ACCEPTANCE OF THIS
            AGREEMENT.&nbsp;
          </strong>
        </p>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textAlign: 'justify',
            pageBreakAfter: 'avoid',
            fontSize: '10pt',
          }}
        >
          <u>
            <span style={{ fontWeight: 'normal' }}>
              VOCABLE IS WILLING TO LICENSE SOFTWARE TO YOU ONLY IF YOU ACCEPT
              ALL OF THE TERMS SET FORTH IN THIS BETA TEST AGREEMENT INCLUDING
              EXHIBIT A (COLLECTIVELY, THE &ldquo;AGREEMENT&rdquo;).
            </span>
          </u>
          <u>
            <span style={{ fontWeight: 'normal' }}>&nbsp;&nbsp;</span>
          </u>
          <u>
            <span style={{ fontWeight: 'normal' }}>
              NO TERMS SET FORTH ON ANY PURCHASE ORDER OR OTHER DOCUMENT
              PROVIDED BY LICENSEE (AS DEFINED BELOW) SHALL APPLY, WHETHER OR
              NOT SUCH TERMS ARE EXECUTED BY VOCABLE.
            </span>
          </u>
        </h1>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textAlign: 'justify',
            pageBreakAfter: 'avoid',
            fontSize: '10pt',
          }}
        >
          <u>
            <span style={{ fontWeight: 'normal' }}>
              BY DOWNLOADING, INSTALLING, ACCESSING, OR USING THE SOFTWARE OR
              OTHERWISE EXPRESSING YOUR AGREEMENT TO THE TERMS CONTAINED IN THIS
              AGREEMENT, YOU INDIVIDUALLY AND ON BEHALF OF THE BUSINESS OR OTHER
              ORGANIZATION THAT YOU REPRESENT (THE &ldquo;LICENSEE&rdquo;)
              CONSENT TO BE BOUND BY THIS AGREEMENT.
            </span>
          </u>
        </h1>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontWeight: 'normal' }}>1.</span>
          <span
            style={{
              width: '28.5pt',
              font: '7pt Times New Roman',
              display: 'inline - block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Background</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>Vocable Ai, Inc. (&ldquo;</span>
          <u>
            <span>Vocable</span>
          </u>
          <span>
            &rdquo;) has developed and is developing an artificial intelligence
            system to help users, such as Licensee, generate content in the
            style of that user that can then be further refined by that user
            (the &ldquo;
          </span>
          <u>
            <span>Beta Software</span>
          </u>
          <span>&rdquo;).</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            The Beta Software interfaces with a third-party large language model
            (&ldquo;
          </span>
          <u>
            <span>LLM</span>
          </u>
          <span>&rdquo;) to provide output (&ldquo;</span>
          <u>
            <span>Generated Output</span>
          </u>
          <span>
            &rdquo;) that may be used by Licensee. Vocable wishes to improve the
            features and functionalities of the Beta Software and is therefore
            willing to grant a non-exclusive right to evaluate the Beta Software
            in accordance with the Beta Test Objectives set forth in&nbsp;
          </span>
          <u>
            <span>Exhibit A</span>
          </u>
          <span>&nbsp;and on the terms set forth herein (the &ldquo;</span>
          <u>
            <span>Beta Test</span>
          </u>
          <span>&rdquo;).</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            If multiple individual users that are each employed by Licensee
            separately sign up for the Beta Test, Licensee shall be bound by the
            Beta Test for each of such users.
          </span>
        </h1>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '10pt',
          }}
        >
          <span>2.</span>
          <span
            style={{
              width: '28.5pt',
              font: '7pt Times New Roman',
              display: 'inline - block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Licenses</span>
          </u>
          <span>.</span>
        </h1>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>2.1</span>
          <span
            style={{
              width: '23.5pt',
              font: '7pt Times New Roman',
              display: 'inline - block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Evaluation</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Subject to the terms and conditions contained herein, Vocable hereby
            grants Licensee and Licensee accepts, a non-exclusive,
            non-transferable license (without the right to sublicense) to use
            and test the Beta Software, for the sole purpose of evaluating the
            Beta Software in accordance with the Beta Test. Vocable and Licensee
            may agree to adjust the Beta Test, which adjustment may be made (a)
            by email agreement of Vocable and Licensee; (b) by written, executed
            agreement of Vocable and Licensee; or (c) by Vocable proposing to
            Licensee a change to the Beta Test by email, if Licensee does not
            email Vocable rejecting such change within five (5) business days
            after the date of such email, in which event such change shall
            automatically become effective.
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>2.2</span>
          <span
            style={{
              width: '23.5pt',
              font: '7pt Times New Roman',
              display: 'inline - block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>No Rights to Distribute</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee is not granted any rights to distribute or otherwise
            disclose the Beta Software, any portion or component of the Beta
            Software or any information regarding the Beta Software under this
            Agreement.&nbsp;
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>2.3</span>
          <span
            style={{
              width: '23.5pt',
              font: '7pt Times New Roman',
              display: 'inline - block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Limitations</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee will not directly or indirectly (a) copy, display,
            transfer, distribute or use the Beta Software in any manner or for
            any purpose not expressly authorized by this Agreement; (b) prepare
            derivative works of or otherwise adapt, modify, or translate the
            Beta Software; (c) reverse engineer, decompile, reconstruct,
            remanufacture, or disassemble the Software, or any portion thereof;
            (d) alter, remove, obscure, erase, deface, or hide from view any
            copyright, trademark, or other proprietary rights notice contained
            in or incorporated into the Software or its packaging; (e) use,
            rent, or lend the Beta Software in connection with a service bureau,
            time-sharing, ASP, software-as-a-service, or similar arrangement or
            pledge, lease, share, or otherwise make available the Beta Software;
            (f) distribute or resell the Software; (g) use the Beta Software in
            any way that violates any individual&rsquo;s privacy rights or other
            rights of any party; (h) disclose any results of testing or
            benchmarking the Beta Software, or of any part of the Beta Test, to
            any third party; (i) use the Beta Software in any way that violates
            any applicable law or regulation, including to generate any content
            that requires an applicable license (such as providing medical
            judgment, legal advice or engineering services); (j) attempt any of
            the foregoing; or (k) assist any third party with any of the
            foregoing.&nbsp;
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>2.4</span>
          <span
            style={{
              width: '23.5pt',
              font: "7pt 'Times New Roman'",
              display: 'inline - block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Modifications</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Vocable may modify the Beta Software at any time during the term of
            this Agreement. Any such modifications, enhancements or changes are
            exclusively owned by Vocable. If Vocable makes any modification
            available to Licensee, such modification is subject to the rights,
            obligations, and limitations of this Agreement. Nothing in this
            Agreement requires Vocable to modify the Beta Software or fix any
            errors.
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>2.5</span>
          <span
            style={{
              width: '23.5pt',
              font: "7pt 'Times New Roman'",
              display: 'inline - block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>LLM and Generated Output</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Vocable is not responsible for any Generated Output, which is solely
            provided by the LLM. Licensee is solely responsible for reviewing
            any Generated Output for accuracy, quality, bias, compliance with
            applicable law and otherwise. Vocable cautions Licensee that LLMs
            may create Generated Output that is not accurate, may not be of
            appropriate quality, may have implicit bias, reinforce existing
            bias, or otherwise have inappropriate biases, may not comply with
            applicable laws or may otherwise be undesirable. Vocable recommends
            that Licensee review all Generated Output carefully. Licensee is
            solely responsible for ensuring that any queries, prompts or other
            submissions comply with applicable law and any applicable terms of
            the LLM. On Vocable&rsquo;s website, Vocable shall provide a link to
            the LLM (which Vocable may change from time to time) and a link to
            the terms of service for the then-current LLM.
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>2.6</span>
          <span
            style={{
              width: '23.5pt',
              font: "7pt 'Times New Roman'",
              display: 'inline - block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>No Implied Licenses</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Except for the express licenses set forth herein, Vocable does not
            grant any license to Licensee, whether by implication, estoppel or
            otherwise.
          </span>
        </h2>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '10pt',
          }}
        >
          <span>3.</span>
          <span
            style={{
              width: '28.5pt',
              font: "7pt 'Times New Roman'",
              display: 'inline - block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Responsibilities of the Parties.</span>
          </u>
        </h1>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>3.1</span>
          <span
            style={{
              width: '23.5pt',
              font: "7pt 'Times New Roman'",
              display: 'inline - block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Vocable&rsquo;s Responsibilities</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>Vocable will perform or provide the following:</span>
        </h2>
        <h3
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '72pt',
            fontSize: '10pt',
          }}
        >
          <span>3.1.1</span>
          <span
            style={{
              width: '16pt',
              font: "7pt 'Times New Roman'",
              display: 'inline - block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Delivery of Beta Software</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Vocable shall provide Licensee with access to the Beta Software
            promptly after Licensee agrees to this Agreement.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            In addition, Vocable shall make available to Licensee such
            documentation as Vocable deems necessary for the planned use of the
            Beta Software, without charge to Licensee, for the duration of the
            Term.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            The Beta Software and all such documentation shall be Confidential
            Information of Vocable, which shall be used only for the Beta Test,
            and shall be restricted to those persons within Licensee&rsquo;s
            organization that are assigned to the Beta Test.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee acknowledges that the Beta Software and all related
            materials are beta software and may not perform as expected.
          </span>
        </h3>
        <h3
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '72pt',
            fontSize: '10pt',
          }}
        >
          <span>3.1.2</span>
          <span
            style={{
              width: '16pt',
              font: "7pt 'Times New Roman'",
              display: 'inline - block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Vocable Assistance</span>
          </u>
          <span>
            . Vocable shall provide reasonable support for the resolution of
            issues that arise during the Beta Test.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            This may include the review of Licensee&rsquo;s test plans.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee may submit support requests via email or such online system
            as Vocable makes available from time to time, and Vocable will use
            reasonable efforts to address such support requests.
          </span>
          <span>&nbsp;&nbsp;</span>
        </h3>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>3.2</span>
          <span
            style={{
              width: '23.5pt',
              font: "7pt 'Times New Roman'",
              display: 'inline - block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Licensee&rsquo;s Responsibilities.</span>
          </u>
          <span>&nbsp;&nbsp;</span>
          <span>Licensee shall perform or provide the following:</span>
        </h2>
        <h3
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '72pt',
            fontSize: '10pt',
          }}
        >
          <span>3.2.1</span>
          <span
            style={{
              width: '16pt',
              font: "7pt 'Times New Roman'",
              display: 'inline - block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Equipment</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee shall be responsible for procuring all hardware and
            software necessary to use the Beta Software for the Beta Test.
          </span>
        </h3>
        <h3
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '72pt',
            fontSize: '10pt',
          }}
        >
          <span>3.2.2</span>
          <span
            style={{
              width: '16pt',
              font: "7pt 'Times New Roman'",
              display: 'inline - block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Test Duration</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee shall run and test the Beta Software during the entire
            Term.
          </span>
        </h3>
        <h3
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '72pt',
            fontSize: '10pt',
          }}
        >
          <span>3.2.3</span>
          <span
            style={{
              width: '16pt',
              font: "7pt 'Times New Roman'",
              display: 'inline - block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Feedback</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Each week during the Term, Licensee shall provide to Vocable all
            material feedback regarding the Beta Test and Beta Software,
            including usability, bug reports and comprehensive test results
            during the Beta Test as more fully set forth on&nbsp;
          </span>
          <u>
            <span>Exhibit A</span>
          </u>
          <span>&nbsp;(&ldquo;</span>
          <u>
            <span>Test Results</span>
          </u>
          <span>
            &rdquo;), and shall keep Vocable apprised of all such information
            which may arise during the Beta Test (collectively and separately,
            &ldquo;
          </span>
          <u>
            <span>Feedback</span>
          </u>
          <span>&rdquo;).</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee shall provide summaries of Feedback in writing and, upon
            Vocable&rsquo;s request, meet with Vocable by video- or
            teleconference to discuss such Feedback. Weekly written summaries
            shall include information on ease of installation, ease of
            administration, ease of loading and running applications,
            compatibility issues, performance relative to expectations, ease of
            use of the documentation and problems and successes of system
            delivery, obtaining software, service and support methodology, Test
            Results and general experience of Licensee with the Beta Software,
            as applicable.
          </span>
          <span>&nbsp;&nbsp;</span>
        </h3>
        <h3
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '72pt',
            fontSize: '10pt',
          }}
        >
          <span>3.2.4</span>
          <span
            style={{
              width: '16pt',
              font: "7pt 'Times New Roman'",
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Final Feedback Report.</span>
          </u>
          <u>
            <span>&nbsp;&nbsp;</span>
          </u>
          <span>
            Within ten (10) days after the expiration or termination of this
            Agreement, Licensee shall provide Vocable with a final written
            Feedback report containing Licensee&rsquo;s configuration, a
            description of the application, an evaluation of the Beta
            Software&rsquo;s functionality, publications, performance, and
            overall support.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee further agrees that Vocable may freely use, disclose,
            reproduce, license, distribute, and otherwise commercialize Feedback
            in any Vocable Beta Software, technology, service, specification, or
            other documentation.
          </span>
        </h3>
        <h3
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '72pt',
            fontSize: '10pt',
          }}
        >
          <span>3.2.5</span>
          <span
            style={{
              width: '16pt',
              font: "7pt 'Times New Roman'",
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Licensee Assistance</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee shall assist Vocable with the determination and resolution
            of problems associated with the Beta Software, which may include
            collecting and furnishing data to Vocable.
          </span>
        </h3>
        <h3
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '72pt',
            fontSize: '10pt',
          }}
        >
          <span>3.2.6</span>
          <span
            style={{
              width: '16pt',
              font: "7pt 'Times New Roman'",
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Access</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee shall allow Vocable&rsquo;s personnel reasonable access to
            Licensee&rsquo;s personnel to discuss the Beta Test/the Beta
            Software for observation of the Beta Test, including Feedback.
          </span>
        </h3>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '10pt',
          }}
        >
          <span>4.</span>
          <span
            style={{
              width: '28.5pt',
              font: "7pt 'Times New Roman'",
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Ownership of Beta Software.&nbsp;</span>
          </u>
        </h1>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>4.1</span>
          <span
            style={{
              width: '23.5pt',
              font: "7pt 'Times New Roman'",
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Beta Software</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Vocable shall retain all right, title, and interest in and to the
            Beta Software and all Intellectual Property therein, and Licensee
            shall not take any action inconsistent with such title and
            ownership.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee shall not have any ownership interest in any element,
            segment, or component of the Beta Software.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>&ldquo;</span>
          <u>
            <span>Intellectual Property</span>
          </u>
          <span>
            &rdquo; means all patent, copyright, trademark, trade secret, or any
            other industrial or intellectual property rights.&nbsp;
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>4.2</span>
          <span
            style={{
              width: '23.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Intellectual Property Notices</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee shall not alter or remove any printed or on-screen
            copyright, trade secret, proprietary or other legal notices
            contained on or in the Beta Software.&nbsp;
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>4.3</span>
          <span
            style={{
              width: '23.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Improvements</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Vocable shall be the sole owner of all right, title and interest in
            and to all improvements to the Beta Software, including any
            improvement made as a result of any Feedback.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee hereby assigns to Vocable all of Licensee&rsquo;s right,
            title and interest in and to any Intellectual Property in any
            Feedback that Licensee provides to Vocable.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee shall execute such other documentation to evidence or
            perfect Vocable&rsquo;s ownership interests in and to any such
            Feedback as may be requested by Vocable from time to time.
          </span>
        </h2>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '10pt',
          }}
        >
          <span>5.</span>
          <span
            style={{
              width: '28.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Term and Termination.</span>
          </u>
        </h1>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>5.1</span>
          <span
            style={{
              width: '23.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Term</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            The effectiveness of this Agreement shall commence on the date that
            Licensee agrees to this Agreement and shall expire on the date that
            is three (3) months thereafter, unless earlier terminated pursuant
            to Section 5.2 (the &ldquo;
          </span>
          <u>
            <span>Term</span>
          </u>
          <span>&rdquo;).</span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>5.2</span>
          <span
            style={{
              width: '23.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Termination</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            This Agreement may be terminated at any time for any or no reason by
            either party upon notice to the other party.
          </span>
        </h2>

        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>5.3</span>
          <span
            style={{
              width: '23.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Effects of Termination</span>
          </u>
          <span>.</span>
        </h2>
        <h4
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '72pt',
            fontSize: '10pt',
          }}
        >
          <span>(a)</span>
          <span
            style={{
              width: '24.9pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>
            Upon expiration or termination of this Agreement, Licensee shall
            have no further right to use or test the Beta Software, and shall
            deliver to Vocable, at Licensee&rsquo;s expense, the Beta Software.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee shall certify in writing to Vocable within ten (10) days
            following termination or expiration that Licensee has complied with
            this Section
          </span>
          <span>&nbsp;</span>
          <span>5.3(a).</span>
        </h4>
        <h4
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '72pt',
            fontSize: '10pt',
          }}
        >
          <span>(b)</span>
          <span
            style={{
              width: '24.34pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>
            The provisions of Sections 3.2.4 (Final Feedback Report), 6
            (Confidentiality), 7 (Disclaimer of Warranty), 8 (Infringement
            Indemnification), 9 (Indemnification by Licensee), 10 (Limitations
            on Liability), 11 (Compliance with Laws), 12 (Notices), 13 (General
            Provisions) and this Section
          </span>
          <span>&nbsp;</span>
          <span>
            5.3 shall survive any termination or expiration of this Agreement
            according to their terms.
          </span>
        </h4>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>5.4</span>
          <span
            style={{
              width: '23.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Following Term</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Following the expiration or termination of this Agreement, Licensee
            and Vocable may agree to a further commercial license of the Beta
            Software, but neither Party is under an obligation to do so.
          </span>
        </h2>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '10pt',
          }}
        >
          <span>6.</span>
          <span
            style={{
              width: '28.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Confidentiality.&nbsp;</span>
          </u>
        </h1>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>6.1</span>
          <span
            style={{
              width: '23.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Confidential Information</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee agrees that in order to further the performance of this
            Agreement, Vocable may disclose to Licensee certain confidential
            information which may be identified as such in writing or may
            reasonably be considered confidential (&ldquo;
          </span>
          <u>
            <span>Confidential Information</span>
          </u>
          <span>&rdquo;).</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            All Feedback shall also constitute Confidential Information.
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>6.2</span>
          <span
            style={{
              width: '23.5pt',
              font: "7pt 'Times New Roman'",
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Protection of Proprietary Information</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee agrees to protect the confidentiality of Confidential
            Information with at least the same degree of care that it utilizes
            with respect to its own similar proprietary information, but in no
            event less than reasonable and customary care for such materials,
            including agreeing:
          </span>
        </h2>
        <h4
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '72pt',
            fontSize: '10pt',
          }}
        >
          <span>(a)</span>
          <span
            style={{
              width: '24.9pt',
              font: "7pt 'Times New Roman'",
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>
            not to disclose or otherwise permit any other person or entity
            access to, in any manner, any Confidential Information, or any part
            thereof in any form whatsoever, except that such disclosure or
            access shall be permitted to an employee of Licensee requiring
            access to such Confidential Information in the course of such
            employee’s employment in connection with this Agreement and who has
            signed an agreement obligating such employee to maintain the
            confidentiality of the confidential information of third Parties in
            Licensee’s possession, and Licensee shall be responsible for any
            action or omission of any such employee that would breach this
            Agreement if made or not made by Licensee;
          </span>
        </h4>
        <h4
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '72pt',
            fontSize: '10pt',
          }}
        >
          <span>(b)</span>
          <span
            style={{
              width: '24.34pt',
              font: "7pt 'Times New Roman'",
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>
            to notify Vocable promptly and in writing of the circumstances
            surrounding any suspected possession, use or knowledge of any
            Confidential Information or any part thereof at any location or by
            any person or entity other than those authorized by this Agreement;
            and
          </span>
        </h4>
        <h4
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '72pt',
            fontSize: '10pt',
          }}
        >
          <span>(c)</span>
          <span
            style={{
              width: '24.9pt',
              font: "7pt 'Times New Roman'",
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>
            not to use any Confidential Information for any purpose other than
            as explicitly set forth herein.
          </span>
        </h4>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>6.3</span>
          <span
            style={{
              width: '23.5pt',
              font: "7pt 'Times New Roman'",
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Injunctive Relief</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Because the unauthorized use, transfer or dissemination of any
            Confidential Information may substantially diminish the value of
            such information and may irreparably harm Vocable, if Licensee
            breaches any provision of this Section
          </span>
          <span>&nbsp;</span>
          <span>
            6, Vocable shall, without limiting its other rights or remedies, be
            entitled to equitable relief, including injunctive relief, without
            posting a bond therefor.
          </span>
        </h2>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '10pt',
          }}
        >
          <span>7.</span>
          <span
            style={{
              width: '28.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Disclaimer of Warranty</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee acknowledges that the Beta Software is a pre-release closed
            beta version of software and is not at the level of performance of a
            commercially available product offering. The Beta Software may be
            substantially modified before being offered as part of a commercial
            offering. THE BETA SOFTWARE IS BEING PROVIDED &ldquo;AS IS&rdquo;
            WITHOUT WARRANTY OF ANY KIND AND VOCABLE HEREBY DISCLAIMS ALL
            WARRANTIES, WHETHER EXPRESS OR IMPLIED, ORAL OR WRITTEN, WITH
            RESPECT TO THE BETA SOFTWARE, INCLUDING WITHOUT LIMITATION ANY
            IMPLIED WARRANTY OF MERCHANTABILITY, NON-INFRINGEMENT, FITNESS FOR
            ANY PARTICULAR PURPOSE, INFORMATIONAL CONTENT, INTERFERENCE WITH
            ENJOYMENT OR THAT THE BETA SOFTWARE IS ERROR-FREE OR FREE FROM
            DEFECTS.
          </span>
          <u>
            <span>&nbsp;</span>
          </u>
        </h1>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '10pt',
          }}
        >
          <span>8.</span>
          <span
            style={{
              width: '28.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Infringement Indemnification</span>
          </u>
          <span>.</span>
        </h1>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>8.1</span>
          <span
            style={{
              width: '23.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>
            Except as provided below, Vocable shall defend and indemnify
            Licensee from and against any damages, liabilities, costs and
            expenses (including reasonable attorneys&rsquo; fees) arising out of
            any claim that the Beta Software infringes a valid U.S. patent,
            copyright or other intellectual property right of a third party or
            misappropriates a trade secret of a third party,&nbsp;
          </span>
          <u>
            <span>provided</span>
          </u>
          <span>
            &nbsp;that (i) Licensee provides Vocable prompt written notice
            thereof and reasonable cooperation, information, and assistance in
            connection therewith, and (ii) Vocable shall have sole control and
            authority with respect to the defense, settlement or compromise
            thereof.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Should the Beta Software become or, in Vocable&rsquo;s opinion, be
            likely to become the subject of an injunction preventing its use as
            contemplated herein, Vocable may, at its option, (1) procure for
            Licensee the right to continue using the Beta Software, (2) replace
            or modify the Beta Software so that it becomes non-infringing or (3)
            terminate Licensee&rsquo;s license to the Beta Software.
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>8.2</span>
          <span
            style={{
              width: '23.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>
            Vocable shall have no liability or obligation to Licensee hereunder
            with respect to any patent, copyright, trade secret or other
            intellectual property infringement, misappropriation or claim
            thereof based on (i) use of the Beta Software by Licensee in
            combination with software not provided by Vocable, or (ii) any
            modification, alteration or enhancement of the Beta Software not
            created by or for Vocable.&nbsp;
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>8.3</span>
          <span
            style={{
              width: '23.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>
            The foregoing states the entire liability of Vocable with respect to
            infringement of patents, copyrights, trade secrets and other
            intellectual property rights by the Beta Software or any part
            thereof or by its operation.
          </span>
        </h2>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '10pt',
          }}
        >
          <span>9.</span>
          <span
            style={{
              width: '28.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Indemnification by Licensee</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee shall defend and indemnify Vocable from and against all
            damages, liabilities, costs and expenses (including reasonable
            attorneys&rsquo; fees) arising out of any claim by a third party
            relating to Licensee&rsquo;s use of the Beta Software,
            Licensee&rsquo;s breach of this Agreement or Licensee&rsquo;s
            negligence, fraud or willful misconduct,&nbsp;
          </span>
          <u>
            <span>provided</span>
          </u>
          <span>
            &nbsp;that (i) Vocable provides Licensee prompt written notice
            thereof and reasonable cooperation, information, and assistance in
            connection therewith, and (ii) Licensee shall have sole control and
            authority with respect to the defense, settlement or compromise
            thereof.
          </span>
        </h1>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '10pt',
          }}
        >
          <span>10.</span>
          <span
            style={{
              width: '23.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Limitations on Liability</span>
          </u>
          <span>.</span>
        </h1>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>10.1</span>
          <span
            style={{
              width: '18.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>
            EXCLUDING INDEMNIFICATION OBLIGATIONS, VOCABLE&rsquo;S LIABILITY FOR
            DAMAGES TO LICENSEE FOR ANY CAUSE WHATSOEVER, REGARDLESS OF THE FORM
            OF ANY CLAIM OR ACTION, SHALL NOT EXCEED THE AMOUNTS PAID TO VOCABLE
            HEREUNDER.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            EXCEPT FOR DAMAGES ARISING FROM A BREACH OF SECTION 3.2.3, 3.2.4, 4
            OR 6, IN NO EVENT SHALL EITHER PARTY BE LIABLE TO THE OTHER PARTY
            FOR ANY LOSS OF DATA, PROFITS OR USE OF THE BETA SOFTWARE, OR FOR
            ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR OTHER INDIRECT
            DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE
            OF THE BETA SOFTWARE.
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>10.2</span>
          <span
            style={{
              width: '18.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>
            Section 10.1 shall apply to the maximum extent permitted by
            applicable Law.
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>10.3</span>
          <span
            style={{
              width: '18.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>
            Licensee will immediately inform Vocable as soon as Licensee becomes
            aware of any threatened or actual liability claim by a third party
            relating to the Beta Software.
          </span>
        </h2>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '10pt',
          }}
        >
          <span>11.</span>
          <span
            style={{
              width: '23.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Compliance with Laws.&nbsp;</span>
          </u>
        </h1>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>11.1</span>
          <span
            style={{
              width: '18.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Export</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee shall not export, directly or indirectly, the Beta
            Software, or other information or materials provided by Vocable
            hereunder, to any country for which the United States or any other
            relevant jurisdiction requires any export license or other
            governmental approval at the time of export without first obtaining
            such license or approval.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            It shall be Licensee&rsquo;s responsibility to comply with all
            applicable United States export Laws, and Licensee shall defend and
            indemnify Vocable from and against any damages, fines, penalties,
            assessments, liabilities, costs and expenses (including reasonable
            attorneys&rsquo; fees and court costs) arising out of any claim that
            the Beta Software or other information or materials provided by
            Vocable hereunder were exported or otherwise shipped or transported
            in violation of applicable Laws.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>&ldquo;</span>
          <u>
            <span>Laws</span>
          </u>
          <span>
            &rdquo; means any declaration, decree, directive, legislative
            enactment, order, common law, ordinance, regulation, rule,
            guideline, guidance or other binding restriction or requirement of
            or by any governmental authority, as may be amended or replaced from
            time to time.
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>11.2</span>
          <span
            style={{
              width: '18.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Compliance with Laws of Other Jurisdictions</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee shall comply with all applicable Laws and industry
            standards with respect to the Beta Software or the use thereof, and
            the performance by Licensee of its obligations hereunder, existing
            in any jurisdiction in which Licensee uses the Beta Software.
          </span>
        </h2>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '10pt',
          }}
        >
          <span>12.</span>
          <span
            style={{
              width: '23.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Notices.&nbsp;</span>
          </u>
        </h1>
        <p
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            widows: '2',
            orphans: '2',
            fontSize: '10pt',
          }}
        >
          Any notice or communication from one party to the other party shall be
          in writing and either personally delivered or sent via certified mail,
          postage prepaid and return receipt requested addressed, to such other
          party at the address specified below or such other address as either
          party may from time to time designate in writing to the other party.
        </p>
        <table
          cellSpacing="0"
          cellPadding="0"
          style={{ marginLeft: '41.4pt', borderCollapse: 'collapse' }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: '70.2pt',
                  paddingRight: '5.4pt',
                  paddingLeft: '5.4pt',
                  verticalAlign: 'top',
                }}
              >
                <p
                  style={{
                    marginTop: '0pt',
                    marginBottom: '0pt',
                    widows: '2',
                    orphans: '2',
                    fontSize: '10pt',
                  }}
                >
                  If to Vocable:
                </p>
              </td>
              <td
                style={{
                  width: '345.6pt',
                  paddingRight: '5.4pt',
                  paddingLeft: '5.4pt',
                  verticalAlign: 'top',
                }}
              >
                <p
                  style={{
                    marginTop: '0pt',
                    marginBottom: '0pt',
                    widows: '2',
                    orphans: '2',
                    fontSize: '10pt',
                  }}
                >
                  Info@vocable.ai
                </p>
                <p
                  style={{
                    marginTop: '0pt',
                    marginBottom: '24pt',
                    widows: '2',
                    orphans: '2',
                    fontSize: '10pt',
                  }}
                >
                  Attn: Vocable Team
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: '70.2pt',
                  paddingRight: '5.4pt',
                  paddingLeft: '5.4pt',
                  verticalAlign: 'top',
                }}
              >
                <p
                  style={{
                    marginTop: '0pt',
                    marginBottom: '0pt',
                    widows: '2',
                    orphans: '2',
                    fontSize: '10pt',
                  }}
                >
                  with a copy to:
                </p>
              </td>
              <td
                style={{
                  width: '345.6pt',
                  paddingRight: '5.4pt',
                  paddingLeft: '5.4pt',
                  verticalAlign: 'top',
                }}
              >
                <p
                  style={{
                    marginTop: '0pt',
                    marginBottom: '0pt',
                    widows: '2',
                    orphans: '2',
                    fontSize: '10pt',
                  }}
                >
                  Wilmer Cutler Pickering Hale and Dorr LLP
                </p>
                <p
                  style={{
                    marginTop: '0pt',
                    marginBottom: '0pt',
                    widows: '2',
                    orphans: '2',
                    fontSize: '10pt',
                  }}
                >
                  60 State Street
                </p>
                <p
                  style={{
                    marginTop: '0pt',
                    marginBottom: '0pt',
                    widows: '2',
                    orphans: '2',
                    fontSize: '10pt',
                  }}
                >
                  Boston, Massachusetts 02109
                </p>
                <p
                  style={{
                    marginTop: '0pt',
                    marginBottom: '24pt',
                    widows: '2',
                    orphans: '2',
                    fontSize: '10pt',
                  }}
                >
                  Attn: Ariel Soiffer, Esq.
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: '70.2pt',
                  paddingRight: '5.4pt',
                  paddingLeft: '5.4pt',
                  verticalAlign: 'top',
                }}
              >
                <p
                  style={{
                    marginTop: '0pt',
                    marginBottom: '0pt',
                    widows: '2',
                    orphans: '2',
                    fontSize: '10pt',
                  }}
                >
                  If to Licensee:
                </p>
              </td>
              <td
                style={{
                  width: '345.6pt',
                  paddingRight: '5.4pt',
                  paddingLeft: '5.4pt',
                  verticalAlign: 'top',
                }}
              >
                <p
                  style={{
                    marginTop: '0pt',
                    marginBottom: '24pt',
                    widows: '2',
                    orphans: '2',
                    fontSize: '10pt',
                  }}
                >
                  The address submitted by Licensee during the registration
                  process to license the Beta Software
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p
          style={{
            marginTop: '0pt',
            marginBottom: '12pt',
            widows: '2',
            orphans: '2',
            fontSize: '10pt',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            widows: '2',
            orphans: '2',
            fontSize: '10pt',
          }}
        >
          No change of address shall be binding upon the other party until
          written notice thereof is received by such party at the address show
          herein.&nbsp; All notices shall be in English and shall be effective
          upon receipt.
        </p>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '10pt',
          }}
        >
          <span>13.</span>
          <span
            style={{
              width: '23.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>General Provisions.</span>
          </u>
        </h1>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>13.1</span>
          <span
            style={{
              width: '18.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Force Majeure</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            In the event that either party is prevented from performing, or is
            unable to perform, any of its obligations under this Agreement due
            to any cause beyond the reasonable control of the party invoking
            this provision, the affected party&rsquo;s performance shall be
            excused and the time for performance shall be extended for the
            period of delay or inability to perform due to such occurrence.
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>13.2</span>
          <span
            style={{
              width: '18.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Publicity</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Licensee agrees that Vocable may issue a news release or other
            public announcement relating to the performance of the Beta Software
            which may include Feedback or other results of the Beta Test, but
            shall reasonably consider any input of Licensee in such a news
            release or other public announcement. Licensee shall not issue any
            news release or other public announcement relating to this
            Agreement.
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>13.3</span>
          <span
            style={{
              width: '18.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Waiver</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            The waiver by either party of a breach or a default of any provision
            of this Agreement by the other party shall not be construed as a
            waiver of any succeeding breach of the same or any other provision,
            nor shall any delay or omission on the part of either party to
            exercise or avail itself of any right, power or privilege that it
            has, or may have hereunder, operate as a waiver of any right, power
            or privilege by such party.
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>13.4</span>
          <span
            style={{
              width: '18.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>
              No Agency; Independent Contractors; Third Party Beneficiaries
            </span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Nothing contained in this Agreement shall be deemed to imply or
            constitute either party as the agent or representative of the other
            party, or both parties as joint ventures or partners for any
            purpose.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Except for the parties, this Agreement is not intended to create any
            right or cause of action in or on behalf of any other person or
            entity.
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>13.5</span>
          <span
            style={{
              width: '18.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Governing Law</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            This Agreement shall be governed by and construed in accordance with
            the Laws of the State of New York, without regard to its conflict of
            Law provisions that would result in the application of the Law of
            any other state except for New York General Obligations Laws 5-1401
            and 5-1402====.
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>13.6</span>
          <span
            style={{
              width: '18.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Entire Agreement; Amendment</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            This Agreement and the Exhibits attached hereto constitute the
            entire agreement between the parties with regard to the subject
            matter hereof.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            No consent, modification or change of any term of this Agreement
            shall bind either Party unless in writing signed by both parties,
            except as set forth in Section 2.1.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            No waiver of any term of this Agreement shall bind the party making
            such waiver unless in writing and signed by the party making such
            waiver.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Any such waiver shall be effective only in the specific instance and
            for the specific purpose given.&nbsp;
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>13.7</span>
          <span
            style={{
              width: '18.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Headings; Interpretation</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            Captions and headings contained in this Agreement have been included
            for ease of reference and convenience and shall not be considered in
            interpreting or construing this Agreement.
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>
            The words &ldquo;including&rdquo;, &ldquo;includes&rdquo; and
            &ldquo;include&rdquo; shall be deemed to be followed by
            &ldquo;without limitation&rdquo;, unless already followed by such
            words.
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>13.8</span>
          <span
            style={{
              width: '18.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Costs, Expenses and Attorneys&rsquo; Fees</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            If either party commences any action or proceeding against the other
            party to enforce or interpret this Agreement, the prevailing party
            in such action or proceeding shall be entitled to recover from the
            other party the actual costs, expenses and reasonable
            attorneys&rsquo; fees (including all related costs and expenses),
            incurred by such prevailing party in connection with such action or
            proceeding and in connection with obtaining and enforcing any
            judgment or order thereby obtained.&nbsp;
          </span>
        </h2>
        <h2
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          <span>13.9</span>
          <span
            style={{
              width: '18.5pt',
              font: '7pt Times New Roman',
              display: 'inline-block',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <u>
            <span>Assignment</span>
          </u>
          <span>.</span>
          <span>&nbsp;&nbsp;</span>
          <span>
            This Agreement, and the rights and obligations hereunder, may not be
            assigned, in whole or in part by Licensee.
          </span>
        </h2>
        <p
          style={{ marginTop: '12pt', marginBottom: '12pt', fontSize: '10pt' }}
        >
          &nbsp;
        </p>
        <p
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textIndent: '36pt',
            fontSize: '10pt',
          }}
        >
          Last Updated: 09/01/2023
        </p>
      </div>
      <p>
        <br
          style={{
            pageBreakBefore: 'always',
            clear: 'both',
            msoBreakType: 'section-break',
          }}
        />
      </p>
      <div>
        <p
          style={{
            marginTop: '0pt',
            marginBottom: '12pt',
            textAlign: 'center',
          }}
        >
          <strong>
            <u>EXHIBIT A</u>
          </strong>
        </p>
        <p
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            textAlign: 'center',
          }}
        >
          <strong>Beta Test Objectives</strong>
        </p>
        <p
          style={{
            marginTop: '12pt',
            marginBottom: '6pt',
            lineHeight: '200%',
            fontSize: '10pt',
          }}
        >
          The objective of the Beta Test is for (1) Licensee to test the Beta
          Software for potential use by Licensee for its internal business
          purposes and (2) Vocable to test the Beta Software and receive
          feedback on potential improvements to the Beta Software and on related
          documentation and processes.
        </p>
      </div>
    </Container>
  </LandingLayout>
);

export default TermsConditions;
