import React from 'react';

import TemplateCard from 'Components/TemplateCard/TemplateCard';
import { removeSpacesAndHyphens } from 'helpers';

function CategoryTemplateCard({
  templates,
  setSelected,
  selected,
  handleTemplateClick,
}) {
  return templates.map((cardData) => {
    const { category_name } = cardData || {};

    return (
      <TemplateCard
        ImageSrc={`/Images/${removeSpacesAndHyphens(cardData?.title)}.svg`}
        heading={cardData?.title}
        text={cardData?.description.slice(0, 80)}
        key={cardData?.id}
        id={cardData?.id}
        className={category_name.replace(/\s/g, '')}
        onClick={() =>
          handleTemplateClick
            ? handleTemplateClick(cardData?.id)
            : setSelected(cardData?.id)
        }
        selected={selected}
      />
    );
  });
}

export default CategoryTemplateCard;
