import React from 'react';
import './TableSkeleton.scss';

export function TableSkeleton(props) {
  const count = props.count || 1;
  const skeletonRows = Array.from({ length: count }, (_, index) => (
    <tr key={index} className={` ${props.class} row-class`}>
      <td className={`loading ${props.className}`}>
        <div className="bar"></div>
      </td>
      <td className={`loading ${props.className}`}>
        <div className="bar"></div>
      </td>
      <td
        className={`loading ${props.className} d-flex align-items-center gap-3`}
      >
        <div className="circle"></div>
        <div className="bar"></div>
      </td>
      <td className={`loading ${props.className}`}>
        <div className="bar"></div>
      </td>
      <td className={`loading ${props.className}`}>
        <div className="bar"></div>
      </td>
      <td className={`loading ${props.className}`}>
        <div className="bar"></div>
      </td>
    </tr>
  ));

  return <tbody>{skeletonRows} </tbody>;
}
