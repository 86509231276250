import {
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from 'helpers/axios';

// ##### ##### ##### ##### #####   GET   ##### ##### ##### ##### ##### //
// TODO: tasks

// TODO: Check this Routes
export const getCompletedTasks = (projectId = '') => {
  return getRequest(`tasks/completed?project_id=${projectId}`);
};

export const getOverdueTasks = (projectId = '') => {
  return getRequest(`tasks/overdue?project_id=${projectId}`);
};

export const getTasksOrderedByPriority = () => {
  return getRequest(`tasks/ordered_by_priority`);
};

export const getTasksByDuration = (duration = 'week', projectId = '') => {
  return getRequest(
    `tasks/total_count?duration=${duration}&project_id=${projectId}`,
  );
};

export const getUpcomingTasks = (projectId = '') => {
  return getRequest(`tasks/upcoming?project_id=${projectId}`);
};

export const getTask = (taskId = '') => {
  return getRequest(`tasks/${taskId}`);
};

// ##### ##### ##### ##### #####   POST   ##### ##### ##### ##### ##### //
// TODO: tasks
// TODO: tasks/generate_briefs
// TODO: tasks/generate_headlines

export const generateAiPoweredBrief = (body) => {
  return postRequest(`tasks/generate_ai_powered_brief`, body);
};

export const generateIdeas = (body) => {
  return postRequest('tasks/ideate', body);
};

// ##### ##### ##### ##### #####   PUT   ##### ##### ##### ##### ##### //
// TODO: tasks/:taskId
export const updateTask = (taskId, body) => {
  return putRequest(`tasks/${taskId}`, body);
};

// ##### ##### ##### ##### #####   PATCH   ##### ##### ##### ##### ##### //
// TODO: tasks/:taskId
export const archiveTask = (taskId) => {
  return patchRequest(`tasks/${taskId}/mark_archived`);
};

// ##### ##### ##### ##### #####   DELETE   ##### ##### ##### ##### ##### //
// TODO: tasks/:taskId
