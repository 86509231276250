import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { setLoader } from 'redux/slices/loader';

import BSButton from 'Components/Button/BSButton';
import ImageView from 'Components/Image/ImageView';
import Input from 'Components/Input/Input';
import { EMAIL_REGEX } from 'constants';
import { getUserImage } from 'helpers';
import { getRequest } from 'helpers/axios';
import { createProjectUser } from 'services/projectService';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import Styles from './EditorAddModal.module.scss';
import { getUsers } from 'services/userService';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';
import { useSelector } from 'react-redux';

const NoOptionsMessage = () => {
  return <p className={Styles.NoOptionsMessage}>No team members</p>;
};

const EditorAddModal = ({ editors, show, onHide }) => {
  const [user, setUser] = useState('');
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [email, setEmail] = useState('');
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { user : loggedUser } = useSelector((state) => state.auth);
  const { id: userId, subscriptions } = loggedUser || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  const { projectId } = useParams();
  const dispatch = useDispatch();

  const fetchUsers = useCallback(async () => {
    const usersRes = await getUsers(search, 1);

    const { data } = usersRes || {};
    let { users: dataUsers } = data || {};
    if (data) {
      dataUsers = dataUsers.filter(
        (dataUser) => !editors.some((editor) => editor.id === dataUser.id),
      );
    }

    setUsers(dataUsers || []);
  }, [search]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const usersOptions = useMemo(() => {
    if (!users?.length) {
      return [];
    }

    return users?.map((user) => {
      return {
        value: user.id,
        name: user.name,
        label: (
          <div className="d-flex align-items-center gap-3">
            {user.mini_avatar ? (
              <ImageView src={user.mini_avatar} class="rounded-profile-x-sm" />
            ) : (
              getUserImage(
                user.mini_avatar,
                user.name,
                {
                  width: 32,
                  height: 32,
                },
                'sm',
              )
            )}
            <p>
              {user.name} ({user.email})
            </p>
          </div>
        ),
      };
    });
  }, [users]);

  const filterColors = async (inputValue) => {
    if (!inputValue) {
      return usersOptions;
    }

    return usersOptions.filter((i) =>
      i.name.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterColors(inputValue));
      }, 1000);
    });

  const handleEditorAdd = async () => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Adding Collaborator!',
          description: 'Please wait...',
        }),
      );
      await createProjectUser(projectId, {
        project_user: {
          ...(email ? { user_email: email } : { user_id: user }),
        },
      });

      onHide();
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
      renderSuccess('Collaborator Added Successfully');
    } catch (error) {
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
      renderError(error);
    }
  };


  const isButtonDisabled = !user && (!email || !email.match(EMAIL_REGEX));
  const conditionalProps = user ? {} : { value: null };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 1100 }}
    >
      <Modal.Header className="border-0 pb-0" closeButton>
        <Modal.Title className="Modal-Title">Add Collaborator</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className={Styles.inputsContainer}>
          <AsyncSelect
            placeholder="Select User"
            cacheOptions
            defaultOptions={usersOptions}
            loadOptions={promiseOptions}
            className="w-100"
            components={{
              NoOptionsMessage,
            }}
            onInputChange={(newValue) => setSearch(newValue)}
            onChange={(value) => {
              setUser(value?.value);
              setEmail('');
            }}
            isClearable
            {...conditionalProps}
          />
          <span className={Styles.orMessage}>OR</span>
          <Input
            inputWrapperClass="w-100"
            type="text"
            placeholder="Enter Email"
            classList="w-100 input "
            value={email}
            onChange={({ target: { value } }) => {
              setEmail(value);
              setUser('');
            }}
          />
        </div>
        <BSButton
          BtnWrapperClass="w-100"
          onClick={
            currentSubscription?.plan_name === 'Basic Plan' &&
            projectId !== null
              ? ()=>setShowUpgradeModal(true)
              : ()=>handleEditorAdd()
          }
          classList="w-100 mt-4 secondary_btn"
          variant="dark"
          ButtonText="Add"
          disabled={isButtonDisabled}
        />
        {showUpgradeModal && (
          <UpgradeModal
            show={showUpgradeModal}
            onHide={() => setShowUpgradeModal(false)}
            message={
              'Updating campaign related content is a pro feature. Please update to pro plan to access this feature.'
            }
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EditorAddModal;
