import React from 'react';

import Styles from './FromObjectivesSection.module.scss';
import Video from '../../../assets/landingpage/new.mov';
import { useNavigate } from 'react-router-dom';

const FromObjectivesSection = () => {
  const navigate = useNavigate();
  return (
    <div className={Styles.outer_container}>
      <div className={Styles.outer_container__top}>
        <p className={Styles.outer_container__top__heading_1}>
          From planning to
        </p>
        <p className={Styles.outer_container__top__heading_2}>
          publishing in minutes
        </p>
        <p className={Styles.outer_container__top__description}>
          Enter your business URL and watch our AI audit, ideate, plan, and
          draft <br />
          weeks worth of content in minutes!
        </p>
        <button
          className={Styles.outer_container__top__btn}
          onClick={() => navigate('sign-up')}
        >
          Start Your Free Trial
        </button>
      </div>

      <div className={Styles.outer_container__bottom}>
        <div className={Styles.outer_container__bottom__buttons}>
          <span className={Styles.outer_container__bottom__buttons__btn}>
            Input your URL and goals
          </span>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
            >
              <g clipPath="url(#clip0_20437_134488)">
                <path
                  d="M0.996094 8.36003H30.3294M30.3294 8.36003L25.6628 3.69336M30.3294 8.36003L25.6628 13.0267"
                  stroke="#98A2B3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_20437_134488">
                  <rect
                    width="33"
                    height="16"
                    fill="white"
                    transform="translate(0.332031 0.679688)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
          <span className={Styles.outer_container__bottom__buttons__btn}>
            Generate full content plan
          </span>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="17"
              viewBox="0 0 34 17"
              fill="none"
            >
              <g clipPath="url(#clip0_20437_134488)">
                <path
                  d="M0.996094 8.36003H30.3294M30.3294 8.36003L25.6628 3.69336M30.3294 8.36003L25.6628 13.0267"
                  stroke="#98A2B3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_20437_134488">
                  <rect
                    width="33"
                    height="16"
                    fill="white"
                    transform="translate(0.332031 0.679688)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
          <span className={Styles.outer_container__bottom__buttons__btn}>
            Review, edit, and Publish
          </span>
        </div>
        <p className={Styles.outer_container__bottom__text}>
          Let Our AI Generate a Tailored Content Plan for Your Brand
        </p>
        <div className={Styles.outer_container__bottom__video}>
          <video autoPlay muted loop>
            <source src={Video} />
          </video>
        </div>
      </div>
    </div>
  );
};

export default FromObjectivesSection;
