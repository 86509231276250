import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import { useNavigate, useSearchParams } from 'react-router-dom';

import BSButton from 'Components/Button/BSButton';
import Styles from './BackToOnboarding.module.scss';
import { ROUTES } from 'constants/routes';

const BackToOnboarding = () => {
  const navigate = useNavigate();
  const [URLSearchParams, setURLSearchParams] = useSearchParams();

  return (
    <div className={Styles.BackToOnboarding}>
      <div className="w-100 d-inline-flex align-items-center">
        <div className={Styles.BackToOnboarding_Title}>
          Wanted to create something else?
        </div>
        <BSButton
          classList="button"
          icon={
            <IoMdClose
              className={Styles.BackToOnboarding_CloseButton}
              size={24}
            />
          }
          type="button"
          onClick={() => {
            URLSearchParams.delete('onboarding');
            setURLSearchParams(URLSearchParams);
          }}
        />
      </div>
      <div>
        <BSButton
          BtnWrapperClass={Styles.BackToOnboarding_BackButton}
          style={{ fontWeight: '300', fontSize: '13px' }}
          classList="button"
          icon={<FiArrowLeft size={16} />}
          ButtonText="Back to Onboarding"
          type="button"
          onClick={() => navigate(ROUTES.Onboarding)}
        />
      </div>
    </div>
  );
};

export default BackToOnboarding;
