import React from 'react';
import Styels from './style.module.css';

const CustomCheckbox = ({ label, id, checked, onChange }) => {
  return (
    <div className={Styels.form_group}>
      <input type="checkbox" id={id} checked={checked} onChange={onChange} />
      <label for={id}>{label}</label>
    </div>
  );
};

export default CustomCheckbox;
