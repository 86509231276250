import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from 'redux/slices/auth';
import ProfileStyles from './ProfileSection.module.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ProfileModal from 'Containers/Modal/Profile Modal/ProfileModal';
import { getNameInitials } from 'helpers';
import { FiLogOut, FiUser } from 'react-icons/fi';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { BILLING_SUBSCRIPTION } from 'constants';
import { FaChevronDown } from 'react-icons/fa6';

const Profile = ({ isSubscriptionHeader = false }) => {
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { user } = useSelector((state) => state.auth);
  const profileImage = user?.medium_avatar;
  const title = profileImage ? (
    <img
      className={ProfileStyles.ProfileImage}
      src={profileImage}
      alt="Profie"
    />
  ) : (
    <div className={ProfileStyles.user_container}>
      <span className="rounded-profile">{getNameInitials(user?.name)}</span>
      <div className={ProfileStyles.user_name_box}>
        <div className={ProfileStyles.user_container__details}>
          <p className={ProfileStyles.user_container__details__name}>
            {user?.name}
          </p>
          <div className={ProfileStyles.user_container__details__user}>
            <span
              className={ProfileStyles.user_container__details__user__email}
            >
              {user?.role}
            </span>
          </div>
        </div>
        <span className={ProfileStyles.user_container__details__user__drop}>
          <FaChevronDown
            className={ProfileStyles.user_container__details__user__drop__icon}
          />
        </span>
      </div>
    </div>
  );

  return (
    <div>
      <DropdownButton
        className={ProfileStyles.ImgdropDownBtn + ' profileDropDownBtn'}
        title={title}
      >
        <Dropdown.ItemText className={'d-flex ' + ProfileStyles.profileHeader}>
          {/* {title} */}
          {/* <div
            className={
              'd-flex flex-column ' + ProfileStyles.profileHeader__infoSection
            }
          >
            <span className={ProfileStyles.profileHeader__infoSection_name}>
              {user?.name}
            </span>
            <span className={ProfileStyles.profileHeader__infoSection_email}>
              {user?.email}
            </span>
          </div> */}
        </Dropdown.ItemText>
        {!isSubscriptionHeader && (
          <>
            <Dropdown.Item
              onClick={() => setModalShow(true)}
              as="button"
              className={ProfileStyles.profileOptionListItem}
            >
              <FiUser size={16} color="#344054" />
              <span>Profile</span>
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              className={ProfileStyles.profileOptionListItem}
              onClick={() => navigate(`/${BILLING_SUBSCRIPTION}`)}
            >
              <img src="/Images/billing_icon.svg" alt=""></img>
              <span>Billing</span>
            </Dropdown.Item>
          </>
        )}
        <Dropdown.Item
          as="button"
          className={ProfileStyles.profileOptionListItem}
          onClick={() => {
            queryClient.removeQueries();
            dispatch(logout({ showToast: true }));
          }}
        >
          <FiLogOut size={16} color="#D92D20" />
          <span>Log out</span>
        </Dropdown.Item>
      </DropdownButton>

      {modalShow && (
        <ProfileModal show={modalShow} onHide={() => setModalShow(false)} />
      )}
    </div>
  );
};

export default Profile;
