import ImageView from 'Components/Image/ImageView';
import Profile from 'Components/Profile/Profile';
import { useNavigate } from 'react-router-dom';
import HeaderStyles from './SubscriptionHeader.module.scss';
import React from 'react';

const SubscriptionHeader = () => {
  const navigate = useNavigate();

  return (
    <div
      className={
        HeaderStyles.Dashboard_header +
        ' d-flex justify-content-between align-items-center'
      }
    >
      <div style={{ cursor: 'pointer' }}>
        <ImageView
          src="/Images/vocable__logo_svg.svg"
          alt="Vocable"
          class="img-fluid logo-img mb-2"
          onClick={() => navigate("/")}
        />
      </div>
      <div className="d-flex g-10 align-items-center">
        <Profile isSubscriptionHeader={true} />
      </div>
    </div>
  );
};

export default SubscriptionHeader;
