import React from 'react';
import Form from 'react-bootstrap/Form';
import Styles from './Header.module.scss';

const Header = ({ isChecked, handleToggleClick }) => {
  return (
    <div className={Styles.outerContainer__card}>
      <div className={Styles.outerContainer__card__left}>
        <p>Pricing Plans</p>
      </div>
      <div className={Styles.outerContainer__card__right}>
        <span>{isChecked ? 'Billed Yearly' : 'Billed Monthly'}</span>
        {/* <span>Billed Yearly</span> */}

        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
            label=""
            className={Styles.outerContainer__card__right__switch}
            checked={isChecked}
            onChange={handleToggleClick}
          />
        </Form>
        {/* <span>Billed Annually</span> */}
        <div className={Styles.outerContainer__card__right__badge}>
          Limited Offer: Save up to {isChecked ? '40%' : '30%'}
        </div>
      </div>
    </div>
  );
};

export default Header;
