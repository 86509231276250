import { getRequest } from 'helpers/axios';

// ##### ##### ##### ##### #####   GET   ##### ##### ##### ##### ##### //
export const getResearch = (keyword) => {
  return getRequest(`research?phrase=${keyword}`);
};

export const getMultiResearch = (keyword) => {
  return getRequest(`research/multi?phrases=${keyword}`);
};
