import React from 'react';
import FileCard from 'Components/DocumentFileCard/FileCard';
import Styles from './DocumentFileView.module.scss';

const DocumentFileView = ({ documents, fetchFolders, isTrash, projects }) => {
  return (
    <section className={Styles.DocumentView}>
      {documents?.map((document) => (
        <FileCard
          key={document.id}
          document={document}
          fetchFolders={fetchFolders}
          isTrash={isTrash}
          projects={projects}
        />
      ))}
    </section>
  );
};

export default DocumentFileView;
