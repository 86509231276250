
import React, { useState, useEffect } from 'react';
import Styles from './Styles.module.scss';
import FormikTextField from 'Components/Form/FormikTextField';
import { Form } from 'react-bootstrap';
import { ErrorMessage, Formik } from 'formik';
import ButtonSection from '../ButtonSection/ButtonSection';
import { useDispatch, useSelector } from 'react-redux';
import onBoardingService from 'services/onBoardingService';
import { prevStep, nextStep, updateBusinessSummary, updateStep3, updateStep1 } from 'redux/slices/newOnboarding';
import * as Yup from 'yup';
import Board from './Board';
import TagInput from 'Components/Input/TagsInput/TagInput';
import { toast } from 'react-toastify';
import { error as errorToast, success as successToast } from 'helpers/toaster';
import { actions } from 'redux/slices/auth';


const BusinessSummary = ({ showBoard, setShowBoard, haveweb }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const onboardingData1 = useSelector((state) => state.newOnboarding.step1);
  const onboardingData = useSelector((state) => state?.newOnboarding?.businessSummary);
  let interval;
  let timeoutCounter = 0;
  const maxTimeout = 80000; 

  const [initialValues, setInitialValues] = useState({
    brand_name: onboardingData?.brand_name || '',
    brand_description: onboardingData?.brand_description || '',
    core_messaging: onboardingData?.core_messaging || '',
    value_proposition: onboardingData?.value_proposition || '',
    industries: onboardingData?.industries || [],
    keywords_and_themes: onboardingData?.keywords_and_themes || [],
    target_audience: onboardingData?.target_audience || [],
    tone: onboardingData?.tone || []
  });

  const validationSchema = Yup.object().shape({
    brand_name: Yup.string().trim().required('Brand Name is required'),
    brand_description: Yup.string().trim().required('Brand description is required'),
    core_messaging: Yup.string().trim().required('Core messaging is required'),
    value_proposition: Yup.string().trim().required('Value proposition is required'),
    industries: Yup.array().of(Yup.string().required()).min(1, 'Industry is required'),
    keywords_and_themes: Yup.array().of(Yup.string().required()).min(1, 'Keyword is required'),
    target_audience: Yup.array().of(Yup.string().required()).min(1, 'Target audience is required'),
    tone: Yup.array().of(Yup.string().required()).min(1, 'Tone is required'),
  });

  useEffect(() => {
    if (!onboardingData || Object.keys(onboardingData).length === 0) {
      setShowBoard(true);
      handleAutofill(onboardingData1?.url, onboardingData1?.logo);
    } else {
      setIsLoading(false);
    }
  }, [onboardingData1?.url, onboardingData1?.logo]);

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, []);



  const handleAutofill = async (url, logo) => {
    try {
      const response = await onBoardingService.getAnalyzeBusiness(url);
      if (response?.data) {
        pollFetchScrappedBusiness(url); 
      } else {
        errorToast('There is some issue while scrapping. Please try Again');
        dispatch(prevStep());
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.error || 'We are unable to fetch data from scrapper. Try Again';
      toast.error(errorMessage);
      dispatch(updateStep1({ logo, url: '' }));
      dispatch(prevStep());
    }
  };

  const handleError = () => {
    setShowBoard(false);
    dispatch(prevStep());
    errorToast('We are unable to fetch data from scrapper. Try Again');
  };

  const postFailedScrapper = async (url) => {
    try {
      await onBoardingService.postFailedScrapper(url);
    } catch (error) {
      console.error('Failed to post failed scraper:', error);
    }
  };

  const pollFetchScrappedBusiness = (url) => { 
    interval = setInterval(async () => {
      try {
        let response = await onBoardingService.getScrappedBusiness(url); 
        if (response?.data?.scraper_status === 'completed') {
          if (response?.data?.response) {
            setInitialValues({
              brand_name: response.data.response.brand_name || '',
              brand_description: response.data.response.brand_description || '',
              core_messaging: response.data.response.core_messaging || '',
              value_proposition: response.data.response.value_proposition || '',
              industries: response.data.response.industries || [],
              keywords_and_themes: response.data.response.keywords_and_themes || [],
              target_audience: response.data.response.target_audience || [],
              tone: response.data.response.tone || []
            });
            clearInterval(interval); 
            setShowBoard(false);
          }
        } else if (response?.data?.scraper_status === 'failed') {
          handleError();
          clearInterval(interval);
        }
        else {
          timeoutCounter += 10000; // Polling interval  (10 seconds)
            if (timeoutCounter >= maxTimeout) {
            handleError();
            clearInterval(interval);
            await postFailedScrapper(url)
          }
        }
      } catch (error) {
        handleError();
        clearInterval(interval);
      }
    }, 10000); // Poll every 10 seconds
  };

  const handleBack = (values) => {
    dispatch(updateBusinessSummary(values));
    dispatch(prevStep());
  };

  const fetchBrands = async () => {
    try {
      const response = await onBoardingService.getUserBrandInfo();
      dispatch(actions.updateUser({ user: { user_brands: response?.data } }));
      // setBrandsData(response?.data?.brands_data);
    } catch (error) {
      console.error(error);
    }
  };


  const handleNext = async (values) => {
    try {
      let body = { auto_generated_summary: values, ...onboardingData1, brand_name: values.brand_name };
      const response = await onBoardingService.createBusiness(body);
      if (response?.data?.success) {
        dispatch(updateStep3({ topics: response?.data?.business?.content_topics }));
        dispatch(updateBusinessSummary(values));
        fetchBrands();
        dispatch(nextStep());
      } else {
        errorToast('An error occurred 1: ' + response.message); 
      }
    } catch (error) {
      console.error(error);
      errorToast('An error occurred 2: ' + error.message);
    }
  };

  if (showBoard) {
    return <Board />;
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={handleNext}
      >
        {({ values, setFieldValue, isValid, dirty, submitForm }) => {
          const handleClick = () => {
            submitForm();
          };
          return (
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className={Styles.form2_wrapper}
              >
                  <div className={`${Styles.textarea_wrapper} ${Styles.bg_green_light}`}>
                  <label htmlFor="" className={Styles.textarea_wrapper__label}>
                    Brand Name
                  </label>
                  <FormikTextField
                    id="brand_name"
                    name="brand_name"
                    type="text"
                    placeholder="Enter brand name..."
                    Label=""
                    classList="input form-control w-100"
                    isRequired
                    // isTextArea
                  />
                  <ErrorMessage
                    name="brand_name"
                    component="div"
                    className="error mt-2"
                  />
                </div>
                <div className={`${Styles.textarea_wrapper} ${Styles.bg_purpel}`}>
                  <label htmlFor="" className={Styles.textarea_wrapper__label}>
                    Brand Description
                  </label>
                  <FormikTextField
                    id="brand_description"
                    name="brand_description"
                    type="text"
                    placeholder="Enter brand description..."
                    Label=""
                    classList="input form-control text-area-height w-100"
                    isRequired
                    isTextArea
                  />
                  <ErrorMessage
                    name="brand_description"
                    component="div"
                    className="error mt-2"
                  />
                </div>
                <div
                  className={`${Styles.textarea_wrapper} ${Styles.bg_yellow}`}
                >
                  <TagInput
                    label="Audience"
                    tags={values.target_audience}
                    onChange={(value) => setFieldValue('target_audience', value)}
                  />
                   <ErrorMessage
                    name="target_audience"
                    component="div"
                    className="error mt-2"
                  />
                </div>
                <div
                  className={`${Styles.textarea_wrapper} ${Styles.bg_green}`}
                >
                     <TagInput
                    label="Tone of Voice"
                    tags={values.tone}
                    onChange={(value) => setFieldValue('tone', value)}
                  />
                    <ErrorMessage
                    name="tone"
                    component="div"
                    className="error mt-2"
                  />
                </div>
                <div
                  className={`${Styles.textarea_wrapper} ${Styles.bg_green_light}`}
                >
                  <label htmlFor="" className={Styles.textarea_wrapper__label}>
                    Core Messaging
                  </label>
                  <FormikTextField
                    id="core_messaging"
                    name="core_messaging"
                    type="text"
                    placeholder="The brand voice is very professional, informative and inclusive, prioritising clarity, precision, consistency, transparency and creative while emphasising trust worthiness, diversity and innovation in a friendly yet formal tone."
                    Label=""
                    classList="input form-control text-area-height w-100"
                    isRequired
                    isTextArea
                  />
                  <ErrorMessage
                    name="core_messaging"
                    component="div"
                    className="error mt-2"
                  />
                </div>
                <div
                  className={`${Styles.textarea_wrapper} ${Styles.bg_purpel}`}
                >
                     <TagInput
                    label="Industry"
                    tags={values.industries}
                    onChange={(value) => setFieldValue('industries', value)}
                  />
                   <ErrorMessage
                    name="industries"
                    component="div"
                    className="error mt-2"
                  />
                </div>
          
                <div
                  className={`${Styles.textarea_wrapper} ${Styles.bg_green_light_2}`}
                >
                  <label htmlFor="" className={Styles.textarea_wrapper__label}>
                    Value Proposition
                  </label>
                  <FormikTextField
                    id="value_proposition"
                    name="value_proposition"
                    type="text"
                    placeholder="Innovation: Continuously pushing the boundaries of AI to simplify content creation and marketing.
                    Efficiency: Streamlining processes to save time and enhance productivity.
                    Creativity: Empowering users to break through creative blocks and generate fresh, impactful content.
                    Collaboration: Facilitating teamwork and seamless collaboration within content operations.
                    User-Centricity: Designing tools and features with the end-user's needs and goals in mind."
                    Label=""
                    classList="input form-control text-area-height w-100"
                    isRequired
                    isTextArea
                  />
                  <ErrorMessage
                    name="value_proposition"
                    component="div"
                    className="error mt-2"
                  />
                </div>
                <div
                  className={`${Styles.textarea_wrapper} ${Styles.bg_yellow}`}
                >
                      <TagInput
                    label="Keywords & Themes"
                    tags={values.keywords_and_themes}
                    onChange={(value) => setFieldValue('keywords_and_themes', value)}
                  />
                     <ErrorMessage
                    name="keywords_and_themes"
                    component="div"
                    className="error mt-2"
                  />
                  
                </div>
                <ButtonSection
                  btn_1="Save and Continue"
                  btn_2="Back to previous step"
                  handleNext={handleClick}
                  handleBack={() => handleBack(values)}
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default BusinessSummary;