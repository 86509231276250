import React, { useCallback, useEffect, useState } from 'react';
import ImageView from 'Components/Image/ImageView';
import OptionsDropDown from 'Components/OptionsDropDown/OptionsDropDown';
import { getNameInitials } from 'helpers';
import { error as renderError } from 'helpers/toaster.js';
import { FaEllipsisV } from 'react-icons/fa';
import { FiCalendar } from 'react-icons/fi';
import Styles from './FileCard.module.scss';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import Badge from 'Components/Badge/Badge';
import Star from 'Components/Star/Star';
import useDeleteStarredDocument from 'hooks/documents/useDeleteStarredDocument';
import useStarDocument from 'hooks/documents/useStarDocument';
import moment from 'moment';
import { CONTENT_TYPE_ICONS } from 'constants/contentTypes';
import { BsThreeDotsVertical } from 'react-icons/bs';

const FileCard = ({ document, isTrash, fetchFolders, projects }) => {
  const { id, title, created_at, starred, template_id, status, task, user } =
    document;

  const [star, setStar] = useState(starred);
  const [starDisplay, setStarDisplay] = useState(false);
  const [collaborators, setCollaborators] = useState([]);

  const { mutateAsync: addStar } = useStarDocument();
  const { mutateAsync: removeStar } = useDeleteStarredDocument();

  const navigate = useNavigate();

  const collabs = [
    { name: user.name, avatar: user.mini_avatar },
    ...document.collaborators?.map((c) => ({
      avatar: c.avatar,
      name: c.name,
    })),
  ];

  const fetchCollaborators = useCallback(async () => {
    try {
      const { shared_documents } = document.document_collaborators;

      setCollaborators(shared_documents || []);
    } catch (error) {
      renderError(error);
    }
  }, []);

  const handleStar = async () => {
    if (Object.keys(star || {}).length) {
      await removeStar(star.id, {
        onSuccess: () => {
          setStar(null);
        },
      });
    } else {
      await addStar(id, {
        onSuccess: (data) => {
          if (!data?.data?.starred_document) return;
          setStar(data?.data?.starred_document);
        },
      });
    }

    await fetchFolders(false);
  };

  const handleDocument = (documentId, templateId) => {
    if (isTrash) return;

    if (documentId) {
      const targetRoute = templateId
        ? ROUTES.EditorDocumentTemplate(documentId, templateId)
        : ROUTES.FreestyleDocumentTemplate(documentId);

      navigate(targetRoute);
    }
  };

  useEffect(() => {
    fetchCollaborators();
  }, []);

  useEffect(() => {
    setStar(starred);
  }, [starred]);

  return (
    <div
      onMouseEnter={() => setStarDisplay(true)}
      onMouseLeave={() => {
        if (!star && setStarDisplay(false));
      }}
      className={Styles.FileCard}
    >
      {/* {!isTrash && (
        <Star
          isStarred={star}
          shouldDisplay={starDisplay}
          position="absolute"
          onClick={handleStar}
        />
      )}
        <Badge status={status} /> */}

      <div className={Styles.card__header}>
        <div>
          {task &&
            task.content_type &&
            (task.content_type === 'other'
              ? CONTENT_TYPE_ICONS.other
              : CONTENT_TYPE_ICONS[task.content_type])}
        </div>
        <div className="d-flex align-items-center gap-3">
          <div
            className={`${Styles.card__header__badge} ${status === 'in_progress' ? Styles.inprogress : status === 'not_started' ? Styles.not_started : status === 'in_review' ? Styles.in_review : status === 'approved' ? Styles.completed : status === 'published' ? Styles.published : ''}`}
          >
            {status.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())}
          </div>
          <Star
            isStarred={star}
            shouldDisplay={starDisplay}
            position={starDisplay ? 'flex' : 'absolute'}
            shouldAlwaysDisplay={!!star}
            onClick={(e) => {
              e.stopPropagation();
              handleStar();
            }}
          />
        </div>
      </div>

      <div
        className={Styles.FileCard_Top}
        onClick={() => handleDocument(id, template_id)}
      >
        <div className={Styles.FileCard_Top_Title}>{title}</div>
        <div className={Styles.FileCard_Bottom_Info}>
          <div className={Styles.FileCard_Bottom_Info_Date}>
            <FiCalendar size={12} color="#667085" />
            <span>{moment(created_at).format('MM/DD/YYYY')}</span>
          </div>
          {/* <div className="d-flex stacked-Images-rounded">
            {collaborators?.map(({ collaborator }, index) => {
              return collaborator.mini_avatar ? (
                <ImageView
                  key={index}
                  class="rounded-avatar-bordered"
                  src={collaborator.mini_avatar}
                />
              ) : (
                <span className="rounded-profile-x-sm" key={index}>
                  {getNameInitials(collaborator.name)}
                </span>
              );
            })}
          </div> */}
        </div>
      </div>
      <div className={Styles.FileCard_Bottom}>
        <div
          className={Styles.TileInfo_AvatarGroup}
          style={collabs.length === 1 ? { background: 'transparent' } : {}}
        >
          <div className="d-flex gap-3 stacked-Images-rounded">
            <div>
              {collabs?.slice(0, 3)?.map(({ id, avatar, name: userName }) => {
                return avatar ? (
                  <ImageView
                    key={id}
                    src={avatar}
                    class="rounded-avatar-bordered"
                  />
                ) : (
                  <div key={id} className={Styles.rounded_profile}>
                    {getNameInitials(userName)}
                  </div>
                );
              })}

              {collabs.length > 3 && (
                <div className={Styles.rounded_profile}>
                  {`+${collabs.length - 3}`}
                </div>
              )}
            </div>

            {collabs.length === 1 && (
              <span className={`${Styles.collaborator_name}`}>
                {collabs[0].name}
              </span>
            )}
          </div>
        </div>
        <div className={Styles.drop_icon}>
          <OptionsDropDown
            OptionsFor={'File'}
            drop="end"
            Icon={<BsThreeDotsVertical color="#182230" size={16} />}
            document={document}
            fetchFolders={fetchFolders}
            isTrash={isTrash}
            isDocument={true}
            chevronRight={true}
            myProjects={projects}
          />
        </div>
      </div>
    </div>
  );
};

export default FileCard;
