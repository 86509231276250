import {
  getRequest,
  deleteRequest,
  postRequest,
  patchRequest,
  putRequest,
} from 'helpers/axios';
import { stringify } from 'qs';

export const fetchTasks = async (
  searchValue,
  type,
  filtersStr = '',
  selectedProject,
  page,
) => {
  const queryParams = {
    type,
    'search[title_or_description_cont]': searchValue,
    ...(selectedProject && { project_id: selectedProject }),
    page,
  };

  const response = await getRequest(
    `tasks?${stringify(queryParams)}&${filtersStr}`,
  );
  return response;
};

export const deleteTask = async (id) => {
  const response = await deleteRequest(`tasks/${id}`);
  return response;
};

export const addTask = async (payload) => {
  const response = await postRequest('tasks', payload);
  return response;
};

export const updateTask = async (id, payload) => {
  const response = await patchRequest(`tasks/${id}`, payload);
  return response;
};

export const updateDashboardConfig = async (payload) => {
  const response = await putRequest('content_planner_configs', payload);
  return response;
};

export const getDashboardConfig = async () => {
  const response = await getRequest('content_planner_configs');
  return response;
};
