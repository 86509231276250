import React, { useState } from 'react';

import Loader from 'Components/Loader/Loader';
import Editor from 'Containers/Editor';

function FreeStyleEditor() {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div>
      <Editor
        freeStyle={true}
        setIsLoading={() => {
          isLoading && setIsLoading(false);
        }}
      />

      {isLoading && (
        <Loader
          loaderHeading="Loading Document"
          loaderDescription="Please wait..."
        />
      )}
    </div>
  );
}

export default FreeStyleEditor;
