import React, { useState } from 'react';
import Styles from './Tabs.module.scss';
import { ReactComponent as UrlIcon } from '../../../assets/svg/url.svg';
import { ReactComponent as Upload } from '../../../assets/svg/upload.svg';
import { ReactComponent as Text } from '../../../assets/svg/text.svg';
import UrlForm from './UrlForm';
import TextForm from './TextForm';
import UploadForm from './UploadForm';
import BrandSummary from './BrandSummary';
import { useSelector } from 'react-redux';

function Tab({ text, description, icon, onClick, isActive, type, height }) {
  return (
    <div
      className={`${Styles.tab} ${Styles[type]} ${isActive ? Styles.active : ''}`}
      style={height ? { height: 'auto' } : {}}
      onClick={onClick}
    >
      <div>{icon}</div>
      <div className={Styles.heading}>{text}</div>
      <div className={Styles.description}>{description}</div>
    </div>
  );
}

const Tabs = ({ setBrandSummary, height, noButtons, setLoading, setUrl, setDescription, activeTab, setActiveTab, setCancelClicked }) => {
  const { user } = useSelector((state) => state.auth);
  const brandCount = user?.user_brands?.brands_count;

  const handleShowBrands = ()=>{
    setCancelClicked(true)
    setBrandSummary(true);
  }

  return (
    <>
      <div className={Styles.tabs_container}>
        <Tab
          text="Create New Brand From URL"
          description="Vocable will scan a site"
          icon={<UrlIcon />}
          onClick={() => setActiveTab(1)}
          isActive={activeTab === 1}
          type="url"
          height={height}
        />
        {brandCount > 0 && <Tab
          text="View/Edit My Brand"
          description="View and manage your brand"
          icon={<Upload />}
          onClick={handleShowBrands}
          isActive={activeTab === 2}
          type="file"
          height={height}
        />}
        <Tab
          text="Create New Brand from Text"
          description="Write or copy and paste text"
          icon={<Text />}
          onClick={() => setActiveTab(3)}
          isActive={activeTab === 3}
          type="text"
          height={height}
        />
      </div>

  

      {activeTab === 1 && (
        <UrlForm setBrandSummary={setBrandSummary} noButtons={noButtons} setLoading={setLoading} setUrl={setUrl} setActiveTab={setActiveTab} setCancelClicked={setCancelClicked}  />
      )}
      {activeTab === 2 && <UploadForm noButtons={noButtons} />}
      {activeTab === 3 && <TextForm noButtons={noButtons} setDescription={setDescription} setBrandSummary={setBrandSummary} setActiveTab={setActiveTab} setCancelClicked={setCancelClicked} />}
    </>
  );
};

export default Tabs;
