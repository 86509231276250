import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import Toast from 'react-bootstrap/Toast';
import { TbChevronRight } from 'react-icons/tb';

import { PersonalViewSelectStyles } from 'constants';
import Styles from './ContentPlanner.module.scss';
import useProjects from 'hooks/projects/useProjects';

const ProjectSelect = ({ setSelectedProject: setSelectedProjectParent }) => {
  const [showExportFormats, setShowExportFormats] = useState(false);
  const [selectedProject, setSelectedProject] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { data: projectPages } = useProjects({ searchTerm: '' });

  const projects = useMemo(() => {
    return projectPages?.pages.flatMap((page) => page?.data?.projects);
  }, [projectPages?.pages]);

  const selectedTeamInfo = projects?.find(
    (project) => project.id === selectedProject,
  );

  const VIEW_OPTIONS = [
    {
      value: 'Personal view',
      label: (
        <div className={'label__View ' + Styles.SelectLabel_View}>
          <p>General View</p>
        </div>
      ),
    },
    {
      value: 'Campaign View',
      label: (
        <div
          onMouseEnter={() => isMenuOpen && setShowExportFormats(true)}
          onMouseLeave={() => setShowExportFormats(false)}
          className={
            ' d-flex align-items-center justify-content-between label__View ' +
            Styles.SelectLabel_View
          }
        >
          <p>
            <span className="team_name">Campaign View</span>{' '}
            <span className="team_value">
              {selectedTeamInfo?.name
                ? `${selectedTeamInfo?.name.substring(0, 15)} ${
                    selectedTeamInfo?.name.length < 16 ? '' : '...'
                  }`
                : ''}
            </span>
          </p>
          <span style={{ paddingRight: 10 }}>
            <TbChevronRight size={14} color="#667085" />
          </span>
        </div>
      ),
    },
  ];
  return (
    <section className="position-relative d-flex g-10">
      <Select
        isSearchable={false}
        className={'Personal-View-Select'}
        classNamePrefix={'Personal-View-Select'}
        styles={PersonalViewSelectStyles}
        placeholder="Personal View"
        onChange={(value) => {
          setShowExportFormats(false);
          if (value.value === 'Personal view') {
            setSelectedProject('');
            setSelectedProjectParent('');
          }
        }}
        options={VIEW_OPTIONS}
        value={selectedProject ? VIEW_OPTIONS[1] : VIEW_OPTIONS[0]}
        defaultValue={VIEW_OPTIONS[0]}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
      />
      {showExportFormats && (
        <Toast
          className={Styles.Toast}
          show={showExportFormats}
          onClose={() => setShowExportFormats(false)}
          onMouseLeave={() => setShowExportFormats(false)}
          onMouseEnter={() => setShowExportFormats(true)}
          style={{ right: '175px' }}
        >
          <Toast.Body className={Styles.Toast__Body}>
            <ul className={Styles.list}>
              {projects?.map((project) => (
                <li
                  key={project.id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setShowExportFormats(false);
                    setSelectedProject(project.id);
                    setSelectedProjectParent(project.id);
                  }}
                >
                  {project.name}
                </li>
              ))}
            </ul>
          </Toast.Body>
        </Toast>
      )}
    </section>
  );
};

export default ProjectSelect;
