export const BRIEF_GOALS = [
  'Brand Awareness',
  'Lead Generation',
  'Engagement',
  'Education & Information',
  'Sales & Conversion',
].map((value) => {
  return {
    value,
    label: value,
  };
});
