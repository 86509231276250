import React from "react";
import { Modal } from "react-bootstrap";

function FreePlanModal({ show, onHide, onSubmit }) {
  return (
    <Modal
      className="modalPlanConfirmation"
      dialogClassName="modalWidth__400"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Body className="p-4 pb-0 modalBody">
        <div className="modal__iconsWrapper">
          <span className="confrimIcon">
            <img
              className=""
              src="/Images/checkModalCircleIcon.svg"
              alt="checkIcon"
            />
          </span>

          <span className="closeIcon" onClick={onHide}>
            <img src="/Images/closeModalIcon.svg" alt="closeIcon" />
          </span>
        </div>
        <div className="headingWrapper">
          <div className="heading">Are you sure?</div>
          <span className="desc">
            Are you sure you want to subscribe to free plan?
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer className="modalFooter">
        <button
          className="modalBtn grayOutlinedBtn"
          variant="secondary"
          onClick={onHide}
        >
          Cancel
        </button>
        <button
          className="modalBtn blackBtn"
          variant="primary"
          onClick={() => onSubmit()}
        >
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default FreePlanModal;
