import { styled } from 'styles/stitches/stitches.config';

const Text = styled('span', {
  fontFamily: '$body',
  color: '$primaryBase',
  fontSize: '$16',
  lineHeight: '$24',
  fontWeight: '$regular',
  height: 'fit-content',

  variants: {
    heading: {
      1: {
        fontSize: '$64',
        lineHeight: '$72',
        fontWeight: '$semiBold',
      },
    },
    size: {
      '4xl': {
        fontSize: '$48',
        lineHeight: '$60',
      },
      '3xl': {
        fontSize: '$32',
        lineHeight: '$40',
      },
      '2xl': {
        fontSize: '$28',
        lineHeight: '$36',
      },
      xl: {
        fontSize: '$24',
        lineHeight: '$32',
      },
      lg: {
        fontSize: '$20',
        lineHeight: '$28',
      },
      md: {
        fontSize: '$16',
        lineHeight: '$24',
      },
      sm: {
        fontSize: '$14',
        lineHeight: '$20',
      },
      xs: {
        fontSize: '$12',
        lineHeight: '$16',
      },
    },
    weight: {
      regular: {
        fontWeight: '$regular',
      },
      medium: {
        fontWeight: '$medium',
      },
      semiBold: {
        fontWeight: '$semiBold',
      },
      bold: {
        fontWeight: '$bold',
      },
      extraBold: {
        fontWeight: '$extraBold',
      },
      f_600: {
        fontWeight: '600',
      },
    },
    transform: {
      uppercase: {
        textTransform: 'uppercase',
      },
    },
    spacing: {
      2: {
        letterSpacing: '$2',
      },
    },
  },
  // Default Variants
});

export default Text;
