import { CONFIRM_SUBSCRIPTION, BILLING_PAYMENT } from 'constants';
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import sc from './Subscription.module.scss';
import SubscriptionCardModal from './SubscriptionCardModal';
import PricingCard from 'Components/Pages/PricingPlans/PricingCard';
import { sortArrayCustomOrder } from 'helpers';
import {
  cancelSubscription,
  createSubscription,
  startTrial,
  updateSubscription,
} from 'services/subscriptionService';
import { useDispatch } from 'react-redux';
import { setLoader } from 'redux/slices/loader';
import { toast } from 'react-toastify';
import FreePlanModal from './FreePlanModal';
import ConfirmationModal from 'Containers/Modal/ConfirmationModal/ConfirmationModal';
import PaidPlanModal from './PaidPlanModal';
import { error as renderError } from 'helpers/toaster.js';
import { CouponModal } from './CouponModal';
import FooterCard from 'Components/Pages/PricingPlans/FooterCard';
import { setCurrentStep } from 'redux/slices/newOnboarding';

const SubscriptionCard = ({
  plan = null,
  currentSubscription,
  refetch,
  lastSubscription,
  hasPaymentMethod,
  closeDrawer,
  setProductData,
  newUser,
  fullHeight,
  setTrial,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [CouponModalOpen, setCouponModalOpen] = useState(false);
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  const [renewModalShow, setRenewModalShow] = useState(false);
  const [isFreeModalOpen, setIsFreeModalOpen] = useState(false);
  const [product, setProduct] = useState(null);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [paidModalOpen, setPaidModalOpen] = useState(false);
  const [trialMode, setTrialMode] = useState(false);
  const [redirectToDashboardOnSuccess, setRedirectToDashboardOnSuccess] =
    useState(false);

  useEffect(() => {
    if (redirectToDashboardOnSuccess) {
      navigate('/');
    }
  }, [redirectToDashboardOnSuccess]);

  const handleConfirmationCloseModal = () => setConfirmationModalShow(false);
  const handleConfirmationShowModal = () => setConfirmationModalShow(true);

  const handleRenewCloseModal = () => setRenewModalShow(false);
  const handleRenewShowModal = () => setRenewModalShow(true);

  const handleTrial = async (product) => {
    setTrialMode(true);
    handleRenewShowModal();
    // setProductData(product)

  };

  const handleFirstTrial = async (product) => {
    setTrialMode(true);
    // handleRenewShowModal();
    // setTrial(true)
    // dispatch(setCurrentStep(5))
    navigate(`/payment?trial=${true}`, {state : {subscriptionId: product?.id }});
    closeDrawer && closeDrawer();
    // closeDrawer()

  };

  const initiateTrial = async (product) => {
    const { id, type, amount, interval } = product || {};
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Starting Trial!',
          description: 'Please wait...',
        }),
      );
      await startTrial(id);
      refetch(setRedirectToDashboardOnSuccess);
      dispatch(setLoader({ isLoading: false }));
      toast.success('Free Trial Started Successfully');
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
      renderError(error);
    }
  };

  const { stripe_price, payment_method } = currentSubscription || {};
  const { id: currentSubscriptionId } = stripe_price || {};

  const paymentMethod = payment_method || lastSubscription?.payment_method;

  const handleFreePlan = async (priceId) => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Subscribing Plan!',
          description: 'Please wait...',
        }),
      );

      await createSubscription(priceId);

      toast.success('Free Plan Subscribed Successfully');
      setIsFreeModalOpen(false);
      await refetch();
      dispatch(setLoader({ isLoading: false }));
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
      renderError(error);
    }
  };

  const handleCancelSubscription = async (priceId) => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Unsubscribing Plan!',
          description: 'Please wait...',
        }),
      );
      await cancelSubscription();

      toast.success('Your Subscription Was Cancelled');
      await refetch();
      dispatch(setLoader({ isLoading: false }));
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
      renderError(error);
    }
  };

  const handleUpdateSubscription = async (
    product,
    couponName = '',
    discountedPrice = '',
  ) => {
    const { id, type, amount, interval } = product || {};
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Subscribing Plan!',
          description: 'Please wait...',
        }),
      );

      await updateSubscription(id, couponName, discountedPrice);

      toast.success('Plan Subscribed Successfully');

      refetch();

      setPaidModalOpen(false);
      dispatch(setLoader({ isLoading: false }));
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
      renderError(error);
    }
  };

  const redirectToPayment = (id) => {
    navigate(`/${BILLING_PAYMENT}`, {state : {subscriptionId: id }});
    closeDrawer && closeDrawer();
  };

  const planName =
    currentSubscription?.plan_name?.toLowerCase() ||
    lastSubscription?.plan_name?.toLowerCase();
  const isExpired = !currentSubscription && lastSubscription;

  return (
    <>
      <div className="d-flex">
        {plan &&
          sortArrayCustomOrder(plan, [
            'Free Plan',
            'Creator Plan',
            'Business Plan',
          ]).map((el) => {
            return (
              <PricingCard
                key={el.id}
                type={el.type.replace(/\s/g, '').toLowerCase()}
                name={el.type}
                amount={Number(el.old_amount)}
                discount={Number(el.amount)}
                duration={el.interval === 'month' ? 'month' : 'year'}
                services={el.services || el?.additional_info?.headings?.services}
                fullHeight={fullHeight}
              />
            );
          })}
      </div>
      <div className={`${sc.footer_container} ${newUser && sc.footer_container_new_user}`}>
        {plan &&
          sortArrayCustomOrder(plan, [
            'Free Plan',
            'Creator Plan',
            'Business Plan',
          ]).map((el) => {
            const isTrial = currentSubscription?.status === 'trial';
            const isFreePlanDisabled =
              el.type === 'Free Plan'
                ? ['Creator Plan', 'Business Plan'].includes(planName || '')
                : el.type === 'Creator Plan'
                  ? planName === 'Business Plan'
                  : false;

            return (
              <FooterCard key={el.id} type={el.type} subId={el.id} newUser={newUser}>
                {currentSubscription ? (
                  <button
                    onClick={() => {
                      setProduct(el);
                      // setProductData(el)
                      setProductData && setProductData(el)

                      if (
                        currentSubscriptionId === el.id &&
                        !isTrial &&
                        currentSubscription?.status !== 'canceled'
                      ) {
                        setCancelModalOpen(true);
                        return;
                      }
                      if (
                        currentSubscriptionId === el.id &&
                        isTrial &&
                        currentSubscription?.status !== 'canceled'
                      ) {
                        setCancelModalOpen(true);
                        return;
                      }

                      if (hasPaymentMethod) {
                        redirectToPayment(el.id);
                        return;
                      }

                      if (el.type === 'Free Plan') {
                        handleFreePlan(el.id);

                        return;
                      }

                      if (currentSubscription?.status === 'free') {
                        handleTrial(el);
                        return;
                      }
                      handleRenewShowModal();
                    }}
                    // className={
                    //   !isTrial
                    //     ? currentSubscriptionId === el.id
                    //       ? currentSubscription?.status === 'canceled'
                    //         ? sc.pro_btn
                    //         : sc.basic_btn
                    //       : sc.pro_btn
                    //     : sc.pro_btn
                    // }
                    className={
                      currentSubscriptionId === el.id
                        ? currentSubscription?.status === 'canceled'
                          ? sc.pro_btn
                          : sc.basic_btn
                        : sc.pro_btn
                    }
                    disabled={isFreePlanDisabled || el.type === 'Business Plan'}
                  >
                    {el.type === 'Business Plan'
                      ? 'Coming Soon!'
                      : !isTrial
                        ? currentSubscriptionId === el.id
                          ? currentSubscription?.status === 'canceled' &&
                            currentSubscription?.active
                            ? 'Resubscribe'
                            : 'Cancel Subscription'
                          : el.type === 'Free Plan'
                            ? 'Start Plan'
                            : [
                                  'basic plan',
                                  'pro plan',
                                  'business plan',
                                ].includes(planName || '')
                              ? 'Select This Plan'
                              : 'Start Free Trial'
                        : el.type === 'Free Plan'
                          ? 'Start Plan'
                          : currentSubscriptionId === el.id && isTrial
                            ? 'Cancel Trial'
                            : 'Select This Plan'}
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setProduct(el);
                      // setProductData(el)
                      setProductData && setProductData(el)

                      if(location.pathname==="/subscription-plan"){
                        navigate('/log-in');
                        return;
                      }

                      if (
                        !currentSubscription &&
                        lastSubscription?.plan_name === 'Free Plan'
                      ) {
                        if (el.type !== 'Free Plan') {
                          handleTrial(el);
                          return;
                        }
                      }

                      if (isExpired) {
                        if (el.type === 'Free Plan') {
                          handleFreePlan(el.id);
                          return;
                        }

                        //if no current and last is over due then do same as handle first trial it should open the drawer and change the plan only and then pay and test if it is subscribing or not

                        //this is working fine if i have to chnage plan and pay
                        // what if i dont chnage plan and try to pay which condition will run ... it should pay and move forward 

                        if(!currentSubscription &&
                          lastSubscription ){
                            navigate(`/payment`, {state : {subscriptionId: el?.id, status: lastSubscription?.status }});
                            closeDrawer && closeDrawer();
                            return;
                          }

                        if (paymentMethod) {
                          handleUpdateSubscription(el);
                          return;
                        }
                        handleRenewShowModal();
                        return;
                      }

                      if (el.type === 'Free Plan') {
                        handleFreePlan(el.id);
                        return;
                      }
                      handleFirstTrial(el);
                    }}
                    className={sc.pro_btn}
                    disabled={isFreePlanDisabled || el.type === 'Business Plan'}
                  >
                    {el.type === 'Business Plan'
                      ? 'Coming Soon'
                      : el.type === 'Free Plan'
                        ? 'Start Plan'
                        : !currentSubscription &&
                            lastSubscription?.plan_name === 'Free Plan'
                          ? 'Start Free Trial'
                          : isExpired
                            ? 'Upgrade Plan'
                            : 'Start Free Trial'}
                  </button>
                )}
              </FooterCard>
            );
          })}
      </div>

      <Modal
        className="modalPlanConfirmation"
        dialogClassName="modalWidth__400"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={confirmationModalShow}
        onHide={handleConfirmationCloseModal}
      >
        <Modal.Body className="p-4 pb-0 modalBody">
          <div className="modal__iconsWrapper">
            <span className="confrimIcon">
              <img
                className=""
                src="/Images/checkModalCircleIcon.svg"
                alt="checkIcon"
              />
            </span>

            <span className="closeIcon" onClick={handleConfirmationCloseModal}>
              <img src="/Images/closeModalIcon.svg" alt="closeIcon" />
            </span>
          </div>
          <div className="headingWrapper">
            <div className="heading">Are you sure?</div>
            <span className="desc">
              Are you sure you want to subscribe for our 7 days free trial?
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalFooter">
          <button
            className="modalBtn grayOutlinedBtn"
            variant="secondary"
            onClick={handleConfirmationCloseModal}
          >
            Cancel
          </button>
          <button
            className="modalBtn blackBtn"
            variant="primary"
            onClick={() => handleTrial()}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>

      {renewModalShow && (
        <SubscriptionCardModal
          onHide={handleRenewCloseModal}
          show={renewModalShow}
          product={product}
          isTrial={trialMode}
          initiateTrial={initiateTrial}
        />
      )}

      {isFreeModalOpen && (
        <FreePlanModal
          onHide={() => setIsFreeModalOpen(false)}
          show={isFreeModalOpen}
          onSubmit={() => handleFreePlan()}
        />
      )}

      {paidModalOpen && (
        <PaidPlanModal
          onHide={() => setPaidModalOpen(false)}
          show={paidModalOpen}
          onSubmit={() => handleUpdateSubscription()}
          planName={product?.type}
        />
      )}

      <CouponModal
        isOpen={CouponModalOpen}
        onClose={() => setCouponModalOpen(false)}
        product={product}
        handleUpdateSubscription={handleUpdateSubscription}
      />

      <ConfirmationModal
        show={cancelModalOpen}
        onHide={() => setCancelModalOpen(false)}
        onDelete={handleCancelSubscription}
        modalHeading="Cancel Subscription"
        modalDescription="Are you sure you want to cancel your subscription? This action cannot be
        undone."
        actionText="Proceed"
        loaderHeading="Unsubscribing!"
      />
    </>
  );
};

export default SubscriptionCard;
