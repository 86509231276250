import React, { forwardRef } from 'react';
import { Popover as BootstrapPopover } from 'react-bootstrap';
import Styles from './Popover.module.scss';

const Popover = forwardRef(({ id, children, ...props }, ref) => {
  return (
    <BootstrapPopover
      id={id}
      ref={ref}
      {...props}
      arrowProps={{ style: { display: 'none' } }}
      className={Styles.Popover}
    >
      <BootstrapPopover.Body className={Styles.PopoverContainer}>
        {children}
      </BootstrapPopover.Body>
    </BootstrapPopover>
  );
});

Popover.displayName = 'Popover';

export default Popover;
