import { useQuery } from '@tanstack/react-query';
import keys from 'constants/queryKeys';
import { getTasksByDuration } from 'services/taskService';

const useOverdueCountBriefs = (duration) => {
  const query = useQuery({
    queryKey: keys.OverdueCountBriefs(duration),
    queryFn: () => getTasksByDuration(duration),
  });

  return {
    ...query,
    ...query?.data?.data,
  };
};

export default useOverdueCountBriefs;
