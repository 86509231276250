import useTemplates from './useTemplates';
import useCategories from './useCategories';
import { useMemo } from 'react';

const useCategoriesWithTemplates = (
  searchValue = '',
  hasAllTemplates = false,
) => {
  const { templates, isLoading: isLoadingTemplates } = useTemplates();
  const { categories, isLoading: isLoadingCategories } = useCategories();

  const isLoading = isLoadingTemplates || isLoadingCategories;

  const templatesGroupedByCategories = useMemo(
    () =>
      templates?.reduce((acc, template) => {
        const categoryName = template.category_name;

        acc[categoryName] = acc[categoryName] || [];

        acc[categoryName].push(template);
        return acc;
      }, {}),
    [templates],
  );

  const categoriesWithTemplates = useMemo(() => {
    const auxCategories =
      categories?.map((category) => {
        return {
          ...category,
          templates: templatesGroupedByCategories?.[category.title],
        };
      }) || [];

    const allTemplates = [
      {
        id: 0,
        templates,
        title: 'All',
      },
      ...auxCategories,
    ].map((category) => {
      const filteredCategory = { ...category };
      const filteredTemplates = filteredCategory.templates?.filter((template) =>
        template.title.toLowerCase().includes(searchValue.toLowerCase()),
      );
      filteredCategory.templates = filteredTemplates;

      return filteredCategory;
    });

    return hasAllTemplates ? allTemplates : auxCategories;
  }, [categories, templatesGroupedByCategories, searchValue]);

  return { isLoading, categoriesWithTemplates, templates };
};

export default useCategoriesWithTemplates;
