import React from 'react';
import styleClass from './Error.module.scss';
function PasswordValidationError() {
  return (
    <div className={styleClass.error_message}>
      <span>
        * Password must contain at least 8 characters, one uppercase letter, one
        lowercase letter, one number and one symbol
      </span>
    </div>
  );
}

export default PasswordValidationError;
