import React from 'react';

export default [
  {
    title: 'Name',
    id: 'name',
  },
  {
    title: 'Content Type',
    id: 'totalBriefs',
  },
  {
    title: 'Timeline',
    id: 'totalDocuments',
  },
  {
    title: 'Content Goal',
    id: 'collaborators',
  },
  {
    title: 'Collaborators',
    id: 'collaborators',
  },
  {
    title: '',
    icon: '',
    id: '',
  },
];
