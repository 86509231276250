import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
// import pdf2html from "pdf2html";
import { LINK_REGEX } from 'constants';
import { error as renderError } from 'helpers/toaster.js';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'redux/slices/loader';
import { extractContent } from 'services/documentService';
import StyleClasses from './ImportDraftModal.module.scss';
import ModalBodyContent from './ModalBodyContent';
import ProgressBarContent from './ProgressBarContent';
import { useNavigate, useParams } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import { ROUTES } from 'constants/routes';
import useCreateDocument from 'hooks/documents/useCreateDocument';

function ImportDraftModal(props) {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [link, setLink] = useState('');
  const [content, setContent] = useState('');
  const [startUploading, setStartUploading] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);

  const { user } = useSelector((state) => state.auth);
  const { id: userId } = user || {};

  const { mutateAsync: createDocument } = useCreateDocument({ userId });

  const { projectId } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const fileUploadingHandler = () => {
    fileUploading && setCurrentProgress(0);
    if (fileUploading === false) {
      setStartUploading(true);
    }
    setFileUploading(!fileUploading);
    setIsFilePicked(!isFilePicked);
  };

  const changeHandler = async (event) => {
    setSelectedFile(event.target.files[0]);
    fileUploadingHandler();
    setLink('');
    setContent('');
  };

  useEffect(() => {
    let interval;
    if (startUploading) {
      if (currentProgress < 100) {
        interval = setInterval(() => {
          setCurrentProgress((preValue) => preValue + 10);
        }, 200);
      } else {
        setStartUploading(false);
        clearInterval(interval);
      }
    }

    return () => clearInterval(interval);
  }, [currentProgress, startUploading]);

  const isValidLink = link ? !!link.match(LINK_REGEX) : true;

  const handleDocument = async (content) => {
    await createDocument(
      {
        document: {
          title: 'Untitled document',
          user_id: userId,
          content,
        },
        taskId: props.taskId,
        projectId,
        folderId: props.folderId,
      },
      {
        onSuccess: (documentResponse) => {
          const documentId = documentResponse?.data?.document?.id;
          props.onHide();
          if (!documentId) return;

          navigate(ROUTES.FreestyleDocumentTemplate(documentId));
        },
      },
    );
  };

  const handleImport = async () => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Creating Document',
        description: 'Please wait...',
      }),
    );
    try {
      if (content) {
        const transformedContent = content.replace(/\\n/g, '<br>');
        handleDocument(transformedContent);
      } else if (link) {
        const formData = new FormData();
        formData.append('link', link);
        const linkContent = await extractContent(formData);
        const { data } = linkContent || {};
        const { data: contentData } = data || {};
        const transformedContent = contentData.replace(/\n/g, '<br>');
        handleDocument(transformedContent);
      } else if (isFilePicked) {
        const formData = new FormData();
        formData.append('file', selectedFile);
        const fileContent = await extractContent(formData);
        const { data } = fileContent || {};
        const { data: contentData } = data || {};
        const transformedContent = contentData.replace(/\n/g, '<br>');
        handleDocument(transformedContent);
      }

      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
      // props.onHide();
    } catch (error) {
      renderError(error?.response?.data?.message || 'Invalid File');
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    }
  };

  const isDisabled = useMemo(() => {
    if (link) {
      return !isValidLink;
    }

    if (isFilePicked) {
      return currentProgress !== 100;
    }

    return !content.trim();
  }, [link, isValidLink, content, currentProgress, isFilePicked]);

  return (
    <div>
      <Modal
        {...props}
        size={680}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ zIndex: 100000 }}
      >
        <Modal.Header className="border-0 pb-0" closeButton>
          <Modal.Title className={StyleClasses.Modal__Heading}>
            Import a draft
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={'d-flex flex-column p-4 ' + StyleClasses.Modal__Body}
        >
          {!fileUploading ? (
            <ModalBodyContent
              changeHandler={changeHandler}
              content={content}
              isValidLink={isValidLink}
              link={link}
              setContent={setContent}
              setLink={setLink}
            />
          ) : (
            <ProgressBar
              className={StyleClasses.file_UploadStatusWrapper}
              now={currentProgress}
              label={
                <ProgressBarContent
                  currentProgress={currentProgress}
                  fileUploadingHandler={fileUploadingHandler}
                  selectedFile={selectedFile}
                />
              }
              min={0}
              max={100}
              bsPrefix="file-upload-progress"
            />
          )}
          <Button
            variant="dark"
            onClick={handleImport}
            className="w-100 secondary_btn"
            disabled={isDisabled}
          >
            Import in Editor <FiArrowRight size={20} />
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ImportDraftModal;
