import React, { useEffect } from 'react';
import { LoaderContainer, loader } from 'react-global-loader';
import { useSelector } from 'react-redux';
import './Preloader.scss';

const Preloader = () => {
  const { isLoading, loaderHeading, laoderDescription } = useSelector(
    (state) => state.loader,
  );

  useEffect(() => {
    if (isLoading === null) {
      return;
    }
    isLoading ? loader.show() : loader.hide();
  }, [isLoading]);

  return (
    <LoaderContainer>
      <div className=" preloader__wrapper shadow-reset mg-t-30">
        <section className="ts_preloading_box">
          <div id="ts-preloader-absolute14">
            <div className="tsperloader14" id="tsperloader14_one"></div>
            <div className="tsperloader14" id="tsperloader14_two"></div>
            <div className="tsperloader14" id="tsperloader14_three"></div>
          </div>
          <section className="text-center preloader__textSection">
            <h3 className="text-lg-lh30">{loaderHeading}</h3>
            <p className="text-sm-18lh">{laoderDescription}</p>
          </section>
        </section>

        <div className="backdrop-loader"></div>
      </div>
    </LoaderContainer>
  );
};

export default Preloader;
