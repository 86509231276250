import React, { useState } from 'react';
import BSButton from 'Components/Button/BSButton';
import FormikCheckbox from 'Components/Form/FormikCheckbox';
import FormikTextField from 'Components/Form/FormikTextField';
import { useNavigate } from 'react-router-dom';
import ImageView from 'Components/Image/ImageView';
import { EMAIL_REGEX, SUBSCRIBE_PLAN } from 'constants';
import { ErrorMessage, Formik } from 'formik';
import { getNameInitials } from 'helpers';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/slices/auth';
import { setLoader } from 'redux/slices/loader';
import userService from 'services/userService';
import * as Yup from 'yup';
import ProfileFormStyles from './ProfileForm.module.scss';
import SubscriptionHeader from 'Components/SubscriptionHeader/SubscriptionHeader';

const ProfileForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [selectedImage, setSelectedImage] = useState(user.avatar);
  const [ImageFile, setImageFile] = useState(null);
  const [imageChanged, setImageChanged] = useState(false);
  const [showChangeProfile, setShowChangeProfile] = useState(false);

  const initialValues = {
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    is_email_notification_enable: user.is_email_notification_enable,
    role: user.role ?? '',
    company: user.company ?? '',
  };

  const generalTabValidationSchema = Yup.object({
    firstName: Yup.string().trim().required('First name is required.'),
    lastName: Yup.string().trim().required('Last name is required.'),
    email: Yup.string()
      .matches(EMAIL_REGEX, 'Invalid Email')
      .required('Email is required.'),
    is_email_notification_enable: Yup.boolean(),
    role: Yup.string().trim().required('Role is required.'),
    company: Yup.string().trim().required('Company information is required.'),
  });

  const handleSubmit = async (values) => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Saving!',
        description: 'Please wait...',
      }),
    );
    let body = {};

    const {
      firstName,
      lastName,
      email,
      is_email_notification_enable,
      role,
      company,
    } = values;
    body = {
      user: {
        email,
        first_name: firstName,
        last_name: lastName,
        is_email_notification_enable,
        role,
        company,
        avatar: imageChanged ? ImageFile : {},
      },
    };

    try {
      const response = await userService.updateProfile(body);
      dispatch(setLoader({ isLoading: false }));
      const { user } = response?.data || {};
      dispatch(actions.updateUser({ user }));
      renderSuccess(response?.data?.message);
      navigate(`/${SUBSCRIBE_PLAN}`);
    } catch (error) {
      renderError(error);
      dispatch(setLoader({ isLoading: false }));
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSelectedImage(reader.result);
      setImageChanged(true);
      setImageFile(file);
    };
  };

  return (
    <div>
      <div style={{ marginBottom: 30 }}>
        <SubscriptionHeader />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={generalTabValidationSchema}
        validateOnMount={true}
        enableReinitialize={true}
      >
        {({ values, isValid, setErrors, errors, dirty, resetForm }) => {
          return (
            <section
              className={ProfileFormStyles.innerModalSection}
              style={{ margin: 'auto' }}
            >
              <Modal.Body className={ProfileFormStyles.ProfileModalBody}>
                <div className={'Modal-Title  ' + ProfileFormStyles.ModalTitle}>
                  My Profile
                </div>
                <section
                  className={
                    'd-flex flex-column ' + ProfileFormStyles.tabFlexWrapper
                  }
                >
                  <Form
                    className={
                      'd-flex flex-column ' + ProfileFormStyles.tabFlexWrapper
                    }
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <div
                      onMouseEnter={() => {
                        setShowChangeProfile(true);
                      }}
                      onMouseLeave={() => {
                        setShowChangeProfile(false);
                      }}
                      className={
                        'd-flex position-relative ' +
                        ProfileFormStyles.ProfileImageSection
                      }
                    >
                      {selectedImage ? (
                        <ImageView
                          class={'img-fluid  ' + ProfileFormStyles.profileImage}
                          alt="Profile Image"
                          src={selectedImage}
                        />
                      ) : (
                        <span
                          className={
                            'rounded-profile ' + ProfileFormStyles.profileImage
                          }
                        >
                          {getNameInitials(user.name)}
                        </span>
                      )}
                      <input
                        className={ProfileFormStyles.profileImage_Input}
                        type="file"
                        name="myImage"
                        onChange={handleImageChange}
                        accept="image/*"
                      />
                      {showChangeProfile && (
                        <div
                          className={`d-flex justify-content-center align-items-center  ${ProfileFormStyles.profileImage_Input} ${ProfileFormStyles.profileImage_Input_text}`}
                        >
                          Change Profile
                        </div>
                      )}
                    </div>
                    <div>
                      <FormikTextField
                        Label="First Name"
                        name="firstName"
                        placeholder=""
                        classList="w-100 input"
                        isRequired
                      />
                      <ErrorMessage name="firstName">
                        {(msg) => <span className="error">{msg}</span>}
                      </ErrorMessage>
                    </div>
                    <div>
                      <FormikTextField
                        Label="Last Name"
                        name="lastName"
                        placeholder=""
                        classList="w-100 input"
                        isRequired
                      />
                      <ErrorMessage name="lastName">
                        {(msg) => <span className="error">{msg}</span>}
                      </ErrorMessage>
                    </div>

                    <div>
                      <FormikTextField
                        Label="Email"
                        name="email"
                        placeholder=""
                        classList="w-100 input"
                        disabled
                        isRequired
                      />
                      <ErrorMessage name="email">
                        {(msg) => <span className="error">{msg}</span>}
                      </ErrorMessage>
                    </div>

                    <div>
                      <FormikTextField
                        Label="Role"
                        name="role"
                        placeholder=""
                        classList="w-100 input"
                        isRequired
                      />
                      <ErrorMessage name="role">
                        {(msg) => <span className="error">{msg}</span>}
                      </ErrorMessage>
                    </div>

                    <div>
                      <FormikTextField
                        Label="Company Information"
                        name="company"
                        placeholder=""
                        classList="w-100 input"
                        isRequired
                      />
                      <ErrorMessage name="company">
                        {(msg) => <span className="error">{msg}</span>}
                      </ErrorMessage>
                    </div>

                    <FormikCheckbox
                      type="switch"
                      Label="Enable Email Notifications"
                      name="is_email_notification_enable"
                    ></FormikCheckbox>
                    <div>
                      <BSButton
                        onHide={props.onHide}
                        ButtonText="Next"
                        variant="dark"
                        classList={'btn secondary_btn w-100'}
                        disabled={!isValid}
                        onClick={() => handleSubmit(values)}
                      />
                    </div>
                  </Form>
                </section>
              </Modal.Body>
            </section>
          );
        }}
      </Formik>
    </div>
  );
};

export default ProfileForm;
