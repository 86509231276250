import React from 'react';
import Profile from 'Components/Profile/Profile';
import HeaderStyles from './Header.module.scss';
import ImageView from 'Components/Image/ImageView';
import { useNavigate } from 'react-router-dom';

const OnboardingHeader = () => {
  const navigate = useNavigate();

  return (
    <div
      className={
        HeaderStyles.Dashboard_header +
        ' d-flex justify-content-between align-items-center'
      }
    >
      <div style={{ cursor: 'pointer', paddingBottom: '16px' }}>
        <ImageView
          src="/Images/vocable_onboarding.svg"
          alt="Vocable"
          class="img-fluid logo-img"
          onClick={() => navigate('/')}
        />
      </div>
      <div className="d-flex g-10 align-items-center">
        <Profile />
      </div>
    </div>
  );
};

export default OnboardingHeader;
