import React from 'react';
import Styles from './DrawerCard.module.scss';

const DrawerCard = ({icon, text}) => {
  return (
    <div className={Styles.card}>
      <div className={Styles.card__header}>
        {icon}
        <span className={Styles.card__header__heading}>{text}</span>
      </div>
      <div className={Styles.card__button}>
        <button className="cancel_btn">Connect</button>
      </div>
    </div>
  );
};

export default DrawerCard;
