import React from 'react';
import Styles from './Stepper.module.scss';

const Stepper = ({ steps, activeStep, classes }) => {
  return (
    <div className={Styles.steps} style={classes}>
      <div className={Styles.steps__container}>
        {steps.map((step, index) => (
          <div
            key={index}
            className={`${Styles.steps__container__step} ${
              activeStep === index
                ? Styles.active
                : index < activeStep
                  ? Styles.completed
                  : ''
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Stepper;
