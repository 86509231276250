import moment from 'moment';
export const mapTaskAttributes = (task, projectId) => {
  return {
    content_type: task?.content_type ?? '',
    title: task?.title ?? '',
    description: task?.description ?? '',
    content_structure: task?.content_structure ?? '',
    keywords: task?.keywords ?? [],
    word_count: task?.word_count ?? '',
    target_audience: task?.target_audience ?? '',
    deadline_date: task?.deadline_date
      ? moment.utc(task.deadline_date, 'MMMM D, YYYY').toDate()
      : '',
    priority: task?.priority ?? 'medium',
    assignee_id: task?.assignee?.id ?? '',
    state: task?.state ?? 'in_progress',
    project_id: task?.project_id || projectId || '',
    document_id: task?.document_id ?? '',
  };
};
