import React, { useState } from 'react';
import Styles from './LeftNavigation.module.scss';
import { BiLink } from 'react-icons/bi';
import { FiRefreshCw } from 'react-icons/fi';
import { FaArrowRight } from 'react-icons/fa';
import Drawer from 'Components/Drawer/Drawer';
import Tabs from './Tabs';
import BSButton from 'Components/Button/BSButton';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { formatDistanceToNow } from 'date-fns';
import ImageUploadedState from '../Onboarding/ImageUploader/ImageUploadedState';
import { navigationItems } from 'constants';
import { AiOutlineDelete } from 'react-icons/ai';


const LeftNavigation = ({
  test,
  brandName,
  url,
  currentSubscription,
  setBrandSummary,
  brandsData,
  brand,
  setBrand,
  updateTime,
  brandImage,
  setBrandImage,
  setChangeAdd,
  setBrandName,
  setUrl,
  setBrandSummaryData,
  onFormReset,
  setDescription,
  setInitialValues,
  emptyStateSummary,
  showContentGenerateButton,
  description,
  submitForm,
  handleClick,
  hasId,
  handleDeleteBrand,
  setCancelModalOpen
}) => {


  const [activeTab, setActiveTab] = useState(0);
  const [showDrawer, setShowDrawer] = useState(false);

  function transformBrandsData(brandsData) {
    return brandsData?.map((item) => {
      const [key, value] = Object.entries(item)[0];
      if (value === null) {
        return { label: 'Unknown', value: key };
      }
      return { label: value, value: key };
    });
  }

  const handleImageChange = (image) => {
    setBrandImage(image);
  };

  // const handleClick = () => {
  //   // Call the submit form function passed as a prop
  //   submitForm();
  // };

  return (
    <div className={Styles.container}>
      <div className={Styles.container__top}>
        <div className={Styles.container__top__header}>
          <div className={Styles.form1_wrapper__uploaders}>
            <div className={Styles.form1_wrapper__uploaders__left}>
              <div
                className={
                  Styles.form1_wrapper__uploaders__left__image_container
                }
              >
                <ImageUploadedState
                  fieldName="logo"
                  selectedImage={brandImage}
                  onImageChange={handleImageChange}
                />
                <div
                  className={
                    Styles.form1_wrapper__uploaders__left__image_container__text
                  }
                ></div>
              </div>
            </div>
          </div>
          <div className={Styles.container__top__header__user}>
            <p className={Styles.container__top__header__user__name}>
              {brandName}
            </p>
            {updateTime && (
              <div
                className={Styles.container__top__header__user__last_updated}
              >
                <FiRefreshCw color="#475467" size={16} />
                <span>
                  Last updated{' '}
                  {updateTime
                    ? formatDistanceToNow(new Date(updateTime), {
                        addSuffix: true,
                      })
                    : ''}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className={Styles.container__top__badge}>
          {currentSubscription?.plan_name === 'Basic Plan' ? (
            // <div className={Styles.container__top__badge__left}>
            //   <div className={Styles.container__top__badge__left__icon}>
            //     <BiLink size={16} color="#F99806" />
            //   </div>
            //   <span className={Styles.container__top__badge__left__text}>
            //     {url}
            //   </span>
            // </div>
            url && (
              <div className={Styles.container__top__badge__left}>
                <div className={Styles.container__top__badge__left__icon}>
                  <BiLink size={16} color="#F99806" />
                </div>
                <span className={Styles.container__top__badge__left__text}>
                  {url}
                </span>
              </div>
            )
          ) : (
            <Form.Group controlId={brand} style={{ width: '100%' }}>
              <Select
                aria-label="Default select example"
                style={{
                  border: '1px solid #D0D5DD',
                  color: '#667085',
                }}
                value={{ label: brandName, value: brand }}
                onChange={(selectedOption) => {
                  setBrand(selectedOption.value);
                  setDescription('');
                  setUrl('');
                }}
                options={transformBrandsData(brandsData)}
                defaultValue={brand}
                formatOptionLabel={(option) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    {option.label}
                  </div>
                )}
              />
              {/* <ErrorMessage name={name} component="div" className="error mt-2" /> */}
            </Form.Group>
          )}
          <div className={Styles.container__top__badge__right}>
            <button
              onClick={() => {
                setBrandSummary(false);
                setChangeAdd(true);
                setBrand('');
                setBrandName('');
                setUrl('');
                setBrandSummaryData(false);
                setBrandImage(null);
                setDescription('');
                setInitialValues(emptyStateSummary);
              }}
            >
              {/* {currentSubscription?.plan_name === 'Basic Plan'
                ? 'Change'
                : 'Add'} */}
              Create New Brand
            </button>
          </div>
     
        </div>
        {description && !showContentGenerateButton ? (
          <BSButton
            ButtonText={'Generate Topics'}
            classList="secondary_btn w-100"
            // onClick={() =>
            //   generateTopics(values, setFieldValue, validateForm)
            // }
            onClick={() => handleClick('generate')}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <BSButton
              // ButtonText={hasId.state ? 'Edit' : 'Save'}
              ButtonText={'Save'}
              classList="secondary_btn w-100"
              onClick={() => handleClick('save')}
              wrapStyle={{width: "100%"}}
              style={{ flexGrow: 1 }}
            />
            {hasId.state && (
              <button
              type="button"
              className={Styles.delete_button}
              onClick={() => setCancelModalOpen(true)}
              style={{ flexShrink: 0 }}
              >
              <AiOutlineDelete size={20} color="#667085" />
            </button>
            )}
          </div>
        )}
      </div>

      <ul className={Styles.container__navigation}>
        {navigationItems.map((item, index) => (
          <li key={index} onClick={() => setActiveTab(index + 1)}>
            <a
              href={`#${item.id}`}
              onClick={() => test(item.id)}
              className={`${Styles.container__navigation__list} ${activeTab === index + 1 ? Styles.container__navigation__list_active : ''}`}
            >
              <div className={Styles.container__navigation__list__item}>
                <span className={Styles.svg_icon}>{item.icon}</span>
                <span>{item.text}</span>
              </div>
              <div className={Styles.container__navigation__list__arrow}>
                <FaArrowRight color="#344054" size={16} />
              </div>
            </a>
          </li>
        ))}
      </ul>
      <Drawer
        show={showDrawer}
        onHide={() => {
          setShowDrawer(false);
        }}
        title={'Change URL'}
        placement="end"
        width={640}
      >
        <div className="px-3">
          <div className={Styles.drawers_container}>
            <p className={Styles.drawers_container__label}>
              Vocable can infer your voice from your website, text or a
              document. Select one of the methods below to get your brand
              content into Vocable.
            </p>
            {/* <p></p> */}
            <Tabs setBrandSummary={false} height="fit" noButtons={true} />
            <div
              className={`d-flex drawer-footer d-flex justify-content-end gap-2`}
            >
              <BSButton
                classList={`cancel_btn`}
                variant=""
                ButtonText="Cancel"
              />
              <BSButton
                classList={`secondary_btn`}
                variant=""
                ButtonText="Continue"
                type="button"
                // onClick={() => setBrandSummary(true)}
              />
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default LeftNavigation;
