import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';
import { setLoader } from 'redux/slices/loader';
import { useDispatch, useSelector } from 'react-redux';

import BSButton from 'Components/Button/BSButton';
import ImageView from 'Components/Image/ImageView';
import { getNameInitials } from 'helpers';
import { deleteProjectUser } from 'services/projectService';
import { error as errorToast, success as successToast } from 'helpers/toaster';

const EditorRemoveModal = ({ editors, show, onHide }) => {
  const { projectId } = useParams();

  const { user } = useSelector((state) => state.auth);
  const { id: userSelectorId } = user || {};

  const dispatch = useDispatch();

  const handleEditorRemove = async (userId) => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Removing Editor!',
          description: 'Please wait...',
        }),
      );
      await deleteProjectUser(projectId, userId);
      onHide();
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
      successToast('Editor Removed Successfully');
    } catch (error) {
      errorToast(error);
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 1100 }}
    >
      <Modal.Header className="border-0 pb-0" closeButton>
        <Modal.Title className="Modal-Title">Remove Collaborators</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        {editors?.map((editor) => {
          const { avatar, name: userName, id, owner, email } = editor || {};
          return (
            <div
              key={id}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {avatar ? (
                  <ImageView
                    src={avatar}
                    class="rounded-avatar-bordered "
                    style={{ width: 26, height: 26 }}
                  />
                ) : (
                  <span
                    className="rounded-profile"
                    style={{ width: 26, height: 26, fontSize: 12 }}
                  >
                    {getNameInitials(userName)}
                  </span>
                )}
                <div>
                  <p
                    className="text-md-Regular"
                    style={{
                      marginLeft: 10,
                      color: '#101828',
                      fontSize: '16px',
                    }}
                  >
                    {userName}
                  </p>
                  <p
                    className="text-md-Regular"
                    style={{
                      marginLeft: 10,
                      color: '#101828',
                      fontSize: '12px',
                    }}
                  >
                    {email}
                  </p>
                </div>
              </div>
              <BSButton
                classList="button w-100 secondary_btn"
                ButtonText="Remove"
                variant="light"
                onClick={() => handleEditorRemove(id)}
                disabled={owner || userSelectorId === id}
              />
            </div>
          );
        })}
      </Modal.Body>
    </Modal>
  );
};

export default EditorRemoveModal;
