import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FiHelpCircle } from 'react-icons/fi';
import sc from './AITool.module.scss';
import { KeywordResearchStats } from '../../constants/KeywordResearchStats';

const ResearchToolCards = ({
  basicInfo,
  isOnboarding,
  isAiPoweredBrief,
  researchModule,
}) => {
  const renderTooltip = (args) => (
    <Tooltip className="tooltip-white" style={{ zIndex: 2000 }} id={args.id}>
      <span id={args.id} className="w-700">
        <strong>{args.Heading + '  -'}</strong>
      </span>{' '}
      {args.text}
    </Tooltip>
  );

  const getCardClassName = () => {
    if (isOnboarding) return sc.CardSection__Card__Onboarding;
    if (isAiPoweredBrief) return '';
    return sc.CardSection__Card;
  };

  const {
    'Keyword Difficulty Index': keywordDifficulty,
    'Search Volume': searchVolume,
    'Number of Results': numberOfResults,
    CPC,
  } = basicInfo || {};

  const cards = KeywordResearchStats({
    searchVolume,
    keywordDifficulty,
    numberOfResults,
    CPC,
  }).map((stat) => {
    return (
      <div className={getCardClassName()} key={stat.Title}>
        <div
          className={`d-flex gap-3 ${isAiPoweredBrief ? `flex-column` : ``}`}
          id={stat.id}
        >
          <div
            className={`${sc.CardSection__Card_Icon} ${stat?.icon?._source?.lineNumber === 15 ? sc?.search_col : stat?.icon?._source?.lineNumber === 23 ? sc.keyword_diff : stat?.icon?._source?.lineNumber === 31 ? sc.results : stat?.icon?._source?.lineNumber === 40 ? sc.cpc : ''}`}
          >
            {stat.icon} {console.log(stat?.icon?._source?.lineNumber)}
          </div>
          <div className="d-flex flex-column">
            <div
              className={
                'd-flex justify-content-between align-items-baseline gap-2 position-relative'
              }
            >
              <span
                className={
                  isAiPoweredBrief
                    ? sc.CardSection__Card_AiPoweredBriefHeadline
                    : sc.CardSection__Card_Headline
                }
              >
                {stat.Title}
              </span>
              <OverlayTrigger
                id={stat.id}
                placement="top"
                delay={{ show: 100, hide: 100 }}
                overlay={renderTooltip({
                  Heading: stat.TooltipHeading,
                  text: stat.TooltipText,
                  id: stat.id,
                })}
              >
                <section>
                  {<FiHelpCircle color="#98A2B3"></FiHelpCircle>}
                </section>
              </OverlayTrigger>
            </div>
            <span className={`clr-d-grey ${sc.CardSection__Card__Stats}`}>
              {stat.StatsNumbers}
            </span>
          </div>
        </div>
      </div>
    );
  });

  const getCardSectionStyles = () => {
    if (isOnboarding) return 'pt-4';
    if (isAiPoweredBrief) return 'gap-5';
    return `${researchModule && sc.card_container} flex-wrap gap-3 pt-4`;
  };

  return (
    <section className={`d-flex ${getCardSectionStyles()} ${sc.CardSection}`}>
      {cards}
    </section>
  );
};

export default ResearchToolCards;
