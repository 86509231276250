import React from 'react';
import Styles from './DocumentCard.module.scss';
import { Link } from 'react-router-dom';
import pluralize from 'pluralize';

export function DocumentCard({ Icon, to, count, text, polorized }) {
  return (
    <span
      className={`${Styles.Dashboard__Header_Navigation_DocumentsDetails_Status} +
    ${Styles.Dashboard__Header_Navigation_DocumentsDetails_Status_Documents}`}
    >
      <Icon />
      <Link
        to={to}
        className={
          Styles.Dashboard__Header_Navigation_DocumentsDetails_Status_Documents__card
        }
      >
        <strong>{count || 0} </strong>
        <span>
          {pluralize(`${polorized}`, count || 0)} {text}
        </span>
      </Link>
    </span>
  );
}
