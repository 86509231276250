import { Extension } from '@tiptap/core';

export const BeforeParagraphSpacing = Extension.create({
  name: 'beforeParagraphSpacing',

  addOptions() {
    return {
      types: ['heading', 'paragraph'],
      spacing: ['2rem'],
      defaultHeight: '0px',
      before: true,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          beforeParagraphSpacing: {
            default: this.options.defaultHeight,
            parseHTML: (element) =>
              element.style.marginTop || this.options.defaultHeight,
            renderHTML: (attributes) => {
              if (
                attributes.beforeParagraphSpacing === this.options.defaultHeight
              ) {
                return {};
              }

              return {
                style: `${'margin-top'}: ${
                  attributes.beforeParagraphSpacing || '2rem'
                }`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setBeforeParagraphSpacing:
        (height) =>
        ({ commands }) => {
          if (!this.options.spacing.includes(height)) {
            return false;
          }

          return this.options.types.every((type) =>
            commands.updateAttributes(type, {
              beforeParagraphSpacing: height,
            }),
          );
        },

      unsetBeforeParagraphSpacing:
        () =>
        ({ commands }) => {
          return this.options.types.every((type) =>
            commands.resetAttributes(type, 'beforeParagraphSpacing'),
          );
        },
    };
  },
});
