import * as Yup from 'yup';
import moment from 'moment';

export const aiProjectCreateSchema = Yup.object({
  name: Yup.string().trim().required('Name is a required field.'),
  description: Yup.string().trim().required('Description is a required field.'),
  contentType: Yup.array()
    .of(Yup.string())
    .required('Content type is a required field.'),
  surpriseMe: Yup.boolean().optional(),
  startDate: Yup.string().required('Start date is a required field.'),
  endDate: Yup.string()
    .required('End date is a required field.')
    .test(
      'is-after-start-date',
      'End date must be after or the same as start date',
      (value, context) => moment(value).isSameOrAfter(context.parent.startDate),
    ),
  targetAudience: Yup.string().optional(),
  keyMessage: Yup.string().optional(),
});

export const manualProjectCreateSchema = (isEdit) =>
  Yup.object({
    name: Yup.string().trim().required('Name is a required field.'),
    // content_types: Yup.string().required('At least one content type must be selected'),
    campaign_goal: Yup.string().required('Campaign goal value is required'),

    description: Yup.string().trim().required('Description is a required field.'),
   
    target_audience: Yup.string().trim().required('Audience is a required field'),
    key_message: Yup.string().trim(),
    start_date: Yup.string().required('Start date is required field.'),
    content_types: Yup.array().min(1, 'Content Type is required field.'),
    content_no: Yup.number()
    .required('Please select the number of content')
    .min(1, 'The content number must be at least 1')
    .max(30, 'The content number must be at most 30'),
    
    end_date: Yup.string()
      .required('End date is required field.')
      .test(
        'is-after-start-date',
        'End date must be after or the same as start date',
        (value, context) =>
          moment(value).isSameOrAfter(context.parent.start_date),
      ),
  });
