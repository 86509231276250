// import moment from 'moment';
import * as Yup from 'yup';

export const briefCreateSchema = Yup.object({
  content_type: Yup.string().required('Content Type is a required field.'),
  title: Yup.string().trim().required('Title is a required field.'),
  description: Yup.string().trim().required('Description is a required field.'),
  // deadline_date: Yup.string().trim().nullable(true),
  // .test('is-after-start-date', 'Deadline must be after today.', (value) =>
  //   moment(value).isSameOrAfter(moment()),
  // ),
  priority: Yup.string().trim().required('Priority is a required field.'),
  assignee_id: Yup.number(),
  state: Yup.string().trim().required('Status is a required field.'),
});

// AI POWERED BRIEFS
export const foundationSchema = Yup.object({
  content_type: Yup.string().required('Content Type is a required field.'),
  topic: Yup.string().trim().required('Topic is a required field.'),
  target_audience: Yup.string().notRequired(
    'Target Audience is a required field.',
  ),
  goals: Yup.string().required('Goals is a required field.'),
  headline: Yup.string().required('Headline is a required field.'),
});
