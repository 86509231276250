import React from 'react';

import Box from 'ui/Box/Box';
import Flex from 'ui/Flex/Flex';
import Text from 'ui/Text/Text';
import ReviewCard from './ReviewCard';

import { FaQuoteRight } from 'react-icons/fa';

import Styles from './CustomerReview.module.scss';
import MyCarousel from './Testimonials/Carousel';

const CustomerReview = () => {
  const testimonial = [
    {
      text: "I just want to say I am so grateful that I found this incredible tool. Being a solopreneur, it's tough to wear so many hats and hiring a social media agency is expensive. Vocable is so useful and accessible for everyone to try.",
      name: 'Namrata Agarwal',
      color: 'sunset',
      rating: 5,
    },
    {
      text: "You really should check out Vocable.ai. It is a highly useful tool for me and so many content creators and I must admit I was blown away by the obvious potential. Go check it out for yourself. it's actually pretty cool and free!",
      name: 'Kit B.',
      color: 'white',
      rating: 5,
    },
    {
      text: 'This will save me at least 7-12 hours per week!',
      name: 'Veerle B.',
      color: 'periwinkle',
      rating: 4,
    },
    {
      text: 'Just found out about vocable.ai. An amazing free tool for planning and optimizing your content process.',
      name: 'Patricia R.',
      color: 'white',
      rating: 5,
    },
    {
      text: 'WOW! I am BEYOND stoked to use Vocable!!! As a process oriented woman it has transformed my approach to content planning.',
      name: 'Bre S.',
      color: 'moonstone',
      rating: 3,
    },
    {
      text: 'Thank you massively for creating Vocable.ai I am currently using it for the upcoming launch of my online course, recruiting agency brand, and the launch of my podcast show! I am very confident this tool is going to help me deliver the outcome I am looking for with the launch and successful scaling of my businesses!',
      name: 'Chris Begin',
      color: 'sunset',
      rating: 4,
    },
    {
      text: "Vocable is really good. I've been planning and experimenting for a week now and I'm super impressed.",
      name: 'Sofia R.',
      color: 'periwinkle',
      rating: 4,
    },
    {
      text: 'Wanted to share a really awesome Al tool. Vocable is an integrated social media planner tool. I checked it out and I plan to use it for ideation and planning for various platforms.',
      name: '@thewealthy.mum',
      color: 'white',
      rating: 5,
    },
    {
      text: 'A much faster way to generate content at scale!',
      name: 'Roy C.',
      color: 'moonstone',
      rating: 3,
    },
    {
      text: 'Tremendously bullish on Vocable.ai and the work Iman Oubou and Adil Oubou have put in to create an #automation/ #Al-powered platform for content delivery. Platforms like Vocable.ai open up many opportunities for users to build clean workflows to improve brand and solution visibility. The amount of time and energy automation has saved me on LinkedIn (to mere minutes a day) is amazing!',
      name: 'Erik P.',
      color: 'periwinkle',
      rating: 4,
    },
    {
      text: 'Vocable helped me create content quickly, do keyword research, and efficiently managed it all in one place, which I liked. I think this combination for features sets this platform apart from other Al generated content tools I currently use.',
      name: 'Zakiya A.',
      color: 'sunset',
      rating: 5,
    },
    {
      text: 'Vocable has been great for project planning!',
      name: 'Jose C.',
      color: 'moonstone',
      rating: 4,
    },
    {
      text: 'The automated projects feature helped ease the process of content creation',
      name: 'Edward C.',
      color: 'white',
      rating: 3,
    },
    {
      text: 'Happy for the discovery of Vocable.ai It has changed my world.',
      name: 'Lori H.',
      color: 'sunset',
      rating: 5,
    },
    {
      text: "Check out vocable.ai, it's a free amazing tool for marketing.",
      name: '@bluediamondphotomn',
      color: 'periwinkle',
      rating: 4,
    },
    {
      text: 'I found Vocable to be very useful as it actually made the different tools I use work together on one platform. Instead of using word to create, then check online for content, I could use Al to build upon my ideas for the post and search for additional content to include for reference.',
      name: 'Brandy C.',
      color: 'moonstone',
      rating: 3,
    },
    {
      text: "If you're serious about mastering Al for your content marketing or your entire creator business, don't sleep on this.",
      name: 'Jan K.',
      color: 'white',
      rating: 5,
    },
    {
      text: 'There is truly so much Vocable can do that it surprised me in some aspects. One part in particular was the style guide.',
      name: 'Hailey K.',
      color: 'periwinkle',
      rating: 4,
    },
    {
      text: 'After using Vocable, I wanted to thank you loads for sharing and creating it!!! It is AMAZING!!',
      name: 'Luisa',
      color: 'sunset',
      rating: 5,
    },
    {
      text: 'I started using Vocable a few months back and it is amazing. I am really excited about this product.',
      name: 'Gavriel L.',
      color: 'moonstone',
      rating: 3,
    },
    {
      text: 'I was very impressed with how easy and efficient the workflow was.',
      name: 'Vinny C.',
      color: 'white',
      rating: 4,
    },
  ];

  return (
    <Box
      as="section"
      id="CustomerReview"
      className={Styles.CustomerReview}
      direction="column"
      align="center"
      color="sunset"
      margins
    >
      <div className={Styles.CustomerReview__header}>
        <div className={Styles.QuoteIcon}>
          <FaQuoteRight height={16} width={16} />
        </div>
        <p className={Styles.CustomerReview__header__heading}>
          What's the Buzz? Our Users Chime In!
        </p>
        <p className={Styles.CustomerReview__header__description}>
          Our users have us blushing! See what they're saying about their
          experience using Vocable.
        </p>
      </div>
      <MyCarousel>
        <Flex
          // gap="16"
          justify="center"
          css={{
            position: 'relative',
          }}
          className={Styles.review_sec}
        >
          <div className={Styles.review_sec__image_container}>
            {testimonial.map((item, index) => (
              <ReviewCard
                key={index}
                color={item.color}
                text={item.text}
                name={item.name}
                rating={item.rating}
              />
            ))}
          </div>
        </Flex>
      </MyCarousel>
    </Box>
  );
};

export default CustomerReview;
