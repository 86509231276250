import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "helpers/axios";
import { v4 as uuid } from "uuid";
// import { useLocation, useNavigate } from "react-router-dom";

export const getPlans = () => {
  return getRequest("subscriptions/products");
};

export const startTrial = (priceId, couponName = '') => {
  return postRequest("subscriptions/start_trial", {
    coupon_name: couponName,
    price_id: priceId,
  });
};

export const createCustomer = () => {
  return postRequest("subscriptions/create_customer");
};

export const createSubscription = (priceId) => {
  return postRequest("subscriptions", {
    price_id: priceId,
  });
};

export const getClientSecret = (customerId) => {
  return postRequest("subscriptions/create_setup_intent", {
    customer_id: customerId,
  });

};

export const updateSubscription = (priceId, couponName, discountedPrice) => {
  return putRequest("subscriptions", {
    price_id: priceId,
    coupon_name: couponName,
    discounted_price: discountedPrice
  });
};

export const refreshSubscription = (paymentIntent) => {
  return postRequest("subscriptions/refresh", {
    payment_intent: paymentIntent,
  });
};

export const getPaymentMethods = (paymentIntent) => {
  return getRequest("stripe/payment_methods");
};

export const markDefaultCard = (cardId) => {
  return postRequest("stripe/payment_methods/mark_default", {
    payment_mehod_id: cardId,
  });
};

export const removeCard = (cardId) => {
  return deleteRequest(
    `stripe/payment_methods/remove?payment_method_id=${cardId}`
  );
};

export const getCurrentSubscription = (cardId) => {
  return getRequest(`current_subscription`);
};

export const cancelSubscription = (cardId) => {
  return deleteRequest(`subscriptions/cancel`);
};

export const createCard = (cardToken) => {
  return postRequest(`stripe/payment_methods/add`, {
    card_token: cardToken,
  });
};

export const getAllInvoices = (cardToken) => {
  return getRequest(`invoices`);
};

export const sendSupportEmail = (subject, message) => {
  return postRequest(`request_support`,{
    subject,
    text: message
  });
};

export const applyCouponDiscount = (couponName, priceId) => {
  return postRequest(`subscriptions/apply_coupon`, {
    coupon_name: couponName,
    price_id: priceId
  });
};

