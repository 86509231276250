import React from 'react';
import Styles from './NewDocumentModal.module.scss';
import { NewDocumentAI } from 'assets/NewDocumentAI';
import { PickTemplate } from 'assets/PickTemplate';
import { ImportDraft } from 'assets/ImportDraft';
import { Freestyle } from 'assets/Freestyle';

const typeAsset = {
  ai: <NewDocumentAI />,
  template: <PickTemplate />,
  draft: <ImportDraft />,
  freestyle: <Freestyle />,
};

const NewDocumentCard = ({
  type = 'ai',
  title,
  description,
  onClick,
  isLoading,
}) => {
  return (
    <div
      className={Styles.DocumentCard}
      onClick={onClick}
      data-loading={isLoading}
    >
      {typeAsset[type]}
      <p className={Styles.DocumentCard_Title}>{title}</p>
      <p className={Styles.DocumentCard_Description}>{description}</p>
    </div>
  );
};

export default NewDocumentCard;
