import React, { useEffect, useState } from 'react';
import BSButton from 'Components/Button/BSButton';
import CustomDropDown from 'Components/CustomDropDown/CustomDropDown';
import ImageView from 'Components/Image/ImageView';
import Styles from 'Components/TableBody/TableBody.module.scss';
import TableHead from 'Components/TableHeader/TableHead';
import LeaveTeamModal from 'Containers/Modal/LeaveTeamModal/LeaveTeamModal';
import AddMemberModal from 'Containers/Modal/TeamModals/AddMemberModal';
import sc from 'Containers/Team/Team.module.scss';
import { TEAM_MEMBER_TABLE_HEADER_TITLES } from 'constants';
import { copyToClipboard, getNameInitials, titleize } from 'helpers';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import Table from 'react-bootstrap/Table';
import {
  FiArrowLeft,
  FiCopy,
  FiEdit,
  FiPlus,
  FiTrash2,
  FiXCircle,
} from 'react-icons/fi';
import { TbSend } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setLoader } from 'redux/slices/loader';
import {
  getTeam,
  getTeamUsersData,
  resendInvite as reInvite,
} from 'services/teamService';
import SearchInput from 'ui/SearchInput/SearchInput';

const TeamDetail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const teamId = params.teamId;
  const [team, setTeam] = useState({});
  const [teamUsers, setTeamUsers] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [AddMemberModalView, setAddMemberModalView] = useState(false);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [teamUser, setTeamUser] = useState({});
  const { user: currentUser } = useSelector((state) => state.auth);
  const [leaveTeamModal, setLeaveTeamModal] = useState(false);
  const [showCustomMessage, setShowCustomMessage] = useState(false);

  useEffect(() => {
    fetchTeamDetail();
  }, [teamId, searchTerm]);

  const fetchTeamDetail = async () => {
    if (searchTerm == null) {
      dispatch(setLoader({ isLoading: true }));
    }
    try {
      const [teamData, teamUsersData] = await Promise.all([
        getTeam(teamId),
        getTeamUsersData(teamId, searchTerm),
      ]);
      setTeam(teamData?.data?.team);
      setTeamUsers(teamUsersData?.data?.team_users);
      setPendingUsers(teamUsersData?.data?.pending_team_users);
    } catch (error) {
      renderError(error);
    } finally {
      dispatch(setLoader({ isLoading: false }));
    }
  };

  const resendInvite = async (member) => {
    dispatch(setLoader({ isLoading: true }));
    try {
      const res = await reInvite(teamId, member);
      dispatch(setLoader({ isLoading: false }));
      renderSuccess(res?.data?.message);
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
      renderError(error);
    }
  };

  const editMember = (member) => {
    setTeamUser(member);
    setAddMemberModalView(true);
  };

  const deleteMember = (member) => {
    setLeaveTeamModal(true);
    setTeamUser(member);
  };

  const leaveTeam = (member) => {
    setLeaveTeamModal(true);
    setShowCustomMessage(true);
    setTeamUser(member);
  };

  const copyEmail = (member) => {
    copyToClipboard(member.email);
  };

  const getOptions = (team_access, access, email, showResendOpt) => {
    let dropDownOptions = [];

    switch (true) {
      case team_access === 'owner':
        dropDownOptions = [
          {
            key: 'edit',
            label: 'Edit',
            icon: <FiEdit />,
            action: editMember,
          },
          {
            key: 'remove',
            label: 'Remove from team',
            icon: <FiTrash2 />,
            action: deleteMember,
          },
        ];
        if (showResendOpt) {
          dropDownOptions.unshift({
            key: 'resend-invite',
            label: 'Resend invite',
            icon: <TbSend />,
            action: resendInvite,
          });
        }
        break;
      case team_access === 'admin' &&
        email !== currentUser.email &&
        access !== 'owner':
        dropDownOptions = [
          {
            key: 'edit',
            label: 'Edit',
            icon: <FiEdit />,
            action: editMember,
          },
          {
            key: 'remove-from-team',
            label: 'Remove from team',
            icon: <FiTrash2 />,
            action: deleteMember,
          },
        ];
        if (showResendOpt) {
          dropDownOptions.unshift({
            key: 'resend-invite',
            label: 'Resend invite',
            icon: <TbSend />,
            action: resendInvite,
          });
        }
        break;
      case team_access === 'admin' && email === currentUser.email:
        dropDownOptions = [
          {
            key: 'leave-team',
            label: 'Leave team',
            icon: <FiXCircle />,
            action: leaveTeam,
          },
        ];
        break;
      case team_access === 'creator' && email === currentUser.email:
        dropDownOptions = [
          {
            key: 'leave-team',
            label: 'Leave team',
            icon: <FiXCircle />,
            action: leaveTeam,
          },
        ];
        break;
      case team_access === 'creator' && email !== currentUser.email:
        dropDownOptions = [
          {
            key: 'copy-email',
            label: <span>Copy email</span>,
            icon: <FiCopy />,
            action: copyEmail,
          },
        ];
        break;
      case team_access === 'admin' &&
        email !== currentUser.email &&
        access === 'owner':
        dropDownOptions = [
          {
            key: 'copy-email',
            label: <span>Copy email</span>,
            icon: <FiCopy />,
            action: copyEmail,
          },
        ];
        break;
      default:
        dropDownOptions = [
          {
            key: 'copy-email',
            label: <span>Copy email</span>,
            icon: <FiCopy />,
            action: copyEmail,
          },
        ];
    }

    return dropDownOptions;
  };

  return (
    <section className={sc.TeamMembersWrapper}>
      <div
        className={`d-flex align-items-center ${sc.TeamMembersWrapper__Header}`}
      >
        <FiArrowLeft
          className={sc.TeamMembersWrapper__Header_Arrow}
          onClick={() => navigate('/teams')}
          size={24}
          color="#344054"
        />
        <div
          className={
            'd-flex align-items-center ' + sc.TeamMembersWrapper__Header_Details
          }
        >
          {team.avatar ? (
            <ImageView
              src={team.avatar}
              class={` img-fuild ${sc.TeamMembersWrapper__Header_Details_TeamAvatar}`}
            />
          ) : (
            <span
              className={
                'rounded-profile ' +
                sc.TeamMembersWrapper__Header_Details_TeamAvatar
              }
              style={{ fontSize: '1.75rem' }}
            >
              {getNameInitials(team.title)}
            </span>
          )}
          <div
            className={`d-flex flex-column ${sc.TeamMembersWrapper__TeamDetails}`}
          >
            <div className="d-flex justify-content-between align-items-center ">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <h4
                    className={`Heading-lg-fs-24-32 me-2 ${sc.TeamMembersWrapper__TeamDetails_Name}`}
                  >
                    {team.title?.substring(0, 100)}
                  </h4>
                  <span
                    className={
                      'text-sm-18lh ' +
                      sc.TeamMembersWrapper__TeamDetails_CreatedOn
                    }
                  >
                    Created {team.created_at}
                  </span>
                </div>
                <div className="w-100">
                  <p
                    className={
                      'text-sm-18lh ' +
                      sc.TeamMembersWrapper__TeamDetails_Description
                    }
                  >
                    {team.description}
                  </p>
                </div>
              </div>

              {team.access !== 'creator' && pendingUsers.length < 2 && (
                <BSButton
                  icon={<FiPlus color="#fff" size={20} />}
                  classList={'button  secondary_btn'}
                  ButtonText="Add Member"
                  variant="light"
                  onClick={() => setAddMemberModalView(true)}
                />
              )}
            </div>
          </div>
        </div>
        {AddMemberModalView && (
          <AddMemberModal
            show={AddMemberModalView}
            onHide={() => {
              setTeamUser({});
              setAddMemberModalView(false);
            }}
            teamUser={teamUser}
            teamUsers={teamUsers}
            pendingUsers={pendingUsers}
            team={team}
            fetchTeamDetail={fetchTeamDetail}
          ></AddMemberModal>
        )}
        {leaveTeamModal && (
          <LeaveTeamModal
            show={leaveTeamModal}
            onHide={() => {
              setLeaveTeamModal(false);
              setTeamUser({});
            }}
            resource={team}
            email={showCustomMessage ? currentUser.email : teamUser.email}
            showCustomMessage={showCustomMessage}
            fetchTeamDetail={fetchTeamDetail}
          />
        )}
      </div>
      <div className={sc.TeamsData}>
        <div className={sc.TeamsData__Header}>
          <span className={`${sc.teamMamber} text-md-Regular`}>
            Team Members
          </span>
          <SearchInput
            setInputValue={setSearchTerm}
            inputValue={searchTerm}
            text="Search"
          />
        </div>
        <div>
          <Table className=" table-fixed" hover responsive="lg">
            <TableHead TableHeadings={TEAM_MEMBER_TABLE_HEADER_TITLES} />
            <tbody>
              {teamUsers?.map(({ access, user }) => {
                return (
                  <tr key={user.id} className={Styles.TableRow}>
                    <td
                      className={`d-flex align-items-center g-10 text-md-Regular ${Styles.TableData} ${Styles.TableData__Extensive}`}
                    >
                      {user.mini_avatar ? (
                        <ImageView
                          class="rounded-profile-x-sm "
                          src={user.mini_avatar}
                        />
                      ) : (
                        <span
                          className={'rounded-profile rounded-profile-x-sm '}
                          style={{ fontSize: '0.75rem' }}
                        >
                          {getNameInitials(user.name)}
                        </span>
                      )}
                      <p className={Styles.TableData_Title}>{user.name}</p>
                    </td>
                    <td className={'text-md-Regular ' + Styles.TableData_Title}>
                      {user.email}
                    </td>
                    <td className={'text-md-Regular ' + Styles.TableData_Title}>
                      {titleize(
                        (access === 'creator' ? 'Member' : access) || '-',
                      )}
                    </td>
                    <td className={'text-md-Regular ' + Styles.TableData_Title}>
                      {user.last_active_at || '---'}
                    </td>
                    <td
                      className={
                        'text-md-Regular overflow-visible ' +
                        Styles.TableData_Title
                      }
                    >
                      {!(
                        access === 'owner' && currentUser.email === user.email
                      ) && (
                        <CustomDropDown
                          options={getOptions(team.access, access, user.email)}
                          resource={{
                            access,
                            email: user.email,
                            name: user.name,
                            editName: true,
                          }}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
              {pendingUsers?.map((user) => {
                return (
                  <tr key={user.id} className={Styles.TableRow}>
                    <td
                      className={`d-flex align-items-center g-10 text-md-Regular ${Styles.TableData} ${Styles.TableData__Extensive}`}
                    >
                      {
                        <span
                          className={'rounded-profile rounded-profile-x-sm '}
                          style={{ fontSize: '0.75rem' }}
                        >
                          {getNameInitials(user.name)}
                        </span>
                      }
                      <p className={Styles.TableData_Title}>
                        {user.name || '----'}
                      </p>
                    </td>
                    <td className={'text-md-Regular ' + Styles.TableData_Title}>
                      {user.email}
                    </td>
                    <td className={'text-md-Regular ' + Styles.TableData_Title}>
                      {titleize(user.member_access || '-')}
                    </td>
                    <td className={'text-md-Regular ' + Styles.TableData_Title}>
                      <span className="badge-wrapper ">Invitation Pending</span>
                    </td>
                    <td
                      className={
                        'text-md-Regular overflow-visible ' +
                        Styles.TableData_Title
                      }
                    >
                      <CustomDropDown
                        options={getOptions(
                          team.access,
                          user.member_access,
                          user.email,
                          true,
                        )}
                        resource={{
                          access: user.member_access,
                          email: user.email,
                          name: user.name,
                          editName: false,
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </section>
  );
};

export default TeamDetail;
