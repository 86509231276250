import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from 'helpers/axios';

// ##### ##### ##### ##### #####   GET   ##### ##### ##### ##### ##### //
export const getComments = (documentId) => {
  return getRequest(`comments?search[document_slug_eq]=${documentId}`);
};

export const getCommentsById = (documentId, id) => {
  return getRequest(
    `comments?search[document_slug_eq]=${documentId}&uuid=${id}`,
  );
};

export const getOpenComments = (documentId) => {
  return getRequest(
    `comments/open_comments?search[document_slug_eq]=${documentId}`,
  );
};

// TODO: comments/refresh

export const getResolvedComments = (documentId) => {
  return getRequest(
    `comments/resolved_comments?search[document_slug_eq]=${documentId}`,
  );
};

// ##### ##### ##### ##### #####   POST   ##### ##### ##### ##### ##### //
export const postComment = (documentId, body) => {
  return postRequest(`comments?search[document_slug_eq]=${documentId}`, body);
};

// ##### ##### ##### ##### #####   PATCH   ##### ##### ##### ##### ##### //
export const changeStatus = (documentId, body) => {
  return patchRequest(
    `comments/mark_resolved?search[document_slug_eq]=${documentId}`,
    body,
  );
};

// ##### ##### ##### ##### #####   DELETE   ##### ##### ##### ##### ##### //
export const deleteComment = (documentId, body) => {
  return deleteRequest(
    `comments/delete_comments?search[document_slug_eq]=${documentId}`,
    body,
  );
};
