import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CouponElement } from './CouponElement';

export const CouponModal = ({
  isOpen,
  onClose,
  product,
  handleUpdateSubscription,
}) => {
  const [couponName, setCouponName] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState('');
  const [showCouponForm, setShowCouponForm] = useState(false);

  const updatePlan = () => {
    // Call the handleUpdateSubscription function passed from parent component
    handleUpdateSubscription(product, couponName, discountedPrice);
    // Clear fields and close modal
    closeModal();
  };

  const closeModal = () => {
    setCouponName('');
    setDiscountedPrice('');
    setShowCouponForm(false);
    onClose();
  };

  return (
    <Modal size="100" centered show={isOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title
          style={{ fontFamily: 'Inter', textAlign: 'center', width: '100%' }}
        >
          Change Plan
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-3">
          <CouponElement
            couponName={couponName}
            setCouponName={setCouponName}
            discountedPrice={discountedPrice}
            setDiscountedPrice={setDiscountedPrice}
            showCouponForm={showCouponForm}
            setShowCouponForm={setShowCouponForm}
            priceId={product?.id}
            product={product}
          />
        </div>
        <div className={'btnWrapper' + (showCouponForm ? ' d-none' : '')}>
          <button
            className="primary_btn w-100 px-3 py-2"
            onClick={() => updatePlan()}
          >
            Update Plan
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
