import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Dropdown, Nav, OverlayTrigger, Tab } from 'react-bootstrap';
import { deleteProject } from 'services/projectService';
import ProjectDeleteModal from './ProjectDeleteModal';
import ProjectList from './ProjectList/ProjectList';
import ProjectDrawer from './ProjectDrawer';
import ProjectTiles from './ProjectTiles/ProjectTiles';
import { ROUTES } from 'constants/routes';
import { FiGrid, FiList, FiTag, FiUser } from 'react-icons/fi';
import { success as successToast, error as errorToast } from 'helpers/toaster';
import Styles from './Projects.module.scss';
import SearchInput from 'ui/SearchInput/SearchInput';
import FloatingButton from 'ui/FloatingButton/FloatingButton';
import Popover from 'Components/Popover/Popover';
import BSButton from 'Components/Button/BSButton';
import EmptyState from 'Components/EmptyState/EmptyState';
import ImageView from 'Components/Image/ImageView';
import useProjects from 'hooks/projects/useProjects';
import Loader from 'Components/Loader/Loader';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';
import { CiGrid2H } from 'react-icons/ci';
import { BsFilter, BsPlus } from 'react-icons/bs';
import { BiDisc } from 'react-icons/bi';
import { TbCalendarMinus } from 'react-icons/tb';
import Select from 'react-select';
import ContentFilter from 'Containers/Content Planner/ContentFilter';
import { RxCross2 } from 'react-icons/rx';
import ProjectSelect from 'Containers/Content Planner/ProjectSelect';
import CompaignCard from 'Components/CompaignCard/CompaignCard';

const Projects = () => {
  const optionsClassName =
    'd-flex align-items-center select-option gap-2 ' + Styles.SelectLabel;
  const filterOptions = {
    filterBy: '',
    isOptions: '',
  };
  const isFilterOptions = [
    {
      value: '_in',
      label: <div className={optionsClassName}>is</div>,
    },
    {
      value: '_not_in',
      label: <div className={optionsClassName}>is not</div>,
    },
  ];
  const SelectStyles = {
    option: (styles, { isSelected, isFocused }) => ({
      boxShadow: isFocused ? null : null,
      background: isSelected ? `url("./Images/blueTick.svg")` : '',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '90% 50%',
      backgroundColor: isSelected ? '#F2FBFB!important' : '#fff',
      paddingRight: isSelected ? '0px' : '25px',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: '500',
      color: '#344054',
      padding: '4px 10px',
      '&:hover': {
        backgroundColor: 'lightgrey',
        cursor: 'pointer',
      },
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      cursor: 'pointer',
    }),
  };
  const options = [
    {
      value: 'state',
      label: (
        <div className={optionsClassName}>
          <BiDisc size={14} color="#667085" /> <p>Status</p>
        </div>
      ),
    },
    {
      value: 'deadline_date',
      label: (
        <div className={optionsClassName}>
          <TbCalendarMinus size={14} color="#667085" /> <p>Deadline</p>
        </div>
      ),
    },
    {
      value: 'assignee_id',
      label: (
        <div className={optionsClassName}>
          <FiUser size={14} color="#667085" /> <p>Assigned to</p>
        </div>
      ),
    },
    {
      value: 'content_type',
      label: (
        <div className={optionsClassName}>
          <FiTag size={14} color="#667085" /> <p>Content Type</p>
        </div>
      ),
    },
  ];

  const [showProjectDrawer, setShowProjectDrawer] = useState(false);
  const [showProjectDeleteModal, setShowProjectDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [action, setAction] = useState('create');
  const [project, setProject] = useState(null);
  const [projectIdToRemove, setProjectIdToRemove] = useState('');
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [filters, setFilters] = useState([filterOptions]);
  const [showPopover, setShowPopover] = useState(false);

  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { id: userId, subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};
  const [selectedProject, setSelectedProject] = useState(null);

  const {
    data: projectPages,
    isLoading: isProjectsLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useProjects({
    searchTerm,
  });

  const projects = useMemo(() => {
    return projectPages?.pages.flatMap((page) => page?.data?.projects) || [];
  }, [projectPages?.pages]);

  const handleProjectEdit = (project) => {
    setAction('update');
    setProject(project);
    setShowProjectDrawer(true);
  };

  const handleProjectDelete = (projectId) => {
    setProjectIdToRemove(projectId);
    setShowProjectDeleteModal(true);
  };

  const handleUpgrade = () => {
    navigate('/subscription');
  };

  const handleProjectDeletion = async () => {
    try {
      await deleteProject(projectIdToRemove);
      setProjectIdToRemove('');
      setShowProjectDeleteModal(false);
      refetch();
      successToast('Campaign Deleted Successfully');
    } catch (error) {
      errorToast(error.message || 'Failed to delete the project');
    }
  };

  const handleTogglePopover = () => {
    setShowPopover((show) => !show);
  };


  const handleOpenDrawer = () => {
    setAction('create');
    setShowProjectDrawer(true);
  };

  const handleAIPowered = () => {
    if (currentSubscription?.plan_name === 'Basic Plan') {
      setShowUpgradeModal(true);
    } else {
      navigate(ROUTES.Project.New);
    }
  };

  const appliedFilters = filters?.map((filter) => filter?.filterBy) || [];
  const handleFilters = (index, val, type) => {
    const transformedFilters = filters.map((filter, filIndex) => {
      if (filIndex === index) {
        if (type === 'isOptions') {
          return {
            ...filter,
            [type]: val,
          };
        }
        if (type !== 'filterValue') {
          return {
            ...filter,
            [type]: val,
            filterValue: '',
          };
        }
        return {
          ...filter,
          [type]: val,
        };
      }
      return filter;
    });
    if (type === 'filterValue') {
      setFinalFilters(transformedFilters);
    }
    setFilters(transformedFilters);
  };

  const NewProjectPopover = (
    <Popover>
      <BSButton
        classList="button secondary_btn"
        ButtonText="AI-Powered Campaigns"
        variant="light"
        onClick={handleAIPowered}
      />
      <BSButton
        classList="button secondary_btn"
        ButtonText="Self-Powered Campaigns"
        variant="outline-secondary"
        onClick={() => {
          setShowPopover(false);
          setAction('create');
          setShowProjectDrawer(true);
        }}
      />
    </Popover>
  );

  const campaigns = [
    {
      id: 1,
      name: 'Engagement',
      start_date: '02-07',
      end_date: '12-07',
      editors: [
        'https://i.pravatar.cc/150?img=1',
        'https://i.pravatar.cc/150?img=2',
      ],
      type: 'engagement',
      description: 'A New Era of Content Market',
    },
    {
      id: 2,
      name: 'Lead Generation',
      start_date: '02-07',
      end_date: '07-03',
      editors: [
        'https://i.pravatar.cc/150?img=3',
        'https://i.pravatar.cc/150?img=4',
      ],
      type: 'lead_generation',
      description: 'Maximize Your Lead Potential',
    },
    {
      id: 3,
      name: 'Brand Awareness',
      start_date: '02-07',
      end_date: '07-03',
      editors: [
        'https://i.pravatar.cc/150?img=3',
        'https://i.pravatar.cc/150?img=4',
      ],
      type: 'Brand Awareness',
      description: 'Maximize Your Lead Potential',
    },
    {
      id: 4,
      name: 'Sales Conversion',
      start_date: '02-07',
      end_date: '07-03',
      editors: [
        'https://i.pravatar.cc/150?img=3',
        'https://i.pravatar.cc/150?img=4',
      ],
      type: 'Sales Conversion',
      description: 'Maximize Your Lead Potential',
    },
    {
      id: 5,
      name: 'Lead Generation',
      start_date: '02-07',
      end_date: '07-03',
      editors: [
        'https://i.pravatar.cc/150?img=3',
        'https://i.pravatar.cc/150?img=4',
      ],
      type: 'lead_generation',
      description: 'Maximize Your Lead Potential',
    },
    {
      id: 6,
      name: 'Sales Conversion',
      start_date: '02-07',
      end_date: '07-03',
      editors: [
        'https://i.pravatar.cc/150?img=3',
        'https://i.pravatar.cc/150?img=4',
      ],
      type: 'Sales Conversion',
      description: 'Maximize Your Lead Potential',
    },
    {
      id: 7,
      name: 'Lead Generation',
      start_date: '02-07',
      end_date: '07-03',
      editors: [
        'https://i.pravatar.cc/150?img=3',
        'https://i.pravatar.cc/150?img=4',
      ],
      type: 'lead_generation',
      description: 'Maximize Your Lead Potential',
    },
  ];

  return (
    <section className={Styles.Projects}>
      <section className={Styles.Documents_Wrapper}>
        <Tab.Container
          className="nav-tabs"
          id="document-tabs"
          defaultActiveKey="grid"
        >
          <Nav className={Styles.ContentPlanner__Wrapper_Navigation}>
            <div className={Styles.ContentPlanner__Wrapper_Navigation__left}>
              <Nav.Item
                className={Styles.ContentPlanner__Wrapper_Navigation_Icon}
              >
                <Nav.Link
                  eventKey="grid"
                  className={
                    Styles.ContentPlanner__Wrapper_Navigation_Icon__Item
                  }
                >
                  <FiGrid size={20} />
                  <span>Grid view</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                className={Styles.ContentPlanner__Wrapper_Navigation_Icon}
              >
                <Nav.Link
                  eventKey="list"
                  className={
                    Styles.ContentPlanner__Wrapper_Navigation_Icon__Item
                  }
                >
                  <CiGrid2H size={20} />
                  <span>List view</span>
                </Nav.Link>
              </Nav.Item>
            </div>
            <div className={Styles.ContentPlanner__Wrapper_Navigation__right}>
              <div className="px-2">
                <SearchInput
                  setInputValue={setSearchTerm}
                  inputValue={searchTerm}
                  text="Search"
                />
              </div>
              <Dropdown>
                {/* <Dropdown.Toggle
                  className={Styles.ContentPlanner__Wrapper_Navigation_Filter}
                >
                  <BsFilter size={20} />
                  Filter
                </Dropdown.Toggle> */}
                <Dropdown.Menu
                  className={
                    Styles.ContentPlanner__Wrapper_InputSection_DropDown
                  }
                >
                  {filters.map((filter, index) => {
                    const { filterBy, isOptions, filterValue } = filter || {};
                    const filterByValue = options.find(
                      (status) => status.value === filterBy,
                    );
                    return (
                      <div
                        className={
                          'justify-content-between gap-2 ' +
                          Styles.ContentPlanner__Wrapper_InputSection_Filters
                        }
                        style={{ marginBottom: 5 }}
                        key={index}
                      >
                        <section className="d-flex justify-content-start gap-2">
                          <Select
                            isSearchable={false}
                            className={'filter-Select'}
                            classNamePrefix={'filter-Select'}
                            styles={SelectStyles}
                            placeholder="Filter By"
                            options={options.filter(
                              (option) =>
                                !appliedFilters.includes(option.value),
                            )}
                            value={filterByValue || null}
                            onChange={(val) =>
                              handleFilters(index, val.value, 'filterBy')
                            }
                          />
                          <Select
                            isSearchable={false}
                            className="filter-Select"
                            classNamePrefix="filter-Select"
                            styles={SelectStyles}
                            placeholder="is"
                            options={isFilterOptions}
                            value={
                              isFilterOptions.find(
                                (status) => status.value === isOptions,
                              ) || null
                            }
                            onChange={(val) =>
                              handleFilters(index, val.value, 'isOptions')
                            }
                          />
                          <ContentFilter
                            filterBy={filterBy}
                            filterValue={filterValue}
                            handleFilters={handleFilters}
                            index={index}
                            filters={filters}
                            isOptions={isOptions}
                          />
                        </section>
                        <RxCross2
                          size={14}
                          color="#667085"
                          // onClick={() => handleRemoveFilter(index)}
                        />
                      </div>
                    );
                  })}
                  {filters.length < 4 && (
                    <BSButton
                      icon={<BsPlus />}
                      classList="mt-3 button button-light"
                      ButtonText="Add filter"
                      variant="light"
                      onClick={() =>
                        setFilters((prevValue) => [
                          ...prevValue,
                          { filterBy: '', isOptions: '' },
                        ])
                      }
                    />
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {/* <div
                className={`dropdown_container ${Styles.utility_left_margin}`}
              >
                <ProjectSelect setSelectedProject={setSelectedProject} />
              </div> */}
            </div>
          </Nav>
          <Tab.Content>
            {isProjectsLoading ? (
              <div className="mt-4">
                <Loader inline loaderHeading="Loading Campaigns..." />
              </div>
            ) : projects.length <= 0 ? (
              <EmptyState
                emptyText="Looks like you haven’t created any campaign yet."
                ctaText="Click here to build one."
                buttonText="Create Campaign"
                onClick={() => {
                  setShowPopover(false);
                  setAction('create');
                  currentSubscription?.plan_name === "Basic Plan" ? setShowUpgradeModal(true) : setShowProjectDrawer(true);
                }}
              >
                <ImageView src="/Images/plan.svg" />
              </EmptyState>
            ) : (
              <>
                <Tab.Pane eventKey="grid">
                  <div className={``}>
                    <ProjectTiles
                      projects={projects}
                      userId={userId}
                      next={fetchNextPage}
                      hasMore={hasNextPage}
                      handleProjectEdit={currentSubscription?.plan_name === "Basic Plan" ? ()=>setShowUpgradeModal(true) : handleProjectEdit}
                      handleProjectDelete={handleProjectDelete}
                      projectAllCard={true}
                    />
                    {isFetchingNextPage && (
                      <div className="mt-4">
                        <Loader inline />
                      </div>
                    )}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="list">
                  <ProjectList
                    projects={projects}
                    userId={userId}
                    handleProjectEdit={handleProjectEdit}
                    handleProjectDelete={handleProjectDelete}
                  />
                </Tab.Pane>
              </>
            )}
          </Tab.Content>
        </Tab.Container>
      </section>
      <OverlayTrigger
        placement="top"
        rootClose
        show={showPopover}
        onToggle={handleTogglePopover}
        overlay={NewProjectPopover}
      >
        <FloatingButton onClick={currentSubscription?.plan_name === "Basic Plan" ? ()=>setShowUpgradeModal(true) : handleOpenDrawer} />
      </OverlayTrigger>
      {showProjectDrawer && (
        <ProjectDrawer
          show={showProjectDrawer}
          onHide={async () => {
            refetch();
            setProject(null);
            setShowProjectDrawer(false);
          }}
          action={action}
          project={project}
        />
      )}
      {showProjectDeleteModal && (
        <ProjectDeleteModal
          show={showProjectDeleteModal}
          onHide={() => {
            setProjectIdToRemove('');
            setShowProjectDeleteModal(false);
          }}
          onDelete={handleProjectDeletion}
        />
      )}
      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
        />
      )}
    </section>
  );
};

export default Projects;
