import React from 'react';
import SectionHeader from './SectionHeader';
import { ROUTES } from 'constants/routes';
import EmptyDashboardState from './EmptyDashboardState';
import Styles from './Dashboard.module.scss';
import RecentDocumentCard from 'Components/RecentDocumentCard/RecentDocumentCard';
import { FiFile } from 'react-icons/fi';
import CompaignCardSkeleton from 'Components/CompaignCard/CompaignCardSkeleton';
import useRecentDocuments from 'hooks/documents/useRecentDocuments';

const DocumentSection = ({ onCreateNewDocument, refetchDocumentStats }) => {
  const {
    documents,
    isLoading,
    refetch: refetchRecentDocuments,
  } = useRecentDocuments();

  return (
    <>
      <SectionHeader
        icon={<FiFile size={20} color="#00A7B7" />}
        name="Recent Documents"
        route={ROUTES.Library}
        count={documents?.length ?? 0}
        hideLink={(documents?.length ?? 0) < 1}
      />
      {isLoading ? (
        <div className={Styles.Dashboard__compaigns}>
          <CompaignCardSkeleton count={5} />
        </div>
      ) : documents?.length ? (
        <div className={Styles.Dashboard__compaigns}>
          {documents.slice(0, 4).map((document, index) => (
            <RecentDocumentCard
              key={index}
              document={document}
              isDashboardView={true}
              refetch={async () => {
                refetchDocumentStats();
                refetchRecentDocuments();
              }}
            />
          ))}
        </div>
      ) : (
        <EmptyDashboardState
          title="Zero Docs"
          description="Start drafting your content using our AI templates or AI-assisted freestyling options"
          buttonText="Create New Document"
          backgroundImageUrl="/Images/DashboardEmptyDocsState.svg"
          onClick={onCreateNewDocument}
        />
      )}
    </>
  );
};

export default DocumentSection;
