import React from 'react';
import { ErrorMessage, Field } from 'formik';
import { Form } from 'react-bootstrap';
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TbClockHour4 } from 'react-icons/tb';

const FormikTimeField = ({
  name,
  label,
  isRequired,
  placeholder,
  className,
}) => {
  return (
    <div className="position-relative">
      <Field name={name}>
        {({ field, form }) => {
          return (
            <Form.Group controlId={name} className="position-relative">
              {label && (
                <Form.Label className="Input_label">
                  {`${label} `}
                  {isRequired && <span style={{ color: '#b42318' }}>*</span>}
                </Form.Label>
              )}
              <div className="position-relative">
                <Datepicker
                  {...field}
                  onChange={(newValue) => {
                    form.setFieldValue(field.name, newValue);
                  }}
                  className="input form-control w-100"
                  selected={field.value}
                  placeholderText={placeholder}
                  showPopperArrow={false}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  required={isRequired}
                />
                <TbClockHour4 className="clock-icon" />
              </div>
              <ErrorMessage
                name={name}
                component="div"
                className="error mt-2"
              />
            </Form.Group>
          );
        }}
      </Field>
      <style jsx>{`
        .clock-icon {
          position: absolute;
          top: 50%;
          right: 12px;
          transform: translateY(-50%);
          pointer-events: none;
          color: #dadfe3; /* Adjust icon color */
        }
        .react-datepicker-wrapper {
          width: 100% !important; /* Ensure full width */
        }
        .react-datepicker__input-container input {
          padding-right: 30px; /* Add padding to the right to avoid text overlap with icon */
          width: 100%; /* Ensure input takes full width */
        }
        .react-datepicker__input-container
          input::-webkit-calendar-picker-indicator::after {
          display: none !im[portant; /* Hide the default calendar icon */
        }
        .react-datepicker__input-container input::-webkit-inner-spin-button,
        .react-datepicker__input-container input::-webkit-outer-spin-button {
          -webkit-appearance: none; /* Hide the spinner for time input */
          margin: 0; /* Remove default margin */
        }
      `}</style>
    </div>
  );
};

export default FormikTimeField;
