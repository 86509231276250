import React from 'react';
import Styles from './ContactUs.module.scss';

const ContactUs = () => {
  const openEmail = () => {
    const email = 'info@vocable.ai';
    const subject = 'Subject';
    const body = 'Hello there!';
    window.open(
      `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`,
      '_blank',
    );
  };
  return (
    <div className={Styles.contact_us}>
      <p className={Styles.contact_us__heading}>Contact Us</p>
      <p className={Styles.contact_us__cta}>
        if you have any question please contact us
      </p>
      <button className={Styles.contact_us__btn} onClick={openEmail}>
        Contact Us
      </button>
    </div>
  );
};

export default ContactUs;
