import React from 'react';
import Styles from './Announcement.module.scss';
import { ReactComponent as AnnouncementIcon } from '../../../assets/svg/Announcement.svg';
import { useNavigate } from 'react-router-dom';

const Announcement = ({view=false}) => {
  const navigate = useNavigate();

  return (
    <div className={Styles.container}>
      <div className={Styles.container__left}>
        <div className={Styles.container__left__icon}>
          <AnnouncementIcon />
        </div>
        <span className={Styles.container__left__heading}>Exciting news!</span>
        <span className={Styles.container__left__desc}>
          We’ve launched our new “Brand Voice” feature.
        </span>
      </div>
      <div className={Styles.container__right}>
        {view && <button onClick={() => navigate('/brand')}>View Details</button>}
      </div>
    </div>
  );
};

export default Announcement;
